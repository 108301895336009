import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import { DashboardModalAction } from "components/common/DashboardModal";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { onScroll } from "helpers";
import { debounce, truncate } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import countries from "variables/countries";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const Shipment = () => {
    const limit = 20;
    const [shipmentShow, setShowShipment] = useState(false);
    const [allShipments, setAllShipments] = useState([]);
    const [isScrolled, setIsScrolled] = useState(false);
    const [shipmentLimit, setShipmentLimit] = useState(limit);
    const [loadMoreShipments, setLoadMoreShipments] = useState(false);
    const [shipmentPagination, setShipmentPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [search, setSearch] = useState("");
    const [debounceSearch, setDebounceSearch] = useState("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { fetchingShipments, fetchShipmentsSuccess } = useAppSelector((state) => state.shipment);
    const dashboardContent = document.getElementById("dashboardContent");
    const [param] = useSearchParams();
    const showAssignShipmentModal = param.get("assign");
    const tableHeader = [
        { title: "Shipment description", widthClass: "w-[250px]" },
        { title: "Origin", widthClass: "w-[250px]" },
        { title: "Destination", widthClass: "w-[250px]" },
        { title: "Vendor", widthClass: "w-[250px]" },
        { title: "Status", widthClass: "w-[200px]" },
        // { title: "Delivery timeline", widthClass: "w-[250px]" },
    ];

    const findOriginCountryData = (shipment) => {
        return countries.find((countryData) => {
            return shipment?.movementdetails?.origin?.details?.[0]?.address_components?.find(
                (value) => value?.short_name === countryData.abbreviation
            );
        });
    };

    const findDestinationCountryData = (shipment) => {
        return countries.find((countryData) => {
            return shipment?.movementdetails?.destination?.details?.[0]?.address_components?.find(
                (value) => value?.short_name === countryData.abbreviation
            );
        });
    };

    const findSupplierDestinationCountry = (shipment) => {
        return countries.find((countryData) => {
            return shipment?.supplier?.customerAndSupplierDetails?.companyAddress?.details?.[0]?.address_components?.find(
                (value) => value?.short_name === countryData.abbreviation
            );
        });
    };

    const tableBody = allShipments?.map((shipment) => {
        const originCountryData = findOriginCountryData(shipment);
        const destinationCountryData = findDestinationCountryData(shipment);
        const supplierDestinationCountryData = findSupplierDestinationCountry(shipment);

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {truncate(
                            shipment?.shipmentType?.toLowerCase() === "freight"
                                ? shipment?.freight?.goodsDetails?.description
                                : shipment?.supplier?.ItemDetails?.description,
                            {
                                length: 28,
                            }
                        )}
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/shipment/${shipment?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div className={`py-3 pr-3 border-slate-100 text-sm text-slate-700 `}>
                        <div className="flex items-center gap-3">
                            {originCountryData && (
                                <ReactCountryFlag
                                    countryCode={originCountryData?.abbreviation}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        marginTop: "-4px",
                                    }}
                                />
                            )}
                            <span>
                                {shipment?.movementdetails?.origin?.address ||
                                    originCountryData?.name}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 border-slate-100 text-sm text-slate-700 `}>
                        <div className="flex items-center gap-3">
                            {(destinationCountryData || supplierDestinationCountryData) && (
                                <ReactCountryFlag
                                    countryCode={
                                        destinationCountryData?.abbreviation ||
                                        supplierDestinationCountryData?.abbreviation
                                    }
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        marginTop: "-4px",
                                    }}
                                />
                            )}
                            <span>
                                {shipment?.movementdetails?.destination?.address ||
                                    destinationCountryData?.name}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 border-slate-100 text-sm text-slate-700 `}>
                        <div className="flex items-center gap-3">
                            <VendorAvatar
                                imageSrc={shipment?.vendor?.profile?.avatar}
                                size={30}
                                name={shipment?.vendor?.profile?.businessName}
                            />
                            <span>{shipment?.vendor?.profile?.businessName}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 space-x-1 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div
                            className={`h-2 w-2 ${
                                shipment?.status === "in-transit" && "bg-orange"
                            }  ${shipment?.status === "delayed" && "bg-red-nice"}  ${
                                shipment?.status === "delivered" && "bg-g-50"
                            } rounded-full`}
                        ></div>
                        <p className="capitalize">{shipment?.status}</p>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
            //         >
            //             13 Mar 2024
            //         </div>
            //     ),
            // },
        ];
    });

    const mobileTable = () =>
        allShipments?.map((shipment, idx) => {
            const originCountryData = findOriginCountryData(shipment);
            const destinationCountryData = findDestinationCountryData(shipment);
            const supplierDestinationCountryData = findSupplierDestinationCountry(shipment);
            return (
                <div
                    key={idx}
                    className=" bg-white border-[#F1F5F9] border rounded-lg  px-4 py-[14px] flex justify-between relative font-rocGroteskMedium  text-sm gap-[9px] flex-col"
                    onClick={() =>
                        navigate(`/dashboard/shipment/${shipment?._id}`, {
                            state: { internalNavigation: true },
                        })
                    }
                >
                    <div className="flex justify-between w-full">
                        <div className="">
                            {originCountryData && (
                                <ReactCountryFlag
                                    countryCode={originCountryData?.abbreviation}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        marginTop: "-4px",
                                    }}
                                />
                            )}

                            {(destinationCountryData || supplierDestinationCountryData) && (
                                <ReactCountryFlag
                                    countryCode={
                                        destinationCountryData?.abbreviation ||
                                        supplierDestinationCountryData?.abbreviation
                                    }
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        marginTop: "-4px",
                                        marginLeft: "-15px",
                                    }}
                                />
                            )}
                        </div>

                        <div
                            className={`flex items-center h-full  py-[5px] px-[15px] rounded-[25px]
                                            ${
                                                shipment?.status === "in-transit"
                                                    ? "bg-[rgba(249,223,179,0.60)]"
                                                    : shipment?.status === "delayed"
                                                    ? "bg-[gba(255,216,210,0.60)]"
                                                    : shipment?.status === "delivered" &&
                                                      "bg-[rgba(208,244,237,0.60)]"
                                            } `}
                        >
                            <div
                                className={`h-2 w-2 mr-2 ${
                                    shipment?.status === "in-transit" && "bg-orange"
                                }  ${shipment?.status === "delayed" && "bg-red-nice"}  ${
                                    shipment?.status === "delivered" && "bg-g-50"
                                } rounded-full`}
                            ></div>
                            <p className="capitalize text-slate-900 font-rocGroteskMedium text-sm">
                                {shipment?.status}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between items-center gap-2 text-[13px] font-rocGroteskMedium">
                        <span className="">
                            {truncate(
                                shipment?.movementdetails?.origin?.address ||
                                    originCountryData?.name,
                                { length: 20 }
                            )}
                        </span>
                        {(shipment?.movementdetails?.destination?.address ||
                            destinationCountryData?.name) && (
                            <i className="ri-arrow-right-line text-slate-400"></i>
                        )}
                        <span className="">
                            {truncate(
                                shipment?.movementdetails?.destination?.address ||
                                    destinationCountryData?.name,
                                { length: 20 }
                            )}
                        </span>
                    </div>

                    <div className="flex items-center gap-3 font-sm font-rocGroteskMedium ">
                        <VendorAvatar
                            imageSrc={shipment?.vendor?.profile?.avatar}
                            size={30}
                            name={shipment?.vendor?.profile?.businessName}
                        />
                        <span>{shipment?.vendor?.profile?.businessName}</span>
                    </div>
                </div>
            );
        });
    const onShipmentTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                shipmentPagination?.current as number,
                shipmentPagination?.number_of_pages as number,
                () => {
                    setShipmentLimit(() => shipmentLimit + limit);
                    setLoadMoreShipments(true);
                }
            ),
        [dashboardContent, shipmentPagination]
    );

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value);
    };

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebounceSearch(value);
    }, 1000);

    useEffect(() => {
        dispatch(shipmentActions.getShipments(shipmentLimit, debounceSearch));
    }, [dispatch, shipmentLimit, debounceSearch]);
    useEffect(() => {
        if (showAssignShipmentModal?.toLowerCase() === "success") {
            setShowShipment(true);
        } else {
            setShowShipment(false);
        }
    }, [showAssignShipmentModal, dispatch]);

    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess && !shipmentShow)) {
            setAllShipments(fetchShipmentsSuccess?.data);
            setShipmentPagination({
                current: fetchShipmentsSuccess?.pagination?.current,
                number_of_pages: fetchShipmentsSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onShipmentTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onShipmentTableScroll);
        };
    }, [dashboardContent, onShipmentTableScroll]);

    return (
        <PageFrame
            isLoading={
                fetchingShipments &&
                allShipments?.length === 0 &&
                !shipmentShow &&
                !loadMoreShipments &&
                !debounceSearch
            }
        >
            <div className="lg:mx-7 !pb-36">
                <div className="mb-[25px] flex items-center justify-between">
                    <BreadCrumb
                        parentCrumb={"Shipment management"}
                        size="large"
                        parentClassName="!text-slate-700"
                    />
                    {/* <div>
                        <Button
                            btnText={"Assign a shipment"}
                            type={"button"}
                            btnClassname="!py-2 !px-4"
                            onClick={() => setShowShipment(true)}
                        />
                    </div> */}
                </div>
                <div className="flex items-center justify-between flex-col lg:flex-row mb-4 lg:mb-[27px]">
                    {/* <div className="flex items-center gap-3">
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div className="w-[318px]  relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                            <div className="flex relative flex-col h-fit gap-2">
                                                <SelectInput
                                                    value={""}
                                                    name="status"
                                                    placeholder={"Status"}
                                                    // handleChange={(name, value) =>
                                                    //     handleChange({ target: { name, value } })
                                                    // }
                                                    isRequired={false}
                                                    dropdownOptions={[
                                                        { label: "Accepted", value: "accepted" },
                                                        { label: "Rejected", value: "rejected" },
                                                        { label: "Pending", value: "pending" },
                                                    ].map((item) => ({
                                                        label: item.label,
                                                        value: item.value,
                                                    }))}
                                                />
                                                <TextInput
                                                    name={"amount"}
                                                    type={"text"}
                                                    // value={""}
                                                    placeholder={"Amount"}
                                                    // onChange={handleChange}
                                                    required={false}
                                                />
                                                <TextInput
                                                    name={"vendor"}
                                                    type={"text"}
                                                    // value={""}
                                                    placeholder={"Vendor"}
                                                    // onChange={handleChange}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Sort</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                "  !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                            <div className="flex relative flex-col h-fit gap-2">
                                                <SelectInput
                                                    value={""}
                                                    name="status"
                                                    placeholder={"Status"}
                                                    // handleChange={(name, value) =>
                                                    //     handleChange({ target: { name, value } })
                                                    // }
                                                    isRequired={false}
                                                    dropdownOptions={[
                                                        { label: "Accepted", value: "accepted" },
                                                        { label: "Rejected", value: "rejected" },
                                                        { label: "Pending", value: "pending" },
                                                    ].map((item) => ({
                                                        label: item.label,
                                                        value: item.value,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div> */}
                    <div className="w-full lg:w-auto ">
                        <TextInput
                            name={"search"}
                            value={search}
                            type={"text"}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-full lg:!w-[350px] lg:!w-[428px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>

                    <div className=" w-full lg:w-auto mt-4 kg:mt-0">
                        <Button
                            btnText={"Assign a shipment"}
                            type={"button"}
                            btnClassname="!py-3 !px-4  lg:mx-0"
                            onClick={() => setShowShipment(true)}
                        />
                    </div>
                </div>
                {allShipments?.length > 0 ? (
                    <div>
                        <div className="overflow-x-scroll hidden lg:block" onScroll={handleScroll}>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                            />
                        </div>

                        <div className="flex gap-[6px]  flex-col mt-6 mb-16 lg:mb-44 lg:hidden ">
                            {mobileTable()}
                        </div>

                        {fetchingShipments && loadMoreShipments && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {shipmentPagination?.current === shipmentPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {search ? "No shipments found" : "No shipments yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {search
                                    ? "No shipments found with this search query"
                                    : "Your list of shipments will be displayed here"}
                            </p>
                        </div>
                    </div>
                )}

                <ModalContainer
                    open={shipmentShow}
                    showCloseIcon={false}
                    tailwindClassName="w-[38.4%] max-lg:w-1/2 max-sm:w-[95%]"
                    closeModal={() => {
                        setShowShipment(false);
                    }}
                >
                    <DashboardModalAction
                        contentTitle="Assign a shipment"
                        content="Assign a specific shipment to your team member"
                        btnContent="Assign shipment"
                        btnContentTwo={"Close"}
                        // imageStr="/images/greenModal.png"
                        onClose={() => {
                            setShowShipment(false);
                        }}
                    />
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default Shipment;
