import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import { MultipleDropdowns } from "components/common/MultipleDropdown";
import { SideDrawer } from "components/common/SideDrawer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const DocumentUpload = () => {
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [createFolder, setCreateFolder] = useState(false);
    const [showCollaboration, setShowCollaboration] = useState(false);
    const [addCollaboration, setAddCollaboration] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [email, setEmail] = useState("");
    const [showView, setShowView] = useState("list");
    const navigate = useNavigate();
    const tableHeader = [
        { title: "Document", widthClass: "xl:w-[25%] w-[30%]" },
        { title: "Shared by", widthClass: "w-[25%]" },
        { title: "Collaborators", widthClass: "w-[20%]" },
        { title: "Last updated", widthClass: "w-[20%]" },
        { title: "", widthClass: "20%" },
    ];

    const onAddCollaborator = () => {
        setAddCollaboration(true);
        setShowCollaboration(false);
    };

    const toggleDrawerButton = document.getElementById("toggleDrawer");
    const sidebar = document.querySelector(".fixed");

    toggleDrawerButton?.addEventListener("click", () => {
        sidebar?.classList.toggle("-translate-x-full");
    });
    const options = [
        {
            label: "View document",
            onClick: () => {
                console.log("Option 1 clicked");
            },
            children: [],
        },
        {
            label: "Add to folder",

            children: [
                {
                    label: "New folder",
                    onClick: () => {
                        console.log("Option 2.1.1 clicked");
                    },
                    children: [],
                },
                {
                    label: "Existing folder",
                    onClick: () => {
                        console.log("Option 2.1.2 clicked");
                    },
                    children: [
                        {
                            label: "Alienware Project Folder 1",
                            onClick: () => {
                                console.log("Option 2.1.1 clicked");
                            },
                            children: [],
                        },
                        {
                            label: "Alienware Project Folder 2",
                            onClick: () => {
                                console.log("Option 2.1.2 clicked");
                            },
                            children: [],
                        },
                    ],
                },
            ],
            onClick: () => {
                console.log("eeee");
            },
        },
        // {
        //     label: "Create new folder",
        //     onClick: () => {
        //         console.log("Option 1.2 clicked");
        //     },
        //     children: [],
        // },
        {
            label: "View activity log",
            onClick: () => {
                setShowActivity(true);
            },
            children: [],
        },
        {
            label: "Share document",
            onClick: () => {
                console.log("Option 1.4 clicked");
            },
            children: [],
        },
        {
            label: "Download document",
            onClick: () => {
                console.log("Option 1.5 clicked");
            },
            children: [],
        },
        {
            label: "Delete document",
            onClick: () => {
                console.log("Option 1.6 clicked");
            },
            children: [],
        },
    ];
    const tableBody = [1, 1]?.map(() => [
        {
            content: (
                <div className={`py-3 flex items-center space-x-3 pr-3 text-sm text-slate-700`}>
                    <img
                        src={
                            "https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/undefined_-_Imgur_c37dzz.jpg"
                        }
                        width={15}
                        height={15}
                        alt="vendor logo"
                    />
                    <div className="">
                        <span className="text-slate-700 font-rocGroteskMedium text-[14px]">
                            Insurance Certificates
                        </span>
                    </div>
                </div>
            ),
            cellClickAction: () => {
                navigate("");
            },
        },
        {
            content: (
                <div className={`py-3 pr-3 text-sm text-slate-700`}>
                    <div className="flex items-center gap-2">
                        <div className="h-[28px] w-[28px] border-[1.5px] border-white bg-red-500 rounded-full"></div>

                        <span className="text-slate-700 font-rocGroteskMedium text-[14px]">
                            Emily Kate
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className="flex items-center cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowCollaboration(true);
                    }}
                >
                    <div className="relative pb-8">
                        <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute left-4"></div>
                        <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[30px]"></div>
                        <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[42px]"></div>
                        <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[55px]">
                            <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                +50
                            </p>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 text-sm text-slate-700 font-rocGroteskMedium`}>
                    <div className="flex items-center gap-3">
                        <span>April 17, 2023</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <MultipleDropdowns options={options} />
                </div>
            ),
        },
    ]);

    return (
        <div className="mt-10 px-4">
            <div className="my-12 flex items-center justify-between">
                <div className="w-fit">
                    <Dropdown
                        dropdown={
                            <Button
                                btnCustomContent={
                                    <div className="flex items-center gap-1">
                                        <i className="ri-add-fill text-black text-[20px]"></i>
                                        <span className="text-sm font-rocGroteskMedium text-black">
                                            Add document
                                        </span>
                                    </div>
                                }
                                btnClassname={"!w-fit !px-4 !py-2"}
                                type={"submit"}
                                isLoading={false}
                            />
                        }
                        dropdownClassName={"!w-full"}
                        dropdownContainerClasses={`-top-40 !left-[150px] !mb-20 border-slate-200 !border-none rounded !max-h-fit !z-[9999]`}
                        dropdownItemsClasses={`!text-gm-50`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Upload document",

                                value: "empty1",
                                action: () => {
                                    console.log("action");
                                },
                            },
                            {
                                label: "Upload folder",

                                value: "empty2",
                                action: () => {
                                    console.log("action");
                                },
                            },
                            {
                                label: "Create new folder",

                                value: "empty3",
                                action: () => {
                                    setCreateFolder(true);
                                },
                            },
                        ]}
                    />
                </div>
                <div>
                    <div className="w-[410px]">
                        <TextInput
                            name={"search"}
                            // value={search}
                            type={"text"}
                            inputPlaceholder={"Search document"}
                            inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                            // onChange={handleSearch}
                            // onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-full !w-[410px] !h-10 !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>
                    {/* <Dropdown
                        dropdown={
                            <>
                                {showView === "list" ? (
                                    <div className="flex items-center space-x-1 text-gm-50">
                                        <i className="ri-list-radio text-[20px] "></i>
                                        <p className="text-[14px] font-rocGroteskMedium">
                                            List view
                                        </p>
                                        <i className="ri-arrow-down-s-line text-[20px]  "></i>
                                    </div>
                                ) : (
                                    <div className="flex items-center space-x-1 text-gm-50">
                                        <i className="ri-layout-grid-line text-[20px] "></i>
                                        <p className="text-[14px] font-rocGroteskMedium">
                                            Grid view
                                        </p>
                                        <i className="ri-arrow-down-s-line text-[20px]  "></i>
                                    </div>
                                )}
                            </>
                        }
                        dropdownClassName={"!w-full !mb-20 !-mt-24"}
                        dropdownContainerClasses={`drop-shadow -top-40 -left-10 !mb-20 border-slate-200 border rounded !max-h-fit`}
                        dropdownItemsClasses={`!text-gm-50`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                icon: <i className="ri-list-radio text-[16px] text-gm-50"></i>,
                                label: "List view",

                                value: "empty4",
                                action: () => {
                                    setShowView("list");
                                },
                            },
                            {
                                icon: (
                                    <i className="ri-layout-grid-line text-[16px] text-gm-50"></i>
                                ),
                                label: "Grid view",

                                value: "empty5",
                                action: () => {
                                    setShowView("grid");
                                },
                            },
                        ]}
                    /> */}
                </div>
            </div>
            <div>
                {showView === "list" && (
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        isCellBordered={false}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        headerContainerClass="!bg-slate-100"
                        headerItemClass="font-rocGroteskMedium "
                        tableClass="!border !border-slate-200"
                        bodyItemClass="!border-slate-200"
                    />
                )}

                {showView === "grid" && (
                    <div className="gap-6 gap-x-3 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        containerClassName={
                                            "!right-0 !left-44 !shadow-faintShadow rounded !w-[200px]"
                                        }
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative space-y-8 w-[240px] px-4 py-5 h-[200px] bg-slate-50 border-slate-100 rounded-[4px] border">
                            <div className="h-6 w-6 rounded-[4px] border"></div>
                            <p className="font-rocGroteskMedium text-slate-700 text-[14px]">
                                Insurance Certificates
                            </p>
                            <div className="flex  justify-between items-center ">
                                <div className="relative pb-8">
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[15px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[28px]"></div>
                                    <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[42px]">
                                        <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                            +50
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`h-full absolute whitespace-nowrap left-[180px] top-32 border-slate-100 text-sm text-slate-700 `}
                                >
                                    <MultipleDropdowns
                                        childClassName={"!right-0 !left-44"}
                                        className={"z-10 "}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showActivity && (
                    <SideDrawer
                        teams
                        title={"Insurance Certificates"}
                        onClick={() => setShowActivity(false)}
                    />
                )}
                {/* <ModalContainer
                    open={showCollaboration}
                    tailwindClassName="w-[53%] max-w-[32%]"
                    closeModal={() => {
                        setShowCollaboration(false);
                    }}
                >
                    <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                        <div className="px-8 pt-3">
                            <div className="flex  justify-between  mb-8">
                                <p className="text-2xl mb-1 font-rocGroteskMedium text-g-75">
                                    Collaborators (9)
                                </p>
                                <span
                                    className="material-icons text-gm-45 text-2xl cursor-pointer font-medium"
                                    onClick={() => setShowCollaboration(false)}
                                >
                                    close
                                </span>
                            </div>
                            <div className="space-y-3">
                                <div
                                    className={` border-b border-t py-2 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                            <img
                                                src={
                                                    "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                }
                                                width={20}
                                                height={20}
                                                alt="google logo"
                                            />
                                        </div>
                                        <span>Augustine asiuwhu</span>
                                    </div>

                                    <div className=" -mt-3">
                                        <SelectInput
                                            value={"viewer"}
                                            // filter={"admin"}
                                            name="status"
                                            optionItemClassName={"h-full"}
                                            //  placeholder={"Select a shipment"}
                                            //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                            inputClassName={
                                                "!font-rocGroteskMedium !text-slate-700"
                                            }
                                            className={"!border-0"}
                                            iconClassName={"!text-slate-700"}
                                            isRequired={false}
                                            dropdownOptions={[
                                                {
                                                    label: "Editor",
                                                    value: "editor",
                                                },
                                                {
                                                    label: "Viewer",
                                                    value: "viewer",
                                                },
                                                { label: "Commentor", value: "commentor" },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                            <img
                                                src={
                                                    "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                }
                                                width={20}
                                                height={20}
                                                alt="google logo"
                                            />
                                        </div>
                                        <span>Augustine asiuwhu</span>
                                    </div>

                                    <div className=" -mt-3">
                                        <SelectInput
                                            value={"viewer"}
                                            // filter={"admin"}
                                            name="status"
                                            optionItemClassName={"h-full"}
                                            //  placeholder={"Select a shipment"}
                                            //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                            inputClassName={
                                                "!font-rocGroteskMedium !text-slate-700"
                                            }
                                            className={"!border-0"}
                                            iconClassName={"!text-slate-700"}
                                            isRequired={false}
                                            dropdownOptions={[
                                                {
                                                    label: "Editor",
                                                    value: "editor",
                                                },
                                                {
                                                    label: "Viewer",
                                                    value: "viewer",
                                                },
                                                { label: "Commentor", value: "commentor" },
                                            ].map((item) => ({
                                                label: item.label,
                                                value: item.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                            <img
                                                src={
                                                    "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                }
                                                width={20}
                                                height={20}
                                                alt="google logo"
                                            />
                                        </div>
                                        <span>Augustine asiuwhu</span>
                                    </div>

                                    <div className=" -mt-3">
                                        <SelectInput
                                            value={"viewer"}
                                            // filter={"admin"}
                                            name="status"
                                            optionItemClassName={"h-full"}
                                            //  placeholder={"Select a shipment"}
                                            //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                            inputClassName={
                                                "!font-rocGroteskMedium !text-slate-700"
                                            }
                                            className={"!border-0"}
                                            iconClassName={"!text-slate-700"}
                                            isRequired={false}
                                            dropdownOptions={[
                                                {
                                                    label: "Editor",
                                                    value: "editor",
                                                },
                                                {
                                                    label: "Viewer",
                                                    value: "viewer",
                                                },
                                                { label: "Commentor", value: "commentor" },
                                            ].map((item) => ({
                                                label: item.label,
                                                value: item.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                            <img
                                                src={
                                                    "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                }
                                                width={20}
                                                height={20}
                                                alt="google logo"
                                            />
                                        </div>
                                        <span>Augustine asiuwhu</span>
                                    </div>

                                    <div className=" -mt-3">
                                        <SelectInput
                                            value={"viewer"}
                                            // filter={"admin"}
                                            name="status"
                                            optionItemClassName={"h-full"}
                                            //  placeholder={"Select a shipment"}
                                            //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                            inputClassName={
                                                "!font-rocGroteskMedium !text-slate-700"
                                            }
                                            className={"!border-0"}
                                            iconClassName={"!text-slate-700"}
                                            isRequired={false}
                                            dropdownOptions={[
                                                {
                                                    label: "Editor",
                                                    value: "editor",
                                                },
                                                {
                                                    label: "Viewer",
                                                    value: "viewer",
                                                },
                                                { label: "Commentor", value: "commentor" },
                                            ].map((item) => ({
                                                label: item.label,
                                                value: item.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="px-5 mt-5 cursor-pointer space-x-1 text-g-50 text-[16px] font-rocGroteskMedium flex items-center"
                                onClick={onAddCollaborator}
                            >
                                <i className="ri-add-line text-[18px]"></i>
                                <p>Add a collaborator</p>
                            </div>
                        </div>
                    </div>
                </ModalContainer> */}
                <ModalContainer
                    open={addCollaboration}
                    showCloseIcon={false}
                    tailwindClassName=" px-5 w-[533px] "
                    closeModal={() => {
                        setAddCollaboration(false);
                        setEmail("");
                    }}
                >
                    <div className="bg-white shadow-cardShadow relative rounded-[20px]">
                        <div className="p-10 flex flex-col items-end">
                            <span
                                className="material-icons text-gm-45 text-2xl cursor-pointer font-medium"
                                onClick={() => setAddCollaboration(false)}
                            >
                                close
                            </span>
                            <div className="text-center mt-2 w-full mb-[46px]">
                                <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                    Add collaborator
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-gm-40">
                                    Add collaborators to your document and select access level
                                </p>
                            </div>
                            <form className="w-full" onSubmit={() => {}}>
                                <div className="mb-10 flex flex-col space-y-2 sm:space-y-0 sm:flex-row items-center w-full">
                                    <div className="lg:w-[60%] w-full">
                                        <TextInput
                                            value={email}
                                            name={"email"}
                                            type={"email"}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder={"Enter email address"}
                                            required={true}
                                        />
                                    </div>
                                    <div className="lg:w-[40%] w-full">
                                        <SelectInput
                                            value={""}
                                            name="status"
                                            placeholder={"Access level"}
                                            // handleChange={(name, value) =>
                                            //     handleChange({ target: { name, value } })
                                            // }
                                            isRequired={false}
                                            dropdownOptions={[
                                                {
                                                    label: "Accepted",
                                                    value: "accepted",
                                                },
                                                {
                                                    label: "Rejected",
                                                    value: "rejected",
                                                },
                                                { label: "Pending", value: "pending" },
                                            ].map((item) => ({
                                                label: item.label,
                                                value: item.value,
                                            }))}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-3">
                                    <div
                                        className={` border-b border-t py-2 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                    >
                                        <div className="flex items-center gap-3">
                                            <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                                <img
                                                    src={
                                                        "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="google logo"
                                                />
                                            </div>
                                            <span>Augustine asiuwhu</span>
                                        </div>

                                        <div className=" -mt-3">
                                            <SelectInput
                                                value={"viewer"}
                                                // filter={"admin"}
                                                name="status"
                                                optionItemClassName={"h-full"}
                                                //  placeholder={"Select a shipment"}
                                                //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                                inputClassName={
                                                    "!font-rocGroteskMedium !text-slate-700"
                                                }
                                                className={"!border-0"}
                                                iconClassName={"!text-slate-700"}
                                                isRequired={false}
                                                dropdownOptions={[
                                                    {
                                                        label: "Editor",
                                                        value: "editor",
                                                    },
                                                    {
                                                        label: "Viewer",
                                                        value: "viewer",
                                                    },
                                                    { label: "Commentor", value: "commentor" },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                    >
                                        <div className="flex items-center gap-3">
                                            <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                                <img
                                                    src={
                                                        "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="google logo"
                                                />
                                            </div>
                                            <span>Augustine asiuwhu</span>
                                        </div>

                                        <div className=" -mt-3">
                                            <SelectInput
                                                value={"viewer"}
                                                // filter={"admin"}
                                                name="status"
                                                optionItemClassName={"h-full"}
                                                //  placeholder={"Select a shipment"}
                                                //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                                inputClassName={
                                                    "!font-rocGroteskMedium !text-slate-700"
                                                }
                                                className={"!border-0"}
                                                iconClassName={"!text-slate-700"}
                                                isRequired={false}
                                                dropdownOptions={[
                                                    {
                                                        label: "Editor",
                                                        value: "editor",
                                                    },
                                                    {
                                                        label: "Viewer",
                                                        value: "viewer",
                                                    },
                                                    { label: "Commentor", value: "commentor" },
                                                ].map((item) => ({
                                                    label: item.label,
                                                    value: item.value,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                    >
                                        <div className="flex items-center gap-3">
                                            <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                                <img
                                                    src={
                                                        "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="google logo"
                                                />
                                            </div>
                                            <span>Augustine asiuwhu</span>
                                        </div>

                                        <div className=" -mt-3">
                                            <SelectInput
                                                value={"viewer"}
                                                // filter={"admin"}
                                                name="status"
                                                optionItemClassName={"h-full"}
                                                //  placeholder={"Select a shipment"}
                                                //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                                inputClassName={
                                                    "!font-rocGroteskMedium !text-slate-700"
                                                }
                                                className={"!border-0"}
                                                iconClassName={"!text-slate-700"}
                                                isRequired={false}
                                                dropdownOptions={[
                                                    {
                                                        label: "Editor",
                                                        value: "editor",
                                                    },
                                                    {
                                                        label: "Viewer",
                                                        value: "viewer",
                                                    },
                                                    { label: "Commentor", value: "commentor" },
                                                ].map((item) => ({
                                                    label: item.label,
                                                    value: item.value,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={` border-b  py-1 flex whitespace-nowrap justify-between items-center font-rocGroteskMedium border-slate-100 text-sm text-slate-700 `}
                                    >
                                        <div className="flex items-center gap-3">
                                            <div className="h-[24px] w-[24px] flex items-center rounded-full">
                                                <img
                                                    src={
                                                        "https://storage.googleapis.com/heysynth_files/synth_webapp/Web%20App/google_logo_u0iqig.svg"
                                                    }
                                                    width={20}
                                                    height={20}
                                                    alt="google logo"
                                                />
                                            </div>
                                            <span>Augustine asiuwhu</span>
                                        </div>

                                        <div className=" -mt-3">
                                            <SelectInput
                                                value={"viewer"}
                                                // filter={"admin"}
                                                name="status"
                                                optionItemClassName={"h-full"}
                                                //  placeholder={"Select a shipment"}
                                                //  handleChange={(name, value) => handleChange({ target: { name, value } })}
                                                inputClassName={
                                                    "!font-rocGroteskMedium !text-slate-700"
                                                }
                                                className={"!border-0"}
                                                iconClassName={"!text-slate-700"}
                                                isRequired={false}
                                                dropdownOptions={[
                                                    {
                                                        label: "Editor",
                                                        value: "editor",
                                                    },
                                                    {
                                                        label: "Viewer",
                                                        value: "viewer",
                                                    },
                                                    { label: "Commentor", value: "commentor" },
                                                ].map((item) => ({
                                                    label: item.label,
                                                    value: item.value,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mt-4">
                                    <Button
                                        btnText={"Add collaborator"}
                                        type={"submit"}
                                        isLoading={false}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalContainer>
            </div>

            <ModalContainer
                open={createFolder}
                showCloseIcon={false}
                modalClassName={"py-10"}
                tailwindClassName="w-[383px]"
                closeModal={() => {
                    setCreateFolder(false);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setCreateFolder(false);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center px-8">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-2xl mb-11 font-rocGroteskMedium text-g-75">
                                        Create New Folder
                                    </p>
                                </div>
                            </div>
                            <form onSubmit={() => console.log("form")} className="w-full  ">
                                <div className={`flex  flex-col max-h-[225px] gap-4 w-full`}>
                                    <TextInput
                                        value={""}
                                        name="folder"
                                        type="email"
                                        // onChange={(e) => handleChange(e, index)}
                                        placeholder="Enter folder title"
                                        required={true}
                                        inputContainerClassname="!border-[#D0D4D7] !border-[0.5px] rounded"
                                        inputClassName="!text-gm-50 !font-bold"
                                    />
                                </div>

                                <div className="w-full mt-10">
                                    <Button
                                        btnText={"Create Folder"}
                                        // isLoading={addingTeamMember}
                                        type={"submit"}
                                        btnClassname="!py-4 !px-2"
                                        btnTextClassName="!text-md"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};
