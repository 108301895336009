import ModalContainer from "components/common/ModalContainer";
import React from "react";
import { Link } from "react-router-dom";

interface AuthFrameProps {
    children: React.ReactNode;
}

const AuthFrame = ({ children }: AuthFrameProps) => {
    const [playVideo, setPlayVideo] = React.useState(false);
    return (
        <>
            <div className="w-full h-screen relative bg-[#FFFBEB] flex justify-center">
                {/* <div className="w-[40%] max-lg:hidden h-full bg-cover bg-center xl:bg-left-top bg-no-repeat bg-[url('https://storage.googleapis.com/heysynth_files/synth_webapp/login_vmd4rf.svg')] relative ">
                    <div className="px-12 pt-[5.5rem] absolute bottom-12">
                        <p className="text-[64px] leading-[70px] text-white font-rocGroteskMedium ">
                            Welcome Back
                        </p>
                        <p className="text-2xl text-white mb-12 !font-rocGroteskLight">
                            10x your CPG business with{" "}
                            <span className="text-y-10 font-rocGroteskMedium">synth’s AI</span>
                        </p>
                        <Link to="/" className="w-fit ">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/synthLogo_ekkisb.svg"
                                alt="synthally logo"
                                width={157}
                                height={29}
                            />
                        </Link>
                    </div>
                </div> */}
                {/* <div className="absolute inset-0">
                    <div className="absolute bottom-0 right-0 w-1/2 h-1/2 bg-[url('https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_1_kmhgmb.svg')] bg-cover bg-no-repeat"></div>
                    <div className="absolute top-0 left-0 w-1/2 h-1/2 bg-[url('https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2_jbwtqy.svg')] bg-cover bg-no-repeat"></div>
                </div> */}
                <div className="md:w-[45%] w-[90%] relative z-10   flex items-center justify-center ">
                    {children}
                </div>
            </div>

            {/* {playVideo && (
                <ModalContainer open={playVideo} closeModal={() => setPlayVideo(false)}>
                    <div className=" h-[786px] max-lg:w-full max-lg:px-2 max-sm:h-[265px] max-lg:h-[500px] rounded-xl flex justify-center items-center ">
                        <video
                            poster="https://storage.googleapis.com/heysynth_files/Tradeally_2.0/Landing%20page/demo_video_preview_psvkng.svg"
                            src="https://storage.googleapis.com/heysynth_files/Tradeally_2.0/Landing%20page/YC_Demo_No_sound_f7zhtk.mp4"
                            playsInline
                            controls={true}
                            autoPlay
                            controlsList="nodownload"
                            muted
                            className="z-10 cursor-pointer w-[1000px] h-[598px] max-lg:w-full max-lg:h-full"
                        />
                    </div>
                </ModalContainer>
            )} */}
        </>
    );
};

export default AuthFrame;
