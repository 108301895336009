import { useEffect, useState } from "react";
import { inventoryActions } from "../../redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useParams } from "react-router-dom";

const useGetGlobalProductList = (scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [productDetails, setProductDetails] = useState<{ [key: string]: any }>({});
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const {
        fetchingGlobalProductList,
        fetchedGlobalProductListSuccess,
        fetchedGlobalProductListFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (productId) {
            dispatch(inventoryActions.fetchGlobalProductList(productId, 10, updatedPage));
        }
    }, [dispatch, updatedPage, productId]);

    useEffect(() => {
        if (Boolean(fetchedGlobalProductListSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedGlobalProductListSuccess?.product?.variants], "_id")
                    : uniqBy(
                          [...prev, ...fetchedGlobalProductListSuccess?.product?.variants],
                          "_id"
                      )
            );
            setProductDetails(fetchedGlobalProductListSuccess?.product);
            setPagination({
                currentPage: fetchedGlobalProductListSuccess.pagination?.current,
                noOfPages: fetchedGlobalProductListSuccess.pagination?.number_of_pages,
            });
            setLoading(false);
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchGlobalProductList());
        }
    }, [dispatch, fetchedGlobalProductListSuccess, updatedPage]);

    return {
        isFetching: loading,
        error: fetchedGlobalProductListFailure,
        productList,
        productDetails,
        setProductList,
        setUpdatedPage,
        pagination,
        setPagination,
        isLoadingMore,
        updatedPage,
    };
};

export default useGetGlobalProductList;
