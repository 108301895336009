import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import { getFromStorage, onScroll } from "helpers";
import { debounce, truncate, uniqBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { authActions } from "redux/Ldb/actions";
import { accessTypes } from "variables";
import VendorAvatar from "components/common/VendorAvatar";
import DashboardNav from "components/common/Navbar/DashboardNav";

type teamMemberProp = {
    teamSearch?: string;
};
const TeamMembers = ({ teamSearch }: teamMemberProp) => {
    const limit = 20;
    const dispatch = useAppDispatch();
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const [deleteId, setDeleteId] = useState("");
    const [updateId, setUpdateId] = useState("");
    const [action, setAction] = useState("");
    // const [teamLimit, setTeamLimit] = useState(limit);
    const [page, setPage] = useState<number>(1);
    const [teamList, setTeamList] = useState([]);
    const [loadMoreTeam, setLoadMoreTeam] = useState(false);
    const [deleteMember, setDeleteMember] = useState(false);
    const [showMoreId, setShowMoreId] = useState("");
    const [viewMember, setViewMember] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [accessLevel, setAccessLevel] = useState<boolean>(false);
    const [selectedProfile, setSelectedProfile] = useState<{ [key: string]: any }>();
    const [updated, setUpdateTrue] = useState<boolean>(false);
    const [newRole, setNewRole] = useState<string>("");
    const [debounceTeamSearch, setDebounceTeamSearch] = useState<string>("");
    const [teamPagination, setTeamPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [roles, setRoles] = useState([]);
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });

    const dashboardContent = document.getElementById("dashboardContent");
    const active = true;
    const {
        deleteTeamMember,
        updateTeamMemberSuccess,
        deleteTeamMemberSuccess,
        fetchingTeamMember,
        fetchedTeamMemberSuccess,
        fetchingRoles,
        fetchedRolesSuccess,
    } = useAppSelector((state) => state.auth);

    useEffect(() => {
        const debouncedSearch = debounce(() => {
            setDebounceTeamSearch(teamSearch);
        }, 1000);
        debouncedSearch();
    }, [teamSearch]);
    const tableHeader = [
        {
            title: `Team members (${teamList?.length})`,
            widthClass: "w-[25%]",
        },
        { title: "Role", widthClass: "w-[25%]" },
        { title: "Access level", widthClass: "w-[23%]" },
        { title: "Last active", widthClass: "w-[19%]" },
        { title: "Status", widthClass: "w-[12%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    useEffect(() => {
        dispatch(authActions.getRoles(20, userProfile?.token, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                teamPagination?.current as number,
                teamPagination?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMoreTeam(true);
                }
            ),
        [dashboardContent, teamPagination]
    );

    useEffect(() => {
        dispatch(authActions.getTeamMembers(10, debounceTeamSearch, page));
    }, [dispatch, debounceTeamSearch, page]);

    useEffect(() => {
        if (debounceTeamSearch) {
            setPage(1);
        }
    }, [debounceTeamSearch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList((prev) =>
                fetchedTeamMemberSuccess?.pagination?.current > 1
                    ? uniqBy([...prev, ...fetchedTeamMemberSuccess?.teamMembers], "_id")
                    : [...fetchedTeamMemberSuccess?.teamMembers]
            );
            setTeamPagination({
                current: fetchedTeamMemberSuccess?.pagination?.current,
                number_of_pages: fetchedTeamMemberSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    function getLabelByValue(value) {
        const accessType = accessTypes.find((item) => item.value === value);
        return accessType ? accessType.label : "Label not found";
    }

    function getLabelsAndMore(array) {
        const displayedLabels = [];
        const maxItemsToDisplay = 1;

        for (let i = 0; i < array.length && i < maxItemsToDisplay; i++) {
            const accessType = accessTypes.find((item) => item.value === array[i]);
            if (accessType) {
                displayedLabels.push(accessType.label);
            }
        }

        if (array.length > maxItemsToDisplay) {
            displayedLabels.push(`${array.length - maxItemsToDisplay} more`);
        }

        return displayedLabels.join(", ");
    }

    const handleChange = (value, teamId) => {
        const updatedTeamList = teamList.map((team) => {
            if (team._id === teamId) {
                return {
                    ...team,
                    role: value,
                };
            }
            return team;
        });
        setTeamList(updatedTeamList);

        const body = {
            memberId: teamId,
            role: value,
        };
        if (teamId) {
            dispatch(authActions.updateTeamMember(body, userProfile?.token));
        }
    };

    const onDeleteMember = () => {
        if (deleteId) {
            dispatch(authActions.deleteTeamMember(deleteId, userProfile?.token));
        }
    };

    useEffect(() => {
        const body = {
            memberId: updateId,
            active: action === "enable" ? true : false,
        };
        if (updateId) {
            dispatch(authActions.updateTeamMember(body, userProfile?.token));
        }
    }, [updateId, userProfile, action]);

    useEffect(() => {
        if (Boolean(updateTeamMemberSuccess)) {
            setUpdateId("");
            dispatch(authActions.getTeamMembers(limit));
            dispatch(authActions.resetUpdateTeamMemberSuccess());
            setSelectedProfile({
                ...selectedProfile,
                role: newRole,
            });
        }
    }, [dispatch, updateTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(deleteTeamMemberSuccess)) {
            setDeleteId("");
            setDeleteMember(false);
            dispatch(authActions.getTeamMembers(limit));
            dispatch(authActions.resetDeleteTeamMemberSuccess());
        }
    }, [deleteTeamMemberSuccess]);

    const getRoleLabel = (role) => {
        const roleObject = roles.find((r) => r.role === role);
        const label = roleObject ? roleObject.label : role;
        return label.charAt(0).toUpperCase() + label.slice(1);
    };

    const optionDropdown = (team) => {
        return (
            <Dropdown
                dropdown={<i className="ri-more-2-fill text-lg !text-gm-50 lg:!text-slate-500"></i>}
                dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                dropdownContainerClasses={
                    "shadow-cardShadow-2 !left-[-170px] lg:!left-[-150px] border border-slate-300 lg:border-0 rounded !max-h-fit"
                }
                dropdownItemsClasses={``}
                name={"faq-dropdown"}
                dropdownOptions={[
                    {
                        label: `${team?.active ? "Disable" : "Enable"} team member`,
                        value: "1",
                        action: () => {
                            setUpdateId(team?._id);
                            setAction(`${team?.active ? "disable" : "enable"}`);
                        },
                    },
                    {
                        label: "Remove team member",
                        value: "2",
                        action: () => {
                            setDeleteMember(true);
                            setDeleteId(team?._id);
                        },
                    },
                ]}
            />
        );
    };

    const tableBody = teamList?.map((team, idx) => [
        {
            content: (
                <div
                    key={team?._id}
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }  `}
                >
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                team?.profile?.avatar
                                    ? team?.profile?.avatar
                                    : "https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                            }
                            alt="profile image"
                            className="rounded-full w-6 h-6"
                        />
                        <span>
                            {truncate(team?.fullName ? team?.fullName : team?.email, {
                                length: 17,
                            })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <>
                    {userProfile?.user?.role === "owner" || userProfile?.user?.role === "admin" ? (
                        <div
                            className={` w-[200px] -ml-4 h-full !font-rocGroteskMedium border-slate-100 text-sm  ${
                                team?.active ? "text-slate-900 " : "text-slate-500 "
                            }`}
                        >
                            {userProfile?.user?.email !== team?.email ? (
                                <SelectInput
                                    value={userProfile?.user?.email !== team?.email && team?.role}
                                    name="role"
                                    handleInputChange={debounce((evt) => {
                                        setDebouncedSearch({
                                            search: evt.target.value,
                                        });
                                    }, 800)}
                                    disabled={userProfile?.user?.email === team?.email}
                                    searchLoading={fetchingRoles}
                                    optionItemClassName={"h-full"}
                                    handleChange={(name, value) => handleChange(value, team?._id)}
                                    inputClassName={`!!font-rocGroteskRegular  ${
                                        team?.active ? "!text-slate-800 " : "!text-slate-500 "
                                    }`}
                                    className={"!border-0 "}
                                    iconClassName={` ${
                                        team?.active ? "text-slate-800 " : "text-slate-500 "
                                    }`}
                                    isRequired={false}
                                    dropdownOptions={roles?.map((item) => ({
                                        label: item?.label,
                                        value: item?.role,
                                    }))}
                                />
                            ) : (
                                <p className="pl-4">Owner</p>
                            )}
                        </div>
                    ) : (
                        <div
                            className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                                team?.active ? "text-slate-800 " : "text-slate-500 "
                            } `}
                        >
                            <div className="flex items-center gap-3">
                                <p>{getRoleLabel(team?.role)}</p>
                            </div>
                        </div>
                    )}
                </>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }`}
                >
                    {team?.permission.length > 0 ? (
                        <div className="flex items-center gap-3">
                            {team?.permission.includes("all_access") && (
                                <p>{getLabelByValue("all_access")}</p>
                            )}
                            {!team?.permission.includes("all_access") && (
                                <p>{getLabelsAndMore(team?.permission)}</p>
                            )}
                        </div>
                    ) : (
                        <div className="flex items-center gap-3">
                            {team?.permission.length === 0 && <p>----</p>}
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }`}
                >
                    <div className="flex items-center gap-3">
                        <p>{moment(team?.updated_at).format("MMM Do, YYYY")}</p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2.5 px-1   !font-rocGroteskMedium flex items-center justify-center h-full 
                    border-slate-100 text-sm text-slate-800 rounded gap-2 !w-[90px] ${
                        team?.active ? "bg-[rgba(101,98,229,0.2)]" : "bg-[rgba(190,197,206,0.19)]"
                    }`}
                >
                    <div
                        className={`h-2 w-2 ${
                            team?.active ? "bg-p-50" : "bg-[#BEC5CE]"
                        } rounded-full`}
                    ></div>
                    <p className={` ${team?.active ? "text-p-50" : "text-[#BEC5CE]"}`}>
                        {team?.active ? "Active" : "Inactive"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px]  flex justify-center items-center  border-slate-100 text-sm text-slate-800 `}
                >
                    {userProfile?.user?.email !== team?.email && (
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 !left-[-150px] ${
                                teamList?.length >= 9 &&
                                idx >= teamList?.length - 2 &&
                                "!top-[-105px]"
                            } border-0 rounded !max-h-fit`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: `View`,
                                    value: "1",
                                    action: () => {
                                        setViewMember(true);
                                        setSelectedProfile(teamList[idx]);
                                    },
                                },
                                {
                                    label: `${team?.active ? "Disable" : "Enable"} team member`,
                                    value: "2",
                                    action: () => {
                                        setUpdateId(team?._id);
                                        setAction(`${team?.active ? "disable" : "enable"}`);
                                    },
                                },
                                {
                                    label: "Remove team member",
                                    value: "3",
                                    action: () => {
                                        setDeleteMember(true);
                                        setDeleteId(team?._id);
                                    },
                                },
                            ]}
                        />
                    )}
                </div>
            ),
        },
    ]);

    const mobileTable = () => {
        return teamList?.map((team, idx) => {
            return (
                <div
                    key={team?._id}
                    className=" bg-white border-slate-200 border rounded p-4 flex items-center justify-between relative !font-rocGroteskMedium  text-sm"
                >
                    <div className="">
                        <div className="flex gap-2 items-center">
                            <img
                                src={
                                    team?.profile?.avatar
                                        ? team?.profile?.avatar
                                        : "https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                }
                                alt="profile image"
                                className="rounded-full w-6 h-6"
                            />
                            <p className="text-slate-900 text-sm border-r border-[#E0E0E0] pr-4">
                                {/* {truncate(team?.fullName ? team?.fullName : team?.email, {
                                    length: 17,
                                })} */}
                                {team?.fullName ? team?.fullName : team?.email}
                            </p>

                            <div>
                                {userProfile?.user?.role === "owner" ||
                                userProfile?.user?.role === "admin" ? (
                                    <div
                                        className={` w-[200px] -ml-4 h-full !font-rocGroteskMedium border-slate-100 text-sm  ${
                                            team?.active ? "text-slate-900 " : "text-slate-500 "
                                        }`}
                                    >
                                        {userProfile?.user?.email !== team?.email ? (
                                            <SelectInput
                                                value={
                                                    userProfile?.user?.email !== team?.email &&
                                                    team?.role
                                                }
                                                name="role"
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedSearch({
                                                        search: evt.target.value,
                                                    });
                                                }, 800)}
                                                disabled={userProfile?.user?.email === team?.email}
                                                searchLoading={fetchingRoles}
                                                optionItemClassName={"h-full"}
                                                handleChange={(name, value) =>
                                                    handleChange(value, team?._id)
                                                }
                                                inputClassName={`!font-rocGroteskMedium  ${
                                                    team?.active
                                                        ? "!text-slate-800 "
                                                        : "!text-slate-500 "
                                                }`}
                                                className={"!border-0 !pt-0 !h-auto"}
                                                iconClassName={` ${
                                                    team?.active
                                                        ? "text-slate-800 "
                                                        : "text-slate-500 "
                                                }`}
                                                isRequired={false}
                                                dropdownOptions={roles?.map((item) => ({
                                                    label: item?.label,
                                                    value: item?.role,
                                                }))}
                                            />
                                        ) : (
                                            <p className="pl-4">Owner</p>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                                            team?.active ? "text-slate-800 " : "text-slate-500 "
                                        } `}
                                    >
                                        <div className="flex items-center gap-3">
                                            <p>{getRoleLabel(team?.role)}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex flex-col">
                                <div className="flex flex-wrap gap-[11px] items-center text-[#64748B] mt-1">
                                    <div className="flex justify-between items-center">
                                        <p className="text-xs  border-r border-[#E0E0E0] pr-4">
                                            Last active{" "}
                                        </p>
                                        <p className="text-xs border-r border-slate-500 pr-4">
                                            {moment(team?.updated_at).format("MMM Do, YYYY")}
                                        </p>
                                    </div>

                                    <div className={`flex items-center  gap-2 text-xs `}>
                                        <span
                                            className={`h-2 w-2 ${
                                                team?.active ? "bg-g-50 " : "bg-[#64748B] "
                                            } rounded-full `}
                                        ></span>
                                        <p>{team?.active ? "Active" : "Inactive"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`py-[18px]  flex justify-center items-center  border-slate-100 text-sm text-slate-800 `}
                    >
                        {userProfile?.user?.email !== team?.email && (
                            <Dropdown
                                dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                                dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 !left-[-150px] ${
                                    teamList?.length >= 9 &&
                                    idx >= teamList?.length - 2 &&
                                    "!top-[-105px]"
                                } border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={``}
                                name={"faq-dropdown"}
                                dropdownOptions={[
                                    {
                                        label: `View`,
                                        value: "1",
                                        action: () => {
                                            setViewMember(true);
                                            setSelectedProfile(teamList[idx]);
                                        },
                                    },
                                    {
                                        label: `${team?.active ? "Disable" : "Enable"} team member`,
                                        value: "2",
                                        action: () => {
                                            setUpdateId(team?._id);
                                            setAction(`${team?.active ? "disable" : "enable"}`);
                                        },
                                    },
                                    {
                                        label: "Remove team member",
                                        value: "3",
                                        action: () => {
                                            setDeleteMember(true);
                                            setDeleteId(team?._id);
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            );
        });
    };

    const accessLevelValues = [
        {
            label: "Admin",
            value: "Admin",
        },
        {
            label: "Manager",
            value: "Manager",
        },
        {
            label: "Operator/logistician",
            value: "Operator/logistician",
        },
        {
            label: "Viewer/Analyst",
            value: "Viewer/Analyst",
        },
    ];
    return (
        <PageFrame
            isLoading={
                fetchingTeamMember &&
                !(Boolean(updateTeamMemberSuccess) || !Boolean(deleteTeamMemberSuccess))
            }
            containerClassName={"h-[calc(100vh-84px)] oveflow-y-hidden "}
        >
            <div className="lg:px-4">
                {teamList?.length > 0 ? (
                    <>
                        <div className="hidden lg:block">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-100"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12 !border !border-slate-200"
                                bodyItemClass="!border-slate-200"
                            />
                        </div>
                        <div className="flex gap-4  flex-col mt-6 mb-16 lg:mb-44 lg:hidden ">
                            {mobileTable()}
                        </div>
                        {loadMoreTeam && fetchingTeamMember && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {teamPagination?.current === teamPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500  justify-center text-sm !font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 !font-rocGroteskMedium text-[16px]">
                                No Team member yet
                            </p>
                            <p className="text-slate-500 !font-rocGroteskMedium text-[12px]">
                                Your list of team members will be displayed here
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <ModalContainer
                open={viewMember}
                showCloseIcon={false}
                modalClassName={"py-10 !top-0 !left-0 !items-center bg-gm-50/20"}
                tailwindClassName="w-[90%] sm:w-[500px] rounded-5"
                closeModal={() => {
                    setViewMember(false);
                }}
            >
                <div className="bg-white px-6 lg:px-10 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setViewMember(false);
                        }}
                        className="cursor-pointer flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="w-full">
                            <div className="w-full   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <div className="mb-4">
                                        {selectedProfile?.profile?.avatar &&
                                        selectedProfile?.profile?.avatar !==
                                            "www.google.com/avater,jpg" ? (
                                            <img
                                                src={selectedProfile?.profile?.avatar}
                                                alt=""
                                                className="w-[173px] h-[173px] rounded-full"
                                            />
                                        ) : (
                                            <img
                                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/defaultUser_n48r5i.svg"
                                                alt=""
                                                className=""
                                            />
                                        )}
                                    </div>

                                    <p className="text-2xl font-rocGroteskMedium text-g-75">
                                        {selectedProfile?.profile?.businessName}
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-35 mb-6">
                                        <span className="capitalize">{selectedProfile?.role}</span>
                                        {selectedProfile?.role === "owner" ||
                                        selectedProfile?.role === "admin"
                                            ? " (full access)"
                                            : ""}
                                    </p>
                                    <p className="text-sm text-gm-35 mb-4">
                                        {selectedProfile?.email}
                                    </p>
                                    <div
                                        className={`py-2 px-1   !font-rocGroteskMedium flex items-center justify-center h-full 
                    border-slate-100 text-sm text-slate-800 rounded gap-2 !w-[90px] ${
                        selectedProfile?.active
                            ? "bg-[rgba(101,98,229,0.2)]"
                            : "bg-[rgba(190,197,206,0.19)]"
                    }`}
                                    >
                                        <div
                                            className={`h-2 w-2 ${
                                                selectedProfile?.active ? "bg-p-50" : "bg-[#BEC5CE]"
                                            } rounded-full`}
                                        ></div>
                                        <p
                                            className={` ${
                                                selectedProfile?.active
                                                    ? "text-p-50"
                                                    : "text-[#BEC5CE]"
                                            }`}
                                        >
                                            {selectedProfile?.active ? "Active" : "Inactive"}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {(userProfile?.user?.role === "owner" ||
                                userProfile?.user?.role === "admin") && (
                                <div className="flex justify-between  gap-[14px] my-6 mt-14 flex-col lg:flex-row">
                                    {/* <div className=""> */}
                                    <Button
                                        onClick={() => {
                                            setDeleteMember(true);
                                            setViewMember(false);
                                            setDeleteId(selectedProfile?._id);
                                        }}
                                        btnText={"Remove member"}
                                        btnClassname={
                                            "  !bg-white !border-slate-200 !border !py-4 order-2 lg:order-1"
                                        }
                                    />
                                    {/* </div> */}

                                    {/* <div className="order-1 lg:order-2"> */}
                                    <Button
                                        onClick={() => setAccessLevel(true)}
                                        btnText={"Edit access level"}
                                        btnClassname={"!py-4 order-1 lg:order-2"}
                                    />
                                    {/* </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={accessLevel}
                showCloseIcon={false}
                modalClassName={"py-10"}
                tailwindClassName="w-[383px]"
                closeModal={() => {
                    setAccessLevel(false);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className=" px-4 flex justify-between my-4">
                        <p className="text-2xl font-rocGroteskMedium text-g-75">Access Levels</p>
                        <i
                            className="ri-close-fill text-gm-45 text-[24px] cursor-pointer"
                            onClick={() => {
                                setAccessLevel(false);
                            }}
                        ></i>
                    </div>
                    <div className="w-full flex justify-center px-5">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <form onSubmit={() => console.log("form")} className="w-full  ">
                                <div className={`flex  flex-col max-h-[225px] w-full`}>
                                    {roles?.map((option, idx) => {
                                        return (
                                            <div
                                                className="flex border-b border-slate-200 justify-between py-3 items-center"
                                                key={idx}
                                            >
                                                <p className="text-slate-900 font-rocGroteskMedium text-sm">
                                                    {option?.label}
                                                </p>
                                                {selectedProfile?.role === option?.role ? (
                                                    <i className="ri-checkbox-fill before:content-['\eb82'] text-xl mt-[-2px] text-carribean-green"></i>
                                                ) : (
                                                    <i
                                                        className="ri-checkbox-blank-line before:content-['\eb7f'] text-xl mt-[-2px] text-gm-25 cursor-pointer"
                                                        onClick={() => {
                                                            handleChange(
                                                                option?.role,
                                                                selectedProfile?._id
                                                            );
                                                            setNewRole(option?.role);
                                                        }}
                                                    ></i>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* <div className="w-full mt-8 text-center">
                                    <p
                                       
                                        className="underline cursor-pointer text-sm text-b-55 mt-2 font-rocGroteskMedium"
                                    >
                                        View roles and responsibilities
                                    </p>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={deleteMember}
                showCloseIcon={false}
                modalClassName={"py-10 !px-4 xs:!px-10"}
                closeModal={() => setDeleteMember(false)}
                tailwindClassName="h-[371px] lg:w-[572px] "
            >
                <div className="bg-white flex flex-col rounded-[20px]">
                    <div className=" mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setDeleteMember(false)}
                            className="ri-close-fill cursor-pointer text-gm-45 text-[30px]"
                        ></i>
                    </div>
                    <div className="px-6 xs:px-14">
                        <div>
                            <h6 className="text-center !font-rocGroteskMedium text-[16px]">
                                Are you sure you want to delete the member?
                            </h6>
                        </div>
                        <div className="mt-5 mb-10 space-y-4">
                            <Button
                                btnText={"Yes, delete member"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                onClick={onDeleteMember}
                                isLoading={deleteTeamMember}
                            />
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setDeleteMember(false)}
                                disabled={deleteTeamMember}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default TeamMembers;
