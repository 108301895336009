import MarketingAgentCalendar from "../../../../common/BigCalendar/MarketingAgentCalendar";
import React, { useEffect, useState } from "react";
import CampaignSimulationDetails from "../CampaignSimulationDetails";
import ModalContainer from "components/common/ModalContainer";
import Loader from "components/common/Loader";
import useGetSingleCampaign from "hooks/workforceHooks/useGetSingleCampaign";
import { useStream } from "@langchain/langgraph-sdk/react";
import { MarketingAgentState } from "types/marketingCampaignType";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import config from "config/config";

type CalendarViewProps = {
    setGridDate: React.Dispatch<any>;
    gridDate: any;
    view: string;
    setView: React.Dispatch<string>;
    data:
        | {
              [key: string]: any;
          }[]
        | {
              [key: string]: any;
          };
    handleCalendarViewDetails?: () => void;
};

const CalendarView = ({ setGridDate, gridDate, view, setView, data }: CalendarViewProps) => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState<any>([]);
    const [campaignId, setCampaignId] = useState<string>("");
    const [scenerio, setScenerio] = useState<string>("optimistic");
    const [calendarViewDetailsModal, setCalendarViewDetailsModal] = useState(false);
    const [threadValues, setThreadValues] = useState({});

    const { data: campaignDetail, isFetching: isFetchingCampaignDetail } =
        useGetSingleCampaign(campaignId);

    const copyArr = cloneDeep(campaignDetail?.aiAgentTasks);
    const getThreadId = copyArr
        ?.reverse()
        ?.find((task) => task?.simulationScenario === scenerio)?.threadId;

    const { values } = useStream<typeof MarketingAgentState.State>({
        apiUrl: config.LANGCHAIN_API_URL,
        apiKey: config.LANGCHAIN_API_KEY,
        assistantId: "cia",
        messagesKey: "messages",
        threadId: getThreadId,
    });

    useEffect(() => {
        setThreadValues({}); // Reset when threadId changes
    }, [scenerio]);

    useEffect(() => {
        if (values && Object.keys(values).length > 0) {
            setThreadValues((prevValues) => {
                // Only update if values are different
                if (JSON.stringify(prevValues) !== JSON.stringify(values)) {
                    return values;
                }
                return prevValues;
            });
        }
    }, [values]);

    useEffect(() => {
        const event = data?.map((event) => {
            return {
                id: event?._id,
                start: new Date(event?.startDate),
                end: new Date(event?.endDate),
                title: event?.campaignName,
                startDate: event?.startDate,
                endDate: event?.endDate,
                status: event?.status,
            };
        });
        setCampaigns(event);
    }, [data]);

    return (
        <div className="">
            <MarketingAgentCalendar
                setGridDate={setGridDate}
                gridDate={gridDate}
                view={view}
                setView={setView}
                campaigns={campaigns}
                setCalendarViewDetailsModal={setCalendarViewDetailsModal}
                setCampaignId={setCampaignId}
            />

            <ModalContainer
                open={calendarViewDetailsModal}
                showCloseIcon={false}
                closeModal={() => {
                    setCalendarViewDetailsModal(false);
                }}
                tailwindClassName="w-[45%]"
                modalClassName="!justify-end"
            >
                {isFetchingCampaignDetail ? (
                    <div className="bg-white h-screen flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <div className="bg-white flex h-screen flex-col overflow-y-scroll font-rocGroteskMedium">
                        <div className="flex items-center justify-between px-4 py-[22px] border-b border-slate-100 sticky top-0 bg-white z-10">
                            <div className="space-x-2 flex items-center ">
                                <i
                                    className="ri-close-fill cursor-pointer text-gm-45 text-2xl 
                        px-1"
                                    onClick={() => {
                                        setCalendarViewDetailsModal(false);
                                    }}
                                ></i>
                                <p className="text-lg font-rocGroteskMedium">
                                    “{campaignDetail?.campaignName}” Campaign
                                </p>
                            </div>

                            <div className="space-x-4 flex items-center">
                                <div
                                    className={`  ${
                                        campaignDetail?.syncStatus === "completed"
                                            ? "bg-[#F1F5FF]"
                                            : campaignDetail?.syncStatus === "inprogress"
                                            ? "bg-[##D0F4EDB2]"
                                            : campaignDetail?.syncStatus === "upcoming"
                                            ? "bg-[#FFFBE9]"
                                            : "bg-[#F8FAFC]"
                                    } flex items-center gap-2 text-sm  w-fit h-8 space-x-2 rounded-md px-2.5`}
                                >
                                    {campaignDetail?.syncStatus && (
                                        <div
                                            className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       campaignDetail?.syncStatus === "completed"
                                           ? "bg-[#0054C2]"
                                           : campaignDetail?.syncStatus === "inprogress"
                                           ? "bg-[#56B648]"
                                           : campaignDetail?.syncStatus === "upcoming"
                                           ? "bg-[#FFB902]"
                                           : "bg-[#303437]"
                                   }
                                    `}
                                        ></div>
                                    )}
                                    <span className="capitalize">
                                        {campaignDetail?.syncStatus === "inprogress"
                                            ? "In progress"
                                            : campaignDetail?.syncStatus}
                                    </span>
                                </div>

                                {/* <Dropdown
                                    dropdown={
                                        <i
                                            className="ri-more-2-fill cursor-pointer text-gm-45 text-base border-[#E2E8F0] border-[1px] rounded-[4px]
                        p-2"
                                        ></i>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
                                    dropdownItemsClasses={``}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        {
                                            label: "View campaign",
                                            value: "view",
                                            action: () =>
                                                navigate(`/dashboard/campaign/${campaignId}`),
                                        },
                                    ]}
                                /> */}
                            </div>
                        </div>

                        <div className="flex items-center gap-2.5 mb-6 p-4">
                            <Button
                                btnText={`Best-case scenario`}
                                type="button"
                                onClick={() => setScenerio("optimistic")}
                                btnClassname={` ${
                                    scenerio === "optimistic"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                            <Button
                                btnText={`Worse-case scenario`}
                                type="button"
                                onClick={() => setScenerio("pessimistic")}
                                btnClassname={` ${
                                    scenerio === "pessimistic"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                            <Button
                                btnText={`Likely scenario`}
                                type="button"
                                onClick={() => setScenerio("most likely")}
                                btnClassname={` ${
                                    scenerio === "most likely"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                        </div>
                        <div className="px-4">
                            <CampaignSimulationDetails
                                values={threadValues}
                                campaignDetail={campaignDetail}
                                isSaved={false}
                                scenerio={scenerio}
                            />
                        </div>
                    </div>
                )}
            </ModalContainer>

            {/* <ViewCampaignModal
                campaignId={campaignId}
                calendarViewDetailsModal={calendarViewDetailsModal}
                setCalendarViewDetailsModal={setCalendarViewDetailsModal}
            /> */}
        </div>
    );
};

export default CalendarView;
