import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { marketingCampaignActions } from "redux/Ldb/actions";

const useGetCampaignList = () => {
    const dispatch = useAppDispatch();
    const [campaignList, setCampaignList] = useState<{ [key: string]: any }[]>([]);
    const [status, setStatus] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [type, setType] = useState<string>("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingAllCampaign, fetchAllCampaignSuccess } = useAppSelector(
        (state) => state.marketingCampaign
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch]);

    useEffect(() => {
        if (type === "list") {
            dispatch(
                marketingCampaignActions.getAllCampaigns(10, updatedPage, debouncedSearch, status)
            );
        }
    }, [dispatch, debouncedSearch, updatedPage, status, type]);

    useEffect(() => {
        if (Boolean(fetchAllCampaignSuccess)) {
            setCampaignList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchAllCampaignSuccess?.campaigns], "_id")
                    : uniqBy([...prev, ...fetchAllCampaignSuccess?.campaigns], "_id")
            );
            setPagination({
                currentPage: fetchAllCampaignSuccess.pagination?.current,
                noOfPages: fetchAllCampaignSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(marketingCampaignActions.resetGetAllCampaigns());
        }
    }, [dispatch, fetchAllCampaignSuccess]);

    return {
        data: campaignList,
        isLoading: fetchingAllCampaign,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setType,
        setDebouncedSearch,
        status,
        setStatus,
    };
};

export default useGetCampaignList;
