import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetSingleBrand = () => {
    const dispatch = useAppDispatch();
    const [brandData, setBrandData] = useState<{ [key: string]: any }>();
    const { brandId } = useParams();

    const { fetchingSingleBrand, fetchSingleBrandSuccess, fetchSingleBrandFailure } =
        useAppSelector((state) => state.multibrand);

    useEffect(() => {
        if (Boolean(fetchSingleBrandSuccess)) {
            setBrandData(fetchSingleBrandSuccess?.brand);
        }
    }, [fetchSingleBrandSuccess]);

    useEffect(() => {
        if (brandId) {
            dispatch(multibrandActions?.getSingleBrand(brandId));
        }

        return () => {
            dispatch(multibrandActions?.resetGetSingleBrandSuccess());
        };
    }, [dispatch, brandId]);

    return {
        data: brandData,
        isFetching: fetchingSingleBrand,
        error: fetchSingleBrandFailure,
    };
};

export default useGetSingleBrand;
