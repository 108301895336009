import { marketingCampaignTypes } from "redux/Ldb/types";

const initialState = {
    planningCampaign: false,
    planCampaignSuccess: null,
    planCampaignFailure: null,
    addingCampaign: false,
    addCampaignSuccess: null,
    addCampaignFailure: null,
    fetchingAllCampaign: false,
    fetchAllCampaignSuccess: null,
    fetchAllCampaignFailure: null,
    fetchingAllCampaignCalendarView: false,
    fetchAllCampaignCalendarViewSuccess: null,
    fetchAllCampaignCalendarViewFailure: null,
};

export const marketingCampaign = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case marketingCampaignTypes.PLAN_CAMPAIGN_REQUEST:
            return {
                ...state,
                planningCampaign: true,
                planCampaignSuccess: null,
                planCampaignFailure: null,
            };
        case marketingCampaignTypes.PLAN_CAMPAIGN_SUCCESS:
            return {
                ...state,
                planningCampaign: false,
                planCampaignSuccess: action.payload,
                planCampaignFailure: null,
            };
        case marketingCampaignTypes.PLAN_CAMPAIGN_FAILURE:
            return {
                ...state,
                planningCampaign: false,
                planCampaignSuccess: null,
                planCampaignFailure: action.payload,
            };
        case marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_REQUEST:
            return {
                ...state,
                addingCampaign: true,
                addCampaignSuccess: null,
                addCampaignFailure: null,
            };
        case marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_SUCCESS:
            return {
                ...state,
                addingCampaign: false,
                addCampaignSuccess: action.payload,
                addCampaignFailure: null,
            };
        case marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_FAILURE:
            return {
                ...state,
                addingCampaign: false,
                addCampaignSuccess: null,
                addCampaignFailure: action.payload,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_REQUEST:
            return {
                ...state,
                fetchingAllCampaign: true,
                fetchAllCampaignSuccess: null,
                fetchAllCampaignFailure: null,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetchingAllCampaign: false,
                fetchAllCampaignSuccess: action.payload,
                fetchAllCampaignFailure: null,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_FAILURE:
            return {
                ...state,
                fetchingAllCampaign: false,
                fetchAllCampaignSuccess: null,
                fetchAllCampaignFailure: action.payload,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_REQUEST:
            return {
                ...state,
                fetchingAllCampaignCalendarView: true,
                fetchAllCampaignCalendarViewSuccess: null,
                fetchAllCampaignCalendarViewFailure: null,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_SUCCESS:
            return {
                ...state,
                fetchingAllCampaignCalendarView: false,
                fetchAllCampaignCalendarViewSuccess: action.payload,
                fetchAllCampaignCalendarViewFailure: null,
            };
        case marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_FAILURE:
            return {
                ...state,
                fetchingAllCampaignCalendarView: false,
                fetchAllCampaignCalendarViewSuccess: null,
                fetchAllCampaignCalendarViewFailure: action.payload,
            };
        default:
            return state;
    }
};
