import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import DateInput from "components/common/DateInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import { displayFileIcon } from "helpers";
import { truncate } from "lodash";
import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import Dropdown from "components/common/Dropdown";
import ModalContainer from "components/common/ModalContainer";

const Playground = () => {
    const [method, setMethod] = useState<number>(0);
    const [chooseDataSet, setChooseDataSet] = useState<boolean>(false);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const options = {
        grid: { top: 8, right: 0, bottom: 24, left: 0 },
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                color: "#89939b",
                align: "center",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: true,
            },
            splitLine: {
                show: true,
            },
            show: true,
        },

        yAxis: [
            {
                type: "value",
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#7581BD",
                    },
                },
                axisLabel: {
                    formatter: "{value}",
                },
                splitLine: {
                    show: true,
                },
                show: true,
            },
            {
                type: "value",

                axisLabel: {
                    formatter: "{value}",
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        series: [
            {
                name: "Actual",
                data: [120, 132, 101, 134, 90, 230, 210],
                type: "line",
                yAxisIndex: 0,
                lineStyle: {
                    width: 1.4,
                    color: "rgba(0, 165, 208, 1)",
                },
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: "rgba(0, 240, 255, 0.15)" },
                            { offset: 1, color: "rgba(0, 240, 255, 0)" },
                        ],
                    },
                },
                showSymbol: false,
                symbol: "none",
            },
            {
                name: "Forecasted",
                data: [220, 182, 191, 234, 290, 330, 310],
                type: "line",
                yAxisIndex: 1,
                lineStyle: {
                    type: "dashed",
                    color: "rgba(255, 61, 29, 1)",
                },

                showSymbol: false,
                symbol: "none",
            },
        ],
        tooltip: {
            trigger: "axis",
            backgroundColor: "#142837",
            textStyle: {
                color: "#fff",
            },

            axisPointer: {
                type: "line",
                lineStyle: {
                    color: "#00A5D0",
                    width: 1,
                    type: "dashed",
                },
            },
            // formatter: function (params) {
            //     if (params) {
            //         return `
            //         <div> Period: ${params[0].name}</div>
            //               <div>${
            //                   params[0]?.seriesName ? params[0]?.seriesName : "Av. team efficiency"
            //               }: ${params[0]?.value}%</div>
            //               <div>${
            //                   params[1]?.seriesName
            //                       ? params[1]?.seriesName
            //                       : "Previous team efficiency"
            //               }: ${params[1]?.value ? params[1]?.value : 0}%</div>
            //               `;
            //     } else {
            //         return "";
            //     }
            // },
        },
    };

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const displaySelectedMethod = (selectedMethod: number) => {
        switch (selectedMethod) {
            case 0:
                return (
                    <div>
                        <div className="flex items-center space-x-3 mb-6">
                            <Button
                                btnText="Select a data template"
                                btnClassname="!rounded-[50px] !bg-p-70 !p-2.5 !w-fit !h-[40px]"
                                btnTextClassName="!text-[13px] !text-white"
                                icon={<i className="ri-add-circle-line text-base text-white"></i>}
                                onClick={() => setChooseDataSet(true)}
                            />

                            <div className="flex items-center space-x-2.5">
                                <div className="border rounded-[8px] border-slate-500 bg-slate-50 py-3 px-2.5 w-fit h-[43px] flex items-center space-x-2.5">
                                    {displayFileIcon("xlsx", true)}
                                    <span className="text-[13px] mt-[2px] font-rocGroteskMedium">
                                        Dataset for 2000 packs of cabbage
                                    </span>
                                </div>
                                <div className="bg-r-25 px-[13.5px] w-[43px] h-[43px] transition-[width] ease-in-out delay-150 duration-300 rounded-[8px] group hover:w-[183px] flex items-center  cursor-pointer">
                                    <i className="ri-delete-bin-5-line text-base text-r-50"></i>
                                    <div className="items-center space-x-2.5 opacity-0 w-0 group-hover:opacity-100 group-hover:w-[130px] ml-2.5 transition-all ease-in-out delay-150 duration-300">
                                        <span className="text-xs font-rocGroteskMedium text-r-50 underline whitespace-nowrap">
                                            Delete file
                                        </span>
                                        <span className="text-xs font-rocGroteskMedium underline whitespace-nowrap">
                                            Change file
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pb-6">
                            <DateInput
                                label="Select a past date"
                                value={new Date()}
                                onChange={(newDate) => console.log("manufacturingDate", newDate)}
                                showCalendar={false}
                                onCalendarToggle={(value) => console.log("first", value)}
                            />
                        </div>

                        <div className="pb-6">
                            <h4 className="text-sm font-rocGroteskMedium mb-4">
                                Select all the factors that are likely to affect product sales
                            </h4>
                            <div className="flex items-center gap-2.5 flex-wrap">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((_, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="w-fit px-2.5 py-2 h-[33px] rounded-[8px] border border-slate-200 flex items-center space-x-2.5 cursor-pointer group hover:bg-p-70"
                                        >
                                            <span className="text-[13px] text-slate-400 font-rocGroteskMedium group-hover:text-white">
                                                Bad weather
                                            </span>
                                            <i className="ri-add-circle-line text-base text-slate-400 group-hover:hidden"></i>
                                            <i className="ri-checkbox-circle-fill text-base text-white hidden group-hover:block"></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="flex space-x-2.5 mt-6">
                            <Button
                                btnText="Reset"
                                btnClassname="!bg-n-20 !px-6 !py-2 !h-10 !w-fit"
                            />
                            <Button
                                btnText="Show me the results"
                                btnClassname="!px-6 !py-2 !h-10 !w-fit disabled:opacity-50"
                                // disabled
                                onClick={() => setShowResult(true)}
                            />
                        </div>
                    </div>
                );

            case 1:
                return (
                    <div>
                        <div className="mb-6">
                            <div className="flex items-center space-x-3 mb-2.5">
                                <Button
                                    btnText="Upload from device"
                                    btnClassname="!rounded-[50px] !bg-p-70 !p-2.5 !w-fit !h-[40px]"
                                    btnTextClassName="!text-[13px] !text-white"
                                    icon={
                                        <i className="ri-upload-cloud-2-line text-base text-white"></i>
                                    }
                                />

                                <div className="flex items-center space-x-2.5">
                                    <div className="border rounded-[8px] border-slate-500 bg-slate-50 py-3 px-2.5 w-fit h-[43px] flex items-center space-x-2.5">
                                        {displayFileIcon("xlsx", true)}
                                        <span className="text-[13px] mt-[2px] font-rocGroteskMedium">
                                            Dataset for 2000 packs of cabbage
                                        </span>
                                    </div>
                                    <div className="bg-r-25 px-[13.5px] w-[43px] h-[43px] transition-[width] ease-in-out delay-150 duration-300 rounded-[8px] group hover:w-[183px] flex items-center  cursor-pointer">
                                        <i className="ri-delete-bin-5-line text-base text-r-50"></i>
                                        <div className="items-center space-x-2.5 opacity-0 w-0 group-hover:opacity-100 group-hover:w-[130px] ml-2.5 transition-all ease-in-out delay-150 duration-300">
                                            <span className="text-xs font-rocGroteskMedium text-r-50 underline whitespace-nowrap">
                                                Delete file
                                            </span>
                                            <span className="text-xs font-rocGroteskMedium underline whitespace-nowrap">
                                                Change file
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="block text-sm font-rocGroteskRegular text-slate-400 mb-6">
                                Upload information for just one product
                            </span>
                        </div>

                        <div className="mb-4">
                            <span className="block font-rocGroteskMedium text-sm text-slate-500 mb-4">
                                Or Connect a data source
                            </span>
                            <div className="flex items-center gap-2.5 flex-nowrap overflow-auto w-full">
                                {[1, 2, 3, 4, 5, 6, 7, 8]?.map((_, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="w-fit px-2.5 py-2 h-[34px] rounded-[8px] bg-slate-100 border border-p-70 flex items-center space-x-2.5 cursor-pointer group hover:bg-g-25 hover:border-g-55"
                                        >
                                            <VendorAvatar
                                                size={24}
                                                name="logo"
                                                textClassname="!text-[10px]"
                                            />
                                            <span className="text-[12px] text-p-55 font-rocGroteskMedium group-hover:text-g-60 whitespace-nowrap">
                                                Connect Shopify
                                            </span>
                                            <i className="ri-external-link-line text-base text-p-55 group-hover:hidden"></i>
                                            <i className="ri-checkbox-circle-fill text-base text-g-60 hidden group-hover:block"></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="pb-6">
                            <div className="mb-6">
                                <SelectInput
                                    // value={}
                                    name="product"
                                    placeholder={"Select product"}
                                    handleChange={(name, value) => {
                                        console.log(name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Co-pilot",
                                            value: "copilot",
                                        },
                                        {
                                            label: "Team member",
                                            value: "LDB",
                                        },
                                        { label: "Vendor", value: "vendor" },
                                    ]}
                                />
                            </div>
                            <div className="flex justify-between items-center space-x-[26px] w-full">
                                <div className="w-1/2 ">
                                    <DateInput
                                        label="Select a past date"
                                        value={new Date()}
                                        onChange={(newDate) =>
                                            console.log("manufacturingDate", newDate)
                                        }
                                        showCalendar={false}
                                        onCalendarToggle={(value) => console.log("first", value)}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <TextInput
                                        name="inventoryLevel"
                                        type="number"
                                        value={""}
                                        placeholder="Current inventory level"
                                        required={true}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="pb-6">
                            <h4 className="text-sm font-rocGroteskMedium mb-4">
                                Select all the factors that are likely to affect product sales
                            </h4>
                            <div className="flex items-center gap-2.5 flex-wrap">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((_, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="w-fit px-2.5 py-2 h-[33px] rounded-[8px] border border-slate-200 flex items-center space-x-2.5 cursor-pointer group hover:bg-p-70"
                                        >
                                            <span className="text-[13px] text-slate-400 font-rocGroteskMedium group-hover:text-white">
                                                Bad weather
                                            </span>
                                            <i className="ri-add-circle-line text-base text-slate-400 group-hover:hidden"></i>
                                            <i className="ri-checkbox-circle-fill text-base text-white hidden group-hover:block"></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="flex space-x-2.5 mt-6">
                            <Button
                                btnText="Reset"
                                btnClassname="!bg-n-20 !px-6 !py-2 !h-10 !w-fit"
                            />
                            <Button
                                btnText="Show me the results"
                                btnClassname="!px-6 !py-2 !h-10 !w-fit disabled:opacity-50"
                                // disabled
                                onClick={() => setShowResult(true)}
                            />
                        </div>
                    </div>
                );

            default:
                break;
        }
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[298px]" },
        { title: "Current Inventory level", widthClass: "w-[197px]" },
        { title: "Connected source", widthClass: "w-[144px]" },
        { title: "Forecasted stock needs", widthClass: "w-[198px]" },
        { title: "Stock status", widthClass: "w-[198px]" },
        { title: "Outlet", widthClass: "w-[250px]" },
    ];

    const tableBody = [1]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center space-x-2">
                        <div className="rounded border border-slate-200 p-[2px]">
                            <VendorAvatar imageSrc={""} size={28} name={"Intercom"} />
                        </div>
                        <div>
                            <span className="block text-sm ">
                                {truncate("Vine Ripened Tomatoes", { length: 18 })}
                            </span>
                            <span className="block text-sm text-slate-500 font-rocGroteskMedium">
                                SKU Number: TS123456
                            </span>
                        </div>
                    </div>
                </div>
            ),
            cellClickAction: () => {},
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>100 lb</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center space-x-2">
                        <VendorAvatar imageSrc={""} size={32} name={"John snow"} />
                        <span>{truncate("Amazon", { length: 10 })}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>Rice</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>Fedex</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>Apr 17, 2023</span>
                </div>
            ),
        },
    ]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white relative">
            <div className="mb-5">
                <PageTitleSection
                    leftComp={<h3 className="text-xl font-rocGroteskMedium">Playground</h3>}
                />
            </div>

            <div>
                <h4 className="text-lg font-rocGroteskMedium mb-5">
                    See how Synth makes you money
                </h4>
                <div className="flex justify-between">
                    <div className="w-[52%]">
                        <div className="flex justify-between items-center space-x-[30px] mb-6">
                            <div
                                className={`border-[1.5px] p-4 rounded-[10px] h-full w-1/2 cursor-pointer group hover:border-slate-900 ${
                                    method === 0 ? "border-slate-900" : "border-slate-300"
                                }`}
                                onClick={() => setMethod(0)}
                            >
                                <i
                                    className={`${
                                        method === 0
                                            ? "ri-radio-button-line"
                                            : "ri-circle-line text-slate-300"
                                    } text-2xl mb-2.5 group-hover:hidden`}
                                ></i>
                                <i
                                    className={`ri-radio-button-line hidden group-hover:block text-2xl`}
                                ></i>
                                <div className="flex flex-col items-center">
                                    <img
                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/playground_data_template_kd4txj.svg"
                                        alt="select template data image"
                                        className="w-[66px] h-[54.21px] object-cover mb-3"
                                    />
                                    <div className="">
                                        <span className="block text-base text-center font-rocGroteskMedium mb-3">
                                            Choose our data template
                                        </span>
                                        <span className="block text-sm text-center font-rocGroteskRegular text-slate-400">
                                            Select one of our pre-defined <br /> demo data
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`border-[1.5px] p-4 rounded-[10px] h-full w-1/2 cursor-pointer group hover:border-slate-900 ${
                                    method === 1 ? "border-slate-900" : "border-slate-300"
                                }`}
                                onClick={() => setMethod(1)}
                            >
                                <i
                                    className={`${
                                        method === 1
                                            ? "ri-radio-button-line"
                                            : "ri-circle-line text-slate-300"
                                    } text-2xl mb-2.5 group-hover:hidden`}
                                ></i>
                                <i
                                    className={`ri-radio-button-line hidden group-hover:block text-2xl`}
                                ></i>
                                <div className="flex flex-col items-center">
                                    <img
                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/playground_own_data_ynbnpk.svg"
                                        alt="select template data image"
                                        className="w-[66px] h-[54.21px] object-cover mb-3"
                                    />
                                    <div className="">
                                        <span className="block text-base text-center font-rocGroteskMedium mb-3">
                                            Add your own data
                                        </span>
                                        <span className="block text-sm text-center font-rocGroteskRegular text-slate-400">
                                            Upload an excel sheet containing salesdata for a product
                                            or connect a data source
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {displaySelectedMethod(method)}
                    </div>
                    <div className="flex justify-end w-[25%] h-fit">
                        <div className="border-[1.5px] border-slate-300 py-8 px-4 rounded-[10px] h-full w-full">
                            <div className="p-[5px] flex items-center justify-center w-[50px] h-[50px] rounded-lg bg-p-25 mb-3">
                                <i className="ri-guide-fill text-[40px] text-p-55"></i>
                            </div>
                            <span className="block text-base font-rocGroteskMedium mb-3">
                                Playground guides
                            </span>
                            <span className="block text-sm font-rocGroteskRegular text-slate-400 mb-3">
                                Click the button below to see how to use the playground.
                            </span>
                            <Button
                                btnText="Explore guides"
                                btnTextClassName="text-[13px]"
                                btnClassname="!px-2.5 !py-1.5 !bg-transparent !border !border-slate-800 !w-fit !rounded-[8px]"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={chooseDataSet}
                showCloseIcon={false}
                closeModal={() => setChooseDataSet(false)}
                tailwindClassName=" transition-all ease-in-out delay-150 duration-300"
            >
                <div
                    className={`fixed top-0 right-0 ${
                        chooseDataSet ? "w-[447px]" : "w-0 border-none"
                    } transition-[width] ease-in-out delay-150 duration-300 bg-white border-l border-slate-100 h-screen`}
                >
                    <div className="p-5 mb-4">
                        <div className="space-x-2.5 flex items-center pb-4 border-b border-slate-200 mb-4">
                            <i
                                className="ri-close-fill text-2xl cursor-pointer"
                                onClick={() => setChooseDataSet(false)}
                            ></i>
                            <span className="text-xl text-p-55 font-rocGroteskMedium mt-[2px]">
                                Select data set
                            </span>
                        </div>
                        <TextInput
                            name="searchProduct"
                            type="number"
                            value={""}
                            inputPlaceholder="Search for a product"
                            inputClassName={"pl-[36px] !h-[47px]"}
                            inputContainerClassname={"!bg-slate-50 !h-[47px] !border-slate-100"}
                            leftIcon={<i className="ri-search-line text-base text-slate-400"></i>}
                            required={true}
                            onChange={(e) => {
                                console.log(e.target.value);
                            }}
                        />
                    </div>
                    <div className="pl-5 flex items-center space-x-2.5">
                        <span className="text-sm font-rocGroteskMedium text-slate-400 whitespace-nowrap">
                            Sort by:
                        </span>
                        <div className="flex flex-nowrap w-[85%] items-center space-x-2.5 overflow-x-auto">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((_, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="w-fit px-2.5 py-2 h-[33px] rounded-[10px] border border-slate-200 flex items-center space-x-2.5 cursor-pointer group hover:bg-p-70"
                                    >
                                        <span className="text-[13px] text-slate-400 font-rocGroteskMedium whitespace-nowrap group-hover:text-white">
                                            Product type
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="p-5 h-full overflow-y-auto">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((_, idx) => {
                            return (
                                <div className="py-[14px] px-4 flex items-center justify-between border-b border-slate-200 mb-4 rounded-[10px]">
                                    <div className="flex items-center space-x-4 w-[62%]">
                                        {displayFileIcon("xlsx", false)}
                                        <div>
                                            <span className="text-sm font-rocGroteskMedium text-slate-600">
                                                Dataset for 2000 farm produce products
                                            </span>
                                        </div>
                                    </div>
                                    <i className="ri-add-circle-line text-2xl text-slate-400"></i>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={showResult}
                showCloseIcon={false}
                closeModal={() => setShowResult(false)}
                tailwindClassName=" transition-all ease-in-out delay-150 duration-300"
            >
                <div
                    className={`fixed top-0 right-0 ${
                        showResult ? "w-[720px]" : "w-0 border-none"
                    }  transition-[width] ease-in-out delay-150 duration-300 bg-white border-l border-slate-100 h-screen`}
                >
                    <div className="p-5">
                        <div className="space-x-2.5 flex items-start pb-4 border-b border-slate-200 mb-4">
                            <i
                                className="ri-close-fill text-2xl cursor-pointer"
                                onClick={() => setShowResult(false)}
                            ></i>
                            <div className="mt-[2px]">
                                <span className="block text-2xl text-p-55 font-rocGroteskBold mb-[5px]">
                                    Vine Ripened Tomatoes
                                </span>
                                <span className="block text-sm text-slate-800 font-rocGroteskMedium">
                                    SKU Number: TS123456
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="h-[calc(100vh-100px)] overflow-y-auto overflow-x-hidden pb-[50px]">
                        <div className="px-5 pb-5">
                            <div className="px-6 py-4 border border-slate-200 shadow-[0px_4px_10px_0px_#1E293B08] grid grid-cols-[0.5fr_2fr] items-center space-x-2.5 mb-10">
                                <svg
                                    className="circular-progress"
                                    width={134}
                                    height={134}
                                    viewBox="0 0 120 120"
                                >
                                    <defs>
                                        <linearGradient
                                            id={"progress"}
                                            x1="0%"
                                            y1="0%"
                                            x2="100%"
                                            y2="0%"
                                        >
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "#84CC16", stopOpacity: 1 }}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        fill={"none"}
                                        stroke={"#F1F5F9"} /* Background color */
                                        strokeWidth={15}
                                        cx="60"
                                        cy="60"
                                        r="51"
                                    />
                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="51"
                                        fill="none"
                                        stroke={`url(#progress)`}
                                        strokeWidth={15}
                                        strokeLinecap={"round"}
                                        style={{
                                            transition: "stroke-dashoffset 0.3s",
                                        }}
                                        strokeDasharray="320.484"
                                        strokeDashoffset={((100 - 60) / 100) * 320.484}
                                    />
                                    <text
                                        x="50%"
                                        y="50%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fontSize="17"
                                        fontWeight={700}
                                        className="font-rocGroteskBold"
                                        fill="#333333"
                                    >
                                        $430,000
                                    </text>
                                </svg>
                                <p className="text-base font-rocGroteskMedium">
                                    Your business would have saved{" "}
                                    <span className="font-rocGroteskBold">$430,000</span> if you had{" "}
                                    <span className="font-rocGroteskBold">Synth</span> as of 22nd
                                    October, 2022. This would have been a 40% increase in revenue
                                    made within the specified period.
                                </p>
                            </div>
                        </div>

                        <div className="px-5 mb-10 items-center space-x-2.5">
                            <p className="flex items-center space-x-2.5 mb-3">
                                <span className="text-base font-rocGroteskMedium">Product</span>
                                <span className="w-fit px-2.5 py-[7px] h-[27px] rounded-[6px] border border-slate-200 text-[9px] text-slate-400 font-rocGroteskMedium flex items-center">
                                    Swipe through table
                                </span>
                            </p>

                            <div className="overflow-x-scroll " onScroll={handleScroll}>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    // leftFixedColumnCount={1}
                                    // leftFixedColumnClass={["!left-0"]}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass=""
                                />
                            </div>
                        </div>

                        <div className="px-5 mb-10">
                            <span className="text-sm block text-slate-800 mb-2.5 font-rocGroteskMedium">
                                Select sales period
                            </span>
                            <div className="flex justify-between items-center space-x-[26px] w-full">
                                <div className="w-1/2 ">
                                    <DateInput
                                        label="Select a past date"
                                        value={new Date()}
                                        onChange={(newDate) =>
                                            console.log("manufacturingDate", newDate)
                                        }
                                        showCalendar={false}
                                        onCalendarToggle={(value) => console.log("first", value)}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <DateInput
                                        label="Select a past date"
                                        value={new Date()}
                                        onChange={(newDate) =>
                                            console.log("manufacturingDate", newDate)
                                        }
                                        showCalendar={false}
                                        onCalendarToggle={(value) => console.log("first", value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="px-5 mb-10">
                            <p className="flex items-center mb-4 space-x-[3px]">
                                <span className="text-lg block text-slate-800 mb-2.5 font-rocGroteskMedium">
                                    Demand Summary
                                </span>
                                <span className="text-sm block text-slate-800 mb-2.5 font-rocGroteskMedium">
                                    (for 10 days)
                                </span>
                            </p>

                            <div className="rounded-[10px] border border-slate-200">
                                <div className="p-4 border-b border-slate-200">
                                    <div className="flex items-center space-x-3">
                                        <div className="rounded p-2 w-10 h-10 bg-slate-100 flex items-center justify-center">
                                            <i className="ri-store-2-line text-[22px] text-p-50"></i>
                                        </div>
                                        <div>
                                            <p className="text-sm flex items-end space-x-[3px] font-rocGroteskMedium">
                                                <span>Current Stock Level</span>
                                                <span className="text-[9px] text-slate-600">
                                                    (For the first 5 days)
                                                </span>
                                            </p>
                                            <span className="block text-sm text-slate-500 font-rocGroteskMedium">
                                                35,600 units
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-4 border-b border-slate-200 grid grid-cols-2 ">
                                    <div className="flex items-center space-x-3 border-r border-slate-200 py-4">
                                        <div className="rounded p-2 w-10 h-10 bg-[#FFEDD5] flex items-center justify-center">
                                            <i className="ri-line-chart-line text-[22px] text-[#EA580C]"></i>
                                        </div>
                                        <div>
                                            <span className="text-sm flex items-end space-x-[3px] font-rocGroteskMedium">
                                                Forecasted Demand
                                            </span>
                                            <p className="flex items-center space-x-[3px] font-rocGroteskMedium">
                                                <span className="block text-sm text-slate-500">
                                                    35,600 units
                                                </span>
                                                <span className="text-xs text-slate-600">
                                                    (For the first 5 days)
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center space-x-3 py-4 pl-4">
                                        <div className="rounded p-2 w-10 h-10 bg-g-25 flex items-center justify-center">
                                            <i className="ri-line-chart-line text-[22px] text-g-55"></i>
                                        </div>
                                        <div>
                                            <span className="text-sm flex items-end space-x-[3px] font-rocGroteskMedium">
                                                Forecasted Demand
                                            </span>
                                            <p className="flex items-center space-x-[3px] font-rocGroteskMedium">
                                                <span className="block text-sm text-slate-500 ">
                                                    35,600 units
                                                </span>
                                                <span className="text-xs text-slate-600">
                                                    (For the first 5 days)
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-4 grid grid-cols-2 ">
                                    <div className="flex items-center space-x-3 border-r border-slate-200 py-4">
                                        <div>
                                            <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                Estimated revenue for first 5 days
                                            </span>
                                            <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                $530,000
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex items-center space-x-3 py-4 pl-4">
                                        <div>
                                            <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                Estimated revenue for first 5 days
                                            </span>
                                            <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                $430,000
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="rounded-b-[10px] bg-[#FFEDD5] flex items-center space-x-3 py-4 px-4">
                                    <div className="p-2 w-[30px] h-[30px] rounded-full bg-[#FFB75733] flex items-center justify-center">
                                        <i className="ri-lightbulb-flash-fill text-[14px] text-[#E88500]"></i>
                                    </div>

                                    <p className="text-sm font-rocGroteskMedium">
                                        Based on the forecasted demand, you are likely to run out of
                                        stock on day 5 of the sales period. This means that you are
                                        likely to lose about $430,000 across all outlets.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-5 ">
                            <div className="py-5 px-3 bg-white rounded-[12px] shadow-faintShadow">
                                <div className="flex items-center justify-between pb-[13px] border-b border-slate-300">
                                    <span className="block font-rocGroteskMedium text-[15px]">
                                        Actual Stock vs Forecasted Demand
                                    </span>
                                    <div>
                                        <Dropdown
                                            handleChange={(name, value) => {
                                                console.log("first", name, value);
                                            }}
                                            dropdown={
                                                <div className="flex whitespace-nowrap border border-slate-200 items-center rounded-[5px] h-[31px] py-2 px-1.5 space-x-1">
                                                    <span
                                                        className={`text-slate-600 font-rocGroteskMedium text-[12px]`}
                                                    >
                                                        Per outlet
                                                    </span>
                                                    <i className="ri-arrow-down-s-line text-slate-600"></i>
                                                </div>
                                            }
                                            dropdownItemsClasses={`!px-0 !py-0`}
                                            // dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit
                            `}
                                            dropdownOptions={[
                                                { label: "Last 7 days", value: "last7days" },
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="">
                                    <div className="flex space-x-[35px] mt-[13px] pl-3">
                                        <div className="pr-5">
                                            <div className="flex items-center space-x-1.5 mb-1">
                                                <span className="w-2 h-2 rounded-full bg-[#22D3EE]"></span>
                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                    Actual
                                                </span>
                                            </div>
                                            <span className="block text-base font-rocGroteskMedium text-g-75">
                                                $530,000
                                            </span>
                                        </div>
                                        <div className="pr-5">
                                            <div className="flex items-center space-x-1.5 mb-1">
                                                <span className="w-2 h-2 rounded-full bg-[#FC1D8B]"></span>
                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                    Forecasted
                                                </span>
                                            </div>
                                            <span className="block text-base font-rocGroteskMedium text-g-75">
                                                $960,000
                                            </span>
                                        </div>
                                    </div>
                                    <ReactECharts option={options} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default Playground;
