import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { convertDaysToWeeks } from "helpers/convertDaysToWeeks";
import useGetInventoryIncidents from "hooks/inventoryHooks/useGetInventoryIncidents";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import { truncate } from "lodash";
import moment from "moment";
import { format } from "path";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    data: any;
    isFetching: boolean;
    pagination: any;
    isLoadingMore: boolean;
}

const Alerts = () => {
    const navigate = useNavigate();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/portfolio/home" });
    const data = [];
    const isFetching = false;
    const pagination = { currentPage: 1, noOfPages: 1 };
    const isLoadingMore = false;

    return (
        <PageFrame
            isLoading={isFetching && Number(pagination.currentPage) === 1 && data.length === 0}
            containerClassName={"!min-h-[60vh]"}
        >
            <div>
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <i
                                className="ri-arrow-left-line cursor-pointer"
                                onClick={() => goBack()}
                            ></i>
                            <h3 className="text-xl font-rocGroteskMedium">Incidents</h3>
                        </div>
                    }
                    rightComp={
                        <div className="flex items-center space-x-1.5 bg-white border border-slate-200 rounded py-2 px-3 h-[33px]">
                            <i className="ri-signal-tower-fill text-base text-[#15803D] "></i>
                            <span className="text-xs text-[#15803DDD] font-rocGroteskMedium">
                                Real time monitoring
                            </span>
                        </div>
                    }
                />

                <div className="flex items-center justify-between mt-[14px]">
                    <span className="text-sm font-rocGroteskMedium pb-2 border-b-[1.5px] border-y-10">
                        All Incidents 
                    </span>

                    <div className="flex max-lg:mb-4 max-sm:space-x-0 max-sm:flex-col max-sm:space-y-3 items-center space-x-3">
                        <TextInput
                            name={"search"}
                            value={""}
                            type={"text"}
                            inputPlaceholder={"Search brand name, SKU number, product name"}
                            inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                            // onChange={handleSearch}
                            // onInput={handleDebouncedChange}
                            containerClassname={"!w-[300px] max-sm:!w-full"}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                            }
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                // handleCostSummaryBrandChangeFilter({
                                //     label: value?.split("-")?.[0],
                                //     value: value?.split("-")?.[1],
                                // });
                            }}
                            dropdown={
                                <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                    <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                    <div
                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                    >
                                        {truncate("All brands", {
                                            length: 20,
                                        })}
                                    </div>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[286px] !w-[200px] !py-0`}
                            name="valueRange"
                            onClose={() => {
                                // setSearchTwo("");
                                // setDebouncedSearchTwo("");
                                // setSearchTwoAssigned("");
                                // setDebouncedSearchTwoAssigned("");
                            }}
                            search={
                                <div>
                                    <TextInput
                                        value={
                                            ""
                                            // profile?.role === "owner" && !profile?.parentId
                                            //     ? searchTwo
                                            //     : searchTwoAssigned
                                        }
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={(e) => {
                                            // profile?.role === "owner" && !profile?.parentId
                                            //     ? handleSearchTwo(e)
                                            //     : handleSearchTwoAssigned(e)
                                        }}
                                        onInput={
                                            () => {}
                                            // profile?.role === "owner" && !profile?.parentId
                                            //     ? handleDebouncedChangeTwo
                                            //     : handleDebouncedChangeTwoAssigned
                                        }
                                        inputPlaceholder="Search brands"
                                        // containerClassname=""
                                        inputContainerClassname={"!h-[40px]"}
                                        inputClassName="w-full !h-[40px]"
                                    />
                                </div>
                            }
                            dropdownOptions={[
                                {
                                    label: (
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                border-b border-b-slate-100 px-4 py-[10px]`}
                                        >
                                            All brands
                                        </div>
                                    ),
                                    value: ``,
                                },
                                // ...(profile?.role === "owner" && !profile?.parentId
                                //     ? brandList
                                //     : assignedBrands
                                // )?.map((brand) => {
                                //     let brandItem = brand;
                                //     if (profile?.role === "owner" && !profile?.parentId) {
                                //         brandItem = brand?.brand;
                                //     }

                                //     return {
                                //         label: (
                                //             <div
                                //                 className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                // border-b border-b-slate-100 px-4 py-[10px]`}
                                //             >
                                //                 {brandItem?.brandName}
                                //             </div>
                                //         ),
                                //         value: `${brandItem?.brandName}-${brandItem?._id}`,
                                //     };
                                // }),
                            ]}
                        />

                        <Dropdown
                            name="filter"
                            value={""}
                            dropdownTriggerClassName={" "}
                            handleChange={(name, value) => {
                                // setIncidentType(value);
                                // handleIncidentTypeChange(value);
                            }}
                            dropdown={
                                <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                        {"All Incidents types"}
                                    </span>

                                    <div className="flex items-center space-x-2">
                                        {/* {incidentType !== "" && (
                                                        <i
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                setIncidentType("");
                                                                handleClearFilter();
                                                            }}
                                                            className="ri-close-circle-fill text-sm text-slate-500"
                                                        ></i>
                                                    )} */}
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            wholeContainerClass={`max-sm:!w-full`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    label: "Overstocking",
                                    value: "overstocking",
                                },
                                {
                                    label: "Stock Out",
                                    value: "stockout",
                                },
                            ]}
                        />

                        <div className="border rounded border-slate-200 max-sm:w-full">
                            <Dropdown
                                handleChange={(name, value) => {
                                    // handleWarehouseChange(value);
                                }}
                                dropdown={
                                    <div className="flex items-center space-x-2 whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {"All Warehouses / Outlets"}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            {/* {
                                                <i
                                                    onClick={() => {
                                                        // handleWarehouseChange("");
                                                        // clearFilter();
                                                    }}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            } */}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={""}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            // onChange={handleSearchTwo}
                                            // onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            // inputClassName="w-[50%]"
                                        />
                                    </div>
                                }
                                dropdownContainerClasses={`shadow-cardShadow-4 !right-0 max-sm:!w-full  border border-slate-100  rounded `}
                                wholeContainerClass={`max-sm:!w-full`}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div>
                                                    {/* {searchResult?.map((warehouse) => {
                                                                    return (
                                                                        <div
                                                                            key={warehouse?._id}
                                                                            onClick={() => {
                                                                                filterChange(
                                                                                    warehouse?.warehouseName,
                                                                                    warehouse?._id
                                                                                );

                                                                                handleWarehouseChange(
                                                                                    warehouse?._id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                                    warehouseDataValue?.value ===
                                                                                        warehouse?._id &&
                                                                                    "bg-slate-100"
                                                                                }`}
                                                                            >
                                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                                <div>
                                                                                    <span
                                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address &&
                                                                                            "mb-[2px]"
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            warehouse?.warehouseName
                                                                                        }
                                                                                    </span>
                                                                                    <span className="block  text-g-75 text-sm font-rocGroteskMedium ">
                                                                                        {
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })} */}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                {data?.length > 0 ? (
                    <>
                        <div className="border border-slate-200 rounded ">
                            {data?.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={`p-4  ${
                                            idx < data?.length - 1 && "border-b border-slate-200"
                                        } flex items-start space-x-3 cursor-pointer max-sm:flex-col max-sm:space-x-0 max-sm:space-y-2`}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/portfolio/incident/${item?._id}/${
                                                    idx + 1
                                                }`,
                                                { state: { internalNavigation: true } }
                                            );
                                        }}
                                    >
                                        <div
                                            className={`w-8 h-8 rounded-full ${
                                                item.incidentType?.toLowerCase() === "stockout"
                                                    ? "bg-[#FEF2F2] border-[#FF0000] "
                                                    : "bg-[#FFFBEB] border-[#FA9600] "
                                            } border  flex items-center justify-center`}
                                        >
                                            <i
                                                className={`ri-alarm-warning-fill ${
                                                    item.incidentType?.toLowerCase() === "stockout"
                                                        ? "text-[#FF0000]"
                                                        : "text-[#FA9600]"
                                                } text-[17px]`}
                                            ></i>
                                        </div>
                                        <div className="w-full">
                                            <div className="flex items-center space-x-2 mb-1">
                                                <span className="flex items-center text-g-75 text-sm font-rocGroteskMedium mb-1">
                                                    <p
                                                        className={`h-2 w-2 rounded-full max-sm:hidden ${
                                                            item.incidentType.toLowerCase() ===
                                                            "stockout"
                                                                ? "bg-[#FF3347]"
                                                                : "bg-[#FA9600]"
                                                        } mr-1.5`}
                                                    ></p>
                                                    {idx + 1} Alert - Potential{" "}
                                                    {item.incidentType?.toLowerCase() === "stockout"
                                                        ? "stockout"
                                                        : "overstocking"}{" "}
                                                    for{" "}
                                                    {item?.inventory?.productName
                                                        ?.charAt(0)
                                                        .toUpperCase() +
                                                        item?.inventory?.productName.slice(1) ||
                                                        "----"}
                                                </span>
                                                <div
                                                    className={` ${
                                                        item.incidentType?.toLowerCase() ===
                                                        "stockout"
                                                            ? "bg-[#FEF2F2] border-[#FF0000]"
                                                            : "bg-[#FFFBEB] border-[#FA9600]"
                                                    } flex items-center border  py-[1px] rounded-[3px]  px-[6px]`}
                                                >
                                                    <p
                                                        className={`text-xs font-rocGroteskMedium ${
                                                            item?.incidentType.toLowerCase() ===
                                                            "stockout"
                                                                ? "text-[#DC2626] "
                                                                : "text-[#EA580C]"
                                                        }`}
                                                    >
                                                        {item?.incidentType
                                                            ?.charAt(0)
                                                            .toUpperCase() +
                                                            item?.incidentType.slice(1) || "----"}
                                                    </p>
                                                </div>
                                            </div>
                                            <span className="block text-slate-500 text-sm font-rocGroteskMedium mb-2">
                                                {/* Stockout qty: {formatMoney().format(item?.qty) || 0}{" "}
                                                {item?.inventory?.productStockDetails
                                                    ?.unitOfMeasurement || "N/A"}{" "} */}
                                                Potential revenue loss:{" "}
                                                {getCurrencyFromCurrencyCode(
                                                    item?.inventory?.currency
                                                )}
                                                {formatMoney().format(
                                                    item?.inventory?.unitCost?.amount * item?.qty
                                                ) || 0}{" "}
                                                | Forecasted stock needed:{" "}
                                                {formatMoney().format(item?.forecastedQty) || 0}{" "}
                                                {item?.inventory?.productStockDetails
                                                    ?.unitOfMeasurement || "N/A"}{" "}
                                            </span>
                                            <div className="flex max-lg:grid max-lg:grid-cols-2   max-lg:gap-4 w-full items-center justify-between">
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                                                    {item?.risk === "high" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_2_h0x1km.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "medium" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_mlbycq.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "low" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_1_kxmng6.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}

                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.risk} risk
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Stockout date"
                                                            : "Overstocking date"}
                                                        :{" "}
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`
                                                            : `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Forecasted stock duration"
                                                            : "Overstocking duration"}
                                                        :{" "}
                                                        {(item?.forecastLength || 0)?.toFixed(0) ||
                                                            "N/A"}{" "}
                                                        weeks
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Reported {moment(item?.createdAt).fromNow()}
                                                    </span>
                                                </div>

                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-check-fill text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.status}
                                                    </span>
                                                </div>

                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-map-pin-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Target -{" "}
                                                        {truncate(item?.warehouse?.warehouseName, {
                                                            length: 12,
                                                        })}
                                                    </span>
                                                </div>

                                                {/* <div className="flex items-center space-x-1.5 w-fit">
                                 <VendorAvatar
                                     name="Augustine Asiuwhu"
                                     imageSrc=""
                                     size={20}
                                     textClassname="!text-[8px]"
                                 />
                                 <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                     Augustine Asiuwhu
                                 </span>
                             </div> */}
                                                <div className="max-lg:hidden">
                                                    <i className="ri-arrow-right-s-line text-slate-500 text-lg"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {isFetching && isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </>
                ) : (
                    <EmptyStateComp
                        contentContainerClass="max-w-[611px]"
                        title="No incidents reported"
                        description="There are currently no incidents to display. All systems are operating smoothly"
                        icon={<i className="ri-alarm-warning-line text-slate-500 text-[40px]"></i>}
                    />
                )}
            </div>
        </PageFrame>
    );
};

export default Alerts;
