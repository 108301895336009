import { TrackstarConnectButton } from "@trackstar/react-trackstar-link";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch } from "hooks/storeHooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorActions } from "redux/Ldb/actions";
import { trackstarService } from "services/trackstarService";

interface Props {
    imageSrc: string;
    imageName: string;
    title: string;
    content: string;
    connected: boolean;
    onClick?: any;
    height?: number;
    width?: number;
    logoClassname?: string;
    trackstarIntegration?: string;
    disconnectButton?: React.ReactNode;
    wasConnected?: boolean;
}

const IntegrationCard = ({
    imageName,
    height = 38,
    width = 38,
    imageSrc,
    title,
    onClick,
    connected,
    content,
    logoClassname,
    trackstarIntegration,
    disconnectButton,
    wasConnected,
}: Props) => {
    const dispatch = useAppDispatch();
    return (
        <div className="bg-white border  rounded-[8px] border-slate-100 shadow-faintShadow">
            <div className="px-4 pt-4  ">
                <div className="flex justify-between items-center">
                    <div
                        className={`${logoClassname} bg-white shadow-faintShadow rounded-[6px] w-[50px] h-[50px] flex justify-center items-center`}
                    >
                        {!trackstarIntegration ? (
                            <img width={width} height={height} src={imageSrc} alt={imageName} />
                        ) : (
                            <img src={imageSrc} alt={imageName} />
                        )}
                    </div>

                    <div
                        className={` border ${
                            connected
                                ? "border-[#16A34A] bg-[#DCFCE7]"
                                : "border-[0.5px] border-slate-200 bg-slate-50"
                        } py-[5px] px-[15px] rounded`}
                    >
                        <p
                            className={`text-xs ${
                                connected ? "text-[#16A34A]" : "text-slate-400"
                            } font-rocGroteskMedium`}
                        >
                            {connected ? "Connected" : "Not connected"}
                        </p>
                    </div>
                </div>
                <div className="mt-3">
                    <p className="text-gm-50 font-rocGroteskMedium text-sm mb-1">
                        {!trackstarIntegration ? `Connect Your ${title} Account` : `${title}`}
                    </p>
                    <p className="text-[#64748B] mb-3 text-xs font-rocGroteskMedium ">{content}</p>
                </div>
            </div>
            <div className="border-t border-slate-100 pt-4 ">
                <div className="px-4 pb-4">
                    {trackstarIntegration ? (
                        connected || wasConnected ? (
                            disconnectButton
                        ) : (
                            <TrackstarConnectButton
                                getLinkToken={async () => {
                                    let resp;
                                    try {
                                        resp = await trackstarService.getLinkToken();

                                        if (resp && resp.success) {
                                            const { link_token } = resp.data;
                                            return link_token;
                                        } else {
                                            throw new Error("Failed to retrieve link token");
                                            // toast.custom((t) => (
                                            //     <CustomToast t={t} message={""} type="error" />
                                            // ));
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }}
                                onSuccess={async (authCode) => {
                                    const body = {
                                        auth_code: authCode,
                                    };
                                    try {
                                        await new Promise((resolve, reject) => {
                                            trackstarService
                                                .storeToken(body)
                                                .then(resolve)
                                                .catch(reject);
                                        });
                                        dispatch(vendorActions.getAllChannels());
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }}
                                integrationAllowList={[trackstarIntegration]}
                                buttonId={trackstarIntegration}
                                style={{
                                    color: "#5452BF",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    border: "1px solid #22214C",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    backgroundColor: "#F1F5F9",
                                }}
                            >
                                Connect
                                <span className="pl-2">
                                    <i
                                        className={`ri-${
                                            !connected ? "external-link-line " : "arrow-right-line"
                                        } text-p-55 text-[14px]`}
                                    ></i>
                                </span>
                            </TrackstarConnectButton>
                        )
                    ) : (
                        <div
                            onClick={onClick}
                            className={` cursor-pointer border-p-70 bg-slate-100 border
                               md:w-fit flex justify-center space-x-2 items-center py-[5px] px-[10px] rounded`}
                        >
                            <p className={`text-xs text-p-55  font-rocGroteskMedium`}>
                                {connected
                                    ? "View connection"
                                    : !trackstarIntegration
                                    ? `Connect ${title}`
                                    : `Connect`}
                            </p>
                            <i
                                className={`ri-${
                                    !connected ? "external-link-line " : "arrow-right-line"
                                } text-p-55 `}
                            ></i>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default IntegrationCard;
