import Button from "components/common/Button";
import EmptyStateComp from "components/common/EmptyStateComp";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, formatMoney, getCurrencyFromCurrencyCode, onModalScroll } from "helpers";
import { calculateRemainingTime } from "helpers/calculateCommentDate";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetProductsAnalytics from "hooks/inventoryHooks/useGetProductsAnalytics";
import { cloneDeep, debounce, flattenDeep, set, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { COLORS } from "variables/chartColors";
import ReactECharts from "echarts-for-react";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import millify from "millify";
import ModalContainer from "components/common/ModalContainer";
import AlertTabDetails from "../Alerts/AlertTabDetails";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { oceanCarriers } from "variables/oceanCarriers";
import useRecentInventoryIncident from "hooks/inventoryHooks/useRecentInventoryInceident";
import Incidents from "./ProductDetail/Tabs/Incidents";
import useGetSingleInventoryIncidents from "hooks/inventoryHooks/useGetSingleInventoryIncidents";
import useGetReplishmentStockList from "hooks/inventoryHooks/useGetReplishmentStock";
import ScrollableModal from "components/views/Synth2.0/Inventory/ModalInv";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { firebaseService } from "services/firebaseService";
import CustomMobileTable from "components/common/CustomMobileTable";
import TabsContainer from "components/common/TabsContainer";
import useGetListBundle from "hooks/inventoryHooks/useGetListBundle";
import useGetArchivedProductsByChannel from "hooks/inventoryHooks/useGetArchivedProductsByChannel";
import CustomTableLoader from "components/common/CustomTableLoader";
import InventoryAnalyticsCard from "components/common/InventoryAnalyticsCard";
import { productCategories } from "variables/productCategories";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import { Tooltip } from "react-tooltip";
import useGetInventoryTotalStockValue from "hooks/inventoryHooks/useGetInventoryTotalStockValue";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import useGetInventoryInStockValue from "hooks/inventoryHooks/useGetInventoryInStockValue";
import useGetInventoryReStockValue from "hooks/inventoryHooks/useGetInventoryReStock";
import useGetInventoryOutStockValue from "hooks/inventoryHooks/useGetInventoryOutStock";
import useGetInventoryAvgCostValue from "hooks/inventoryHooks/useGetInventoryAvgCostValue";
import ResponsivePaginationComponent from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import jsonToCsvExport from "json-to-csv-export";
import { yearDropdown } from "helpers/yearDropdown";
import useGetAllProductsNoVariant from "hooks/inventoryHooks/useGetAllProductsNoVariant";
import { stockLevelCategory } from "variables/stockLevelCategory";

const ProductList = () => {
    const limit = 15;
    const navigate = useNavigate();
    const customRef = useRef(null);
    const warehouseRef = useRef(null);
    const [dateRange, setDateRange] = useState("all");
    const [openReStockModal, setOpenReStockModal] = useState<boolean>(false);
    const [openDocument, setOpenDocument] = useState<boolean>(false);
    const [openShipment, setOpenShipment] = useState<boolean>(false);
    const [expandAnalyticsCard, setExpandAnalyticsCard] = useState<string>("");
    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["Timeline", "Comments", "Sources", "Previous alerts"];
    const tableTabs = ["Variants", "All Products", "Bundles", "Archived"];
    const dispatch = useAppDispatch();
    const [productList, setProductList] = useState<any>([]);
    const [productData, setProductData] = useState<any>([]);
    const [mode, setMode] = useState<string>("");
    const [getProductId, setGetProductId] = useState<string>("");
    const [showAddShipmentReminder, setShowAddShipmentReminder] = useState<boolean>(false);
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [replacingAttachment, setReplacingAttachment] = useState<boolean>(false);
    const [poShipment, setPoShipment] = useState([]);
    const [shipmentId, setShipmentId] = useState<string>("");
    const [showSuccessShipmentAdded, setshowSuccessShipmentAdded] = useState<boolean>(false);
    const [unarchiveProductsModal, setUnarchiveProductsModal] = useState<boolean>(false);
    const [archiveProductsModal, setArchiveProductsModal] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<any>([]);
    const [response, setResponse] = useState<any>([]);
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [customFilter, setCustomFilter] = useState<string>("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [poShipmentPagination, setPoShipmentPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [selectedArchiveProducts, setSelectedArchiveProducts] = useState<
        { [key: string]: any }[]
    >([]);
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [selectProduct, setSelectProduct] = useState<{ [key: string]: any }[]>([]);
    const [selectedBundles, setSelectedBundles] = useState<{ [key: string]: any }[]>([]);
    const [selectedInventories, setSelectedInventories] = useState<{ [key: string]: any }[]>([]);
    const [addedProducts, setAddedProducts] = useState<{ [key: string]: any }[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [editBundleModal, setEditBundleModal] = useState<boolean>(false);
    const [skuModal, setSkuModal] = useState<boolean>(true);
    const [years, setYears] = useState<number[]>([]);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [allProductsSearch, setAllProductsSearch] = useState<string>("");

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    const {
        data: recentIncident,
        isFetching: isFetchingRecentIncident,
        handleIncidentId,
    } = useRecentInventoryIncident();
    const { data: incidentData, isFetching: isFetchingIncidentData } =
        useGetSingleInventoryIncidents(recentIncident?.incident?._id);
    const {
        data: replishmentStock,
        isFetching: isFetchingReplishment,
        handleIncidentId: handleRecentIncidentId,
        reFetchData,
    } = useGetReplishmentStockList();

    const [country, setCountry] = useState<string>("");
    const {
        createShipment,
        createdShipmentSuccess,
        fetchPoShipmentSuccess,
        updateShipment,
        updatedShipmentSuccess,
        fetchingPoShipment,
    } = useAppSelector((state) => state.shipment);

    const {
        settingArchiveStatus,
        setArchiveStatusSuccess,
        exportingInStockList,
        exportInStockListSuccess,
        exportingOutStockList,
        exportOutStockListSuccess,
        exportingInventoryList,
        exportInventoryListSuccess,
        exportingAvgCostPerSkuList,
        exportAvgCostPerSkuListSuccess,
    } = useAppSelector((state) => state.inventory);
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const currProductType = params.get("currProductType");

    useEffect(() => {
        setActiveTab(Number(currProductType));
    }, [currProductType]);

    const handleDelete = (bundleIndex, itemIndex) => {
        const updatedBundles = cloneDeep(selectedBundles);
        updatedBundles[bundleIndex].bundleItems.splice(itemIndex, 1);
        setSelectedBundles(updatedBundles);
    };

    const ModalElement = document.getElementById("modalTable");

    const [pieChartModal, setPieChartModal] = useState<boolean>(false);

    useEffect(() => {
        if (recentIncident?.incident?._id) {
            handleRecentIncidentId(recentIncident?.incident?._id);
        }
    }, [recentIncident]);

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();

    const {
        data: allWarehouses,
        warehouseValue,
        search: warehouseSearch,
        handleDebouncedChange: debounceChange,
        handleSearch: handleWarehouseSearch,
        handleFilterChange: handleWarehouseFilterChange,
        handleClearFilter: clearWarehouseFilter,
    } = useGetAllWarehouses();

    const {
        data,
        setProductList: setProductsListData,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        setSearch,
        searchTwo,
        handleSearchTwo,
        handleDebouncedChangeTwo,
        handleFilterChangeTwo,
        handleClearFilterTwo,
        selectedProduct,
        productSearchList,
        productCategory,
        setProductCategory,
        pagination,
        isLoadingMore,
        channel,
        setChannel,
        updatedPage,
        stockLevelRange: variantStockLevelRange,
        forecastedNeedsRange: variantForecastedNeedsRange,
        variantTableFilter: variantTableFilter,
        setStockLevelRange: setVariantStockLevelRange,
        setForecastedNeedsRange: setVariantForecastedNeedsRange,
        setVariantTableFilter: setVariantTableFilter,
        setApplyProductFilter,
        applyProductFilter,
        handleFilterChange,
        handleClearFilter,
    } = useGetProductList(null, productCategories?.[1]?.value, true);

    const {
        data: allProductsList,
        isFetching: allProductsListLoading,
        pagination: allProductsPagination,
        isLoadingMore: allProductsLoadingMore,
        setAllProductsNoVariantValues,
        setStockLevelRange: setStockLevelRange,
        stockLevelRange: stockLevelRange,
        setForecastedNeedsRange,
        forecastedNeedsRange,
        handleDebouncedChange: handleAllProductsDebouncedChange,
        channelFilter: channelNoVariantFilter,
        setChannelFilter: setChannelNoVariantFilter,
        stockLevelFilter: stockLevelNoVariantFilter,
        setStockLevelFilter: setStockLevelNoVariantFilter,
        setApplyFilter,
        applyFilter,
        debouncedSearch: debounceSeachNoVariant,
    } = useGetAllProductsNoVariant();

    const {
        data: bundleList,
        handleDebouncedChange: bundleHandleDebouncedChange,
        handleSearch: bundleHandleSearch,
        isFetching: bundleListLoading,
        search: bundleSearch,
        searchTwo: bundleSerchTwo,
        handleSearchTwo: bundleHandleSearchTwo,
        handleDebouncedChangeTwo: bundleHandleDebouncedChangeTwo,
        handleFilterChangeTwo: bundleFilterChangeTwo,
        handleClearFilterTwo: bundleHandleClearFilterTwo,
        selectedProduct: bundleSelectProduct,
        productSearchList: bundleProductSearchList,
        handleFilterChange: bundleHandleFilterChange,
        handleClearFilter: bundleHandleClearFilter,
        pagination: bundlePagination,
        isLoadingMore: isBundleLoadingMore,
    } = useGetListBundle();
    const {
        data: archivedProducts,
        setArchiveProducts,
        isFetching: fetchingArchiveProducts,
        search: archiveSearch,
        debouncedSearch: archiveDebounceSearch,
        handleDebouncedChange: archiveHandleDebounceChange,
        handleSearch: archiveHandleSearch,
        handleClearFilter: archiveHandleClearFilter,
        handleFilterChange: archiveHandleFilterChange,
        productCategory: archiveProductCategory,
        pagination: archivePagination,
        isLoadingMore: archiveIsLoadingMore,
        handleChannelFilterChange: archiveHandleChannelFilterChange,
        handleClearChannelFilter: archiveHandleClearChannelFilter,
        channel: archiveChannel,
    } = useGetArchivedProductsByChannel();

    const {
        rawData: rawTotalStockValue,
        data: totalStockValue,
        error: totalStockValueError,
        handleDebouncedChange: handleTopDebouncedChange,
        isFetching: totalStockValueDataLoading,
        pagination: totalStockValuePagination,
        setWarehouseId: setTopWarehouseId,
        setChannel: setTotalStockValueChannel,
        channel: totalStockValueChannel,
        setSortValues,
        setEndDateRange,
        setStartDateRange,
        debouncedSearch,
        setDebouncedSearch,
        isLoadingMore: istotalStockLoadMore,
        setUpdatedPage: totalStockSetUpdatedPage,
    } = useGetInventoryTotalStockValue(
        Boolean(expandAnalyticsCard === "totalStockValue"),
        ModalElement
    );
    const {
        rawData: rawInStockValue,
        data: inStockValue,
        error: inStockValueError,
        handleDebouncedChange: handleInStockDebouncedChange,
        isFetching: inStockValueDataLoading,
        pagination: inStockValuePagination,
        setWarehouseId: setInStockWarehouseId,
        setChannel: setInStockValueChannel,
        channel: inStockValueChannel,
        setSortValues: setInStockSortValues,
        setEndDateRange: setInStockEndDateRange,
        setStartDateRange: setInStockStartDateRange,
        debouncedSearch: inStockDebouncedSearch,
        setDebouncedSearch: setInStockDebouncedSearch,
        isLoadingMore: isInStockLoadMore,
        setUpdatedPage: inStockSetUpdatedPage,
    } = useGetInventoryInStockValue(Boolean(expandAnalyticsCard === "inStock"), ModalElement);

    const {
        rawData: rawRestockValue,
        data: reStockValue,
        error: reStockValueError,
        handleDebouncedChange: handleReStockDebouncedChange,
        isFetching: reStockValueDataLoading,
        pagination: reStockValuePagination,
        setWarehouseId: setReStockWarehouseId,
        setChannel: setReStockValueChannel,
        channel: restockValueChannel,
        setSortValues: setReStockSortValues,
        setEndDateRange: setReStockEndDateRange,
        setStartDateRange: setReStockStartDateRange,
        debouncedSearch: reStockDebouncedSearch,
        setDebouncedSearch: setReStockDebouncedSearch,
        isLoadingMore: isReStockLoadMore,
        setUpdatedPage: reStockSetUpdatedPage,
    } = useGetInventoryReStockValue(Boolean(expandAnalyticsCard === "belowMinimum"), ModalElement);

    const {
        rawData: rawOutStockValue,
        data: outStockValue,
        error: outStockValueError,
        handleDebouncedChange: handleOutStockDebouncedChange,
        isFetching: outStockValueDataLoading,
        pagination: outStockValuePagination,
        setWarehouseId: setOutStockWarehouseId,
        setChannel: setOutStockValueChannel,
        channel: outStockValueChannel,
        setSortValues: setOutStockSortValues,
        setEndDateRange: setOutStockEndDateRange,
        setStartDateRange: setOutStockStartDateRange,
        debouncedSearch: outStockDebouncedSearch,
        setDebouncedSearch: setOutStockDebouncedSearch,
        isLoadingMore: isOutStockLoadMore,
        setUpdatedPage: outStockSetUpdatedPage,
    } = useGetInventoryOutStockValue(Boolean(expandAnalyticsCard === "outOfStock"), ModalElement);

    const {
        rawData: rawAvgCostValue,
        data: avgCostValue,
        error: avgCostValueError,
        handleDebouncedChange: handleAvgCostDebouncedChange,
        isFetching: avgCostValueDataLoading,
        pagination: avgCostValuePagination,
        setWarehouseId: setAvgCostWarehouseId,
        setChannel: setAvgCostValueChannel,
        channel: avgCostValueChannel,
        setSortValues: setAvgCostSortValues,
        setEndDateRange: setAvgCostEndDateRange,
        setStartDateRange: setAvgCostStartDateRange,
        debouncedSearch: avgCostDebouncedSearch,
        setDebouncedSearch: setAvgCostDebouncedSearch,
        isLoadingMore: isAvgCostLoadMore,
        setUpdatedPage: avgCostSetUpdatedPage,
    } = useGetInventoryAvgCostValue(Boolean(expandAnalyticsCard === "averageCogs"), ModalElement);
    const { updatingBundle, updatedBundleSuccess, updatedBundleFailure } = useAppSelector(
        (state) => state.inventory
    );

    const handleAllProductsSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAllProductsSearch(e.target.value);
    };

    const [atBottom, setAtBottom] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const dateValues2 = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "This week",
            value: "weekly",
        },

        {
            period: "Today",
            value: "daily",
        },
        {
            period: "All times",
            value: "",
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const filterModalRef = document.getElementById("filterModalRef");
            if (filterModalRef && !filterModalRef?.contains(event.target as Node)) {
                setFilterModal(false);
            }
        };

        if (filterModal) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterModal]);

    const onUpdate = () => {
        const mappedBundles = selectedBundles?.map((bundle) => ({
            bundleId: bundle._id,
            bundleItems: bundle.bundleItems.map((item) => ({
                inventoryId: item.inventoryId,
            })),
        }));
        const body: any = {
            bundles: mappedBundles,
        };

        dispatch(inventoryActions.editBundleData(body));
    };

    useEffect(() => {
        if (updatedBundleSuccess) {
            setSelectedBundles([]);
            dispatch(inventoryActions.resetEditBundle());
            setEditBundleModal(false);
            navigate("/dashboard/inventory?currProductType=2", {
                state: { internalNavigation: true },
            });
        }
    }, [updatedBundleSuccess]);

    const checkScrollPosition = () => {
        if (modalRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = modalRef.current;
            setAtBottom(scrollTop + clientHeight >= scrollHeight);
        }
    };

    const ChannelDisplay = (channels) => {
        const maxDisplay = 2;
        const displayedChannels = channels?.slice(0, maxDisplay);
        const remainingCount = channels?.length > maxDisplay ? channels?.length - maxDisplay : 0;

        return (
            <>
                {channels?.length > 0 ? (
                    <div className="flex items-center space-x-3">
                        <div className="flex items-center">
                            {displayedChannels?.map((channel) => (
                                <span key={channel}>
                                    {displayProductChannelIcon(channel, "!w-[14px] !h-[14px]")}
                                </span>
                            ))}
                            {remainingCount > 0 && (
                                <span className="text-sm">+{remainingCount}</span>
                            )}
                        </div>
                        <span>
                            {displayedChannels?.join(", ")}
                            {remainingCount > 0 && `, +${remainingCount}`}
                        </span>
                    </div>
                ) : (
                    <div>
                        <p>N/A</p>
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener("scroll", checkScrollPosition);
            return () => modalRef.current?.removeEventListener("scroll", checkScrollPosition);
        }
    }, []);

    const {
        isFetching: analyticsLoading,
        data: productAnalytics,
        salesBreakdown,
    } = useGetProductsAnalytics(warehouseValue?.value, selectedProduct?.value, dateRange);

    const [shipmentDetails, setShipmentDetails] = useState<{ [key: string]: any }>({
        billOfLading: "",
        trackingLink: "",
        carrier: "",
        billNumber: "",
        destination: "",
        additionalInfo: "",
    });

    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        shipmentSearch: "",
    });

    const [warehouseList, setWarehouseList] = useState([]);
    const { fetchedWarehouseListSuccess } = useAppSelector((state) => state.inventory);

    const handleSelectAllProducts = (deselect = false) => {
        const selectedProducts = deselect ? [] : data;
        setSelectProduct(selectedProducts);
    };

    const tableProductHeader = [
        {
            title: "Product name",
            widthClass: "w-[40%] !px-3 !border-r-none",
            // onSelectAll: () => {
            //     handleSelectAllProducts(selectProduct?.length > 0);
            // },
            // isAllChecked: selectProduct?.length === data?.length,
        },
        { title: "Current stock level", widthClass: "w-[20%] !border-r-none" },
        { title: "Unit price", widthClass: "w-[20%] !border-r-none" },
    ];

    const handleCheckboxClick = (itemSelected) => {
        const addedProductsCopy = [...addedProducts];
        const products = addedProductsCopy?.[activeIndex]?.bundleItems?.map(
            (product) => product?.inventory
        );
        const check = products.some((product) => product?._id === itemSelected?._id);

        const updatedSelectProduct = check
            ? products.filter((product) => product?._id !== itemSelected?._id)
            : [...products, itemSelected];

        addedProductsCopy[activeIndex].bundleItems = updatedSelectProduct?.map((product) => ({
            inventory: product,
        }));

        setAddedProducts(addedProductsCopy);
    };

    const tableProductBody = data?.map((item, idx) => {
        const products = addedProducts?.[activeIndex]?.bundleItems?.map(
            (product) => product?.inventory
        );

        const isChecked = products?.some((product) => product?._id === item?._id);

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium   items-center flex space-x-3">
                            {item?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        item?.productImageDetails?.productAvatar ||
                                        item?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={item?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">{item.productName}</p>
                                <p className="text-slate-500 ">{item.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleCheckboxClick(item);
                },

                tableDataDivClass: "!pl-3",
                widthClass: `!border-none`,
            },
            {
                content: (
                    <div
                        className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                    >
                        <p>
                            {item?.productStockDetails?.stockLevel
                                ? item?.productStockDetails?.stockLevel?.toLocaleString()
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
                widthClass: `!border-none`,
            },
            {
                content: (
                    <div
                        className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.unitCost?.currency)}{" "}
                            {formatMoney().format(item?.unitCost?.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
                widthClass: `!border-none`,
            },
        ];
    });

    const productDetailsTableHeader = [
        { title: "Product name", widthClass: "w-[30.6%]" },
        { title: "SKU", widthClass: "w-[15.2%]" },
        { title: "Qty", widthClass: "w-[15.2%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    const handleDeleteRow = (index) => {
        const updatedData = productData.filter((_, idx) => idx !== index);
        setProductData(updatedData);
    };

    const handleAddRow = () => {
        const newRow = {
            name: "",
            inventoryCategory: "",
            qty: 0,
            unitOfMeasurement: "",
            amount: 0,
        };

        setProductData([...productData, newRow]);
    };

    useEffect(() => {
        const filteredData = recentIncident?.purchaseOrder?.itemsOrServices?.filter(
            (item) => item?.inventoryId
        );
        setProductData(filteredData || []);
    }, [recentIncident?.purchaseOrder?.itemsOrServices]);

    const updatedData = [...productData];

    useEffect(() => {
        if (address?.origin) {
            const countryObject = address?.origin?.details?.[0]?.address_components?.find((obj) =>
                obj.types.includes("country")
            );
            if (countryObject) {
                setCountry(countryObject.long_name);
            }
        }
    }, [address?.origin]);

    useEffect(() => {
        if (Boolean(createdShipmentSuccess)) {
            setResponse(createdShipmentSuccess);
            setShowAddShipmentReminder(false);
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id));
            setshowSuccessShipmentAdded(true);
            reFetchData();
        }
    }, [createdShipmentSuccess, dispatch]);

    // useEffect(() => {
    //     if (
    //         (!currTab || (currTab && currTab === "1")) &&
    //         (!currProductType || (currProductType && currProductType === "1"))
    //     ) {
    //         handleFilterChange("Finished Goods");
    //     }
    // }, [currTab, currProductType]);

    const handleSubmit = () => {
        const body: { [key: string]: any } = {
            channel: "web",
            origin: {
                address: address?.origin?.address,
                lng: address?.origin?.lng,
                lat: address?.origin?.lat,
                details: [address?.origin?.address],
                country: country,
            },

            destinationId: shipmentDetails?.destination,
            purchaseOrderId: replishmentStock?.purchaseOrder?._id,
            shipmentMode: mode,
            productDetails: productData?.map((product) => ({
                category: product?.inventory?.category ?? product?.name,
                inventoryId: product?.inventoryId,
                quantity: Number(Number(product?.qty)?.toFixed(0)),
            })),
        };
        if (shipmentDetails?.additionalInfo) {
            body.additionalNotes = shipmentDetails?.additionalInfo;
        }
        if (mode.toLowerCase() === "land") {
            body.trackingLink = shipmentDetails?.trackingLink;
        }
        if (mode.toLowerCase() === "air") {
            body.airWayBillNumber = shipmentDetails?.billNumber;
            body.carrier = shipmentDetails?.carrier;
        }
        if (mode.toLowerCase() === "ocean") {
            if (shipmentDetails?.trackingLink) {
                body.trackingLink = shipmentDetails?.trackingLink;
            }
            body.billOfLading = shipmentDetails?.billOfLading;
        }
        dispatch(shipmentActions.createShipment(body));
    };

    useEffect(() => {
        if (recentIncident?.purchaseOrder?._id) {
            dispatch(
                shipmentActions.getPoShipment(
                    recentIncident?.purchaseOrder?._id,
                    limit,
                    page,
                    debouncedFilter.shipmentSearch
                )
            );
        }
    }, [dispatch, limit, page, recentIncident?.purchaseOrder?._id, debouncedFilter.shipmentSearch]);

    useEffect(() => {
        if (Boolean(fetchPoShipmentSuccess)) {
            setPoShipment(fetchPoShipmentSuccess?.data);
            setPoShipmentPagination({
                current: fetchPoShipmentSuccess?.pagination.current,
                number_of_pages: fetchPoShipmentSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchPoShipmentSuccess]);

    const handleReplaceUpload = (indexToReplace: number, newFileName: string, newFile: File) => {
        setReplacingAttachment(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${newFileName}`,
            newFile,
            (url) => {
                setAttachments((prevAttachments) => {
                    const updatedAttachments = [...prevAttachments];
                    updatedAttachments[indexToReplace] = {
                        filename: newFileName,
                        path: url,
                        documentType: "pdf",
                    };
                    return updatedAttachments;
                });

                setReplacingAttachment(false);
            }
        );
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${fileName}`,
                file,
                (url) => {
                    setAttachments((prev) => [
                        ...prev,
                        { path: url, filename: fileName, documentType: "pdf" },
                    ]);

                    setUploadingAttachment(false);
                }
            );
        }
    };

    const handleRemoveUpload = (idx) => {
        const newArr = [...attachments];

        const imagePath = decodeURIComponent(
            attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachments(newArr);
            setDeletingImage(false);
        });
    };

    const handleUpdateShipment = () => {
        const body = {
            attachments,
        };
        dispatch(shipmentActions.updateShipment(body, shipmentId));
    };

    useEffect(() => {
        if (Boolean(updatedShipmentSuccess)) {
            dispatch(shipmentActions.resetUpdateShipmentSuccess());
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id, 20));
            reFetchData();
            setOpenDocument(false);
            setOpenReStockModal(true);
        }
    }, [updatedShipmentSuccess, dispatch]);

    const handleTableChange = (name, value, idx) => {
        const selectedProduct = productData?.find((product) => product.name === value);

        if (selectedProduct && !updatedData.some((product) => product.name === value)) {
            updatedData[idx] = {
                ...updatedData[idx],
                ...selectedProduct,
                qty: selectedProduct?.qty || 0,
            };

            setProductData(updatedData);
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Product selected already exists"} type="error" />
            ));
        }
    };

    const handleQuantityChange = (e, idx) => {
        const { value } = e.target;
        const updatedData = [...productData];

        updatedData[idx] = {
            ...updatedData[idx],
            qty: value,
        };

        setProductData(updatedData);
    };

    const handleWarehouseChange = (e) => {
        const { value } = e.target;
        const arrayValue = value !== "" && value?.split("-");
        if (expandAnalyticsCard === "totalStockValue") {
            setTopWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "inStock") {
            setInStockWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "belowMinimum") {
            setReStockWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "outOfStock") {
            setOutStockWarehouseId(arrayValue[1]);
        }

        setWarehouse(value);
    };

    const productDetailsTableBody = productData?.map((data: any, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`-ml-3 pr-3  flex font-rocGroteskMedium items-center text-sm text-slate-500 `}
                >
                    <SelectInput
                        name="category"
                        value={data.name}
                        placeholder={"Enter Product"}
                        handleChange={(name, value) => handleTableChange(name, value, idx)}
                        className={"w-full !py-0 border-none"}
                        isRequired={true}
                        clearValue
                        dropdownOptions={replishmentStock?.purchaseOrder?.itemsOrServices?.map(
                            (res) => ({
                                label: (
                                    <div className={`font-rocGroteskMedium -mt-2 flex space-x-1`}>
                                        <VendorAvatar
                                            imageSrc={
                                                res?.inventory?.productImageDetails?.productAvatar
                                            }
                                            size={24}
                                            name={res?.name}
                                        />

                                        <p className="text-slate-500 ">{res?.name}</p>
                                    </div>
                                ),
                                value: res?.name,
                            })
                        )}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <p className="text-slate-700">{data?.inventory?.sku || "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full -ml-3  border-r pr-1  font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <TextInput
                        value={Number(data?.qty)?.toFixed(0)}
                        name={"qty"}
                        type={"number"}
                        inputContainerClassname="!border-none"
                        inputPlaceholder="Enter qty"
                        onChange={(e) => handleQuantityChange(e, idx)}
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <i
                    className={`ri-delete-bin-line text-sm text-[#64748B] ${
                        productData[0] && "opacity-50"
                    } `}
                    onClick={() => productData?.length > 1 && handleDeleteRow(idx)}
                ></i>
            ),
        },
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleImportArchivedProducts = () => {
        const body = {
            inventories: selectedArchiveProducts?.map((product) => ({
                inventoryId: product?._id,
                archived: false,
            })),
        };

        dispatch(inventoryActions.setArchiveStatus(body));
    };

  

    const handleArchivedProducts = () => {
        const body = {
            inventories: selectedInventories?.map((product) => ({
                inventoryId: product?._id,
                archived: true,
            })),
        };

        dispatch(inventoryActions.setArchiveStatus(body));
    };

    const handleTotalValueSortChange = (idx: number, sortValue: number) => {
        let sortKey;
        if (expandAnalyticsCard === "totalStockValue") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "unitPrice";
            } else if (idx === 4) {
                sortKey = "totalValue";
            } else if (idx === 6) {
                sortKey = "lastRestocked";
            }

            setSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "inStock") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "unitPrice";
            } else if (idx === 4) {
                sortKey = "totalValue";
            } else if (idx === 7) {
                sortKey = "lastRestocked";
            }
            setInStockSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "belowMinimum") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "lastRestocked";
            } else if (idx === 6) {
                sortKey = "reorderQty";
            } else if (idx === 7) {
                sortKey = "unitPrice";
            } else if (idx === 8) {
                sortKey = "totalValue";
            }
            setReStockSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "outOfStock") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 1) {
                sortKey = "quantity";
            } else if (idx === 2) {
                sortKey = "lastRestocked";
            } else if (idx === 6) {
                sortKey = "unitPrice";
            } else if (idx === 7) {
                sortKey = "totalValue";
            }
            setOutStockSortValues({ [sortKey]: sortValue });
        }
    };

    const handleChannelsChange = (value) => {
        setChannelFilter(value);

        if (value === "All channels") {
            if (expandAnalyticsCard === "totalStockValue") {
                setTotalStockValueChannel("");
            } else if (expandAnalyticsCard === "inStock") {
                setInStockValueChannel("");
            } else if (expandAnalyticsCard === "belowMinimum") {
                setReStockValueChannel("");
            } else if (expandAnalyticsCard === "outOfStock") {
                setOutStockValueChannel("");
            }
        } else {
            if (expandAnalyticsCard === "totalStockValue") {
                setTotalStockValueChannel(value);
            } else if (expandAnalyticsCard === "inStock") {
                setInStockValueChannel(value);
            } else if (expandAnalyticsCard === "belowMinimum") {
                setReStockValueChannel(value);
            } else if (expandAnalyticsCard === "outOfStock") {
                setOutStockValueChannel(value);
            }
        }
    };

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, 1, debouncedFilter.search));
    }, [dispatch, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        if (Boolean(setArchiveStatusSuccess) && activeTab === 3) {
            const updatedProducts = archivedProducts?.filter((product) => {
                return !setArchiveStatusSuccess?.inventories?.some(
                    (inventory) => inventory?._id === product?._id
                );
            });
            setArchiveProducts(updatedProducts);
            setUnarchiveProductsModal(false);
            setSelectedArchiveProducts([]);
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={"Product(s) unarchived successfully"}
                    type={"success"}
                />
            ));
            dispatch(inventoryActions.resetSetArchiveStatusSuccess());
        }

        if (Boolean(setArchiveStatusSuccess) && activeTab === 0) {
            const updatedProducts = data?.filter((product) => {
                return !setArchiveStatusSuccess?.inventories?.some(
                    (inventory) => inventory?._id === product?._id
                );
            });
            setProductsListData(updatedProducts);
            setArchiveProductsModal(false);
            setSelectedArchiveProducts([]);
            toast.custom((t) => (
                <CustomToast t={t} message={"Product(s) archived successfully"} type={"success"} />
            ));
            dispatch(inventoryActions.resetSetArchiveStatusSuccess());
        }
    }, [dispatch, setArchiveStatusSuccess, archivedProducts, activeTab, data]);

    useEffect(() => {
        if (Boolean(exportOutStockListSuccess)) {
            jsonToCsvExport({
                data: exportOutStockListSuccess?.products,
                filename: "out_of_stock_products",
            });
            setExpandAnalyticsCard("");
            dispatch(inventoryActions.resetExportStockOutList());
        }
        if (Boolean(exportInStockListSuccess)) {
            jsonToCsvExport({
                data: exportInStockListSuccess?.products,
                filename: "in_stock_products",
            });
            setExpandAnalyticsCard("");
            dispatch(inventoryActions.resetExportStockInList());
        }
        if (Boolean(exportInventoryListSuccess)) {
            jsonToCsvExport({
                data: exportInventoryListSuccess?.products,
                filename: "inventory_list",
            });
            dispatch(inventoryActions.resetExportInventoryListSuccess());
        }
    }, [dispatch, exportOutStockListSuccess, exportInStockListSuccess, exportInventoryListSuccess]);

    const handleDateChange = (e) => {
        const { value } = e.target;
        const [val, period] = value.split("-");
        setModalDurationFilter(period);
        setCustomFilter(val);
        let startDate, endDate;
        switch (val) {
            case "yearly":
                startDate = moment().startOf("year").format("YYYY-MM-DD");
                endDate = moment().endOf("year").format("YYYY-MM-DD");
                break;
            case "monthly":
                startDate = moment().startOf("month").format("YYYY-MM-DD");
                endDate = moment().endOf("month").format("YYYY-MM-DD");
                break;
            case "weekly":
                startDate = moment().startOf("week").format("YYYY-MM-DD");
                endDate = moment().endOf("week").format("YYYY-MM-DD");
                break;
            case "daily":
                startDate = moment().startOf("day").format("YYYY-MM-DD");
                endDate = moment().endOf("day").format("YYYY-MM-DD");
                break;
            default:
                startDate = null;
                endDate = null;
        }
        if (expandAnalyticsCard === "totalStockValue") {
            setStartDateRange(startDate);
            setEndDateRange(endDate);
        } else if (expandAnalyticsCard === "inStock") {
            setInStockStartDateRange(startDate);
            setInStockEndDateRange(endDate);
        } else if (expandAnalyticsCard === "belowMinimum") {
            setReStockStartDateRange(startDate);
            setReStockEndDateRange(endDate);
        } else if (expandAnalyticsCard === "outOfStock") {
            setOutStockStartDateRange(startDate);
            setOutStockEndDateRange(endDate);
        }
    };

    const handleSelectAllInventory = (deselect = false) => {
        const selectedInventoryData = deselect ? [] : data;
        setSelectedInventories(selectedInventoryData);
    };

    const handleSortVariantTable = (idx: number, sortValue: number) => {
        let sortBy;
        if (idx === 2) {
            sortBy = "stockLevel";
        } else if (idx === 3) {
            sortBy = "stockoutDate";
        }
        setVariantTableFilter({
            sortBy,
            ...(sortValue === 1 ? { sortDirection: "asc" } : { sortDirection: "desc" }),
        });
    };

    const handleSortNoVariantTable = (idx: number, sortValue: number) => {
        let sortBy;
        if (idx === 0) {
            sortBy = "productName";
        } else if (idx === 1) {
            sortBy = "stockLevel";
        } else if (idx === 2) {
            sortBy = "variantCount";
        } else if (idx === 3) {
            sortBy = "forecastedNeeds";
        } else if (idx === 4) {
            sortBy = "stockoutDate";
        }
        setAllProductsNoVariantValues({
            sortBy,
            ...(sortValue === 1 ? { sortDirection: "asc" } : { sortDirection: "desc" }),
        });
    };
    const allProductsTableHeader = [
        { title: "Product name", widthClass: "w-[300px]", sortIcon: true },
        {
            title: "Current stock level",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "product-name",
            tooltipContent: "The total available stock for this product across all locations.",
        },
        {
            title: "Number of variants",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "variants",
            tooltipContent:
                "The total number of variations (e.g., sizes, colors, packaging) for this product.",
        },
        {
            title: "Forecasted needs",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "Forecasted-needs",
            tooltipContent:
                "The estimated stock required to meet demand within the forecasting period, based on product lead time.",
        },
        {
            title: "Stockout date",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "Stockout-date",
            tooltipContent:
                "The earliest date when any variant of this product is expected to run out of stock, based on product lead time.",
        },
    ];

    const allProductsTableBody = allProductsList?.map((product, idx) => {
        const givenDate = moment(product?.stockoutDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }

        return [
            {
                content: (
                    <div
                        className={`py-3 px-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImage?.src ? (
                                <VendorAvatar
                                    imageSrc={product?.productImage?.src}
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`all-product-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#all-product-column-${idx}`}
                                        place={"right"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/global-products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.stockLevel ? formatMoney().format(product?.stockLevel) : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.variantCount
                                ? formatMoney().format(product?.variantCount)
                                : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    // <div
                    //     className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    // >
                    //     <div className="flex items-center space-x-1.5">
                    //         {Boolean(product?.replenishment) && (
                    //             <div>
                    //                 <a id={`forecast-needs-${idx}`}>
                    //                     <span className="material-symbols-outlined text-[14px] text-r-50">
                    //                         warning
                    //                     </span>
                    //                 </a>

                    //                 <Tooltip
                    //                     anchorSelect={`#forecast-needs-${idx}`}
                    //                     place={"top"}
                    //                     content={
                    //                         Number(product?.productStockDetails?.stockLevel) < 1
                    //                             ? "Product is out of stock"
                    //                             : "Stock for this product may soon run out."
                    //                     }
                    //                     style={{ width: "180px", zIndex: 9999 }}
                    //                 />
                    //             </div>
                    //         )}
                    //         <span>
                    //             {product?.replenishment
                    //                 ? Math.round(product?.replenishment)?.toLocaleString()
                    //                 : "N/A"}
                    //         </span>
                    //     </div>
                    // </div>
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.forecastedNeeds
                                ? formatMoney().format(product?.forecastedNeeds)
                                : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium !border-b border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="text-sm font-rocGroteskMedium">
                                {differenceInDays <= 0 ? (
                                    "N/A"
                                ) : (
                                    <>
                                        <span
                                            className={`block ${
                                                differenceInDays > 10
                                                    ? "text-carribean-green"
                                                    : "text-[#E4281D]"
                                            }`}
                                        >
                                            {formatMoney().format(differenceInDays)} days
                                        </span>
                                        <span className={`block text-slate-500`}>
                                            {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                ),
                widthClass: "!border-b",
            },
        ];
    });

    const tableHeader = [
        {
            title: "Product name",
            widthClass: "w-[300px]",
            onSelectAll: () => {
                handleSelectAllInventory(selectedInventories?.length > 0);
            },
            isAllChecked: selectedInventories?.length === data?.length,
        },
        { title: "SKU No.", widthClass: "w-[200px]" },
        { title: "Current stock", widthClass: "w-[200px]", sortIcon: true },
        {
            title: "Stockout date",
            widthClass: "w-[200px]",
            sortIcon: true,
        },
        { title: "Forecasted needs", widthClass: "w-[200px]" },
        { title: "Sales channels", widthClass: "w-[200px]" },
        { title: "Categories", widthClass: "w-[200px]" },

        { title: "FC transfer", widthClass: "w-[200px]" },
        { title: "Inbound QTY", widthClass: "w-[200px]" },
        { title: "On order", widthClass: "w-[150px]" },
        { title: "Ongoing sales", widthClass: "w-[150px]" },

        { title: "Action", widthClass: "w-[150px]" },
    ];

    // selectedInventories, setSelectedInventories
    const handleInventoryCheckboxClick = (itemSelected) => {
        const selectedInventoriesCopy = [...selectedInventories];
        const check = selectedInventoriesCopy?.find(
            (inventory) => inventory._id === itemSelected?._id
        );

        const updatedSelectInventory = check
            ? selectedInventoriesCopy.filter((product) => product?._id !== itemSelected?._id)
            : [...selectedInventoriesCopy, itemSelected];

        setSelectedInventories(updatedSelectInventory);
    };

    const tableBody = data?.map((product, idx) => {
        const isChecked = [...selectedInventories].find(
            (inventory) => inventory._id === product?._id
        );
        const givenDate = moment(product?.stockoutDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`product-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#product-column-${idx}`}
                                        place={"right"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleInventoryCheckboxClick(product);
                },
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        <div>
                            <a id={`sku-column-${idx}`}>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(product?.sku, { length: 14 })}
                                </span>
                            </a>
                            <Tooltip
                                anchorSelect={`#sku-column-${idx}`}
                                place={"top"}
                                content={product?.sku}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.productStockDetails?.stockLevel?.toLocaleString() || "0"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="text-sm font-rocGroteskMedium">
                                {product?.productStockDetails?.stockLevel === 0 ? (
                                    "Sold out"
                                ) : differenceInDays <= 0 ? (
                                    "N/A"
                                ) : (
                                    <>
                                        <span
                                            className={`block ${
                                                differenceInDays > 10
                                                    ? "text-carribean-green"
                                                    : "text-[#E4281D]"
                                            }`}
                                        >
                                            {formatMoney().format(differenceInDays)} days
                                        </span>
                                        <span className={`block text-slate-500`}>
                                            {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-1.5">
                            {Boolean(product?.replenishment) && (
                                <div>
                                    <a id={`forecast-needs-${idx}`}>
                                        <span className="material-symbols-outlined text-[14px] text-r-50">
                                            warning
                                        </span>
                                    </a>

                                    <Tooltip
                                        anchorSelect={`#forecast-needs-${idx}`}
                                        place={"top"}
                                        content={
                                            Number(product?.productStockDetails?.stockLevel) < 1
                                                ? "Product is out of stock"
                                                : "Stock for this product may soon run out."
                                        }
                                        style={{ width: "180px", zIndex: 9999 }}
                                    />
                                </div>
                            )}
                            <span>
                                {product?.replenishment
                                    ? Math.round(product?.replenishment)?.toLocaleString()
                                    : "N/A"}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <a id={`channel-column-${idx}`}>
                                {product?.channels?.length > 1 ? (
                                    <div className="flex items-center space-x-2">
                                        {product?.channels.map((channel) => (
                                            <span key={channel}>
                                                {displayProductChannelIcon(channel)}
                                            </span>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex items-center space-x-2">
                                        {displayProductChannelIcon(product?.channels?.[0])}
                                        <span>
                                            {truncate(
                                                product?.channels?.[0] === "quickbooks"
                                                    ? "QB"
                                                    : product?.channels?.[0],
                                                { length: 10 }
                                            )}
                                        </span>
                                    </div>
                                )}
                            </a>

                            <Tooltip
                                anchorSelect={`#channel-column-${idx}`}
                                place={"top"}
                                content={
                                    product?.channels?.length > 1
                                        ? product?.channels?.map((channel) => channel)?.join(", ")
                                        : product?.channels?.[0]
                                }
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.inventoryCategory || "N/A"}</span>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.amazon?.fcProcessingQuantity
                                ? Math.round(
                                      product?.amazon?.fcProcessingQuantity
                                  )?.toLocaleString()
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.amazon?.inboundShippedQuantity
                                ? Math.round(
                                      product?.amazon?.inboundShippedQuantity
                                  )?.toLocaleString()
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{Math.round(product?.onOrder)?.toLocaleString()}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.unfulfilledSale?.toLocaleString()}</span>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {Number(product?.productStockDetails?.stockLevel) <= 0 ? (
                            <Button
                                isLoading={
                                    isFetchingRecentIncident && getProductId === product?._id
                                }
                                btnText="Restock"
                                disabled={isFetchingRecentIncident}
                                btnPermission={["all_access", "create_purchase_order"]}
                                btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                                onClick={() => {
                                    setGetProductId(product?._id);
                                    navigate(
                                        `/dashboard/purchase/create-purchase-request?inventory=${product?._id}`,
                                        { state: { internalNavigation: true } }
                                    );
                                    // handleIncidentId(product?._id);
                                }}
                            />
                        ) : product?.inventoryIncident &&
                          product?.inventoryIncident?.incidentType?.toLowerCase() ===
                              "overstocking" ? (
                            <Button
                                btnText="Overstocked"
                                btnClassname="!px-[8px] !py-2 !w-fit !h-[35px] !text-[13px] !text-white !whitespace-nowrap !bg-[#FF0000]"
                                icon={
                                    <i className="ri-alarm-warning-line text-[16px] text-white"></i>
                                }
                            />
                        ) : (
                            <Button
                                isLoading={
                                    isFetchingRecentIncident && getProductId === product?._id
                                }
                                btnText="Restock"
                                disabled={isFetchingRecentIncident}
                                btnPermission={["all_access", "create_purchase_order"]}
                                btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                                onClick={() => {
                                    setGetProductId(product?._id);
                                    navigate(
                                        `/dashboard/purchase/create-purchase-request?inventory=${product?._id}`,
                                        { state: { internalNavigation: true } }
                                    );
                                    // handleIncidentId(product?._id);
                                }}
                            />
                        )}
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = data?.map((product, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/inventory/products/${product?._id}`, {
                    state: { internalNavigation: true },
                });
            },
            topString: product?._id,
            avatar: product?.productImageDetails?.productImages?.[0] ? (
                <VendorAvatar
                    imageSrc={
                        product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0]
                    }
                    size={32}
                    name={product?.productName}
                    containerClassname="!rounded-md"
                />
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div className="text-sm font-rocGroteskMedium">
                    <p className="font-rocGroteskMedium text-sm">
                        {truncate(product?.productName, { length: 25 })}
                    </p>
                    <p className="text-slate-500 font-rocGroteskMedium text-sm break-all">
                        SKU: {truncate(product?.sku, { length: 10 })} | {product?.inventoryCategory}
                    </p>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center space-x-2">
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        {/* {product?.channel ? (
                            <div className="flex items-center space-x-2 ">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    {truncate(product?.channel, { length: 15 })}
                                </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Synth
                                </span>
                            </div>
                        )} */}
                        {product?.channels?.length > 1 ? (
                            <div className="flex items-center space-x-2">
                                {product?.channels.map((channel) => (
                                    <span key={channel}>{displayProductChannelIcon(channel)}</span>
                                ))}
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channels[0])}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    {truncate(
                                        product?.channels[0] === "quickbooks"
                                            ? "QB"
                                            : product?.channels[0],
                                        { length: 10 }
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        <div className="flex items-center">
                            <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                Curr_stock:{" "}
                                {product?.productStockDetails?.stockLevel?.toLocaleString() ||
                                    "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        };
    });

    const handleSelectAllBundles = (deselect = false) => {
        const selectedBundleData = deselect ? [] : bundleList;
        setSelectedBundles(selectedBundleData);
    };

    const bundleTableHeader = [
        {
            title: "Bundle",
            widthClass: "w-[47%]",
            onSelectAll: () => {
                handleSelectAllBundles(selectedBundles?.length > 0);
            },
            isAllChecked: selectedBundles?.length === bundleList?.length,
        },
        { title: "Price", widthClass: "w-[16.6%]" },
        { title: "Stock Level", widthClass: "w-[15.5%]" },
        { title: "Products", widthClass: "w-[18.2%]" },
        { title: "", widthClass: "w-[3%]" },
    ];

    const handleBundleCheckboxClick = (itemSelected) => {
        const check = selectedBundles.some((bundle) => bundle._id === itemSelected?._id);

        const updatedSelectBundle = check
            ? selectedBundles.filter((product) => product?._id !== itemSelected?._id)
            : [...selectedBundles, itemSelected];

        setSelectedBundles(updatedSelectBundle);
    };

    const bundleTableBody = bundleList?.map((product, idx) => {
        const isChecked = selectedBundles.some((bundle) => bundle._id === product?._id);

        const bundleItemsCanCreate = product?.bundleItems?.map((item) => {
            const inventory = item?.inventory;

            const bundleItemCanCreate =
                inventory?.productStockDetails?.stockLevel && item?.quantity
                    ? (inventory?.productStockDetails?.stockLevel || 0) / (item?.quantity || 0)
                    : 0;

            return bundleItemCanCreate;
        });

        const maxBundles = bundleItemsCanCreate?.length > 0 ? Math.min(...bundleItemsCanCreate) : 0;

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div>
                                <a id={`bundleName-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.bundleName, {
                                            length: 55,
                                        })}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#bundleName-column-${idx}`}
                                    place={"top"}
                                    content={product?.bundleName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/bundle/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleBundleCheckboxClick(product);
                },
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <span>
                                {getCurrencyFromCurrencyCode(product?.salesPrice?.currency)}
                                {formatMoney().format(product?.salesPrice?.amount)}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{formatMoney().format(Math.floor(maxBundles))}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.totalProducts)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View Bundle",
                                    value: "view bundle",
                                    action: () => {
                                        navigate(`/dashboard/inventory/bundle/${product?._id}`, {
                                            state: { internalNavigation: true },
                                        });
                                    },
                                },
                                {
                                    label: "Edit Bundle",
                                    value: "Edit bundle",
                                    action: () => {
                                        navigate(
                                            `/dashboard/inventory/edit-bundle/${product?._id}`,
                                            { state: { internalNavigation: true } }
                                        );
                                    },
                                },

                                // {
                                //     label: "Delete Bundle",
                                //     value: "Delete bundle",
                                //     action: () => {
                                //         // setConfirmDeleteModal(true);
                                //         // setShowModal(true);
                                //         // setDeleteSupplier({
                                //         //     id: vendor?.vendorId,
                                //         //     name: vendor?.vendorData?.profile?.businessName,
                                //         // });
                                //     },
                                // },
                            ]}
                        />
                    </div>
                ),
            },
        ];
    });

    const bundleMobileTableBody = bundleList?.map((product, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/inventory/bundle/${product?.inventoryId}`, {
                    state: { internalNavigation: true },
                });
            },
            topString: product?._id,
            avatar: product?.productImageDetails?.productImages?.[0] ? (
                <VendorAvatar
                    imageSrc={
                        product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0]
                    }
                    size={32}
                    name={product?.productName}
                    containerClassname="!rounded-md"
                />
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div className="text-sm font-rocGroteskMedium">
                    <p className="font-rocGroteskMedium text-sm">
                        {truncate(product?.productName, { length: 25 })}
                    </p>
                    <p className="text-slate-500 font-rocGroteskMedium text-sm break-all">
                        SKU: {product?.sku} | {product?.inventoryCategory}
                    </p>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center space-x-2">
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        {product?.channel ? (
                            <div className="flex items-center space-x-2 ">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    {truncate(product?.channel, { length: 15 })}
                                </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Synth
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        <div className="flex items-center">
                            <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                Curr_stock:{" "}
                                {product?.productStockDetails?.stockLevel?.toLocaleString() ||
                                    "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        };
    });

    const archiveTableHeader = [
        {
            title: "Product name",
            widthClass: "w-[40%]",
            onSelectAll: () => {
                if (archivedProducts?.length === selectedArchiveProducts?.length) {
                    setSelectedArchiveProducts([]);
                } else {
                    setSelectedArchiveProducts(archivedProducts);
                }
            },
            isAllChecked:
                selectedArchiveProducts?.length > 0 &&
                archivedProducts?.length === selectedArchiveProducts?.length,
        },
        { title: "SKU No.", widthClass: "w-[8.3%]" },
        { title: "Sales channels", widthClass: "w-[12.5%]" },
        { title: "Categories", widthClass: "w-[10.2%]" },
        { title: "Status", widthClass: "w-[10.2%]" },
        { title: "Current stock", widthClass: "w-[9.2%]" },
        { title: "Action", widthClass: "w-[9.5%]" },
    ];

    const archiveTableBody = archivedProducts?.map((product, idx) => {
        const isSelected = selectedArchiveProducts.find((prod) => {
            return prod?._id === product?._id;
        });

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div>
                                <div>
                                    <a id={`product-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#product-column-${idx}`}
                                        place={"top"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                isItemChecked: Boolean(isSelected),
                onCheckBoxClick: () => {
                    const archiveProductsCopy = [...selectedArchiveProducts];
                    const selectedArchive = archiveProductsCopy.find((prod) => {
                        return prod?._id === product?._id;
                    });

                    if (Boolean(selectedArchive)) {
                        setSelectedArchiveProducts((prev) =>
                            prev.filter((prod) => prod?._id !== product?._id)
                        );
                    } else {
                        setSelectedArchiveProducts((prev) => [...prev, product]);
                    }
                },
                tableDataDivClass: `!pl-0`,
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        <div>
                            <a id={`sku-column-${idx}`}>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(product?.sku, { length: 10 })}
                                </span>
                            </a>
                            <Tooltip
                                anchorSelect={`#sku-column-${idx}`}
                                place={"top"}
                                content={product?.sku}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        {product?.channel ? (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel)}
                                {/* <span>{truncate(product?.channel, { length: 10 })} </span> */}
                                <div>
                                    <a id={`channel-column-${idx}`}>
                                        <span>{truncate(product?.channel, { length: 10 })}</span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#channel-column-${idx}`}
                                        place={"top"}
                                        content={product?.channel}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel)}
                                {/* <span>Synth</span> */}
                                <div>
                                    <a id={`channel-column-${idx}`}>
                                        <span>Synth</span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#channel-column-${idx}`}
                                        place={"top"}
                                        content={"Synth"}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.inventoryCategory || "N/A"}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span className="py-[2px] rounded-[16px] px-2 text-xs font-rocGroteskMedium bg-slate-200">
                            Archived
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.productStockDetails?.stockLevel
                                ? product?.productStockDetails?.stockLevel?.toLocaleString() +
                                  ` ${product?.productStockDetails?.unitOfMeasurement || ""}`
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {!Boolean(isSelected) && (
                            <Button
                                // isLoading={isFetchingRecentIncident && getProductId === product?._id}
                                btnText="Unarchive"
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                // disabled={isFetchingRecentIncident}
                                btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !bg-slate-200 !whitespace-nowrap"
                                onClick={() => {
                                    setSelectedArchiveProducts([product]);
                                    setUnarchiveProductsModal(true);
                                }}
                            />
                        )}
                    </div>
                ),
            },
        ];
    });

    const archiveMobileTableBody = data?.map((product, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/inventory/products/${product?._id}`, {
                    state: { internalNavigation: true },
                });
            },
            topString: product?._id,
            avatar: product?.productImageDetails?.productImages?.[0] ? (
                <VendorAvatar
                    imageSrc={
                        product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0]
                    }
                    size={32}
                    name={product?.productName}
                    containerClassname="!rounded-md"
                />
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div className="text-sm font-rocGroteskMedium">
                    <p className="font-rocGroteskMedium text-sm">
                        {truncate(product?.productName, { length: 25 })}
                    </p>
                    <p className="text-slate-500 font-rocGroteskMedium text-sm break-all">
                        SKU: {product?.sku} | {product?.inventoryCategory}
                    </p>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center space-x-2">
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        {product?.channel ? (
                            <div className="flex items-center space-x-2 ">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    {truncate(product?.channel, { length: 15 })}
                                </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Synth
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        <div className="flex items-center">
                            <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                Curr_stock:{" "}
                                {product?.productStockDetails?.stockLevel?.toLocaleString() ||
                                    "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        };
    });

    const totalStockValueTableHeader = [
        { title: "Product", widthClass: "w-[25%]", sortIcon: true },
        { title: "Channels", widthClass: "w-[18.1%]" },
        { title: "Quantity", widthClass: "w-[10.6%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[8.7%]", sortIcon: true },
        { title: "Total Value", widthClass: "w-[11%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[14.6%]" },
        { title: "Last restocked", widthClass: "w-[11%]", sortIcon: true },
    ];

    const totalStockValueTableBody = rawTotalStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500 break-all">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {product?.channels?.length > 0 ? ChannelDisplay(product?.channels) : "N/A"}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <span>{formatMoney().format(product?.quantity)}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierDetails?.[0]?.profile?.logo ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.supplierDetails?.[0]?.profile?.avatar ||
                                        product?.supplierDetails?.[0]?.profile?.logo
                                    }
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.lastRestocked)}</span>
                    </div>
                ),
            },
        ];
    });

    const inStockValueTableHeader = [
        { title: "Product", widthClass: "w-[17%]", sortIcon: true },
        { title: "Channel", widthClass: "w-[17.8%]" },
        { title: "Quantity", widthClass: "w-[8.6%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[8.7%]", sortIcon: true },
        { title: "Total Value", widthClass: "w-[11%]", sortIcon: true },
        { title: "In Stock", widthClass: "w-[8.8%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[14.6%]" },
        { title: "Last restocked", widthClass: "w-[11%]", sortIcon: true },
    ];
    const inStockValueTableBody = rawInStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500 break-all">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.channels?.length > 0
                                ? ChannelDisplay(product?.channels)
                                : "N/A"}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <span>{formatMoney().format(product?.quantity)}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="">$ {formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="text-[#22C55E]">{product?.instock}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierDetails?.[0]?.profile?.logo ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.supplierDetails?.[0]?.profile?.avatar ||
                                        product?.supplierDetails?.[0]?.profile?.logo
                                    }
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.lastRestocked)}</span>
                    </div>
                ),
            },
        ];
    });

    const belowMinValueTableHeader = [
        { title: "Product", widthClass: "w-[18.6%]", sortIcon: true },
        { title: "Current Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Last Restocked", widthClass: "w-[12%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[9.1%]" },
        { title: "Supplier lead time", widthClass: "w-[10.8%]" },
        { title: "Reorder Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[10%]", sortIcon: true },
        { title: "Total value", widthClass: "w-[10%]", sortIcon: true },
        { title: "Action", widthClass: "w-[12.9%]" },
    ];
    const belowMinValueTableBody = rawRestockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500 break-all">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-[#FF3D1D] `}
                    >
                        <span className="r-50">
                            {formatMoney().format(product?.currentStockQty)}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{moment(product?.lastRestocked).format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierLogo ? (
                                <VendorAvatar
                                    imageSrc={product?.supplierLogo}
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {product?.leadTime ? (
                            <span className="">
                                {`${product?.leadTime?.value}  ${product?.leadTime?.duration} `}
                            </span>
                        ) : (
                            <span className="">N/A</span>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.reorderQty)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Button
                            btnText="Restock"
                            btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                            btnPermission={["all_access", "create_purchase_order"]}
                            onClick={() => {
                                setGetProductId(product?._id);
                                navigate(
                                    `/dashboard/purchase/create-purchase-request?inventory=${product?._id}`,
                                    { state: { internalNavigation: true } }
                                );
                            }}
                        />
                        {/* <Dropdown
                            name="filter"
                            value={""}
                            dropdownTriggerClassName={" "}
                            // handleChange={(name, value) => handleFilterChange(value)}
                            dropdown={
                                <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                    <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                            {productCategory || "Re-order"}
                                        </span>
                                        {Boolean(productCategory) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    handleClearFilter();
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )}
                                    </div>
                                    <div className=" py-2.5">
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 left-[-38px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="py-[2px] px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[10px] whitespace-nowrap text-slate-500 font-rocGroteskMedium">
                                                    Schedule re-order date
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Re-order tomorrow
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Re-order next week
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Custom date
                                                </span>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        /> */}
                    </div>
                ),
            },
        ];
    });
    const outOfStockValueTableHeader = [
        { title: "Product", widthClass: "w-[18.6%]", sortIcon: true },
        { title: "Current Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Last Restocked", widthClass: "w-[12%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[9.1%]" },
        { title: "Supplier lead time", widthClass: "w-[10.8%]" },
        { title: "Forecasted needs", widthClass: "w-[10%]" },
        { title: "Unit price", widthClass: "w-[10%]", sortIcon: true },
        { title: "Total value", widthClass: "w-[10%]", sortIcon: true },
        { title: "Action", widthClass: "w-[12.9%]" },
    ];
    const outOfStockValueTableBody = rawOutStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500 break-all">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="r-50">
                            {formatMoney().format(product?.currentStockQty)}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{moment(product?.lastRestocked).format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierLogo ? (
                                <VendorAvatar
                                    imageSrc={product?.supplierLogo}
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {product?.leadTime ? (
                            <span className="">
                                {`${product?.leadTime?.value}  ${product?.leadTime?.duration} `}
                            </span>
                        ) : (
                            <span className="">N/A</span>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-1.5">
                            <span className="material-symbols-outlined text-[14px] text-r-50">
                                warning
                            </span>
                            <span>{product?.forecastNeeds} ea</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Button
                            btnText="Restock"
                            btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                            btnPermission={["all_access", "create_purchase_order"]}
                            onClick={() => {
                                setGetProductId(product?._id);
                                navigate(
                                    `/dashboard/purchase/create-purchase-request?inventory=${product?._id}`,
                                    { state: { internalNavigation: true } }
                                );
                            }}
                        />
                        {/* <Dropdown
                            name="filter"
                            value={""}
                            dropdownTriggerClassName={" "}
                            // handleChange={(name, value) => handleFilterChange(value)}
                            dropdown={
                                <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                    <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                            {productCategory || "Re-order"}
                                        </span>
                                        {Boolean(productCategory) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    handleClearFilter();
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )}
                                    </div>
                                    <div className=" py-2.5">
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 left-[-38px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="py-[2px] px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[10px] whitespace-nowrap text-slate-500 font-rocGroteskMedium">
                                                    Schedule re-order date
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Re-order tomorrow
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Re-order next week
                                                </span>
                                            </div>
                                            <div className="py-2.5 px-3 flex items-center space-x-2">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Custom date
                                                </span>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        /> */}
                    </div>
                ),
            },
        ];
    });
    const avgCostValueTableHeader = [
        { title: "SKU No.", widthClass: "w-[18.6%]" },
        { title: "Product(s)", widthClass: "w-[10%]" },
        { title: "Total PO", widthClass: "w-[12%]" },
        { title: "Av. Cost per PO", widthClass: "w-[9.1%]" },
        { title: "Supplier", widthClass: "w-[10.8%]" },
        { title: "Warehouse", widthClass: "w-[10%]" },
    ];

    const avgCostValueTableBody = [1, 2, 3]?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    TS123451 + 2 more
                                </span>
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/id`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="r-50">Marinara Sauce + 2 more</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>340</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>$340</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {/* <div className="flex items-center space-x-3">
                            {product?.supplierLogo ? (
                                <VendorAvatar
                                    imageSrc={product?.supplierLogo}
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div> */}
                        <p>Logitech</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="">Amazon Fulfillment Center....</span>
                    </div>
                ),
            },
        ];
    });

    useEffect(() => {
        if (recentIncident?.incident === undefined) {
            return;
        }
        if (recentIncident.incident?._id) {
            setOpenReStockModal(true);
        } else if (!recentIncident.incident?._id) {
            navigate(`/dashboard/purchase/create-purchase-request?inventory=${getProductId}`, {
                state: { internalNavigation: true },
            });
            handleIncidentId("");
        }
    }, [recentIncident?.incident]);

    const pieChartTwoOptions = {
        tooltip: {
            trigger: "item",
            formatter: function (params) {
                return `
                <div>
                    <p style="margin-bottom: 10px">${params?.seriesName}</p>
                    <div style="display:flex ; align-items: center; gap: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.data?.name}</span>
                        </div> 

                        <span style="font-weight: 600;">${formatMoney().format(
                            params?.data?.value
                        )}</span>
                    </div>
                </div>
                `;
            },
        },
        color: COLORS,
        series: [
            {
                name: "Inventory per location",
                type: "pie",
                radius: ["55%", "70%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 0,
                    borderWidth: 0,
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: salesBreakdown?.channelData?.map((channel) => {
                    return { value: channel?.totalStock, name: channel?.warehouseName };
                }),
            },
        ],
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return productListLoading && data.length === 0 ? (
                    <CustomTableLoader tableHeader={tableHeader?.slice(0, 6)} />
                ) : data?.length > 0 ? (
                    <div className="overflow-x-scroll mb-6">
                        <div id="table-container-one" className="max-lg:hidden ">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isCellBordered={true}
                                // isInifiniteScroll={false}
                                isCellSelectable={true}
                                isAllSelectable={true}
                                stickyHeader={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                                rightFixedColumnCount={1}
                                leftFixedColumnCount={0}
                                isScrollable={true}
                                sort={handleSortVariantTable}
                            />
                        </div>

                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileTableBody} />
                        </div>

                        {productListLoading && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No products available"
                        description="There are no products in your inventory. Add new products to start managing your inventory."
                        hasButton
                        btnText="Add new product"
                        btnAction={() =>
                            navigate("/dashboard/inventory/add-product", {
                                state: { internalNavigation: true },
                            })
                        }
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );
            case 1:
                return allProductsListLoading && allProductsList.length === 0 ? (
                    <CustomTableLoader tableHeader={allProductsTableHeader?.slice(0, 5)} />
                ) : allProductsList?.length > 0 ? (
                    <div className="overflow-x-scroll mb-6">
                        <div id="" className="">
                            <CustomTable
                                tableBody={allProductsTableBody}
                                tableHeader={allProductsTableHeader}
                                isCellBordered={true}
                                stickyHeader={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                                isScrollable={true}
                                sort={handleSortNoVariantTable}
                            />
                        </div>

                        {allProductsLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {allProductsPagination?.currentPage ===
                            allProductsPagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : channelNoVariantFilter ||
                  stockLevelRange?.stockLevelMax ||
                  stockLevelRange?.stockLevelMin ||
                  forecastedNeedsRange?.forecastedDemandMax ||
                  forecastedNeedsRange?.forecastedDemandMin ||
                  debounceSeachNoVariant ? (
                    <div className="flex items-center justify-center flex-col mt-20">
                        {allProductsListLoading ? (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        ) : (
                            <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                                No data matches your search query
                            </p>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No products found"
                        description="You haven't added any products yet. Connect your sales channels or upload your product catalog to start managing your inventory."
                        hasButton
                        btnText="Add new product"
                        btnAction={() =>
                            navigate("/dashboard/inventory/add-product", {
                                state: { internalNavigation: true },
                            })
                        }
                        hasButton2={true}
                        hasButton2Classname="!justify-start"
                        btn2Text="Connect a sales channel"
                        btn2Action={() =>
                            navigate("/dashboard/integrations", {
                                state: { internalNavigation: true },
                            })
                        }
                        btn2Classname="!bg-[#F4F5F7]"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            case 2:
                return (
                    <div>
                        {bundleListLoading && bundleList?.length === 0 && !bundleSearch ? (
                            <CustomTableLoader tableHeader={bundleTableHeader} />
                        ) : bundleList?.length > 0 ? (
                            <div className="relative">
                                <div id="table-container-two" className="max-lg:hidden ">
                                    <CustomTable
                                        tableBody={bundleTableBody}
                                        tableHeader={bundleTableHeader}
                                        isScrollable={false}
                                        // isScrolled={isScrolled}
                                        isCellBordered={false}
                                        isCellSelectable={true}
                                        isAllSelectable={true}
                                        headerContainerClass="!bg-slate-50"
                                        headerItemClass="font-rocGroteskMedium "
                                        tableClass="mb-12"
                                    />
                                </div>
                                {/* <div className="hidden max-lg:block">
                            <CustomMobileTable data={bundleMobileTableBody} />
                            </div> */}

                                {isBundleLoadingMore && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}

                                {bundlePagination?.currentPage === bundlePagination?.noOfPages && (
                                    <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <EmptyStateComp
                                title="No products available"
                                description="There are no products in your inventory. Add new products to start managing your inventory."
                                hasButton
                                btnText="create new bundle"
                                btnAction={() =>
                                    navigate("/dashboard/inventory/add-bundle", {
                                        state: { internalNavigation: true },
                                    })
                                }
                                icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                            />
                        )}
                    </div>
                );

            case 3:
                return archivedProducts?.length > 0 ? (
                    <div className=" mb-6">
                        <div id="table-container-three" className="">
                            <CustomTable
                                tableBody={archiveTableBody}
                                tableHeader={archiveTableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={true}
                                isAllSelectable={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>

                        {/* <div className="hidden max-lg:block">
                        <CustomMobileTable data={bundleMobileTableBody} />
                            </div> */}

                        {archiveIsLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {archivePagination?.currentPage === archivePagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No archived products available"
                        description="There are no archived products in your available right now."
                        hasButton={false}
                        // btnText="Add new product"
                        // btnAction={() => navigate("/dashboard/inventory/add-product")}
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            default:
                break;
        }
    };

    return (
        <PageFrame
            isLoading={
                // (productListLoading && Number(pagination.currentPage) === 1 && data.length === 0) ||
                analyticsLoading
            }
            containerClassName={"!min-h-[60vh]"}
        >
            <div className="grid grid-cols-[1fr_1.2fr] max-lg:grid-cols-1 max-lg:space-x-0 max-lg:space-y-6 space-x-6 mb-6">
                <div className="grid grid-rows-2 gap-4 max-sm:grid-cols-1">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <InventoryAnalyticsCard
                                title="Total Stock value"
                                containerClassName="bg-[#FFFBEB] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                                icon={
                                    <i className="ri-money-dollar-circle-fill text-2xl text-y-10 mb-2.5"></i>
                                }
                                onClick={() => setExpandAnalyticsCard("totalStockValue")}
                                value={`$${
                                    formatMoney().format(productAnalytics?.totalStockValue) || 0
                                }`}
                                tooltipIcon={
                                    <a id="totalValue">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>
                                }
                            />
                            <Tooltip
                                anchorSelect="#totalValue"
                                place={"top"}
                                content="This is the value of your current inventory. It is gotten by multiplying the stock level of each product by the unit cost."
                                style={{
                                    width: "300px",
                                    zIndex: 9999,
                                    backgroundColor: "#142837",
                                    color: "#fff",
                                }}
                            />
                        </div>
                        <div>
                            <InventoryAnalyticsCard
                                title="In stock"
                                containerClassName="bg-[#F0FDF4] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                                icon={
                                    <i className="ri-box-2-fill text-2xl text-[#16A34A] mb-2.5"></i>
                                }
                                onClick={() => setExpandAnalyticsCard("inStock")}
                                value={`${productAnalytics?.totalPositiveStock || 0}`}
                                tooltipIcon={
                                    <a id="inStock">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>
                                }
                            />
                            <Tooltip
                                anchorSelect="#inStock"
                                place={"top"}
                                content="These are products (including variants) that their stock level is greater than 0"
                                style={{
                                    width: "300px",
                                    zIndex: 9999,
                                    backgroundColor: "#142837",
                                    color: "#fff",
                                }}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        {/* //change to grid-cols-3 when uncomment the avgCog */}
                        <div>
                            <InventoryAnalyticsCard
                                title="Restock Now"
                                containerClassName="bg-[#FAF5FF] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                                icon={
                                    <i className="ri-arrow-down-line text-2xl text-[#7C3AED] mb-2.5"></i>
                                }
                                onClick={() => setExpandAnalyticsCard("belowMinimum")}
                                value={`${productAnalytics?.totalBelowMinimumStock || 0}`}
                                tooltipIcon={
                                    <a id="belowMinimum">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>
                                }
                            />
                            <Tooltip
                                anchorSelect="#belowMinimum"
                                place={"top"}
                                content="These are products (including variants) that their stock level is less than 0"
                                style={{
                                    width: "300px",
                                    zIndex: 9999,
                                    backgroundColor: "#142837",
                                    color: "#fff",
                                }}
                            />
                        </div>
                        <div>
                            <InventoryAnalyticsCard
                                title="Out of stock"
                                containerClassName="bg-[#FEF2F2] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                                icon={
                                    <i className="ri-box-3-line text-2xl text-[#DC2626] mb-2.5"></i>
                                }
                                onClick={() => setExpandAnalyticsCard("outOfStock")}
                                value={`${productAnalytics?.totalNegetiveStock || 0}`}
                                tooltipIcon={
                                    <a id="inStock">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>
                                }
                            />
                            <Tooltip
                                anchorSelect="#inStock"
                                place={"top"}
                                content="These are products that their current stock level is below the warehouse minimum stock level set"
                                style={{
                                    width: "300px",
                                    zIndex: 9999,
                                    backgroundColor: "#142837",
                                    color: "#fff",
                                }}
                            />
                        </div>
                        {/* <div
                            onClick={() => setExpandAnalyticsCard("averageCogs")}
                            className="p-6 bg-slate-50 rounded-md flex flex-col justify-center cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                        >
                            <i className="ri-money-dollar-box-line text-2xl text-slate-600 mb-2.5"></i>
                            <span className="block text-sm font-rocGroteskMedium">Av. COGS</span>
                            <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                                $42k
                            </span>
                        </div> */}
                    </div>
                </div>

                <div className="border border-[#E2E8F080] rounded-lg p-6 max-sm:px-3 shadow-[-1px_6px_15px_0px_#00000005] h-[350px] max-sm:h-auto">
                    <div className="flex items-center space-x-2 max-sm:grid max-sm:grid-cols-1 max-sm:space-x-0 max-sm:space-y-2">
                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("");
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                        {truncate(selectedProduct?.name, { length: 20 }) ||
                                            "All products"}
                                    </span>
                                    <div className="flex items-center space-x-1">
                                        {selectedProduct?.name && (
                                            <i
                                                onClick={handleClearFilterTwo}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            search={
                                <div>
                                    <TextInput
                                        value={searchTwo}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleSearchTwo}
                                        onInput={handleDebouncedChangeTwo}
                                        inputPlaceholder="Search products"
                                        containerClassname=""
                                        // inputContainerClassname={"!border-none !rounded-none "}
                                        inputClassName="w-full"
                                    />
                                </div>
                            }
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full min-w-full border border-slate-100 rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="max-h-[280px]">
                                                {productSearchList?.map((product) => {
                                                    return (
                                                        <div
                                                            key={product?._id}
                                                            onClick={() =>
                                                                handleFilterChangeTwo(
                                                                    product?.productName,
                                                                    product?._id
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                            >
                                                                <div>
                                                                    <div className="flex items-center space-x-3">
                                                                        {product
                                                                            ?.productImageDetails
                                                                            ?.productImages?.[0] ? (
                                                                            <VendorAvatar
                                                                                imageSrc={
                                                                                    product
                                                                                        ?.productImageDetails
                                                                                        ?.productImages?.[0]
                                                                                }
                                                                                size={32}
                                                                                name={
                                                                                    product?.productName
                                                                                }
                                                                                containerClassname="!rounded-md"
                                                                            />
                                                                        ) : (
                                                                            <div className="w-8 h-8 rounded flex shrink-0 items-center justify-center bg-slate-200 ">
                                                                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                                                            </div>
                                                                        )}
                                                                        <div>
                                                                            <span className="block text-xs font-rocGroteskMedium">
                                                                                {truncate(
                                                                                    product?.sku,
                                                                                    {
                                                                                        length: 30,
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                            <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                {truncate(
                                                                                    product?.productName,
                                                                                    {
                                                                                        length: 50,
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("first", name, value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <div className="flex items-center space-x-1">
                                        {/* <i className="ri-map-pin-line text-sm text-g-75 "></i> */}
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(warehouseValue?.name, { length: 16 }) ||
                                                "All locations"}
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        {warehouseValue?.name && (
                                            <i
                                                onClick={clearWarehouseFilter}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            search={
                                <div>
                                    <TextInput
                                        value={warehouseSearch}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleWarehouseSearch}
                                        onInput={debounceChange}
                                        inputPlaceholder="Search warehouse"
                                        containerClassname=""
                                        // inputContainerClassname={"!border-none !rounded-none "}
                                        inputClassName="w-full"
                                    />
                                </div>
                            }
                            // dropdownClassName=""
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            id="modalContent"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="max-h-[280px]">
                                                {allWarehouses?.map((warehouse) => {
                                                    return (
                                                        <div
                                                            key={warehouse?._id}
                                                            onClick={() =>
                                                                handleWarehouseFilterChange(
                                                                    warehouse?.warehouseName,
                                                                    warehouse?._id
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                    warehouseValue?.value ===
                                                                        warehouse?._id &&
                                                                    "bg-slate-100"
                                                                }`}
                                                            >
                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                <div>
                                                                    <span
                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                            warehouse?.address
                                                                                ?.address &&
                                                                            "mb-[2px]"
                                                                        }`}
                                                                    >
                                                                        {truncate(
                                                                            warehouse?.warehouseName,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                    <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                        {truncate(
                                                                            warehouse?.address
                                                                                ?.address,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                setDateRange(value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <span
                                        className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                    >
                                        {dateRange === "all" ? "All time" : dateRange}
                                    </span>
                                    <i className="ri-arrow-down-s-line text-g-75"></i>
                                </div>
                            }
                            // dropdownItemsClasses={`!px-0 !py-0`}
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded`}
                            dropdownOptions={[
                                {
                                    label: "All time",
                                    value: "all",
                                },
                                {
                                    label: "Daily",
                                    value: "daily",
                                },
                                {
                                    label: "Weekly",
                                    value: "weekly",
                                },
                                {
                                    label: "Monthly",
                                    value: "monthly",
                                },
                                ...(years && Array.isArray(years)
                                    ? years.map((item) => ({
                                          label:
                                              item === new Date().getFullYear()
                                                  ? `This year (${item})`
                                                  : item?.toString(),
                                          value: item,
                                      }))
                                    : []),
                            ]}
                        />
                    </div>

                    <div className="grid grid-cols-2 max-sm:grid-cols-1 items-center">
                        <div className="relative ">
                            <ReactECharts option={pieChartTwoOptions} />

                            <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                <span className="block text-[12px] text-slate-500">
                                    {truncate(warehouseValue?.name, { length: 15 }) ||
                                        "All warehouses"}
                                </span>
                                <span className="block text-[20px] text-g-75 leading-[40px]">
                                    {salesBreakdown.totalStock !== undefined
                                        ? millify(salesBreakdown.totalStock, {
                                              precision: 2,
                                          })
                                        : ""}
                                </span>
                                <span className="block text-[12px] text-slate-500">Units</span>
                            </div>
                        </div>

                        <div className="">
                            {salesBreakdown?.channelData?.slice(0, 5)?.map((channel, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex justify-between items-center mb-3"
                                    >
                                        <div className="flex items-center space-x-1.5">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px]`}
                                                style={{ backgroundColor: COLORS?.[idx] }}
                                            ></span>
                                            <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                {truncate(channel?.warehouseName, { length: 20 })}
                                            </span>
                                        </div>
                                        <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                            {formatMoney(true).format(channel?.totalStock)} units
                                        </span>
                                    </div>
                                );
                            })}
                            {salesBreakdown?.channelData?.slice(5)?.length > 0 && (
                                <div className="flex justify-end">
                                    <div
                                        onClick={() => setPieChartModal(true)}
                                        className="flex items-center space-x-1 w-fit cursor-pointer"
                                    >
                                        <span className="text-sm font-rocGroteskMedium underline text-p-50">
                                            View all
                                        </span>
                                        <i className="ri-arrow-right-line text-base text-p-50"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-8">
                <p className="text-[#0F172A] font-rocGroteskMedium text-xl">Product List</p>
            </div>

            <div>
                <TabsContainer
                    tabs={tableTabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        dispatch(inventoryActions.resetFetchInventoryList());
                        navigate(`/dashboard/inventory?currProductType=${idx}`, {
                            // replace: true,
                            state: { internalNavigation: true },
                        });
                    }}
                    component={<div className="min-h-[500px]">{displayActiveTab()}</div>}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 max-lg:whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[32%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                            <TextInput
                                name={"search"}
                                value={
                                    activeTab === 3
                                        ? archiveSearch
                                        : activeTab === 2
                                        ? bundleSearch
                                        : activeTab === 0
                                        ? search
                                        : allProductsSearch
                                }
                                type={"text"}
                                inputPlaceholder={"Search products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={
                                    activeTab === 3
                                        ? archiveHandleSearch
                                        : activeTab === 2
                                        ? bundleHandleSearch
                                        : activeTab === 0
                                        ? handleSearch
                                        : handleAllProductsSearch
                                }
                                onInput={
                                    activeTab === 3
                                        ? archiveHandleDebounceChange
                                        : activeTab === 2
                                        ? bundleHandleDebouncedChange
                                        : activeTab === 0
                                        ? handleDebouncedChange
                                        : handleAllProductsDebouncedChange
                                }
                                containerClassname={"!w-[300px] max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />

                            {activeTab === 3 && (
                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        // handleChannelChange(value);
                                        archiveHandleChannelFilterChange(value);
                                    }}
                                    isLoading={salesChannelLoading}
                                    dropdown={
                                        <div className="bg-white flex items-center justify-between h-10 space-x-2 py-2.5 px-3 border border-slate-200 !rounded ">
                                            <div className="flex items-center space-x-2">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                                    {archiveChannel || "Channel"}
                                                </span>
                                                {Boolean(channel) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            archiveHandleClearChannelFilter();
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] max-sm:!w-full rounded !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={salesChannel?.map((channel) => {
                                        return {
                                            label: channel?.[0]?.toUpperCase() + channel?.slice(1),
                                            value: channel,
                                        };
                                    })}
                                />
                            )}

                            {(activeTab === 0 || activeTab === 3) && (
                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) =>
                                        activeTab === 3
                                            ? archiveHandleFilterChange(value)
                                            : // : activeTab === 2
                                              // ? bundleHandleFilterChange(value)
                                              handleFilterChange(value)
                                    }
                                    dropdown={
                                        <div className="bg-white flex items-center justify-between h-10 space-x-2 py-2.5 px-3 border border-slate-200 !rounded ">
                                            <div className="flex items-center space-x-2">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {(activeTab === 3
                                                        ? archiveProductCategory
                                                        : productCategory) || "Category"}
                                                </span>
                                                {Boolean(
                                                    activeTab === 3
                                                        ? archiveProductCategory
                                                        : productCategory
                                                ) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            if (activeTab === 3) {
                                                                archiveHandleClearFilter();
                                                                // } else if (activeTab === 2) {
                                                                //     bundleHandleClearFilter();
                                                            } else {
                                                                handleClearFilter();
                                                            }
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={productCategories?.map((category) => {
                                        return {
                                            label: category?.label,
                                            value: category?.value,
                                        };
                                    })}
                                />
                            )}

                            {(activeTab === 0 || activeTab === 1) && (
                                <div className="relative">
                                    <div className="flex items-center space-x-2">
                                        {filterModal && (
                                            <Button
                                                btnText="Filter"
                                                icon={
                                                    <i className="ri-filter-3-fill text-[#142837]"></i>
                                                }
                                                btnClassname="text-[#142837] !w-fit !h-10 px-3 !bg-white !border !border-slate-200"
                                            />
                                        )}
                                        {!filterModal && (
                                            <Button
                                                btnText="Filter"
                                                icon={
                                                    <i className="ri-filter-3-fill text-[#142837]"></i>
                                                }
                                                onClick={() => setFilterModal(true)}
                                                btnClassname="text-[#142837] !w-fit !h-10 px-3 !bg-white !border !border-slate-200"
                                            />
                                        )}
                                    </div>
                                    {filterModal && (
                                        <div
                                            className="shadow-cardShadow-4 !z-[1200] absolute top-[50px] right-0 !bg-white p-4 rounded-md"
                                            id="filterModalRef"
                                        >
                                            <div className="">
                                                {activeTab === 1 ? (
                                                    <SelectInput
                                                        value={channelNoVariantFilter}
                                                        name="channelFilter"
                                                        placeholder={"Channel"}
                                                        handleChange={(name, value) => {
                                                            setChannelNoVariantFilter(value);
                                                        }}
                                                        clearValue={true}
                                                        isRequired={true}
                                                        dropdownOptions={[
                                                            {
                                                                label: "Shopify",
                                                                value: "shopify",
                                                            },
                                                        ]}
                                                        className="!h-[44px]"
                                                    />
                                                ) : (
                                                    <div className="grid gap-2">
                                                        {/* <SelectInput
                                                            value={productCategory}
                                                            name="productCategory"
                                                            placeholder={"Category"}
                                                            handleChange={(name, value) => {
                                                                handleFilterChange(value);
                                                            }}
                                                            clearValue={true}
                                                            isRequired={true}
                                                            dropdownOptions={[
                                                                ...productCategories?.map(
                                                                    (item) => {
                                                                        return {
                                                                            label: item?.label,
                                                                            value: item?.value,
                                                                        };
                                                                    }
                                                                ),
                                                            ]}
                                                            className="!h-[44px]"
                                                        /> */}
                                                        <SelectInput
                                                            value={channelFilter}
                                                            name="channelFilter"
                                                            placeholder={"Channel"}
                                                            handleChange={(name, value) => {
                                                                setChannelFilter(value);
                                                                setChannel(value);
                                                            }}
                                                            clearValue={true}
                                                            isRequired={true}
                                                            dropdownOptions={[
                                                                ...salesChannel?.map((item) => {
                                                                    return {
                                                                        label: item,
                                                                        value: item,
                                                                    };
                                                                }),
                                                            ]}
                                                            className="!h-[44px]"
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex justify-between gap-2">
                                                <div className="">
                                                    <p className="text-sm font-rocGroteskMedium pt-4 py-2">
                                                        Stock Level Range
                                                    </p>
                                                    <div className="flex items-center space-x-2">
                                                        <TextInput
                                                            name={
                                                                activeTab === 1
                                                                    ? "stockLevelMin"
                                                                    : "variantStockLevelMin"
                                                            }
                                                            value={
                                                                activeTab === 1
                                                                    ? stockLevelRange?.stockLevelMin ||
                                                                      ""
                                                                    : variantStockLevelRange?.stockLevelMin ||
                                                                      ""
                                                            }
                                                            type="number"
                                                            placeholder={"Min"}
                                                            onChange={(e) => {
                                                                activeTab === 1
                                                                    ? setStockLevelRange({
                                                                          ...stockLevelRange,
                                                                          stockLevelMin:
                                                                              e.target.value,
                                                                      })
                                                                    : setVariantStockLevelRange({
                                                                          ...variantStockLevelRange,
                                                                          stockLevelMin:
                                                                              e.target.value,
                                                                      });
                                                            }}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                        />
                                                        <TextInput
                                                            name={"stockLevelMax"}
                                                            value={
                                                                activeTab === 1
                                                                    ? stockLevelRange?.stockLevelMax ||
                                                                      ""
                                                                    : variantStockLevelRange?.stockLevelMax ||
                                                                      ""
                                                            }
                                                            type="number"
                                                            placeholder={"Max"}
                                                            onChange={(e) => {
                                                                activeTab === 1
                                                                    ? setStockLevelRange({
                                                                          ...stockLevelRange,
                                                                          stockLevelMax:
                                                                              e.target.value,
                                                                      })
                                                                    : setVariantStockLevelRange({
                                                                          ...variantStockLevelRange,
                                                                          stockLevelMax:
                                                                              e.target.value,
                                                                      });
                                                            }}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="text-sm font-rocGroteskMedium pt-4 py-2">
                                                        Forecasted demand range
                                                    </p>
                                                    <div className="flex items-center space-x-2">
                                                        <TextInput
                                                            name={
                                                                activeTab === 1
                                                                    ? "forecastedDemandMin"
                                                                    : "variantForecastedDemandMin"
                                                            }
                                                            value={
                                                                activeTab === 1
                                                                    ? forecastedNeedsRange?.forecastedDemandMin ||
                                                                      ""
                                                                    : variantForecastedNeedsRange?.forecastedDemandMin ||
                                                                      ""
                                                            }
                                                            type="number"
                                                            placeholder={"Min"}
                                                            onChange={(e) => {
                                                                activeTab === 1
                                                                    ? setForecastedNeedsRange({
                                                                          ...forecastedNeedsRange,
                                                                          forecastedDemandMin:
                                                                              e.target.value,
                                                                      })
                                                                    : setVariantForecastedNeedsRange(
                                                                          {
                                                                              ...variantForecastedNeedsRange,
                                                                              forecastedDemandMin:
                                                                                  e.target.value,
                                                                          }
                                                                      );
                                                            }}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                        />
                                                        <TextInput
                                                            name={
                                                                activeTab === 1
                                                                    ? "forecastedDemandMax"
                                                                    : "variantForecastedDemandMax"
                                                            }
                                                            value={
                                                                activeTab === 1
                                                                    ? forecastedNeedsRange?.forecastedDemandMax ||
                                                                      ""
                                                                    : variantForecastedNeedsRange?.forecastedDemandMax ||
                                                                      ""
                                                            }
                                                            type="number"
                                                            placeholder={"Max"}
                                                            onChange={(e) => {
                                                                activeTab === 1
                                                                    ? setForecastedNeedsRange({
                                                                          ...forecastedNeedsRange,
                                                                          forecastedDemandMax:
                                                                              e.target.value,
                                                                      })
                                                                    : setVariantForecastedNeedsRange(
                                                                          {
                                                                              ...variantForecastedNeedsRange,
                                                                              forecastedDemandMax:
                                                                                  e.target.value,
                                                                          }
                                                                      );
                                                            }}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full mt-1 py-4 px-6 flex items-center justify-end space-x-2">
                                                <Button
                                                    btnText={"Reset"}
                                                    onClick={() => {
                                                        if (activeTab === 1) {
                                                            if (
                                                                channelNoVariantFilter ||
                                                                stockLevelRange?.stockLevelMax ||
                                                                stockLevelRange?.stockLevelMin ||
                                                                forecastedNeedsRange?.forecastedDemandMax ||
                                                                forecastedNeedsRange?.forecastedDemandMin
                                                            ) {
                                                                setChannelNoVariantFilter("");
                                                                setStockLevelRange({
                                                                    stockLevelMax: "",
                                                                    stockLevelMin: "",
                                                                });
                                                                setForecastedNeedsRange({
                                                                    forecastedDemandMin: "",
                                                                    forecastedDemandMax: "",
                                                                });
                                                                setApplyFilter(!applyFilter);
                                                            }
                                                        }
                                                        if (activeTab === 0) {
                                                            if (
                                                                channel ||
                                                                variantStockLevelRange?.stockLevelMax ||
                                                                variantStockLevelRange?.stockLevelMin ||
                                                                variantForecastedNeedsRange?.forecastedDemandMax ||
                                                                variantForecastedNeedsRange?.forecastedDemandMin
                                                            ) {
                                                                setChannel("");
                                                                setChannelFilter("");
                                                                setVariantStockLevelRange({
                                                                    stockLevelMax: "",
                                                                    stockLevelMin: "",
                                                                });
                                                                setVariantForecastedNeedsRange({
                                                                    forecastedDemandMin: "",
                                                                    forecastedDemandMax: "",
                                                                });
                                                                setApplyProductFilter(
                                                                    !applyProductFilter
                                                                );
                                                            }
                                                        }
                                                        setFilterModal(false);
                                                    }}
                                                    btnClassname={
                                                        "!bg-tradeally-neutral-20 !text-gm-75 !w-fit"
                                                    }
                                                />
                                                <Button
                                                    btnText={"Apply filters"}
                                                    onClick={() => {
                                                        activeTab === 1
                                                            ? setApplyFilter(!applyFilter)
                                                            : setApplyProductFilter(
                                                                  !applyProductFilter
                                                              );

                                                        setFilterModal(false);
                                                    }}
                                                    btnClassname="!w-fit"
                                                    btnTextClassName="!text-md "
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {activeTab === 0 && (
                                <Button
                                    btnText="Export"
                                    icon={<i className="ri-arrow-go-forward-fill text-p-50"></i>}
                                    isLoading={exportingInventoryList}
                                    btnClassname="text-p-50 !w-fit !h-10 px-3 !bg-white !border !border-slate-200"
                                    onClick={() => {
                                        // if (activeTab === 1) {
                                        dispatch(inventoryActions?.exportInventoryList());
                                        // } else {
                                        //     dispatch(inventoryActions?.exportInventoryList());
                                        // }
                                    }}
                                />
                            )}

                            {activeTab === 2 && selectedBundles?.length > 1 && (
                                <Button
                                    btnText="Edit bundles"
                                    btnPermission={[
                                        "all_access",
                                        "create_inventory",
                                        "update_inventory",
                                    ]}
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-n-20 !text-[13px] !whitespace-nowrap"
                                    icon={<i className="ri-edit-2-line text-base"></i>}
                                    onClick={() => {
                                        setEditBundleModal(true);
                                    }}
                                />
                            )}

                            {/* {activeTab === 3 && selectedArchiveProducts?.length > 0 && (
                                <Button
                                    btnText="Unarchive"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-transparent !border !border-slate-200 !text-[13px] !whitespace-nowrap"
                                    onClick={() => {
                                        setUnarchiveProductsModal(true);
                                    }}
                                />
                            )} */}
                            <Dropdown
                                name="filter"
                                value={""}
                                dropdownTriggerClassName={" "}
                                // handleChange={(name, value) => handleFilterChange(value)}
                                dropdown={
                                    <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                        <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Add product
                                            </span>
                                        </div>
                                        <div className=" py-2.5">
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    </div>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 left-[-58px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="w-[200px]">
                                                <div
                                                    className="py-2.5 px-3 border-b border-slate-200 cursor-pointer"
                                                    onClick={() => {
                                                        navigate(
                                                            "/dashboard/inventory/add-product",
                                                            { state: { internalNavigation: true } }
                                                        );
                                                    }}
                                                >
                                                    <span className="block text-sm whitespace-nowrap font-rocGroteskMedium">
                                                        Single products
                                                    </span>
                                                    <span className="block text-[12px] text-slate-500 whitespace-nowrap font-rocGroteskMedium">
                                                        One item sold individually
                                                    </span>
                                                </div>
                                                <div
                                                    className="py-2.5 px-3 border-b border-slate-200 cursor-pointer"
                                                    onClick={() =>
                                                        navigate(
                                                            "/dashboard/inventory/add-bundle",
                                                            { state: { internalNavigation: true } }
                                                        )
                                                    }
                                                >
                                                    <span className="block text-sm whitespace-nowrap font-rocGroteskMedium">
                                                        Bundle products
                                                    </span>
                                                    <span className="block text-[12px] text-slate-500 whitespace-nowrap font-rocGroteskMedium">
                                                        Multiple items sold together
                                                    </span>
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    }
                />
            </div>

            {activeTab === 0 && selectedInventories?.length > 0 && (
                <div className="fixed bottom-[20px] right-[43%] w-fit">
                    <Button
                        btnText={`Archive selected products (${selectedInventories?.length})`}
                        btnPermission={["all_access", "create_inventory", "update_inventory"]}
                        btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !border !border-slate-200 !rounded-[200px] !whitespace-nowrap"
                        onClick={() => {
                            setArchiveProductsModal(true);
                        }}
                    />
                </div>
            )}

            {activeTab === 2 && selectedBundles?.length > 1 && (
                <div className="fixed bottom-[20px] right-[45%] w-fit">
                    <Button
                        btnText="Edit bundles"
                        btnPermission={["all_access", "create_inventory", "update_inventory"]}
                        btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-n-20 !text-[13px] !border !border-slate-200 !rounded-[200px] !whitespace-nowrap"
                        icon={<i className="ri-edit-2-line text-base"></i>}
                        onClick={() => {
                            setEditBundleModal(true);
                        }}
                    />
                </div>
            )}

            {activeTab === 3 && selectedArchiveProducts?.length > 0 && (
                <div className="fixed bottom-[20px] right-[42%] w-fit">
                    <Button
                        btnText={`Unarchive selected products (${selectedArchiveProducts?.length})`}
                        btnPermission={["all_access", "create_inventory", "update_inventory"]}
                        btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !border !border-slate-200 !rounded-[200px] !whitespace-nowrap"
                        onClick={() => {
                            setUnarchiveProductsModal(true);
                        }}
                    />
                </div>
            )}

            {openReStockModal && (
                <ScrollableModal
                    closeModal={() => {
                        handleIncidentId("");
                        setOpenReStockModal(false);
                    }}
                    setAtBottom={setAtBottom}
                    modalHeader={
                        <div className="flex py-4  px-8 border-b border-slate-200 justify-between items-center">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Replenishment Tasks
                            </p>
                            <i
                                className="ri-close-fill cursor-pointer text-xl text-black"
                                onClick={() => {
                                    handleIncidentId("");
                                    setOpenReStockModal(false);
                                }}
                            ></i>
                        </div>
                    }
                    children={
                        <div className="mt-2 ">
                            <AlertTabDetails
                                data={incidentData}
                                isFetching={isFetchingIncidentData}
                                pagination={pagination}
                                isLoadingMore={isLoadingMore}
                                modalShow={false}
                                onClickDocument={() => {
                                    setOpenDocument(true);
                                    setOpenReStockModal(false);
                                }}
                                incidentId={recentIncident?.incident?._id}
                                replishmentStock={replishmentStock}
                                isFetchingReplishment={isFetchingReplishment}
                                onClickShipment={() => {
                                    setOpenShipment(true);
                                    setOpenReStockModal(false);
                                }}
                            />
                            {!atBottom && (
                                <div className="justify-center flex  absolute left-0 right-0 bottom-2">
                                    <div className="bg-y-10 px-[16px]  shadow-scrollShadow  py-[6px] !w-fit rounded-[200px] flex space-x-2 items-center">
                                        <i className="ri-arrow-down-line text-g-75"></i>
                                        <p className="text-base font-rocGroteskMedium text-g-75">
                                            Scroll down
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                />
            )}

            <ModalContainer
                open={editBundleModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[70%]"
                showCloseIcon={false}
                closeModal={() => {
                    setEditBundleModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="">
                        <div className="px-6 py-4 flex items-center justify-between border-b border-slate-200">
                            <span className="text-base font-rocGroteskMedium">
                                Edit Product Bundled SKUs
                            </span>
                            <i
                                className="ri-close-fill text-xl cursor-pointer"
                                onClick={() => setEditBundleModal(false)}
                            ></i>
                        </div>
                        <div className="h-[48.8vh] overflow-y-auto space-y-4 px-6 py-6">
                            {selectedBundles?.map((bundle, idx) => {
                                // const productAttached = attachedProducts?.find((attached) => {
                                //     return Object.entries(attached)?.some(([key, value]) => {
                                //         return Number(key) === idx;
                                //     });
                                // });
                                // const theAttached: any = Object.values(
                                //     productAttached?.[idx] || {}
                                // )?.map((attachProd) => attachProd);

                                return (
                                    <div
                                        key={bundle?._id}
                                        className="border border-slate-200 rounded-[6px]"
                                    >
                                        <div className="py-3  px-4 text-sm rounded-t-[6px] border-b font-rocGroteskMedium flex space-x-1.5 bg-slate-50  border-slate-200">
                                            <div className="border-b-[1px] px-2 rounded border-t-[0.5px] border-r-[0.5px] border-l-[0.5px] border-[#0F846D]">
                                                <span className="text-[8px] font-rocGroteskMedium text-[#142837]">
                                                    Product bundle name
                                                </span>
                                                <div className=" flex space-x-1.5 -mt-1">
                                                    <span className="">
                                                        {truncate(bundle?.bundleName, {
                                                            length: 50,
                                                        })}
                                                    </span>
                                                    <span>-</span>
                                                    <span className="text-[#64748B]">
                                                        {truncate(bundle?.sku, { length: 30 })}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-4 overflow-y-auto flex items-center space-x-3">
                                            {bundle?.bundleItems?.map((item, index) => {
                                                return (
                                                    <div className="relative">
                                                        {index !== 0 && (
                                                            <div
                                                                className="flex justify-end -mb-2 w-full"
                                                                onClick={() =>
                                                                    handleDelete(idx, index)
                                                                }
                                                            >
                                                                <div className="border w-4 h-4 flex justify-center items-center cursor-pointer border-[#E2E8F0] shrink-0 rounded-full">
                                                                    <i className="ri-close-line text-[#E4281D] text-sm"></i>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            key={item?.inventory?._id}
                                                            className="flex shrink-0 items-center border border-slate-200 w-[150px] rounded"
                                                        >
                                                            <div className="">
                                                                {item?.inventory
                                                                    ?.productImageDetails
                                                                    ?.productImages?.[0] ? (
                                                                    <VendorAvatar
                                                                        imageSrc={
                                                                            item?.inventory
                                                                                ?.productImageDetails
                                                                                ?.productAvatar ||
                                                                            item?.inventory
                                                                                ?.productImageDetails
                                                                                ?.productImages?.[0]
                                                                        }
                                                                        size={56}
                                                                        name={
                                                                            item?.inventory
                                                                                ?.productName
                                                                        }
                                                                        containerClassname="!rounded-md"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[56px] h-[56px] rounded-l flex items-center justify-center bg-slate-200 ">
                                                                        <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="border-l border-slate-200 h-[56px] pl-3 flex items-center">
                                                                <div>
                                                                    <span className="block font-rocGroteskMedium text-sm">
                                                                        {truncate(
                                                                            item?.inventory
                                                                                ?.productName,
                                                                            {
                                                                                length: 18,
                                                                            }
                                                                        )}
                                                                    </span>
                                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                        {item?.inventory?.sku}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div
                                                className={`h-14 w-14 ${
                                                    bundle.length !== 0 ? "mt-3" : "-mt-2"
                                                } cursor-pointer shrink-0 rounded-[2px] border border-dashed border-slate-300 flex items-center justify-center`}
                                            >
                                                <i
                                                    className="ri-add-fill text-[28px] text-slate-400"
                                                    onClick={() => {
                                                        setActiveIndex(idx);
                                                        setAddedProducts([
                                                            ...cloneDeep(selectedBundles),
                                                        ]);
                                                        // setSelectProduct(theAttached);
                                                        setAddProduct(true);
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center justify-end space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !w-fit !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setEditBundleModal(false);
                                }}
                            />
                            <Button
                                btnText={"Update product bundles"}
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !w-fit !font-rocGroteskMedium !px-4"
                                isLoading={updatingBundle}
                                onClick={onUpdate}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addProduct}
                showCloseIcon={false}
                closeModal={() => {
                    setAddProduct(false);
                    setAddedProducts([]);
                    setSearch("");
                }}
                tailwindClassName="w-[55%]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Add products/SKUs to {selectedBundles?.[activeIndex]?.bundleName}
                        </p>
                        <i
                            onClick={() => {
                                setSearch("");
                                setAddProduct(false);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 ">
                        <div className="mx-8">
                            <TextInput
                                name={"search"}
                                value={search}
                                type={"text"}
                                inputPlaceholder={"Search  products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                // containerClassname={"!w-[400px] max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                                }
                            />
                        </div>

                        <div className="">
                            {productListLoading && Number(updatedPage) === 1 ? (
                                <CustomTableLoader tableHeader={tableProductHeader} />
                            ) : (
                                <div
                                    className="max-h-[300px] mt-5 overflow-y-auto"
                                    id="custom-modal"
                                >
                                    <CustomTable
                                        tableBody={tableProductBody}
                                        tableHeader={tableProductHeader}
                                        isAllSelectable={false}
                                        isCellSelectable={true}
                                        isCellBordered={true}
                                        headerItemClass="font-rocGroteskMedium   !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50 !border !border-[#E2E8F0] "
                                        isScrollable={false}
                                        bodyItemClass="!border-t-none !border-r-none"
                                        tableClass="!border-t-none !border-r-none !border-l-none"
                                    />
                                    {isLoadingMore && (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}

                                    {pagination?.currentPage === pagination?.noOfPages && (
                                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex   px-6 border-t py-3 justify-between items-center">
                        <div className=" ">
                            <p className="text-sm font-rocGroteskMedium text-g-75 ">
                                {addedProducts[activeIndex]?.bundleItems?.length} bundles selected
                            </p>
                        </div>
                        <div className="flex  items-center space-x-2 ">
                            <Button
                                btnClassname={"!py-2 !text-gm-50 !w-fit !px-8"}
                                onClick={() => {
                                    setSelectedBundles(addedProducts);
                                    setAddProduct(false);
                                    setAddedProducts([]);
                                    setSearch("");
                                }}
                                btnText={"Add product(s)"}
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={unarchiveProductsModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setUnarchiveProductsModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <i className="ri-shopping-basket-line text-[48px]"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Unarchive Product(s)?
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    Are you sure you want to unarchive the selected product(s), the
                                    selected product(s) will be imported into your inventory?
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"No, I don't"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setUnarchiveProductsModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, I do"}
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                isLoading={settingArchiveStatus}
                                onClick={() => {
                                    handleImportArchivedProducts();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={archiveProductsModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setArchiveProductsModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <i className="ri-shopping-basket-line text-[48px]"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Archive Product(s)?
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    Are you sure you want to archive the selected product(s), the
                                    selected product(s) will be imported into your archived list?
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"No, I don't"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setArchiveProductsModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, I do"}
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                isLoading={settingArchiveStatus}
                                onClick={() => {
                                    handleArchivedProducts();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openShipment}
                showCloseIcon={false}
                tailwindClassName="w-[700px]"
                closeModal={() => {
                    setOpenShipment(false);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShowAddShipmentReminder(true);
                            setOpenReStockModal(false);
                            setOpenShipment(false);
                        }}
                    >
                        <div className="">
                            <div className="flex py-4 border-b border-slate-200 px-8 justify-between items-center">
                                <p className="text-lg font-rocGroteskMedium text-g-75">
                                    Add shipment(s) from your supplier
                                </p>
                                <i
                                    className="ri-close-fill text-xl text-black cursor-pointer"
                                    onClick={() => setOpenShipment(false)}
                                ></i>
                            </div>
                            <div className=" mt-6 max-h-[400px] overflow-y-auto">
                                <div className="px-8">
                                    <div className=" pb-4">
                                        <div className="flex space-x-5 w-full">
                                            <div className="w-full">
                                                <TextInput
                                                    value={""}
                                                    disabled={true}
                                                    name="shipmentId"
                                                    placeholder={"Shipment ID"}
                                                    onChange={(e) => {}}
                                                />
                                                <span className="mt-1 flex items-center">
                                                    <img
                                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_g6qjiy.svg"
                                                        className="h-2 w-2"
                                                        alt=""
                                                    />
                                                    <p className="text-[10px] leading-[14px] text-[#64748B]">
                                                        This is auto generated by the system
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="w-full">
                                                <SelectInput
                                                    value={mode}
                                                    name="mode"
                                                    placeholder={"Select mode"}
                                                    handleChange={(name, value) => setMode(value)}
                                                    isRequired={true}
                                                    optionItemContainerClassName={
                                                        "hover:bg-slate-100"
                                                    }
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-ship-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Ocean
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "ocean",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-plane-fill"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Air
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "air",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-truck-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Land
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "land",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className=" pb-4">
                                            {mode.toLowerCase() === "ocean" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                    <TextInput
                                                        value={shipmentDetails?.billOfLading}
                                                        type={"text"}
                                                        name="billOfLading"
                                                        placeholder={"Bill of lading"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "air" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.billNumber}
                                                        name="billNumber"
                                                        placeholder={"Airway bill number"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                    <SelectInput
                                                        value={shipmentDetails?.carrier}
                                                        name="carrier"
                                                        placeholder={"Carrier"}
                                                        handleChange={(name, value) =>
                                                            handleChange({
                                                                target: {
                                                                    name,
                                                                    value,
                                                                },
                                                            })
                                                        }
                                                        isRequired={true}
                                                        optionItemContainerClassName={
                                                            "hover:bg-slate-100"
                                                        }
                                                        dropdownOptions={oceanCarriers?.map(
                                                            (carrierData) => {
                                                                return {
                                                                    label: carrierData?.[
                                                                        "Full Name"
                                                                    ],
                                                                    value: carrierData?.SCAC,
                                                                };
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "land" && (
                                                <div className="mt-3 ">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex space-x-5 mt-1">
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.streetAddress
                                                        ? address?.origin?.address
                                                        : ""
                                                }
                                                placeholder="Origin"
                                                required={true}
                                                name={"origin"}
                                            />

                                            <SelectInput
                                                value={shipmentDetails?.destination}
                                                name="destination"
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedFilter({
                                                        search: evt.target.value,
                                                        shipmentSearch: "",
                                                    });
                                                }, 800)}
                                                placeholder={"Destination"}
                                                handleChange={(name, value) =>
                                                    handleChange({
                                                        target: { name, value },
                                                    })
                                                }
                                                isRequired={true}
                                                optionItemContainerClassName={"hover:bg-slate-100"}
                                                dropdownOptions={warehouseList?.map((list) => {
                                                    return {
                                                        label: list?.warehouseName,
                                                        value: list?._id,
                                                    };
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-8 mt-3  ">
                                    <div className="font-rocGroteskMedium">
                                        <p className="text-base  text-gm-50 mb-2">
                                            Product details
                                        </p>
                                        <p className="text-sm  text-[#64748B]  mb-2">
                                            Kindly include the products you wish to ship in your
                                            shipment
                                        </p>
                                        <div>
                                            <CustomTable
                                                tableBody={productDetailsTableBody}
                                                tableHeader={productDetailsTableHeader}
                                                isAllSelectable={false}
                                                isCellSelectable={false}
                                                headerContainerClass="!bg-[#F1F5F9] !border-[#E2E8F0] !border"
                                                isScrollable={false}
                                                isCellBordered={false}
                                                bodyItemClass={"hover:!bg-transparent"}
                                            />
                                        </div>
                                        {productData?.length > 0 &&
                                            productData?.length <
                                                productList?.itemsOrServices?.length && (
                                                <Button
                                                    btnText="Add products"
                                                    btnPermission={[
                                                        "all_access",
                                                        "create_inventory",
                                                        "update_inventory",
                                                    ]}
                                                    onClick={handleAddRow}
                                                    btnClassname="!px-4 !py-2.5 !w-fit "
                                                />
                                            )}
                                    </div>
                                    <div className=" mt-4 pb-4">
                                        <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                            Additional notes
                                        </p>
                                        <TextareaInput
                                            name={"additionalInfo"}
                                            value={shipmentDetails?.additionalInfo}
                                            onChange={handleChange}
                                            rows={2}
                                            placeholder={"Additional notes"}
                                            containerClassname=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-3 border-t border-slate-200 px-8 flex space-x-4 items-center ">
                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        setOpenShipment(false);
                                    }}
                                />
                                <Button
                                    btnText="Add shipment"
                                    btnClassname="!px-4 !py-2.5 "
                                    type={"submit"}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalContainer>

            <ModalContainer
                open={showAddShipmentReminder}
                showCloseIcon={false}
                tailwindClassName="md:w-[600px] "
            >
                <div className="bg-white p-6 flex flex-col rounded-[8px]  ">
                    <p className="text-[18px] mb-2 leading-[24px] font-rocGroteskMedium text-gm-50">
                        Review shipment for accuracy
                    </p>

                    <p className="text-slate-500 font-rocGroteskMedium text-base">
                        Please review and ensure that the items listed in the Purchase Order (PO)
                        match what has been delivered. Before adding these products to inventory,
                        carefully review the product details for accuracy in both quantity and
                        quality to ensure everything is correct.
                    </p>

                    <div className=" justify-end mt-6 flex space-x-4">
                        <Button
                            btnText={"No, go back"}
                            btnClassname={"!bg-[#F4F5F7] !w-fit !py-3"}
                            disabled={createShipment}
                            onClick={() => {
                                setShowAddShipmentReminder(false);
                                setOpenShipment(true);
                            }}
                        />
                        <Button
                            btnText={`Proceed to add shipment to inventory`}
                            onClick={handleSubmit}
                            btnClassname={"!w-fit !py-3"}
                            disabled={createShipment}
                            isLoading={createShipment}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={showSuccessShipmentAdded}
                showCloseIcon={false}
                closeModal={() => {
                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                    setshowSuccessShipmentAdded(false);
                    setShipmentDetails({});
                    setMode("");
                }}
                tailwindClassName="w-[500px]"
            >
                {response?.failed?.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-8 max-h-[70vh] ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                        setOpenReStockModal(true);
                                    }}
                                ></i>
                            </div>
                            <div className="flex flex-col items-center">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Software_xcvcl6.svg"
                                    alt="warn-sign"
                                />

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment creation failed!
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    There was an error while creating your shipment as the quantity
                                    exceeded the purchase order
                                </p>
                            </div>
                            {response?.failed?.map((item, idx) => (
                                <div className="max-h-[50vh] overflow-y-scroll ">
                                    <div className="mt-2">
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            Package {idx + 1}
                                        </p>
                                    </div>
                                    <div className="bg-white space-y-3 mt-2 text-sm mb-2 shadow-cardDropdownShadow p-4">
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Product name</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.productName}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">SKU</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.sku}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Inventory category</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.inventoryCategory}
                                            </p>
                                        </div>

                                        <div className="flex justify-between text-[#FF3D1D] items-center font-rocGroteskMedium">
                                            <div className="flex items-center space-x-1 ">
                                                <i className="ri-information-line"></i>
                                                <p>Quantity</p>
                                            </div>
                                            <p className="text-end">
                                                {item?.qtyRemaining < 0
                                                    ? 0
                                                    : formatMoney().format(item?.qtyRemaining)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full px-8 py-4  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-5 max-h-[70vh] overflow-y-scroll ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                        setOpenReStockModal(true);
                                    }}
                                ></i>
                            </div>
                            <div className="flex mb-4 flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-2xl  text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment added successful
                                </p>
                                <p className="text-base text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    The shipment has been successfully created and associated with
                                    Purchase Order: PO-{replishmentStock?.purchaseOrder?.taId}
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-8 pb-6 pt-2  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                        </div>
                    </div>
                )}
            </ModalContainer>

            <ModalContainer
                open={openDocument}
                showCloseIcon={false}
                tailwindClassName="w-[700px]"
                closeModal={() => {
                    setOpenDocument(false);
                    setOpenReStockModal(true);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <div className="">
                        <div className="flex py-4  px-8 border-b border-slate-200 justify-between items-center">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Add documents related to shipment(s)
                            </p>
                            <i
                                className="ri-close-fill text-xl text-black cursor-pointer"
                                onClick={() => {
                                    setOpenDocument(false);
                                    setOpenReStockModal(true);
                                }}
                            ></i>
                        </div>
                        <div className=" px-8 mt-6 border-b border-slate-200 ">
                            <div>
                                <SelectInput
                                    value={shipmentId}
                                    name="shipment"
                                    placeholder={"Select Shipment"}
                                    handleChange={(name, value) => {
                                        setShipmentId(value);
                                    }}
                                    handleInputChange={debounce((evt) => {
                                        setPage(1);
                                        setDebouncedFilter({
                                            search: "",
                                            shipmentSearch: evt.target.value,
                                        });
                                    }, 800)}
                                    isRequired={true}
                                    searchLoading={fetchingPoShipment}
                                    dropdownOptions={poShipment?.map((data: any) => ({
                                        label: (
                                            <div className="flex space-x-1 items-center">
                                                <p>{data?.origin?.country}</p> <span> - </span>
                                                <p>{data?.destination?.address?.country}</p>
                                            </div>
                                        ),
                                        value: data?._id,
                                    }))}
                                />
                            </div>
                            {attachments?.length === 0 && (
                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                                        <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                        <p className="text-[#142837] mb-1 text-sm ">
                                            Drag your file here to upload
                                        </p>
                                        <p className="text-[#6B778C] text-sm mb-4">
                                            File size limit: 5MB
                                        </p>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {attachments?.length > 0 && (
                            <div className="px-8 max-h-[400px] overflow-y-auto mt-6 border-b border-slate-200">
                                {attachments?.map((doc, idx) => (
                                    <div
                                        key={idx}
                                        className="h-[68px] justify-between  items-center   border-t border-b border-r rounded border-slate-200 flex"
                                    >
                                        <div className="flex items-center space-x-3">
                                            <div className="bg-slate-50 border w-[68px] rounded-tl rounded-bl h-[68px] flex justify-center items-center border-slate-200">
                                                <i className="ri-file-fill text-4xl text-slate-500"></i>
                                            </div>
                                            <div>
                                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                    {doc?.filename}
                                                </p>
                                                <p className="text-slate-500  font-rocGroteskMedium text-sm">
                                                    PDF
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center pr-6 space-x-2 ">
                                            <div className="relative w-fit cursor-pointer">
                                                <Button
                                                    isLoading={replacingAttachment}
                                                    disabled={
                                                        uploadingAttachment ||
                                                        deletingImage ||
                                                        replacingAttachment
                                                    }
                                                    onClick={() => {
                                                        !replacingAttachment &&
                                                            document
                                                                .getElementById("fileInput")
                                                                ?.click();
                                                    }}
                                                    btnText="Replace File"
                                                    btnClassname="!px-4 !py-2.5 !bg-transparent !whitespace-nowrap  !border !border-[#DFE1E6] "
                                                />

                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className={`absolute z-[-1]  opacity-0`}
                                                    accept="png"
                                                    onChange={(e) =>
                                                        handleReplaceUpload(
                                                            idx,
                                                            e.target?.files[0]?.name,
                                                            e.target.files[0] as File
                                                        )
                                                    }
                                                />
                                            </div>

                                            <Button
                                                btnText="Remove"
                                                isLoading={deletingImage}
                                                disabled={
                                                    uploadingAttachment ||
                                                    deletingImage ||
                                                    replacingAttachment
                                                }
                                                btnClassname="!px-4 !py-2.5 !bg-transparent !text-[#FF0000] !border !border-[#DFE1E6] "
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveUpload(idx);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-6 px-5 font-rocGroteskMedium flex  justify-between items-center">
                                        <div className="flex items-center space-x-2.5">
                                            <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                            <div>
                                                <p className="text-[#142837] mb-1 text-sm ">
                                                    Drag your file here to upload
                                                </p>
                                                <p className="text-[#6B778C] text-sm mb-4">
                                                    File size limit: 5MB
                                                </p>
                                            </div>
                                        </div>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="mt-3 px-8 flex space-x-4 items-center ">
                            <Button
                                btnText="Close"
                                disabled={updateShipment}
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                onClick={() => {
                                    setOpenDocument(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                            <Button
                                isLoading={updateShipment}
                                btnText="Add uploaded file to shipment"
                                onClick={handleUpdateShipment}
                                btnClassname="!px-4 !py-2.5 "
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={pieChartModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[54%] max-lg:w-[95%]"
                closeModal={() => {
                    clearWarehouseFilter();
                    handleClearFilterTwo();
                    setDateRange("all");
                    setPieChartModal(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-[8px]">
                    <div className="flex items-center justify-between px-6 py-4 border-b border-slate-200">
                        <h4 className="text-lg font-rocGroteskMedium">Inventory per channel</h4>
                        <i
                            onClick={() => {
                                clearWarehouseFilter();
                                handleClearFilterTwo();
                                setDateRange("all");
                                setPieChartModal(false);
                            }}
                            className="ri-close-fill text-[24px] cursor-pointer "
                        ></i>
                    </div>

                    <div className="py-4 px-6">
                        <div className="flex items-center space-x-2">
                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {truncate(warehouseValue?.name, { length: 16 }) ||
                                                    "Warehouses"}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            {warehouseValue?.name && (
                                                <i
                                                    onClick={clearWarehouseFilter}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={warehouseSearch}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleWarehouseSearch}
                                            onInput={debounceChange}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            inputClassName="w-full"
                                        />
                                    </div>
                                }
                                // dropdownClassName=""
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 left-0 rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div className="max-h-[280px]">
                                                    {allWarehouses?.map((warehouse) => {
                                                        return (
                                                            <div
                                                                key={warehouse?._id}
                                                                onClick={() =>
                                                                    handleWarehouseFilterChange(
                                                                        warehouse?.warehouseName,
                                                                        warehouse?._id
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                        warehouseValue?.value ===
                                                                            warehouse?._id &&
                                                                        "bg-slate-100"
                                                                    }`}
                                                                >
                                                                    <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                    <div>
                                                                        <span
                                                                            className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                warehouse?.address
                                                                                    ?.address &&
                                                                                "mb-[2px]"
                                                                            }`}
                                                                        >
                                                                            {truncate(
                                                                                warehouse?.warehouseName,
                                                                                { length: 40 }
                                                                            )}
                                                                        </span>
                                                                        <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                            {truncate(
                                                                                warehouse?.address
                                                                                    ?.address,
                                                                                { length: 40 }
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(selectedProduct?.name, { length: 20 }) ||
                                                "All products"}
                                        </span>
                                        <div className="flex items-center space-x-1">
                                            {selectedProduct?.name && (
                                                <i
                                                    onClick={handleClearFilterTwo}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleSearchTwo}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            inputClassName="w-full"
                                        />
                                    </div>
                                }
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full min-w-full border border-slate-100  rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div className="max-h-[280px]">
                                                    {productSearchList?.map((product) => {
                                                        return (
                                                            <div
                                                                key={product?._id}
                                                                onClick={() =>
                                                                    handleFilterChangeTwo(
                                                                        product?.productName,
                                                                        product?._id
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                                >
                                                                    <div>
                                                                        <div className="flex items-center space-x-3">
                                                                            {product
                                                                                ?.productImageDetails
                                                                                ?.productImages?.[0] ? (
                                                                                <VendorAvatar
                                                                                    imageSrc={
                                                                                        product
                                                                                            ?.productImageDetails
                                                                                            ?.productImages?.[0]
                                                                                    }
                                                                                    size={24}
                                                                                    name={
                                                                                        product?.productName
                                                                                    }
                                                                                    containerClassname="!rounded-md"
                                                                                />
                                                                            ) : (
                                                                                <div className="w-6 h-6 rounded flex items-center justify-center bg-slate-200 ">
                                                                                    <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                <span className="block text-xs font-rocGroteskMedium">
                                                                                    {truncate(
                                                                                        product?.sku,
                                                                                        {
                                                                                            length: 30,
                                                                                        }
                                                                                    )}
                                                                                </span>
                                                                                <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                    {truncate(
                                                                                        product?.productName,
                                                                                        {
                                                                                            length: 50,
                                                                                        }
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    setDateRange(value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                        >
                                            {dateRange === "all" ? "All time" : dateRange}
                                        </span>
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                }
                                // dropdownItemsClasses={`!px-0 !py-0`}
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        label: "All time",
                                        value: "all",
                                    },
                                    {
                                        label: "Daily",
                                        value: "daily",
                                    },
                                    {
                                        label: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        label: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        label: "Yearly",
                                        value: "yearly",
                                    },
                                ]}
                            />
                        </div>

                        <div className="">
                            <div className="relative ">
                                <ReactECharts option={pieChartTwoOptions} />

                                <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                    <span className="block text-[12px] text-slate-500">
                                        {truncate(warehouseValue?.name, { length: 15 }) ||
                                            "All warehouses"}
                                    </span>
                                    <span className="block text-[20px] text-g-75 leading-[40px]">
                                        {millify(salesBreakdown.totalStock, {
                                            precision: 2,
                                        })}
                                    </span>
                                    <span className="block text-[12px] text-slate-500">Units</span>
                                </div>
                            </div>

                            <div className="max-h-[192px] overflow-y-auto">
                                {salesBreakdown?.channelData?.map((channel, idx) => {
                                    return (
                                        <div
                                            key={channel?._id + String(idx)}
                                            className="flex justify-between items-center mb-3"
                                        >
                                            <div className="flex items-center space-x-1.5">
                                                <span
                                                    className={`w-[14px] h-[14px] rounded-[2px]`}
                                                    style={{ backgroundColor: COLORS[idx] }}
                                                ></span>
                                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                    {channel?.warehouseName}
                                                </span>
                                            </div>
                                            <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                                {formatMoney(true).format(channel?.totalStock)}{" "}
                                                units
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={Boolean(expandAnalyticsCard)}
                showCloseIcon={false}
                tailwindClassName="w-[80%] "
                closeModal={() => {
                    setExpandAnalyticsCard("");
                }}
            >
                <div className="bg-white flex flex-col rounded-[8px] max-h-[500px] overflow-y-auto overflow-x-hidden">
                    <div className="p-4 flex justify-between items-center border-b border-slate-200">
                        <p
                            className={`text-[18px] font-rocGroteskMedium ${
                                expandAnalyticsCard === "totalStockValue"
                                    ? "text-[#C38D00]"
                                    : expandAnalyticsCard === "inStock"
                                    ? "text-[#22C55E]"
                                    : expandAnalyticsCard === "outOfStock"
                                    ? "text-[#FF3D1D]"
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? "text-[#6562E5]"
                                    : expandAnalyticsCard === "averageCogs"
                                    ? "text-[#0F172A]"
                                    : "text-[#FF3D1D]"
                            }`}
                        >
                            {expandAnalyticsCard === "totalStockValue"
                                ? "Total Stock Value"
                                : expandAnalyticsCard === "inStock"
                                ? "In Stock"
                                : expandAnalyticsCard === "outOfStock"
                                ? "Out of Stock"
                                : expandAnalyticsCard === "belowMinimum"
                                ? "Restock Now"
                                : expandAnalyticsCard === "averageCogs"
                                ? "Average Cost Per SKUs"
                                : ""}
                        </p>
                        <i
                            onClick={() => setExpandAnalyticsCard("")}
                            className="ri-close-fill text-base cursor-pointer"
                        ></i>
                    </div>

                    <div className="flex items-center space-x-2 py-3 px-4 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                        <div className="w-fill-available">
                            <TextInput
                                name={"search"}
                                value={
                                    expandAnalyticsCard === "totalStockValue"
                                        ? debouncedSearch
                                        : expandAnalyticsCard === "inStock"
                                        ? inStockDebouncedSearch
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? reStockDebouncedSearch
                                        : expandAnalyticsCard === "outOfStock"
                                        ? reStockDebouncedSearch
                                        : inStockDebouncedSearch
                                }
                                type={"text"}
                                inputPlaceholder={"Search product"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={(e) => {
                                    expandAnalyticsCard === "totalStockValue"
                                        ? setDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "inStock"
                                        ? setInStockDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? setReStockDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? setOutStockDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "averageCogs"
                                        ? setAvgCostDebouncedSearch(e.target.value)
                                        : setDebouncedSearch(e.target.value);
                                }}
                                onInput={(e) => {
                                    expandAnalyticsCard === "totalStockValue"
                                        ? handleTopDebouncedChange(e)
                                        : expandAnalyticsCard === "inStock"
                                        ? handleInStockDebouncedChange(e)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? handleReStockDebouncedChange(e)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? handleOutStockDebouncedChange(e)
                                        : handleInStockDebouncedChange(e);
                                    expandAnalyticsCard === "totalStockValue"
                                        ? totalStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "inStock"
                                        ? inStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? reStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? outStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "averageCogs"
                                        ? avgCostSetUpdatedPage(1)
                                        : totalStockSetUpdatedPage(1);
                                }}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                                }
                            />
                        </div>
                        <Dropdown
                            name="filter"
                            value={channelFilter}
                            dropdownTriggerClassName={" "}
                            handleChange={(name, value) => {
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "averageCogs"
                                    ? avgCostSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                                handleChannelsChange(value);
                            }}
                            dropdown={
                                <div className="bg-white flex items-center justify-between space-x-2 w-fit h-10 px-3 border border-tradeally-neutral-20 !rounded shadow-[0_1px_2px_0_rgba(91,104,113,0.15)]">
                                    <div className="flex items-center space-x-2">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                            {expandAnalyticsCard === "totalStockValue"
                                                ? totalStockValueChannel || "All channels"
                                                : expandAnalyticsCard === "inStock"
                                                ? inStockValueChannel || "All channels"
                                                : expandAnalyticsCard === "belowMinimum"
                                                ? restockValueChannel || "All channels"
                                                : expandAnalyticsCard === "outOfStock"
                                                ? outStockValueChannel || "All channels"
                                                : expandAnalyticsCard === "averageCogs"
                                                ? avgCostValueChannel || "All channels"
                                                : "All channels"}
                                        </span>
                                        {Boolean(
                                            expandAnalyticsCard === "totalStockValue"
                                                ? totalStockValueChannel
                                                : expandAnalyticsCard === "inStock"
                                                ? inStockValueChannel
                                                : expandAnalyticsCard === "belowMinimum"
                                                ? restockValueChannel
                                                : expandAnalyticsCard === "outOfStock"
                                                ? outStockValueChannel
                                                : avgCostValueChannel
                                        ) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    // handleClearFilter();
                                                    if (expandAnalyticsCard === "totalStockValue") {
                                                        setTotalStockValueChannel("");
                                                    } else if (expandAnalyticsCard === "inStock") {
                                                        setInStockValueChannel("");
                                                    } else if (
                                                        expandAnalyticsCard === "belowMinimum"
                                                    ) {
                                                        setReStockValueChannel("");
                                                    } else if (
                                                        expandAnalyticsCard === "outOfStock"
                                                    ) {
                                                        setOutStockValueChannel("");
                                                    } else {
                                                        setAvgCostValueChannel("");
                                                    }
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )}
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    label: (
                                        <div
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                   !w-[190px] px-4 "
                                        >
                                            <i
                                                className={`${
                                                    channelFilter === ""
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            All channels
                                        </div>
                                    ),
                                    value: "",
                                },
                                ...salesChannel
                                    ?.filter((item) => item?.toLowerCase() !== "synth")
                                    ?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                     !w-[190px] px-4 capitalize"
                                            >
                                                <i
                                                    className={`${
                                                        channelFilter === item
                                                            ? "ri-checkbox-line text-carribean-green"
                                                            : "ri-checkbox-blank-line text-slate-200"
                                                    }    text-xl`}
                                                ></i>
                                                {item}
                                            </div>
                                        ),
                                        value: item,
                                    })),
                            ]}
                        />
                        <Dropdown
                            handleChange={(name, value) => {
                                handleWarehouseChange({
                                    target: { name, value },
                                });
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "averageCogs"
                                    ? avgCostSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                            }}
                            search={
                                <div ref={warehouseRef} className="">
                                    <TextInput
                                        value={warehouseSearch}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleWarehouseSearch}
                                        onInput={debounceChange}
                                        inputPlaceholder="Search warehouse"
                                        containerClassname=""
                                        inputContainerClassname={"!h-[40px] "}
                                    />
                                </div>
                            }
                            dropdown={
                                <div className="flex items-center justify-between lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] h-10 w-fit px-[10px] gap-2 !border !border-tradeally-neutral-20">
                                    <div
                                        className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block capitalize whitespace-nowrap`}
                                    >
                                        {warehouse
                                            ? truncate(warehouse?.split("-")[0], {
                                                  length: 12,
                                              })
                                            : "All warehouses"}
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-[200px]
                                         !w-[200px] !min-w-min !pt-0
                            `}
                            name="valueRange"
                            dropdownOptions={[
                                {
                                    label: (
                                        <div
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                        >
                                            <i
                                                className={`${
                                                    warehouse === ""
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            All Warehouses
                                        </div>
                                    ),
                                    value: "",
                                },
                                ...allWarehouses?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                    border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                        >
                                            <i
                                                className={`${
                                                    warehouse?.split("-")[1] === item?._id
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            {item?.warehouseName}
                                        </div>
                                    ),
                                    value: item?.warehouseName + "-" + item?._id,
                                })),
                            ]}
                        />
                        <Dropdown
                            handleChange={(name, value) => {
                                handleDateChange({
                                    target: { name, value },
                                });
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "averageCogs"
                                    ? avgCostSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] h-10 !w-fit gap-2 !border !border-tradeally-neutral-20">
                                    <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                    <div
                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block whitespace-nowrap`}
                                    >
                                        {modalDurationFilter || "This year"}
                                    </div>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                            name="valueRange"
                            dropdownOptions={[
                                ...dateValues2?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-g-75 whitespace-nowrap font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                        >
                                            {item?.period}
                                        </div>
                                    ),
                                    value: item?.value + "-" + item?.period,
                                })),
                            ]}
                        />
                        {(expandAnalyticsCard === "inStock" ||
                            expandAnalyticsCard === "outOfStock") && (
                            <Button
                                btnText="Export"
                                icon={<i className="ri-arrow-go-forward-fill text-p-50"></i>}
                                isLoading={exportingInStockList || exportingOutStockList}
                                btnClassname="text-p-50 !w-fit !h-10 px-3 !bg-white shadow-buttonShadow2 !border !border-slate-100"
                                onClick={() => {
                                    if (expandAnalyticsCard === "inStock") {
                                        dispatch(inventoryActions?.exportInStockList());
                                    }
                                    if (expandAnalyticsCard === "outOfStock") {
                                        dispatch(inventoryActions?.exportOutStockList());
                                    }
                                }}
                            />
                        )}
                    </div>
                    {(expandAnalyticsCard === "totalStockValue" &&
                        totalStockValueDataLoading &&
                        !isLoadingMore &&
                        !debouncedSearch) ||
                    (expandAnalyticsCard === "inStock" &&
                        inStockValueDataLoading &&
                        !inStockDebouncedSearch &&
                        !isInStockLoadMore) ||
                    (expandAnalyticsCard === "belowMinimum" &&
                        reStockValueDataLoading &&
                        !reStockDebouncedSearch &&
                        !isReStockLoadMore) ||
                    (expandAnalyticsCard === "outOfStock" &&
                        outStockValueDataLoading &&
                        !outStockDebouncedSearch &&
                        !isOutStockLoadMore) ||
                    (expandAnalyticsCard === "averageCogs" &&
                        avgCostValueDataLoading &&
                        !avgCostDebouncedSearch &&
                        !isAvgCostLoadMore) ? (
                        <CustomTableLoader
                            tableHeader={
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockValueTableHeader
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockValueTableHeader
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? belowMinValueTableHeader
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outOfStockValueTableHeader
                                    : expandAnalyticsCard === "averageCogs"
                                    ? avgCostValueTableHeader
                                    : totalStockValueTableHeader
                            }
                        />
                    ) : (expandAnalyticsCard === "totalStockValue" &&
                          rawTotalStockValue?.length > 0) ||
                      (expandAnalyticsCard === "inStock" && rawInStockValue?.length > 0) ||
                      (expandAnalyticsCard === "belowMinimum" && rawRestockValue?.length > 0) ||
                      (expandAnalyticsCard === "outOfStock" && rawOutStockValue?.length > 0) ||
                      (expandAnalyticsCard === "averageCogs" && rawAvgCostValue?.length > 0) ? (
                        <>
                            <div id="modalTableContent">
                                <CustomTable
                                    tableBody={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockValueTableBody
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockValueTableBody
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? belowMinValueTableBody
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outOfStockValueTableBody
                                            : expandAnalyticsCard === "averageCogs"
                                            ? avgCostValueTableBody
                                            : totalStockValueTableBody
                                    }
                                    tableHeader={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockValueTableHeader
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockValueTableHeader
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? belowMinValueTableHeader
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outOfStockValueTableHeader
                                            : expandAnalyticsCard === "averageCogs"
                                            ? avgCostValueTableHeader
                                            : totalStockValueTableHeader
                                    }
                                    isScrollable={false}
                                    scrollWithin={false}
                                    isInifiniteScroll={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass="mb-12"
                                    sort={handleTotalValueSortChange}
                                />

                                {expandAnalyticsCard === "totalStockValue"
                                    ? istotalStockLoadMore && totalStockValueDataLoading
                                    : expandAnalyticsCard === "inStock"
                                    ? isInStockLoadMore && inStockValueDataLoading
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? isReStockLoadMore && reStockValueDataLoading
                                    : expandAnalyticsCard === "outOfStock"
                                    ? isOutStockLoadMore && outStockValueDataLoading
                                    : expandAnalyticsCard === "averageCogs"
                                    ? isAvgCostLoadMore && avgCostValueDataLoading
                                    : istotalStockLoadMore &&
                                      totalStockValueDataLoading && (
                                          <div className="flex my-4 justify-center">
                                              <Loader color="gm-25" size={4} />
                                          </div>
                                      )}
                                {expandAnalyticsCard === "totalStockValue"
                                    ? totalStockValuePagination?.currentPage ===
                                      totalStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockValuePagination?.currentPage ===
                                      inStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockValuePagination?.currentPage ===
                                      reStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockValuePagination?.currentPage ===
                                      outStockValuePagination?.noOfPages
                                    : totalStockValuePagination?.currentPage ===
                                          totalStockValuePagination?.noOfPages && (
                                          <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                              <p className="text-center"> End of list</p>
                                          </div>
                                      )}
                            </div>
                            <div className="flex justify-end mb-14 pr-5">
                                <ResponsivePaginationComponent
                                    current={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? Number(totalStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "inStock"
                                            ? Number(inStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? Number(reStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? Number(outStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "averageCogs"
                                            ? Number(avgCostValuePagination?.currentPage)
                                            : Number(totalStockValuePagination?.currentPage)
                                    }
                                    total={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? Number(totalStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "inStock"
                                            ? Number(inStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? Number(reStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? Number(outStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "averageCogs"
                                            ? Number(avgCostValuePagination?.noOfPages)
                                            : Number(totalStockValuePagination?.noOfPages)
                                    }
                                    maxWidth={350}
                                    onPageChange={(page) => {
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? reStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "averageCogs"
                                            ? avgCostSetUpdatedPage(page)
                                            : totalStockSetUpdatedPage(page);
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                            {(
                                expandAnalyticsCard === "totalStockValue"
                                    ? debouncedSearch
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockDebouncedSearch
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockDebouncedSearch
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockDebouncedSearch
                                    : expandAnalyticsCard === "averageCogs"
                                    ? avgCostDebouncedSearch
                                    : debouncedSearch
                            ) ? (
                                <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                    <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                        <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                    </div>

                                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                        Sorry, no results match your search. Refine your criteria
                                        and try again.
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    {expandAnalyticsCard === "totalStockValue" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            hasButton
                                            btnText="Add product"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory/add-product", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "inStock" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No In-Stock Products"
                                            description="There are no items currently in stock. Check your inventory or add new products to get started."
                                            hasButton
                                            btnText="Add product"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory/add-product", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "belowMinimum" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No In-Stock Products"
                                            description="There are no items that need restocking at the moment. Monitor your inventory levels to ensure timely restocking."
                                            hasButton
                                            btnText="View Inventory"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "outOfStock" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Out of Stock Items"
                                            description="All items are currently in stock. Monitor your inventory to stay ahead of potential stockouts."
                                            hasButton
                                            btnText="View Inventory"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "averageCogs" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            hasButton
                                            btnText="Add product"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory/add-product", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            hasButton
                                            btnText="Add product"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory/add-product", {
                                                    state: { internalNavigation: true },
                                                })
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default ProductList;
