"use client";

import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import PageFrame from "components/layout/PageFrame";
import React, { Component, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import millify from "millify";
import { Tooltip } from "react-tooltip";
import CampaignComments from "./CampaignComments/index";
import { adjustHeight } from "variables";
import useGetCampaignComments from "hooks/workforceHooks/useGetCampaignComments";
import useGetCampaignSectionComments from "hooks/workforceHooks/useGetCampaignSectionComments";
import EmptyStateComp from "components/common/EmptyStateComp";
import { workforceActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import Loader from "components/common/Loader";
import { Mention, MentionsInput } from "react-mentions";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import { sanitizeTaggedUserStringForSubmission } from "helpers/sanitizeTaggedUserString";
import { ChannelProvider } from "ably/react";
import { formatMoney, getFromStorage } from "helpers";
import { useStream } from "@langchain/langgraph-sdk/react";
import type { Message } from "@langchain/langgraph-sdk";
import { MarketingAgentState } from "types/marketingCampaignType";
import useGetSingleCampaign from "hooks/workforceHooks/useGetSingleCampaign";
import moment from "moment";
import CampaignSimulationDetails from "components/views/Synth2.0/MarketingCampaign/CampaignSimulationDetails";
import { cloneDeep } from "lodash";
import config from "config/config";

const CampaignDetail = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [msg, setMsg] = useState<string>("");
    const [activeCommentSection, setActiveCommentSection] = useState<number | null>(null);
    const [selectedSection, setSelectedSection] = useState(0);
    const [selectedSentimentTab, setSelectedSentimentTab] = useState<number>(0);
    const [isResolvedTab, setIsResolvedTab] = useState<boolean>(false);
    const [isMentionTab, setIsMentionTab] = useState<boolean>(false);
    // const [parentComments, setParentComments] = useState<any>([]);
    // const [sectionComments, setSectionComments] = useState<any>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const newMsgContainerRef = useRef(null);
    const { campaignId } = useParams();

    const { data: parentComments } = useGetCampaignComments(isResolvedTab, isMentionTab);
    const { data: sectionComments } = useGetCampaignSectionComments(
        selectedSection,
        isResolvedTab,
        isMentionTab
    );
    const { creatingCampaignComment, createCampaignCommentSuccess, fetchingCampaignComment } =
        useAppSelector((state) => state.workforce);
    const {
        data: team,
        handleSearch,
        handleDebouncedChange,
        search,
        isFetching: isFetchingTeamList,
    } = useGetTeam();

    const { data: campaignDetail } = useGetSingleCampaign();

    const campaignThreadId = cloneDeep(campaignDetail?.aiAgentTasks)?.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )?.[0]?.threadId;

    const thread = useStream<typeof MarketingAgentState.State>({
        apiUrl: config.LANGCHAIN_API_URL,
        apiKey: config.LANGCHAIN_API_KEY,
        assistantId: "cia",
        messagesKey: "messages",
        threadId: campaignThreadId,
    });

    const { values } = thread;
    console.log("values", values);
    console.log("campaignDetail", campaignDetail);
    const teamList = team?.map((item) => ({
        id: item?._id,
        display: item?.fullName || item?.firstName || item?.lastName || item?.email,
    }));

    const sentimentBubbleChartData = values?.[
        selectedSentimentTab === 0 ? "positiveKeywords" : "negativeKeywords"
    ]
        ?.sort(
            (a, b) =>
                selectedSentimentTab === 0
                    ? b.score - a.score // Sort highest to lowest for positive
                    : a.score - b.score // Sort lowest to highest for negative
        )

        ?.slice(0, 10)
        ?.map((item, index) => {
            // Calculate x position incrementing by 20 each time
            const startX = (index + 1) * 31;

            // Calculate y position alternating between 20 and 40
            const startY = index % 2 === 0 ? 30 : 110;

            return [startX, startY, item?.score, `"${item?.keyword}"`];
        });

    const handleAddComment = (
        section: number,
        content: string,

        parentId?: string
    ) => {
        const taggedIds = sanitizeTaggedUserStringForSubmission(content)?.taggedIds;
        const msgToSubmit = sanitizeTaggedUserStringForSubmission(content)?.submissionString;

        const body: { [key: string]: any } = {
            content: msgToSubmit,
            // "taggedUsersId": [
            // "677feae9cea7f4a0a6aed36d"
            // ],
            // parentId: "67ba7b2df473b300bfcd76f4",
            section: section,
        };

        if (taggedIds?.length > 0) {
            body.taggedUsersId = taggedIds;
        }

        if (parentId) {
            body.parentId = parentId;
        }

        dispatch(workforceActions.createCampaignComment(campaignId, body));
    };

    const getSectionCommentsCount = (section: number) => {
        const sectionComments = [...parentComments]?.filter((item) => item?.section === section);

        return sectionComments?.length;
    };

    // useEffect(() => {
    //     if (Boolean(parentCommentsData)) {
    //         setParentComments(parentCommentsData);
    //     }
    // }, [parentCommentsData?.length]);

    // useEffect(() => {
    //     if (Boolean(sectionCommentsData)) {
    //         setSectionComments(sectionCommentsData);
    //     }
    // }, [sectionCommentsData?.length]);

    useEffect(() => {
        if (Boolean(createCampaignCommentSuccess)) {
            setActiveCommentSection(null);
            setActiveTab(1);
            setMsg("");
        }
    }, [createCampaignCommentSuccess]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (newMsgContainerRef.current && !newMsgContainerRef.current?.contains(event.target)) {
                setActiveCommentSection(null);
                setMsg("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [newMsgContainerRef]);

    return (
        <PageFrame isLoading={false}>
            <div className="flex items-center justify-between px-8 py-5 border-x border-t rounded-t-lg border-n-20 sticky top-[-20px] bg-white z-[9999]">
                <div className="flex items-center space-x-1.5">
                    <i
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                    ></i>
                    {/* <span className="text-lg font-rocGroteskMedium">your AI teammate</span> */}
                    <div className="flex items-center space-x-2">
                        {campaignDetail?.agent?.avatar ? (
                            <img
                                className="w-12 h-12 rounded-lg bg-cover bg-center bg-no-repeat"
                                src={campaignDetail?.agent?.avatar}
                                alt="agent avatar"
                            />
                        ) : (
                            <div className="w-12 h-12 bg-p-50 rounded-lg flex items-center justify-center shadow-[0px_4px_8px_-4px_#10182808]">
                                <i className="ri-robot-fill text-white text-2xl"></i>
                            </div>
                        )}

                        <div className="font-rocGroteskMedium">
                            <p className="text-lg">{campaignDetail?.agent?.teamMateName}</p>
                            <p className="text-xs text-slate-500">
                                Campaign Intelligence Assistant
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <div className="flex items-center space-x-2 rounded-md py-1.5 px-2.5 font-rocGroteskMedium">
                        <span className="w-2 h-2 rounded-full bg-[#22C55E] " />
                        <span className="text-sm">Active</span>
                    </div>

                    {/* <Dropdown
                        dropdown={
                            <div className="flex items-center  whitespace-nowrap h-[40px] py-2 px-4 space-x-1 bg-tradeally-neutral-20">
                               
                                <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                    View more campaigns history
                                </span>
                                
                                <i className="ri-arrow-down-s-line text-g-75"></i>
                            </div>
                        }
                        handleChange={(name, value) => {
                            console.log("first --->>>", name, value);
                        }}
                        search={
                            <div>
                                <TextInput
                                    value={""}
                                    name={"search"}
                                    type={"text"}
                                    required={false}
                                    // onChange={handleSearchTwo}
                                    // onInput={handleDebouncedChangeTwo}
                                    inputPlaceholder="Search warehouse"
                                    containerClassname=""
                                    // inputContainerClassname={"!border-none !rounded-none "}
                                    // inputClassName="w-[50%]"
                                />
                            </div>
                        }
                        dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100  rounded `}
                        dropdownOptions={[
                            {
                                customChild: (
                                    <div>
                                        <div className="max-h-[280px]">
                                            {[1, 2, 3]?.map((warehouse, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        onClick={() => {
                                                            // filterChange(
                                                            //     warehouse?.warehouseName,
                                                            //     warehouse?._id
                                                            // );
                                                            // handleWarehouseChange(
                                                            //     warehouse?._id
                                                            // );
                                                        }}
                                                    >
                                                        <div
                                                            className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer`}
                                                        >
                                                            <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                            <div>
                                                                <span>Option {idx + 1}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    /> */}

                    {/* <Button
                        btnText={`Share`}
                        type="button"
                        // isLoading={creatingAiTeammate || updatingAiTeammate}
                        onClick={() => console.log("clickkk")}
                        btnClassname="!px-4 !py-2 !w-fit"
                        icon={<i className="ri-share-forward-line text-black"></i>}
                    /> */}
                </div>
            </div>
            <CampaignSimulationDetails
                values={values}
                creatingCampaignComment={creatingCampaignComment}
                parentComments={parentComments}
                handleDebouncedChange={handleDebouncedChange}
                isFetchingTeamList={isFetchingTeamList}
                campaignDetail={campaignDetail}
                teamList={teamList}
                fetchingCampaignComment={fetchingCampaignComment}
                createCampaignCommentSuccess={createCampaignCommentSuccess}
                sectionComments={sectionComments}
                sentimentBubbleChartData={sentimentBubbleChartData}
                selectedSentimentTab={selectedSentimentTab}
                setSelectedSentimentTab={setSelectedSentimentTab}
                handleAddComment={handleAddComment}
                getSectionCommentsCount={getSectionCommentsCount}
                isSaved={true}
                setIsResolvedTab={setIsResolvedTab}
                setIsMentionTab={setIsMentionTab}
            />
        </PageFrame>
    );
};

export default CampaignDetail;
