import React from "react";
import Button from "components/common/Button";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/common/InputField/Checkbox";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import Loader from "components/common/Loader";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { Tooltip } from "react-tooltip";
import TextInput from "components/common/InputField/TextInput";
import SelectInput from "components/common/InputField/SelectInput";
import { truncate } from "lodash";
import EmptyStateComp from "components/common/EmptyStateComp";

type SelectProductsProps = {
    step?: number;
    setStep?: React.Dispatch<React.SetStateAction<number>>;
    setDisabledChannels?: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedProducts: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
    selectedProducts: { [key: string]: any }[];
    pagination: { [key: string]: any };
    disabledChannels: string[];
    setSkuNumber?: React.Dispatch<React.SetStateAction<string>>;
    skuNumber?: string;
    data: { [key: string]: any }[];
    isFetching: boolean;
    total: number;
    search?: string;
    handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDebouncedChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    masterSkuList?: { [key: string]: any }[];
    handleDebouncedChangeTwo?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isFetchingMasterSkuList?: boolean;
    setMasterChannel?: React.Dispatch<React.SetStateAction<string>>;
    setMasterSkuId?: React.Dispatch<React.SetStateAction<string>>;
    isLoadingMore?: boolean;
};

const CreateSku = ({
    step,
    setStep,
    setSelectedProducts,
    selectedProducts,
    skuNumber,
    setSkuNumber,
    data,
    isFetching,
    total,
    disabledChannels,
    pagination,
    setDisabledChannels,
    search,
    handleSearch,
    handleDebouncedChange,
    masterSkuList,
    handleDebouncedChangeTwo,
    isFetchingMasterSkuList,
    setMasterChannel,
    setMasterSkuId,
    isLoadingMore,
}: SelectProductsProps) => {
    const navigate = useNavigate();

    const handleSelectItem = (item) => {
        setSelectedProducts((prev) => {
            const isAlreadySelected = prev.some(
                (selectedItem) => selectedItem?.channel === item?.channel
            );

            if (isAlreadySelected) {
                return prev.filter((selectedItem) => selectedItem?.channel !== item.channel);
            } else {
                return [...prev, item];
            }
        });

        setDisabledChannels((prev) => {
            const isAlreadyDisabled = prev.includes(item?.channel);

            if (isAlreadyDisabled) {
                return prev.filter((channel) => channel !== item?.channel);
            } else {
                return [...prev, item.channel];
            }
        });
    };

    const onChangeStep = () => {
        if (skuNumber === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please input your sku number"} type={"error"} />
            ));
        } else if (selectedProducts.length <= 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={"Please select at least one additional SKU to map"}
                    type={"error"}
                />
            ));
        } else {
            setStep(2);
        }
    };

    return (
        <div className="mt-[31px]  flex justify-center">
            <div className="w-[70%] h-[75vh] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_#10182814]">
                <div className="w-full h-full relative">
                    <div className="px-6 pt-4 pb-3 border-b border-slate-200">
                        <div className="font-rocGroteskMedium flex justify-between items-center mb-2">
                            <div className="flex items-center space-x-1.5">
                                <h4 className="text-g-75 text-base">Create SKU mapping</h4>
                            </div>
                            <div className="w-[93px]">
                                <span className="text-sm flex justify-end text-slate-500 ">
                                    Step {step}/2
                                </span>
                                <div className="grid  grid-cols-2 space-x-1.5">
                                    <span className="h-1 bg-[#FFB902] rounded"></span>
                                    <span className="h-1 bg-[#EADFC1] rounded"></span>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">
                                    Select Master SKU <span className="text-r-50">*</span>
                                </p>
                            </div>
                            <SelectInput
                                value={skuNumber}
                                name="skuNumber"
                                placeholder={"Select SKU"}
                                handleChange={(name, value) => setSkuNumber(value)}
                                handleInputChange={handleDebouncedChangeTwo}
                                className=""
                                isRequired={true}
                                searchLoading={isFetchingMasterSkuList}
                                dropdownOptions={masterSkuList?.map((item, idx) => {
                                    return {
                                        label: (
                                            <div
                                                key={idx}
                                                className={`flex   items-center justify-between pb-2 border-slate-200`}
                                                onClick={() => {
                                                    setMasterChannel(
                                                        item?.channel || item?.channels?.[0]
                                                    );
                                                    setMasterSkuId(item?._id);
                                                }}
                                            >
                                                <div className={` flex  space-x-3 items-center`}>
                                                    <div>
                                                        {item?.productImageDetails
                                                            ?.productImages?.[0] ? (
                                                            <VendorAvatar
                                                                imageSrc={
                                                                    item?.productImageDetails
                                                                        ?.productImages?.[0]
                                                                }
                                                                size={32}
                                                                name={item?.productName}
                                                                containerClassname="!rounded-md"
                                                            />
                                                        ) : (
                                                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#042821] ">
                                                            {truncate(item?.productName, {
                                                                length: 60,
                                                            })}
                                                        </p>

                                                        <div
                                                            className={` flex items-center space-x-1`}
                                                        >
                                                            <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                                                {truncate(item?.sku, {
                                                                    length: 60,
                                                                })}
                                                            </p>
                                                            <span>
                                                                {displayProductChannelIcon(
                                                                    item?.channel ||
                                                                        item.channels?.[0],
                                                                    "!w-4 !h-4"
                                                                )}{" "}
                                                            </span>
                                                            <span className="text-sm font-rocGroteskMedium text-[#042821]">
                                                                {item?.channel ||
                                                                    item?.channels?.[0]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                        value: `${item?.sku}`,
                                    };
                                })}
                            />
                        </div>
                        {skuNumber && (
                            <div className="mb-4">
                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search  products"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    containerClassname={"!w-full max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />
                            </div>
                        )}
                        {skuNumber && (
                            <div>
                                <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                    Select Additional SKUs
                                </p>
                            </div>
                        )}
                    </div>

                    {skuNumber ? (
                        <div id="modal" className="h-[41vh] overflow-y-auto space-y-3 pt-3 pb-14">
                            {isFetching && !isLoadingMore ? (
                                <Loader />
                            ) : (
                                <>
                                    {data?.map((item, idx) => (
                                        <div
                                            key={idx}
                                            className={`flex px-6 items-center justify-between border-b pb-2 border-slate-200 cursor-pointer`}
                                            onClick={() => handleSelectItem(item)}
                                        >
                                            <div className={` flex w-[80%] space-x-3 items-center`}>
                                                <div>
                                                    <a id={`product-column-${idx}`}>
                                                        <Checkbox
                                                            name=""
                                                            isChecked={selectedProducts.some(
                                                                (selectedItem) =>
                                                                    (selectedItem.channel ||
                                                                        selectedItem
                                                                            ?.channels?.[0]) ===
                                                                        (item.channel ||
                                                                            item?.channels?.[0]) &&
                                                                    selectedItem.sku === item.sku
                                                            )}
                                                            // onChxange={() => handleSelectItem(item)}
                                                            className={`checked:!bg-[#FFB902]
                                                                ${
                                                                    disabledChannels.includes(
                                                                        item.channel ||
                                                                            item?.channels?.[0]
                                                                    ) &&
                                                                    !selectedProducts.some(
                                                                        (selectedItem) =>
                                                                            selectedItem.channel ===
                                                                                (item.channel ||
                                                                                    item
                                                                                        ?.channels?.[0]) &&
                                                                            selectedItem.sku ===
                                                                                item.sku
                                                                    ) &&
                                                                    "opacity-20"
                                                                }
                                                                 checked:!border-[#FFB902] !text-[#042821]`}
                                                            disabled={
                                                                disabledChannels.includes(
                                                                    item.channel ||
                                                                        item?.channels?.[0]
                                                                ) &&
                                                                !selectedProducts.some(
                                                                    (selectedItem) =>
                                                                        selectedItem.channel ===
                                                                            (item.channel ||
                                                                                item
                                                                                    ?.channels?.[0]) &&
                                                                        selectedItem.sku ===
                                                                            item.sku
                                                                )
                                                            }
                                                        />
                                                    </a>
                                                    {disabledChannels.includes(
                                                        item.channel || item?.channels?.[0]
                                                    ) &&
                                                        !selectedProducts.some(
                                                            (selectedItem) =>
                                                                selectedItem.channel ===
                                                                    (item.channel ||
                                                                        item?.channels?.[0]) &&
                                                                selectedItem.sku === item.sku
                                                        ) && (
                                                            <Tooltip
                                                                anchorSelect={`#product-column-${idx}`}
                                                                place={"top"}
                                                                content={
                                                                    "You cannot map skus from the same channel"
                                                                }
                                                                style={{
                                                                    maxWidth: "250px",
                                                                    zIndex: 9999,
                                                                    background: "#142837",
                                                                    borderRadius: "6px",
                                                                    border: "1px solid #E2E8F0",
                                                                    color: "#FFFFFF",
                                                                    boxShadow:
                                                                        "0px 4px 16px 0px rgba(16, 24, 40, 0.03)",
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                                <div
                                                    className={` ${
                                                        disabledChannels.includes(
                                                            item.channel || item?.channels?.[0]
                                                        ) &&
                                                        !selectedProducts.some(
                                                            (selectedItem) =>
                                                                selectedItem.channel ===
                                                                    (item.channel ||
                                                                        item?.channels?.[0]) &&
                                                                selectedItem.sku === item.sku
                                                        ) &&
                                                        "opacity-20"
                                                    }`}
                                                >
                                                    {item?.productImageDetails
                                                        ?.productImages?.[0] ? (
                                                        <VendorAvatar
                                                            imageSrc={
                                                                item?.productImageDetails
                                                                    ?.productImages?.[0]
                                                            }
                                                            size={32}
                                                            name={item?.productName}
                                                            containerClassname="!rounded-md"
                                                        />
                                                    ) : (
                                                        <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                            <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={`
                                             ${
                                                 disabledChannels.includes(
                                                     item.channel || item.channels?.[0]
                                                 ) &&
                                                 !selectedProducts.some(
                                                     (selectedItem) =>
                                                         selectedItem.channel ===
                                                             (item.channel || item.channels?.[0]) &&
                                                         selectedItem.sku === item.sku
                                                 ) &&
                                                 "opacity-20"
                                             }
                                            `}
                                                >
                                                    <p className="text-sm font-rocGroteskMedium text-[#042821] ">
                                                        {item?.productName}
                                                    </p>
                                                    <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                                        {item?.sku}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={`   ${
                                                    disabledChannels.includes(
                                                        item.channel || item.channels?.[0]
                                                    ) &&
                                                    !selectedProducts.some(
                                                        (selectedItem) =>
                                                            selectedItem.channel ===
                                                                (item.channel ||
                                                                    item.channels?.[0]) &&
                                                            selectedItem.sku === item.sku
                                                    ) &&
                                                    "opacity-20"
                                                } flex  w-[13%]  items-center space-x-1`}
                                            >
                                                <span>
                                                    {displayProductChannelIcon(
                                                        item?.channel || item.channels?.[0]
                                                    )}{" "}
                                                </span>
                                                <span className="text-sm font-rocGroteskMedium text-[#042821]">
                                                    {item?.channel || item?.channels?.[0]}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {pagination?.currentPage === pagination?.noOfPages && (
                                        <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                            <p className="text-center"> End of list</p>
                                        </div>
                                    )}
                                    {data.length > 0 && isFetching && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <EmptyStateComp
                            title="Select a master SKU"
                            description="Please select a master SKU to see your list of mappable SKUs"
                        />
                    )}

                    <div className="flex items-center justify-between absolute bottom-0 w-full bg-white border-t border-slate-200 px-6 py-4">
                        <div>
                            <p className="font-rocGroteskMedium text-sm">
                                {selectedProducts?.length} out of {total} products selected
                            </p>
                        </div>
                        <div className="flex items-center space-x-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!py-3 !bg-n-20"
                                disabled={false}
                                onClick={() =>
                                    navigate(`/dashboard/inventory?currTab=5`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                            />

                            <Button
                                btnText="Preview products"
                                btnClassname="!py-3 !whitespace-nowrap"
                                isLoading={false}
                                onClick={onChangeStep}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateSku;
