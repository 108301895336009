import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetCampaignCommentReplies = (commentId?: string) => {
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();

    const {
        fetchingCampaignCommentReplies,
        fetchCampaignCommentRepliesSuccess,
        fetchCampaignCommentRepliesFailure,
        createCampaignCommentSuccess,
    } = useAppSelector((state) => state.workforce);

    const [replies, setReplies] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (campaignId && commentId) {
            dispatch(workforceActions.getCampaignCommentReplies(campaignId, commentId));

            return () => {
                dispatch(workforceActions.resetGetCampaignCommentRepliesSuccess());
            };
        }
    }, [dispatch, campaignId, commentId]);

    useEffect(() => {
        if (Boolean(fetchCampaignCommentRepliesSuccess)) {
            setReplies(fetchCampaignCommentRepliesSuccess?.comment);
        }
    }, [fetchCampaignCommentRepliesSuccess]);

    return {
        isFetching: fetchingCampaignCommentReplies,
        error: fetchCampaignCommentRepliesFailure,
        data: replies,
    };
};

export default useGetCampaignCommentReplies;
