import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import DateInput from "components/common/DateInput";
import AddressInput from "components/common/InputField/AddressInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import countries from "variables/countries";
import OrderPreview from "./OrderPreview";
import { hasHTMLTag, sanitizeHtml } from "helpers";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/config";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import useGetNavLinks from "hooks/navLinks";

const CreateQuote = () => {
    const [address, setAddress] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const navigate = useNavigate();
    const { allowedLinks } = useGetNavLinks();
    const firstLink = allowedLinks[0]?.link;
    const { goBack } = useNavigationHandler({ fallbackPath: firstLink });

    const tableHeader = [
        { title: "Item", widthClass: "w-[13.6%]" },
        { title: "Unit price", widthClass: "w-[13.6%]" },
        { title: "Quantity", widthClass: "w-[13.6%]" },
        { title: "Total price", widthClass: "w-[13.6%]" },
        { title: "Tax", widthClass: "w-[13.6%]" },
        { title: "Discount", widthClass: "w-[13.6%]" },
    ];

    const tableBody = [1]?.map((item, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <SelectInput
                            name="name"
                            value={""}
                            placeholder={"Select"}
                            className={"!border-0 !rounded-none "}
                            handleChange={(name, value) => console.log(name, value, idx)}
                            isRequired={true}
                            dropdownOptions={[1, 1, 1, 1]?.map((res) => ({
                                label: (
                                    <div className="font-rocGroteskMedium -mt-2 flex space-x-1">
                                        <VendorAvatar imageSrc={""} size={24} name={"Avatar"} />

                                        <p className="text-slate-500 ">Avatar</p>
                                    </div>
                                ),
                                value: "avatar",
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={` w-full border-slate-100 text-sm text-slate-700 `}>$10</div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <TextInput
                            value={""}
                            name={"name"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {}}
                            // disabled={}
                            placeholder="Qty"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={` w-full border-slate-100 text-sm text-slate-700 `}>$10</div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={` w-full border-slate-100 text-sm text-slate-700 `}>$10</div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        $500
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    return !isPreview ? (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div
                onClick={() => goBack()}
                className="cursor-pointer space-x-3 flex items-center py-4"
            >
                <i className="ri-arrow-left-line text-sm "></i>
                <p className="text-sm underline  font-rocGroteskMedium">Back</p>
            </div>
            <div className=" py-4 border-b border-slate-200 flex justify-between items-center">
                <p className="text-[22px] font-rocGroteskMedium text-gm-50">Quote creation form</p>
                <Button
                    onClick={() => setIsPreview(true)}
                    btnClassname="!py-2.5 !px-4 !w-fit !h-10"
                    btnText="Preview order"
                />
            </div>

            <div className="py-4">
                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Customer details</h6>

                    <div className="grid grid-cols-2 gap-3">
                        <TextInput
                            value={""}
                            name={"firstName"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {}}
                            placeholder={"First name"}
                            containerClassname="w-full "
                            inputContainerClassname={" "}
                            inputClassName={`w-full`}
                        />
                        <TextInput
                            value={""}
                            name={"lastName"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {}}
                            placeholder={"Last name"}
                            containerClassname="w-full "
                            inputContainerClassname={" "}
                            inputClassName={`w-full`}
                        />

                        <TextInput
                            value={""}
                            name={"email"}
                            type={"email"}
                            required={true}
                            onChange={(e) => {}}
                            placeholder={"Email"}
                            containerClassname="w-full "
                            inputContainerClassname={" "}
                            inputClassName={`w-full`}
                        />
                        <div className="w-full grid grid-cols-[115px_calc(100%-119px)] space-x-1">
                            <SelectInput
                                value={""}
                                name="phoneExt"
                                placeholder={"Ext"}
                                handleChange={(name, value) => {
                                    // handleChange({target: {name, value}})
                                }}
                                isRequired={true}
                                dropdownOptions={countries
                                    .filter((data) => Boolean(data?.codes[0]))
                                    .sort((p1, p2) =>
                                        p1.codes[0] > p2.codes[0]
                                            ? 1
                                            : p1.codes[0] < p2.codes[0]
                                            ? -1
                                            : 0
                                    )
                                    .map((item) => ({
                                        label: item.codes[0]?.replace(" ", ""),
                                        value: item.codes[0]?.replace(" ", ""),
                                        icon: (
                                            <ReactCountryFlag
                                                countryCode={item?.abbreviation}
                                                svg
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                style={{
                                                    width: "14px",
                                                    height: "14px",
                                                    borderRadius: "50%",
                                                    marginTop: "-4px",
                                                }}
                                            />
                                        ),
                                    }))}
                            />
                            <TextInput
                                name={"phoneNo"}
                                type={"number"}
                                value={""}
                                // onChange={handleChange}
                                placeholder={"Phone number"}
                                minLength={10}
                                required={true}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                            />
                        </div>

                        <AddressInput
                            setData={setAddress}
                            savedAddress={
                                address?.billingAddress ? address?.billingAddress?.address : ""
                            }
                            placeholder="Billing address"
                            required={true}
                            name={"billingAddress"}
                        />
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-8" />

                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Quote details</h6>

                    <div>
                        <div className="grid grid-cols-2 gap-3 mb-6">
                            <DateInput
                                label="EIssued date"
                                value={new Date()}
                                // onChange={(newDate) => console.log("bestBefore", newDate)}
                                showCalendar={false}
                                required={true}
                                // onCalendarToggle={setShowBBDate}
                            />

                            <DateInput
                                label="Due date"
                                value={new Date()}
                                // onChange={(newDate) => console.log("bestBefore", newDate)}
                                showCalendar={false}
                                required={true}
                                // onCalendarToggle={setShowBBDate}
                            />
                            <SelectInput
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                className={"!border-g-60 !border "}
                                value={"USD"}
                                name="currency"
                                placeholder={"Your currency"}
                                handleChange={(name, value) =>
                                    console.log({ target: { name, value } })
                                }
                                isRequired={true}
                                dropdownOptions={[
                                    {
                                        label: "GBP (£)",
                                        value: "GBP",
                                    },
                                    {
                                        label: "USD ($)",
                                        value: "USD",
                                    },
                                    {
                                        label: "EUR (€)",
                                        value: "EUR",
                                    },
                                    {
                                        label: "NGN (₦)",
                                        value: "NGN",
                                    },
                                ]}
                            />
                            <SelectInput
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                className={" "}
                                value={""}
                                name="paymentTerms"
                                placeholder={"Payment terms"}
                                handleChange={(name, value) =>
                                    console.log({ target: { name, value } })
                                }
                                isRequired={true}
                                dropdownOptions={[
                                    {
                                        label: "GBP (£)",
                                        value: "GBP",
                                    },
                                    {
                                        label: "USD ($)",
                                        value: "USD",
                                    },
                                    {
                                        label: "EUR (€)",
                                        value: "EUR",
                                    },
                                    {
                                        label: "NGN (₦)",
                                        value: "NGN",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-8" />

                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Product details</h6>

                    <div>
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellSelectable={false}
                                headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                                isScrollable={false}
                                isCellBordered={true}
                                bodyItemClass={"hover:!bg-transparent"}
                            />
                        </div>
                        <div className="py-3 flex items-center justify-between">
                            <div className="w-fit flex items-center space-x-3">
                                <i className="ri-add-circle-line text-[#6562E5]"></i>
                                <span className="font-rocGroteskMedium text-sm text-[#6562E5] underline">
                                    Add an item
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-4" />

                <div>
                    <h6 className="flex justify-between mb-4 text-sm font-rocGroteskMedium text-gm-50">
                        Terms & Conditions
                    </h6>
                    <div className="space-y-0.5 text-sm font-rocGroteskMedium text-slate-500">
                        <Editor
                            apiKey={config.TINYMICE_API}
                            value={""}
                            onEditorChange={(newValue, editor) => {
                                // setCustomerDetails((prev) => ({
                                //     ...prev,
                                //     termsAndCondition: newValue,
                                // }));
                            }}
                            init={{
                                height: 400,
                                menubar: false,
                                plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                ],
                                placeholder:
                                    "Add guarantee terms, payment term, scope of work, return policy and other note for the ordered products or services",
                                // selector: "textarea",
                                resize: false,
                                branding: false,
                                toolbar:
                                    "undo redo | casechange blocks | bold italic underline link backcolor | " +
                                    "alignleft aligncenter alignright alignjustify | " +
                                    "bullist numlist checklist outdent indent | removeformat",
                                content_style:
                                    "body { font-family:rocGrotesk,Helvetica,Arial,sans-serif; font-size:14px; }",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <OrderPreview setIsPreview={setIsPreview} />
    );
};

export default CreateQuote;
