import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

type TextareaInputProps = {
    value?: string;
    placeholder?: string;
    inputPlaceholder?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    title?: string;
    minLength?: number;
    onInput?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    inputClassName?: string;
    label?: string;
    labelClassName?: string;
    rows?: number;
    inputContainerClassname?: string;
    containerClassname?: string;
    floatingPlaceholderClass?: string;
    showInputComment?: boolean;
    inputComment?: string;
    inputContainerStyles?: { [key: string]: any };
    tooltipText?: string;
};

const TextareaInput = ({
    value,
    placeholder,
    inputPlaceholder,
    name,
    required,
    disabled,
    onKeyDown,
    title,
    minLength,
    onInput,
    onChange,
    inputClassName,
    label,
    labelClassName,
    rows,
    inputContainerClassname,
    containerClassname,
    floatingPlaceholderClass,
    showInputComment,
    inputComment,
    inputContainerStyles,
    tooltipText,
}: TextareaInputProps) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={`w-full ${containerClassname}`}>
            {label && (
                <div className={`${labelClassName} text flex items-center space-x-1`}>
                    <span>{label}</span>{" "}
                    {tooltipText && (
                        <div>
                            <a id={`description-${name}`}>
                                <i className="ri-information-fill text-slate-300 text-xs mb-[-2px]"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#description-${name}`}
                                place={"right"}
                                content={tooltipText}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div
                className={`w-full relative border-[0.5px] border-gm-25  ${
                    isFocused ? "!border-g-60 !border-[0.5px] !border-b-[1px]" : ""
                } rounded flex items-center ${
                    disabled && "bg-slate-50"
                } ${inputContainerClassname}`}
                style={inputContainerStyles}
            >
                <div className="w-full">
                    <textarea
                        value={value}
                        className={`textAreaInput resize-none border-0 bg-transparent mt-[8px] rounded outline-0 w-full px-3 py-3 text-sm text-gm-50 font-rocGroteskMedium placeholder:text-gm-35 placeholder:font-rocGroteskMedium disabled:text-slate-500 ${inputClassName}`}
                        name={name}
                        rows={rows}
                        required={required}
                        placeholder={inputPlaceholder}
                        onInput={onInput}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        title={title}
                        disabled={disabled}
                    />
                    {placeholder && (
                        <div
                            className={`textAreafloating-label text-sm text-gm-35 font-rocGroteskRegular ${floatingPlaceholderClass}`}
                        >
                            {placeholder}

                            {required && !isFocused && (
                                <span className="required !text-r-50 text-[inherit] mt-[-2px] font-rocGroteskMedium">
                                    *
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showInputComment && (
                <div className="flex ml-1 items-center space-x-1">
                    <div className="bg-gm-25 flex justify-center items-center h-[10px] rounded-full w-[10px]">
                        <img
                            alt="vector"
                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/icons/notice_iy2t2o.svg"
                        />
                    </div>
                    <p className="text-[10px] mt-0.5 text-gm-35 font-rocGrotesk">{inputComment}</p>
                </div>
            )}
        </div>
    );
};

export default TextareaInput;
