import React from "react";

type WorkforceAnalyticsCardProps = {
    title: string;
    value: string;
    bottomComp?: JSX.Element;
    containerClassname?: string;
};

const WorkforceAnalyticsCard = ({
    title,
    value,
    bottomComp,
    containerClassname,
}: WorkforceAnalyticsCardProps) => {
    return (
        <div
            className={` font-rocGroteskMedium p-4 rounded border border-slate-200 h-[127px] shadow-[0px_4px_8px_-4px_#10182808] ${containerClassname}`}
        >
            <span className="block mb-1 text-base ">{title}</span>
            <span className="block text-[24px]">{value}</span>
            {bottomComp}
        </div>
    );
};

export default WorkforceAnalyticsCard;
