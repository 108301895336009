import React from "react";
import Loader from "./Loader";
import { useAppSelector } from "hooks/storeHooks";
import { isLoggedInUserPermitted, isUserPermitted } from "helpers/userPermissions";
import toast from "react-hot-toast";
import CustomToast from "./CustomToast";
import { getFromStorage } from "helpers";

type ButtonProps = {
    btnText?: string;
    type?: "button" | "submit";
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    btnClassname?: string;
    btnCustomContent?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    btnTextClassName?: string;
    btnType?: string;
    rightIcon?: React.ReactNode;
    id?: string;
    btnPermission?: string[];
};

const Button = ({
    btnText,
    type,
    onClick,
    btnClassname,
    btnCustomContent,
    isLoading,
    disabled,
    icon,
    btnTextClassName,
    btnType,
    rightIcon,
    id,
    btnPermission,
}: ButtonProps) => {
    // const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);
    const userProfile = getFromStorage("ally-user");
    // console.log("fetchUserProfileSuccess", fetchUserProfileSuccess);
    // const permissions = fetchUserProfileSuccess?.permission;

    const isPermitted = isUserPermitted(userProfile?.user?.permission, btnPermission);

    return (
        <button
            onClick={(e) =>
                !isPermitted
                    ? toast.custom((t) => (
                          <CustomToast
                              t={t}
                              message="You don't have access to this feature"
                              type="error"
                          />
                      ))
                    : onClick?.(e)
            }
            type={type && isPermitted ? type : "button"}
            id={id}
            disabled={disabled || isLoading}
            className={`${btnClassname}  w-full flex justify-center py-3 items-center px-4
             bg-y-10 rounded text-sm font-rocGroteskMedium text-black disabled:opacity-50 ${
                 disabled && "opacity-50"
             } `}
        >
            {isLoading ? (
                <Loader color={"inherit"} size={4} />
            ) : // <p>loading</p>
            btnCustomContent ? (
                btnCustomContent
            ) : (
                <>
                    {btnType === "textFirst" ? (
                        <div className="flex space-x-2 items-center">
                            <p className={btnTextClassName}> {btnText}</p>
                            <div> {icon}</div>
                        </div>
                    ) : icon ? (
                        <div className="flex space-x-2 items-center">
                            <div> {icon}</div>
                            {btnText && <p className={btnTextClassName}> {btnText}</p>}
                            {rightIcon && <div> {rightIcon}</div>}
                        </div>
                    ) : (
                        btnText
                    )}
                </>
            )}
        </button>
    );
};

export default Button;
