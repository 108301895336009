import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetSalesOrderList from "hooks/salesHooks/useGetSalesOrderList";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type OrderListProps = {
    durationValue?: string;
    dateRange?: Date[];
};

const OrderList = ({ durationValue, dateRange }: OrderListProps) => {
    const [activeOrderTab, setActiveOrderTab] = useState<number>(0);
    const [channelFilter, setChannelFilter] = useState<string>("");
    const tabs = ["All orders", "Ongoing orders", "Fulfilled orders", "Pending orders"];
    const navigate = useNavigate();

    const {
        data: allWarehouses,
        warehouseValue,
        search: warehouseSearch,
        handleDebouncedChange: debounceChange,
        handleSearch: handleWarehouseSearch,
        handleFilterChange: handleWarehouseFilterChange,
        handleClearFilter: clearWarehouseFilter,
    } = useGetAllWarehouses();

    const {
        data: salesOrderData,
        isFetching: isFetchingSalesOrder,
        ongoingSalesOrder,
        fulfilledSalesOrder,
        pendingSalesOrder,
        isLoadingMore,
        pagination,
        ongoingPagination,
        pendingPagination,
        fulfilledPagination,
        updatedPage,
        setUpdatedPage,
        search,
        fulfilledSearch,
        ongoingSearch,
        pendingSearch,
        handleSearch,
        handleDebouncedChange,
        handleFilterChange,
        handleClearFilter,
        fulfilledWarehousefilter,
        ongoingWarehousefilter,
        pendingWarehousefilter,
        warehousefilter,
        setStartEndDate,
    } = useGetSalesOrderList(
        activeOrderTab === 0
            ? ""
            : activeOrderTab === 1
            ? "ongoing"
            : activeOrderTab === 2
            ? "fulfilled"
            : "pending",
        channelFilter
    );
    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();

    const allOrderTableHeader = [
        { title: "Order ID", widthClass: "w-[13.5%]" },
        { title: "No of products", widthClass: "w-[13.5]" },
        { title: "Sale source", widthClass: "w-[13.5%]" },
        { title: "Lead time", widthClass: "w-[13.5%]" },
        { title: "Estimated delivery date", widthClass: "w-[13.5%]" },
        { title: "Estimated revenue", widthClass: "w-[13.5%]" },
        { title: "Status", widthClass: "w-[13.5%]" },
        { title: "", widthClass: "w-[4.8%]" },
    ];

    const allOrderTableBody = salesOrderData?.map((order, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <span className="text-slate-600 text-[13px] block">
                        SO-
                        {truncate(order?.taId || order?.orderId, { length: 18 })}
                    </span>
                    <span className="text-slate-500 text-[10px] block">
                        {moment(order?.saleDate)?.format("DD.MM.YYYY; hh:mm a")}
                    </span>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/order/${order?._id}`, { state: { internalNavigation: true } });
            },
        },
        {
            content: (
                <div
                    className={`py-3  flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    {order?.totalProducts}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <div className="flex items-center space-x-2">
                        {displayProductChannelIcon(
                            order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel,
                            order?.channel?.toLowerCase()?.includes("synth")
                                ? "!w-6 !h-6"
                                : "w-8 h-8"
                        )}

                        <span className="">
                            {order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel}
                        </span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {Math.round(order?.leadTime)} days
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {order?.deliveryDate ? moment(order?.deliveryDate).format("DD.MM.YYYY") : "N/A"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(order?.currency)}
                    {formatMoney().format(order?.totalRevenue?.toFixed(2))}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div
                        className={`w-fit h-[21px] flex items-center text-sm py-1 px-2  ${
                            order?.status === "fulfilled"
                                ? "bg-g-25"
                                : order?.status === "pending"
                                ? "bg-[#FCC34E26]"
                                : "bg-[#CCDDF380]"
                        } rounded-2xl`}
                    >
                        <span
                            className={`text-xs ${
                                order?.status === "fulfilled"
                                    ? "text-g-60"
                                    : order?.status === "pending"
                                    ? "text-[#F4B000]"
                                    : "text-b-55"
                            }`}
                        >
                            {order?.status || "N/A"}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        value={""}
                        dropdown={<i className="ri-more-2-fill "></i>}
                        dropdownClassName={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View details",
                                value: "view",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}`, {
                                        state: { internalNavigation: true },
                                    }),
                            },
                            {
                                label: "Edit order",
                                value: "edit",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}/edit`, {
                                        state: { internalNavigation: true },
                                    }),
                                disabled: order?.status?.toLowerCase() === "fulfilled",
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const ongoingOrderTableHeader = [
        { title: "Order ID", widthClass: "w-[13.5%]" },
        { title: "No of products", widthClass: "w-[13.5]" },
        { title: "Sale source", widthClass: "w-[13.5%]" },
        { title: "Lead time", widthClass: "w-[13.5%]" },
        { title: "Estimated delivery date", widthClass: "w-[13.5%]" },
        { title: "Estimated revenue", widthClass: "w-[13.5%]" },
        { title: "Status", widthClass: "w-[13.5%]" },
        { title: "", widthClass: "w-[4.8%]" },
    ];

    const ongoingOrderTableBody = ongoingSalesOrder?.map((order, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <span className="text-slate-600 text-[13px] block">
                        SO-{truncate(order?.taId || order?.orderId, { length: 18 })}
                    </span>
                    <span className="text-slate-500 text-[10px] block">
                        {moment(order?.saleDate)?.format("DD.MM.YYYY; hh:mm a")}
                    </span>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/order/${order?._id}`, { state: { internalNavigation: true } });
            },
        },
        {
            content: (
                <div
                    className={`py-3  flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    {order?.totalProducts}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <div className="flex items-center space-x-2">
                        {displayProductChannelIcon(
                            order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel,
                            "!w-6 !h-6"
                        )}

                        <span className="">
                            {order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel}
                        </span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {Math.round(order?.leadTime)} days
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {order?.deliveryDate ? moment(order?.deliveryDate).format("DD.MM.YYYY") : "N/A"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(order?.currency)}
                    {formatMoney().format(order?.totalRevenue?.toFixed(2))}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div
                        className={`w-fit h-[21px] flex items-center text-sm py-1 px-2 bg-[#CCDDF380] rounded-2xl`}
                    >
                        <span className={`text-xs text-b-55`}>{order?.status || "N/A"}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        value={""}
                        dropdown={<i className="ri-more-2-fill "></i>}
                        dropdownClassName={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View details",
                                value: "view",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}`, {
                                        state: { internalNavigation: true },
                                    }),
                            },
                            {
                                label: "Edit order",
                                value: "edit",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}/edit`, {
                                        state: { internalNavigation: true },
                                    }),
                                disabled: order?.status?.toLowerCase() === "fulfilled",
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const fulfilledOrderTableHeader = [
        { title: "Order ID", widthClass: "w-[11.8%]" },
        { title: "No of products", widthClass: "w-[11.9]" },
        { title: "Sale source", widthClass: "w-[11.8%]" },
        { title: "Lead time", widthClass: "w-[11.9%]" },
        { title: "Delivery date", widthClass: "w-[11.8%]" },
        { title: "Revenue", widthClass: "w-[11.9%]" },
        // { title: "Landed cost", widthClass: "w-[11.8%]" },
        { title: "Status", widthClass: "w-[11.9%]" },
        { title: "", widthClass: "w-[4.8%]" },
    ];

    const fulfilledOrderTableBody = fulfilledSalesOrder?.map((order, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <span className="text-slate-600 text-[13px] block">
                        SO-{truncate(order?.taId || order?.orderId, { length: 18 })}
                    </span>
                    <span className="text-slate-500 text-[10px] block">
                        {moment(order?.saleDate)?.format("DD.MM.YYYY; hh:mm a")}
                    </span>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/order/${order?._id}`, { state: { internalNavigation: true } });
            },
        },
        {
            content: (
                <div
                    className={`py-3  flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    {order?.totalProducts}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <div className="flex items-center space-x-2">
                        {displayProductChannelIcon(
                            order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel,
                            "!w-6 !h-6"
                        )}

                        <span className="">
                            {order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel}
                        </span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {Math.round(order?.leadTime)} days
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {order?.deliveryDate ? moment(order?.deliveryDate).format("DD.MM.YYYY") : "N/A"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(order?.currency)}
                    {formatMoney().format(order?.totalRevenue?.toFixed(2))}
                </div>
            ),
        },

        // {
        //     content: (
        //         <div
        //             className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
        //         >
        //             $100.00-d
        //         </div>
        //     ),
        // },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div
                        className={`w-fit h-[21px] flex items-center text-sm py-1 px-2 bg-g-25 rounded-2xl`}
                    >
                        <span className={`text-xs text-g-60`}>{order?.status || "N/A"}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        value={""}
                        dropdown={<i className="ri-more-2-fill "></i>}
                        dropdownClassName={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        name={"faq-dropdown"}
                        dropdownOptions={[]}
                    />
                </div>
            ),
        },
    ]);

    const pendingOrderTableHeader = [
        { title: "Order ID", widthClass: "w-[13.5%]" },
        { title: "No of products", widthClass: "w-[13.5]" },
        { title: "Sale source", widthClass: "w-[13.5%]" },
        { title: "Lead time", widthClass: "w-[13.5%]" },
        { title: "Estimated delivery date", widthClass: "w-[13.5%]" },
        { title: "Estimated revenue", widthClass: "w-[13.5%]" },
        { title: "Status", widthClass: "w-[13.5%]" },
        { title: "", widthClass: "w-[4.8%]" },
    ];

    const pendingOrderTableBody = pendingSalesOrder?.map((order, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <span className="text-slate-600 text-[13px] block">
                        SO-{truncate(order?.taId || order?.orderId, { length: 18 })}
                    </span>
                    <span className="text-slate-500 text-[10px] block">
                        {moment(order?.saleDate)?.format("DD.MM.YYYY; hh:mm a")}
                    </span>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/order/${order?._id}`, { state: { internalNavigation: true } });
            },
        },
        {
            content: (
                <div
                    className={`py-3  flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    {order?.totalProducts}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900`}
                >
                    <div className="flex items-center space-x-2">
                        {displayProductChannelIcon(
                            order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel,
                            "!w-6 !h-6"
                        )}

                        <span className="">
                            {order?.channel?.toLowerCase()?.includes("synth")
                                ? "Synth"
                                : order?.channel}
                        </span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {Math.round(order?.leadTime)} days
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {order?.deliveryDate ? moment(order?.deliveryDate).format("DD.MM.YYYY") : "N/A"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(order?.currency)}
                    {formatMoney().format(order?.totalRevenue?.toFixed(2))}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div
                        className={`w-fit h-[21px] flex items-center text-sm py-1 px-2 bg-[#FCC34E26] rounded-2xl`}
                    >
                        <span className={`text-xs text-[#F4B000]`}>{order?.status || "N/A"}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        value={""}
                        dropdown={<i className="ri-more-2-fill "></i>}
                        dropdownClassName={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[140px]  border border-slate-100 !w-[159px] rounded !max-h-fit`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View details",
                                value: "view",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}`, {
                                        state: { internalNavigation: true },
                                    }),
                            },
                            {
                                label: "Edit order",
                                value: "edit",
                                action: () =>
                                    navigate(`/dashboard/order/${order?._id}/edit`, {
                                        state: { internalNavigation: true },
                                    }),
                                disabled: order?.status?.toLowerCase() === "fulfilled",
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const displayActiveOrderTab = () => {
        switch (activeOrderTab) {
            case 0:
                return isFetchingSalesOrder && Number(updatedPage) === 1 ? (
                    <CustomTableLoader tableHeader={allOrderTableHeader} />
                ) : salesOrderData?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={allOrderTableBody}
                            tableHeader={allOrderTableHeader}
                            isAllSelectable={false}
                            isCellBordered={true}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                            tableContainerClass="pb-[90px] mb-[-90px]"
                        />
                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            case 1:
                return isFetchingSalesOrder && Number(updatedPage) === 1 ? (
                    <CustomTableLoader tableHeader={ongoingOrderTableHeader} />
                ) : ongoingSalesOrder?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={ongoingOrderTableBody}
                            tableHeader={ongoingOrderTableHeader}
                            isAllSelectable={false}
                            isCellBordered={true}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                            tableContainerClass="pb-[90px] mb-[-90px]"
                        />

                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {ongoingPagination?.currentPage === ongoingPagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            case 2:
                return isFetchingSalesOrder && Number(updatedPage) === 1 ? (
                    <CustomTableLoader tableHeader={fulfilledOrderTableHeader} />
                ) : fulfilledSalesOrder?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={fulfilledOrderTableBody}
                            tableHeader={fulfilledOrderTableHeader}
                            isAllSelectable={false}
                            isCellBordered={true}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                        />

                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {fulfilledPagination?.currentPage === fulfilledPagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            case 3:
                return isFetchingSalesOrder && Number(updatedPage) === 1 ? (
                    <CustomTableLoader tableHeader={fulfilledOrderTableHeader} />
                ) : pendingSalesOrder?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={pendingOrderTableBody}
                            tableHeader={pendingOrderTableHeader}
                            isAllSelectable={false}
                            isCellBordered={true}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                            tableContainerClass="pb-[90px] mb-[-90px]"
                        />

                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {pendingPagination?.currentPage === pendingPagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            default:
                return (
                    <div>
                        <CustomTable
                            tableBody={allOrderTableBody}
                            tableHeader={allOrderTableHeader}
                            isAllSelectable={false}
                            isCellBordered={false}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                        />
                    </div>
                );
        }
    };

    useEffect(() => {
        setStartEndDate(
            getCurrentStartAndEndDate(
                durationValue === "custom" && !dateRange?.[0] && !dateRange?.[1]
                    ? "monthly"
                    : durationValue,
                dateRange
            )
        );
    }, [durationValue, dateRange]);

    return (
        <div>
            <TabsContainer
                tabs={tabs}
                activeTab={activeOrderTab}
                setActiveTab={(idx) => {
                    setActiveOrderTab(idx);
                    setUpdatedPage(1);
                }}
                component={displayActiveOrderTab()}
                className="!px-0"
                itemClassName="!pb-2"
                borderLineClase={"!text-slate-100 w-[45%] max-lg:w-full mb-6"}
                tabRowClassname="!overflow-visible"
                showButton={
                    <div className=" flex items-center space-x-4">
                        <TextInput
                            name={"search"}
                            value={
                                activeOrderTab === 0
                                    ? search
                                    : activeOrderTab === 1
                                    ? ongoingSearch
                                    : activeOrderTab === 2
                                    ? fulfilledSearch
                                    : pendingSearch
                            }
                            type={"text"}
                            inputPlaceholder={"Search product name, sku, order id"}
                            inputClassName={"!h-[38px] !w-[300px] pl-[0px] !bg-white text-sm !mb-0"}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px]  max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-n-20"
                            }
                        />

                        {/* <SelectInput
                            value={channelFilter}
                            name="channelFilter"
                            placeholder={"Channel"}
                            handleChange={(name, value) => {
                                setChannelFilter(value);
                                // setChannel(value);
                            }}
                            clearValue={true}
                            isRequired={true}
                            dropdownOptions={[
                                ...salesChannel?.map((item) => {
                                    return {
                                        label: item,
                                        value: item,
                                    };
                                }),
                            ]}
                            className="!h-[38px]"
                        /> */}

                        <Dropdown
                            handleChange={(name, value) => {
                                setChannelFilter(value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <div className="flex items-center space-x-1">
                                        {/* <i className="ri-map-pin-line text-sm text-g-75 "></i> */}
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(channelFilter, { length: 16 }) ||
                                                "Sales channel"}
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        {Boolean(channelFilter) && (
                                            <i
                                                onClick={() => {
                                                    setChannelFilter("");
                                                }}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            // dropdownClassName=""
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            id="modalContent"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 right-0 rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                ...salesChannel?.map((item) => {
                                    return {
                                        label: item,
                                        value: item,
                                    };
                                }),
                            ]}
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("first", name, value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <div className="flex items-center space-x-1">
                                        {/* <i className="ri-map-pin-line text-sm text-g-75 "></i> */}
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(
                                                activeOrderTab === 0
                                                    ? warehousefilter?.name
                                                    : activeOrderTab === 1
                                                    ? ongoingWarehousefilter?.name
                                                    : activeOrderTab === 2
                                                    ? fulfilledWarehousefilter?.name
                                                    : pendingWarehousefilter?.name,
                                                { length: 16 }
                                            ) || "Locations"}
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        {Boolean(
                                            activeOrderTab === 0
                                                ? warehousefilter?.name
                                                : activeOrderTab === 1
                                                ? ongoingWarehousefilter?.name
                                                : activeOrderTab === 2
                                                ? fulfilledWarehousefilter?.name
                                                : pendingWarehousefilter?.name
                                        ) && (
                                            <i
                                                onClick={() => {
                                                    clearWarehouseFilter();
                                                    handleClearFilter();
                                                }}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            search={
                                <div>
                                    <TextInput
                                        value={warehouseSearch}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleWarehouseSearch}
                                        onInput={debounceChange}
                                        inputPlaceholder="Search warehouse"
                                        containerClassname=""
                                        // inputContainerClassname={"!border-none !rounded-none "}
                                        inputClassName="w-full"
                                    />
                                </div>
                            }
                            // dropdownClassName=""
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            id="modalContent"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 right-0 rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="max-h-[280px]">
                                                {allWarehouses?.map((warehouse) => {
                                                    return (
                                                        <div
                                                            key={warehouse?._id}
                                                            onClick={() => {
                                                                handleWarehouseFilterChange(
                                                                    warehouse?.warehouseName,
                                                                    warehouse?._id
                                                                );

                                                                handleFilterChange(
                                                                    warehouse?.warehouseName,
                                                                    warehouse?._id
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                    warehouseValue?.value ===
                                                                        warehouse?._id &&
                                                                    "bg-slate-100"
                                                                }`}
                                                            >
                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                <div>
                                                                    <span
                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                            warehouse?.address
                                                                                ?.address &&
                                                                            "mb-[2px]"
                                                                        }`}
                                                                    >
                                                                        {truncate(
                                                                            warehouse?.warehouseName,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                    <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                        {truncate(
                                                                            warehouse?.address
                                                                                ?.address,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default OrderList;
