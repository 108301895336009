import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import {
    displayFileIcon,
    formatMoney,
    getCurrencyFromCurrencyCode,
    hasHTMLTag,
    sanitizeHtml,
} from "helpers";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
// import { getEditPermissionList } from "helpers/getEditPermissionList";
import CustomFields from "components/common/CustomFields";
import { inventoryActions } from "redux/Ldb/actions";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const StockDetails = () => {
    const [stockDetails, setStockDetails] = useState<any>({});
    const { id } = useParams();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/inventory" });
    const [customArray, setCustomArray] = useState<any>();
    const { fetchingSingleStock, fetchedSingleStockSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (stockDetails?.customFields?.length > 0) {
            const formatCustomFields = () => {
                return stockDetails?.customFields?.map((field) => {
                    let formattedField = {
                        name: undefined,
                        value: null,
                    };

                    for (const key in field) {
                        if (key !== "fieldType") {
                            if (field.fieldType !== "phone" && field.fieldType !== "currency") {
                                formattedField.name = key;
                                formattedField.value = field[key];
                                formattedField[field?.fieldType] = field[key];
                            } else if (typeof field[key] === "object") {
                                formattedField = { ...formattedField, ...field[key], name: key };
                            }
                        }
                    }
                    if (formattedField.value === null) {
                        delete formattedField.value;
                    }

                    return { ...field, ...formattedField };
                });
            };
            setCustomArray(formatCustomFields);
        }
    }, [stockDetails]);

    useEffect(() => {
        if (id) {
            dispatch(inventoryActions.fetchSingleStock(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedSingleStockSuccess)) {
            setStockDetails(fetchedSingleStockSuccess?.stock);
            setIsPageLoaded(true);
        }
    }, [fetchedSingleStockSuccess]);
    return (
        <PageFrame isLoading={!isPageLoaded}>
            <div>
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100 top-[-20px] md:sticky  z-[50] bg-white">
                        <p
                            className="text-sm font-rocGroteskMedium flex items-center cursor-pointer gap-1"
                            onClick={() => goBack()}
                        >
                            <i className="ri-arrow-left-line text-carribean-green text-base"></i>
                            <span className="underline">Back</span>
                        </p>
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Edit stock entry"
                                btnPermission={[
                                    "all_access",
                                    "add_stock",
                                    "transfer_stock",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                type="button"
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/inventory/stock-control/edit-stock/${id}?currTab=${
                                            currTab || 1
                                        }`,
                                        { state: { internalNavigation: true } }
                                    )
                                }
                                // permissions={getEditPermissionList("inventory")}
                            />
                        </div>
                    </div>
                    <div className="py-6 px-8 border-b border-slate-100">
                        <div className="flex items-center gap-4">
                            {stockDetails?.inventory?.productImageDetails?.productAvatar ? (
                                <img
                                    className="w-16 h-16 rounded border border-slate-200 object-cover"
                                    src={
                                        stockDetails?.inventory?.productImageDetails?.productAvatar
                                    }
                                    alt="product avatar"
                                />
                            ) : (
                                <div className="w-16 h-16 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <p className="text-base font-rocGroteskMedium">
                                    {stockDetails?.inventory?.productName}
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {stockDetails?.inventory?.inventoryCategory}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 px-8">
                        <div className="w-[65%]">
                            <div className="mb-8">
                                <p className="mb-4 text-base font-rocGroteskMedium">
                                    Stock details
                                </p>
                                <div className="border border-slate-100 rounded-lg">
                                    <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                        <p className="text-slate-500">Vendor</p>
                                        {stockDetails?.vendor?.profile?.businessName ? (
                                            <div className="flex items-center gap-3 ">
                                                <VendorAvatar
                                                    name={
                                                        stockDetails?.vendor?.profile?.businessName
                                                    }
                                                    imageSrc={stockDetails?.vendor?.profile?.logo}
                                                    size={32}
                                                    containerClassname="!rounded"
                                                />
                                                <p className="text-sm font-rocGroteskMedium">
                                                    {stockDetails?.vendor?.profile?.businessName}
                                                </p>
                                            </div>
                                        ) : (
                                            <p className="text-sm font-rocGroteskMedium">---</p>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                        <p className="text-slate-500 text-left w-1/2">Quantity</p>
                                        <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                            {stockDetails?.productStockDetails?.stockLevel?.toLocaleString() ||
                                                "---"}
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                        <p className="text-slate-500 text-left w-1/2">Unit Cost</p>
                                        {stockDetails?.inventory?.unitCost?.amount &&
                                        stockDetails?.inventory?.unitCost?.currency ? (
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                {getCurrencyFromCurrencyCode(
                                                    stockDetails?.inventory?.unitCost?.currency
                                                )}
                                                {formatMoney()?.format(
                                                    stockDetails?.inventory?.unitCost?.amount
                                                )}
                                            </p>
                                        ) : (
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                ---
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                        <p className="text-slate-500 text-left w-1/2">Batch ID</p>
                                        <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                            {stockDetails?.batchId || "---"}
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between p-4 text-sm font-rocGroteskMedium">
                                        <p className="text-slate-500 text-left w-1/2">Warehouse</p>
                                        <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                            {stockDetails?.warehouse?.warehouseName || "---"}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full  font-rocGroteskMedium mb-8">
                                <p className="mb-2 text-base font-rocGroteskMedium">
                                    In quarantine
                                </p>
                                <p className="text-sm text-slate-500">
                                    {stockDetails?.quarantine ? "Yes" : "No"}
                                </p>
                            </div>

                            <div className="mb-8">
                                {(stockDetails?.productViabilityDetails?.manufacturingDate ||
                                    stockDetails?.productViabilityDetails?.bestBefore ||
                                    stockDetails?.productViabilityDetails?.expiryDate ||
                                    stockDetails?.productViabilityDetails?.removalTime) && (
                                    <p className="mb-4 text-base font-rocGroteskMedium">
                                        Product expiration date
                                    </p>
                                )}

                                <div className="">
                                    {stockDetails?.productViabilityDetails?.manufacturingDate && (
                                        <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                            <p className=" text-left w-1/2">Manufacture date</p>
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                {moment(
                                                    stockDetails?.productViabilityDetails
                                                        ?.manufacturingDate
                                                )?.fromNow()}
                                            </p>
                                        </div>
                                    )}
                                    {stockDetails?.productViabilityDetails?.bestBefore && (
                                        <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                            <p className="text-left w-1/2">Best Before Date</p>
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                {moment(
                                                    stockDetails?.productViabilityDetails
                                                        ?.bestBefore
                                                )?.fromNow()}
                                            </p>
                                        </div>
                                    )}
                                    {stockDetails?.productViabilityDetails?.expiryDate && (
                                        <div className="flex items-center justify-between p-4 border-b border-slate-100 text-sm font-rocGroteskMedium">
                                            <p className="text-left w-1/2">Expiration Time</p>
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                {moment(
                                                    stockDetails?.productViabilityDetails
                                                        ?.expiryDate
                                                )?.fromNow()}
                                            </p>
                                        </div>
                                    )}
                                    {stockDetails?.productViabilityDetails?.removalTime && (
                                        <div className="flex items-center justify-between p-4 text-sm font-rocGroteskMedium">
                                            <p className=" text-left w-1/2">Removal Time</p>
                                            <p className="text-sm font-rocGroteskMedium w-1/2 text-right">
                                                {moment(
                                                    stockDetails?.productViabilityDetails
                                                        ?.removalTime
                                                )?.fromNow()}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {stockDetails?.attachments?.length > 0 && (
                                <div className="mb-8">
                                    <p className="mb-4 text-base font-rocGroteskMedium">
                                        Attachments
                                    </p>
                                    <div className="flex flex-col gap-3">
                                        {stockDetails?.attachments?.map((attachment) => (
                                            <div
                                                key={attachment?._id}
                                                className="p-3 border border-slate-100 rounded-md flex items-center justify-between"
                                            >
                                                <div className="flex items-center gap-3">
                                                    {displayFileIcon(
                                                        getFileTypeFromUrl(attachment?.path),
                                                        false
                                                    )}
                                                    <div>
                                                        <p className="text-base font-rocGroteskMedium">
                                                            {attachment?.filename}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="w-fit">
                                                    <Link to={attachment?.path} target="_blank">
                                                        <Button
                                                            btnClassname="!bg-n-20 !py-[6px]"
                                                            btnText="View file"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {/* {stockDetails?.inventory?.additionalComment && (
                                <div className="mb-8">
                                    <p className="mb-4 text-base font-rocGroteskMedium">Notes</p>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: hasHTMLTag(
                                                stockDetails?.inventory?.additionalComment
                                            )
                                                ? sanitizeHtml(
                                                      stockDetails?.inventory?.additionalComment
                                                  ).sanitizedHTML?.join("")
                                                : stockDetails?.inventory?.additionalComment,
                                        }}
                                        className="text-sm text-slate-500 font-rocGroteskMedium"
                                    ></p>
                                </div>
                            )} */}

                            {stockDetails?.notes && (
                                <div className="w-full  font-rocGroteskMedium mb-8">
                                    <p className="mb-2 text-base font-rocGroteskMedium">Notes</p>
                                    <p className="text-sm text-slate-500">{stockDetails?.notes}</p>
                                </div>
                            )}
                            {stockDetails?.inspectionNote && (
                                <div className="w-full  font-rocGroteskMedium mb-8">
                                    <p className="mb-2 text-base font-rocGroteskMedium">
                                        {" "}
                                        Inspection Note
                                    </p>
                                    <p className="text-sm text-slate-500">
                                        {stockDetails?.inspectionNote}
                                    </p>
                                </div>
                            )}
                            {stockDetails?.conditionNote && (
                                <div className="w-full  font-rocGroteskMedium mb-8">
                                    <p className="mb-2 text-base font-rocGroteskMedium">
                                        Condition
                                    </p>
                                    <p className="text-sm text-slate-500">
                                        {stockDetails?.conditionNote}
                                    </p>
                                </div>
                            )}

                            {customArray?.length > 0 && (
                                <div className="">
                                    <p className="text-base font-rocGroteskMedium mb-4">
                                        Custom Fields
                                    </p>
                                    {customArray?.map((item, idx) => {
                                        return (
                                            <div className="relative" key={idx}>
                                                <CustomFields
                                                    displayCustomField={item}
                                                    fieldType={item?.fieldType}
                                                    required={false}
                                                    className="!mb-4"
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default StockDetails;
