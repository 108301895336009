import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { inventoryActions, marketingCampaignActions } from "redux/Ldb/actions";

type modalProps = {
    campaignModal: boolean;
    setCampaignModal: (data: boolean) => void;
};

const PlanACampaignModal = ({ campaignModal, setCampaignModal }: modalProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { aiTeammateId } = useParams();
    const [campaignData, setCampaignData] = useState<{ [key: string]: any }>({});
    const [search, setSearch] = useState<string>("");
    const [productModal, setProductModal] = useState<boolean>(false);
    const [campaignId, setCampaignId] = useState<string>("");
    const [selectedProduct, setSelectedProduct] = useState<{ [key: string]: any }[]>([]);
    const [addedSelectedProduct, setAddedSelectedProduct] = useState<{ [key: string]: any }[]>([]);
    const [productData, setProductData] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [prodPagination, setProdPagination] = useState<{
        currentPage: string;
        noOfPages: string;
    }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [updatedPage, setUpdatedPage] = useState<number>(1);
    const [loadMore, setLoadMore] = useState<boolean>(false);

    const { planningCampaign, planCampaignSuccess } = useAppSelector(
        (state) => state.marketingCampaign
    );

    const { fetchProductSuccess } = useAppSelector((state) => state.inventory);

    const onPageScroll = useCallback(() => {
        const modalContent = document.getElementById("modalContent");
        console.log(modalContent);
        if (!modalContent) return;
        const { scrollTop, scrollHeight, clientHeight } = modalContent;
        const hasReachedBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        if (
            hasReachedBottom &&
            !loadMore &&
            Number(prodPagination?.currentPage) < Number(prodPagination?.noOfPages)
        ) {
            setUpdatedPage((prev) => prev + 1);
            setLoadMore(true);
        }
    }, [prodPagination, loadMore, productModal]);

    useEffect(() => {
        const modalContent = document.getElementById("modalContent");
        console.log(modalContent);

        if (!modalContent) return;
        modalContent.addEventListener("scroll", onPageScroll);
        return () => {
            modalContent.removeEventListener("scroll", onPageScroll);
        };
    }, [onPageScroll, productModal]);

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(inventoryActions.fetchProduct(5, updatedPage, debouncedSearch));
    }, [debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchProductSuccess)) {
            setProductData((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchProductSuccess.inventory?.data], "_id")
                    : uniqBy([...prev, ...fetchProductSuccess.inventory?.data], "_id")
            );
            setProdPagination({
                currentPage: fetchProductSuccess.pagination?.current,
                noOfPages: fetchProductSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchProduct());
        }
    }, [fetchProductSuccess, updatedPage]);

    const handleCamapignData = (e) => {
        const { name, value } = e?.target;
        setCampaignData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDataSubmission = (e) => {
        e.preventDefault();

        const body = {
            campaignName: campaignData?.campaignName,
            channel: campaignData?.channel,
            startDate: campaignData?.startDate,
            endDate: campaignData?.endDate,
            ...(campaignData?.description && { description: campaignData?.description }),
            ...(addedSelectedProduct?.length > 0 && {
                products: addedSelectedProduct?.map((product) => {
                    return { productId: product?._id };
                }),
            }),
        };

        dispatch(marketingCampaignActions.planCampaign(body));
    };

    useEffect(() => {
        if (Boolean(planCampaignSuccess)) {
            dispatch(marketingCampaignActions?.resetPlanCampaign());
            navigate(`/dashboard/campaign/plan-campaign/${planCampaignSuccess?.campaign?._id}`, {
                state: { aiTeammateId },
            });
            setCampaignId(planCampaignSuccess?.data?._id);
        }
    }, [planCampaignSuccess]);

    const handleSelectAllProducts = () => {
        if (productData?.length === selectedProduct?.length) {
            setSelectedProduct([]);
        } else {
            setSelectedProduct(productData);
        }
    };

    const handleSelectProduct = (item) => {
        const checkSelected = selectedProduct?.find((prod) => prod?._id === item?._id);
        if (checkSelected) {
            const copyArr = [...selectedProduct];
            const filter = copyArr?.filter((data) => data?._id !== item?._id);
            setSelectedProduct(filter);
        } else {
            setSelectedProduct((prev) => {
                const copyArr = [...prev];
                copyArr?.push(item);
                return copyArr;
            });
        }
    };

    const handleAddButton = () => {
        setAddedSelectedProduct(selectedProduct);
        setProductModal(false);
    };

    const resetForm = () => {
        setSelectedProduct([]);
        setAddedSelectedProduct([]);
        setCampaignData({});
    };

    const tableModalHeader = [
        {
            title: "",
            widthClass: "w-[10%] !font-rocGroteskMedium  !border-r !border-slate-200",
            itemClass: "!py-4 !pl-6",
            onSelectAll: () => {
                handleSelectAllProducts();
            },
            isAllChecked: selectedProduct?.length === productData?.length,
        },
        {
            title: "Product name",
            widthClass: "!w-[90%] !font-rocGroteskMedium  !border-slate-200",
        },
    ];
    const tableModalBody = productData?.map((item, idx) => {
        const isSelected = selectedProduct?.find((prod) => prod?._id === item?._id);
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 !-pl-[0px] border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                    ></div>
                ),
                widthClass: "!border-b !border-r !border-slate-200",
                isItemChecked: isSelected ? true : false,
                onCheckBoxClick: () => {
                    handleSelectProduct(item);
                },
                cellClickAction: () => {
                    handleSelectProduct(item);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center justify-between h-full
                         border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3 !w-full`}
                    >
                        <div className="flex items-center gap-2">
                            {item?.productImageDetails?.productAvatar ? (
                                <div className="w-8 h-8 rounded">
                                    <img
                                        src={item?.productImageDetails?.productAvatar}
                                        alt=""
                                        className="w-8 h-8 rounded"
                                    />
                                </div>
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="">
                                <p className="text-gm-50"> {item?.productName}</p>
                                <p className="text-slate-500"> SKU:{item?.sku}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="w-auto bg-slate-100 rounded px-2 py-1 flex gap-1 text-gm-50 text-xs">
                                <p className="">Available qty:</p>
                                <span className="font-rocGroteskBold">
                                    {item?.productStockDetails?.stockLevel &&
                                        item?.productStockDetails?.stockLevel?.toLocaleString()}
                                    {` `}
                                    {item?.productStockDetails?.unitOfMeasurement &&
                                        item?.productStockDetails?.unitOfMeasurement}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
        ];
    });

    return (
        <div>
            <ModalContainer
                open={campaignModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[774px] max-h-[80vh] rounded-[8px]"
                showCloseIcon={false}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        handleDataSubmission(e);
                    }}
                >
                    <div className="">
                        <div className="sticky top-0 bg-white rounded-t-lg z-[1200] px-4 font-rocGroteskMedium border-b border-b-slate-100 flex items-center justify-start py-3 gap-2">
                            <i
                                className={` ri-close-fill text-lg text-[#142837] cursor-pointer`}
                                onClick={() => {
                                    !planningCampaign && setCampaignModal(false);
                                }}
                            ></i>
                            <p className="mt-1 text-lg text-gm-50 ">Plan a campaign</p>
                        </div>

                        <div className=" pb-8 space-y-3 pt-3 px-4">
                            <TextInput
                                value={campaignData.campaignName || ""}
                                name={"campaignName"}
                                type={"text"}
                                onChange={(e) => handleCamapignData(e)}
                                placeholder={"Enter campaign name"}
                                required={true}
                                containerClassname=""
                                inputContainerClassname="rounded-[8px]"
                            />

                            <div className="grid grid-cols-3 gap-4">
                                <SelectInput
                                    value={campaignData?.channel || ""}
                                    name="channel"
                                    placeholder={"Select channel"}
                                    handleChange={(name, value) => {
                                        handleCamapignData({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Tiktok",
                                            value: "tiktok",
                                        },
                                        {
                                            label: "Klaviyo",
                                            value: "klaviyo",
                                        },
                                        {
                                            label: "Google",
                                            value: "google",
                                        },
                                    ]}
                                    className="rounded-[8px]"
                                />
                                <TextInput
                                    value={campaignData?.startDate || ""}
                                    type={"date"}
                                    placeholder={"Start date"}
                                    name="startDate"
                                    required={true}
                                    onChange={(e) => {
                                        handleCamapignData(e);
                                    }}
                                    inputContainerClassname="cursor-pointer rounded-[8px]"
                                    min={moment().format("YYYY-MM-DD")}
                                />

                                <TextInput
                                    value={campaignData?.endDate || ""}
                                    type={"date"}
                                    placeholder={"End date"}
                                    name="endDate"
                                    required={true}
                                    onChange={(e) => {
                                        handleCamapignData(e);
                                    }}
                                    min={campaignData?.startDate}
                                    inputContainerClassname="cursor-pointer rounded-[8px]"
                                />
                            </div>

                            <TextareaInput
                                name={"description"}
                                value={campaignData?.description || ""}
                                onChange={(e) => handleCamapignData(e)}
                                rows={5}
                                // inputPlaceholder={"Describe your campaign"}
                                labelClassName="text-slate-500 text-xs mb-1"
                                // placeholder={"Describe your campaign"}
                                label="Describe your campaign"
                                inputContainerClassname="rounded-[8px]"
                                inputClassName="!pt-0"
                                tooltipText={`Summarize your campaign plan: objective, audience, and optional product sales forecasts.
                                    Ex: "Increase Q4 brand awareness for eco-products among conscious shoppers. Target sales: 1000 'Bamboo Toothbrush' units, 500 'Reusable Bag' sets.`}
                            />

                            <div className="flex items-center gap-3 py-1">
                                <div className="h-[1px] w-full bg-slate-100"></div>
                                <p className="text-[#64748B] text-[14px] w-[280px]">
                                    Select products
                                </p>
                                <div className="h-[1px] w-full bg-slate-100"></div>
                            </div>

                            {addedSelectedProduct?.length < 1 ? (
                                <div className=" rounded-md border-slate-200 border-dashed border-[1.5px] mb-20">
                                    <div className="p-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                                        <img
                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/box-3-line_ybcpgn.svg"
                                            alt=""
                                            className="w-10 h-10 mb-2"
                                        />
                                        <p className="text-[#142837] mb-1 text-sm text-center ">
                                            Add the products for the campaign
                                        </p>
                                        <p className="text-[#6B778C] text-sm mb-[19px] text-center">
                                            Select and add the products you want to run a campaign
                                            for
                                        </p>
                                        <div>
                                            <Button
                                                btnText="Select products"
                                                btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto"
                                                onClick={() => setProductModal(true)}
                                                icon=<i className="ri-add-fill text-base text-gm-50"></i>
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="">
                                    {addedSelectedProduct?.map((product) => {
                                        return (
                                            <div className="rounded-lg border border-slate-200 flex justify-between items-center my-2 py-3 px-6 ">
                                                <div className="flex items-center gap-2">
                                                    {product?.productImageDetails?.productAvatar ? (
                                                        <div className="w-8 h-8">
                                                            <img
                                                                src={
                                                                    product?.productImageDetails
                                                                        ?.productAvatar
                                                                }
                                                                alt=""
                                                                className="w-8 h-8 rounded"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                            <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                        </div>
                                                    )}

                                                    <div className="">
                                                        <p className="text-gm-50 text-sm">
                                                            {" "}
                                                            {product?.productName}
                                                        </p>
                                                        <p className="text-slate-500 text-sm">
                                                            {" "}
                                                            SKU:{product?.sku}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="w-auto bg-slate-100 rounded px-2 py-1 flex gap-1 text-gm-50 text-xs">
                                                    <p className="">Available qty:</p>
                                                    <span className="font-rocGroteskBold">
                                                        {product?.productStockDetails?.stockLevel &&
                                                            product?.productStockDetails?.stockLevel?.toLocaleString()}
                                                        {` `}
                                                        {product?.productStockDetails
                                                            ?.unitOfMeasurement &&
                                                            product?.productStockDetails
                                                                ?.unitOfMeasurement}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <Button
                                        btnText="Add more products"
                                        btnClassname="!bg-slate-50 !py-3 !pl-4 border border-slate-200 !text-gm-50 
                                !text-[13px] !w-auto !mt-2"
                                        onClick={() => setProductModal(true)}
                                        icon=<i className="ri-add-fill text-base text-gm-50"></i>
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={` py-4 px-4 flex items-center w-full sticky bottom-0 bg-white justify-end space-x-4 gap-2 z-[1300]`}
                        >
                            <Button
                                btnText={"Reset"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={resetForm}
                                disabled={planningCampaign}
                            />
                            <Button
                                type="submit"
                                btnText={"View campaign results"}
                                isLoading={planningCampaign}
                                disabled={planningCampaign}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>
            {/* max-h-[80vh] */}
            <ModalContainer
                open={productModal}
                // showCloseIcon={false}
                // tailwindClassName="w-[90%] lg:w-[1000px]"
                tailwindClassName="w-[774px] rounded-[8px]"
            >
                <div className="bg-white max-h-[90vh] rounded-lg overflow-y-scroll">
                    <div className="sticky top-0 bg-white rounded-t-lg z-[1200] px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">Add Products</p>
                        <i
                            onClick={() => {
                                setProductModal(false);
                                setSelectedProduct(addedSelectedProduct);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 mx-8  ">
                        <div className="sticky top-[70px] bg-white !z-[1200]">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onInput={debounce((e) => {
                                    setDebouncedSearch(e?.target?.value);
                                }, 800)}
                                inputPlaceholder="Search"
                                inputClassName={
                                    "!h-[50px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 !border-b-[0.5px]"
                                }
                            />
                        </div>
                        {productData?.length > 0 ? (
                            <div className="overflow-y-auto max-h-[300px]" id="modalContent">
                                <CustomTable
                                    tableBody={tableModalBody}
                                    tableHeader={tableModalHeader}
                                    isInifiniteScroll={true}
                                    isAllSelectable={true}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    isScrollable={true}
                                    isCellBordered={false}
                                    tableContainerClass=""
                                    expandedRowTClass="!sticky !top-[-3px]"
                                />
                                {loadMore && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {prodPagination?.currentPage === prodPagination?.noOfPages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="flex my-10 text-slate-500  justify-center text-sm font-rocGroteskMedium h-40">
                                No product
                            </div>
                        )}
                    </div>
                    <div className="sticky bottom-0 bg-white px-8 py-4 flex gap-4 items-center justify-end border-t border-t-slate-200">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !w-fit"
                            onClick={() => {
                                setProductModal(false);
                                setSelectedProduct(addedSelectedProduct);
                            }}
                        />
                        <Button
                            btnText="Add Products"
                            btnClassname="!px-6 !py-3 !w-fit"
                            onClick={() => {
                                handleAddButton();
                            }}
                        />
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default PlanACampaignModal;
