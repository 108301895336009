import React from "react";

export const sanitizeTaggedUserStringForDisplay = (text: string) => {
    // Regex to match @[email]
    const pattern = /@\[[^\]]+\]/g;

    const formattedText = text.split(pattern).reduce((acc, part, index, array) => {
        acc.push(part);
        if (index < array.length - 1) {
            const match = text.match(pattern)[index].replace(/[\[\]]/g, "");
            acc.push(
                <span key={index} className="text-p-55 font-rocGroteskBold">
                    {match}
                </span>
            );
        }
        return acc;
    }, []);

    return formattedText;
};

export const sanitizeTaggedUserStringForSubmission = (text: string) => {
    // Regex to match @[email](id)
    const pattern = /\@\[[^\]]+\]\(([^)]+)\)/g;

    // Extract all IDs and remove them from the text
    const extractedIds = [];
    const modifiedText = text.replace(pattern, (match, id) => {
        extractedIds.push(id);
        return match.replace(/\([^)]+\)/, ""); // Remove only the (ID) part
    });

    return {
        taggedIds: extractedIds,
        submissionString: modifiedText.trim(),
    };
};
