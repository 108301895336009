import { getFromStorage } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetUserProfile from "hooks/useGetUserProfile";
import { AccountPermissions } from "types/permissions";
import { useEffect, useState } from "react";

const useGetNavLinks = () => {
    const [allLinks, setAllLinks] = useState([]);
    const [profile] = useGetUserProfile();
    // const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const userProfile = getFromStorage("ally-user");
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    const singleBrandLinks = [
        // {
        //     title: "Dashboard",
        //     link: "/dashboard/home",
        //     childrenLinks: [""],
        //     iconClass: "",
        //     permissions: [AccountPermissions.all_access, AccountPermissions.view_dashboard],
        // },
        {
            title: "Sales",
            link: "/dashboard/order",
            childrenLinks: [""],
            iconClass: "ri-file-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            title: "Forecast",
            link: "/dashboard/forecast",
            childrenLinks: [""],
            iconClass: "ri-file-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_dashboard,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        // {
        //     title: "Dashboard",
        //     link: "/dashboard/home",
        //     childrenLinks: [""],
        //     iconClass: "",
        //     permissions: [AccountPermissions.all_access, AccountPermissions.view_dashboard],
        // },
        {
            title: "Inventory",
            link: "/dashboard/inventory",
            childrenLinks: [""],
            iconClass: "ri-shopping-basket-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            title: "Purchase",
            link: "/dashboard/purchase",
            childrenLinks: [""],
            iconClass: "ri-file-list-3-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            title: "Suppliers",
            link: "/dashboard/suppliers",
            childrenLinks: [""],
            iconClass: "ri-building-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            title: "Production",
            link: "/dashboard/production",
            childrenLinks: [""],
            iconClass: "",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production,
                AccountPermissions.view_production_report,
            ],
        },
        {
            title: "WorkforceIQ",
            link: "/dashboard/workforce",
            childrenLinks: [""],
            iconClass: "ri-building-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_ai_teammate,
                AccountPermissions.view_ai_task,
            ],
        },
        {
            title: "Tasks",
            link: "/dashboard/task",
            childrenLinks: [""],
            iconClass: "ri-building-line",
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
    ]?.filter((item) => {
        if (
            fetchUserProfileSuccess?.role === "owner" ||
            userProfile?.user?.role === "owner" ||
            userProfile?.user?.parent?.role === "owner"
        ) {
            return true;
        } else {
            return !item?.title?.toLowerCase()?.includes("workforce");
        }
    });

    const multiBrandLinks = [
        {
            title: "Dashboard",
            link: "/dashboard/portfolio/home",
            childrenLinks: [""],
            iconClass: "",
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            title: "Inventory",
            link: "/dashboard/portfolio/inventory",
            childrenLinks: [""],
            iconClass: "ri-shopping-basket-line",
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            title: "Portfolio Management",
            link: "/dashboard/portfolio/management",
            childrenLinks: [""],
            iconClass: "ri-file-list-3-line",
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            title: "Team Management",
            link: "/dashboard/portfolio/team",
            childrenLinks: [""],
            iconClass: "ri-building-line",
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
    ];

    const allowedLinks = allLinks?.filter((link) => {
        const allowedRoutes =
            link?.permissions?.length === 0 ||
            link?.permissions?.some((permission) =>
                userProfile?.user?.permission?.includes(permission)
            );

        return allowedRoutes;
    });

    useEffect(() => {
        setAllLinks(
            fetchUserProfileSuccess?.isMultiBrandAccount ||
                fetchUserProfileSuccess?.parent?.isMultiBrandAccount
                ? multiBrandLinks
                : singleBrandLinks
        );
    }, [fetchUserProfileSuccess?._id]);

    return { allowedLinks };
};

export default useGetNavLinks;
