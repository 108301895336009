import { PDFDownloadLink } from "@react-pdf/renderer";
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PurchaseOrdersPDF from "components/views/Ldb/Orders/PDFPurchase";
import {
    displayFileIcon,
    formatMoney,
    getCurrencyFromCurrencyCode,
    getFromStorage,
    hasHTMLTag,
} from "helpers";
import { downloadUrl, fbDownload } from "helpers/fileDownload";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { AccountPermissions } from "types/permissions";

interface Props {
    showTabs?: boolean;
    setActiveTabTitle?: any;
    activeTabTitle?: number;
}
const PurchaseDetails = ({ showTabs, setActiveTabTitle, activeTabTitle }: Props) => {
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/purchase" });
    const [purchaseOrderList, setPurchaseOrderList] = useState<{ [key: string]: any }>({});
    const [subTotals, setSubTotals] = useState<number>(0);
    const [taxTotals, setTaxTotals] = useState<number>(0);
    const [shouldApprove, setShouldApprove] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { purchaseId } = useParams();
    const [container, setContainer] = useState([]);
    const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
    const [openApprovalSuccessModal, setOpenApprovalSuccessModal] = useState<boolean>(false);
    const [openRejectModal, setOpentRejectModal] = useState<boolean>(false);
    const [openRejectionReasonModal, setOpentRejectionReasonModal] = useState<boolean>(false);
    const [rejectionReason, setRejectionReason] = useState<string>("");
    const [userProfile, setUserProfile] = useState<{ [key: string]: any }>(() =>
        getFromStorage("ally-user")
    );
    const tabTitle = ["Purchase order", "Shipment details"];
    const [customerDetails, setCustomerDetails] = useState<{ [key: string]: any }>({
        issuedDate: "",
        dueDate: "",
        shippingTerms: "",
        fobPoint: "",
        shippingVia: "",
        source: "",
        shippingAndHandling: "",
        currency: "",
        termsAndCondition: "",
        additionalInfo: "",
        tax: "",
        incoterm: "",
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        fetchingSinglePurchaseOrder,
        fetchedSinglePurchaseOrderSuccess,
        loadingPOApprovalOrRejection,
        approveOrRejectPOSuccess,
    } = useAppSelector((state) => state.order);

    useEffect(() => {
        if (showTabs) {
            setActiveTabTitle(0);
        }
    }, [showTabs]);

    useEffect(() => {
        setCustomerDetails({
            issuedDate: purchaseOrderList?.issuedDate,
            dueDate: purchaseOrderList?.dueDate,
            shippingTerms: purchaseOrderList?.shippingTerms,
            fobPoint: purchaseOrderList?.fobPoint,
            shippingVia: purchaseOrderList?.shippingMethod,
            source: purchaseOrderList?.source,
            currency: purchaseOrderList?.currency,
            tax: purchaseOrderList?.tax?.percentage,
            shippingAndHandling: purchaseOrderList?.shippingAndHandling?.amount,
            termsAndCondition: purchaseOrderList?.termsAndCondition,
            paymentTerms: purchaseOrderList?.paymentTerms,
            incoterm: purchaseOrderList?.incoterm,
        });
    }, [purchaseOrderList]);

    useEffect(() => {
        const userIndex = purchaseOrderList?.approvals?.findIndex(
            (item) => item.person._id === userProfile?.user?._id
        );
        if (
            userIndex !== -1 &&
            purchaseOrderList?.approvals?.[userIndex - 1]?.status?.toLowerCase() !== "rejected" &&
            purchaseOrderList?.approvals?.[userIndex]?.status?.toLowerCase() === "pending"
        ) {
            setShouldApprove(true);
        } else {
            setShouldApprove(false);
        }
    }, [userProfile, purchaseOrderList?.approvals]);

    const handleApproveRejectPO = (isApprove: boolean) => {
        const specificTask = purchaseOrderList?.tasks?.find(
            (task) => task?.assignee?._id === userProfile?.user?._id
        );
        const body: { [key: string]: any } = {
            approved: isApprove,
            taskId: specificTask?._id,
            poId: purchaseId,
        };

        if (!isApprove) {
            body.rejectReason = rejectionReason;
        }

        dispatch(orderActions.approveRejectPO(body));
    };

    useEffect(() => {
        if (Boolean(approveOrRejectPOSuccess)) {
            const isApprovedAction = approveOrRejectPOSuccess?.po?.approvedById?.includes(
                userProfile?.user?._id
            );

            const isRejectionAction = approveOrRejectPOSuccess?.po?.rejectedById?.includes(
                userProfile?.user?._id
            );

            if (isApprovedAction) {
                setOpenApproveModal(false);
                setOpenApprovalSuccessModal(true);
            }

            if (isRejectionAction) {
                setOpentRejectModal(false);
                setOpentRejectionReasonModal(false);
                toast.custom((t) => (
                    <CustomToast
                        t={t}
                        message={"Purchase order rejected successfully"}
                        type={"error"}
                    />
                ));
            }
            setRejectionReason("");
            dispatch(orderActions.resetApproveRejectPOSuccess());
            dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
        }
    }, [approveOrRejectPOSuccess, dispatch, userProfile?.user?._id]);

    useEffect(() => {
        dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
    }, [dispatch, purchaseId]);

    useEffect(() => {
        if (Boolean(fetchedSinglePurchaseOrderSuccess)) {
            setLoading(false);
            setPurchaseOrderList(fetchedSinglePurchaseOrderSuccess?.purchaseOrder);
        }
    }, [fetchedSinglePurchaseOrderSuccess]);

    const quantities = purchaseOrderList?.itemsOrServices?.map((item) => Number(item.qty));
    const totalQuantity = quantities?.reduce((acc, curr) => acc + curr, 0);

    useEffect(() => {
        let totalSubtotals = 0;
        if (purchaseOrderList?.itemsOrServices?.length > 0) {
            for (const container of purchaseOrderList?.itemsOrServices) {
                const subtotal = parseFloat(container.subtotal.amount);
                totalSubtotals += subtotal;
            }
            const taxPercentage = parseFloat(purchaseOrderList?.tax?.percentage);
            const taxAmount = (taxPercentage / 100) * totalSubtotals;

            setSubTotals(totalSubtotals);
            setTaxTotals(taxAmount);
        }
        setContainer(
            purchaseOrderList?.itemsOrServices?.map((data, idx) => ({
                productImageDetails: {
                    avatar: data?.inventory?.productImageDetails?.productAvatar,
                },
                productName: data?.inventory?.productName,
                sku: data?.inventory?.sku,
                description: data?.inventory?.productDescription,
                unitCost: {
                    amount: data?.price?.amount,
                    currency: data?.price?.currency,
                },
                subtotal: {
                    amount: data?.subtotal?.amount,
                },
                quantity: data?.qty,
                deliveryDate: data?.deliveryDate,
                leadTime: data?.leadTime,
            }))
        );
    }, [purchaseOrderList]);

    const tableHeader = [
        { title: "No", widthClass: "w-[6%]" },
        { title: "Product name", widthClass: "w-[23%]" },
        { title: "Description", widthClass: "w-[23%]" },
        { title: "Qty", widthClass: "w-[11%]" },
        { title: "Unit cost", widthClass: "w-[11.5%]" },
        { title: "Sub total", widthClass: "w-[16.6%]" },
        { title: "Delivery Date", widthClass: "w-[12%]" },
        { title: "Lead time", widthClass: "w-[12%]" },
    ];

    const tableBody = purchaseOrderList?.itemsOrServices?.map((item: any, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium relative`}
                    >
                        <p>{idx + 1}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div key={idx} className={`py-2 pr-6 w-full border-slate-100`}>
                        <div className="font-rocGroteskMedium items-center flex space-x-2">
                            <VendorAvatar
                                imageSrc={item?.inventory?.prodcutImageDetails?.avatar}
                                name={item?.inventory?.productName}
                                size={32}
                                containerClassname="!rounded-[4px]"
                            />

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">
                                    {truncate(item?.inventory?.productName, { length: 20 })}
                                </p>
                                <p className="text-slate-500 break-all">{item?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                widthClass: " ",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc `}
                    >
                        <p className="whitespace-normal">{item?.description || "N/A"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc `}
                    >
                        <p className="whitespace-normal">{formatMoney().format(item?.qty)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.price?.currency)}
                            {formatMoney().format(item?.price?.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.subtotal?.currency)}
                            {formatMoney().format(
                                (item?.price?.amount * Number(item?.qty))?.toFixed(2)
                            )}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>{moment(item.deliveryDate).format("DD-MMM-YYYY")}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>{item?.leadTime || "0"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    const subtotalNumber = Number(subTotals) || 0;
    const taxTotalNumber = Number(taxTotals) || 0;
    const shippingAndHandlingNumber = Number(purchaseOrderList?.shippingAndHandling?.amount) || 0;

    return (
        <div className="bg-white">
            {loading ? (
                <div className=" my-[20%]">
                    <Loader />
                </div>
            ) : (
                <>
                    {Object.keys(purchaseOrderList).length > 0 ? (
                        <div className="pb-4 lg:border border-slate-100 rounded-[8px] ">
                            <div
                                className={`pt-4 pb-3 lg:block hidden border-b rounded  ${
                                    purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                        "approved" &&
                                    purchaseOrderList?.approvalStatus?.toLowerCase() !== "rejected"
                                        ? "-top-[17px] sticky !z-20000"
                                        : ""
                                } bg-white `}
                            >
                                <div className=" px-4 pt-3 pb-4 border-b rounded border-slate-100">
                                    <div
                                        className="flex items-center space-x-2 w-fit cursor-pointer"
                                        onClick={() => goBack()}
                                    >
                                        <i className="ri-arrow-left-line text-[20px]"></i>
                                        <p className="text-sm font-rocGroteskMedium underline">
                                            Back
                                        </p>
                                    </div>
                                </div>
                                <div className="px-4 mt-3">
                                    <PageTitleSection
                                        leftComp={
                                            <BreadCrumb
                                                parentCrumb={"Purchase"}
                                                parentUrl={"/dashboard/purchase?curTab=1"}
                                                childrenCrumb={[
                                                    {
                                                        title: "Purchase order details",
                                                        path: `/dashboard/purchase/${purchaseId}`,
                                                    },
                                                ]}
                                                size="large"
                                            />
                                        }
                                        rightComp={
                                            <div className="flex items-center space-x-[14px]">
                                                {purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                    "approved" &&
                                                    purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                        "rejected" && (
                                                        <Button
                                                            btnText={"Edit Purchase order"}
                                                            btnPermission={[
                                                                AccountPermissions.create_purchase_order,
                                                                AccountPermissions.all_access,
                                                            ]}
                                                            type={"button"}
                                                            isLoading={false}
                                                            btnClassname="!py-2 !whitespace-nowrap !text-[14px] !px-4"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/dashboard/purchase/edit-purchase-request/${purchaseId}`,
                                                                    {
                                                                        state: {
                                                                            internalNavigation:
                                                                                true,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {purchaseOrderList?.documents?.length > 0 ? (
                                                    <div className="">
                                                        <Button
                                                            btnText={"Download"}
                                                            type={"button"}
                                                            isLoading={false}
                                                            btnClassname="!py-2  !bg-transparent !border !border-[#94A3B8] !text-[14px] !px-4"
                                                            onClick={() => {
                                                                return purchaseOrderList?.documents?.[0]?.path?.includes(
                                                                    "firebase"
                                                                )
                                                                    ? fbDownload(
                                                                          purchaseOrderList
                                                                              ?.documents?.[0]
                                                                              ?.path,
                                                                          purchaseOrderList
                                                                              ?.documents?.[0]
                                                                              ?.filename
                                                                      )
                                                                    : downloadUrl(
                                                                          purchaseOrderList
                                                                              ?.documents?.[0]
                                                                              ?.path,
                                                                          purchaseOrderList
                                                                              ?.documents?.[0]
                                                                              ?.filename
                                                                      );
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <PDFDownloadLink
                                                        document={
                                                            <PurchaseOrdersPDF
                                                                imageDetails={
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.avatar
                                                                }
                                                                customerDetails={customerDetails}
                                                                ldbName={
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.businessName
                                                                }
                                                                ldbEmail={
                                                                    purchaseOrderList?.ldb?.email
                                                                }
                                                                ldbPhone={
                                                                    purchaseOrderList?.ldb?.mobile
                                                                }
                                                                ldbAddress={
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.address?.address
                                                                }
                                                                vendorName={
                                                                    purchaseOrderList?.vendor
                                                                        ?.profile?.businessName
                                                                }
                                                                vendorEmail={
                                                                    purchaseOrderList?.vendor?.email
                                                                }
                                                                vendorAddress={
                                                                    purchaseOrderList?.vendor
                                                                        ?.profile?.address?.address
                                                                }
                                                                vendorPhone={
                                                                    purchaseOrderList?.vendor
                                                                        ?.mobile
                                                                }
                                                                totalQuantity={totalQuantity}
                                                                totalAmount={subTotals}
                                                                filesAttached={
                                                                    purchaseOrderList?.attachments
                                                                }
                                                                taxAmount={
                                                                    purchaseOrderList?.tax?.amount
                                                                }
                                                                containers={container}
                                                            />
                                                        }
                                                        fileName="purchase-order.pdf"
                                                    >
                                                        {({ loading }) => {
                                                            return loading ? (
                                                                <Button
                                                                    btnText={"Download"}
                                                                    type={"button"}
                                                                    isLoading={loading}
                                                                    disabled={loading}
                                                                    btnClassname="!py-2  !bg-transparent !border !border-[#94A3B8] !text-[14px] !px-4"
                                                                />
                                                            ) : (
                                                                <Button
                                                                    btnText={"Download"}
                                                                    type={"button"}
                                                                    isLoading={false}
                                                                    btnClassname="!py-2  !bg-transparent !border !border-[#94A3B8] !text-[14px] !px-4"
                                                                />
                                                            );
                                                        }}
                                                    </PDFDownloadLink>
                                                )}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            {showTabs && (
                                <div className="md:mb-0 md:mt-5 mb-2 px-4 lg:px-6">
                                    <TabsContainer
                                        tabs={tabTitle}
                                        activeTab={activeTabTitle}
                                        setActiveTab={setActiveTabTitle}
                                        className="!px-0"
                                        itemClassName="!pb-2"
                                        borderLineClase={
                                            "!text-[#F1F5F9] md:w-[33%] lg:w-[25%]   !h-fit  mb-4"
                                        }
                                    />
                                </div>
                            )}
                            <div className="px-2">
                                <div className="w-full flex items-end justify-end">
                                    <div className="max-lg:flex hidden  w-fit">
                                        {purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                            "approved" &&
                                            purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                "rejected" && (
                                                <Button
                                                    btnText={""}
                                                    type={"button"}
                                                    btnPermission={[
                                                        AccountPermissions.create_purchase_order,
                                                        AccountPermissions.all_access,
                                                    ]}
                                                    icon={
                                                        <div className="bg-edit-light-green flex justify-center items-center rounded-full shrink-0 h-[36px] w-[36px]">
                                                            <i className="ri-edit-box-line text-[#16A34A] text-[16px]"></i>
                                                        </div>
                                                    }
                                                    isLoading={false}
                                                    btnClassname="!py-2 !w-fit !bg-transparent !whitespace-nowrap !text-[14px] !px-4"
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/purchase/edit-purchase-request/${purchaseId}`,
                                                            { state: { internalNavigation: true } }
                                                        )
                                                    }
                                                />
                                            )}
                                        {purchaseOrderList?.documents?.length > 0 ? (
                                            <div className="">
                                                <Button
                                                    btnText={""}
                                                    type={"button"}
                                                    btnPermission={[
                                                        AccountPermissions.create_purchase_order,
                                                        AccountPermissions.all_access,
                                                    ]}
                                                    icon={
                                                        <div className="bg-download-light-grey flex justify-center items-center rounded-full shrink-0 h-[36px] w-[36px]">
                                                            <i className="ri-download-line text-[#142837] text-[16px]"></i>
                                                        </div>
                                                    }
                                                    isLoading={false}
                                                    btnClassname="!py-2 !w-fit !bg-transparent !whitespace-nowrap !text-[14px] !px-4"
                                                    onClick={() => {
                                                        return purchaseOrderList?.documents?.[0]?.path?.includes(
                                                            "firebase"
                                                        )
                                                            ? fbDownload(
                                                                  purchaseOrderList?.documents?.[0]
                                                                      ?.path,
                                                                  purchaseOrderList?.documents?.[0]
                                                                      ?.filename
                                                              )
                                                            : downloadUrl(
                                                                  purchaseOrderList?.documents?.[0]
                                                                      ?.path,
                                                                  purchaseOrderList?.documents?.[0]
                                                                      ?.filename
                                                              );
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <PDFDownloadLink
                                                document={
                                                    <PurchaseOrdersPDF
                                                        imageDetails={
                                                            purchaseOrderList?.ldb?.profile?.avatar
                                                        }
                                                        customerDetails={customerDetails}
                                                        ldbName={
                                                            purchaseOrderList?.ldb?.profile
                                                                ?.businessName
                                                        }
                                                        ldbEmail={purchaseOrderList?.ldb?.email}
                                                        ldbPhone={purchaseOrderList?.ldb?.mobile}
                                                        ldbAddress={
                                                            purchaseOrderList?.ldb?.profile?.address
                                                                ?.address
                                                        }
                                                        vendorName={
                                                            purchaseOrderList?.vendor?.profile
                                                                ?.businessName
                                                        }
                                                        vendorEmail={
                                                            purchaseOrderList?.vendor?.email
                                                        }
                                                        vendorAddress={
                                                            purchaseOrderList?.vendor?.profile
                                                                ?.address?.address
                                                        }
                                                        vendorPhone={
                                                            purchaseOrderList?.vendor?.mobile
                                                        }
                                                        totalQuantity={totalQuantity}
                                                        totalAmount={subTotals}
                                                        filesAttached={
                                                            purchaseOrderList?.attachments
                                                        }
                                                        taxAmount={purchaseOrderList?.tax?.amount}
                                                        containers={container}
                                                    />
                                                }
                                                fileName="purchase-order.pdf"
                                            >
                                                {({ loading }) => {
                                                    return loading ? (
                                                        <Button
                                                            btnText={""}
                                                            icon={
                                                                <div className="bg-download-light-grey flex justify-center items-center rounded-full shrink-0 h-[36px] w-[36px]">
                                                                    <i className="ri-download-line text-[#142837] text-[16px]"></i>
                                                                </div>
                                                            }
                                                            type={"button"}
                                                            isLoading={loading}
                                                            disabled={loading}
                                                            btnClassname="!py-2 !w-fit !bg-transparent !whitespace-nowrap !text-[14px] !px-4"
                                                        />
                                                    ) : (
                                                        <Button
                                                            btnText={""}
                                                            icon={
                                                                <div className="bg-download-light-grey flex justify-center items-center rounded-full shrink-0 h-[36px] w-[36px]">
                                                                    <i className="ri-download-line text-[#142837] text-[16px]"></i>
                                                                </div>
                                                            }
                                                            type={"button"}
                                                            isLoading={false}
                                                            btnClassname="!py-2 !w-fit !bg-transparent !whitespace-nowrap !text-[14px] !px-4"
                                                        />
                                                    );
                                                }}
                                            </PDFDownloadLink>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="flex pb-32 lg:flex-row flex-col w-full ">
                                        <div className="lg:border-r border-[#E2E8F0] lg:w-[75%]  ">
                                            <div className="lg:px-6 px-2 pt-2 md:pt-8">
                                                <div className="flex items-center justify-between">
                                                    <p className="mb-4 text-base md:text-[22px] font-rocGroteskMedium text-gm-50">
                                                        {purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                        "pending"
                                                            ? "PO"
                                                            : "PR"}{" "}
                                                        {purchaseOrderList?.taId}
                                                    </p>
                                                    <div
                                                        className={` lg:hidden flex space-x-1 items-center  ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "approved" && "bg-[#F0FDF4]"
                                                        }  ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                "draft" &&
                                                            "bg-[#F9DFB3]"
                                                        } 
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "rejected" && "bg-light-red-status"
                                                        } ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                "draft" &&
                                                            "bg-[#F1F5F9]"
                                                        }
                                                        px-2.5 py-2 rounded-[4px] cursor-pointer $`}
                                                    >
                                                        <div
                                                            className={`h-2 w-2 rounded ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                    "draft" &&
                                                                "bg-[#64748B]"
                                                            }
                                                            ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "rejected" && "bg-[#FF3D1D]"
                                                            } 
                                                            ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                    "draft" &&
                                                                "bg-[#ffaf26]"
                                                            } 
                                                            
                                                            ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "approved" && "bg-[#16C6A4]"
                                                            } 
                                                        `}
                                                        ></div>
                                                        <p
                                                            className={` font-rocGroteskMedium ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                    "draft" &&
                                                                "text-gm-50"
                                                            }
                                                       
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "approved" && "text-[#16A34A]"
                                                        }
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "rejected" && "text-[#FF3D1D]"
                                                        } ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                    "draft" &&
                                                                "text-[#0F172A]"
                                                            }
                                                        text-sm`}
                                                        >
                                                            {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                    "draft" &&
                                                                "Pending Approval"}
                                                            {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "approved" && "Approved"}
                                                            {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "rejected" && "PO Rejected"}
                                                            {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                                purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                    "draft" &&
                                                                "Draft"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="lg:hidden block">
                                                    {purchaseOrderList?.vendor && (
                                                        <div className="mt-3">
                                                            <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                                Contact person
                                                            </p>
                                                            <div className="md:mt-2 mt-1 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <VendorAvatar
                                                                    size={22}
                                                                    imageSrc={
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.logo
                                                                    }
                                                                    name={
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.businessName
                                                                    }
                                                                />
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.firstName
                                                                    }{" "}
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.lastName
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="md:mt-2 mt-1 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <i className="ri-mail-line text-lg text-[#94A3B8]"></i>
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.email
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="md:mt-2 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <i className="ri-phone-line text-lg text-[#94A3B8]"></i>
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.mobile
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="lg:flex  hidden justify-between items-center">
                                                    <div className="border  rounded-[4px]  border-slate-200 w-[128px] h-[128px] flex items-center justify-center">
                                                        <div className="">
                                                            <VendorAvatar
                                                                size={128}
                                                                containerClassname="!rounded-[4px]"
                                                                imageSrc={
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.avatar
                                                                }
                                                                name={`business-logo- ${purchaseOrderList?.ldb?.profile?.businessName}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`  ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "approved" && "bg-[#F0FDF4]"
                                                        } 
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                "draft" &&
                                                            "bg-[#F9DFB3]"
                                                        } 
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "rejected" && "bg-light-red-status"
                                                        } 
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                "draft" &&
                                                            "bg-[#F1F5F9]"
                                                        }
                                                        px-2.5 py-2 rounded-[4px] cursor-pointer`}
                                                    >
                                                        <p
                                                            className={` font-rocGroteskMedium ${
                                                                purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                    "pending" && "text-gm-50"
                                                            }
                                                       
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "approved" && "text-[#16A34A]"
                                                        }
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "rejected" && "text-[#FF3D1D]"
                                                        } 
                                                        ${
                                                            purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                "draft" &&
                                                            "text-[#0F172A]"
                                                        }
                                                        text-sm`}
                                                        >
                                                            {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                            "approved" ? (
                                                                <>
                                                                    {purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                        "rejected" && "Cancelled"}
                                                                    {purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                        "in-transit" &&
                                                                        "Pending fulfilment"}
                                                                    {purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                        "completed" && "Fulfilled"}
                                                                    {purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                        "pending" && "Pending"}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                        "pending" &&
                                                                        purchaseOrderList?.currentStatus?.toLowerCase() !==
                                                                            "draft" &&
                                                                        "Pending Approval"}
                                                                    {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                        "approved" && "Approved"}
                                                                    {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                        "rejected" && "PO Rejected"}
                                                                    {purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                                                        "pending" &&
                                                                        purchaseOrderList?.currentStatus?.toLowerCase() ===
                                                                            "draft" &&
                                                                        "Draft"}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="w-full  mt-5 lg:py-0 py-4 md:mt-10 md:border-0 border-t border-b flex md:flex-row flex-col md:space-y-0 space-y-3 justify-between">
                                                    <div>
                                                        <p className="text-base font-rocGroteskMedium mb-2">
                                                            From:
                                                        </p>
                                                        <div className="text-sm font-rocGroteskMedium text-slate-500">
                                                            <p className="text-gm-50">
                                                                {
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.businessName
                                                                }
                                                            </p>
                                                            <p>
                                                                {truncate(
                                                                    purchaseOrderList?.ldb?.email,
                                                                    { length: 50 }
                                                                )}
                                                            </p>
                                                            <p>
                                                                {truncate(
                                                                    purchaseOrderList?.ldb?.profile
                                                                        ?.address?.address,
                                                                    { length: 50 }
                                                                )}
                                                            </p>
                                                            <p>{purchaseOrderList?.ldb?.mobile}</p>
                                                        </div>
                                                    </div>
                                                    <div className="md:text-right">
                                                        <p className="text-base font-rocGroteskMedium mb-2">
                                                            To:
                                                        </p>
                                                        <div className="text-sm font-rocGroteskMedium text-slate-500">
                                                            {purchaseOrderList?.vendor?.profile
                                                                ?.businessName && (
                                                                <p className="text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.businessName
                                                                    }
                                                                </p>
                                                            )}

                                                            {purchaseOrderList?.vendor?.email && (
                                                                <p>
                                                                    {truncate(
                                                                        purchaseOrderList?.vendor
                                                                            ?.email,
                                                                        { length: 50 }
                                                                    )}
                                                                </p>
                                                            )}

                                                            {purchaseOrderList?.vendor?.profile
                                                                ?.address?.address && (
                                                                <p>
                                                                    {truncate(
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.address
                                                                            ?.address,
                                                                        { length: 50 }
                                                                    )}
                                                                </p>
                                                            )}

                                                            {purchaseOrderList?.vendor?.mobile && (
                                                                <p>
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.mobile
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="md:hidden grid grid-cols-2 sm:grid-cols-3 items-center border px-3 rounded gap-3 mt-5  py-5">
                                                    <div>
                                                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                            FOB
                                                        </h6>
                                                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.fobPoint
                                                                ? purchaseOrderList?.fobPoint
                                                                      ?.charAt(0)
                                                                      .toUpperCase() +
                                                                  purchaseOrderList?.fobPoint?.slice(
                                                                      1
                                                                  )
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                            Shipping via
                                                        </h6>
                                                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.shippingMethod ||
                                                                "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                            Shipping terms
                                                        </h6>
                                                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.shippingTerms ??
                                                                "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            PO source
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.source
                                                                ? purchaseOrderList?.source
                                                                      ?.charAt(0)
                                                                      .toUpperCase() +
                                                                  purchaseOrderList?.source?.slice(
                                                                      1
                                                                  )
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            Issued Date
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.issuedDate
                                                                ? moment(
                                                                      purchaseOrderList?.issuedDate
                                                                  ).format("MMM DD, YYYY")
                                                                : "N/A"}
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            Due Date
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.dueDate
                                                                ? moment(
                                                                      purchaseOrderList?.dueDate
                                                                  ).format("MMM DD, YYYY")
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-6 md:grid grid-cols-3 hidden justify-between  w-full border-b border-t  py-5">
                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            PO source
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.source
                                                                ? purchaseOrderList?.source
                                                                      ?.charAt(0)
                                                                      .toUpperCase() +
                                                                  purchaseOrderList?.source?.slice(
                                                                      1
                                                                  )
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            Issued Date
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.issuedDate
                                                                ? moment(
                                                                      purchaseOrderList?.issuedDate
                                                                  ).format("MMM DD, YYYY")
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                                                            Due Date
                                                        </h6>
                                                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                                            {purchaseOrderList?.dueDate
                                                                ? moment(
                                                                      purchaseOrderList?.dueDate
                                                                  ).format("MMM DD, YYYY")
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="mt-6 pb-5 border-b">
                                                    <p className="text-[14px] md:block hidden mb-2 text-gm-50 font-rocGroteskMedium">
                                                        Shipping information
                                                    </p>
                                                    <div className="mt-4  md:grid grid-cols-3 justify-between hidden  w-full">
                                                        <div>
                                                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                                FOB
                                                            </h6>
                                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                                {purchaseOrderList?.fobPoint
                                                                    ? purchaseOrderList?.fobPoint
                                                                          ?.charAt(0)
                                                                          .toUpperCase() +
                                                                      purchaseOrderList?.fobPoint?.slice(
                                                                          1
                                                                      )
                                                                    : "N/A"}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                                Shipping via
                                                            </h6>
                                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                                {purchaseOrderList?.shippingMethod ||
                                                                    "N/A"}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                                                Shipping terms
                                                            </h6>
                                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                                {purchaseOrderList?.shippingTerms ??
                                                                    "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="mt-8 ">
                                                    <div className="max-lg:hidden block">
                                                        <CustomTable
                                                            tableBody={tableBody}
                                                            tableHeader={tableHeader}
                                                            isAllSelectable={false}
                                                            isCellSelectable={false}
                                                            headerItemClass="!py-3"
                                                            headerContainerClass="!rounded !font-rocGroteskMedium !text-[#334155] !border-slate-200"
                                                            isScrollable={false}
                                                            isCellBordered={true}
                                                            bodyItemClass={
                                                                "hover:!bg-transparent !rounded"
                                                            }
                                                        />
                                                        <div className="flex justify-between mt-4">
                                                            <div className=" text-sm text-gm-50 w-[50%] font-rocGroteskMedium">
                                                                <p>
                                                                    Total Quantity:{" "}
                                                                    {formatMoney().format(
                                                                        Number(totalQuantity) || 0
                                                                    )}
                                                                </p>
                                                            </div>

                                                            <div className="w-[50%]">
                                                                <div className="text-sm text-gm-50  flex justify-between items-center font-rocGroteskMedium py-2">
                                                                    <p>Total</p>
                                                                    <p className="text-end">
                                                                        {" "}
                                                                        {getCurrencyFromCurrencyCode(
                                                                            purchaseOrderList?.currency
                                                                        )}
                                                                        {formatMoney().format(
                                                                            Number(subTotals) || 0
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                {purchaseOrderList?.tax
                                                                    ?.percentage !== 0 &&
                                                                    purchaseOrderList?.tax
                                                                        ?.percentage !== null &&
                                                                    purchaseOrderList?.tax
                                                                        ?.percentage !==
                                                                        undefined && (
                                                                        <div className="text-sm text-gm-50  flex justify-between items-center border-y  border-slate-200 font-rocGroteskMedium py-3">
                                                                            <p>
                                                                                Tax (
                                                                                {
                                                                                    purchaseOrderList
                                                                                        ?.tax
                                                                                        ?.percentage
                                                                                }
                                                                                %)
                                                                            </p>
                                                                            <p className="text-end">
                                                                                {getCurrencyFromCurrencyCode(
                                                                                    purchaseOrderList?.currency
                                                                                )}
                                                                                {formatMoney().format(
                                                                                    Number(
                                                                                        taxTotals
                                                                                    ) || 0
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                <div className="text-sm text-gm-50  flex justify-between items-center border-b  border-slate-200 font-rocGroteskMedium py-3">
                                                                    <p>Shipping and Handling</p>
                                                                    <p className="text-end">
                                                                        {" "}
                                                                        {getCurrencyFromCurrencyCode(
                                                                            purchaseOrderList
                                                                                ?.shippingAndHandling
                                                                                ?.currency
                                                                        )}
                                                                        {formatMoney().format(
                                                                            Number(
                                                                                purchaseOrderList
                                                                                    ?.shippingAndHandling
                                                                                    ?.amount
                                                                            ) || 0
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="text-base text-gm-50  flex justify-between items-center font-rocGroteskMedium py-2">
                                                                    <p>Grand Total Amount</p>
                                                                    <p className="text-end">
                                                                        {getCurrencyFromCurrencyCode(
                                                                            purchaseOrderList?.currency
                                                                        )}

                                                                        {formatMoney().format(
                                                                            subtotalNumber +
                                                                                taxTotalNumber +
                                                                                shippingAndHandlingNumber
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="max-lg:block hidden">
                                                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium mb-3">
                                                            Product details
                                                        </p>
                                                        {purchaseOrderList?.itemsOrServices?.map(
                                                            (item, idx) => {
                                                                return (
                                                                    <div key={idx} className="">
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                Product name
                                                                            </p>
                                                                            <div className="flex  items-center space-x-2">
                                                                                <VendorAvatar
                                                                                    imageSrc={
                                                                                        item
                                                                                            ?.inventory
                                                                                            ?.prodcutImageDetails
                                                                                            ?.avatar
                                                                                    }
                                                                                    name={
                                                                                        item
                                                                                            ?.inventory
                                                                                            ?.productName
                                                                                    }
                                                                                    containerClassname="!rounded-none "
                                                                                    size={29}
                                                                                />
                                                                                <div className="sm:block hidden">
                                                                                    <p className="text-[#142837] font-rocGroteskMedium text-sm">
                                                                                        {truncate(
                                                                                            item
                                                                                                ?.inventory
                                                                                                ?.productName,
                                                                                            {
                                                                                                length: 20,
                                                                                            }
                                                                                        )}
                                                                                    </p>
                                                                                    <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                        {
                                                                                            item
                                                                                                ?.inventory
                                                                                                ?.sku
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className="sm:hidden block">
                                                                                    <p className="text-[#142837] font-rocGroteskMedium text-sm">
                                                                                        {truncate(
                                                                                            item
                                                                                                ?.inventory
                                                                                                ?.productName,
                                                                                            {
                                                                                                length: 16,
                                                                                            }
                                                                                        )}
                                                                                    </p>
                                                                                    <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                        {
                                                                                            item
                                                                                                ?.inventory
                                                                                                ?.sku
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                Description
                                                                            </p>
                                                                            <div className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {item?.description ||
                                                                                    "N/A"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="font-rocGroteskMedium text-sm text-[#64748B]">
                                                                                Quantity
                                                                            </p>
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {formatMoney().format(
                                                                                    item?.qty
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                Unit cost
                                                                            </p>
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {getCurrencyFromCurrencyCode(
                                                                                    item?.price
                                                                                        ?.currency
                                                                                )}
                                                                                {formatMoney().format(
                                                                                    item?.price
                                                                                        ?.amount
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                Sub-total
                                                                            </p>
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {getCurrencyFromCurrencyCode(
                                                                                    item?.subtotal
                                                                                        ?.currency
                                                                                )}
                                                                                {formatMoney().format(
                                                                                    item?.price
                                                                                        ?.amount *
                                                                                        item?.qty
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                                                            <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                Delivery date
                                                                            </p>
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {moment(
                                                                                    item.deliveryDate
                                                                                ).format(
                                                                                    "DD-MMM-YYYY"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex py-2 justify-between items-center mt-2">
                                                                            <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                                                Lead time
                                                                            </p>
                                                                            <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                                                                {item?.leadTime ||
                                                                                    0}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}

                                                        <div className="bg-[#F1F5F9] mt-4 space-y-3 p-4">
                                                            <div className="flex justify-between">
                                                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                                                    Total
                                                                </p>
                                                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                                    {" "}
                                                                    {getCurrencyFromCurrencyCode(
                                                                        purchaseOrderList?.currency
                                                                    )}
                                                                    {formatMoney().format(
                                                                        Number(subTotals) || 0
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                                                    Tax (
                                                                    {
                                                                        purchaseOrderList?.tax
                                                                            ?.percentage
                                                                    }
                                                                    %)
                                                                </p>
                                                                <p className="tex-sm font-rocGroteskMedium text-sm text-[#142837]">
                                                                    {getCurrencyFromCurrencyCode(
                                                                        purchaseOrderList?.currency
                                                                    )}
                                                                    {formatMoney().format(
                                                                        Number(taxTotals) || 0
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                                                    Shipping and Handling
                                                                </p>
                                                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                                    {getCurrencyFromCurrencyCode(
                                                                        purchaseOrderList
                                                                            ?.shippingAndHandling
                                                                            ?.currency
                                                                    )}
                                                                    {formatMoney().format(
                                                                        Number(
                                                                            purchaseOrderList
                                                                                ?.shippingAndHandling
                                                                                ?.amount
                                                                        ) || 0
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                                                    Grand total amount
                                                                </p>
                                                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                                    {getCurrencyFromCurrencyCode(
                                                                        purchaseOrderList?.currency
                                                                    )}

                                                                    {formatMoney().format(
                                                                        subtotalNumber +
                                                                            taxTotalNumber +
                                                                            shippingAndHandlingNumber
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-5 ">
                                                    {purchaseOrderList?.incoterm && (
                                                        <div className="text-sm font-rocGroteskMedium pb-5 border-b border-slate-200">
                                                            <p className="text-gm-50">Incoterm</p>
                                                            <p className="text-slate-500">
                                                                {purchaseOrderList?.incoterm}{" "}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {purchaseOrderList?.itemsOrServices.length >
                                                        0 && (
                                                        <div className="my-5 space-y-4">
                                                            <div className="text-sm font-rocGroteskMedium">
                                                                <p className="text-gm-50">
                                                                    Currency
                                                                </p>
                                                                <p className="text-slate-500">
                                                                    {
                                                                        purchaseOrderList
                                                                            ?.itemsOrServices[0]
                                                                            ?.price?.currency
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="my-5 space-y-4">
                                                        <div className="text-sm font-rocGroteskMedium">
                                                            <p className="text-gm-50">
                                                                Payment terms
                                                            </p>
                                                            <p className="text-slate-500">
                                                                {purchaseOrderList?.paymentTerms
                                                                    ? purchaseOrderList?.paymentTerms
                                                                          ?.charAt(0)
                                                                          .toUpperCase() +
                                                                      purchaseOrderList?.paymentTerms?.slice(
                                                                          1
                                                                      )
                                                                    : "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {purchaseOrderList?.attachments?.length > 0 && (
                                                        <div className="mt-10">
                                                            <p className="text-gm-50 text-base font-rocGroteskMedium mb-2">
                                                                File Attachment
                                                            </p>
                                                            {purchaseOrderList?.attachments?.map(
                                                                (doc, idx) => (
                                                                    <div
                                                                        className="flex mb-2 items-center p-3 rounded-[6px] space-x-3 border border-[#F1F5F9]"
                                                                        key={idx}
                                                                    >
                                                                        {displayFileIcon(
                                                                            doc?.documentType,
                                                                            true
                                                                        )}
                                                                        <div>
                                                                            <p className="text-sm font-rocGroteskMedium text-slate-700">
                                                                                {doc?.filename}
                                                                            </p>
                                                                            {/* <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                      2MB
                                                  </p> */}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    {purchaseOrderList?.termsAndConditions && (
                                                        <div className="lg:py-10 py-4">
                                                            <h6 className="flex justify-between mb-4 text-sm md:text-base font-rocGroteskMedium text-gm-50">
                                                                Terms & Conditions
                                                            </h6>
                                                            <div className="space-y-0.5 md:text-sm text-[13px] leading-[19px]  font-rocGroteskMedium text-slate-500">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: hasHTMLTag(
                                                                            purchaseOrderList?.termsAndConditions
                                                                        )
                                                                            ? purchaseOrderList?.termsAndConditions
                                                                            : purchaseOrderList?.termsAndConditions,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {purchaseOrderList?.additionalComment && (
                                                    <div className="lg:pb-10 py-3 w-[80%]">
                                                        <h6 className="flex justify-between mb-4  text-sm md:text-base font-rocGroteskMedium text-gm-50">
                                                            Additional Info
                                                        </h6>
                                                        <div className="space-y-0.5 md:text-sm  text-[13px] leading-[19px] font-rocGroteskMedium text-slate-500">
                                                            <p>
                                                                {
                                                                    purchaseOrderList?.additionalComment
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="lg:w-[25%]  lg:pr-3 lg:pl-5 lg:py-4 relative">
                                            <div
                                                className={` ${
                                                    purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                        "approved" &&
                                                    purchaseOrderList?.approvalStatus?.toLowerCase() !==
                                                        "rejected"
                                                        ? "top-[180px] sticky"
                                                        : "sticky top-0 "
                                                } z-100`}
                                            >
                                                <div className="lg:block hidden">
                                                    {purchaseOrderList?.approvals?.length > 0 && (
                                                        <>
                                                            {shouldApprove &&
                                                                !fetchingSinglePurchaseOrder && (
                                                                    <div className="space-y-3 pb-3 border-b border-[#F1F5F9]">
                                                                        <Button
                                                                            btnText={
                                                                                "Approve Purchase order"
                                                                            }
                                                                            type={"button"}
                                                                            btnPermission={[
                                                                                AccountPermissions.all_access,
                                                                                AccountPermissions.create_purchase_order,
                                                                            ]}
                                                                            isLoading={false}
                                                                            btnClassname="!py-2 !text-[14px] !px-4"
                                                                            onClick={() =>
                                                                                shouldApprove &&
                                                                                setOpenApproveModal(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                        <Button
                                                                            btnText={
                                                                                "Reject Purchase order"
                                                                            }
                                                                            type={"button"}
                                                                            btnPermission={[
                                                                                AccountPermissions.all_access,
                                                                                AccountPermissions.create_purchase_order,
                                                                            ]}
                                                                            isLoading={false}
                                                                            btnClassname="!py-2 !bg-[#F4F5F7] !text-[14px] !px-4"
                                                                            onClick={() =>
                                                                                shouldApprove &&
                                                                                setOpentRejectModal(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>

                                                <div className="mt-3 max-lg:px-2 px-0   lg:pb-0">
                                                    {purchaseOrderList?.approvals?.length > 0 && (
                                                        <>
                                                            <p className="mb-3 text-sm font-rocGroteskMedium text-gm-50">
                                                                Approval levels
                                                            </p>
                                                            {purchaseOrderList?.approvals?.map(
                                                                (approver, idx) => {
                                                                    let rejectionFound = false;
                                                                    if (
                                                                        approver?.status?.toLowerCase() ===
                                                                        "rejected"
                                                                    ) {
                                                                        rejectionFound = true;
                                                                    }
                                                                    return (
                                                                        <div
                                                                            className="pb-3 border-b mb-3 border-[#F1F5F9]"
                                                                            key={idx}
                                                                        >
                                                                            <div className="flex  space-x-2 font-rocGroteskMedium">
                                                                                <VendorAvatar
                                                                                    size={28}
                                                                                    imageSrc={
                                                                                        approver
                                                                                            ?.person
                                                                                            ?.profile
                                                                                            ?.avatar
                                                                                    }
                                                                                    name={
                                                                                        approver
                                                                                            ?.person
                                                                                            ?.fullName
                                                                                    }
                                                                                />
                                                                                <div>
                                                                                    <p className="text-[13px] leading-[20px] text-gm-50">
                                                                                        {approver
                                                                                            ?.person
                                                                                            ?.fullName ||
                                                                                            approver
                                                                                                ?.person
                                                                                                ?.email}{" "}
                                                                                        -{" "}
                                                                                        {
                                                                                            approver
                                                                                                ?.person
                                                                                                ?.role
                                                                                        }
                                                                                    </p>
                                                                                    <p className="text-[12px] leading-[18px] text-slate-500">
                                                                                        {moment(
                                                                                            approver
                                                                                                ?.person
                                                                                                ?.updatedAt
                                                                                        ).format(
                                                                                            "MMMM DD, YYYY; h:mma"
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-1 font-rocGroteskMedium">
                                                                                <p className="text-[13px] leading-[20px] text-slate-500">
                                                                                    {
                                                                                        approver?.reason
                                                                                    }
                                                                                </p>
                                                                                <div className="flex items-center space-x-2 mt-2">
                                                                                    <div className="bg-slate-100 px-3 py-1 rounded-[4px] cursor-pointer">
                                                                                        <p className="text-gm-50 text-sm">
                                                                                            Level{" "}
                                                                                            {idx +
                                                                                                1}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className={` ${
                                                                                            approver?.status?.toLowerCase() ===
                                                                                                "pending" &&
                                                                                            "bg-[#F9DFB3] text-[#0F172A]"
                                                                                        } ${
                                                                                            approver?.status?.toLowerCase() ===
                                                                                                "approved" &&
                                                                                            "bg-[#D0F4ED] text-[#0F172A]"
                                                                                        } ${
                                                                                            approver?.status?.toLowerCase() ===
                                                                                                "rejected" &&
                                                                                            "bg-light-red-status text-[#FF3D1D]"
                                                                                        } 
                                                                                    ${
                                                                                        rejectionFound &&
                                                                                        approver?.status?.toLowerCase() !==
                                                                                            "rejected" &&
                                                                                        approver?.status?.toLowerCase() ===
                                                                                            "pending" &&
                                                                                        "bg-slate-100 text-slate-500"
                                                                                    }
                                                                                    px-3 py-1 rounded-[4px] cursor-pointer`}
                                                                                    >
                                                                                        <p className="text-sm">
                                                                                            {rejectionFound &&
                                                                                            approver?.status?.toLowerCase() !==
                                                                                                "rejected" &&
                                                                                            approver?.status?.toLowerCase() ===
                                                                                                "pending"
                                                                                                ? "Cancelled"
                                                                                                : approver?.status}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    )}
                                                    {purchaseOrderList?.vendor && (
                                                        <div className="mt-3 lg:block hidden">
                                                            <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                                                Customer details
                                                            </p>
                                                            <div className="mt-2 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <VendorAvatar
                                                                    size={22}
                                                                    imageSrc={
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.logo
                                                                    }
                                                                    name={
                                                                        purchaseOrderList?.vendor
                                                                            ?.profile?.businessName
                                                                    }
                                                                />
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.firstName
                                                                    }{" "}
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.lastName
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="mt-2 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <i className="ri-mail-line text-lg text-[#94A3B8]"></i>
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.email
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="mt-2 flex items-center space-x-2 font-rocGroteskMedium">
                                                                <i className="ri-phone-line text-lg text-[#94A3B8]"></i>
                                                                <p className="text-[14px] leading-[20px] text-gm-50">
                                                                    {
                                                                        purchaseOrderList?.vendor
                                                                            ?.mobile
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="lg:hidden mt-4 bg-white py-3 left-0 px-5 fixed z-[50] bottom-0 w-full">
                                                    {purchaseOrderList?.approvals?.length > 0 && (
                                                        <>
                                                            {shouldApprove &&
                                                                !fetchingSinglePurchaseOrder && (
                                                                    <div className="space-y-3 pb-3 border-b border-[#F1F5F9]">
                                                                        <Button
                                                                            btnText={
                                                                                "Approve Purchase order"
                                                                            }
                                                                            type={"button"}
                                                                            isLoading={false}
                                                                            btnPermission={[
                                                                                AccountPermissions.all_access,
                                                                                AccountPermissions.create_purchase_order,
                                                                            ]}
                                                                            btnClassname="!py-2 !text-[14px] !px-4"
                                                                            onClick={() =>
                                                                                shouldApprove &&
                                                                                setOpenApproveModal(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                        <Button
                                                                            btnText={
                                                                                "Reject Purchase order"
                                                                            }
                                                                            type={"button"}
                                                                            btnPermission={[
                                                                                AccountPermissions.all_access,
                                                                                AccountPermissions.create_purchase_order,
                                                                            ]}
                                                                            isLoading={false}
                                                                            btnClassname="!py-2 !bg-[#F4F5F7] !text-[14px] !px-4"
                                                                            onClick={() =>
                                                                                shouldApprove &&
                                                                                setOpentRejectModal(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="items-center flex  h-[100%]  justify-center my-[8%]">
                            <div className="space-y-2  text-center">
                                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>

                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    No purchase details
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
            <ModalContainer
                open={openApproveModal}
                modalClassName={"py-10 px-10"}
                showCloseIcon={false}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApproveModal(false);
                }}
            >
                <div className="bg-white !px-2 py-5  rounded-[8px]">
                    <div className="px-6">
                        <div className="flex justify-end">
                            <i
                                className="ri-close-line text-gm-50 text-[20px] cursor-pointer"
                                onClick={() => {
                                    setOpenApproveModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Approved
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    The purchase request has been approved by you, kindly proceed to
                                    the next approval level.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApproveModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            <Button
                                onClick={() => handleApproveRejectPO(true)}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_purchase_order,
                                ]}
                                btnText={"Proceed"}
                                type={"button"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={openRejectModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectModal(false);
                }}
            >
                <div className="bg-white !px-2 py-5  rounded-[8px]">
                    <div className=" px-6">
                        <div className="flex justify-end">
                            <i
                                className="ri-close-line text-gm-50 text-[20px] cursor-pointer"
                                onClick={() => {
                                    setOpentRejectModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Caution_sign_g1u9nv.svg"
                                alt="caution"
                                className="w-[60px] h-[50px]"
                            />
                            <div>
                                <h3 className="text-[22px] leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                    Rejected
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    This purchase request will be rejected by you, kindly add a
                                    comment to complete rejection
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            <Button
                                onClick={() => setOpentRejectionReasonModal(true)}
                                btnText={"Add Comment"}
                                type={"button"}
                                btnClassname={"!bg-r-50 !text-white"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={openApprovalSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApprovalSuccessModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Purchase request Approved
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-base font-rocGroteskMedium">
                                    Congratulations! You've successfully approved the purchase
                                    order.
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h1 className="font-rocGroteskMedium text-sm text-black ">Details</h1>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-base font-rocGroteskMedium text-slate-500">
                                    PO ID
                                </p>
                                <p className="text-base font-rocGroteskMedium text-black">
                                    PO {purchaseOrderList?.taId}
                                </p>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-base font-rocGroteskMedium text-slate-500">
                                    Vendor
                                </p>
                                <div className="flex items-center space-x-1">
                                    <VendorAvatar
                                        imageSrc={purchaseOrderList?.vendor?.profile?.logo}
                                        containerClassname="!rounded-none"
                                        name={purchaseOrderList?.vendor?.profile?.businessName}
                                        size={32}
                                    />
                                    <p className="text-base font-rocGroteskMedium text-black">
                                        {purchaseOrderList?.vendor?.profile?.businessName}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-base font-rocGroteskMedium text-slate-500">
                                    Total amount
                                </p>
                                <p className="text-base font-rocGroteskMedium text-black">
                                    {getCurrencyFromCurrencyCode(purchaseOrderList?.currency)}

                                    {formatMoney().format(
                                        subtotalNumber + taxTotalNumber + shippingAndHandlingNumber
                                    )}
                                </p>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="text-base font-rocGroteskMedium text-slate-500">
                                    Approval date
                                </p>
                                <p className="text-base font-rocGroteskMedium text-black">
                                    {moment(new Date()).format("MMM DD, YYYY")}
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApprovalSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={openRejectionReasonModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectionReasonModal(false);
                    setRejectionReason("");
                }}
            >
                <div className="bg-white !px-2 py-5  rounded-[8px]">
                    <div className="px-6">
                        <div className="flex justify-end">
                            <i
                                className="ri-close-line text-gm-50 text-[20px] cursor-pointer"
                                onClick={() => {
                                    setOpentRejectionReasonModal(false);
                                    setRejectionReason("");
                                }}
                            ></i>
                        </div>
                        <div className="text-center flex justify-center flex-col items-center">
                            <div className="mb-8">
                                <i className="ri-feedback-line text-[39px] text-gm-50"></i>
                                <h3 className="text-2xl mb-2.5 text-black font-rocGroteskMedium">
                                    Provide Rejection Reasons
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    To ensure transparency and compliance, please provide a brief
                                    comment explaining your decision to rejecting this PO.
                                </p>
                            </div>

                            <div className="w-full">
                                <TextareaInput
                                    name={"rejectionReason"}
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="mt-5 flex items-center gap-2.5">
                            <div className="w-full">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-[#F4F5F7] !text-gm-50 "}
                                    isLoading={false}
                                    onClick={() => {
                                        setOpentRejectionReasonModal(false);
                                        setRejectionReason("");
                                    }}
                                    disabled={loadingPOApprovalOrRejection}
                                />
                            </div>
                            <div className="w-full">
                                <Button
                                    onClick={() => handleApproveRejectPO(false)}
                                    btnText={"Reject Purchase Request"}
                                    type={"button"}
                                    btnClassname={"!bg-[#FF3D1D] !text-white "}
                                    disabled={loadingPOApprovalOrRejection}
                                    isLoading={loadingPOApprovalOrRejection}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
            {/* <ModalContainer
                open={openRejectionSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[594px]"
                closeModal={() => {
                    setOpentRejectionSuccessModal(false);
                }}
            >
                <div className="bg-white !px-2 py-5  rounded-[8px]">
                    <div className="px-6">
                        <div className="flex justify-end">
                            <i
                                className="ri-close-line text-gm-50 text-[20px] cursor-pointer"
                                onClick={() => {
                                    setOpentRejectionSuccessModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#DC2626] rounded-full">
                                <i className="ri-close-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-[22px] leading-[32px] mb-2 text-gm-50 font-rocGroteskMedium">
                                    Purchase Request Rejected! (0/3)
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-base font-rocGroteskMedium">
                                    The purchase request has been rejected, and your feedback has
                                    been received. The requester will be notified for necessary
                                    revisions.
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h1 className="font-rocGroteskMedium text-sm text-black ">Approvals</h1>
                            <div className="mt-2 pb-3 border-b border-[#F1F5F9]">
                                <div className="flex justify-between items-center ">
                                    <div className="flex space-x-1 items-center">
                                        <VendorAvatar imageSrc="" name="" size={28} />
                                        <div className="text-[13px] font-rocGroteskMedium leading-[20px]">
                                            <p className="text-gm-50">Tom Smith - Finance</p>
                                            <p className="text-slate-500">April 17, 2023; 2:00pm</p>
                                        </div>
                                    </div>
                                    <div className="flex  items-center space-x-1">
                                        <div className="bg-[#F1F5F9] py-1 rounded px-3">
                                            <p className="text-xs text-[#0F172A] font-rocGroteskMedium">
                                                Level 1
                                            </p>
                                        </div>
                                        <div className="bg-[#FEE2E2] py-1 rounded px-3">
                                            <p className="text-xs text-[#0F172A] font-rocGroteskMedium">
                                                Rejected
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-slate-500 mt-1 text-[13px] leading-[22px] font-rocGroteskMedium">
                                    I don't like the way you structured the Purchase Request. Please
                                    recreated the PR again. Thank you
                                </p>
                            </div>
                            <div className="mt-2 pb-3 border-b border-[#F1F5F9]">
                                <div className="flex justify-between items-center ">
                                    <div className="flex space-x-1 items-center">
                                        <VendorAvatar imageSrc="" name="" size={28} />
                                        <div className="text-[13px] font-rocGroteskMedium leading-[20px]">
                                            <p className="text-gm-50">Tom Smith - Finance</p>
                                            <p className="text-slate-500">April 17, 2023; 2:00pm</p>
                                        </div>
                                    </div>
                                    <div className="flex  items-center space-x-1">
                                        <div className="bg-[#F1F5F9] py-1 rounded px-3">
                                            <p className="text-xs text-[#0F172A] font-rocGroteskMedium">
                                                Level 1
                                            </p>
                                        </div>
                                        <div className="bg-[#F1F5F9] py-1 rounded px-3">
                                            <p className="text-xs text-[#0F172A] font-rocGroteskMedium">
                                                Cancelled
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex items-center gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectionSuccessModal(false);
                                }}
                            />
                            <Button
                                btnText={"View Purchase Request"}
                                type={"button"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectionSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}
        </div>
    );
};

export default PurchaseDetails;
