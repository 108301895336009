import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import Button from "../../../components/common/Button";
import TextInput from "../../../components/common/InputField/TextInput";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { authActions, productionActions } from "redux/Ldb/actions";
import SelectInput from "components/common/InputField/SelectInput";
import ModalContainer from "components/common/ModalContainer";
import TextareaInput from "components/common/InputField/TextareaInput";
import CustomTable from "components/common/CustomTable";
import useGetUserProfile from "hooks/useGetUserProfile";
import { getFromStorage } from "helpers";
import useGetTeamRoles from "hooks/teamHooks/useGetTeamRoles";
import { debounce } from "lodash";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const ProductionSettings = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/production" });

    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    const [workDays, setWorkDays] = useState<string[]>([]);
    const [settingsId, setSettingsId] = useState<string>("");
    const [approval, setApproval] = useState<{ [key: string]: any }>([{}]);
    const [data, setData] = useState<{ [key: string]: any }>({});
    const [orderTracking, setOrderTracking] = useState<{ [key: string]: any }[]>([{}]);
    const [saveSettings, setSaveSettings] = useState<{ [key: string]: any }>({});
    const [materials, setMaterials] = useState<{ [key: string]: any }[]>([{}]);
    const [defaultShift, setDefaultShift] = useState<{ [key: string]: any }>({});
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [planApproval, setPlanApproval] = useState<boolean>(false);
    const [workOrderApproval, setWorkOrderApproval] = useState<boolean>(false);
    const [editRole, setEditRole] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [approvalRules, setApprovalRules] = useState<{ [key: string]: any }[]>([
        { id: "", role: "" },
    ]);
    const [shifts, setShifts] = useState<{ [key: string]: any }[]>([
        { category: "Morning" },
        { category: "Afternoon" },
        { category: "Night" },
    ]);

    const [rulesModal, setRulesModal] = useState<boolean>(false);
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [scheduleModal, setScheduleModal] = useState<boolean>(false);
    const [templateModal, setTemplateModal] = useState<boolean>(false);

    const {
        createProductionSettingsSuccess,
        fetchingProductionSettings,
        fetchedProductionSettingsSuccess,
        updatingProductionSettings,
        updateProductionSettingsSuccess,
    } = useAppSelector((state) => state?.production);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const { data: userRoles, isFetching: isFetchingRoles } = useGetTeamRoles(
        profile?.isMultiBrandAccount || profile?.accountType === "brand"
            ? userProfile?.mainToken || userProfile?.token
            : profile?.token
    );

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const handleDays = (day) => {
        const copyArr = [...workDays];

        if (workDays?.includes(day)) {
            const idx = workDays?.findIndex((days) => days === day);
            copyArr?.splice(idx, 1);
        } else {
            copyArr?.push(day);
        }
        setWorkDays(copyArr);
        if (settingsId) {
            dispatch(
                productionActions?.updateProductionSettings({ workDays: copyArr }, settingsId)
            );
        } else {
            dispatch(productionActions?.createProductionSettings({ workDays: copyArr }));
        }
    };

    const handleDefaultShift = (e) => {
        const { name, value } = e?.target;
        setDefaultShift((prev) => {
            const newDefaultShift = {
                ...prev,
                [name]: value,
            };
            submitDefaultShift(newDefaultShift);
            return newDefaultShift;
        });
    };

    const submitDefaultShift = (updatedShift) => {
        if (updatedShift?.startTime && updatedShift?.endTime) {
            if (settingsId) {
                dispatch(
                    productionActions?.updateProductionSettings(
                        { defaultShift: updatedShift },
                        settingsId
                    )
                );
            } else {
                dispatch(
                    productionActions?.createProductionSettings({ defaultShift: updatedShift })
                );
            }
        }
    };

    const handleSubmitRole = (role) => {
        if (settingsId) {
            dispatch(productionActions?.updateProductionSettings({ editRole: role }, settingsId));
        } else {
            dispatch(productionActions?.createProductionSettings({ editRole: role }));
        }
    };

    const handleSubmitApproval = (plan, workOrder) => {
        const body = {
            planApproval: plan,
            workOrderApproval: workOrder,
        };
        if (settingsId) {
            dispatch(productionActions?.updateProductionSettings(body, settingsId));
        } else {
            dispatch(productionActions?.createProductionSettings(body));
        }
    };

    const handleApprovalRules = (e, idx) => {
        const { value } = e?.target;
        const copyArr = [...approvalRules];
        copyArr[idx].id = value?.split("-")[0];
        copyArr[idx].role = value?.split("-")[1];
        setApprovalRules(copyArr);
        handleSubmitApprovalIds(copyArr);
    };

    const handleSubmitApprovalIds = (approvals) => {
        const approversId =
            approvals?.length >= 1
                ? approvals?.filter((approval) => approval?.id)?.map((assginee) => assginee?.id)
                : [];
        if (settingsId) {
            dispatch(productionActions?.updateProductionSettings({ approversId }, settingsId));
        } else {
            dispatch(productionActions?.createProductionSettings({ approversId }));
        }
    };

    const handleShifts = (e, idx) => {
        const { name, value } = e?.target;
        const copyArr = [...shifts];
        copyArr[idx][name] = value;
        setShifts(copyArr);

        handleSubmitShifts(copyArr);
    };
    const handleSubmitShifts = (shifts) => {
        const requiredKeys = ["startTime", "endTime"]; // Define the keys you want to check

        const newArr = shifts
            ?.filter((shift) => {
                return requiredKeys.every((key) => key in shift);
            })
            .map(({ _id, ...rest }) => {
                return { ...rest };
            });

        if (settingsId) {
            dispatch(productionActions?.updateProductionSettings({ shifts: newArr }, settingsId));
        } else {
            dispatch(productionActions?.createProductionSettings({ shifts: newArr }));
        }
    };

    useEffect(() => {
        dispatch(
            authActions.getTeamMembers(
                40,
                debouncedSearch,
                null,
                profile?.isMultiBrandAccount || profile?.accountType === "brand"
                    ? userProfile?.mainToken || userProfile?.token
                    : profile?.token
            )
        );
    }, [
        dispatch,
        setDebouncedSearch,
        profile?.isMultiBrandAccount,
        profile?.accountType,
        userProfile?.mainToken,
        userProfile?.token,
        profile?.token,
    ]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(productionActions?.fetchProductionSettings());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedProductionSettingsSuccess)) {
            setSaveSettings(fetchedProductionSettingsSuccess?.productionSettings);
            setSettingsId(fetchedProductionSettingsSuccess?.productionSettings?._id);
            // setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedProductionSettingsSuccess]);

    useEffect(() => {
        if (settingsId) {
            const order = ["Morning", "Afternoon", "Night"];

            const resetDefaultShift = order?.map((type) => {
                const shifts = saveSettings?.shifts?.find(
                    (s) => type?.toLowerCase() === s.category?.toLowerCase()
                );
                return shifts || { category: type };
            });

            const resetApprovalRules = saveSettings?.approversId?.map((id) => {
                const exist = teamMembers?.find((member) => member?._id === id);
                return {
                    id: exist?._id,
                    role: exist?.role,
                    name: exist?.fullName ? exist?.fullName : exist?.email,
                };
            });

            setWorkOrderApproval(saveSettings?.workOrderApproval);
            setPlanApproval(saveSettings?.planApproval);
            setWorkDays(saveSettings?.workDays);
            setShifts(resetDefaultShift);
            setDefaultShift(saveSettings?.defaultShift);
            setEditRole(saveSettings?.editRole);
            setApprovalRules(
                saveSettings?.approversId?.length >= 1 ? resetApprovalRules : approvalRules
            );
        }
    }, [settingsId, saveSettings, teamMembers]);

    const TrackingTableHeader = [
        { title: "Statuses", widthClass: "w-[20%]  !bg-slate-50" },
        { title: "Function", widthClass: "w-[70%]   !bg-slate-50" },
        { title: "", widthClass: "w-[5%]  !bg-slate-50" },
    ];

    const TrackingTableBody = orderTracking?.map((product, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  px-4 `}>
                        <p className="text-g-75 text-sm">{product?.bomName ?? "N/A"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full`}>
                        <SelectInput
                            value={data?.userRole}
                            name="userRole"
                            inputPlaceholder={"Select role"}
                            handleChange={(name, value) => {
                                // handleBOMData({ target: { name, value } }, idx);
                            }}
                            // handleInputChange={debounce((evt) => {
                            //     setDebouncedSearch(evt.target.value);
                            // }, 800)}
                            isRequired={true}
                            inputContainerClassName="!mb-0 "
                            className="!border-none !py-0 !px-0"
                            // searchLoading={fetchingProductWithoutBOM}
                            // dropdownOptions={productList.map((item) => ({
                            //     label: item.productName,
                            //     value: item.productName + "_" + item._id,
                            // }))}
                            // disabled={id && true}
                            // clearValue={true}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <i className="ri-delete-bin-line text-slate-500 text-base "></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const MaterialTableHeader = [
        {
            title: "Material",
            widthClass: "w-[50%] !font-rocGroteskMedium !bg-slate-50",
        },
        {
            title: "UOM",
            widthClass: "w-[20%] !font-rocGroteskMedium !bg-slate-50",
        },
        {
            title: "MOQ amount",
            widthClass: "w-[20%] !font-rocGroteskMedium !bg-slate-50",
        },
        {
            title: "",
            widthClass: "w-[10%] !font-rocGroteskMedium !bg-slate-50",
        },
    ];

    const MaterialTableBody = materials?.map((item, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        <SelectInput
                            value={item?.material}
                            name="material"
                            inputPlaceholder={"Select product"}
                            handleChange={(name, value) => {}}
                            // handleInputChange={debounce((evt) => {
                            //     setDebouncedSearch(evt.target.value);
                            // }, 800)}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            // searchLoading={fetchingInventoryList}
                            clearValue={true}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        <SelectInput
                            value={item?.uom}
                            name="uom"
                            inputPlaceholder={"Select"}
                            handleChange={(name, value) => {}}
                            // handleInputChange={debounce((evt) => {
                            //     setDebouncedSearch(evt.target.value);
                            // }, 800)}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            // searchLoading={fetchingInventoryList}
                            clearValue={true}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center px-2.5`}
                    >
                        <TextInput
                            value={item?.moq}
                            name="moq"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="Enter"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <i className="ri-delete-bin-line text-slate-500 text-base "></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div>
            <div className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12">
                <div className="px-6 py-6 border-b border-n-20">
                    <div
                        className="flex items-center space-x-1.5 w-fit cursor-pointer"
                        onClick={() => goBack()}
                    >
                        <i className="ri-arrow-left-line text-[20px]"></i>
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">Settings</p>
                    </div>
                </div>

                <div className="px-6 space-y-4 py-4">
                    <div className="border-b border-slate-200 pb-10 max-w-[1108px]">
                        <p className="text-lg text-gm-50 mb-1">Working Days</p>
                        <p className="text-sm text-slate-500 mb-3">
                            Define which days of the week are designated as working days for
                            production.
                        </p>
                        <div className="">
                            {days?.map((day, idx) => {
                                return (
                                    <div className="flex gap-3 items-center" key={idx}>
                                        <i
                                            className={`${
                                                workDays?.includes(day)
                                                    ? "ri-checkbox-fill text-[#FFB903]"
                                                    : "ri-checkbox-blank-line text-slate-400"
                                            }  text-base cursor-pointer`}
                                            onClick={() => {
                                                handleDays(day);
                                            }}
                                        ></i>
                                        <p
                                            className={`${
                                                workDays?.includes(day)
                                                    ? "text-gm-50"
                                                    : "text-slate-500"
                                            } text-sm`}
                                        >
                                            {day}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="border-b border-slate-200 pb-10 max-w-[1108px]">
                        <p className="text-lg text-gm-50 mb-1">Working Hours</p>
                        <p className="text-sm text-slate-500 mb-3">
                            This is the default working hours for production activities
                        </p>
                        <div className="flex gap-4">
                            <TextInput
                                value={defaultShift?.startTime}
                                name={"startTime"}
                                type={"time"}
                                // required={true}
                                onChange={(e) => {
                                    handleDefaultShift(e);
                                }}
                                containerClassname=""
                            />
                            <TextInput
                                value={defaultShift?.endTime}
                                name={"endTime"}
                                type={"time"}
                                // required={true}
                                onChange={(e) => {
                                    handleDefaultShift(e);
                                }}
                                containerClassname=""
                            />
                        </div>
                    </div>

                    <div className="border-b border-slate-200 pb-10 max-w-[1108px]">
                        <p className="text-lg text-gm-50 mb-1">Working Shift Configuration</p>
                        <p className="text-sm text-slate-500 mb-3">
                            Allows for continuous production by dividing the day into multiple
                            shifts.
                        </p>

                        <div className="space-y-3">
                            {shifts?.map((shift, idx) => (
                                <div className="" key={idx}>
                                    <p className="text-g-75 text-sm mb-3 capitalize">
                                        {shift?.category} Shift
                                    </p>
                                    <div className="flex gap-4">
                                        <TextInput
                                            value={shifts[idx]?.startTime || ""}
                                            name={"startTime"}
                                            type={"time"}
                                            // required={true}
                                            onChange={(e) => {
                                                handleShifts(e, idx);
                                            }}
                                            containerClassname=""
                                        />
                                        <TextInput
                                            value={shifts[idx]?.endTime || ""}
                                            name={"endTime"}
                                            type={"time"}
                                            // required={true}
                                            onChange={(e) => {
                                                handleShifts(e, idx);
                                            }}
                                            containerClassname=""
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="border-b border-slate-200 pb-10 max-w-[1108px]">
                        <p className="text-lg text-gm-50 mb-6">Define user roles and permission</p>

                        <div className="max-w-[489px]">
                            <p className="text-g-75 text-sm mb-3">Restrict production editing</p>
                            <SelectInput
                                value={editRole}
                                name="editRole"
                                inputPlaceholder={"Select role"}
                                handleChange={(name, value) => {
                                    setEditRole(value);
                                    handleSubmitRole(value);
                                }}
                                isRequired={true}
                                className=""
                                inputContainerClassName="!mb-0"
                                dropdownOptions={[
                                    {
                                        label: "Admin",
                                        value: "admin",
                                    },
                                    {
                                        label: "Owner",
                                        value: "owner",
                                    },
                                    {
                                        label: "Manager",
                                        value: "manager",
                                    },
                                    {
                                        label: "Operator",
                                        value: "operator",
                                    },
                                    {
                                        label: "Viewer",
                                        value: "viewer",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="border-b border-slate-200 pb-10 max-w-[1108px]">
                        <p className="text-lg text-gm-50">Production Planning and Order Settings</p>

                        <div className="">
                            <div className={`py-6 border-b borer-slate-200 flex items-start gap-3`}>
                                <i
                                    className={`${
                                        planApproval
                                            ? "ri-checkbox-fill text-[#FFB903]"
                                            : "ri-checkbox-blank-line text-slate-400"
                                    }  text-xl cursor-pointer`}
                                    onClick={() => {
                                        setPlanApproval(!planApproval);
                                        handleSubmitApproval(!planApproval, workOrderApproval);
                                    }}
                                ></i>

                                <div className=" max-w-[589px] ">
                                    <p className={`text-gm-50" text-sm`}>
                                        Production Planning Approval
                                    </p>
                                    <p className="text-slate-500 text-sm">
                                        Adding an approval step to production planning ensures
                                        accurate and accountable changes in your inventory.
                                    </p>
                                </div>
                            </div>

                            <div className={`pt-6 flex items-start gap-3`}>
                                <i
                                    className={`${
                                        workOrderApproval
                                            ? "ri-checkbox-fill text-[#FFB903]"
                                            : "ri-checkbox-blank-line text-slate-400"
                                    }  text-xl cursor-pointer`}
                                    onClick={() => {
                                        setWorkOrderApproval(!workOrderApproval);
                                        handleSubmitApproval(planApproval, !workOrderApproval);
                                    }}
                                ></i>

                                <div className=" max-w-[589px] ">
                                    <p className={`text-gm-50" text-sm`}>Work Order Approval</p>
                                    <p className="text-slate-500 text-sm">
                                        Adding an approval step to Work Order ensures accurate and
                                        accountable changes in your inventory.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-slate-200 pb-10">
                        <p className="text-lg text-gm-50 mb-3">
                            Set up approval rules and assign approvers.
                        </p>

                        <div className="space-y-4">
                            {approvalRules?.map((rules, idx) => (
                                <div className="flex gap-3 items-center max-w-[860px]">
                                    <p className="text-sm text-gm-50 rounded bg-slate-100 py-3 px-[20px]">
                                        {idx + 1}
                                    </p>
                                    <div className="w-[364px]">
                                        <SelectInput
                                            value={rules?.name || ""}
                                            name="name"
                                            placeholder={"Select"}
                                            handleChange={(name, value) => {
                                                const teamStr = String(value?.split("-")[0]);
                                                const pattern = /^[0-9a-fA-F]{24}$/;
                                                const isValid = pattern.test(teamStr);
                                                if (isValid) {
                                                    handleApprovalRules(
                                                        { target: { name, value } },
                                                        idx
                                                    );
                                                }
                                            }}
                                            handleInputChange={debounce((e) => {
                                                setDebouncedSearch(e?.target?.value);
                                            }, 1000)}
                                            searchLoading={fetchingTeamMember}
                                            dropdownOptions={teamMembers?.map((member) => ({
                                                label: member?.fullName
                                                    ? member?.fullName
                                                    : member?.email,
                                                value: member?._id + "-" + member?.role,
                                            }))}
                                        />
                                    </div>
                                    <div className=" border-slate-200 border rounded py-3 px-4 w-[364px]">
                                        <p className="text-g-75 text-sm capitalize">
                                            {rules?.role || "Add member"}
                                        </p>
                                    </div>

                                    {(!planApproval && !workOrderApproval) ||
                                    approvalRules?.length > 1 ? (
                                        <div className="border-slate-200 border rounded py-2 px-3.5">
                                            <i
                                                className={`ri-delete-bin-line text-[#E4281D] text-base ${
                                                    approvalRules?.length > 1
                                                        ? "cursor-pointer"
                                                        : ""
                                                } `}
                                                onClick={() => {
                                                    if (
                                                        (!planApproval && !workOrderApproval) ||
                                                        approvalRules?.length > 1
                                                    ) {
                                                        const copyArr = [...approvalRules];
                                                        copyArr?.splice(idx, 1);
                                                        setApprovalRules(copyArr);
                                                        handleSubmitApprovalIds(copyArr);
                                                    }
                                                }}
                                            ></i>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ))}
                            <Button
                                btnText={
                                    approvalRules?.length > 1 ? "Add more approval" : "Add approval"
                                }
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_production_plan,
                                ]}
                                type="button"
                                // isLoading={}
                                // disabled={}
                                onClick={() => {
                                    const copyArr = [...approvalRules];
                                    copyArr?.push({});
                                    setApprovalRules(copyArr);
                                }}
                                btnClassname="!px-4 !py-2.5 !w-fit"
                            />
                        </div>
                    </div>

                    {/* <div className="border-b border-slate-200 pb-10">
                        <p className="text-lg text-gm-50 mb-3">Work Order templates</p>
                       
                          <div className="space-y-4 ">
                          <div className="flex gap-3 items-center justify-between max-w-[1108px] p-4 border-slate-200 border rounded-lg ">
                            <p className="text-gm-50 text-sm">Standard juice production</p>
                            <div className="flex justify-end items-center gap-5">
                            <p className="text-slate-500 text-sm">Total qty: 1000</p>
                            <i className="ri-arrow-right-s-line text-gm-50 text-2xl"></i>

                            <i className="ri-delete-bin-line text-slate-500 text-base border-l-slate-200 border-l pl-5"  ></i>


                            </div>
                           
                          </div>
                           <div className="flex gap-3 items-center justify-between max-w-[1108px] p-4 border-slate-200 border rounded-lg ">
                            <p className="text-gm-50 text-sm">Standard juice production</p>
                            <div className="flex justify-end items-center gap-5">
                            <p className="text-slate-500 text-sm">Total qty: 1000</p>
                            <i className="ri-arrow-right-s-line text-gm-50 text-2xl"></i>

                            <i className="ri-delete-bin-line text-slate-500 text-base border-l-slate-200 border-l pl-5"  ></i>


                            </div>
                           
                          </div>
                            <Button
                            btnText="Create new template"
                            type="button"
                            // isLoading={}
                            // disabled={}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                        </div>
                    </div>


                    <div className="border-b border-slate-200 pb-10">
                        <p className="text-lg text-gm-50 mb-3">Work Order tracking</p>
                       
                          <div className="">
                         
                           <CustomTable
                        tableBody={TrackingTableBody}
                        tableHeader={TrackingTableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={true}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-50"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        isScrollable={true}
                         tbodyClass="!border !border-slate-200 "
                        tableClass="!border-slate-200 !mb-4 !border-collapse"
                        bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                    />

                    
                           
                          </div>
                            <Button
                            btnText="Create new template"
                            type="button"
                            // isLoading={}
                            // disabled={}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                    </div>

                    <div className="border-b border-slate-200 pb-10 pt-4">
                        <div className="flex items-center gap-3 mb-3">
                             <i className={`ri-checkbox-fill text-[#FFB903] text-base cursor-pointer`}> </i>
                        <p className="text-sm text-gm-50 ">Enable Automatic Status updates</p>

                        </div>
                       
                          <div className="space-y-4 ">
                          <div className="flex gap-3 items-center justify-between p-4 border-slate-200 border rounded-lg ">
                            <p className="text-gm-50 text-sm">Rule 1 : When production starts, set status to "In-Progress</p>
                            

                            <i className="ri-delete-bin-line text-slate-500 text-base "  ></i>


                           
                          </div>
                            <Button
                            btnText="Set up rules"
                            type="button"
                            // isLoading={}
                            // disabled={}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                        </div>
                    </div> */}
                </div>
            </div>
            <ModalContainer
                open={rulesModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setRulesModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="px-4 font-rocGroteskMedium flex items-center justify-between border-b border-b-slate-200 p-6">
                            <p className="text-lg text-gm-50">New Rule</p>
                            <i
                                className="ri-close-fill cursor-pointer text-gm-50 text-xl"
                                onClick={() => {
                                    setRulesModal(false);
                                }}
                            ></i>
                        </div>

                        <div className="px-6 py-6">
                            <TextareaInput
                                name={"rules"}
                                // value={}
                                // onChange={handleProductData}
                                rows={4}
                                placeholder={"Rules"}
                                required={true}
                            />
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center justify-end space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setRulesModal(false);
                                }}
                            />
                            <Button
                                btnText={"Save"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_production_plan,
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={alertModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[620px]"
                showCloseIcon={false}
                closeModal={() => {
                    setAlertModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-1">
                                <i className="ri-calendar-2-line text-gm-50 text-[40px]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Upcoming Holiday Alert
                                </span>
                                <p className="mt-1 text-base text-tradeally-neutral-200 text-center ">
                                    Christmas Day (December 25th) is approaching, which may impact
                                    your work schedule. Additionally, there's an exception for a
                                    special production run on December 26th. Please review and
                                    adjust your plans accordingly to ensure smooth operations.
                                </p>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Not now"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setAlertModal(false);
                                }}
                            />
                            <Button
                                btnText={"View production plan"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={scheduleModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setScheduleModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <div className="mt-4 ">
                        <div className="px-4  flex items-center justify-between border-b border-b-slate-200 p-6">
                            <p className="text-lg text-gm-50">Set Up Scheduling Rules</p>
                            <i
                                className="ri-close-fill cursor-pointer text-gm-50 text-xl"
                                onClick={() => {
                                    setScheduleModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="bg-slate-50 px-6 py-3">
                            <p className="text-slate-500 text-sm ">
                                Customize your production scheduling rules to optimize workflow,
                                prioritize tasks, and manage resources effectively. These settings
                                will ensure that production runs smoothly and deadlines are met.
                            </p>
                        </div>

                        <div className="px-6 pt-6 pb-12 space-y-3">
                            <p className="text-slate-900 text-sm">Priority Rules</p>

                            <SelectInput
                                value={data?.userRole}
                                name="userRole"
                                inputPlaceholder={"Priority Rules"}
                                handleChange={(name, value) => {
                                    // handleBOMData({ target: { name, value } }, idx);
                                }}
                                // handleInputChange={debounce((evt) => {
                                //     setDebouncedSearch(evt.target.value);
                                // }, 800)}
                                isRequired={true}
                                className=""
                                inputContainerClassName="!mb-0 !border-slate-200"
                                // searchLoading={fetchingProductWithoutBOM}
                                dropdownOptions={[
                                    {
                                        label: "Demand Forecast",
                                        value: "Demand Forecast",
                                    },
                                    {
                                        label: "Order Urgency",
                                        value: "Order Urgency",
                                    },
                                    {
                                        label: "Resource Availability",
                                        value: "Resource Availability",
                                    },
                                ]}
                                // disabled={id && true}
                                // clearValue={true}
                            />

                            <TextInput
                                value={data?.start}
                                name={"start"}
                                type={"text"}
                                required={true}
                                onChange={(e) => {
                                    // handleStepData(e);
                                }}
                                placeholder="Set priority levels or ranks."
                                containerClassname=""
                            />

                            <div className="flex gap-3 items-center">
                                <i
                                    className={`${
                                        scheduleModal
                                            ? "ri-checkbox-fill text-[#FFB903]"
                                            : "ri-checkbox-blank-line text-slate-400"
                                    }  text-base cursor-pointer`}

                                    //  onClick={() => {
                                    //   handleDays(day)
                                    // }}
                                ></i>
                                <p className={`text-slate-500 text-sm`}>
                                    Enable conflict resolution rules or overlapping task management.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center justify-end space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setScheduleModal(false);
                                }}
                            />
                            <Button
                                btnText={"Save"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={templateModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setTemplateModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <div className="mt-4 ">
                        <div className="px-4  flex items-center justify-between border-b border-b-slate-200 p-6">
                            <p className="text-lg text-gm-50">Create new template</p>
                            <i
                                className="ri-close-fill cursor-pointer text-gm-50 text-xl"
                                onClick={() => {
                                    setTemplateModal(false);
                                }}
                            ></i>
                        </div>

                        <div className="px-6 py-6 space-y-4">
                            <div className="flex gap-4 border-b border-b-slate-200 pb-4">
                                <TextInput
                                    value={data?.title}
                                    name={"title"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) => {
                                        // handleStepData(e);
                                    }}
                                    placeholder="Title"
                                    containerClassname=""
                                />
                                <TextInput
                                    value={data?.quantity}
                                    name={"quantity"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) => {
                                        // handleStepData(e);
                                    }}
                                    placeholder="Quantity"
                                    containerClassname=""
                                />
                            </div>

                            <CustomTable
                                tableBody={MaterialTableBody}
                                tableHeader={MaterialTableHeader}
                                isInifiniteScroll={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                stickyHeader={true}
                                headerContainerClass="!bg-[#F8FAFC] !border-slate-50"
                                headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                                // tableClass="mb-12 "
                                isScrollable={true}
                                tbodyClass="!border !border-slate-200 "
                                tableClass="!border-slate-200 !border-collapse"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                            <Button
                                btnText="Add more material"
                                type="button"
                                // isLoading={}
                                // disabled={}
                                btnClassname="!w-fit !bg-transparent !text-g-65  !p-0"
                                btnTextClassName="!underline"
                                icon=<i className="ri-add-fill text-base text-g-65"></i>
                            />
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center justify-end space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setTemplateModal(false);
                                }}
                            />
                            <Button
                                btnText={"Save"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ProductionSettings;
