import { sanitizeTaggedUserStringForDisplay } from "helpers/sanitizeTaggedUserString";
import moment from "moment";
import React from "react";

const ChildComment = ({ comment }: { comment: { [key: string]: any } }) => {
    const nameArr = (
        comment?.sender?.fullName ||
        comment?.sender?.firstName ||
        comment?.sender?.lastName ||
        comment?.sender?.email ||
        ""
    ).split(" ");

    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    return (
        <div className=" bg-white font-rocGroteskMedium">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                    <div className="w-8 h-8 flex items-center justify-center bg-[#F7F7F7] border border-[928B78] rounded-full">
                        <span className="text-xs text-[#928B78]">{initials}</span>
                    </div>

                    <h6 className="text-sm font-rocGroteskBold">
                        {comment?.sender?.fullName ||
                            comment?.sender?.firstName ||
                            comment?.sender?.lastName ||
                            comment?.sender?.email}
                    </h6>

                    <span className="text-xs text-gm-35">
                        {moment(comment?.createdAt).fromNow()}
                    </span>
                </div>

                <div className="flex items-center space-x-4">
                    {/* <i className="ri-checkbox-circle-line text-slate-500 text-xl"></i> */}
                    <i className="ri-more-2-fill text-slate-500 text-xl"></i>
                </div>
            </div>

            <div className={`pl-5 ml-4 mb-5`}>
                <p className="text-sm text-slate-600">
                    {sanitizeTaggedUserStringForDisplay(comment?.content)}
                </p>
            </div>
        </div>
    );
};

export default ChildComment;
