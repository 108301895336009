import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetArchivedProductsByChannel = (scrollContent?: HTMLElement) => {
    const [archivedProducts, setArchiveProducts] = useState<{ [key: string]: any }[]>([]);
    const [archivedTotal, setArchiveTotal] = useState<number>(0);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [productCategory, setProductCategory] = useState<string>("");
    const [channel, setChannel] = useState<string>("");


    const dispatch = useAppDispatch();
    const {
        fetchingArchiveProducts,
        fetchedArchivedProductsSuccess,
        fetchedArchivedProductsFailure,
        importedSyncedProductsSuccess,
    } = useAppSelector((state) => state.inventory);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    const handleFilterChange = (value: string) => {
        setUpdatedPage(1);
        setProductCategory(value);
    };

    const handleChannelFilterChange = (value: string) => {
        setUpdatedPage(1);
        setChannel(value);
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        setProductCategory("");
    };

    const handleClearChannelFilter = () => {
        setUpdatedPage(1);
        setChannel("");
    };

    useEffect(() => {
        dispatch(inventoryActions.fetchArchiveProducts(10, debouncedSearch, updatedPage, productCategory, channel));
    }, [dispatch, updatedPage, debouncedSearch, importedSyncedProductsSuccess, productCategory, channel]);

    useEffect(() => {
        if (Boolean(fetchedArchivedProductsSuccess)) {
            setArchiveProducts((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedArchivedProductsSuccess?.stock], "_id")
                    : uniqBy([...prev, ...fetchedArchivedProductsSuccess?.stock], "_id")
            );
            setArchiveTotal(fetchedArchivedProductsSuccess?.total);
            setPagination({
                currentPage: fetchedArchivedProductsSuccess.pagination?.current,
                noOfPages: fetchedArchivedProductsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchArchiveProductsSuccess());
        }
    }, [dispatch, fetchedArchivedProductsSuccess, updatedPage, productCategory]);

    return {
        isFetching: fetchingArchiveProducts,
        error: fetchedArchivedProductsFailure,
        data: archivedProducts,
        setArchiveProducts,
        total: archivedTotal,
        isLoadingMore,
        pagination,
        setPagination,
        updatedPage,
        debouncedSearch,
        search,
        handleSearch,
        handleDebouncedChange,
        handleFilterChange,
        handleClearFilter,
        productCategory,
        handleClearChannelFilter,
        handleChannelFilterChange,
        channel,
    };
};

export default useGetArchivedProductsByChannel;
