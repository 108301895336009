import React, { useState } from "react";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import SingleProductForecast from "./SingleProductForecast";
import BundledProductForecast from "./BundledProductForecast";

const Forecast = () => {
    const [activeTab, setActiveTab] = useState<number>(0);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <SingleProductForecast />;

            case 1:
                return <BundledProductForecast />;

            default:
                return <div></div>;
        }
    };

    return (
        <div>
            <div className="mb-6">
                <PageTitleSection
                    leftComp={<h3 className="text-xl text-g-75 font-rocGroteskMedium">Forecast</h3>}
                />
            </div>

            <div>
                <TabsContainer
                    tabs={["Single SKUs forecast", "Bundled SKUs forecast"]}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    itemClassName="!pb-2"
                    borderLineClase={"!text-slate-100 w-full max-lg:w-full mb-6"}
                    tabRowClassname="!overflow-visible"
                />
            </div>
        </div>
    );
};

export default Forecast;
