import React, { useEffect, useState } from "react";
import AuthFrame from "./AuthFrame";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import countries from "variables/countries";
import SelectInput from "components/common/InputField/SelectInput";
import ReactCountryFlag from "react-country-flag";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneExt, setPhoneExt] = useState("");
    const [usePhone, setUsePhone] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { forgotPasswordRequest, forgotPasswordSuccess } = useAppSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(authActions.forgotPassword({ email }));
    };

    useEffect(() => {
        if (Boolean(forgotPasswordSuccess)) {
            setEmail("");
        }
    }, [forgotPasswordSuccess]);

    return (
        <AuthFrame>
            <div className="w-full h-fit bg-white shadow-dropShadow-cookie p-[40px] rounded-[8px]">
                <div className="mb-[38px] flex flex-col items-center justify-center">
                    <img
                        src={
                            "https://storage.googleapis.com/heysynth_files/synth_webapp/SynthLogo_h118c2.svg"
                        }
                        alt="Logo Icon"
                        className="mb-2 "
                        height={26.4}
                        width={88}
                    />
                    <p className="text-[26px] font-flame font-normal text-center  text-[#013D28]">
                        Forgot your Password?
                    </p>
                    {usePhone ? (
                        <span className="text-sm text-center text-[#94A3B8]">
                            A private link will be sent to your phone number via SMS
                        </span>
                    ) : (
                        <span className="text-sm text-center text-[#94A3B8]">
                            A private link will be sent to your email address
                        </span>
                    )}
                </div>
                <form onSubmit={handleSubmit}>
                    {usePhone ? (
                        <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 mb-1">
                            <SelectInput
                                value={phoneExt}
                                name="phoneExt"
                                placeholder={"Ext"}
                                handleChange={(name, value) => setPhoneExt(value)}
                                isRequired={true}
                                inputClassName={
                                    "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                }
                                dropdownOptions={countries
                                    .filter((data) => Boolean(data?.codes[0]))
                                    .sort((p1, p2) =>
                                        p1.codes[0] > p2.codes[0]
                                            ? 1
                                            : p1.codes[0] < p2.codes[0]
                                            ? -1
                                            : 0
                                    )
                                    .map((item) => ({
                                        label: item.codes[0]?.replace(" ", ""),
                                        value: item.codes[0]?.replace(" ", ""),
                                        icon: (
                                            <ReactCountryFlag
                                                countryCode={item?.abbreviation}
                                                svg
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "50%",
                                                    marginTop: "-4px",
                                                }}
                                            />
                                        ),
                                    }))}
                            />
                            <TextInput
                                name={"phoneNo"}
                                type={"number"}
                                value={phone}
                                onChange={(evt) => setPhone(evt.target.value)}
                                placeholder={"Phone number"}
                                minLength={10}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                required={true}
                                pattern={"^[0-9]+$"}
                                title={"must be digits"}
                                inputClassName={
                                    "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                }
                            />
                        </div>
                    ) : (
                        <TextInput
                            name={"email"}
                            value={email}
                            type={"email"}
                            placeholder={"Enter work email"}
                            required={true}
                            onChange={(evt) => setEmail(evt.target.value)}
                            containerClassname="mb-6"
                        />
                    )}
                    {/* <div className="flex justify-end mb-6">
                        <span
                            className="text-sm cursor-pointer text-[#6562E5] font-interDisplay font-medium"
                            onClick={() => setUsePhone(!usePhone)}
                        >
                            {usePhone ? "Use phone number instead" : "Use email instead"}
                        </span>{" "}
                    </div> */}
                    <div className="w-full mb-[25px]">
                        <Button
                            btnText={"Send link"}
                            isLoading={forgotPasswordRequest}
                            type={"submit"}
                        />
                    </div>

                    <p
                        className="text-center text-[13px] text-[#142837] cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <i className="ri-arrow-left-s-line"></i>
                        Back
                    </p>
                </form>
            </div>
        </AuthFrame>
    );
};

export default ForgotPassword;
