import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigate } from "react-router-dom";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import { inventoryActions } from "redux/Ldb/actions";
import { Tooltip } from "react-tooltip";

type SelectProductsProps = {
    step?: number;
    setStep?: React.Dispatch<React.SetStateAction<number>>;
    selectedProducts: { [key: string]: any }[];
    skuNumber?: string;
    setSelectedProducts: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
    masterSkuId?: string;
};

const PreviewSku = ({
    step,
    setStep,
    selectedProducts,
    skuNumber,
    setSelectedProducts,
    masterSkuId,
}: SelectProductsProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { creatingSkuMapping, createdSkuMappingSuccess } = useAppSelector(
        (state) => state.inventory
    );
    const [isBundle, setIsBundle] = useState<boolean>(false);
    const conflictSku = selectedProducts.filter((product) => skuNumber === product.sku);

    const onCreateSkuMapping = () => {
        const body = {
            sku: skuNumber,
            inventories: [masterSkuId, ...selectedProducts.map((product) => product._id)],
        };
        if (conflictSku.length === 0) {
            dispatch(inventoryActions.createSkuMapping(body));
        }
    };

    const handleDelete = (productId) => {
        setSelectedProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== productId)
        );
    };
    useEffect(() => {
        const hasBundle = selectedProducts.some((product) => product.isBundle === true);
        setIsBundle(hasBundle);
    }, [selectedProducts]);
    useEffect(() => {
        if (Boolean(createdSkuMappingSuccess)) {
            dispatch(inventoryActions.resetCreateSkuMappingSuccess());
            navigate("/dashboard/inventory?currTab=5", { state: { internalNavigation: true } });
        }
    }, [createdSkuMappingSuccess, navigate, dispatch]);

    return (
        <div className="mt-[31px] flex justify-center">
            <div className="w-[70%] max-h-[75vh] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_#10182814]">
                <div className="w-full h-full relative">
                    <div className="px-6 pt-4 pb-3 border-b border-slate-200">
                        <div className="font-rocGroteskMedium flex justify-between items-center mb-2">
                            <div className="flex items-center space-x-1.5">
                                <i
                                    className="ri-arrow-left-line cursor-pointer text-g-75"
                                    onClick={() => setStep(1)}
                                ></i>
                                <h4 className="text-g-75 text-base">
                                    Preview your selected products/SKUs
                                </h4>
                            </div>
                            <div className="w-[93px]">
                                <span className="text-sm flex justify-end text-slate-500 ">
                                    Step 2/2
                                </span>
                                <div className="grid  grid-cols-2 space-x-1.5">
                                    <span className="h-1 bg-[#FFB902] rounded"></span>
                                    <span className="h-1 bg-[#FFB902] rounded"></span>
                                </div>
                            </div>
                        </div>

                        <div className="mb-1">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium w-[68%] text-slate-500">
                                    Before completing this SKU mapping, please take a moment to
                                    review the details of your selected product/SKU. Ensuring
                                    accuracy now can help prevent any conflicts later.
                                </p>
                            </div>
                            <div className="flex bg-slate-50 py-2 px-3 border border-slate-200 rounded items-center justify-between">
                                <div>
                                    <p className="text-slate-700 text-sm font-rocGroteskMedium">
                                        SKU-{skuNumber}
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {selectedProducts?.[0]?.productName}
                                    </p>
                                </div>
                                {isBundle && (
                                    <div className="flex items-center space-x-2">
                                        <div className="bg-slate-200 border border-slate-300 rounded py-[2px] px-2">
                                            <p className="text-slate-500 font-rocGroteskMedium text-xs">
                                                Part of a bundle
                                            </p>
                                        </div>
                                        <div onClick={() => setStep(1)}>
                                            <i className="ri-edit-2-line text-slate-500 text-lg cursor-pointer"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="h-[48.8vh] overflow-y-auto space-y-3 pt-3 pb-14">
                        {selectedProducts?.map((product, idx) => (
                            <div className="w-full flex px-6  items-center justify-between border-b pb-2 border-slate-200">
                                <div className="flex w-[50%] space-x-3 ">
                                    <div>
                                        {product?.productImageDetails?.productImages?.[0] ? (
                                            <VendorAvatar
                                                imageSrc={
                                                    product?.productImageDetails?.productImages?.[0]
                                                }
                                                size={32}
                                                name={product?.productName}
                                                containerClassname="!rounded-md"
                                            />
                                        ) : (
                                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium text-[#042821] ">
                                            {product?.productName}
                                        </p>
                                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                            {product?.sku}
                                        </p>
                                    </div>
                                    {skuNumber === product?.sku && (
                                        <div>
                                            <a id={`product-column-${idx}`}>
                                                <span className="material-symbols-outlined cursor-pointer text-[#FF3D1D] text-sm">
                                                    warning
                                                </span>
                                            </a>
                                            <Tooltip
                                                anchorSelect={`#product-column-${idx}`}
                                                place={"top"}
                                                content={"Conflicted SKU detected"}
                                                style={{
                                                    maxWidth: "250px",
                                                    zIndex: 9999,
                                                    background: "#142837",
                                                    borderRadius: "6px",
                                                    border: "1px solid #E2E8F0",
                                                    color: "#FFFFFF",
                                                    boxShadow:
                                                        "0px 4px 16px 0px rgba(16, 24, 40, 0.03)",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="flex  w-[25%] items-center space-x-1">
                                    <span>
                                        {displayProductChannelIcon(
                                            product?.channel || product.channels?.[0]
                                        )}{" "}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-[#042821]">
                                        {product?.channel}
                                    </span>
                                </div>
                                <div className="w-[20%] flex justify-end">
                                    {selectedProducts?.length > 2 && (
                                        <div>
                                            <i
                                                className="ri-delete-bin-6-line text-slate-500 text-lg cursor-pointer"
                                                onClick={() => handleDelete(product?._id)}
                                            ></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {
                        <div className="flex items-center justify-between absolute bottom-0 w-full bg-white border-t border-slate-200 px-6 py-4">
                            <div>
                                <p className="font-rocGroteskMedium text-sm">
                                    {selectedProducts.length} products ready to be mapped
                                </p>
                            </div>
                            <div className="flex items-center space-x-3">
                                <Button
                                    btnText="Edit"
                                    btnClassname="!py-3 !px-6 !bg-n-20 !bg-transparent !border"
                                    disabled={creatingSkuMapping}
                                    onClick={() => setStep(1)}
                                />
                                <Button
                                    btnText="Cancel"
                                    btnClassname="!py-3 !bg-n-20"
                                    disabled={creatingSkuMapping}
                                    onClick={() =>
                                        navigate("/dashboard/inventory?currTab=5", {
                                            state: { internalNavigation: true },
                                        })
                                    }
                                />

                                <Button
                                    btnText="Complete SKU mapping"
                                    btnClassname="!py-3 !whitespace-nowrap"
                                    isLoading={creatingSkuMapping}
                                    onClick={onCreateSkuMapping}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PreviewSku;
