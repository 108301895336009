import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import SelectInput from "components/common/InputField/SelectInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import useGetTeam from "hooks/teamHooks/useGetTeamList";

const MarketingConfiguration = ({ marketingConfig, setMarketingConfig }) => {
    const [shouldApprove, setShouldApprove] = useState(false);

    const {
        data: teamList,
        handleSearch,
        handleDebouncedChange,
        search,
        isFetching: isFetchingTeamList,
    } = useGetTeam();

    // useEffect(() => {
    //     if (marketingConfig?.approver && !shouldApprove) {
    //         setShouldApprove(true);
    //     }
    // }, [marketingConfig?.approver, shouldApprove]);

    // useEffect(() => {
    //     if (marketingConfig?.approver && !shouldApprove) {
    //         setShouldApprove(true);
    //     }
    // }, [marketingConfig?.approver, shouldApprove]);

    return (
        <div className="space-y-3">
            <div className={`font-rocGroteskMedium p-5 border border-slate-200 rounded-[8px] mb-3`}>
                <div className="flex items-center justify-between mb-1 text-sm space-x-2">
                    <div className="flex items-center space-x-1">
                        <span className="text-sm">
                            Extract campaign information from Ad Manager
                        </span>
                        <div>
                            <a id={`step-info-${1}`}>
                                <i className="ri-information-fill text-slate-300 text-base"></i>
                            </a>

                            <Tooltip
                                anchorSelect={`#step-info-${1}`}
                                place={"top"}
                                content={"Extract campaign information from Ad Manager"}
                                style={{
                                    width: "250px",
                                    zIndex: 9999,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <hr className="my-3" />
                    <SelectInput
                        value={marketingConfig?.syncFrequency}
                        name="syncFrequency"
                        placeholder={"Select frequency"}
                        handleChange={(name, value) => {
                            // handleAiStepsChange(idx, name, value)
                            setMarketingConfig((prev) => ({
                                ...prev,
                                [name]: value,
                            }));
                        }}
                        isRequired={true}
                        dropdownOptions={[
                            {
                                label: "Weekly",
                                value: "weekly",
                            },
                            {
                                label: "Monthly",
                                value: "monthly",
                            },
                            {
                                label: "Yearly",
                                value: "yearly",
                            },
                        ]}
                    />
                </div>
            </div>

            <div className={`font-rocGroteskMedium p-5 border border-slate-200 rounded-[8px] mb-3`}>
                <div className="flex items-center justify-between mb-1 text-sm space-x-2">
                    <div className="flex items-center space-x-1">
                        <span className="text-sm">Alert any potential inventory incidents</span>
                        {/* <div>
                            <a id={`step-info-${1}`}>
                                <i className="ri-information-fill text-slate-300 text-base"></i>
                            </a>

                            <Tooltip
                                anchorSelect={`#step-info-${1}`}
                                place={"top"}
                                content={"Extract campaign information from Ad Manager"}
                                style={{
                                    width: "250px",
                                    zIndex: 9999,
                                }}
                            />
                        </div> */}
                    </div>

                    {/* <div className="text-[13px] flex items-center space-x-1">
                        <span>Require approval before action</span>
                        <ToggleInput
                            checked={shouldApprove}
                            name={"shouldApprove"}
                            onChange={(e) => {
                                setShouldApprove((prev) => !prev);

                                setMarketingConfig((prev) => ({
                                    ...prev,
                                    ["approver"]: "",
                                }));
                            }}
                        />
                    </div> */}
                </div>

                <div>
                    <div className="flex items-center justify-between py-3">
                        <hr className="w-[36%]" />
                        <span className="text-sm text-slate-500 whitespace-nowrap">
                            Specify the collaborators to alert
                        </span>
                        <hr className="w-[36%]" />
                    </div>

                    <MultiselectInput
                        value={marketingConfig.collaboratorIds}
                        name="collaboratorIds"
                        placeholder={"Select collaborator"}
                        handleChange={(name, value, valueArr) => {
                            // handleAiStepsChange(idx, name, value)
                            setMarketingConfig((prev) => ({
                                ...prev,
                                [name]: valueArr,
                            }));
                        }}
                        searchLoading={isFetchingTeamList}
                        handleInputChange={handleDebouncedChange}
                        isRequired={false}
                        dropdownOptions={teamList?.map((team) => {
                            return {
                                label: team?.fullName || team?.email,
                                value: team?._id,
                            };
                        })}
                    />
                </div>

                {/* {shouldApprove && (
                    <div>
                        <div className="flex items-center justify-between py-3">
                            <hr className="w-[36%]" />
                            <span className="text-sm text-slate-500 whitespace-nowrap">
                                Select approver
                            </span>
                            <hr className="w-[36%]" />
                        </div>

                        <SelectInput
                            value={marketingConfig.approver}
                            name="approver"
                            placeholder={"Select approver"}
                            handleChange={(name, value) => {
                                // handleAiStepsChange(idx, name, value)
                                setMarketingConfig((prev) => ({
                                    ...prev,
                                    [name]: value,
                                }));
                            }}
                            isRequired={false}
                            dropdownOptions={teamList?.map((team) => {
                                return {
                                    label: team?.fullName || team?.email,
                                    value: team?._id,
                                };
                            })}
                        />
                    </div>
                )} */}
            </div>

            <div className={`font-rocGroteskMedium p-5 border border-slate-200 rounded-[8px] mb-3`}>
                <div className="flex items-center justify-between mb-1 text-sm space-x-2">
                    <div className="flex items-center space-x-1">
                        <span className="text-sm">Generate reports</span>
                        {/* <div>
                            <a id={`step-info-${1}`}>
                                <i className="ri-information-fill text-slate-300 text-base"></i>
                            </a>

                            <Tooltip
                                anchorSelect={`#step-info-${1}`}
                                place={"top"}
                                content={"Extract campaign information from Ad Manager"}
                                style={{
                                    width: "250px",
                                    zIndex: 9999,
                                }}
                            />
                        </div> */}
                    </div>
                </div>

                <div>
                    <hr className="my-3" />
                    <SelectInput
                        value={marketingConfig?.generateReportsFrequency}
                        name="generateReportsFrequency"
                        placeholder={"Select frequency"}
                        handleChange={(name, value) => {
                            // handleAiStepsChange(idx, name, value)
                            setMarketingConfig((prev) => ({
                                ...prev,
                                [name]: value,
                            }));
                        }}
                        isRequired={true}
                        dropdownOptions={[
                            {
                                label: "Weekly",
                                value: "weekly",
                            },
                            {
                                label: "Monthly",
                                value: "monthly",
                            },
                            {
                                label: "Yearly",
                                value: "yearly",
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default MarketingConfiguration;
