import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useGetAiAgents from "hooks/workforceHooks/useGetAiAgents";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import AiAgentActivities from "./AiAgentActivities";
import MarketingConfiguration from "./MarketingConfiguration";
import Checkbox from "components/common/InputField/Checkbox";
import ToggleInput from "components/common/InputField/ToggleInput";
import { aiAvatarArr, AiNameSuggestions } from "variables/CreateAiTeammateDate";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import { vendorActions, workforceActions } from "redux/Ldb/actions";
import { includes, sortedUniq, uniqBy } from "lodash";
import PageFrame from "components/layout/PageFrame";
import ModalContainer from "components/common/ModalContainer";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetSingleAiTeammate from "hooks/workforceHooks/useGetSingleAiTeammate";
import { firebaseService } from "services/firebaseService";
import {
    getFromStorage,
    hasHTMLTag,
    removeFromStorage,
    sanitizeHtml,
    saveToStorage,
} from "helpers";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";
import TextInput from "components/common/InputField/TextInput";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/config";
import { useChannel } from "ably/react";
import useGetSingleTask from "hooks/workforceHooks/useGetSingleTask";
import useGetUserProfile from "hooks/useGetUserProfile";
import pako from "pako";
import CustomTable from "components/common/CustomTable";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { Tooltip } from "react-tooltip";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const CreateAiTeammate = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const avatarModalRef = useRef<HTMLDivElement>(null);
    const { aiTeammateId } = useParams();
    const profile = getFromStorage("ally-user");
    // const [profile] = useGetUserProfile();
    const [param] = useSearchParams();
    const taskId = param.get("taskId");
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/workforce" });
    const [role, setRole] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aiNameValue, setAiNameValue] = useState("");
    const [communicationChannels, setCommunicationChannels] = useState([]);
    const [openAvatarModal, setOpenAvatarModal] = useState<boolean>(false);
    const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);
    const [testEmailModal, setTestEmailModal] = useState<boolean>(false);
    const [aiStepValues, setAiStepValues] = useState<{ [key: string]: any }[]>([]);
    const [ediSteps, setEdiSteps] = useState<{ [key: string]: any }[]>([
        {
            description: "Email order is received and detected",
            user: true,
        },
        {
            description: "AI team-mate extracts order information",
        },
        {
            description: "AI team-mate checks inventory to see if product is in stock",
            warning: true,
        },
        {
            description: "AI team-mate creates order",
            user: true,
        },
    ]);
    const [emailClientModal, setEmailClientModal] = useState<boolean>(false);
    const [emailClientSocialId, setEmailClientSocialId] = useState<string>("");
    const [showTestOutput, setShowTestOutput] = useState<boolean>(false);
    const [testEmail, setTestEmail] = useState({
        subject: "",
        body: "",
    });
    const [testTask, setTestTask] = useState<{ [key: string]: any }>({});
    const [testTaskId, setTestTaskId] = useState<string>("");
    const [confirmRetry, setConfirmRetry] = useState<boolean>(false);
    const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
    const [feedbackSuccessModal, setFeedbackSuccessModal] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>("");
    const [selectedStep, setSelectedStep] = useState<{ [key: string]: any }>({});
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [ediSource, setEdiSource] = useState<{ [key: string]: any }[]>([
        { name: "SPS Commerce", connection: null, key: "spsCommerce" },
    ]);
    const [signupInfo, setSignupInfo] = useState({
        email: "",
        password: "",
    });
    const [showWhy, setShowWhy] = useState<boolean>(false);
    const [spsSigninModal, setSpsSigninModal] = useState<boolean>(false);
    const [marketingConfig, setMarketingConfig] = useState({
        syncFrequency: "",
        needsApproval: false,
        approver: "",
        collaboratorIds: [],
        generateReportsFrequency: "",
    });
    const [ediConfig, setEdiConfig] = useState({
        syncFrequency: "",
        needsApproval: false,
        approver: "",
        collaboratorIds: [],
        generateReportsFrequency: "",
    });
    const [selectedMarketingConnection, setSelectedMarketingConnection] = useState<
        {
            [key: string]: any;
        }[]
    >([]);
    // Access state in the new route
    const location = useLocation();
    const savedData = getFromStorage("createAiAgentForm");

    const { data: aiAgents, isFetching: isFetchingAiAgent } = useGetAiAgents();
    const { data: taskInfo, isFetching: fetchingSingleTask } = useGetSingleTask(
        taskId || testTaskId
    );
    const {
        creatingAiTeammate,
        createAiTeammateSuccess,
        updatingAiTeammate,
        updateAiTeammateSuccess,
        approvingTaskStep,
        approveTaskStepSuccess,
        retryingTaskStep,
        retryTaskStepSuccess,
        givingTaskStepFeedback,
        giveTaskStepFeedbackSuccess,
        createSpsCommerceConnectionSuccess,
        creatingSpsCommerceConnection,
    } = useAppSelector((state) => state.workforce);

    const { fetchedAllChannelsSuccess, fetchingAllChannels } = useAppSelector(
        (state) => state.vendor
    );
    const { data: singleAiTeammate, isFetching } = useGetSingleAiTeammate();
    const { testingAiTeammate, testAiTeammateSuccess } = useAppSelector((state) => state.workforce);

    const emailClients = fetchedAllChannelsSuccess?.channel?.filter(
        (item) =>
            (item?.channel?.toLowerCase() === "gmail" ||
                item?.channel?.toLowerCase() === "microsoft") &&
            item?.active
    );

    const klaviyoConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "klaviyo" && item?.active
    );

    const googleAdsConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "google_ads" && item?.active
    );

    const tiktokConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "tiktok_ads" && item?.active
    );
    const spsCommerceConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "sps-commerce" && item?.active
    );

    const marketingConnections = [
        {
            name: "Klaviyo",
            connection: klaviyoConnected,
            key: "kvo",
        },
        {
            name: "Google Ads",
            connection: googleAdsConnected,
            key: "goAds",
        },
        {
            name: "Tiktok",
            connection: tiktokConnected,
            key: "tko",
        },
    ];

    const activitiesStructure = aiAgents.reduce((acc, curr) => {
        acc[curr.type] = curr;
        return acc;
    }, {});

    const handleUpload = (fileName, file) => {
        setUploadingAvatar(true);
        firebaseService.uploadFile(
            `synthally/${process.env.REACT_APP_STAGE === "production" ? "production" : "staging"}/${
                profile?.user?.profile?.businessName
            }/aiTeammateAvatar/${fileName}`,
            file,
            (url) => {
                setAvatar(url);
                setUploadingAvatar(false);
                setOpenAvatarModal(false);
            }
        );
    };

    const handleCreateTeammate = () => {
        const stepArray = aiStepValues?.map((step) => {
            const stepData = {
                name: camelCaseToRegularCase(step?.name),
                key: step?.key,
                title: step?.title,
                description: step?.description,
                approvalPossible: step?.shouldApprove,
                repeatable: step?.shouldRepeat,
                // approvalId: step?.approver,
                actions: step?.actions?.map((act) => ({
                    ...act,
                    selected: act?.name === step?.controlAction,
                })),
            };

            if (step?.approver) {
                stepData["approvalId"] = step?.approver;
            }

            if (stepData.repeatable && step?.dateTime) {
                const datetime = moment(step?.dateTime);
                stepData["repeatInterval"] = {
                    frequency: step?.frequency,
                    time: {
                        day: datetime.format("dddd"),
                        time: datetime.format("HH:mm"),
                        hours: datetime.hours(),
                        minutes: datetime.minutes(),
                        seconds: datetime.seconds(),
                    },
                };
            }

            return stepData;
        });

        let body: { [key: string]: any } = {
            teamMateName: aiNameValue,
            avatar: avatar || aiAvatarArr[0],
            agentType: role,
            communicationChannel: {
                email: Boolean(communicationChannels?.find((channel) => channel === "email")),
                inApp: Boolean(communicationChannels?.find((channel) => channel === "in_app")),
                autoGenerateEmail: Boolean(
                    communicationChannels?.find((channel) => channel === "auto_generate_email")
                ),
            },
            steps: stepArray,
        };

        if (
            Boolean(communicationChannels?.find((channel) => channel === "email")) &&
            emailClientSocialId
        ) {
            body = {
                ...body,
                communicationChannel: {
                    ...body.communicationChannel,
                    emailChannels: [
                        {
                            socialId: emailClientSocialId,
                        },
                    ],
                },
            };
        }

        if (selectedMarketingConnection?.length > 0) {
            body = {
                ...body,
                communicationChannel: {
                    ...body.communicationChannel,
                    socialChannels: selectedMarketingConnection?.map((item) => ({
                        socialId: item?.connection?._id,
                    })),
                },
            };
        }

        if (marketingConfig?.syncFrequency && marketingConfig?.generateReportsFrequency) {
            body = {
                ...body,
                configuration: {
                    // approver: marketingConfig?.approver,
                    syncFrequency: marketingConfig.syncFrequency,
                    needsApproval: Boolean(marketingConfig.approver),
                    collaboratorIds: marketingConfig.collaboratorIds, // ldb ids of the collaborators
                    generateReportsFrequency: marketingConfig.generateReportsFrequency, // optional
                },
            };
        }
        console.log("body", body);
        if (aiTeammateId) {
            dispatch(workforceActions.updateAiTeammate(body, aiTeammateId));
            return;
        }
        dispatch(workforceActions.createAiTeammate(body));
    };

    const handleRetry = () => {
        const body = {
            stepId: selectedStep?._id,
        };

        dispatch(workforceActions.retryTaskStep(body));
    };

    const handleSubmitFeedback = () => {
        const body = {
            stepId: selectedStep?._id,
            feedback: feedback,
        };

        dispatch(workforceActions.giveTaskStepFeedback(body));
    };

    const handleRoleChange = (value: string) => {
        const aiRoleActivities = activitiesStructure?.[value]?.steps?.map((step) => {
            return {
                ...step,
                shouldApprove: false,
                shouldRepeat: false,
                frequency: "",
                dateTime: "",
                approver: "",
                controlAction: "",
            };
        });

        setRole(value);
        setAiStepValues(aiRoleActivities);
    };

    const handleChannelChange = (channelValue: string) => {
        const communicationChannelsCopy = [...communicationChannels];
        const channelIndex = communicationChannels?.findIndex(
            (channel) => channel === channelValue
        );
        const isEmailPresent = Boolean(
            communicationChannels?.find((channel) => channel === "email")
        );
        const isAutoGeneratePresent = Boolean(
            communicationChannels?.find((channel) => channel === "auto_generate_email")
        );

        if (isEmailPresent && channelValue === "auto_generate_email") {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== "email"
            );

            return setCommunicationChannels(sortedUniq([...upddatedArray, channelValue]));
        }

        if (isAutoGeneratePresent && channelValue === "email") {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== "auto_generate_email"
            );

            return setCommunicationChannels(sortedUniq([...upddatedArray, channelValue]));
        }

        if (channelIndex !== -1) {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== channelValue
            );
            setCommunicationChannels(sortedUniq(upddatedArray));
        } else {
            setCommunicationChannels((prev) => sortedUniq([...prev, channelValue]));
        }
    };

    const handleAiStepsChange = (idx: number, name: string, value: any) => {
        const aiStepValuesCopy = [...aiStepValues];
        aiStepValuesCopy[idx][name] = value;

        if (name === "approver") {
            aiStepValuesCopy[idx]["approverName"] = "";
        }

        setAiStepValues(aiStepValuesCopy);
    };

    const handleTest = () => {
        const body = {
            agentType: role,
            message: testEmail?.body,
            subject: testEmail?.subject,
        };

        dispatch(workforceActions.testAiAgent(body));
    };

    const handleSelectCampaignSource = (source: { [key: string]: any }) => {
        const selectedSource = [...selectedMarketingConnection];
        const findSource = selectedSource.find(
            (item) => item?.name?.toLowerCase() === source?.name?.toLowerCase()
        );

        if (findSource) {
            setSelectedMarketingConnection(
                selectedSource?.filter(
                    (item) => item?.name?.toLowerCase() !== source?.name?.toLowerCase()
                )
            );
        } else {
            setSelectedMarketingConnection((prev) => [...prev, source]);
        }
    };

    const handleSignup = (e) => {
        e.preventDefault();
        const body = {
            email: signupInfo.email,
            password: signupInfo.password,
        };
    };

    const handleCreateSpsCommerceConnection = (e) => {
        e.preventDefault();
        const body = {
            username: signupInfo.email,
            password: signupInfo.password,
        };

        dispatch(workforceActions.createSpsCommerceConnection(body));
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target;

        setSignupInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const taskTableHeader = [
        { title: "Products", widthClass: "w-[41%]" },
        { title: "Current inventory", widthClass: "w-[31.7%]" },
        { title: "Status", widthClass: "w-[27%]" },
    ];

    const taskTableBody = (
        testTask?.state?.inventory_check_result?.length > 0
            ? testTask?.state?.inventory_check_result
            : testTask?.state?.order_info?.products
    )?.map((task, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                >
                    <div className="flex items-center space-x-3">
                        <div className="w-8 h-8 rounded bg-slate-200 flex items-center justify-center shrink-0">
                            <i className="ri-box-2-fill text-[17px] text-slate-500"></i>
                        </div>

                        <div className=" w-full">
                            <p className="text-sm">
                                {task?.inventory?.productName ||
                                    testTask?.state?.order_info?.products?.[idx]?.name ||
                                    "N/A"}
                            </p>

                            <p className="text-sm text-slate-500">{task?.inventory?.sku}</p>
                        </div>
                    </div>
                </div>
            ),
            // cellClickAction: () => navigate(`/dashboard/task/1`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                >
                    <span>
                        {task?.currentStockLevel ||
                            task?.inventory?.productStockDetails?.stockLevel}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                    <div className="flex items-center space-x-2">
                        <span
                            className={`w-2 h-2 rounded-full ${
                                task?.currentStockLevel >= task?.currentOrderQty ||
                                task?.stockAvailable
                                    ? "bg-[#22C55E]"
                                    : "bg-[#EAB308]"
                            }`}
                        ></span>

                        <span>
                            {task?.currentStockLevel >= task?.currentOrderQty ||
                            task?.stockAvailable
                                ? "in stock"
                                : "out of stock"}
                        </span>
                    </div>
                </div>
            ),
        },
    ]);

    const {} = useChannel(
        `agent_tasks_${profile?.user?.parentId || profile?.user?._id}`,
        (taskData) => {
            // const isStep = taskData?.name?.split("_")?.length === 2;

            if (taskData?.data?.task?._id === (testTaskId || taskId)) {
                const singleTaskCopy = { ...testTask };
                let singleTaskStepRanCopy = [...singleTaskCopy?.stepsRan];
                const decompressed = pako.inflate(taskData?.data?.step?.data, { to: "string" });
                const taskStep = JSON.parse(decompressed);

                const updatedStepIndex = singleTaskStepRanCopy?.findIndex(
                    (stepRan) => stepRan?.key === taskStep?.key
                );
                singleTaskCopy.state = taskData?.data?.task?.state;

                if (updatedStepIndex !== -1) {
                    singleTaskStepRanCopy[updatedStepIndex] = taskStep;
                } else {
                    singleTaskStepRanCopy = [...singleTaskStepRanCopy, taskStep];
                }
                singleTaskCopy.stepsRan = singleTaskStepRanCopy;

                setTestTask({
                    ...singleTaskCopy,

                    stepsRan: uniqBy(singleTaskStepRanCopy, "key"),
                });
                if (taskStep?.key === "order_email_send" || updatedStepIndex === 5) {
                    setIsProcessing(false);
                }
            }
        }
    );

    const stepsSuccessfullyRan = testTask?.stepsRan?.filter(
        (step) => step?.status?.toLowerCase() === "completed"
    );

    const leavePage = () => {
        if (aiTeammateId) {
            saveToStorage(
                "connectedRedirect",
                `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`
            );
        } else {
            saveToStorage(
                "connectedRedirect",
                taskId
                    ? `/dashboard/workforce/create-ai-teammate?taskId=${taskId}`
                    : `/dashboard/workforce/create-ai-teammate`
            );
        }
    };

    useEffect(() => {
        if (taskInfo?._id) {
            setTestTask(taskInfo);
        }
        removeFromStorage("connectedRedirect");
        // return () => {
        //     removeFromStorage("connectedRedirect");
        // };
    }, [taskInfo?._id]);
    console.log("ediSource", ediSource);
    useEffect(() => {
        if (createSpsCommerceConnectionSuccess) {
            console.log("createSpsCommerceConnectionSuccess", createSpsCommerceConnectionSuccess);
            const source = ediSource?.find((item) => item?.key === "spsCommerce");
            setSpsSigninModal(false);
            setSignupInfo({
                email: "",
                password: "",
            });

            setEdiSource((prev) => {
                return prev?.map((item) => {
                    if (item?.key === "spsCommerce") {
                        return {
                            ...item,
                            connection: createSpsCommerceConnectionSuccess,
                        };
                    }

                    return item;
                });
            });
            handleSelectCampaignSource(source);
            // setSelectedMarketingConnection(
            //     ediSource?.filter((item) => item?.key !== "spsCommerce")
            // );
            dispatch(workforceActions.resetCreateSpsCommerceConnectionSuccess());
        }
    }, [createSpsCommerceConnectionSuccess, ediSource]);

    useEffect(() => {
        if (spsCommerceConnected) {
            console.log("spsCommerceConnected--->>", spsCommerceConnected);
            setEdiSource((prev) => [
                ...prev?.filter((item) => item?.key !== "spsCommerce"),
                {
                    name: "SPS Commerce",
                    connection: spsCommerceConnected,
                    key: "spsCommerce",
                },
            ]);
        }
    }, [spsCommerceConnected]);

    useEffect(() => {
        if (singleAiTeammate?._id) {
            const singleAiTeammatecommunicationChannels = singleAiTeammate?.communicationChannel;
            const result = [];
            const agentAtWork = aiAgents?.find(
                (agent) => agent?.type === singleAiTeammate?.agentType
            );

            const allSteps = agentAtWork?.steps?.map((step) => {
                const stepInSingleAiTeammate = singleAiTeammate?.steps?.find(
                    (singleStep) => step?.key === singleStep?.key
                );

                return stepInSingleAiTeammate
                    ? {
                          ...stepInSingleAiTeammate,
                          title: step?.title,
                          description: step?.description,
                      }
                    : {
                          ...step,
                      };
            });

            const teammateSteps = allSteps?.map((step, index) => {
                return {
                    ...step,
                    repeatable: agentAtWork?.steps?.[index]?.repeatable,
                    approvalPossible: agentAtWork?.steps?.[index]?.approvalPossible,
                    shouldApprove: step?.approvalPossible,
                    shouldRepeat: step?.repeatable,
                    frequency: "",
                    dateTime: "",
                    approver: step?.approvalId?._id,
                    approverName:
                        step?.approvalId?.fullName ||
                        step?.approvalId?.firstName ||
                        step?.approvalId?.lastName,
                    controlAction: step?.actions?.find((act) => {
                        return act?.selected;
                    })?.name,
                    actions: step?.actions,
                };
            });

            if (singleAiTeammatecommunicationChannels.email) result.push("email");
            if (singleAiTeammatecommunicationChannels.inApp) result.push("in_app");
            if (singleAiTeammatecommunicationChannels.autoGenerateEmail)
                result.push("auto_generate_email");

            setAiNameValue(singleAiTeammate?.teamMateName);
            setRole(singleAiTeammate?.agentType);
            setCommunicationChannels(result);
            setAvatar(singleAiTeammate?.avatar);
            setEmailClientSocialId(
                singleAiTeammate?.communicationChannel?.emailChannels?.[0]?.socialId || ""
            );
            setAiStepValues(teammateSteps);

            setMarketingConfig({
                approver: singleAiTeammate?.configuration?.approver || "",
                collaboratorIds: singleAiTeammate?.configuration?.collaboratorIds,
                generateReportsFrequency: singleAiTeammate?.configuration?.generateReportsFrequency,
                needsApproval: singleAiTeammate?.configuration?.needsApproval,
                syncFrequency: singleAiTeammate?.configuration?.syncFrequency,
            });
            const socialConnections = [...marketingConnections];
            const selected = socialConnections?.filter((item) =>
                Boolean(
                    singleAiTeammate?.communicationChannel?.socialChannels?.find(
                        (channel) => channel?.socialId === item?.connection?._id
                    )
                )
            );
            const ediSourceCopy = [...ediSource];

            const spsCommerceSource = ediSourceCopy?.filter((item) =>
                Boolean(
                    singleAiTeammate?.communicationChannel?.socialChannels?.find(
                        (channel) => (channel?.socialId || channel?._id) === item?.connection?._id
                    )
                )
            );

            setSelectedMarketingConnection(
                singleAiTeammate?.agentType?.toLowerCase()?.includes("edi")
                    ? spsCommerceSource
                    : selected
            );
        }

        if (savedData?.role || savedData?.aiNameValue) {
            removeFromStorage("connectedRedirect");

            setAiNameValue(savedData?.aiNameValue);
            setRole(savedData?.role);
            setCommunicationChannels(savedData?.communicationChannels);
            setAvatar(savedData?.avatar);
            setEmailClientSocialId(savedData?.emailClientSocialId || "");
            setAiStepValues(savedData?.aiStepValues);
            setShowTestOutput(savedData?.showTestOutput);
            setMarketingConfig(savedData?.marketingConfig);
            setSelectedMarketingConnection(savedData?.selectedMarketingConnection);

            removeFromStorage("createAiAgentForm");
        }
    }, [singleAiTeammate?._id, aiAgents, savedData?.role, ediSource]);
    console.log("singleAiTeammate", singleAiTeammate);
    useEffect(() => {
        if (Boolean(createAiTeammateSuccess)) {
            removeFromStorage("connectedRedirect");
            dispatch(workforceActions.resetCreateAiTeammateSuccess());
            navigate(`/dashboard/workforce/create-ai-teammate`, {
                replace: true,
                state: null,
            });
            navigate(`/dashboard/workforce`, { state: { internalNavigation: true } });
        }
    }, [createAiTeammateSuccess]);

    useEffect(() => {
        if (Boolean(updateAiTeammateSuccess)) {
            removeFromStorage("connectedRedirect");
            dispatch(workforceActions.resetUpdateAiTeammateSuccess());
            if (aiTeammateId) {
                navigate(`/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`, {
                    replace: true,
                    state: null,
                });
            }

            navigate(`/dashboard/workforce`, { state: { internalNavigation: true } });
        }
    }, [dispatch, navigate, updateAiTeammateSuccess, aiTeammateId]);

    useEffect(() => {
        if (Boolean(testAiTeammateSuccess)) {
            setTestTaskId(testAiTeammateSuccess?._id);

            setTestEmailModal(false);
            setShowTestOutput(true);

            setTestEmail({
                body: "",
                subject: "",
            });

            if (aiTeammateId) {
                navigate(
                    `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}?taskId=${testAiTeammateSuccess?._id}`,
                    {
                        replace: true,
                        state: {
                            role,
                            avatar,
                            aiNameValue,
                            aiStepValues,
                            communicationChannels,
                            emailClientSocialId,
                            showTestOutput: true,
                            internalNavigation: true,
                        },
                    }
                );
            } else {
                navigate(
                    `/dashboard/workforce/create-ai-teammate?taskId=${testAiTeammateSuccess?._id}`,
                    {
                        replace: true,
                        state: {
                            role,
                            avatar,
                            aiNameValue,
                            aiStepValues,
                            communicationChannels,
                            emailClientSocialId,
                            showTestOutput: true,
                            internalNavigation: true,
                        },
                    }
                );
            }
        }
        dispatch(workforceActions.resetTestAiAgentSuccess());
    }, [
        testAiTeammateSuccess,
        role,
        avatar,
        aiNameValue,
        aiStepValues,
        communicationChannels?.[0],
        emailClientSocialId,
        showTestOutput,
        aiTeammateId,
    ]);

    useEffect(() => {
        if (Boolean(retryTaskStepSuccess)) {
            setConfirmRetry(false);
            setIsProcessing(true);
            toast.custom((t) => (
                <CustomToast t={t} message={"Re-run successful"} type={"success"} />
            ));
            dispatch(workforceActions.resetRetryTaskStepSuccess());
        }
    }, [dispatch, retryTaskStepSuccess]);

    useEffect(() => {
        if (Boolean(giveTaskStepFeedbackSuccess)) {
            setFeedback("");
            setFeedbackModal(false);
            setFeedbackSuccessModal(true);

            dispatch(workforceActions.resetGiveeTaskStepFeedbackSuccess());
        }
    }, [dispatch, giveTaskStepFeedbackSuccess]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                avatarModalRef.current &&
                !avatarModalRef.current.contains(event.target as Node) &&
                openAvatarModal
            ) {
                setOpenAvatarModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [avatarModalRef, openAvatarModal]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    return (
        <PageFrame isLoading={isFetching}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    handleCreateTeammate();
                }}
                className="bg-white rounded-lg border border-n-20 relative"
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => {
                                removeFromStorage("connectedRedirect");
                                // if (aiTeammateId) {
                                //     navigate(
                                //         `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`,
                                //         {
                                //             replace: true,
                                //             state: null,
                                //         }
                                //     );
                                // } else {
                                //     navigate(
                                //         `/dashboard/workforce/create-ai-teammate?taskId=${taskId}`,
                                //         {
                                //             replace: true,
                                //             state: null,
                                //         }
                                //     );
                                // }

                                goBack();
                            }}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {aiTeammateId ? "Edit" : "Create"} your AI teammate
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => {
                                removeFromStorage("connectedRedirect");
                                // if (aiTeammateId) {
                                //     navigate(
                                //         `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`,
                                //         {
                                //             replace: true,
                                //             state: null,
                                //         }
                                //     );
                                // } else {
                                //     navigate(
                                //         `/dashboard/workforce/create-ai-teammate?taskId=${taskId}`,
                                //         {
                                //             replace: true,
                                //             state: null,
                                //         }
                                //     );
                                // }

                                goBack();
                            }}
                        />
                        <Button
                            btnText={`${aiTeammateId ? "Edit" : "Create"} AI teammate`}
                            btnPermission={[
                                AccountPermissions.all_access,
                                AccountPermissions.create_ai_teammate,
                            ]}
                            type="submit"
                            isLoading={creatingAiTeammate || updatingAiTeammate}
                            onClick={() => console.log("clickkk")}
                            btnClassname="!px-4 !py-2 !w-fit"
                            id="create-teammate"
                        />
                    </div>
                </div>

                <div
                    className={`p-8 flex relative ${
                        role !== "marketingCampaign" ? "justify-between" : "justify-center"
                    }`}
                >
                    <div className="w-[50%]">
                        <div className="relative w-fit mb-[30px] shadow-[0px_20px_24px_-4px_#10182814]">
                            {uploadingAvatar ? (
                                <div className="w-14 h-14 rounded-[10px] border flex justify-center items-center">
                                    <Loader size={6} />
                                </div>
                            ) : (
                                <img
                                    src={avatar || aiAvatarArr[0]}
                                    alt={`avatar`}
                                    className={`w-14 h-14`}
                                />
                            )}

                            <div
                                onClick={() => setOpenAvatarModal(true)}
                                className="w-6 h-6 rounded-full bg-p-50 border-[3px] border-white flex justify-center items-center absolute bottom-[-9px] right-[-12px] cursor-pointer"
                            >
                                <i className="ri-pencil-fill text-white text-[14px]"></i>
                            </div>

                            {openAvatarModal && (
                                <div
                                    ref={avatarModalRef}
                                    className="w-[464px] h-[180px] bg-white rounded-lg absolute right-[-465px] top-[52px] border border-slate-100 shadow-[0px_20px_24px_-4px_#10182814;]"
                                >
                                    <div className="px-4 py-2.5 flex items-center justify-between border-b border-slate-200">
                                        <span className="text-sm font-rocGroteskMedium">
                                            Change agent avatar
                                        </span>
                                        <i
                                            onClick={() => setOpenAvatarModal(false)}
                                            className="ri-close-fill text-2xl cursor-pointer"
                                        ></i>
                                    </div>
                                    <div className="px-4 py-3 grid grid-cols-11 gap-2">
                                        {aiAvatarArr?.map((avatarUrl, idx) => {
                                            return (
                                                <div key={idx} onClick={() => setAvatar(avatarUrl)}>
                                                    <img
                                                        src={avatarUrl}
                                                        alt={`avatar_${idx}`}
                                                        className={`w-8 h-8 cursor-pointer hover:border-[1.5px] rounded-[10px] border-black ${
                                                            avatar === avatarUrl &&
                                                            " border-[1.5px] "
                                                        }`}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <div className="relative">
                                            <div
                                                onClick={() => {
                                                    const elem =
                                                        document.getElementById("uploadAiAvatar");
                                                    elem.click();
                                                }}
                                                className="w-8 h-8 border border-slate-300 rounded-lg bg-slate-200 flex justify-center items-center cursor-pointer"
                                            >
                                                <i className="ri-image-add-fill text-[18px]"></i>
                                            </div>
                                            <input
                                                id="uploadAiAvatar"
                                                type="file"
                                                accept="image/*"
                                                className="absolute top-0 w-8 h-8 hidden"
                                                onChange={(evt) => {
                                                    handleUpload(
                                                        evt.target.files[0].name,
                                                        evt.target.files[0]
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mb-6">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">
                                    Name your AI teammate <span className="text-r-50">*</span>
                                </p>
                            </div>
                            <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                <input
                                    type="text"
                                    placeholder="Enter your AI teammate name"
                                    className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                    name="aiName"
                                    value={aiNameValue}
                                    onChange={(e) => setAiNameValue(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex space-x-2 mb-8 overflow-x-auto">
                            {AiNameSuggestions?.map((aiName, idx) => (
                                <span
                                    key={aiName + String(idx)}
                                    className="text-sm font-rocGroteskMedium px-2 py-[2px] h-6 bg-slate-100 rounded cursor-pointer"
                                    onClick={() => setAiNameValue(aiName)}
                                >
                                    {aiName}
                                </span>
                            ))}

                            {/* <span className="p-1 h-6 bg-slate-100 rounded flex items-center justify-center">
                                <i className="ri-restart-line text-base "></i>
                            </span> */}
                        </div>

                        <div className="">
                            <div>
                                <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#FEF3C7] shrink-0">
                                    <i className="ri-slash-commands-2 text-[#D97706] text-[13px]"></i>
                                </div>
                            </div>

                            <div
                                className={`w-[calc(100%-12px)] ${
                                    role && "border-l"
                                } border-slate-200 pl-4 ml-3 pb-2`}
                            >
                                <div className="mt-[-22px]">
                                    <h5 className="text-base font-rocGroteskMedium mb-3">
                                        Choose AI teammate role
                                    </h5>
                                    <div className="mb-2">
                                        <SelectInput
                                            value={role}
                                            name="role"
                                            placeholder={"Select AI teammate role"}
                                            handleChange={(name, value) => {
                                                if (!aiTeammateId) {
                                                    handleRoleChange(value);
                                                }
                                            }}
                                            isRequired={true}
                                            disabled={Boolean(aiTeammateId)}
                                            searchLoading={isFetchingAiAgent}
                                            dropdownOptions={[
                                                ...aiAgents?.map((item) => {
                                                    return {
                                                        label: camelCaseToRegularCase(item?.type),
                                                        value: item?.type,
                                                    };
                                                }),
                                            ]}
                                        />
                                    </div>
                                    {/* <div className="p-3 bg-slate-50 border border-slate-200 rounded mb-6">
                                        <p className="text-sm text-slate-500 ">
                                            This gives the AI teammate the ability to detect when
                                            there is a potential order.
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {role && role === "orderProcessing" && (
                            <div>
                                <div>
                                    <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#FEF3C7] shrink-0">
                                        <i className="ri-flashlight-line text-[#D97706] text-[13px]"></i>
                                    </div>
                                </div>

                                <div
                                    className={`w-[calc(100%-12px)] ${
                                        communicationChannels?.length > 0 && "border-l"
                                    } border-slate-200 pl-4 ml-3 pb-2`}
                                >
                                    <div className="mt-[-22px]">
                                        <h5 className="text-base font-rocGroteskMedium mb-3">
                                            Select the source - action trigger
                                        </h5>

                                        <div className="mb-6 p-4 border border-slate-200 rounded-lg ">
                                            <div className="flex items-center justify-between pb-4 border-b border-slate-200">
                                                <div>
                                                    <div className="flex items-center justify-between mb-4">
                                                        <div className="flex items-center space-x-3">
                                                            <Checkbox
                                                                name="email"
                                                                isChecked={Boolean(
                                                                    communicationChannels?.find(
                                                                        (channel) =>
                                                                            channel === "email"
                                                                    )
                                                                )}
                                                                required={
                                                                    !Boolean(
                                                                        communicationChannels.length >
                                                                            0
                                                                    )
                                                                }
                                                                onChange={(evt) => {
                                                                    handleChannelChange("email");
                                                                }}
                                                                className={
                                                                    "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                                }
                                                            />

                                                            <span
                                                                className={`text-sm font-rocGroteskMedium ${
                                                                    !Boolean(
                                                                        communicationChannels?.find(
                                                                            (channel) =>
                                                                                channel === "email"
                                                                        )
                                                                    ) && "text-slate-500"
                                                                }`}
                                                            >
                                                                Email
                                                            </span>
                                                        </div>

                                                        {Boolean(
                                                            communicationChannels?.find(
                                                                (channel) => channel === "email"
                                                            )
                                                        ) &&
                                                            (!emailClientSocialId ? (
                                                                <Button
                                                                    btnText="Connect your email account"
                                                                    btnPermission={[
                                                                        AccountPermissions.all_access,
                                                                        AccountPermissions.manage_integrations,
                                                                        AccountPermissions.create_ai_teammate,
                                                                    ]}
                                                                    btnClassname="!px-4 !py-2 !bg-slate-50 !w-fit"
                                                                    btnTextClassName="!text-b-55"
                                                                    disabled={false}
                                                                    onClick={() =>
                                                                        setEmailClientModal(true)
                                                                    }
                                                                    icon={
                                                                        <i className="ri-add-fill text-b-55"></i>
                                                                    }
                                                                />
                                                            ) : (
                                                                <div className="px-4 py-2 w-fit space-x-2 flex items-center">
                                                                    <span className="text-sm font-rocGroteskMedium">
                                                                        Connected
                                                                    </span>{" "}
                                                                    <i className="ri-checkbox-circle-fill text-carribean-green"></i>
                                                                    <i
                                                                        className="ri-edit-2-fill text-slate-600 cursor-pointer"
                                                                        onClick={() =>
                                                                            setEmailClientModal(
                                                                                true
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </div>
                                                            ))}
                                                    </div>

                                                    <div className="flex items-center space-x-3">
                                                        <Checkbox
                                                            name="auto_email"
                                                            isChecked={Boolean(
                                                                communicationChannels?.find(
                                                                    (channel) =>
                                                                        channel ===
                                                                        "auto_generate_email"
                                                                )
                                                            )}
                                                            required={
                                                                !Boolean(
                                                                    communicationChannels.length > 0
                                                                )
                                                            }
                                                            onChange={(evt) => {
                                                                handleChannelChange(
                                                                    "auto_generate_email"
                                                                );
                                                            }}
                                                            className={
                                                                "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                            }
                                                        />
                                                        <span
                                                            className={`text-sm font-rocGroteskMedium ${
                                                                !Boolean(
                                                                    communicationChannels?.find(
                                                                        (channel) =>
                                                                            channel ===
                                                                            "auto_generate_email"
                                                                    )
                                                                ) && "text-slate-500"
                                                            }`}
                                                        >
                                                            Let Synth automatically generate an
                                                            email address for this AI teammate
                                                        </span>
                                                    </div>

                                                    {Boolean(
                                                        communicationChannels?.find(
                                                            (channel) =>
                                                                channel === "auto_generate_email"
                                                        )
                                                    ) && (
                                                        <div className="text-xs text-slate-500 font-rocGroteskMedium bg-slate-100 rounded px-1.5 py-1 mt-2 w-fit">
                                                            <span>Email:</span>{" "}
                                                            <span>
                                                                {`${
                                                                    aiNameValue || "astra"
                                                                }_6734f9d71527d700be445537@agents.synthally.com`}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="p-3 bg-slate-50 border border-slate-200 mt-4 rounded">
                                                <p className="text-sm text-slate-500 ">
                                                    This gives the AI teammate the ability to detect
                                                    when there is a potential order.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {role && role === "marketingCampaign" && (
                            <div>
                                <div>
                                    <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#FEF3C7] shrink-0">
                                        <i className="ri-flashlight-line text-[#D97706] text-[13px]"></i>
                                    </div>
                                </div>

                                <div
                                    className={`w-[calc(100%-12px)] border-l border-slate-200 pl-4 ml-3 pb-2`}
                                >
                                    <div className="mt-[-22px]">
                                        <h5 className="text-base font-rocGroteskMedium mb-3">
                                            Select the campaign data source - action trigger
                                        </h5>

                                        <div className="mb-6 p-4 border border-slate-200 rounded-lg ">
                                            <div className="flex items-center justify-between">
                                                <div className="w-full space-y-4">
                                                    {marketingConnections?.map(
                                                        (marketingConnection) => {
                                                            const isSelected = [
                                                                ...selectedMarketingConnection,
                                                            ]?.find(
                                                                (item) =>
                                                                    item.name?.toLowerCase() ===
                                                                    marketingConnection.name?.toLowerCase()
                                                            );

                                                            return (
                                                                <div
                                                                    key={marketingConnection.name}
                                                                    className="flex items-center justify-between"
                                                                >
                                                                    <div className="flex items-center space-x-3">
                                                                        <Checkbox
                                                                            name={marketingConnection.name?.toLowerCase()}
                                                                            isChecked={Boolean(
                                                                                isSelected
                                                                            )}
                                                                            required={
                                                                                selectedMarketingConnection?.length ===
                                                                                0
                                                                            }
                                                                            disabled={
                                                                                !marketingConnection.connection
                                                                            }
                                                                            onChange={(evt) => {
                                                                                // handleChannelChange("email");
                                                                                if (
                                                                                    marketingConnection.connection
                                                                                ) {
                                                                                    handleSelectCampaignSource(
                                                                                        marketingConnection
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className={
                                                                                "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                                            }
                                                                        />

                                                                        <span
                                                                            className={`text-sm font-rocGroteskMedium `}
                                                                        >
                                                                            {
                                                                                marketingConnection.name
                                                                            }
                                                                        </span>
                                                                    </div>

                                                                    {!marketingConnection.connection ? (
                                                                        <Button
                                                                            btnText="Connect"
                                                                            btnPermission={[
                                                                                AccountPermissions.all_access,
                                                                                AccountPermissions.manage_integrations,
                                                                                AccountPermissions.create_ai_teammate,
                                                                            ]}
                                                                            btnClassname="!px-4 !py-2 !bg-slate-50 !w-fit"
                                                                            btnTextClassName="!text-b-55"
                                                                            disabled={false}
                                                                            onClick={() => {
                                                                                leavePage();

                                                                                saveToStorage(
                                                                                    "createAiAgentForm",
                                                                                    {
                                                                                        role,
                                                                                        avatar,
                                                                                        aiNameValue,
                                                                                        aiStepValues,
                                                                                        communicationChannels,
                                                                                        emailClientSocialId,
                                                                                        showTestOutput,
                                                                                        marketingConfig,
                                                                                        selectedMarketingConnection,
                                                                                    },
                                                                                    true
                                                                                );

                                                                                navigate(
                                                                                    `/dashboard/integrations?social-key=${marketingConnection?.key}`
                                                                                );
                                                                            }}
                                                                            icon={
                                                                                <i className="ri-add-fill text-b-55"></i>
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <div className="px-4 py-2 w-fit space-x-2 flex items-center h-10 rounded bg-slate-50">
                                                                            <i className="ri-checkbox-circle-fill text-g-60"></i>
                                                                            <span className="text-sm font-rocGroteskMedium text-g-60">
                                                                                Connected
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {role && role === "EDIAssistant" && (
                            <div>
                                <div>
                                    <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#FEF3C7] shrink-0">
                                        <i className="ri-flashlight-line text-[#D97706] text-[13px]"></i>
                                    </div>
                                </div>

                                <div
                                    className={`w-[calc(100%-12px)] ${
                                        communicationChannels?.length > 0 && "border-l"
                                    } border-slate-200 pl-4 ml-3 pb-2`}
                                >
                                    <div className="mt-[-22px]">
                                        <h5 className="text-base font-rocGroteskMedium mb-3">
                                            Select the source - action trigger
                                        </h5>

                                        <div className="mb-6 p-4 border border-slate-200 rounded-lg ">
                                            <div className="flex items-center justify-between pb-4 border-b border-slate-200">
                                                <div className="w-full space-y-4">
                                                    {ediSource?.map((marketingConnection) => {
                                                        const isSelected = [
                                                            ...selectedMarketingConnection,
                                                        ]?.find(
                                                            (item) =>
                                                                item.name?.toLowerCase() ===
                                                                marketingConnection.name?.toLowerCase()
                                                        );

                                                        return (
                                                            <div
                                                                key={marketingConnection.name}
                                                                className="flex items-center justify-between"
                                                            >
                                                                <div className="flex items-center space-x-3">
                                                                    <Checkbox
                                                                        name={marketingConnection.name?.toLowerCase()}
                                                                        isChecked={Boolean(
                                                                            isSelected
                                                                        )}
                                                                        required={
                                                                            selectedMarketingConnection?.length ===
                                                                            0
                                                                        }
                                                                        disabled={
                                                                            !marketingConnection.connection
                                                                        }
                                                                        onChange={(evt) => {
                                                                            // handleChannelChange("email");
                                                                            if (
                                                                                marketingConnection.connection
                                                                            ) {
                                                                                handleSelectCampaignSource(
                                                                                    marketingConnection
                                                                                );
                                                                            }
                                                                        }}
                                                                        className={
                                                                            "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                                        }
                                                                    />

                                                                    <span
                                                                        className={`text-sm font-rocGroteskMedium `}
                                                                    >
                                                                        {marketingConnection.name}
                                                                    </span>
                                                                </div>

                                                                {!marketingConnection.connection ? (
                                                                    <Button
                                                                        btnText="Connect"
                                                                        btnClassname="!px-4 !py-2 !bg-slate-50 !w-fit"
                                                                        btnTextClassName="!text-b-55"
                                                                        disabled={false}
                                                                        onClick={() => {
                                                                            setSpsSigninModal(true);
                                                                        }}
                                                                        icon={
                                                                            <i className="ri-add-fill text-b-55"></i>
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div className="px-4 py-2 w-fit space-x-2 flex items-center h-10 rounded bg-slate-50">
                                                                        <i className="ri-checkbox-circle-fill text-g-60"></i>
                                                                        <span className="text-sm font-rocGroteskMedium text-g-60">
                                                                            Connected
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="p-3 bg-slate-50 border border-slate-200 mt-4 rounded">
                                                <p className="text-sm text-slate-500 ">
                                                    This gives the AI teammate the ability to detect
                                                    if there is a potential order that has been sent
                                                    to your SPS Commerce account.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {role && (
                            <div>
                                <div>
                                    <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#FEF3C7] shrink-0">
                                        <i className="ri-list-check-3 text-[#D97706] text-[13px]"></i>
                                    </div>
                                </div>

                                <div className="w-[calc(100%-12px)] pl-4 ml-3 pb-3">
                                    <div className="mt-[-22px]">
                                        <div className="mb-5">
                                            <h5 className="text-base font-rocGroteskMedium">
                                                AI Teammate's Customization
                                            </h5>
                                            <span className="block text-sm font-rocGroteskMedium text-slate-500">
                                                Set how you want this AI team-mate to work
                                            </span>
                                        </div>

                                        {role && role === "orderProcessing" && (
                                            <div className="space-y-3">
                                                <AiAgentActivities
                                                    structure={aiStepValues || []}
                                                    handleAiStepsChange={handleAiStepsChange}
                                                />
                                            </div>
                                        )}

                                        {role && role === "marketingCampaign" && (
                                            <MarketingConfiguration
                                                marketingConfig={marketingConfig}
                                                setMarketingConfig={setMarketingConfig}
                                            />
                                        )}

                                        {/* {role && role === "EDIAssistant" && (
                                            <div className="space-y-3">
                                                <EDIAgentActivities
                                                    structure={aiStepValues || []}
                                                    handleAiStepsChange={handleAiStepsChange}
                                                />
                                            </div>
                                        )} */}
                                        {role && role === "EDIAssistant" && (
                                            <MarketingConfiguration
                                                marketingConfig={marketingConfig}
                                                setMarketingConfig={setMarketingConfig}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {(aiNameValue || role) &&
                        (role === "orderProcessing" || role === "EDIAssistant") && (
                            <div className="w-[46%] font-rocGroteskMedium ">
                                <div className="rounded-[8px] border border-slate-200 p-4 bg-[#FBFBFB] shadow-[0px_4px_18px_0px_#0000000A] sticky top-[70px]">
                                    <div className="pb-3 border-b border-slate-200">
                                        <p className="text-base">Preview team-mate</p>
                                    </div>

                                    <div className="pt-4 relative min-h-[520px] pb-[80px]">
                                        {aiNameValue && (
                                            <div className="flex items-center justify-between bg-white border border-slate-100 rounded-[10px] p-3 mb-4">
                                                <div className="flex space-x-4">
                                                    {/* <div className="w-[42px] h-[42px] bg-p-50 rounded-lg flex items-center justify-center shadow-[0px_4px_8px_-4px_#10182808]"> */}
                                                    {/* <i className="ri-robot-fill text-white text-2xl"></i> */}
                                                    <img
                                                        src={avatar || aiAvatarArr[0]}
                                                        alt={`avatar`}
                                                        className={`w-[42px] h-[42px] rounded-lg`}
                                                    />
                                                    {/* </div> */}

                                                    <div>
                                                        <p className="text-xs">AI teammate</p>
                                                        <p className="text-base">
                                                            {aiNameValue} - Order Processing
                                                        </p>
                                                    </div>
                                                </div>

                                                {isProcessing ? (
                                                    <div className="flex items-end">
                                                        <Loader size={5} />
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center space-x-2 bg-[#D0F4EDB2] rounded-md py-1.5 px-2.5">
                                                        <span className="w-2 h-2 rounded-full bg-[#22C55E] " />
                                                        <span className="text-sm">Active</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div className="flex items-center justify-between mb-6 space-x-4">
                                            {communicationChannels?.[0] && (
                                                <div className="w-full flex items-center justify-between bg-white border border-slate-100 rounded-[10px] p-3">
                                                    <div className="flex space-x-4">
                                                        <div>
                                                            <p className="text-xs">Trigger</p>
                                                            {
                                                                <p className="text-base capitalize">
                                                                    {role === "EDIAssistant"
                                                                        ? "EDI Agent"
                                                                        : communicationChannels?.[0]?.includes(
                                                                              "auto"
                                                                          )
                                                                        ? "Synth email"
                                                                        : communicationChannels?.[0]}
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-200">
                                                        <i className="ri-flashlight-line text-[18px]"></i>
                                                    </div>
                                                </div>
                                            )}

                                            {/* <div className="w-1/2 flex items-center justify-between bg-white border border-slate-100 rounded-[10px] p-3">
                                            <div className="flex space-x-4">
                                                <div>
                                                    <p className="text-xs">
                                                        Communication channels
                                                    </p>
                                                    <p className="text-base">Email, Synth, Teams</p>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-200">
                                                <i className="ri-chat-new-line text-[18px]"></i>
                                            </div>
                                        </div> */}
                                        </div>

                                        <div className="flex items-center justify-between mb-6">
                                            <hr className="w-[35%]" />
                                            <span className="text-sm text-slate-500 whitespace-nowrap">
                                                AI team-mate workflow {showTestOutput && "output"}
                                            </span>
                                            <hr className="w-[35%]" />
                                        </div>

                                        {/* {role === "EDIAssistant" && (
                                            <div className="flex flex-col items-center justify-center">
                                                {ediSteps?.map((step, idx) => {
                                                    return (
                                                        <div
                                                            key={step?.key}
                                                            className="flex flex-col justify-center items-center"
                                                        >
                                                            <div className=" max-w-[430px] bg-white w-fit border border-slate-200 rounded-lg flex items-center p-3 space-x-2">
                                                                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#FEF3C7] shrink-0">
                                                                    <i className="ri-flashlight-line text-[13px] text-[#D97706]"></i>
                                                                </div>

                                                                <p className="text-xs">
                                                                    {step?.description}
                                                                </p>

                                                                {step?.user && (
                                                                    <div className="flex items-center justify-center w-4 h-4 rounded-full bg-slate-600 shrink-0">
                                                                        <i className="ri-user-line text-[9px] text-white"></i>
                                                                    </div>
                                                                )}
                                                                {step?.warning && (
                                                                    <div className="flex items-center justify-center w-4 h-4 rounded-full bg-[#000] shrink-0">
                                                                        <i className="ri-alert-line text-[9px] text-[#FFB902]"></i>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {ediSteps?.length - 1 !== idx && (
                                                                <img
                                                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/arrow_down_cmoqw7.svg"
                                                                    alt="arrow down"
                                                                    height={36}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )} */}

                                        {!showTestOutput &&
                                            (aiNameValue || communicationChannels?.[0]) &&
                                            (role === "orderProcessing" ||
                                                role === "EDIAssistant") && (
                                                <div className="flex flex-col items-center justify-center">
                                                    {aiStepValues?.map((step, idx) => {
                                                        return (
                                                            <div
                                                                key={step?.key}
                                                                className="flex flex-col justify-center items-center"
                                                            >
                                                                <div className=" max-w-[430px] bg-white w-fit border border-slate-200 rounded-lg flex items-center p-3 space-x-2">
                                                                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#FEF3C7] shrink-0">
                                                                        <i className="ri-flashlight-line text-[13px] text-[#D97706]"></i>
                                                                    </div>

                                                                    <p className="text-xs">
                                                                        {step?.description}
                                                                    </p>

                                                                    {step?.approver && (
                                                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-slate-600 shrink-0">
                                                                            <i className="ri-user-line text-[9px] text-white"></i>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                {aiStepValues?.length - 1 !==
                                                                    idx && (
                                                                    <img
                                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1737392368/arrow_down_cmoqw7.svg"
                                                                        alt="arrow down"
                                                                        height={36}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}

                                                    {/* <div className="h-[50px] bg-white w-fit border border-slate-200 rounded-lg flex items-center p-3 space-x-2">
                                        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#FEF3C7]">
                                            <i className="ri-flashlight-line text-[13px] text-[#D97706]"></i>
                                        </div>

                                        <p className="text-xs">
                                            AI team-mate extracts order information
                                        </p>
                                    </div>

                                   
                                    <img
                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/arrow_down_cmoqw7.svg"
                                        alt="arrow down"
                                        height={36}
                                    />

                                    <div className="h-[50px] bg-white w-fit border border-slate-200 rounded-lg flex items-center p-3 space-x-2">
                                        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#FEF3C7]">
                                            <i className="ri-flashlight-line text-[13px] text-[#D97706]"></i>
                                        </div>

                                        <p className="text-xs">
                                            AI team-mate checks inventory to see if product is in
                                            stock
                                        </p>

                                        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-slate-600">
                                            <span className="material-symbols-outlined text-[9px] text-[#D97706]">
                                                warning
                                            </span>
                                        </div>
                                    </div> */}
                                                </div>
                                            )}

                                        {aiNameValue &&
                                            communicationChannels?.[0] &&
                                            role !== "EDIAssistant" && (
                                                <div className="w-full flex justify-center absolute bottom-0">
                                                    <div
                                                        onClick={() => {
                                                            if (!showTestOutput) {
                                                                setTestEmailModal(true);
                                                            } else {
                                                                const elem =
                                                                    document.getElementById(
                                                                        "create-teammate"
                                                                    );

                                                                elem?.click();
                                                            }
                                                        }}
                                                        className="h-[52px] bg-white w-fit border border-slate-200 rounded-[30px] flex items-center p-3 space-x-2 cursor-pointer"
                                                    >
                                                        <i className="ri-sparkling-2-line text-base bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>

                                                        <p className="text-sm">
                                                            {!showTestOutput
                                                                ? "Test AI team-mate"
                                                                : `${
                                                                      aiTeammateId
                                                                          ? "Edit"
                                                                          : "Create"
                                                                  } AI teammate`}
                                                        </p>

                                                        <i className="ri-arrow-right-up-line"></i>
                                                    </div>
                                                </div>
                                            )}

                                        {showTestOutput && (
                                            <div className="space-y-[14px]">
                                                {testTask?.steps?.map((step, idx) => {
                                                    return (
                                                        <div key={step?._id}>
                                                            <div className={` `}>
                                                                {idx === 0 && (
                                                                    <div className="flex items-center space-x-2">
                                                                        <div className="flex items-center justify-center w-[21px] h-[21px] rounded-full bg-slate-200">
                                                                            <i className="ri-flashlight-line text-[13px]"></i>
                                                                        </div>
                                                                        <span className="text-xs text-gm-35">
                                                                            {testTask?.stepsRan?.[
                                                                                idx
                                                                            ]?.data?.answer
                                                                                ? "Email order is received and detected"
                                                                                : "Email received and detected not too be an order"}
                                                                        </span>
                                                                    </div>
                                                                )}

                                                                {idx > 0 && (
                                                                    <details open>
                                                                        <summary className="flex justify-between  cursor-pointer">
                                                                            <div className="flex items-center space-x-2">
                                                                                <div className="flex items-center justify-center w-[21px] h-[21px] rounded-full bg-slate-200">
                                                                                    <i className="ri-flashlight-line text-[13px]"></i>
                                                                                </div>
                                                                                <span className="text-xs text-gm-35">
                                                                                    {
                                                                                        testTask
                                                                                            ?.stepsRan?.[
                                                                                            idx
                                                                                        ]?.title
                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <i className="ri-arrow-down-s-fill"></i>
                                                                        </summary>

                                                                        <div className="mt-2.5 border border-slate-200 rounded-[6px] shadow-boxShadow-12">
                                                                            {testTask?.stepsRan?.[
                                                                                idx
                                                                            ] ? (
                                                                                <div className="p-4 border-b border-slate-200">
                                                                                    <div>
                                                                                        <div className="flex items-center justify-between mb-2">
                                                                                            <span className="block text-base ">
                                                                                                {
                                                                                                    testTask
                                                                                                        ?.stepsRan?.[
                                                                                                        idx
                                                                                                    ]
                                                                                                        ?.title
                                                                                                }
                                                                                            </span>

                                                                                            <div className="flex items-center space-x-3">
                                                                                                {testTask?.stepsRan?.[
                                                                                                    idx
                                                                                                ]?.status?.toLowerCase() ===
                                                                                                "completed" ? (
                                                                                                    <div className="flex w-fit items-center space-x-2">
                                                                                                        <span className="w-2 h-2 rounded-full bg-[#22C55E]"></span>
                                                                                                        <span className="text-sm ">
                                                                                                            {
                                                                                                                testTask
                                                                                                                    ?.stepsRan?.[
                                                                                                                    idx
                                                                                                                ]
                                                                                                                    ?.status
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                ) : !testTask?.stepsRan?.[
                                                                                                      idx
                                                                                                  ]?.status
                                                                                                      ?.toLowerCase()
                                                                                                      ?.includes(
                                                                                                          "attention"
                                                                                                      ) ? (
                                                                                                    <div className="flex w-fit items-center space-x-2">
                                                                                                        <span className="w-2 h-2 rounded-full bg-[#EAB308]"></span>
                                                                                                        <span className="text-sm ">
                                                                                                            {
                                                                                                                testTask
                                                                                                                    ?.stepsRan?.[
                                                                                                                    idx
                                                                                                                ]
                                                                                                                    ?.status
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                ) : null}

                                                                                                {testTask?.stepsRan?.[
                                                                                                    idx
                                                                                                ]?.status
                                                                                                    ?.toLowerCase()
                                                                                                    ?.includes(
                                                                                                        "attention"
                                                                                                    ) && (
                                                                                                    <div
                                                                                                        className={`w-fit pl-1.5 pr-2 py-1 bg-[#FEE2E2] flex items-center space-x-1.5 h-[25px] rounded`}
                                                                                                    >
                                                                                                        <i
                                                                                                            className={`ri-information-line text-base text-[#DC2626]`}
                                                                                                        ></i>
                                                                                                        <span
                                                                                                            className={`text-xs text-[#DC2626]`}
                                                                                                        >
                                                                                                            User
                                                                                                            Attention
                                                                                                            Needed
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>

                                                                                        {testTask
                                                                                            ?.stepsRan?.[
                                                                                            idx
                                                                                        ]
                                                                                            ?.approved ? (
                                                                                            <div className="space-y-1.5">
                                                                                                {testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.key ===
                                                                                                    "order_check" && (
                                                                                                    <p className="text-sm">
                                                                                                        <span className="">
                                                                                                            Is
                                                                                                            an
                                                                                                            order:
                                                                                                        </span>{" "}
                                                                                                        <span className="text-slate-500">
                                                                                                            {testTask
                                                                                                                ?.stepsRan?.[
                                                                                                                idx
                                                                                                            ]
                                                                                                                ?.data
                                                                                                                ?.answer
                                                                                                                ? "Yes"
                                                                                                                : "No"}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                )}

                                                                                                {(testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.key ===
                                                                                                    "order_assistant" ||
                                                                                                    testTask
                                                                                                        ?.stepsRan?.[
                                                                                                        idx
                                                                                                    ]
                                                                                                        ?.key ===
                                                                                                        "order_create") && (
                                                                                                    <div className="space-y-1">
                                                                                                        <p className="text-sm ">
                                                                                                            <span className="">
                                                                                                                Products:
                                                                                                            </span>{" "}
                                                                                                            <span className="text-slate-500">
                                                                                                                {testTask
                                                                                                                    ?.state
                                                                                                                    ?.order_info
                                                                                                                    ?.products
                                                                                                                    ?.length >
                                                                                                                0
                                                                                                                    ? testTask?.state?.order_info?.products
                                                                                                                          ?.map(
                                                                                                                              (
                                                                                                                                  product
                                                                                                                              ) =>
                                                                                                                                  product?.name
                                                                                                                          )
                                                                                                                          ?.join(
                                                                                                                              ", "
                                                                                                                          )
                                                                                                                    : "N/A"}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                        <p className="text-sm ">
                                                                                                            <span className="">
                                                                                                                Customer:
                                                                                                            </span>{" "}
                                                                                                            <span className="text-slate-500">
                                                                                                                {testTask
                                                                                                                    ?.state
                                                                                                                    ?.order_info
                                                                                                                    ?.company ||
                                                                                                                    "N/A"}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                        <p className="text-sm ">
                                                                                                            <span className="">
                                                                                                                Delivery
                                                                                                                date:
                                                                                                            </span>{" "}
                                                                                                            <span className="text-slate-500">
                                                                                                                {testTask
                                                                                                                    ?.state
                                                                                                                    ?.order_info
                                                                                                                    ?.delivery_date
                                                                                                                    ? moment(
                                                                                                                          testTask
                                                                                                                              ?.state
                                                                                                                              ?.order_info
                                                                                                                              ?.delivery_date,
                                                                                                                          "DD/MM/YYYY"
                                                                                                                      )?.format(
                                                                                                                          "MMM DD, YYYY"
                                                                                                                      )
                                                                                                                    : "N/A"}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                        <p className="text-sm ">
                                                                                                            <span className="">
                                                                                                                Delivery
                                                                                                                address:
                                                                                                            </span>{" "}
                                                                                                            <span className="text-slate-500">
                                                                                                                {testTask
                                                                                                                    ?.state
                                                                                                                    ?.order_info
                                                                                                                    ?.delivery_address ||
                                                                                                                    "N/A"}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                )}

                                                                                                {testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.key ===
                                                                                                    "inventory_check" && (
                                                                                                    <CustomTable
                                                                                                        tableBody={
                                                                                                            taskTableBody
                                                                                                        }
                                                                                                        tableHeader={
                                                                                                            taskTableHeader
                                                                                                        }
                                                                                                        isScrollable={
                                                                                                            false
                                                                                                        }
                                                                                                        // isScrolled={isScrolled}
                                                                                                        isCellBordered={
                                                                                                            true
                                                                                                        }
                                                                                                        isCellSelectable={
                                                                                                            false
                                                                                                        }
                                                                                                        isAllSelectable={
                                                                                                            false
                                                                                                        }
                                                                                                        headerContainerClass="!bg-slate-50"
                                                                                                        headerItemClass="font-rocGroteskMedium "
                                                                                                        // tableClass="mb-12"
                                                                                                    />
                                                                                                )}

                                                                                                {/* {testTask
                                                                                                ?.stepsRan?.[
                                                                                                idx
                                                                                            ]
                                                                                                ?.key ===
                                                                                                "order_create" && (
                                                                                                <p className="text-sm text-slate-500">
                                                                                                    {
                                                                                                        testTask
                                                                                                            ?.state
                                                                                                            ?.order_info
                                                                                                            ?.order_summary
                                                                                                    }
                                                                                                </p>
                                                                                            )} */}

                                                                                                {testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.key ===
                                                                                                    "order_email_draft" && (
                                                                                                    <div className="p-4 ">
                                                                                                        <span className=" text-sm font-rocGroteskMedium mb-[2px]">
                                                                                                            Subject:
                                                                                                        </span>{" "}
                                                                                                        <span className=" text-sm font-rocGroteskMedium mb-2">
                                                                                                            {
                                                                                                                testTask
                                                                                                                    ?.stepsRan?.[
                                                                                                                    idx
                                                                                                                ]
                                                                                                                    ?.data
                                                                                                                    ?.order_email_draft_subject
                                                                                                            }
                                                                                                        </span>
                                                                                                        <div
                                                                                                            className="text-sm text-slate-500"
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: hasHTMLTag(
                                                                                                                    testTask
                                                                                                                        ?.stepsRan?.[
                                                                                                                        idx
                                                                                                                    ]
                                                                                                                        ?.data
                                                                                                                        ?.order_email_draft_content
                                                                                                                )
                                                                                                                    ? sanitizeHtml(
                                                                                                                          testTask
                                                                                                                              ?.stepsRan?.[
                                                                                                                              idx
                                                                                                                          ]
                                                                                                                              ?.data
                                                                                                                              ?.order_email_draft_content
                                                                                                                      ).sanitizedHTML?.join(
                                                                                                                          ""
                                                                                                                      )
                                                                                                                    : testTask
                                                                                                                          ?.stepsRan?.[
                                                                                                                          idx
                                                                                                                      ]
                                                                                                                          ?.data
                                                                                                                          ?.order_email_draft_content,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <p className="text-sm text-slate-500">
                                                                                                Please
                                                                                                approve
                                                                                                this
                                                                                                step
                                                                                                to
                                                                                                proceed
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="p-4 border-b border-slate-200">
                                                                                    <div className="flex items-center justify-between mb-2">
                                                                                        <span
                                                                                            className={`text-xs px-1.5 flex items-center justify-center h-5 rounded border ${
                                                                                                testTask?.steps?.[
                                                                                                    idx
                                                                                                ]?.status?.toLowerCase() ===
                                                                                                "completed"
                                                                                                    ? "border-[#16A34A] text-[#16A34A] bg-[#DCFCE7]"
                                                                                                    : "border-slate-200 bg-slate-100"
                                                                                            }`}
                                                                                        >
                                                                                            Step{" "}
                                                                                            {idx +
                                                                                                1}
                                                                                        </span>
                                                                                    </div>

                                                                                    <div>
                                                                                        <span className="block text-base mb-1">
                                                                                            {
                                                                                                testTask
                                                                                                    ?.steps?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.title
                                                                                            }
                                                                                        </span>
                                                                                        <div className="space-y-1.5">
                                                                                            <p className="text-sm text-slate-500">
                                                                                                {
                                                                                                    testTask
                                                                                                        ?.steps?.[
                                                                                                        idx
                                                                                                    ]
                                                                                                        ?.description
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {testTask?.stepsRan?.[
                                                                                idx
                                                                            ] && (
                                                                                <div className="p-4 flex items-center space-x-3">
                                                                                    {!testTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.approved && (
                                                                                        <Button
                                                                                            btnText="Approve step"
                                                                                            btnPermission={[
                                                                                                AccountPermissions.all_access,
                                                                                                AccountPermissions.create_ai_teammate,
                                                                                            ]}
                                                                                            onClick={() => {
                                                                                                // if (
                                                                                                //     testTask?.stepsRan?.[idx]
                                                                                                //         ?.approved
                                                                                                // ) {
                                                                                                setSelectedStep(
                                                                                                    testTask
                                                                                                        ?.stepsRan?.[
                                                                                                        idx
                                                                                                    ]
                                                                                                );
                                                                                            }}
                                                                                            btnClassname="!px-[14px] !py-2 !w-fit !h-[36px]"
                                                                                        />
                                                                                    )}

                                                                                    {testTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.key ===
                                                                                        "order_assistant" && (
                                                                                        <Button
                                                                                            btnText="Edit Order"
                                                                                            btnPermission={[
                                                                                                AccountPermissions.all_access,
                                                                                                AccountPermissions.create_ai_teammate,
                                                                                            ]}
                                                                                            icon={
                                                                                                <i className="ri-edit-2-line text-base"></i>
                                                                                            }
                                                                                            // disabled={
                                                                                            //     !testTask?.stepsRan?.[
                                                                                            //         idx
                                                                                            //     ]?.approved
                                                                                            // }
                                                                                            onClick={() => {
                                                                                                // if (
                                                                                                //     testTask
                                                                                                //         ?.stepsRan?.[
                                                                                                //         idx
                                                                                                //     ]?.approved
                                                                                                // ) {
                                                                                                navigate(
                                                                                                    `/dashboard/task/${testTaskId}/edit-order`,
                                                                                                    {
                                                                                                        state: {
                                                                                                            internalNavigation:
                                                                                                                true,
                                                                                                        },
                                                                                                    }
                                                                                                );
                                                                                                // }
                                                                                            }}
                                                                                            btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                                                        />
                                                                                    )}
                                                                                    <Button
                                                                                        btnPermission={[
                                                                                            AccountPermissions.all_access,
                                                                                            AccountPermissions.create_ai_teammate,
                                                                                        ]}
                                                                                        btnText="Rerun"
                                                                                        icon={
                                                                                            <i className="ri-restart-line text-base"></i>
                                                                                        }
                                                                                        disabled={
                                                                                            !testTask
                                                                                                ?.stepsRan?.[
                                                                                                idx
                                                                                            ]
                                                                                                ?.approved
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // if (
                                                                                            //     testTask?.stepsRan?.[idx]
                                                                                            //         ?.approved
                                                                                            // ) {
                                                                                            setSelectedStep(
                                                                                                testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                            );
                                                                                            setConfirmRetry(
                                                                                                true
                                                                                            );
                                                                                            // }
                                                                                        }}
                                                                                        btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                                                    />
                                                                                    <Button
                                                                                        btnPermission={[
                                                                                            AccountPermissions.all_access,
                                                                                            AccountPermissions.create_ai_teammate,
                                                                                        ]}
                                                                                        btnText="Give feedback to this step"
                                                                                        icon={
                                                                                            <i className="ri-chat-2-line text-base"></i>
                                                                                        }
                                                                                        disabled={
                                                                                            !testTask
                                                                                                ?.stepsRan?.[
                                                                                                idx
                                                                                            ]
                                                                                                ?.approved
                                                                                        }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                testTask
                                                                                                    ?.stepsRan?.[
                                                                                                    idx
                                                                                                ]
                                                                                                    ?.approved
                                                                                            ) {
                                                                                                setSelectedStep(
                                                                                                    testTask
                                                                                                        ?.stepsRan?.[
                                                                                                        idx
                                                                                                    ]
                                                                                                );
                                                                                                setFeedbackModal(
                                                                                                    true
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </details>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </form>

            <ModalContainer
                open={emailClientModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setEmailClientModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3 pb-5">
                        <div className="flex flex-col items-center text-center mb-1">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setEmailClientModal(false)}
                                    className="ri-close-fill text-2xl"
                                ></i>
                            </div>
                            <div className="mb-4 relative w-12 h-12 rounded-full flex items-center justify-center bg-[#FF3D1D17]">
                                <i className="ri-information-fill text-r-50 text-2xl"></i>
                            </div>
                            <p className="text-[22px] mb-1.5 text-center  font-rocGroteskMedium text-g-75">
                                Connect your account
                            </p>
                            <p className="text-base text-center font-rocGroteskMedium text-slate-500">
                                Please select an account to continue
                            </p>
                        </div>

                        {emailClients?.length > 0 ? (
                            <div>
                                {emailClients?.map((client, idx) => {
                                    return (
                                        <div
                                            key={client?._id}
                                            className={`flex items-center justify-between py-2.5 ${
                                                idx < emailClients?.length - 1 && "border-b"
                                            } border-slate-200 w-full`}
                                        >
                                            <div className="flex items-center space-x-3">
                                                {displayProductChannelIcon(
                                                    client?.channel,
                                                    "!w-[22px] !h-[22px]"
                                                )}
                                                <div>
                                                    <span className="block text-base text-slate-500 font-rocGroteskMedium capitalize">
                                                        {client?.channel}
                                                    </span>
                                                    <span className="block text-xs text-slate-500 font-rocGroteskMedium capitalize">
                                                        {client?.creds?.account?.email}
                                                    </span>
                                                </div>
                                            </div>

                                            {client?.socialId === emailClientSocialId ? (
                                                <div className="flex items-center space-x-2.5">
                                                    <span className="text-sm font-rocGroteskMedium">
                                                        Selected
                                                    </span>
                                                    <i className="ri-checkbox-circle-fill text-carribean-green"></i>
                                                </div>
                                            ) : (
                                                <Button
                                                    btnText="Select"
                                                    btnPermission={[
                                                        AccountPermissions.all_access,
                                                        AccountPermissions.manage_integrations,
                                                        AccountPermissions.create_ai_teammate,
                                                    ]}
                                                    btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit !h-[32px]"
                                                    disabled={false}
                                                    onClick={() => {
                                                        setEmailClientSocialId(client?._id);
                                                        setEmailClientModal(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                <EmptyStateComp
                                    title="No email accounts found"
                                    description="There are currently no email integrations found, please integrate your email account"
                                    icon={
                                        <i className="ri-mail-forbid-line text-slate-500 text-[40px]"></i>
                                    }
                                    hasButton
                                    btnText="Integrate email account"
                                    contentContainerClass="!w-full !p-6"
                                    btnAction={() => {
                                        if (aiTeammateId) {
                                            saveToStorage(
                                                "connectedRedirect",
                                                `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`
                                            );
                                            // navigate(
                                            //     `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`,
                                            //     {
                                            //         replace: true,
                                            //         state: {
                                            //             role,
                                            //             avatar,
                                            //             aiNameValue,
                                            //             aiStepValues,
                                            //             communicationChannels,
                                            //             emailClientSocialId,
                                            //             showTestOutput,
                                            //         },
                                            //     }
                                            // );
                                        } else {
                                            saveToStorage(
                                                "connectedRedirect",
                                                taskId
                                                    ? `/dashboard/workforce/create-ai-teammate?taskId=${taskId}`
                                                    : `/dashboard/workforce/create-ai-teammate`
                                            );
                                            // navigate(
                                            //     taskId
                                            //         ? `/dashboard/workforce/create-ai-teammate?taskId=${taskId}`
                                            //         : `/dashboard/workforce/create-ai-teammate`,
                                            //     {
                                            //         replace: true,
                                            //         state: {
                                            //             role,
                                            //             avatar,
                                            //             aiNameValue,
                                            //             aiStepValues,
                                            //             communicationChannels,
                                            //             emailClientSocialId,
                                            //             showTestOutput,
                                            //         },
                                            //     }
                                            // );
                                        }

                                        saveToStorage(
                                            "createAiAgentForm",
                                            {
                                                role,
                                                avatar,
                                                aiNameValue,
                                                aiStepValues,
                                                communicationChannels,
                                                emailClientSocialId,
                                                showTestOutput,
                                                marketingConfig,
                                                selectedMarketingConnection,
                                            },
                                            true
                                        );

                                        navigate("/dashboard/integrations", {
                                            state: { internalNavigation: true },
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={testEmailModal}
                showCloseIcon={false}
                tailwindClassName="w-[45%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setTestEmailModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative font-rocGroteskMedium">
                    <div className="flex items-center justify-between bg-[#424242] px-4 py-3">
                        <span className="text-sm text-white">New message</span>
                        <i
                            className="ri-close-fill text-white cursor-pointer"
                            onClick={() => {
                                setTestEmailModal(false);
                            }}
                        ></i>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleTest();
                        }}
                        className="bg-white min-h-[480px] px-4"
                    >
                        <div className="border-b border-slate-200">
                            <TextInput
                                value={testEmail?.subject}
                                name={"subject"}
                                type={"text"}
                                required={true}
                                onChange={(e) => {
                                    setTestEmail((prev) => ({
                                        ...prev,
                                        subject: e.target.value,
                                    }));
                                }}
                                inputPlaceholder={"Subject"}
                                containerClassname=""
                                inputContainerClassname={"!border-0 !rounded-none "}
                                inputClassName="w-full "
                                inputContainerStyles={{ border: 0 }}
                            />
                        </div>

                        <div>
                            <TextareaInput
                                name={"body"}
                                value={testEmail?.body}
                                onChange={(e) => {
                                    setTestEmail((prev) => ({
                                        ...prev,
                                        body: e.target.value,
                                    }));
                                }}
                                inputContainerClassname={"!border-0 !rounded-none"}
                                inputClassName="!py-1.5 placeholder:!font-rocGroteskRegular"
                                rows={17}
                                inputPlaceholder={"Body"}
                                required={true}
                                inputContainerStyles={{ border: 0 }}
                            />
                        </div>

                        <div className="py-3 flex items-center justify-end border-t border-slate-200">
                            {/* <i className="ri-attachment-2 text-xl text-slate-500 cursor-pointer"></i> */}

                            <Button
                                btnText={"Send"}
                                type={"submit"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_ai_teammate,
                                ]}
                                btnClassname="!py-2 !px-3 !w-fit"
                                // onClick={() => {
                                //     setTestEmailModal(false);
                                //     setShowTestOutput(true);
                                // }}
                                icon={<i className="ri-send-plane-fill"></i>}
                                isLoading={testingAiTeammate}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>

            <ModalContainer
                open={confirmRetry}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setConfirmRetry(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setConfirmRetry(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                You're about to rerun the agent task from this step. This will reset
                                progress from this point forward. Are you sure you want to proceed?
                            </h6>
                        </div>
                        <div className="mt-5 mb-3 space-x-2 flex items-center">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setConfirmRetry(false)}
                                disabled={retryingTaskStep}
                            />
                            <Button
                                btnText={"Yes, re-run step"}
                                type={"button"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_ai_teammate,
                                ]}
                                btnClassname="!py-3 !bg-[#DCFCE7] border border-[#16A34A] !text-dark-gray-2 !px-4"
                                onClick={handleRetry}
                                isLoading={retryingTaskStep}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={feedbackModal}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setFeedbackModal(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setFeedbackModal(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <i className="ri-feedback-line text-[48px] mb-2.5"></i>
                            </div>

                            <h6 className="text-center font-rocGroteskMedium text-[22px]">
                                Provide Feedback on Completed Task
                            </h6>

                            <p className="text-base text-center font-rocGroteskMedium  text-slate-500 px-6">
                                Your AI teammate has completed the assigned task. Share your
                                feedback to help improve its future performance.
                            </p>
                        </div>

                        <div>
                            <div className="">
                                <TextareaInput
                                    name={"feedback"}
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                    rows={5}
                                    placeholder={
                                        "Add or remove instructions, improvements, or suggestions here..."
                                    }
                                    containerClassname=""
                                />
                            </div>
                            <div>
                                <span className="text-xs text-slate-500 font-rocGroteskMedium">
                                    Once submitted, your feedback will be applied, and the task will
                                    re-run automatically
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="py-4 border-t border-slate-200 space-x-2 px-8 flex items-center">
                        <Button
                            btnText={"Cancel"}
                            type={"button"}
                            btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                            onClick={() => setFeedbackModal(false)}
                            disabled={givingTaskStepFeedback}
                        />
                        <Button
                            btnText={"Submit feedback and re-run step"}
                            type={"button"}
                            btnPermission={[
                                AccountPermissions.all_access,
                                AccountPermissions.create_ai_teammate,
                            ]}
                            btnClassname="!py-3 border !text-dark-gray-2 !px-4"
                            onClick={handleSubmitFeedback}
                            disabled={!feedback}
                            isLoading={givingTaskStepFeedback}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={feedbackSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setFeedbackSuccessModal(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setFeedbackSuccessModal(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <i className="ri-feedback-line text-[48px] mb-2.5"></i>
                            </div>

                            <h6 className="text-center font-rocGroteskMedium text-[22px]">
                                Feedback Received! Step Will Run Again
                            </h6>

                            <p className="text-base text-center font-rocGroteskMedium  text-slate-500 px-6">
                                Thank you for your feedback. Based on your input, this step will
                                automatically rerun to reflect the changes you suggested.
                            </p>
                        </div>

                        <div className="mb-8">
                            <span className="text-slate-500 font-rocGroteskMedium text-sm block mb-4">
                                What happens next:
                            </span>
                            <div className="border font-rocGroteskMedium border-slate-200 mb-4 rounded-[6px] px-2 py-3 shadow-[0px_4px_8px_-4px_#10182808]">
                                <ul className="list-disc text-sm ml-4 space-y-2">
                                    <li className="">
                                        Your AI teammate will update the task with the requested
                                        adjustments.
                                    </li>
                                    <li>
                                        The revised process will run again to ensure the task meets
                                        your expectations.
                                    </li>
                                </ul>
                            </div>

                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                You'll be notified once the task is completed with the new changes.
                            </span>
                        </div>
                    </div>
                    <div className="py-4 border-t border-slate-200 space-x-2 px-8 flex items-center">
                        <Button
                            btnText={"Close"}
                            type={"button"}
                            btnClassname="!py-3 border !text-dark-gray-2 !px-4"
                            onClick={() => setFeedbackSuccessModal(false)}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={spsSigninModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] sm:w-[434px]"
                closeModal={() => {
                    setSpsSigninModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-6 pt-3 pb-5">
                        <div className="flex flex-col items-center text-center mb-6">
                            <div className="flex justify-end mr-[-12px] w-full">
                                <div className="bg-slate-100 rounded-full h-8 w-8 flex justify-center">
                                    <i
                                        onClick={() => setSpsSigninModal(false)}
                                        className="ri-close-fill text-2xl text-slate-500 cursor-pointer"
                                    ></i>
                                </div>
                            </div>
                            <p className="text-2xl mb-1.5 text-center  font-rocGroteskBold text-gm-50">
                                Enter sign in details
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-gm-35">
                                Enter your EDI sign in details to complete setup
                            </p>
                        </div>

                        <form onSubmit={handleCreateSpsCommerceConnection}>
                            <TextInput
                                value={signupInfo.email}
                                name={"email"}
                                type={"email"}
                                placeholder={"Enter email address"}
                                onChange={handleChange}
                                required={true}
                                containerClassname="mb-6 "
                                inputContainerClassname="!rounded-lg"
                            />
                            <TextInput
                                value={signupInfo.password}
                                name={"password"}
                                type={"password"}
                                onChange={handleChange}
                                placeholder={"Enter password"}
                                required={true}
                                containerClassname="mb-2"
                                inputContainerClassname="!rounded-lg"
                            />

                            <Button
                                btnText={`Save details`}
                                type="submit"
                                isLoading={creatingSpsCommerceConnection}
                                btnClassname="!mt-4 !w-fit !w-full !py-[14px] !px-4 "
                            />
                            <div className="mt-6 font-rocGroteskMedium py-2 px-3.5 w-fit  bg-slate-100 rounded-lg">
                                <div className="flex justify-between items-center gap-4 ">
                                    <p className="text-gm-45 text-xs">
                                        Why do we need your sign in details?
                                    </p>
                                    <i
                                        className={` ${
                                            showWhy ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                        } text-slate-500 cursor-pointer `}
                                        onClick={() => setShowWhy(!showWhy)}
                                    ></i>
                                </div>
                                {showWhy && (
                                    <p className="text-xs text-gm-35 transition-all duration-500 ease-in-out mt-2">
                                        We use your sign in details to log into your EDI account and
                                        pull any information of orders you get there. Synth is
                                        unable to make any updates to any information on your EDI
                                        account.
                                    </p>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default CreateAiTeammate;
