import React from "react";
import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { marketingCampaignTypes } from "redux/Ldb/types";
import { marketingCampaignService } from "services/Ldb";
import { Dispatch } from "redux";

export const marketingCampaignActions = {
    planCampaign,
    addCampaign,
    getAllCampaigns,
    getAllCampaignsCalendar,
    resetGetAllCampaigns,
    resetAddCampaign,
    resetPlanCampaign,
};

function planCampaign(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(marketingCampaignTypes.PLAN_CAMPAIGN_REQUEST));

        marketingCampaignService.planCampaign(data).then(
            (res) => {
                dispatch(success(marketingCampaignTypes.PLAN_CAMPAIGN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(marketingCampaignTypes.PLAN_CAMPAIGN_FAILURE, error?.message));
                }
            }
        );
    };
}

function addCampaign(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_REQUEST));

        marketingCampaignService.addCampaign(data).then(
            (res) => {
                dispatch(
                    success(marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function getAllCampaigns(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    channel?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(marketingCampaignTypes.FETCH_ALL_CAMPAIGN_REQUEST));

        marketingCampaignService.getAllCampaigns(limit, page, search, status, channel).then(
            (res) => {
                dispatch(success(marketingCampaignTypes.FETCH_ALL_CAMPAIGN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(marketingCampaignTypes.FETCH_ALL_CAMPAIGN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function getAllCampaignsCalendar(search?: string, status?: string, channel?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_REQUEST));

        marketingCampaignService.getAllCampaignsCalendar(search, status, channel).then(
            (res) => {
                dispatch(
                    success(
                        marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_SUCCESS,
                        res?.data
                    )
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            marketingCampaignTypes.FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function resetGetAllCampaigns() {
    return (dispatch: Dispatch) => {
        dispatch(success(marketingCampaignTypes.FETCH_ALL_CAMPAIGN_SUCCESS, null));
    };
}

function resetPlanCampaign() {
    return (dispatch: Dispatch) => {
        dispatch(success(marketingCampaignTypes.PLAN_CAMPAIGN_SUCCESS, null));
    };
}

function resetAddCampaign() {
    return (dispatch: Dispatch) => {
        dispatch(success(marketingCampaignTypes.ADD_CAMPAIGN_TO_CALENDAR_SUCCESS, null));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
