import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import Billing from "components/views/Ldb/Dashboard/Settings/Tabs/Billing";
import Integrations from "components/views/Ldb/Dashboard/Settings/Tabs/Intergrations";
import PersonalInfo from "components/views/Ldb/Dashboard/Settings/Tabs/PersonalInfo";
import Preferences from "components/views/Ldb/Dashboard/Settings/Tabs/Preferences";
import Production from "components/views/Ldb/Dashboard/Settings/Tabs/Production";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountPermissions } from "types/permissions";

const Settings = () => {
    const { id } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const tabs = ["Profile", "Preferences"];
    // const tabs = ["Personal info", "Business info", "Preferences", "Billing", "integrations"];

    const displayActiveTab = () => {
        switch (currentTab) {
            case 0:
                return <PersonalInfo />;

            case 1:
                return <Preferences />;

            // case 2:
            //     return <Billing />;

            // case 2:
            //     return <Integrations />;

            // case 3:
            //     return <Production />;

            default:
                return;
        }
    };

    useEffect(() => {
        if (id) {
            setCurrentTab(parseInt(id));
        }
    }, [id]);

    return (
        <>
            <div className="md:px-[14px] ">
                <p className="text-xl font-rocGroteskMedium mb-[22px]">Settings</p>
                <TabsContainer
                    tabs={tabs}
                    activeTab={currentTab}
                    setActiveTab={setCurrentTab}
                    component={displayActiveTab()}
                    showTabMenu={"settings"}
                    className={`!px-0 `}
                />
            </div>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end mb-[30px]">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">Edit full name</p>
                    <div className="mb-10">
                        <TextInput
                            name={"fullName"}
                            type={"text"}
                            // value={customerDetails.firstName}
                            // onChange={handleChange}
                            placeholder={"Edit full name"}
                            required={true}
                            containerClassname=""
                        />
                    </div>

                    <Button
                        btnText={"Save changes"}
                        type={"submit"}
                        btnPermission={[
                            AccountPermissions.all_access,
                            AccountPermissions.manage_settings,
                        ]}
                    />
                    <p className="text-sm font-rocGroteskMedium text-center mt-[10px] cursor-pointer">
                        Close
                    </p>
                </form>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end mb-[30px]">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">
                        Change password
                    </p>
                    <div className="mb-10 flex flex-col gap-6">
                        <TextInput
                            name={"currentPassword"}
                            type={"password"}
                            // value={customerDetails.firstName}
                            // onChange={handleChange}
                            placeholder={"Current password"}
                            required={true}
                            containerClassname=""
                        />
                        <TextInput
                            name={"newPassword"}
                            type={"password"}
                            // value={customerDetails.firstName}
                            // onChange={handleChange}
                            placeholder={"New password"}
                            required={true}
                            containerClassname=""
                        />
                        <TextInput
                            name={"confirmNewPassword"}
                            type={"password"}
                            // value={customerDetails.firstName}
                            // onChange={handleChange}
                            placeholder={"Confirm new password"}
                            required={true}
                            containerClassname=""
                        />
                    </div>

                    <Button
                        btnText={"Save changes"}
                        type={"submit"}
                        btnPermission={[
                            AccountPermissions.all_access,
                            AccountPermissions.manage_settings,
                        ]}
                    />
                    <p className="text-sm font-rocGroteskMedium text-center mt-[10px] cursor-pointer">
                        Close
                    </p>
                </form>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end mb-[30px]">
                        <i className="ri-close-fill text-xl"></i>
                    </div>

                    <div className="mb-[46px]">
                        <p className="text-center text-base font-rocGroteskMedium">
                            Are you sure you want to de-activate the Co-pilot? The Co-pilot will not
                            be able to help you simplify your Supply-Chain operations until you
                            re-activate it
                        </p>
                    </div>

                    <Button
                        btnText={"No, close"}
                        type={"button"}
                        btnClassname={"!bg-n-20 !text-gm-50 mb-[10px]"}
                    />
                    <Button
                        btnText={"Yes, de-activate"}
                        btnPermission={[
                            AccountPermissions.all_access,
                            AccountPermissions.manage_settings,
                        ]}
                        type={"submit"}
                        btnClassname={"!bg-[#FFEFEC] !text-gm-50 border border-r-50 "}
                    />
                </form>
            </ModalContainer>
        </>
    );
};

export default Settings;
