import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";

type mapProp = {
    mapData?: any;
};

const MultipleLineChart = ({ mapData }: mapProp) => {
    const getOptions = () => {
        const options = {
            grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
            xAxis: {
                type: "category",
                data: mapData && mapData?.[0],
                nameTextStyle: {
                    borderType: "dotted",
                    fontSize: "9",
                    fontFamily: "rocGrotesk-regular",
                },
                axisLabel: {
                    color: "#89939b",
                    // align: "center",
                    rotate: 70,
                    // margin: 10,
                },
                axisLine: {
                    lineStyle: {
                        color: "#E2E8F0",
                    },
                },
                axisTick: {
                    show: false,
                },
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },

            yAxis: {
                type: "value",
                axisLabel: {
                    color: "#89939b",
                    formatter: "${value}",
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dotted",
                        color: "#E2E8F0",
                    },
                },
            },

            series: [
                {
                    name: "Current sales",
                    data: mapData && mapData?.[1],
                    type: "line",
                    symbolSize: 5,
                    symbol: "circle",
                    smooth: true,
                    lineStyle: {
                        width: 1,
                        color: "#002A61",
                        type: "line",
                    },
                },
                {
                    name: "Synth's predicted forecast",
                    data: mapData && mapData?.[2],
                    type: "line",
                    symbolSize: 5,
                    symbol: "circle",
                    smooth: true,
                    lineStyle: {
                        width: 1,
                        color: "#D43318",
                        type: "dashed",
                    },
                },
                {
                    name: "Configured forecast",
                    data: mapData && mapData?.[3],
                    type: "line",
                    symbolSize: 5,
                    symbol: "circle",
                    smooth: true,
                    lineStyle: {
                        width: 1,
                        color: "#00A5D0",
                        type: "dashed",
                    },
                },
            ],
            color: ["#002A61", "#D43318", "#00A5D0"],
            tooltip: {
                trigger: "axis",
                backgroundColor: "#fff",
                textStyle: {
                    color: "#000",
                },

                axisPointer: {
                    snap: true,
                    type: "line",
                    lineStyle: {
                        color: "#CBD5E1",
                        width: 1,
                        type: "line",
                    },
                    show: true,
                    z: 10,
                },

                formatter: function (params) {
                    if (
                        (params?.[0] && params[0]?.data?.value) ||
                        (params?.length === 2 && params?.[1]?.data?.value) ||
                        (params?.length === 3 && params?.[2]?.data?.value)
                    ) {
                        const firstParam = params?.[0];
                        const secondParam = params?.[1];
                        const thirdParam = params?.[2];

                        const showFirstParam = params?.[0]?.data;
                        const showSecondParam = params?.[1]?.data;
                        const showThirdParam = params?.[2]?.data;

                        return `
                            <div class="chart" style="width: 330px">
                                ${
                                    showFirstParam
                                        ? `
                                        <div style="margin-bottom: 10px;">
                                            <div style="display:flex ; align-items: center; gap: 5px;  margin-bottom: 10px;">
                                                <span style="height: 10px; width: 10px; background-color: ${
                                                    firstParam?.seriesName === "Current sales"
                                                        ? "#002A61"
                                                        : firstParam?.seriesName ===
                                                          "Synth's predicted forecast"
                                                        ? "#D43318"
                                                        : "#00A5D0"
                                                } ; border-radius: 100%; flex-shrink: 0;">
                                                </span>
                                                <p style="font-weight: 500; color: #64748B; font-size: 12px;" >  ${
                                                    firstParam?.name
                                                } ${firstParam?.seriesName} </p>
                                            </div>

                                            <div>
                                                ${
                                                    firstParam?.data && firstParam?.data?.value >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > $${firstParam?.data?.value?.toLocaleString()} </p>
                                                `
                                                        : ""
                                                }
                                                ${
                                                    firstParam?.data &&
                                                    firstParam?.data?.quantity >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > ${firstParam?.data?.quantity.toLocaleString()} units </p>
                                                `
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    `
                                        : ""
                                }

                                ${
                                    showSecondParam
                                        ? `
                                        <div style="margin-bottom: 10px;">
                                            <div style="display:flex ; align-items: center; gap: 5px;  margin-bottom: 10px;">
                                                <span style="height: 10px; width: 10px; background-color: ${
                                                    secondParam?.seriesName === "Current sales"
                                                        ? "#002A61"
                                                        : secondParam?.seriesName ===
                                                          "Synth's predicted forecast"
                                                        ? "#D43318"
                                                        : "#00A5D0"
                                                } ; border-radius: 100%; flex-shrink: 0;">
                                                </span>
                                                <p style="font-weight: 500; color: #64748B; font-size: 12px;" >  ${
                                                    secondParam?.name
                                                } ${secondParam?.seriesName} </p>
                                            </div>

                                            <div>
                                                ${
                                                    secondParam?.data &&
                                                    secondParam?.data?.value >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > $${secondParam?.data?.value?.toLocaleString()} </p>
                                                `
                                                        : ""
                                                }
                                                ${
                                                    secondParam?.data &&
                                                    secondParam?.data?.quantity >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > ${secondParam?.data?.quantity.toLocaleString()} units </p>
                                                `
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    `
                                        : ""
                                }

                                ${
                                    showThirdParam
                                        ? `
                                        <div style="margin-bottom: 10px;">
                                            <div style="display:flex ; align-items: center; gap: 5px;  margin-bottom: 10px;">
                                                <span style="height: 10px; width: 10px; background-color: ${
                                                    thirdParam?.seriesName === "Current sales"
                                                        ? "#002A61"
                                                        : thirdParam?.seriesName ===
                                                          "Synth's predicted forecast"
                                                        ? "#D43318"
                                                        : "#00A5D0"
                                                } ; border-radius: 100%; flex-shrink: 0;">
                                                </span>
                                                <p style="font-weight: 500; color: #64748B; font-size: 12px;" >  ${
                                                    thirdParam?.name
                                                } ${thirdParam?.seriesName} </p>
                                            </div>

                                            <div>
                                                ${
                                                    thirdParam?.data && thirdParam?.data?.value >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > $${thirdParam?.data?.value?.toLocaleString()} </p>
                                                `
                                                        : ""
                                                }
                                                ${
                                                    thirdParam?.data &&
                                                    thirdParam?.data?.quantity >= 0
                                                        ? `
                                                    <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > ${thirdParam?.data?.quantity.toLocaleString()} units </p>
                                                `
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    `
                                        : ""
                                }
                                
                            </div>
                        `;
                    } else {
                        return "";
                    }
                },
            },
        };

        return options;
    };

    return (
        <div id="chart" className="w-full">
            <ReactEcharts option={getOptions()} />
        </div>
    );
};

export default MultipleLineChart;
