export const salesTypes = {
    FETCH_SALES_ORDER_LIST_REQUEST: "FETCH_SALES_ORDER_LIST_REQUEST",
    FETCH_SALES_ORDER_LIST_SUCCESS: "FETCH_SALES_ORDER_LIST_SUCCESS",
    FETCH_SALES_ORDER_LIST_FAILURE: "FETCH_SALES_ORDER_LIST_FAILURE",
    CREATE_SALES_ORDER_REQUEST: "CREATE_SALES_ORDER_REQUEST",
    CREATE_SALES_ORDER_SUCCESS: "CREATE_SALES_ORDER_SUCCESS",
    CREATE_SALES_ORDER_FAILURE: "CREATE_SALES_ORDER_FAILURE",
    FETCH_SINGLE_SALES_ORDER_REQUEST: "FETCH_SINGLE_SALES_ORDER_REQUEST",
    FETCH_SINGLE_SALES_ORDER_SUCCESS: "FETCH_SINGLE_SALES_ORDER_SUCCESS",
    FETCH_SINGLE_SALES_ORDER_FAILURE: "FETCH_SINGLE_SALES_ORDER_FAILURE",
    FETCH_SALES_DASHBOARD_STATS_REQUEST: "FETCH_SALES_DASHBOARD_STATS_REQUEST",
    FETCH_SALES_DASHBOARD_STATS_SUCCESS: "FETCH_SALES_DASHBOARD_STATS_SUCCESS",
    FETCH_SALES_DASHBOARD_STATS_FAILURE: "FETCH_SALES_DASHBOARD_STATS_FAILURE",
    FETCH_SALES_TOP_AND_LEAST_PRODUCTS_REQUEST: "FETCH_SALES_TOP_AND_LEAST_PRODUCTS_REQUEST",
    FETCH_SALES_TOP_AND_LEAST_PRODUCTS_SUCCESS: "FETCH_SALES_TOP_AND_LEAST_PRODUCTS_SUCCESS",
    FETCH_SALES_TOP_AND_LEAST_PRODUCTS_FAILURE: "FETCH_SALES_TOP_AND_LEAST_PRODUCTS_FAILURE",
    FETCH_SALES_VELOCITY_STAT_REQUEST: "FETCH_SALES_VELOCITY_STAT_REQUEST",
    FETCH_SALES_VELOCITY_STAT_SUCCESS: "FETCH_SALES_VELOCITY_STAT_SUCCESS",
    FETCH_SALES_VELOCITY_STAT_FAILURE: "FETCH_SALES_VELOCITY_STAT_FAILURE",
    FETCH_SALES_CHANNEL_STAT_REQUEST: "FETCH_SALES_CHANNEL_STAT_REQUEST",
    FETCH_SALES_CHANNEL_STAT_SUCCESS: "FETCH_SALES_CHANNEL_STAT_SUCCESS",
    FETCH_SALES_CHANNEL_STAT_FAILURE: "FETCH_SALES_CHANNEL_STAT_FAILURE",
    FETCH_CUSTOMER_LIST_REQUEST: "FETCH_CUSTOMER_LIST_REQUEST",
    FETCH_CUSTOMER_LIST_SUCCESS: "FETCH_CUSTOMER_LIST_SUCCESS",
    FETCH_CUSTOMER_LIST_FAILURE: "FETCH_CUSTOMER_LIST_FAILURE",
    FETCH_SALES_TOP_AND_LEAST_REGIONS_REQUEST: "FETCH_SALES_TOP_AND_LEAST_REGIONS_REQUEST",
    FETCH_SALES_TOP_AND_LEAST_REGIONS_SUCCESS: "FETCH_SALES_TOP_AND_LEAST_REGIONS_SUCCESS",
    FETCH_SALES_TOP_AND_LEAST_REGIONS_FAILURE: "FETCH_SALES_TOP_AND_LEAST_REGIONS_FAILURE",
    CREATE_SALES_SHIPMENT_REQUEST: "CREATE_SALES_SHIPMENT_REQUEST",
    CREATE_SALES_SHIPMENT_SUCCESS: "CREATE_SALES_SHIPMENT_SUCCESS",
    CREATE_SALES_SHIPMENT_FAILURE: "CREATE_SALES_SHIPMENT_FAILURE",
    UPDATE_SALES_ORDER_REQUEST: "UPDATE_SALES_ORDER_REQUEST",
    UPDATE_SALES_ORDER_SUCCESS: "UPDATE_SALES_ORDER_SUCCESS",
    UPDATE_SALES_ORDER_FAILURE: "UPDATE_SALES_ORDER_FAILURE",
};
