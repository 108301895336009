import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetBundleDashboardGWM = () => {
    const dispatch = useAppDispatch();
    const [gwmData, setGwmData] = useState<{ [key: string]: any }>([]);
    const [productIds, setProductId] = useState<string[]>([]);
    const [channel, setChannel] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    const handleChannelChange = (value) => {
        setChannel(value);
    };
    const handleProductChange = (value) => {
        setProductId(value);
    };
    const handleDateRangeChange = (period) => {
        setDateRange(period);
        setStartDate("");
        setEndDate("");
    };
    const handleStartAndEndDateChange = (customDate) => {
        setStartDate(customDate[0].toISOString());
        setEndDate(customDate[1].toISOString());
        setDateRange("custom");
    };

    const {
        // fetchingDashboardGWM,
        // fetchedDashboardGWMSuccess,
        // fetchedDashboardGWMFailure,
        addForecastConfigSuccess,
        fetchingBundleDashboardGWM,
        fetchedBundleDashboardGWMSuccess,
        fetchedBundleDashboardGWMFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedBundleDashboardGWMSuccess)) {
            return setGwmData(fetchedBundleDashboardGWMSuccess);
        }
    }, [fetchedBundleDashboardGWMSuccess]);

    useEffect(() => {
        dispatch(
            dashboardActions?.getBundleDashboardGWM(
                category,
                productIds?.join(","),
                channel,
                startDate,
                endDate,
                dateRange
            )
        );

        return () => {
            dispatch(dashboardActions?.resetGetBundleDashboardGWMSuccess());
        };
    }, [
        dispatch,
        category,
        productIds,
        channel,
        startDate,
        endDate,
        dateRange,
        addForecastConfigSuccess,
    ]);

    return {
        data: gwmData,
        isFetching: fetchingBundleDashboardGWM,
        error: fetchedBundleDashboardGWMFailure,
        handleCategoryChange,
        handleProductChange,
        handleDateRangeChange,
        handleChannelChange,
        handleStartAndEndDateChange,
        productIds,
    };
};

export default useGetBundleDashboardGWM;
