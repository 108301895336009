import { AccountPermissions } from "types/permissions";

const singleBrandDashboardContent = {
    path: "/dashboard",
    // element: <Dashboard />,
    permissions: [],
    children: [
        {
            path: "home",
            // element: (
            //     <ChannelProvider
            //         channelName={`synth-ai-chat-${
            //             process.env.REACT_APP_STAGE === "production" ? "production" : "staging"
            //         }`}
            //     >
            //         <DashboardHome />
            //     </ChannelProvider>
            // ),
            permissions: [AccountPermissions.all_access, AccountPermissions.view_dashboard],
        },

        // start of synthally v2.0 routes

        {
            path: "inventory",
            // element: <Products />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/mapped-sku/:productId",
            // element: <MappedSkuDetail />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/import-products",
            // element: <ImportProducts />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/alert/:alertId/:inventoryIdx",
            // element: <AlertDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/stock-entry",
            // element: <AddStock />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/stock-entry/:id",
            // element: <StockDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/edit-stock/:id",
            // element: <EditStock />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/stock-adjustment",
            // element: <StockAdjustment />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/stock-adjustment/:id",
            // element: <StockAdjustmentDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/stock-transfer",
            // element: <StockTransfer />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/stock-control/transfer-stock/:id",

            // element: <StockTransferDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },

        {
            path: "inventory/sku-mapping/create",
            // element: <CreateSku />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
            actionPermissions: [
                AccountPermissions.all_access,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/mapped-sku/edit/:productId",
            // element: <EditSku />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "order",
            // element: <Order />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            path: "order/dashboard-details",
            // element: <ExpandedDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            path: "order/:orderId",
            // element: <OrderDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            path: "order/create",
            // element: <CreateOrder />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            path: "quote/:quoteId",
            // element: <QuoteSingleDetails />,
            permissions: [],
        },
        {
            path: "quote/create",
            // element: <CreateQuote />,
            permissions: [],
        },
        {
            path: "order/settings",
            // element: <OrderSettings />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_sales_order,
                AccountPermissions.create_sales_order,
                AccountPermissions.update_sales_order,
            ],
        },
        {
            path: "purchase",
            // element: <Purchase />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase/:purchaseId",
            // element: <PurchaseDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase/shipment/:purchaseId",
            // element: <ShipmentPurchaseDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase/:purchaseId/quotes/:quoteId",
            // element: <QuoteDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "playground",
            // element: <Playground />,
            permissions: [],
        },
        {
            path: "purchase/create-purchase-request",
            // element: <CreatePurchaseRequest />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase/edit-purchase-request/:editId",
            // element: <EditPurchaseRequest />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },

        {
            path: "purchase/settings",
            // element: <PurchaseSettings />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "integrations",
            // element: <Integration />,
            permissions: [AccountPermissions.all_access, AccountPermissions.manage_integrations],
        },
        {
            path: "integrations/:integrationName/:integrationId",
            // element: <IntegrationDetails />,
            permissions: [AccountPermissions.all_access, AccountPermissions.manage_integrations],
        },
        {
            path: "inventory/products/:productId",
            // element: <ProductDetail />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "teams",
            // element: <TeamManagement />,
            permissions: [AccountPermissions.all_access, AccountPermissions.manage_team],
        },
        {
            path: "suppliers",
            // element: <Suppliers />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "suppliers/create-supplier",
            //  element: <CreateSuppliers />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "suppliers/edit-supplier/:supplierId",
            // element: <CreateSuppliers />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "suppliers/:id",
            // element: <SupplierDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "suppliers/:id/contract",
            // element: <SupplierContract />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "track-trace",
            // element: <TrackAndTrace />,
            permissions: [],
        },
        {
            path: "track-trace/:id",
            // element: <ProductTrackDetails />,
            permissions: [],
        },
        {
            path: "workforce",
            // element: <Workforce />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "workforce/ai-teammate/:aiTeammateId",
            // element: <AiTeammateDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "workforce/edit-ai-teammate/:aiTeammateId",
            // element: (
            //     <ChannelProvider
            //         channelName={`agent_tasks_${
            //             userProfile?.user?.parentId || userProfile?.user?._id
            //         }`}
            //     >
            //         {" "}
            //         <CreateAiTeammate />
            //     </ChannelProvider>
            // ),
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "workforce/create-ai-teammate",
            // element: (
            //     <ChannelProvider
            //         channelName={`agent_tasks_${
            //             userProfile?.user?.parentId || userProfile?.user?._id
            //         }`}
            //     >
            //         {" "}
            //         <CreateAiTeammate />
            //     </ChannelProvider>
            // ),
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "task",
            // element: (
            //     <ChannelProvider
            //         channelName={`agent_tasks_${
            //             userProfile?.user?.parentId || userProfile?.user?._id
            //         }`}
            //     >
            //         <TaskManager />
            //     </ChannelProvider>
            // ),
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "task/:taskId",
            // element: (
            //     <ChannelProvider
            //         channelName={`agent_tasks_${
            //             userProfile?.user?.parentId || userProfile?.user?._id
            //         }`}
            //     >
            //         <TaskDetail />
            //     </ChannelProvider>
            // ),
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "campaign/:campaignId",
            // element: <CampaignDetail />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "task/workorder/:taskId",
            // element: <TaskDetailWorkOrder />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "task/:taskId/edit-order",
            // element: <EditOrderStep />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        {
            path: "production",
            // element: <Production />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/create-plan",
            // element: <CreateProductionPlan />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/edit-plan/:productionId",
            // element: <CreateProductionPlan />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/:productionId",
            // element: <ProductionPlanDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/create-bom",
            // element: <CreateBillOfMaterial />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/edit-bom/:id",
            // element: <CreateBillOfMaterial />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/bom/:id",
            // element: <BillOfMaterialDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        {
            path: "production/work-order/:workOrderId",
            // element: <WorkOrderDetails />,
            permissions: [AccountPermissions.all_access],
        },
        {
            path: "production/report/:workOrderId",
            // element: <ReportDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_production,
                AccountPermissions.create_production_plan,
                AccountPermissions.view_production_report,
            ],
        },
        // {
        //     path: "campaign/marketing-assistant/:aiTeammateId",
        //     element: <CampaignOverview />,
        // },
        {
            path: "campaign/plan-campaign/:campaignId",
            // element: <PlanCampaign />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_ai_task,
                AccountPermissions.create_ai_teammate,
            ],
        },
        // end of synthally v2.0 routes

        // start of synthally v1.0 routes
        {
            path: "vendors",
            // element: <VendorList />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "vendors/add-vendor",
            // element: <AddVendor />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "vendor/:vendorId",
            // element: <VendorDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "vendors/analytics",
            // element: <VendorAnalytics />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_suppliers,
                AccountPermissions.create_suppliers,
            ],
        },
        {
            path: "shipment",
            // element: <Shipment />,
            permissions: [AccountPermissions.all_access],
        },
        {
            path: "shipment/:shipmentId",
            // element: <ShipmentDetails />,
            permissions: [AccountPermissions.all_access],
        },
        {
            path: "inventory/add-product",
            // element: <AddProduct />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/edit-product/:productId",
            // element: <AddProduct />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/add-bundle",
            // element: <AddBundle />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/edit-bundle/:bundleId",
            // element: <AddBundle />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/bundle/:bundleId",
            // element: <BundleDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },

        {
            path: "inventory/add-warehouse",
            // element: <AddWareHouse />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/edit-warehouse/:warehouseId",
            // element: <AddWareHouse />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "inventory/warehouse/:id",
            // element: <WarehouseDetails />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_inventory,
                AccountPermissions.create_inventory,
                AccountPermissions.update_inventory,
                AccountPermissions.add_stock,
                AccountPermissions.transfer_stock,
            ],
        },
        {
            path: "purchase-orders",
            // element: <PurchaseOrder />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase-orders/:poId",
            // element: <PurchaseOrderDetail />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "purchase-orders/edit",
            // element: <EditPurchaseOrder />,
            permissions: [
                AccountPermissions.all_access,
                AccountPermissions.view_purchase_order,
                AccountPermissions.create_purchase_order,
            ],
        },
        {
            path: "team-management",
            // element: <TeamManagement />,
            permissions: [AccountPermissions.all_access, AccountPermissions.manage_team],
        },
        {
            path: "settings/:id",
            // element: <Settings />,
            permissions: [AccountPermissions.all_access, AccountPermissions.manage_settings],
        },
        // end of synthally v1.0 routes
    ],
};

const multiBrandDashboardContent = {
    path: "/dashboard/portfolio",
    // element: <Dashboard />,
    permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
    children: [
        {
            path: "home",
            // element: <MultiBrandDashboard />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "incidents",
            // element: <MultiBrandAlerts />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "incident/:alertId/:inventoryIdx",
            // element: <MultiBrandAlertDetails />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "inventory",
            // element: <MultiBrandInventory />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "management",
            // element: <MultiBrandPortfolio />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "management/add-brand",
            // element: <AddBrand />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "management/add-brand/:brandId",
            // element: <AddBrand />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
        {
            path: "team",
            // element: <MultiBrandTeam />,
            permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        },
    ],
};

const routes = [
    {
        path: "",
        // element: <Home />,
        permissions: [],
    },
    {
        path: "/integrations",
        // element: <Integrations />,
        permissions: [],
    },
    // {
    //     path: "/book-a-demo",
    //     element: <Home />,
    // },
    {
        path: "/wait-list",
        // element: <Home />,
        permissions: [],
    },
    {
        path: "/terms",
        // element: <Terms />,
        permissions: [],
    },
    {
        path: "/policy",
        // element: <Policy />,
        permissions: [],
    },

    // {
    //     path: "/auth-steps",
    //     element: (
    //         <PrivateRoutes>
    //             <AuthSteps />
    //         </PrivateRoutes>
    //     ),
    // },
    {
        path: "/login",
        // element: (
        //     <PublicRoutes>
        //         <Login />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    // {
    //     path: "/register",
    //     element: (
    //         <PublicRoutes>
    //             <Signup />
    //         </PublicRoutes>
    //     ),
    // },

    {
        path: "/forgot-password",
        // element: (
        //     <PublicRoutes>
        //         <ForgotPassword />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/reset-password",
        // element: (
        //     <PublicRoutes>
        //         <ResetPassword />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/registration-success/:token",
        // element: (
        //     <PublicRoutes>
        //         <SignupSuccess />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/new-onboard",
        // element: (
        //     <PrivateRoutes>
        //         <NewOnboard />
        //     </PrivateRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/verify",
        // element: (
        //     <PublicRoutes>
        //         <AcctVerification />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/change-email/:token",
        // element: (
        //     <PublicRoutes>
        //         <ChangeVerificationEmail />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },

    {
        path: "/google-auth",
        // element: (
        //     <PublicRoutes>
        //         <GoogleAuth />
        //     </PublicRoutes>
        // ),
        permissions: [],
    },
    {
        path: "/integrations/shopify",
        // element: (
        //     // <PublicRoutes>
        //     <ShopifyAuth />
        //     // </PublicRoutes>
        // ),
        permissions: [],
    },

    {
        path: "*",
        // element: <NoMatchPage />,
        permissions: [],
    },
];

const allUrls: {
    path: string;
    element?: React.ReactNode;
    permissions: string[];
    children?: {
        path: string;
        element?: React.ReactNode;
        permissions: string[];
    }[];
}[] = [...routes, singleBrandDashboardContent, multiBrandDashboardContent];

export default allUrls;
