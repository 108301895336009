import config from "config/config";
import { authService } from "./Ldb/authService";

async function getLinkToken() {
    const requestOptions = {
        method: "POST",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/trackstar/get-link-token`,
        requestOptions
    );
    return res;
}

async function storeToken(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data)
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/trackstar/store-token`,
        requestOptions
    );
    return res;
}

export const trackstarService = {
    getLinkToken,
    storeToken,
};
