import Button from "components/common/Button";
import Checkbox from "components/common/InputField/Checkbox";
import TextInput from "components/common/InputField/TextInput";
import React, { useEffect, useState } from "react";
import SelectProducts from "./Steps/SelectProducts";
import PreviewSelectedProducts from "./Steps/PreviewSelectedProducts";
import CreateProductBundles from "./Steps/CreateProductBundles";
import ModalContainer from "components/common/ModalContainer";
import useGetArchivedProductsByChannel from "hooks/inventoryHooks/useGetArchivedProductsByChannel";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { useNavigate } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import CustomTable from "components/common/CustomTable";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";

const ImportProducts = () => {
    const [activeStep, setActiveStep] = useState<number>(1);
    const [selectedProducts, setSelectedProducts] = useState<{ [key: string]: any }[]>([]);
    const [importedProductsSuccess, setImportedProductsSuccess] = useState<boolean>(false);
    const [doesUserHaveBundledSkus, setDoesUserHaveBundledSkus] = useState<boolean>(false);
    const [bundlesCreated, setBundlesCreated] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { importingSyncedProducts, importedSyncedProductsSuccess, createMultipleBundlesSuccess } =
        useAppSelector((state) => state.inventory);

    const displayActiveStep = () => {
        switch (activeStep) {
            case 1:
                return (
                    <SelectProducts
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                    />
                );

            case 2:
                return (
                    <PreviewSelectedProducts
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                    />
                );

            case 3:
                return (
                    <CreateProductBundles
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                    />
                );

            default:
                return (
                    <SelectProducts
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                    />
                );
        }
    };

    useEffect(() => {
        if (Boolean(importedSyncedProductsSuccess)) {
            setImportedProductsSuccess(true);
            // dispatch(inventoryActions.resetImportSyncedProducts());
        }
    }, [dispatch, importedSyncedProductsSuccess]);

    useEffect(() => {
        if (Boolean(createMultipleBundlesSuccess)) {
            setBundlesCreated(true);
            dispatch(inventoryActions.resetCreateMultipleBundles());
        }
    }, [dispatch, createMultipleBundlesSuccess]);

    return (
        <>
            {displayActiveStep()}

            <ModalContainer
                open={importedProductsSuccess}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setImportedProductsSuccess(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <i className="ri-box-1-line text-[48px]"></i>
                                <div className="w-8 h-8 absolute bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-[16px] text-white"></i>
                                </div>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Products and SKUs added to your inventory successfully!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    Selected products and SKUs have been added. The inventory list
                                    now reflects these for tracking. Unselected SKUs are not tracked
                                    and will not appear in the inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            {/* <Button
                                btnText={"Remind me later"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            /> */}
                            <Button
                                btnText={"Close "}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setImportedProductsSuccess(false);
                                    setDoesUserHaveBundledSkus(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={doesUserHaveBundledSkus}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {}}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <i className="ri-shopping-basket-line text-[48px]"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Do you have bundled SKUs from these selected products?
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    To better manage your inventory, Synth would like to know if you
                                    have bundled SKUs. Bundled SKUs are combinations of multiple
                                    products or SKUs sold together as a single unit.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"No, I don't"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setDoesUserHaveBundledSkus(false);
                                    navigate(`/dashboard/inventory`,
                                            { state: { internalNavigation: true } });
                                }}
                            />
                            <Button
                                btnText={"Yes, I do"}
                                btnPermission={[
                                    "all_access",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setDoesUserHaveBundledSkus(false);
                                    setActiveStep(3);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={bundlesCreated}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setBundlesCreated(false);
                    navigate(`/dashboard/inventory`,
                                            { state: { internalNavigation: true } });
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-[21px] text-white"></i>
                                </div>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Bundled Product/SKUs Created!
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    You have successfully added all the selected products/SKUs to
                                    create new bundled SKUs, which are now available in your
                                    inventory.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setBundlesCreated(false);
                                    navigate(`/dashboard/inventory`,
                                            { state: { internalNavigation: true } });
                                }}
                            />
                            <Button
                                btnText={"View bundled product/SKUs"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    navigate(`/dashboard/inventory?currProductType=1`, {
                                        state: { internalNavigation: true },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default ImportProducts;
