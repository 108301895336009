import { configureStore } from "@reduxjs/toolkit";
import {
    auth,
    vendor,
    order,
    chat,
    workFlow,
    team,
    shipment,
    settings,
    task,
    businessAnalytics,
    inventory,
    integration,
    customField,
    trackTrace,
    dashboard,
    production,
    sales,
    workforce,
    multibrand,
    marketingCampaign,
} from "../Ldb/reducers";

const store = configureStore({
    reducer: {
        auth,
        vendor,
        order,
        chat,
        workFlow,
        team,
        shipment,
        settings,
        task,
        businessAnalytics,
        inventory,
        integration,
        customField,
        trackTrace,
        dashboard,
        production,
        sales,
        workforce,
        multibrand,
        marketingCampaign,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: true, immutableCheck: true }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
