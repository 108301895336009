import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetCampaignSectionComments = (
    section?: number,
    isResolvedTab?: boolean,
    isMentionTab?: boolean
) => {
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();

    const {
        fetchingCampaignSectionComments,
        fetchCampaignSectionCommentsSuccess,
        fetchCampaignSectionCommentsFailure,
    } = useAppSelector((state) => state.workforce);

    const [comments, setComments] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        if (campaignId) {
            dispatch(
                workforceActions.getCampaignSectionComments(
                    campaignId,
                    section,
                    isResolvedTab,
                    isMentionTab
                )
            );

            return () => {
                dispatch(workforceActions.resetGetCampaignSectionCommentsSuccess());
            };
        }
    }, [dispatch, campaignId, section, isResolvedTab, isMentionTab]);

    useEffect(() => {
        if (Boolean(fetchCampaignSectionCommentsSuccess)) {
            console.log("fetchCampaignSectionCommentsSuccess", fetchCampaignSectionCommentsSuccess);
            setComments(fetchCampaignSectionCommentsSuccess?.comments);
        }
    }, [fetchCampaignSectionCommentsSuccess]);

    return {
        isFetching: fetchingCampaignSectionComments,
        error: fetchCampaignSectionCommentsFailure,
        data: comments,
    };
};

export default useGetCampaignSectionComments;
