import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

type TabsContainerProps = {
    tabs: string[] | JSX.Element[];
    className?: string;
    button?: React.ReactNode;
    children?: React.ReactNode;
    component?: React.ReactNode;
    setActiveTab: (idx: number) => void;
    showTabMenu?: string;
    activeTab: number;
    itemClassName?: string;
    showButton?: React.ReactNode;
    borderLineClase?: string;
    tabContainerClassName?: string;
    showButtonClassName?: boolean;
    totalRecommended?: number;
    tabRowClassname?: string;
};

const TabsContainer = ({
    tabs,
    className,
    button,
    children,
    component,
    setActiveTab,
    showTabMenu,
    activeTab,
    itemClassName,
    showButton,
    borderLineClase,
    tabContainerClassName,
    showButtonClassName,
    totalRecommended,
    tabRowClassname,
}: TabsContainerProps) => {
    const [param] = useSearchParams();
    const tab = param.get("tab");
    const navigate = useNavigate();

    const setActiveTabAndNavigate = (idx: number) => {
        showTabMenu &&
            navigate(`/dashboard/${showTabMenu}/${idx}`, { state: { internalNavigation: true } });
        setActiveTab(idx);
    };

    useEffect(() => {
        if (tab) {
            setActiveTab(Number(tab));
        }
    }, [tab]);

    return (
        <div className="w-full">
            <div className={`w-full ${tabContainerClassName}`}>
                <div
                    className={`flex w-full relative overflow-visible justify-between ${tabRowClassname}`}
                >
                    <div
                        className={`flex w-full gap-x-8 ${button && "mt-2"}  ${showButton && "mt-1"}
                            px-6
                        ${className}`}
                    >
                        {tabs?.map((value, idx) => (
                            <div
                                key={`${value + idx}`}
                                onClick={() => setActiveTabAndNavigate(idx)}
                                className={`${itemClassName} flex items-center text-sm  font-rocGroteskMedium pt-[6.5px] ${
                                    showButton ? "pb-0 " : "pb-2"
                                } cursor-pointer ${
                                    activeTab === idx
                                        ? "text-slate-700 border-y-10 border-b-[2px]"
                                        : "text-slate-500"
                                }`}
                            >
                                {value?.toLowerCase() !== "recommended" ? (
                                    value
                                ) : (
                                    <div className="flex items-center pb-0 space-x-1">
                                        <i className="ri-sparkling-fill text-xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#62CC00] from-0% to-[#FFB902] to-100%"></i>
                                        <p>{value}</p>
                                        <div className="bg-[#E4E5E7] py-1 px-2 rounded-[10px]">
                                            <p className="text-xs text-[#6B778C] font-rocGroteskMedium">
                                                {totalRecommended}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {showButton && (
                        <div className={`flex  justify-end w-full `}>
                            <div
                                className={`max-lg:hidden top-0 flex justify-end ${
                                    showButtonClassName && "absolute"
                                }   w-fit`}
                            >
                                {showButton}
                            </div>
                        </div>
                    )}
                </div>

                <hr className={`text-slate-200 ${borderLineClase}`} />
                {showButton && <div className="hidden max-lg:block ">{showButton}</div>}
                {(activeTab === 0 || activeTab === 1) && button && <>{children}</>}
            </div>
            <div className="">{component}</div>
        </div>
    );
};

export default TabsContainer;
