import React, { useEffect, useState } from "react";
import IntegrationCard from "../../Ldb/Dashboard/Integration/Card";
import { trackstarList } from "variables/trackstarList";
import Button from "components/common/Button";
import { vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";

type trackstarProps = {
    activeTrackstars: any;
    active?: boolean;
    connectedButInactiveTrackstars?: any;
    search?: string;
};
const TrackstarIntegration = ({
    activeTrackstars,
    active,
    connectedButInactiveTrackstars,
    search,
}: trackstarProps) => {
    const dispatch = useAppDispatch();

    const [disconnectedButOnceConnected, setDisconnectedButOnceConnected] = useState<string[]>([]);

    const { manageConnection, manageConnectionSuccess } = useAppSelector((state) => state.vendor);

    const handleDisconnection = (integrationName, activate) => {
        const id = activate
            ? connectedButInactiveTrackstars?.find(
                  (list) => list?.trackstar?.integrationName === integrationName
              )?._id
            : activeTrackstars?.find(
                  (activeList) => activeList?.trackstar?.integrationName === integrationName
              )?._id;
        const body = {
            activate: activate,
            socialId: id,
        };
        dispatch(vendorActions.manageConnection(body));
    };

    useEffect(() => {
        if (Boolean(manageConnectionSuccess)) {
            dispatch(vendorActions.resetManageConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
        }
    }, [dispatch, manageConnectionSuccess]);

    useEffect(() => {
        const ids = connectedButInactiveTrackstars?.map((int) => int?.trackstar?.integrationName);
        setDisconnectedButOnceConnected(ids);
    }, [connectedButInactiveTrackstars]);

    return (
        <>
            {active
                ? trackstarList
                      ?.filter((list) => {
                          const isMatch = list?.title
                              ?.toLowerCase()
                              ?.includes(search?.toLowerCase());
                          const active = activeTrackstars?.some(
                              (int) =>
                                  int?.trackstar?.integrationName === list?.trackstarIntegration
                          );
                          return search ? active && isMatch : active;
                      })
                      ?.map((int, idx) => {
                          return (
                              <div key={idx}>
                                  <IntegrationCard
                                      imageSrc={int?.imageSrc}
                                      imageName={int?.imageName}
                                      title={int?.title}
                                      content={int?.content}
                                      connected={true}
                                      trackstarIntegration={int?.trackstarIntegration}
                                      logoClassname="ml-[-16px] !h-auto !w-auto !bg-transparent !shadow-none"
                                      width={34}
                                      height={34}
                                      disconnectButton=<Button
                                          btnText={`Disconnect ${int?.title}`}
                                          type={"button"}
                                          isLoading={manageConnection}
                                          icon={<i className="ri-indeterminate-circle-line"></i>}
                                          btnClassname="!py-2 !w-fit !bg-btn-light-red_3 !border !border-r-55 !text-[13px] !text-r-55 !px-4"
                                          onClick={() =>
                                              handleDisconnection(int?.trackstarIntegration, false)
                                          }
                                      />
                                  />
                              </div>
                          );
                      })
                : trackstarList
                      ?.filter((int) => {
                          const isMatch = int?.title
                              ?.toLowerCase()
                              ?.includes(search?.toLowerCase());
                          const inactive = !activeTrackstars?.some(
                              (list) =>
                                  list?.trackstar?.integrationName === int?.trackstarIntegration
                          );
                          return search ? inactive && isMatch : inactive;
                      })
                      ?.map((int, idx) => {
                          const isConnectedBefore = disconnectedButOnceConnected?.includes(
                              int?.trackstarIntegration
                          );
                          return (
                              <div key={idx}>
                                  {isConnectedBefore ? (
                                      <div className="">
                                          <IntegrationCard
                                              imageSrc={int?.imageSrc}
                                              imageName={int?.imageName}
                                              title={int?.title}
                                              content={int?.content}
                                              connected={false}
                                              wasConnected={true}
                                              trackstarIntegration={int?.trackstarIntegration}
                                              logoClassname="ml-[-16px] !h-auto !w-auto !bg-transparent !shadow-none"
                                              width={34}
                                              height={34}
                                              disconnectButton=<Button
                                                  btnText={`Connect`}
                                                  type={"button"}
                                                  isLoading={manageConnection}
                                                  icon={
                                                      <i className="ri-external-link-line text-[14px] font-bold "></i>
                                                  }
                                                  btnType="textFirst"
                                                  btnClassname="!py-1.5 !w-fit !bg-slate-100 !border !border-p-70 !text-[13px] !text-p-55 !px-2.5"
                                                  onClick={() =>
                                                      handleDisconnection(
                                                          int?.trackstarIntegration,
                                                          true
                                                      )
                                                  }
                                              />
                                          />
                                      </div>
                                  ) : (
                                      <IntegrationCard
                                          imageSrc={int?.imageSrc}
                                          imageName={int?.imageName}
                                          title={int?.title}
                                          content={int?.content}
                                          connected={false}
                                          trackstarIntegration={int?.trackstarIntegration}
                                          logoClassname="ml-[-16px] !h-auto !w-auto !bg-transparent !shadow-none"
                                      />
                                  )}
                              </div>
                          );
                      })}
        </>
    );
};

export default TrackstarIntegration;
