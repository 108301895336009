import React, { useEffect, useState } from "react";

type TextInputProps = {
    value?: string;
    defaultValue?: string;
    type?: string;
    placeholder?: string;
    onWheel?: React.WheelEventHandler<HTMLInputElement> | undefined;
    inputPlaceholder?: string;
    name?: string;
    min?: string | number | any;
    max?: string | number | any;
    required?: boolean;
    disabled?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
    pattern?: string;
    title?: string;
    minLength?: number;
    maxLength?: number;
    onInput?: React.FormEventHandler<HTMLInputElement> | undefined;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
    inputClassName?: string;
    label?: string;
    labelClassName?: string;
    rightIcon?: JSX.Element | string;
    leftIcon?: JSX.Element | string;
    inputContainerClassname?: string;
    containerClassname?: string;
    floatingPlaceholderClass?: string;
    showInputComment?: boolean;
    inputComment?: JSX.Element | string;
    leftIconClass?: string;
    rightIconClass?: string;
    id?: string;
    hideInputComment?: boolean;
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    focusBorder?: string;
    step?: string;
    widthClass?: string;
    autoCompleteOff?: boolean;
    inputContainerStyles?: { [key: string]: any };
};

const TextInput = ({
    id,
    value,
    defaultValue,
    type,
    placeholder,
    onWheel,
    inputPlaceholder,
    name,
    min,
    max,
    required,
    disabled,
    onKeyDown,
    pattern,
    title,
    minLength,
    maxLength,
    onInput,
    onChange,
    onInvalid,
    inputClassName,
    label,
    labelClassName,
    rightIcon,
    leftIcon,
    inputContainerClassname,
    containerClassname,
    floatingPlaceholderClass,
    showInputComment,
    inputComment,
    leftIconClass,
    rightIconClass,
    hideInputComment,
    onFocus,
    onBlur,
    focusBorder,
    step,
    widthClass,
    autoCompleteOff,
    inputContainerStyles,
}: TextInputProps) => {
    const [inputType, setInputType] = useState("text");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (type) {
            setInputType(type);
        }
    }, [type]);
    return (
        <div className={`w-full ${containerClassname}`}>
            {label && <p className={`${labelClassName} text`}>{label}</p>}

            <div
                className={`w-full relative border-[0.5px]  border-gm-25 h-[50px] ${
                    isFocused && focusBorder
                        ? focusBorder
                        : isFocused
                        ? `!border-g-60 !border-[0.5px] !border-b-[1px] `
                        : ""
                } rounded flex  items-center ${
                    disabled && "!bg-slate-50"
                } ${inputContainerClassname}`}
                style={inputContainerStyles}
            >
                {leftIcon && (
                    <div className={`absolute left-[14px] ${leftIconClass}`}>{leftIcon}</div>
                )}
                <div className={`w-full ${leftIcon && "ml-[15px]"} ${widthClass}`}>
                    <input
                        value={value}
                        type={inputType ? inputType : "text"}
                        className={`inputText  border-0 bg-transparent rounded-b-[8px] outline-0 w-full h-[50px] px-3 py-3 text-sm text-gm-50 
                        font-rocGroteskRegular outline-none
                         placeholder:text-gm-35 placeholder:font-rocGroteskRegular ${inputClassName}`}
                        name={name}
                        defaultValue={defaultValue}
                        required={required}
                        onWheel={onWheel}
                        placeholder={inputPlaceholder}
                        onInput={onInput}
                        onChange={onChange}
                        min={min}
                        max={max}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        onFocus={(e) => {
                            setIsFocused(true);
                            onFocus?.(e);
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                            onBlur?.(e);
                        }}
                        autoComplete={autoCompleteOff && "off"}
                        minLength={minLength}
                        pattern={pattern}
                        title={title}
                        disabled={disabled}
                        id={id}
                        onInvalid={onInvalid}
                        step={step}
                    />
                    {placeholder && (
                        <span
                            className={`floating-label  text-sm text-gm-35 ${
                                leftIcon && !value && "ml-[20px]"
                            } h-[50px] flex items-center -mt-4  font-rocGroteskRegular ${floatingPlaceholderClass}`}
                        >
                            {placeholder}
                            {required && !isFocused && (
                                <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                    *
                                </span>
                            )}
                        </span>
                    )}
                </div>
                {type === "password" && (
                    <div
                        onClick={() => {
                            setInputType(inputType === "text" ? "password" : "text");
                        }}
                        className="absolute right-[14px]"
                    >
                        {inputType === "password" ? (
                            <i className="ri-eye-line before:content-['\ecb5'] before:text-gm-25 text-xl cursor-pointer"></i>
                        ) : (
                            <i className="ri-eye-close-line before:content-['\ecb3'] before:text-gm-25 text-xl cursor-pointer"></i>
                        )}
                    </div>
                )}
                {rightIcon && (
                    <div className={`absolute right-[14px] ${rightIconClass}`}>{rightIcon}</div>
                )}
            </div>
            {showInputComment && (
                <div className="flex ml-1 items-center space-x-1">
                    {!hideInputComment && (
                        <div className="bg-gm-25 flex justify-center items-center h-[10px] rounded-full w-[10px]">
                            <img
                                alt="vector"
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/icons/notice_iy2t2o.svg"
                            />
                        </div>
                    )}
                    <p className="text-[10px] mt-0.5 text-gm-35 font-rocGroteskRegular">
                        {inputComment}
                    </p>
                </div>
            )}
        </div>
    );
};

export default TextInput;
