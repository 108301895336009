import Button from "../../../../../../common/Button";
import SelectInput from "../../../../../../common/InputField/SelectInput";
import TextInput from "../../../../../../common/InputField/TextInput";
import ModalContainer from "../../../../../../common/ModalContainer";
import TabsContainer from "../../../../../../common/TabsContainer";
import VendorAvatar from "../../../../../../common/VendorAvatar";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductsVariant from "../../../../../../views/Synth2.0/Inventory/Tabs/ProductList/GlobalProductDetail/Tabs/ProductsVariant";
import IntegrationCard from "../../../../../../views/Ldb/Dashboard/Integration/Card";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import PageFrame from "../../../../../../layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "../../../../../../../helpers";
import { inventoryActions } from "../../../../../../../redux/Ldb/actions";
import { uniq } from "lodash";
import toast from "react-hot-toast";
import CustomToast from "../../../../../../common/CustomToast";
import { displayProductChannelIcon } from "../../../../../../../helpers/displayProductChannelIcon";
import { useNavigationHandler } from "../../../../../../../hooks/useNavigationHandler";
import { AccountPermissions } from "../../../../../../../types/permissions";
import useGetGlobalProductList from "../../../../../../../hooks/inventoryHooks/useGetGlobalProductList";

const GlobalProductDetail = () => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const navigate = useNavigate();

    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/inventory" });
    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["All products variants"];

    const { productDetails, productList, pagination, setUpdatedPage, isLoadingMore, isFetching } =
        useGetGlobalProductList();

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ProductsVariant
                        productList={productList}
                        pagination={pagination}
                        setUpdatedPage={setUpdatedPage}
                        isLoadingMore={isLoadingMore}
                        isFetching={isFetching}
                    />
                );
        }
    };

    return (
        <PageFrame isLoading={isFetching}>
            <div className="w-full pb-8 bg-white border border-slate-100 rounded relative max-h-[85vh] overflow-y-auto">
                <div className="w-full sticky top-0 bg-white z-[9999]">
                    <div className="top-[-25px] md:px-8 py-4 px-[28px] w-full border-b border-n-20 flex items-center justify-between">
                        <div className="flex items-center">
                            <div
                                onClick={() => goBack()}
                                className="cursor-pointer space-x-1 flex items-center justify-center"
                            >
                                <i className="ri-arrow-left-line text-sm "></i>
                                <p className="text-sm underline text-gm-50 font-rocGroteskMedium">
                                    Back
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <div>
                                <Button
                                    btnText="Close"
                                    onClick={() => goBack()}
                                    btnClassname="!px-4 !py-2.5 !bg-transparent !border-[1px] !border-n-20 !h-10"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full ">
                    <div className="flex items-center w-full md:px-8 pt-8 px-[28px] space-x-8 pb-6">
                        <div className="w-[112px] h-[112px]">
                            <div className="relative w-full flex p-1 items-center justify-center border rounded border-slate-200">
                                {productDetails?.image?.src ? (
                                    <VendorAvatar
                                        imageSrc={productDetails?.image?.src}
                                        size={102}
                                        name={productDetails?.productName}
                                        containerClassname="!rounded-none"
                                    />
                                ) : (
                                    <div className="w-[102px] h-[102px] rounded flex items-center justify-center bg-slate-200 ">
                                        <i className="ri-box-2-fill text-[60px] text-slate-500"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full  h-fit flex items-start  border-slate-100 justify-between">
                            <div className="w-full font-rocGroteskMedium">
                                <h3 className="text-slate-700 text-2xl font-rocGroteskMedium">
                                    {productDetails?.productName}
                                </h3>
                                <span className="block text-lg font-rocGroteskMedium">
                                    <span className="text-base text-slate-500">
                                        Total available qty:{" "}
                                        {productDetails?.availableStock !== null
                                            ? formatMoney()?.format(productDetails?.availableStock)
                                            : ""}{` `}
                                        {productDetails?.availableStock &&
                                        productDetails?.unitOfMeasurement
                                            ? productDetails?.unitOfMeasurement
                                            : ""}
                                    </span>
                                </span>
                                <div className="flex">
                                    {productDetails?.channels?.map((data) => (
                                        <div className="!w-fit flex items-center space-x-1.5 border border-slate-200 bg-slate-50 h-6 rounded-[3px] px-2 py-[2px]">
                                            {displayProductChannelIcon(data, "!w-3 !h-3")}
                                            <span className="text-sm text-g-75">
                                                {data || "---"}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:px-8 pt-2 px-[28px]">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={(idx) => {
                                setActiveTab(idx);
                                navigate(
                                    `/dashboard/inventory/global-products/${productId}?currTab=${idx}`,
                                    { replace: true, state: { internalNavigation: true } }
                                );
                            }}
                            component={displayActiveTab()}
                            className="!px-0"
                            itemClassName="!pb-2"
                            borderLineClase={"!text-slate-100  w-full  mb-4"}
                        />
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default GlobalProductDetail;
