import BreadCrumb from "components/common/BreadCrumb";
import CustomTable from "components/common/CustomTable";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import { displayFileIcon, hasHTMLTag, sanitizeHtml } from "helpers";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import React from "react";
import { useNavigate } from "react-router-dom";

const QuoteDetails = () => {
    const navigate = useNavigate();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/order" });
    const tableHeader = [
        { title: "Item", widthClass: "w-[40.7%]" },
        { title: "Qty", widthClass: "w-[16.7%]" },
        { title: "Unit price", widthClass: "w-[16.6%]" },
        { title: "Total", widthClass: "w-[16.6%]" },
    ];

    const tableBody = [1, 2, 3]?.map((order, idx) => [
        {
            content: (
                <div className={`py-5 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    <p className="text-slate-500 ">Fruits & Veget...</p>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-5 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    50
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-5 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    50
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    $500
                </div>
            ),
        },
    ]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div
                onClick={() => goBack()}
                className="cursor-pointer space-x-3 flex items-center py-4"
            >
                <i className="ri-arrow-left-line text-sm "></i>
                <p className="text-sm underline  font-rocGroteskMedium">Back</p>
            </div>
            <div className="mb-5 py-4 border-b border-[#F1F5F9]">
                <p className="text-[22px] font-rocGroteskMedium text-gm-50">Quote details</p>
            </div>

            <div className=" flex ">
                <div className="w-[74%]  px-4">
                    <div className="flex items-center justify-between pb-3">
                        <div className=" w-[20.5%]">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Quote ID
                            </span>
                            <span className="block text-base font-rocGroteskMedium">Q 123</span>
                        </div>

                        <div className=" w-[20.5%]">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Issued date
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                Jan 23, 2024
                            </span>
                        </div>

                        <div className=" w-[20.5%]">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Due date
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                Jan 23, 2024
                            </span>
                        </div>

                        <div className=" w-[20.5%]">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Currency
                            </span>
                            <span className="block text-base font-rocGroteskMedium">USD</span>
                        </div>
                    </div>

                    <div className="border-b  border-[#E0E0E0] my-2" />

                    <div className="mt-5">
                        <div className="flex items-center justify-between mb-4">
                            <span className="block text-base font-rocGroteskMedium ">
                                Product / Service details
                            </span>
                        </div>
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                                tableClass="!w-fill-available"
                            />
                        </div>
                    </div>

                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />

                    <div>
                        <span className="block text-base font-rocGroteskMedium mb-4">
                            Breakdown
                        </span>
                        <div className="space-y-2">
                            <div className="flex justify-between items-center pb-2 border-b border-[#E2E8F0]">
                                <p className="text-sm font-rocGroteskMedium text-[#64748B]">
                                    Total quantity
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">20</p>
                            </div>
                            <div className="flex justify-between items-center pb-2 border-b border-[#E2E8F0]">
                                <p className="text-sm font-rocGroteskMedium text-[#64748B]">
                                    Sub total
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">20</p>
                            </div>
                            <div className="flex justify-between items-center pb-2 border-b border-[#E2E8F0]">
                                <p className="text-sm font-rocGroteskMedium text-[#64748B]">
                                    Tax (10%)
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">20</p>
                            </div>
                            <div className="flex justify-between items-center pb-2 border-b border-[#E2E8F0]">
                                <p className="text-sm font-rocGroteskMedium text-[#64748B]">
                                    Discount
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">5%</p>
                            </div>
                            <div className="flex justify-between items-center pb-2 ">
                                <p className="text-sm font-rocGroteskMedium text-[#64748B]">
                                    Total
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">200</p>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    <div className="mb-4">
                        <span className="block mb-4 text-base font-rocGroteskMedium ">
                            Additional information
                        </span>
                        <h6 className="flex justify-between mb-3 text-sm font-rocGroteskMedium text-gm-50">
                            Terms & Conditions
                        </h6>
                        <div className="space-y-0.5 text-sm font-rocGroteskMedium text-slate-500">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: hasHTMLTag("customerDetails?.termsAndCondition")
                                        ? sanitizeHtml(
                                              "customerDetails?.termsAndCondition"
                                          ).sanitizedHTML?.join("")
                                        : "customerDetails?.termsAndCondition",
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-[26%]  p-4 border #E2E8F0">
                    <div className="">
                        <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-3">
                            Customer details
                        </span>
                        <div className="space-y-2">
                            <div className="flex items-center space-x-3">
                                <VendorAvatar name="avatar" size={24} />
                                <span className="text-base font-rocGroteskMedium">John Snow</span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-mail-line text-slate-400 text-2xl"></i>
                                <span className="text-base font-rocGroteskMedium">
                                    augustineasiuwhu
                                </span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-phone-line text-slate-400 text-2xl"></i>
                                <span className="text-base font-rocGroteskMedium">
                                    (+234) 8000000000
                                </span>
                            </div>
                            <div className="">
                                <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1">
                                    Billing address
                                </span>
                                <p className=" font-rocGroteskMedium text-[15px] leading-[21px]">
                                    USA, Chicago, Illinois, 123 Block City
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                    <div className="">
                        <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-3">
                            Supplier details
                        </span>
                        <div className="space-y-2">
                            <div className="flex items-center space-x-3">
                                <VendorAvatar name="avatar" size={24} />
                                <span className="text-base font-rocGroteskMedium">John Snow</span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-mail-line text-slate-400 text-2xl"></i>
                                <span className="text-base font-rocGroteskMedium">
                                    augustineasiuwhu
                                </span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-phone-line text-slate-400 text-2xl"></i>
                                <span className="text-base font-rocGroteskMedium">
                                    (+234) 8000000000
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                    <div className="mt-4  w-full gap-4">
                        <div className="flex items-center justify-between p-3 w-full rounded-md border border-slate-200">
                            <div className="flex items-center gap-2">
                                {displayFileIcon("pdf")}
                                <div className="font-rocGroteskMedium">
                                    <p className="text-sm">Quotation.pdf</p>
                                    {/* <p className="text-xs text-slate-500">4 MB</p> */}
                                </div>
                            </div>
                            <i
                                onClick={() => {}}
                                className="ri-download-line text-xl text-gm-50 cursor-pointer"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoteDetails;
