import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetWarehouseStats from "hooks/inventoryHooks/useGetWarehouseStats";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import { cloneDeep, debounce, filter, truncate } from "lodash";
import moment, { duration } from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { inventoryActions } from "redux/Ldb/actions";
import jsonToCsvExport from "json-to-csv-export";
import { MultipleDropdowns } from "components/common/MultipleDropdown";
import { AccountPermissions } from "types/permissions";
import useGetWarehouseGlobalProduct from "hooks/inventoryHooks/useGetWarehouseGlobalproduct";
import EmptyStateComp from "components/common/EmptyStateComp";
import TabsContainer from "components/common/TabsContainer";
import SelectInput from "components/common/InputField/SelectInput";

const WarehouseDetails = () => {
    const { id } = useParams();
    const limit = 10;
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/inventory" });
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const tableTabs = ["Variants", "All Products"];
    const [warehouseDetails, setWarehouseDetails] = useState<any>(null);
    const [warehouseProducts, setWarehouseProducts] = useState<{ [key: string]: any }[]>([]);
    const [warehouseList, setWarehouseList] = useState<any>([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState(false);
    const [modalProductFilter, setModalProductFilter] = useState<string>("");
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [deleteWarehouseShow, setDeleteWarehouseShow] = useState(false);
    const [modalChannelFilter, setModalChannelFilter] = useState<string>("");
    const [allProductsSearch, setAllProductsSearch] = useState<string>("");

    const [filters, setFilters] = useState({
        search: "",
        channel: "",
        duration: "",
    });
    const [stockFilter, setStockFilter] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const clearSearchRef = useRef(null);
    const [filterModal, setFilterModal] = useState<boolean>(false);

    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });

    const {
        fetchingSingleWarehouseList,
        fetchedSingleWarehouseListSuccess,
        fetchingListStockWarehouse,
        fetchedListStockWarehouseSuccess,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();
    const {
        data: productData,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        setSearch,
        // setDebouncedSearch,
        pagination,
        isLoadingMore,
    } = useGetProductList();
    const { data: stats, isFetching: statsLoading } = useGetWarehouseStats();
    const dashboardContent = document.getElementById("custom-table");

    const stockFilterList = [
        {
            label: "In stock ",
            value: "instock",
        },
        {
            label: "Overstocking",
            value: "overstocking",
        },
        {
            label: "Stock out",
            value: "stockout",
        },
        {
            label: "Low stock",
            value: "lowstock",
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const filterModalRef = document.getElementById("filterModalRef");
            if (filterModalRef && !filterModalRef?.contains(event.target as Node)) {
                setFilterModal(false);
            }
        };

        if (filterModal) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterModal]);

    const handleAllProductsSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAllProductsSearch(e.target.value);
    };

    const handleFilter = (e) => {
        setFilters({
            ...filters,
            search: e.target.value,
        });
    };
    const handleDebounceFilter = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 800);

    const getStartAndEndDate = (duration: string) => {
        let startDate, endDate;
        if (duration === "yearly") {
            startDate = moment().startOf("year").toISOString();
            endDate = moment().endOf("year").toISOString();
        } else if (duration === "monthly") {
            startDate = moment().startOf("month").toISOString();
            endDate = moment().endOf("month").toISOString();
        } else if (duration === "weekly") {
            startDate = moment().startOf("week").toISOString();
            endDate = moment().endOf("week").toISOString();
        } else if (duration === "daily") {
            startDate = moment().startOf("day").toISOString();
            endDate = moment().endOf("day").toISOString();
        }
        return { startDate, endDate };
    };

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(id));
    };

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());
            setDeleteWarehouseShow(false);
            goBack();
        }
    }, [deleteWarehouseSuccess]);

    useEffect(() => {
        const period = filters?.duration?.split("-");
        const { startDate, endDate } = getStartAndEndDate(period?.[0]);

        dispatch(
            inventoryActions.fetchSingleWarehouseList(
                id,
                null,
                debouncedSearch,
                filters?.channel,
                startDate,
                endDate
            )
        );
    }, [id, debouncedSearch, filters?.channel, filters?.duration]);

    const {
        data: allProductsList,
        isFetching: allProductsListLoading,
        pagination: allProductsPagination,
        isLoadingMore: allProductsLoadingMore,
        setAllProductsNoVariantValues,
        setStockLevelRange: setStockLevelRange,
        stockLevelRange: stockLevelRange,
        setForecastedNeedsRange,
        forecastedNeedsRange,
        handleDebouncedChange: handleAllProductsDebouncedChange,
        channelFilter: channelNoVariantFilter,
        setChannelFilter: setChannelNoVariantFilter,
        stockLevelFilter: stockLevelNoVariantFilter,
        setStockLevelFilter: setStockLevelNoVariantFilter,
        setApplyFilter,
        applyFilter,
        debouncedSearch: debounceSeachNoVariant,
    } = useGetWarehouseGlobalProduct();

    useEffect(() => {
        if (Boolean(fetchedSingleWarehouseListSuccess)) {
            setWarehouseDetails(fetchedSingleWarehouseListSuccess?.warehouse);
            setWarehouseProducts(fetchedSingleWarehouseListSuccess?.warehouse?.products);
        }
    }, [fetchedSingleWarehouseListSuccess]);

    // useEffect(() => {
    //     if (Boolean(fetchedListStockWarehouseSuccess)) {
    //         setWarehouseList(fetchedListStockWarehouseSuccess?.products);
    //         setWarehousePagination({
    //             current: fetchedListStockWarehouseSuccess?.pagination?.current,
    //             number_of_pages: fetchedListStockWarehouseSuccess?.pagination?.number_of_pages,
    //         });
    //     }
    // }, [fetchedListStockWarehouseSuccess]);

    useEffect(() => {
        if (fetchedSingleWarehouseListSuccess?.warehouse?.products?.length >= 1) {
            const copyArray = cloneDeep(fetchedSingleWarehouseListSuccess?.warehouse?.products);
            const filteredProducts = copyArray?.filter((item) => {
                if (stockFilter === "lowstock") {
                    return (
                        item?.warehouseStockLevel <=
                        item?.inventory?.productStockSettings?.minimumStockLevel
                    );
                }
                if (stockFilter === "instock") {
                    return (
                        item?.warehouseStockLevel >
                            item?.inventory?.productStockSettings?.minimumStockLevel &&
                        !item?.incidentType
                    );
                }
                if (stockFilter === "stockout" || stockFilter === "overstocking") {
                    return item?.incidentType === stockFilter;
                }
                return item;
            });
            setWarehouseProducts(filteredProducts);
        }
    }, [stockFilter, fetchedSingleWarehouseListSuccess?.warehouse?.products]);

    const dateValues2 = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "This week",
            value: "weekly",
        },

        {
            period: "Today",
            value: "daily",
        },
        {
            period: "All times",
            value: "",
        },
    ];

    const handleExport = () => {
        const exportData = warehouseProducts?.map((item) => {
            return {
                "Product Name": item?.inventory?.productName,
                "Product SKU": item?.inventory?.sku,
                "Current Qty": formatMoney().format(item?.warehouseStockLevel),
                "Incident Type":
                    item?.incidentType === "overstocking"
                        ? "Likely overstocking"
                        : item?.incidentType === "stockout"
                        ? "Stockout"
                        : item?.warehouseStockLevel <=
                          item?.inventory?.productStockSettings?.minimumStockLevel
                        ? "Low stock"
                        : "N/A",
                "Last Restocked": item?.lastSupplied
                    ? moment(item?.lastSupplied)?.format("MMM DD YYYY")
                    : "N/A",
                Supplier: item?.lastSupplier || "N/A",
                "Supplier lead time":
                    item?.leadTime?.value && item?.leadTime?.duration
                        ? `${item?.leadTime?.value} ${item?.leadTime?.duration}`
                        : "N/A",
                "Forecasted needs": item?.forecastNeeds
                    ? formatMoney().format(item?.forecastNeeds?.toFixed(0))
                    : "N/A",
                "Demand forecast": item?.demandForecast
                    ? formatMoney().format(item?.demandForecast?.toFixed(0))
                    : "N/A",
                "Stockout Date":
                    item?.warehouseStockoutDate !== "N/A"
                        ? moment(item?.warehouseStockoutDate)?.format("MMM DD, YYYY")
                        : "N/A",
                "Unit price": `${getCurrencyFromCurrencyCode(item?.inventory?.unitCost?.currency)}${
                    item?.inventory?.unitCost?.amount
                }`,
                Action:
                    item?.incidentType === "stockout" ||
                    item?.warehouseStockLevel <=
                        item?.inventory?.productStockSettings?.minimumStockLevel
                        ? "Restock"
                        : "N/A",
            };
        });

        jsonToCsvExport({
            data: exportData,
            filename: "warehouse_product_list",
        });
    };

    const tableHeader = [
        { title: "Product", widthClass: "w-[250px]", sortIcon: false },
        { title: "Current Qty", widthClass: "w-[150px]", sortIcon: false },
        { title: "Last Restocked", widthClass: "w-[150px]", sortIcon: false },
        { title: "Supplier", widthClass: "w-[200px]" },
        { title: "Supplier lead time", widthClass: "w-[150px]" },
        { title: "Forecasted needs", widthClass: "w-[150px]" },
        { title: "Demand forecast", widthClass: "w-[150px]", sortIcon: false },
        { title: "Stockout Date", widthClass: "w-[150px]", sortIcon: false },
        { title: "Unit price", widthClass: "w-[150px]", sortIcon: false },
        { title: "Action", widthClass: "w-[140px]" },
    ];

    const tableBody = warehouseProducts?.map((item, idx) => [
        {
            content: (
                <div
                    key={item?._id}
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        {item?.inventory?.productImageDetails?.productAvatar ? (
                            <VendorAvatar
                                name={item?.inventory?.productName}
                                imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                                size={32}
                                containerClassname="!rounded-none !border !bg-white"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}
                        <div>
                            <a id={`name-${idx}`}>
                                <span>
                                    {truncate(item?.inventory?.productName, { length: 20 })}
                                </span>
                                <p className=" text-slate-500">SKU: {item?.inventory?.sku}</p>
                            </a>
                            <Tooltip
                                anchorSelect={`#name-${idx}`}
                                place={"right"}
                                content={item?.inventory?.productName}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 99999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-y-1 flex flex-col items-start h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    <p className={item?.incidentType === "overstocking" && "text-r-55"}>
                        {formatMoney().format(item?.warehouseStockLevel) || "N/A"}
                    </p>
                    {(item?.incidentType ||
                        item?.warehouseStockLevel <=
                            item?.inventory?.productStockSettings?.minimumStockLevel) && (
                        <div
                            className={`${
                                item?.incidentType === "overstocking"
                                    ? "bg-[#FFD8D2] text-[#FF3D1D]"
                                    : item?.incidentType === "stockout"
                                    ? "bg-[#FFF2CF] text-[#FD700B]"
                                    : item?.warehouseStockLevel <=
                                      item?.inventory?.productStockSettings?.minimumStockLevel
                                    ? "bg-[#E4EDFF] text-[#004253]"
                                    : ""
                            }  rounded-[10px] px-2 py-[2px]`}
                        >
                            <p className="text-[8px] leading-[11px] capitalize  font-rocGroteskMedium">
                                {/* {item?.incidentType === "overstocking" && "Likely"}{" "} */}
                                {item?.incidentType || "low stock"}
                            </p>
                        </div>
                    )}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.lastSupplied
                        ? moment(item?.lastSupplied)?.format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {/* <VendorAvatar
                            name="05"
                            imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                            size={16}
                            containerClassname="!rounded-none !border !bg-white"
                        /> */}

                        <span>
                            {item?.lastSupplier
                                ? truncate(item?.lastSupplier, { length: 20 })
                                : "N/A"}
                        </span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.leadTime?.value && item?.leadTime?.duration
                        ? `${item?.leadTime?.value} ${item?.leadTime?.duration}`
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.incidentType === "overstocking" ? (
                        "0"
                    ) : item?.incidentType && item?.forecastNeeds ? (
                        <div className="flex items-center space-x-1.5">
                            <span className="material-symbols-outlined text-[14px] text-r-50">
                                warning
                            </span>
                            <span>{formatMoney().format(item?.forecastNeeds?.toFixed(0))}</span>
                        </div>
                    ) : (
                        "0"
                    )}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        {item?.demandForecast
                            ? formatMoney().format(item?.demandForecast?.toFixed(0))
                            : "N/A"}
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.warehouseStockoutDate !== "N/A"
                        ? moment(item?.warehouseStockoutDate)?.format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        {getCurrencyFromCurrencyCode(item?.inventory?.unitCost?.currency)}
                        {item?.inventory?.unitCost?.amount}
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    {(item?.incidentType === "stockout" ||
                        item?.warehouseStockLevel <=
                            item?.inventory?.productStockSettings?.minimumStockLevel) && (
                        <Button
                            btnText="Restock"
                            btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                            btnPermission={[
                                AccountPermissions.all_access,
                                AccountPermissions.create_purchase_order,
                            ]}
                            onClick={() => {
                                navigate(
                                    `/dashboard/purchase/create-purchase-request?inventory=${item?.inventory?._id}`,
                                    { state: { internalNavigation: true } }
                                );
                                // handleIncidentId(product?._id);
                            }}
                        />
                    )}

                    {/* <Dropdown
                        name="filter"
                        value={""}
                        dropdownTriggerClassName={" "}
                        // handleChange={(name, value) => handleFilterChange(value)}
                        dropdown={
                            <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                        Re-order
                                    </span>
                                </div>
                                <div className=" py-2.5">
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                            </div>
                        }
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 left-[-38px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                        dropdownOptions={[
                            {
                                customChild: (
                                    <div>
                                        <div className="py-[2px] px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[10px] whitespace-nowrap text-slate-500 font-rocGroteskMedium">
                                                Schedule re-order date
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Re-order tomorrow
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Re-order next week
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Custom date
                                            </span>
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    /> */}
                </div>
            ),
            widthClass: "!border-b",
        },
    ]);

    useEffect(() => {
        (inputRef?.current as any)?.focus();
    }, []);

    const options = [
        {
            label: "All Channels",

            children: [
                {
                    label: (
                        <div className="text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2">
                            <i
                                className={`${
                                    filters?.channel === ""
                                        ? "ri-checkbox-line text-carribean-green"
                                        : "ri-checkbox-blank-line text-slate-200"
                                }    text-xl`}
                            ></i>
                            All channels
                        </div>
                    ),
                    value: "",
                    className: "!border-b !border-b-slate-100 px-4 py-[10px]",
                    onClick: () => {
                        setFilters({ ...filters, channel: "" });
                    },
                },
                ...salesChannel?.map((item, idx) => ({
                    label: (
                        <div
                            key={idx}
                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2 capitalize"
                        >
                            <i
                                className={`${
                                    filters?.channel === item
                                        ? "ri-checkbox-line text-carribean-green"
                                        : "ri-checkbox-blank-line text-slate-200"
                                }    text-xl`}
                            ></i>
                            {item}
                        </div>
                    ),
                    value: item,
                    className: "!border-b !border-b-slate-100 px-4 py-[10px]",

                    onClick: () => {
                        setFilters({ ...filters, channel: item });
                    },
                })),
            ],
            onClick: () => {},
            className: "!border-b !border-b-slate-100 !text-[13px] !text-slate-600",
        },
        {
            label: "All Status",

            children: [
                {
                    label: (
                        <div
                            className="text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                            "
                        >
                            Filter by status
                        </div>
                    ),
                    className: "!border-b !border-b-slate-100 px-4 py-1 !cursor-default",
                    onClick: () => {},
                },
                {
                    label: (
                        <div
                            className="text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                            "
                        >
                            <i
                                className={`${
                                    stockFilter === ""
                                        ? "ri-checkbox-line text-carribean-green"
                                        : "ri-checkbox-blank-line text-slate-200"
                                }    text-xl`}
                            ></i>
                            All status
                        </div>
                    ),
                    value: "",
                    className: "!border-b !border-b-slate-100 px-4 py-[10px]",

                    onClick: () => {
                        setStockFilter("");
                    },
                },
                ...stockFilterList?.map((item, idx) => ({
                    label: (
                        <div
                            key={idx}
                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                capitalize"
                        >
                            <i
                                className={`${
                                    item?.value === stockFilter
                                        ? "ri-checkbox-line text-carribean-green"
                                        : "ri-checkbox-blank-line text-slate-200"
                                }    text-xl`}
                            ></i>
                            {item?.label}
                        </div>
                    ),
                    className: "!border-b !border-b-slate-100 px-4 py-[10px]",
                    value: item,
                    onClick: () => {
                        setStockFilter(item?.value);
                    },
                })),
            ],
            onClick: () => {},
        },
    ];

    const handleSortNoVariantTable = (idx: number, sortValue: number) => {
        let sortBy;
        if (idx === 0) {
            sortBy = "productName";
        } else if (idx === 1) {
            sortBy = "stockLevel";
        } else if (idx === 2) {
            sortBy = "variantCount";
        } else if (idx === 3) {
            sortBy = "forecastedNeeds";
        } else if (idx === 4) {
            sortBy = "stockoutDate";
        }
        setAllProductsNoVariantValues({
            sortBy,
            ...(sortValue === 1 ? { sortDirection: "asc" } : { sortDirection: "desc" }),
        });
    };
    const allProductsTableHeader = [
        { title: "Product name", widthClass: "w-[300px]", sortIcon: true },
        {
            title: "Current stock level",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "product-name",
            tooltipContent: "The total available stock for this product across all locations.",
        },
        {
            title: "Number of variants",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "variants",
            tooltipContent:
                "The total number of variations (e.g., sizes, colors, packaging) for this product.",
        },
        {
            title: "Forecasted needs",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "Forecasted-needs",
            tooltipContent:
                "The estimated stock required to meet demand within the forecasting period, based on product lead time.",
        },
        {
            title: "Stockout date",
            widthClass: "w-[200px]",
            sortIcon: true,
            tooltipIcon: "Stockout-date",
            tooltipContent:
                "The earliest date when any variant of this product is expected to run out of stock, based on product lead time.",
        },
    ];

    const allProductsTableBody = allProductsList?.map((product, idx) => {
        const givenDate = moment(product?.stockoutDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }

        return [
            {
                content: (
                    <div
                        className={`py-3 px-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImage?.src ? (
                                <VendorAvatar
                                    imageSrc={product?.productImage?.src}
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`all-product-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#all-product-column-${idx}`}
                                        place={"right"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/global-products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.stockLevel ? formatMoney().format(product?.stockLevel) : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.variantCount
                                ? formatMoney().format(product?.variantCount)
                                : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    // <div
                    //     className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    // >
                    //     <div className="flex items-center space-x-1.5">
                    //         {Boolean(product?.replenishment) && (
                    //             <div>
                    //                 <a id={`forecast-needs-${idx}`}>
                    //                     <span className="material-symbols-outlined text-[14px] text-r-50">
                    //                         warning
                    //                     </span>
                    //                 </a>

                    //                 <Tooltip
                    //                     anchorSelect={`#forecast-needs-${idx}`}
                    //                     place={"top"}
                    //                     content={
                    //                         Number(product?.productStockDetails?.stockLevel) < 1
                    //                             ? "Product is out of stock"
                    //                             : "Stock for this product may soon run out."
                    //                     }
                    //                     style={{ width: "180px", zIndex: 9999 }}
                    //                 />
                    //             </div>
                    //         )}
                    //         <span>
                    //             {product?.replenishment
                    //                 ? Math.round(product?.replenishment)?.toLocaleString()
                    //                 : "N/A"}
                    //         </span>
                    //     </div>
                    // </div>
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.forecastedNeeds
                                ? formatMoney().format(product?.forecastedNeeds)
                                : 0}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium !border-b border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="text-sm font-rocGroteskMedium">
                                {differenceInDays <= 0 ? (
                                    "N/A"
                                ) : (
                                    <>
                                        <span
                                            className={`block ${
                                                differenceInDays > 10
                                                    ? "text-carribean-green"
                                                    : "text-[#E4281D]"
                                            }`}
                                        >
                                            {formatMoney().format(differenceInDays)} days
                                        </span>
                                        <span className={`block text-slate-500`}>
                                            {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                ),
                widthClass: "!border-b",
            },
        ];
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return fetchingSingleWarehouseList ? (
                    <CustomTableLoader tableHeader={tableHeader} />
                ) : (
                    <div className={` w-full mt-2`} id="custom-table">
                        {warehouseProducts?.length > 0 ? (
                            <>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    stickyHeader={true}
                                    isAllSelectable={false}
                                    isScrollable={true}
                                    isCellBordered={true}
                                    headerContainerClass="!bg-slate-50  !border-slate-100 "
                                    bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                    headerItemClass="font-rocGroteskMedium !text-slate-700"
                                    rightFixedColumnCount={1}
                                    leftFixedColumnCount={0}
                                />

                                {loadMoreWarehouse && fetchingListStockWarehouse && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {warehousePagination?.current ===
                                    warehousePagination?.number_of_pages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex items-center justify-center mt-[76px]">
                                <div>
                                    <img
                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                        alt="empty state"
                                        className="w-[268px] h-[235px]"
                                    />
                                    <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                        You have no product
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                );

            case 1:
                return allProductsListLoading && allProductsList.length === 0 ? (
                    <CustomTableLoader tableHeader={allProductsTableHeader?.slice(0, 5)} />
                ) : allProductsList?.length > 0 ? (
                    <div className="overflow-x-scroll mb-6">
                        <div id="" className="">
                            <CustomTable
                                tableBody={allProductsTableBody}
                                tableHeader={allProductsTableHeader}
                                isCellBordered={true}
                                stickyHeader={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                                isScrollable={true}
                                sort={handleSortNoVariantTable}
                            />
                        </div>

                        {allProductsLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {allProductsPagination?.currentPage ===
                            allProductsPagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : channelNoVariantFilter ||
                  stockLevelRange?.stockLevelMax ||
                  stockLevelRange?.stockLevelMin ||
                  forecastedNeedsRange?.forecastedDemandMax ||
                  forecastedNeedsRange?.forecastedDemandMin ||
                  debounceSeachNoVariant ? (
                    <div className="flex items-center justify-center flex-col mt-20">
                        {allProductsListLoading ? (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        ) : (
                            <p className="text-gm-40 text-sm font-rocGroteskMedium max-w-[330px] text-center mb-6">
                                No data matches your search query
                            </p>
                        )}
                    </div>
                ) : (
                    <div className="flex items-center justify-center mt-[76px]">
                        <div>
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                alt="empty state"
                                className="w-[268px] h-[235px]"
                            />
                            <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                You have no product
                            </p>
                        </div>
                    </div>
                );

            default:
                break;
        }
    };

    return (
        <PageFrame isLoading={(fetchingSingleWarehouseList && !warehouseDetails) || statsLoading}>
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100">
                            <div
                                className="flex items-center space-x-2 w-fit cursor-pointer"
                                onClick={() => goBack()}
                            >
                                <i className="ri-arrow-left-line text-[#042821]"></i>
                                <p className="text-sm underline font-rocGroteskMedium">Back</p>
                            </div>
                            <div className="flex items-center gap-3">
                                <Button
                                    btnText="Delete"
                                    onClick={() => setDeleteWarehouseShow(true)}
                                    btnClassname="!px-4 !py-2.5 border !border-slate-300 !bg-tradeally-neutral-20 !w-fit"
                                    icon={<i className="ri-delete-bin-5-line text-[#142837]"></i>}
                                />
                                <Button
                                    btnText="Edit details"
                                    btnClassname="!px-4 !py-2.5 border !border-slate-300 !bg-tradeally-neutral-20 !w-fit"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                        AccountPermissions.add_stock,
                                        AccountPermissions.transfer_stock,
                                    ]}
                                    onClick={() =>
                                        navigate(`/dashboard/inventory/edit-warehouse/${id}`, {
                                            state: { internalNavigation: true },
                                        })
                                    }
                                    icon={<i className="ri-edit-2-line text-[#142837]"></i>}
                                />

                                <Button
                                    btnText="Add products"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.add_stock,
                                        AccountPermissions.transfer_stock,
                                    ]}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/stock-control/stock-entry?channel=offline_purchase&currTab=1&warehouseId=${warehouseDetails?._id}`,
                                            {
                                                state: {
                                                    internalNavigation: true,
                                                },
                                            }
                                        )
                                    }
                                    icon={<i className="ri-add-line text-[#142837]"></i>}
                                />
                            </div>
                        </div>
                        <div className="w-full py-5 ">
                            <div className="mb-3 space-y-6 w-full">
                                <div className="w-full px-8 flex space-x-3 border-b pb-5 mb-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-[45px] h-[45px] rounded bg-[#FFF1F2] flex items-center justify-center">
                                            <i className="ri-store-2-line text-[#E11D48] text-[25px]"></i>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 items-start">
                                        <div>
                                            <p className="text-[20px] leading-[30px] font-rocGroteskMedium text-[#333333]">
                                                {warehouseDetails?.warehouseName}
                                            </p>
                                            <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                {warehouseDetails?.address?.address}
                                            </p>
                                        </div>
                                        {stats?.warehouseInfo?.incidentType && (
                                            <div className="border border-[#FF3D1D] py-[2px] px-2 rounded-[3px]">
                                                <p className="text-[12px] font-rocGroteskMedium text-[#FF3D1D]">
                                                    {stats?.warehouseInfo?.incidentType
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        stats?.warehouseInfo?.incidentType.slice(
                                                            1
                                                        )}{" "}
                                                    detected
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className=" px-8 flex  ">
                                    <div className=" w-[300px] space-y-2">
                                        <p className="text-sm font-rocGroteskMedium">
                                            Contact person’s full name
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            {warehouseDetails?.contactPersonName || "N/A"}
                                        </p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-rocGroteskMedium">
                                            Contact person's email address
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            {warehouseDetails?.contactPersonEmail || "N/A"}
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full px-8  ">
                                    <p className="text-sm font-rocGroteskMedium">Notes</p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {warehouseDetails?.additionalNotes || "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 flex w-full space-x-3">
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <div className="flex items-center gap-2">
                                <p className="text-base text-slate-500 ">Total products</p>
                                <div>
                                    <a id="total-product">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#total-product"
                                        place={"top"}
                                        content="Displays the total number of unique products currently listed in this sales channel."
                                        style={{
                                            width: "300px",
                                            zIndex: 9999,
                                            backgroundColor: "#142837",
                                            color: "#fff",
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.totalProducts || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <div className="flex items-center gap-2">
                                <p className="text-base text-slate-500 ">
                                    Total due replenishments
                                </p>

                                <div>
                                    <a id="replenish">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#replenish"
                                        place={"top"}
                                        content="Shows the total quantity of products that need to be restocked based on current inventory levels and forecasted demand."
                                        style={{
                                            width: "300px",
                                            zIndex: 9999,
                                            backgroundColor: "#142837",
                                            color: "#fff",
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.totalReplenishment || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <div className="flex items-center gap-2">
                                <p className="text-base text-slate-500 ">Total sales</p>

                                <div>
                                    <a id="sales">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#sales"
                                        place={"top"}
                                        content="Represents the total sales value generated through this sales channel."
                                        style={{
                                            width: "300px",
                                            zIndex: 9999,
                                            backgroundColor: "#142837",
                                            color: "#fff",
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                ${formatMoney().format((stats?.totalSales || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <div className="flex items-center gap-2">
                                <p className="text-base text-slate-500 ">Demand forecast</p>

                                <div>
                                    <a id="demand-forecast">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#demand-forecast"
                                        place={"top"}
                                        content="Indicates the predicted number of units required to meet anticipated demand for the selected period, based on historical data and trends."
                                        style={{
                                            width: "300px",
                                            zIndex: 9999,
                                            backgroundColor: "#142837",
                                            color: "#fff",
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.demandForecast || 0)?.toFixed(0))}
                            </p>
                        </div>
                    </div>
                    <div className="px-8 mt-6">
                        <div className="border  rounded-tr-[6px] rounded-tl-[6px] pt-3">
                            <p className="text-base px-3 border-b pb-2 font-rocGroteskMedium text-gm-50">
                                Products in this warehouse
                            </p>

                            <div className="px-3">
                                <TabsContainer
                                    tabs={tableTabs}
                                    activeTab={activeTab}
                                    setActiveTab={(idx) => {
                                        setActiveTab(idx);
                                    }}
                                    component={
                                        <div className="min-h-[500px]">{displayActiveTab()}</div>
                                    }
                                    className="!px-0"
                                    showButtonClassName
                                    itemClassName="!pb-2 max-lg:whitespace-nowrap"
                                    borderLineClase={"!text-slate-100 w-[20%] max-lg:w-full mb-6"}
                                    showButton={
                                        <div className="py-2 flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                            <div className="w-[340px]">
                                                <TextInput
                                                    name={"search"}
                                                    type={"text"}
                                                    value={
                                                        activeTab === 0
                                                            ? filters.search
                                                            : allProductsSearch
                                                    }
                                                    inputPlaceholder={"Search product"}
                                                    inputClassName={
                                                        "!h-[32px] pl-[0px] !bg-slate-50 text-sm "
                                                    }
                                                    containerClassname={""}
                                                    leftIcon={
                                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                                    }
                                                    inputContainerClassname={
                                                        "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-slate-50 shadow-boxShadow-2 !border !border-n-20"
                                                    }
                                                    onChange={
                                                        activeTab === 0
                                                            ? handleFilter
                                                            : handleAllProductsSearch
                                                    }
                                                    onInput={
                                                        activeTab === 0
                                                            ? handleDebounceFilter
                                                            : handleAllProductsDebouncedChange
                                                    }
                                                />
                                            </div>

                                            {activeTab === 0 && (
                                                <Dropdown
                                                    handleChange={(name, value) => {
                                                        setFilters({ ...filters, duration: value });
                                                    }}
                                                    dropdown={
                                                        <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                            <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                                            <div
                                                                className={`text-g-75 font-rocGroteskMedium whitespace-nowrap text-[13px] inline-block`}
                                                            >
                                                                {filters?.duration?.split(
                                                                    "-"
                                                                )?.[1] || "All Times"}
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownItemsClasses="!px-0 !py-0"
                                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                                    name="valueRange"
                                                    dropdownOptions={[
                                                        ...dateValues2?.map((item, idx) => ({
                                                            label: (
                                                                <div
                                                                    key={idx}
                                                                    className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                                                >
                                                                    {item?.period}
                                                                </div>
                                                            ),
                                                            value: item?.value + "-" + item?.period,
                                                        })),
                                                    ]}
                                                />
                                            )}

                                            {activeTab === 0 && (
                                                <MultipleDropdowns
                                                    dropdown={
                                                        <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                            <div
                                                                className={`text-g-75 whitespace-nowrap font-rocGroteskMedium text-[13px] inline-block`}
                                                            >
                                                                Filter by
                                                            </div>
                                                            <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                                        </div>
                                                    }
                                                    childClassName={
                                                        "!right-[150px] !shadow-cardShadow-4 !right-0 !max-h-[200px] !w-[200px] !overflow-y-auto !z-[1000] "
                                                    }
                                                    optionClassName="!border-b !border-b-slate-100"
                                                    relativeParent={`relative `}
                                                    className={
                                                        " !shadow-cardShadow-4 !w-[150px] !text-[13px] !text-slate-600 !rounded-[6px] !z-[1200] !right-0"
                                                    }
                                                    options={options}
                                                    buttonClassName={`!px-0 !min-w-fit`}
                                                    containerClassName={`!p-0`}
                                                    mouseEnter={true}
                                                />
                                            )}

                                            {activeTab === 0 && (
                                                <Button
                                                    btnText="Export"
                                                    btnClassname="!px-4 !py-2.5 !w-fit !text-[#5452BF] !shadow-buttonShadow2 !border-slate-100 !border !rounded-[6px] !bg-white"
                                                    onClick={() => {
                                                        handleExport();
                                                    }}
                                                    icon={
                                                        <i className="ri-arrow-go-forward-fill text-[#5452BF]"></i>
                                                    }
                                                    disabled={!warehouseProducts?.length}
                                                />
                                            )}

                                            {activeTab === 1 && (
                                                <div className="relative">
                                                    <div className="flex items-center space-x-2">
                                                        {filterModal && (
                                                            <Button
                                                                btnText="Filter"
                                                                icon={
                                                                    <i className="ri-filter-3-fill text-[#142837]"></i>
                                                                }
                                                                btnClassname="shadow-cardShadow-4  text-[#142837] !w-fit !h-10 px-3 !bg-white !border !border-slate-100"
                                                            />
                                                        )}
                                                        {!filterModal && (
                                                            <Button
                                                                btnText="Filter"
                                                                icon={
                                                                    <i className="ri-filter-3-fill text-[#142837]"></i>
                                                                }
                                                                onClick={() => setFilterModal(true)}
                                                                btnClassname="shadow-cardShadow-4 text-[#142837] !w-fit !h-10 px-3 !bg-white !border !border-slate-100"
                                                            />
                                                        )}
                                                    </div>
                                                    {filterModal && (
                                                        <div
                                                            className="shadow-cardShadow-4 !z-[1200] absolute top-[50px] right-0 !bg-white p-4 rounded-md"
                                                            id="filterModalRef"
                                                        >
                                                            <div className="">
                                                                <SelectInput
                                                                    value={channelNoVariantFilter}
                                                                    name="channelFilter"
                                                                    placeholder={"Channel"}
                                                                    handleChange={(name, value) => {
                                                                        setChannelNoVariantFilter(
                                                                            value
                                                                        );
                                                                    }}
                                                                    clearValue={true}
                                                                    isRequired={true}
                                                                    dropdownOptions={[
                                                                        {
                                                                            label: "Shopify",
                                                                            value: "shopify",
                                                                        },
                                                                    ]}
                                                                    className="!h-[44px]"
                                                                />
                                                            </div>

                                                            <div className="flex justify-between gap-2">
                                                                <div className="">
                                                                    <p className="text-sm font-rocGroteskMedium pt-4 py-2">
                                                                        Stock Level Range
                                                                    </p>
                                                                    <div className="flex items-center space-x-2">
                                                                        <TextInput
                                                                            name="stockLevelMin"
                                                                            value={
                                                                                stockLevelRange?.stockLevelMin ||
                                                                                ""
                                                                            }
                                                                            type="number"
                                                                            placeholder={"Min"}
                                                                            onChange={(e) => {
                                                                                setStockLevelRange({
                                                                                    ...stockLevelRange,
                                                                                    stockLevelMin:
                                                                                        e.target
                                                                                            .value,
                                                                                });
                                                                            }}
                                                                            onWheel={(e: any) =>
                                                                                e.target.blur()
                                                                            }
                                                                            onKeyDown={(evt) =>
                                                                                [
                                                                                    "e",
                                                                                    "E",
                                                                                    "+",
                                                                                    "-",
                                                                                    "ArrowUp",
                                                                                    "ArrowDown",
                                                                                ].includes(
                                                                                    evt.key
                                                                                ) &&
                                                                                evt.preventDefault()
                                                                            }
                                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                                        />
                                                                        <TextInput
                                                                            name={"stockLevelMax"}
                                                                            value={
                                                                                stockLevelRange?.stockLevelMax ||
                                                                                ""
                                                                            }
                                                                            type="number"
                                                                            placeholder={"Max"}
                                                                            onChange={(e) => {
                                                                                setStockLevelRange({
                                                                                    ...stockLevelRange,
                                                                                    stockLevelMax:
                                                                                        e.target
                                                                                            .value,
                                                                                });
                                                                            }}
                                                                            onWheel={(e: any) =>
                                                                                e.target.blur()
                                                                            }
                                                                            onKeyDown={(evt) =>
                                                                                [
                                                                                    "e",
                                                                                    "E",
                                                                                    "+",
                                                                                    "-",
                                                                                    "ArrowUp",
                                                                                    "ArrowDown",
                                                                                ].includes(
                                                                                    evt.key
                                                                                ) &&
                                                                                evt.preventDefault()
                                                                            }
                                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <p className="text-sm font-rocGroteskMedium pt-4 py-2">
                                                                        Forecasted demand range
                                                                    </p>
                                                                    <div className="flex items-center space-x-2">
                                                                        <TextInput
                                                                            name={
                                                                                activeTab === 1
                                                                                    ? "forecastedDemandMin"
                                                                                    : "variantForecastedDemandMin"
                                                                            }
                                                                            value={
                                                                                forecastedNeedsRange?.forecastedDemandMin ||
                                                                                ""
                                                                            }
                                                                            type="number"
                                                                            placeholder={"Min"}
                                                                            onChange={(e) => {
                                                                                setForecastedNeedsRange(
                                                                                    {
                                                                                        ...forecastedNeedsRange,
                                                                                        forecastedDemandMin:
                                                                                            e.target
                                                                                                .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onWheel={(e: any) =>
                                                                                e.target.blur()
                                                                            }
                                                                            onKeyDown={(evt) =>
                                                                                [
                                                                                    "e",
                                                                                    "E",
                                                                                    "+",
                                                                                    "-",
                                                                                    "ArrowUp",
                                                                                    "ArrowDown",
                                                                                ].includes(
                                                                                    evt.key
                                                                                ) &&
                                                                                evt.preventDefault()
                                                                            }
                                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                                        />
                                                                        <TextInput
                                                                            name="forecastedDemandMax"
                                                                            value={
                                                                                forecastedNeedsRange?.forecastedDemandMax ||
                                                                                ""
                                                                            }
                                                                            type="number"
                                                                            placeholder={"Max"}
                                                                            onChange={(e) => {
                                                                                setForecastedNeedsRange(
                                                                                    {
                                                                                        ...forecastedNeedsRange,
                                                                                        forecastedDemandMax:
                                                                                            e.target
                                                                                                .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onWheel={(e: any) =>
                                                                                e.target.blur()
                                                                            }
                                                                            onKeyDown={(evt) =>
                                                                                [
                                                                                    "e",
                                                                                    "E",
                                                                                    "+",
                                                                                    "-",
                                                                                    "ArrowUp",
                                                                                    "ArrowDown",
                                                                                ].includes(
                                                                                    evt.key
                                                                                ) &&
                                                                                evt.preventDefault()
                                                                            }
                                                                            inputContainerClassname="!w-[150px] !h-[44px]"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="w-full mt-1 py-4 px-6 flex items-center justify-end space-x-2">
                                                                <Button
                                                                    btnText={"Reset"}
                                                                    onClick={() => {
                                                                        if (activeTab === 1) {
                                                                            if (
                                                                                channelNoVariantFilter ||
                                                                                stockLevelRange?.stockLevelMax ||
                                                                                stockLevelRange?.stockLevelMin ||
                                                                                forecastedNeedsRange?.forecastedDemandMax ||
                                                                                forecastedNeedsRange?.forecastedDemandMin
                                                                            ) {
                                                                                setChannelNoVariantFilter(
                                                                                    ""
                                                                                );
                                                                                setStockLevelRange({
                                                                                    stockLevelMax:
                                                                                        "",
                                                                                    stockLevelMin:
                                                                                        "",
                                                                                });
                                                                                setForecastedNeedsRange(
                                                                                    {
                                                                                        forecastedDemandMin:
                                                                                            "",
                                                                                        forecastedDemandMax:
                                                                                            "",
                                                                                    }
                                                                                );
                                                                                setApplyFilter(
                                                                                    !applyFilter
                                                                                );
                                                                            }
                                                                        }
                                                                        setFilterModal(false);
                                                                    }}
                                                                    btnClassname={
                                                                        "!bg-tradeally-neutral-20 !text-gm-75 !w-fit"
                                                                    }
                                                                />
                                                                <Button
                                                                    btnText={"Apply filters"}
                                                                    onClick={() => {
                                                                        setApplyFilter(
                                                                            !applyFilter
                                                                        );

                                                                        setFilterModal(false);
                                                                    }}
                                                                    btnClassname="!w-fit"
                                                                    btnTextClassName="!text-md "
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    }
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={deleteWarehouseShow}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteWarehouseShow(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Delete warehouse?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to delete this warehouse? Please make sure all
                            products are transferred first before proceeding.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={deleteWarehouse}
                                onClick={() => setDeleteWarehouseShow(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={deleteProductWarehouse}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_inventory,
                                    AccountPermissions.update_inventory,
                                    AccountPermissions.add_stock,
                                    AccountPermissions.transfer_stock,
                                ]}
                                btnText={"Delete warehouse"}
                                type={"button"}
                                isLoading={deleteWarehouse}
                                disabled={deleteWarehouse}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default WarehouseDetails;
