import AddVendorForm from "components/common/AddVendorForm";
import AddVendorMethods from "components/common/AddVendorMethods";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import Checkbox from "components/common/InputField/Checkbox";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import { getFromStorage } from "helpers";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { authActions, vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const AddVendor = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [email, setEmail] = useState<string>("");
    const [vendorCategory, setVendorCategory] = useState<string>("");
    const [requiredDocuments, setRequiredDocuments] = useState<{ [key: string]: any }[]>([]);
    const [suggestedVendors, setSuggestedVendors] = useState<{ [key: string]: any }[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<{ [key: string]: any }[]>([]);

    const { addingVendor, addVendorSuccess } = useAppSelector((state) => state.auth);
    const {
        fetchedSuggestedVendorsSuccess,
        connectingGoogleWorkspace,
        connectGoogleWorkspaceSuccess,
        connectingMicrosoftWorkspace,
        connectMicrosoftWorkspaceSuccess,
    } = useAppSelector((state) => state.vendor);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const code = param.get("code");
    const manual = param.get("manual");
    const [tabs, setTabs] = useState(["Add existing vendors"]);

    const handleSelectAllVendors = (deselect?: boolean) => {
        setSelectedVendors(() => (deselect ? [] : suggestedVendors));
    };

    const tableHeader = [
        {
            title: "Vendors",
            widthClass: "w-[40%]",
            onSelectAll: () => {
                handleSelectAllVendors(selectedVendors?.length > 0);
            },
            isAllChecked: selectedVendors?.length === suggestedVendors?.length,
        },
        { title: "Category" },
        { title: "Email address" },
        // { title: "Rating" },
    ];

    const handleAddSuggestedVendors = () => {
        if (selectedVendors && selectedVendors?.length > 0) {
            const body = {
                vendors: selectedVendors?.map((vendor) => ({
                    email: vendor?.email?.trim(),
                    documents: [],
                    category: vendor?.category?.trim(),
                })),
            };

            dispatch(authActions.addVendor(body));
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please select at least one vendor"} type="success" />
            ));
        }
    };

    const handleSelectEachVendor = (vendorSelected: { [key: string]: any }) => {
        const selectedVendorsCopy = [...selectedVendors];
        const idx = selectedVendorsCopy?.findIndex((vendor) => vendor?._id === vendorSelected?._id);

        if (idx !== -1) {
            selectedVendorsCopy.splice(idx, 1);
        } else {
            selectedVendorsCopy.push(vendorSelected);
        }
        setSelectedVendors(selectedVendorsCopy);
    };

    const tableBody = suggestedVendors?.map((vendor) => {
        const isChecked = selectedVendors?.find((vend) => vend?._id === vendor?._id);

        return [
            {
                content: (
                    <div className={`py-3 pr-3 text-sm text-slate-700`}>
                        <div className="flex items-center gap-3">
                            <span>{vendor?.name}</span>
                        </div>
                    </div>
                ),
                data: vendor,
                isItemChecked: isChecked,

                onCheckBoxClick: () => {
                    handleSelectEachVendor(vendor);
                },
            },
            {
                content: (
                    <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
                        {vendor?.category}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
                        {truncate(vendor?.email, { length: 25 })}
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
            //             <div className="flex items-center gap-2">
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-line text-slate-300"></i>
            //                 <i className="ri-star-line text-slate-300"></i>
            //             </div>
            //         </div>
            //     ),
            // },
        ];
    });

    const mobileTableBody = suggestedVendors?.map((vendor) => {
        const isChecked = selectedVendors?.find((vend) => vend?._id === vendor?._id);

        return {
            topContent: <p className="text-sm font-rocGroteskMedium">{vendor?.name}</p>,
            bottomContent: (
                <p className="text-sm font-rocGroteskMedium text-slate-500">
                    {truncate(vendor?.category, { length: 25 })}
                </p>
            ),
            rightIcon: (
                <Checkbox
                    name="select"
                    isChecked={Boolean(isChecked)}
                    // value={Boolean(isChecked)}
                    onChange={() => {
                        handleSelectEachVendor(vendor);
                    }}
                    className={"!h-4 !w-4 !rounded-[4px]"}
                />
            ),
            cellClickAction: () => {
                handleSelectEachVendor(vendor);
            },
        };
    });

    const handleAddVendorManually = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body = {
            vendors: [
                {
                    email,
                    documents: requiredDocuments?.map((docName) => ({ name: docName })),
                    category: vendorCategory,
                },
            ],
        };

        dispatch(authActions.addVendor(body));
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <>
                        {!manual && (
                            <div className="w-[83%] max-lg:w-full pt-6">
                                <AddVendorMethods
                                    addContactFunc={() =>
                                        navigate("/dashboard/vendors/add-vendor?manual=true", {
                                            replace: true,
                                            state: { internalNavigation: true },
                                        })
                                    }
                                />
                            </div>
                        )}
                        {manual && (
                            <div className="relative">
                                <div className="flex justify-center">
                                    <div className="w-[50%] max-sm:w-full">
                                        <AddVendorForm
                                            title={
                                                <p className="text-xl font-rocGroteskMedium mb-6">
                                                    Invite your vendors via email
                                                </p>
                                            }
                                            onSubmit={handleAddVendorManually}
                                            email={email}
                                            vendorCategory={vendorCategory}
                                            onChangeEmail={setEmail}
                                            onChangeCategory={setVendorCategory}
                                            onChangeDocs={setRequiredDocuments}
                                            isSending={addingVendor}
                                        />
                                        <div
                                            className="text-sm px-10 pb-10 relative mt-[-14px] font-rocGroteskMedium w-full flex justify-center items-center gap-2 cursor-pointer"
                                            onClick={() => {
                                                setEmail("");
                                                setVendorCategory("");
                                                setRequiredDocuments([]);
                                                navigate("/dashboard/vendors/add-vendor", {
                                                    replace: true,
                                                    state: { internalNavigation: true },
                                                });
                                            }}
                                        >
                                            <span>Back</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );

            case 1:
                return (
                    <div className="pt-6 max-lg:flex max-lg:flex-col">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                headerContainerClass="!bg-slate-50"
                                isAllSelectable={true}
                                isCellSelectable={true}
                                isScrollable={false}
                                isCellBordered={false}
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileTableBody} />
                        </div>
                        <div className="flex justify-end my-8">
                            <div className="flex items-center gap-3">
                                {/* <Button
                                    btnText={"Show more vendors"}
                                    btnClassname={"!bg-n-20 !text-gm-50 whitespace-nowrap !py-3"}
                                /> */}
                                <Button
                                    btnText={"Add vendors to list"}
                                    btnClassname={"!py-3"}
                                    isLoading={addingVendor}
                                    onClick={handleAddSuggestedVendors}
                                />
                            </div>
                        </div>
                    </div>
                );

            default:
                return (
                    <>
                        {!manual && (
                            <div className="w-[83%] pt-6">
                                <AddVendorMethods
                                    addContactFunc={() =>
                                        navigate("/dashboard/vendors/add-vendor?manual=true", {
                                            replace: true,
                                            state: { internalNavigation: true },
                                        })
                                    }
                                />
                            </div>
                        )}
                        {manual && (
                            <div className="relative">
                                <div className="flex justify-center">
                                    <div className="w-[50%]">
                                        <AddVendorForm
                                            title={
                                                <p className="text-xl font-rocGroteskMedium mb-6">
                                                    Invite your vendors via email
                                                </p>
                                            }
                                            onSubmit={handleAddVendorManually}
                                            email={email}
                                            vendorCategory={vendorCategory}
                                            onChangeEmail={setEmail}
                                            onChangeCategory={setVendorCategory}
                                            onChangeDocs={setRequiredDocuments}
                                            isSending={addingVendor}
                                        />
                                        <div
                                            className="text-sm px-10 pb-10 relative mt-[-14px] font-rocGroteskMedium w-full flex justify-center items-center gap-2 cursor-pointer"
                                            onClick={() => {
                                                setEmail("");
                                                setVendorCategory("");
                                                setRequiredDocuments([]);
                                                navigate("/dashboard/vendors/add-vendor", {
                                                    replace: true,
                                                    state: { internalNavigation: true },
                                                });
                                            }}
                                        >
                                            <span>Back</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );
        }
    };

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            setEmail("");
            setVendorCategory("");
            setRequiredDocuments([]);
            setActiveTab(0);
            setSelectedVendors([]);
            navigate("/dashboard/vendors/add-vendor", {
                replace: true,
                state: { internalNavigation: true },
            });
            dispatch(authActions.resetAddVendorSuccess());
        }
    }, [addVendorSuccess, navigate, dispatch]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");
        if (code && source && source === "gmail") {
            dispatch(vendorActions.connectGoogleWorkspace({ code }));
        }

        if (code && source && source === "microsoft") {
            dispatch(vendorActions.connectMicrosoftWorkspace({ code }));
        }
    }, [code, dispatch]);

    useEffect(() => {
        if (Boolean(connectGoogleWorkspaceSuccess)) {
            dispatch(vendorActions.resetConnectGoogleWorkspaceSuccess());
            navigate("/dashboard/vendors", { replace: true, state: { internalNavigation: true } });
        }
        if (Boolean(connectMicrosoftWorkspaceSuccess)) {
            dispatch(vendorActions.resetConnectMicrosoftWorkspaceSuccess());
            navigate("/dashboard/vendors", { replace: true, state: { internalNavigation: true } });
        }
    }, [connectGoogleWorkspaceSuccess, navigate, dispatch, connectMicrosoftWorkspaceSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getSuggestedVendors());
    }, [addVendorSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedSuggestedVendorsSuccess)) {
            setSuggestedVendors(fetchedSuggestedVendorsSuccess?.vendors);
            const suggested = [...fetchedSuggestedVendorsSuccess?.vendors];
            if (tabs?.length === 2) {
                setTabs((prev) => (suggested?.length > 0 ? [...prev] : ["Add existing vendors"]));
            } else {
                setTabs((prev) =>
                    suggested?.length > 0
                        ? [...prev, "Suggested vendors"]
                        : ["Add existing vendors"]
                );
            }

            dispatch(vendorActions.resetGetSuggestedVendorsSuccess());
        }
    }, [fetchedSuggestedVendorsSuccess, dispatch, tabs?.length]);

    return (
        <>
            <div className="w-full h-full px-[14px] max-lg:px-0">
                <div className="w-full px-6 h-auto rounded-[12px] bg-white border border-slate-100">
                    <Link
                        to={"/dashboard/vendors"}
                        className=" pt-6 flex items-center gap-1 mb-4 cursor w-fit"
                    >
                        <i className="ri-arrow-left-line mt-[-2px] text-sm"></i>
                        <p className="text-sm text-gm-50 font-rocGroteskMedium">Go back</p>
                    </Link>
                    <div>
                        <div className="mb-6">
                            <p className="text-xl font-rocGroteskMedium mb-1">Add vendors</p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Invite existing vendors to your TradeAlly account to accelerate
                                supply chain operations and improve vendor management.
                            </p>
                        </div>
                        <div>
                            <TabsContainer
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                component={displayActiveTab()}
                                className={"!px-0"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={connectingGoogleWorkspace || connectingMicrosoftWorkspace}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Connecting account...
                    </p>
                </div>
            </ModalContainer>
        </>
    );
};

export default AddVendor;
