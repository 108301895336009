import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions, inventoryActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetGlobalProductSales = () => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const [salesData, setSalesData] = useState<{ [key: string]: any }>([]);
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");
    const [warehouseId, setWarehouseId] = useState<any>("");
    const [inventoryId, setInventoryId] = useState<any>("");

    const handleStartAndEndDateChange = (customDate) => {
        setStartDate(customDate[0].toISOString());
        setEndDate(customDate[1].toISOString());
        setDateRange("custom");
    };

    const handleDateRangeChange = (period) => {
        setDateRange(period);
        setStartDate("");
        setEndDate("");
    };

    const handleWarehouseChange = (warehouseId) => {
        setWarehouseId(warehouseId);
    };

    const handleInventoryIdChange = (inventoryId) => {
        setInventoryId(inventoryId);
    };
    const {
        fetchingGlobalProductSales,
        fetchedGlobalProductSalesSuccess,
        fetchedGlobalProductSalesFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (Boolean(fetchedGlobalProductSalesSuccess)) {
            return setSalesData(fetchedGlobalProductSalesSuccess);
        }
    }, [fetchedGlobalProductSalesSuccess]);

    useEffect(() => {
        dispatch(
            inventoryActions?.fetchGlobalProductSales(
                productId,
                dateRange,
                startDate,
                endDate,
                warehouseId,
                inventoryId
            )
        );
    }, [dispatch, productId, warehouseId, inventoryId, startDate, endDate, dateRange]);

    return {
        data: salesData,
        isFetching: fetchingGlobalProductSales,
        error: fetchedGlobalProductSalesFailure,
        handleStartAndEndDateChange,
        handleDateRangeChange,
        handleWarehouseChange,
        handleInventoryIdChange,
    };
};

export default useGetGlobalProductSales;
