import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Details from "components/views/Synth2.0/Inventory/Tabs/ProductList/ProductDetail/Tabs/Details";
import Sales from "./Tabs/Sales";
import Lots from "./Tabs/Lots";
import MarketInsight from "./Tabs/MarketInsight";
import Settings from "./Tabs/Settings";
import IntegrationCard from "components/views/Ldb/Dashboard/Integration/Card";
import Suppliers from "./Tabs/Suppliers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import PageFrame from "components/layout/PageFrame";
import { getCurrencyFromCurrencyCode } from "helpers";
import Warehouse from "./Tabs/Warehouse";
import Recipes from "./Tabs/Recipes";
import Purchase from "./Tabs/Purchase";
import { inventoryActions } from "redux/Ldb/actions";
import { uniq } from "lodash";
import Incidents from "./Tabs/Incidents";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const ProductDetail = () => {
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/inventory" });

    const [activeTab, setActiveTab] = useState(0);
    const [productDetail, setProductDetail] = useState<{ [key: string]: any }>({});
    const [unarchiveProductsModal, setUnarchiveProductsModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        settingArchiveStatus,
        setArchiveStatusSuccess,
    } = useAppSelector((state) => state.inventory);
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");

    const [tabs, setTabs] = useState([
        "Details",
        "Sales",
        "Lots",
        "Incidents",
        "Market insights",
        "Suppliers",
        "Warehouse",
        "Settings",
    ]);

    const handleToggleArchivedStatus = () => {
        const body = {
            inventories: [
                {
                    inventoryId: productDetail?._id,
                    archived: !productDetail?.archived,
                },
            ],
        };

        dispatch(inventoryActions.setArchiveStatus(body));
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <Details />;

            case 1:
                return <Sales />;

            case 2:
                return <Lots />;

            case 3:
                return <Incidents />;

            case 4:
                return <MarketInsight productName={productDetail?.productName} />;

            case 5:
                return <Suppliers />;

            case 6:
                return <Warehouse />;

            case 7:
                return <Settings />;

            case 8:
                return <Recipes />;

            case 9:
                return <Purchase />;
            // return (
            //     <div>
            //         <div className="flex items-center space-x-3 mb-6">
            //             <span className="text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75">
            //                 All integrations
            //             </span>
            //             <span className="text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75">
            //                 Ecommerce
            //             </span>
            //             <span className="text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75">
            //                 Finance
            //             </span>
            //         </div>

            //         <div className="grid grid-cols-4 gap-4">
            //             <IntegrationCard
            //                 imageSrc=""
            //                 imageName=""
            //                 title="Shopify"
            //                 content="Connecting your Google email address allows Synth to scan your email for relevant information that can help in streamlining supply chain operations by extracting relevant data."
            //                 connected={true}
            //                 onClick={() => {}}
            //             />

            //             <IntegrationCard
            //                 imageSrc=""
            //                 imageName=""
            //                 title="QuickBooks"
            //                 content="Connecting your Google email address allows Synth to scan your email for relevant information that can help in streamlining supply chain operations by extracting relevant data."
            //                 connected={false}
            //                 onClick={() => {}}
            //             />
            //         </div>
            //     </div>
            // );

            default:
                return <div></div>;
        }
    };

    useEffect(() => {
        setActiveTab(Number(currTab));
    }, [currTab]);

    useEffect(() => {
        // if (!Boolean(fetchedSingleInventorySuccess)) {
        dispatch(inventoryActions.fetchSingleInventory(productId));
        // }
    }, [productId]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess && productId)) {
            setProductDetail(fetchedSingleInventorySuccess?.inventory);
            if (
                fetchedSingleInventorySuccess?.inventory?.inventoryCategory?.toLowerCase() ===
                    "finished goods" ||
                fetchedSingleInventorySuccess?.inventory?.inventoryCategory?.toLowerCase() ===
                    "work-in-progress (wip)"
            ) {
                setTabs((prev) => uniq([...prev, "Recipes"]));
            } else {
                setTabs((prev) => uniq(prev?.filter((item) => item !== "Recipes")));
            }
        }
    }, [fetchedSingleInventorySuccess, productId]);

    useEffect(() => {
        if (Boolean(setArchiveStatusSuccess)) {
            setProductDetail((prev) => ({
                ...prev,
                archived: !prev.archived,
            }));
            setUnarchiveProductsModal(false);
            toast.custom((t) => (
                <CustomToast t={t} message={"Product update successful!"} type={"success"} />
            ));
            dispatch(inventoryActions.resetSetArchiveStatusSuccess());
        }
    }, [dispatch, setArchiveStatusSuccess]);

    return (
        <PageFrame isLoading={fetchingSingleInventory}>
            <div className="w-full pb-8 bg-white border border-slate-100 rounded relative max-h-[85vh] overflow-y-auto">
                <div className="w-full sticky top-0 bg-white z-[9999]">
                    <div className="top-[-25px] md:px-8 py-4 px-[28px] w-full border-b border-n-20 flex items-center justify-between">
                        <div className="flex items-center">
                            <div
                                onClick={() => goBack()}
                                className="cursor-pointer space-x-1 flex items-center justify-center"
                            >
                                <i className="ri-arrow-left-line text-sm "></i>
                                <p className="text-sm underline text-gm-50 font-rocGroteskMedium">
                                    Back
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <div>
                                <Button
                                    btnText="Close"
                                    onClick={() => goBack()}
                                    btnClassname="!px-4 !py-2.5 !bg-transparent !border-[1px] !border-n-20 !h-10"
                                />
                            </div>
                            <div>
                                <Button
                                    btnText={productDetail?.archived ? "Unarchive" : "Archive"}
                                    onClick={() => setUnarchiveProductsModal(true)}
                                    btnClassname="!px-4 !py-2.5 !bg-transparent !border-[1px] !border-n-20 !h-10"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.update_inventory,
                                        AccountPermissions.create_inventory,
                                    ]}
                                />
                            </div>
                            <div>
                                <Button
                                    btnText={"Create bundle"}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/add-bundle?inventory=${productId}`,
                                            { state: { internalNavigation: true } }
                                        )
                                    }
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                    ]}
                                    btnClassname="!px-4 !py-2.5 !bg-transparent !border-[1px] !border-n-20 !h-10"
                                />
                            </div>
                            <div>
                                <Button
                                    btnText="Edit"
                                    btnClassname="!px-4 !py-2.5 !bg-n-20 !h-10"
                                    onClick={() =>
                                        navigate(`/dashboard/inventory/edit-product/${productId}`, {
                                            state: { internalNavigation: true },
                                        })
                                    }
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                    ]}
                                />
                            </div>
                            <div>
                                <Button
                                    btnText="Reorder"
                                    btnClassname="!px-4 !py-2.5 !h-10"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/purchase/create-purchase-request?inventory=${productId}`,
                                            { state: { internalNavigation: true } }
                                        )
                                    }
                                    btnPermission={[
                                        AccountPermissions.all_access,

                                        AccountPermissions.create_purchase_order,
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full ">
                    <div className="flex items-center w-full md:px-8 pt-8 px-[28px] space-x-8 pb-6">
                        <div className="w-[112px] h-[112px]">
                            <div className="relative w-full flex p-1 items-center justify-center border rounded border-slate-200">
                                {productDetail?.productImageDetails?.productImages[0] ? (
                                    <VendorAvatar
                                        imageSrc={
                                            productDetail?.productImageDetails?.productAvatar ||
                                            productDetail?.productImageDetails?.productImages[0]
                                        }
                                        size={102}
                                        name={productDetail?.productName}
                                        containerClassname="!rounded-none"
                                    />
                                ) : (
                                    <div className="w-[102px] h-[102px] rounded flex items-center justify-center bg-slate-200 ">
                                        <i className="ri-box-2-fill text-[60px] text-slate-500"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full  h-fit flex items-start  border-slate-100 justify-between">
                            <div className="w-full font-rocGroteskMedium">
                                <h3 className="text-slate-700 text-2xl font-rocGroteskMedium">
                                    {productDetail?.productName}
                                </h3>
                                <span className="block text-lg font-rocGroteskMedium">
                                    SKU: {productDetail?.sku}
                                    <span className="text-base text-slate-500">
                                        {" "}
                                        |{" "}
                                        {getCurrencyFromCurrencyCode(
                                            productDetail?.unitCost?.currency
                                        )}
                                        {productDetail?.unitCost?.amount || 0} each{" "}
                                        {productDetail?.productStockDetails?.unitOfMeasurement
                                            ? `(${productDetail?.productStockDetails?.unitOfMeasurement})`
                                            : ""}{" "}
                                        |{" "}
                                    </span>
                                    <span className="text-base text-slate-500">
                                        Total avail. qty:{" "}
                                        {productDetail?.productStockDetails?.stockLevel
                                            ? productDetail?.productStockDetails?.stockLevel?.toLocaleString()
                                            : 0}{" "}
                                        {productDetail?.productStockDetails?.unitOfMeasurement ||
                                            "s"}
                                    </span>{" "}
                                </span>
                                <div className="flex items-center space-x-3 mt-1">
                                    {Number(productDetail?.productStockDetails?.stockLevel) > 0 && (
                                        <div className="flex items-center space-x-1.5 border border-[#16A34A] bg-[#DCFCE7] h-6 rounded-[3px] px-2 py-[2px]">
                                            <i className="ri-information-line text-[#16A34A] text-base"></i>
                                            <span className="text-sm text-[#16A34A]">
                                                Fully Stocked
                                            </span>
                                        </div>
                                    )}
                                    {productDetail?.channels?.map((data) => (
                                        <div className="flex items-center space-x-1.5 border border-slate-200 bg-slate-50 h-6 rounded-[3px] px-2 py-[2px]">
                                            {/* <i className="ri-information-line text-g-75 text-base"></i> */}
                                            {displayProductChannelIcon(data, "!w-3 !h-3")}
                                            <span className="text-sm text-g-75">
                                                {data || "---"}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:px-8 pt-2 px-[28px]">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={(idx) => {
                                setActiveTab(idx);
                                navigate(
                                    `/dashboard/inventory/products/${productId}?currTab=${idx}`,
                                    { replace: true, state: { internalNavigation: true } }
                                );
                            }}
                            component={displayActiveTab()}
                            className="!px-0"
                            itemClassName="!pb-2"
                            borderLineClase={"!text-slate-100  w-full  mb-4"}
                        />
                    </div>

                    <ModalContainer
                        open={false}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[500px]"
                        closeModal={() => {
                            // setShowEditPrice(false);
                        }}
                    >
                        <div className="bg-white py-7  shadow-cardShadow relative rounded-[8px]">
                            <div className="flex mb-4 justify-between px-6">
                                <h1 className="text-xl font-rocGroteskMedium">Edit product cost</h1>
                                <div
                                    className="cursor-pointer"
                                    // onClick={() => setShowEditPrice(false)}
                                >
                                    <i className="ri-close-fill text-gm-45 text-xl"></i>
                                </div>
                            </div>
                            <div className="border border-slate-200 bg-slate-50 py-[18px]">
                                <div className="mt-2 flex space-x-3 items-center px-6">
                                    <VendorAvatar
                                        size={64}
                                        imageSrc={""}
                                        name={"product name"}
                                        containerClassname="!border-slate-200 !border !rounded"
                                    />
                                    <div>
                                        <p className="text-base text-slate-700 font-rocGroteskMedium">
                                            Product name
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            Product description
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 space-y-4 pb-14 border-b border-slate-200">
                                <div className="px-6">
                                    <p className="text-[#333333] mb-2 text-sm font-rocGroteskMedium">
                                        Selling price
                                    </p>
                                    <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                        <SelectInput
                                            value={""}
                                            name="currency"
                                            placeholder="Currency"
                                            handleChange={(name, value) => {
                                                // handleCostChange({ target: { name, value } });
                                            }}
                                            isRequired={true}
                                            dropdownOptions={[
                                                {
                                                    label: "GBP (£)",
                                                    value: "GBP",
                                                },
                                                {
                                                    label: "USD ($)",
                                                    value: "USD",
                                                },
                                                {
                                                    label: "EUR (€)",
                                                    value: "EUR",
                                                },
                                                {
                                                    label: "NGN (₦)",
                                                    value: "NGN",
                                                },
                                            ]}
                                        />
                                        <TextInput
                                            name={"sellingPrice"}
                                            type={"number"}
                                            onWheel={(e: any) => e.target.blur()}
                                            value={""}
                                            // onChange={handleCostChange}
                                            placeholder={"Selling price"}
                                            onKeyDown={(evt) =>
                                                [
                                                    "e",
                                                    "E",
                                                    "+",
                                                    "-",
                                                    "ArrowUp",
                                                    "ArrowDown",
                                                ].includes(evt.key) && evt.preventDefault()
                                            }
                                            required={true}
                                            pattern={"^[0-9]+$"}
                                            title={"must be digits"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex space-x-2 mt-5 px-6">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={false}
                                    // onClick={() => setShowEditPrice(false)}
                                />
                                <Button
                                    btnClassname={"!py-3 !px-8"}
                                    // onClick={onUpdateSellingPrice}
                                    btnText={"Update price"}
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_inventory,
                                        AccountPermissions.update_inventory,
                                    ]}
                                    type={"button"}
                                    // isLoading={updatingSingleProduct}
                                />
                            </div>
                        </div>
                    </ModalContainer>

                    <ModalContainer
                        open={false}
                        showCloseIcon={false}
                        tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                        closeModal={() => {
                            // setDeleteImageModal(false);
                        }}
                    >
                        <div className="bg-white rounded-lg shadow-cardShadow relative">
                            <div className="px-10 pt-3">
                                <div className="flex flex-col items-center text-center">
                                    <div className="flex w-full justify-end mr-[-12px]">
                                        <i
                                            // onClick={() => setDeleteImageModal(false)}
                                            className="ri-close-fill text-2xl cursor-pointe"
                                        ></i>
                                    </div>

                                    <i className="ri-delete-bin-6-line text-[32px]"></i>

                                    <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                        Delete Image?
                                    </p>
                                    <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                        Are you sure about deleting this image?
                                    </p>
                                </div>

                                <div className="w-full flex items-center gap-2 py-6">
                                    <Button
                                        btnText={"No, Cancel"}
                                        type={"button"}
                                        btnClassname="!bg-tradeally-neutral-20"
                                        onClick={() => {
                                            // setDeleteImageModal(false);
                                        }}
                                    />
                                    <Button
                                        btnText={"Yes, delete"}
                                        type={"button"}
                                        btnClassname="!bg-r-50 !text-white"
                                        // isLoading={deletingImage}
                                        onClick={() => {
                                            // handleRemoveImage();
                                        }}
                                        btnPermission={[
                                            AccountPermissions.all_access,
                                            AccountPermissions.create_inventory,
                                            AccountPermissions.update_inventory,
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </div>
            </div>

            <ModalContainer
                open={unarchiveProductsModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setUnarchiveProductsModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 relative">
                                <i className="ri-shopping-basket-line text-[48px]"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    {productDetail?.archived ? "Unarchive" : "Archive"} Product?
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    Are you sure you want to{" "}
                                    {productDetail?.archived ? "unarchive" : "archive"} the this
                                    product?
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"No, I don't"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setUnarchiveProductsModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, I do"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_inventory,
                                    AccountPermissions.update_inventory,
                                ]}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                isLoading={settingArchiveStatus}
                                onClick={() => {
                                    handleToggleArchivedStatus();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default ProductDetail;
