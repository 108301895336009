import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

type bomTypes = {
    bomDetails: any;
    productName: string;
    isLoading: boolean;
    debouncedSearch: string;
    handleDebouncedSearch: (e: any) => void;
    search: string;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isLoadingMore: boolean;
    pagination: {
        current: string;
        number_of_pages: string;
    };
    plannedQuantity: number;
    showShortageButton?: boolean;
    type?: string;
};
const BOM = ({
    bomDetails,
    productName,
    isLoading,
    debouncedSearch,
    handleDebouncedSearch,
    search,
    handleSearch,
    isLoadingMore,
    pagination,
    showShortageButton,
    type,
    plannedQuantity,
}: bomTypes) => {
    const navigate = useNavigate();
    const { productionId } = useParams();
    const [shortages, setShortages] = useState<{ [key: string]: any }[]>([]);
    const [BOMList, setBOMList] = useState<{ [key: string]: any }[]>([]);
    const [expandedRowIdx, setExpandedRowIdx] = useState<number>(-1);
    const [stockList, setStockList] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        bomDetails?.billOfMaterialIds?.length >= 1
            ? setBOMList(bomDetails?.billOfMaterialIds)
            : setBOMList([]);
    }, [bomDetails]);

    useEffect(() => {
        const getLowStockProd = BOMList?.filter((item) => {
            const calcQuantity = item?.quantity * plannedQuantity;

            const totalNeeded = item?.scrap
                ? calcTotalQuantity(calcQuantity, item?.scrap)
                : item?.quantity
                ? calcQuantity
                : "";

            const totalStockAvailable =
                item.batches?.length > 0
                    ? item.batches?.reduce((sum, stock) => {
                          return sum + (stock?.quantity || 0);
                      }, 0)
                    : 0;

            return totalStockAvailable < totalNeeded;
        })?.map((item) => {
            const calcQuantity = item?.quantity * plannedQuantity;

            const totalNeeded = item?.scrap
                ? calcTotalQuantity(calcQuantity, item?.scrap)
                : item?.quantity
                ? calcQuantity
                : "";

            const totalStockAvailable =
                item.batches?.length > 0
                    ? item.batches?.reduce((sum, stock) => {
                          return sum + (stock?.quantity || 0);
                      }, 0)
                    : 0;

            const deficit = Number(totalNeeded) - Number(totalStockAvailable);
            return {
                unitCostAmount: item?.inventory?.unitCost?.amount,
                unitCostCurrency: item?.inventory?.unitCost?.currency,
                sku: item?.inventory?.sku,
                productName: item?.inventory?.productName,
                inventoryId: item?.inventory?._id,
                quantity: deficit,
                unitCost: {
                    amount: "",
                    currency: "",
                },
                subtotal: {
                    amount: item?.inventory?.unitCost?.amount * item?.quantity * plannedQuantity,
                    currency: item?.inventory?.unitCost?.currency,
                },

                deliveryDate: "",
                leadTime: "",
                description: item?.inventory?.productDescription,
            };
        });

        setShortages(getLowStockProd);
    }, [BOMList]);

    const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = quantity;
        const scrapNum = scrap;
        const percentage = (quantityNum * scrapNum) / 100;

        const totalQuantity = quantity ? Number(percentage) + Number(quantity) : 0;
        if (unitPrice !== undefined) {
            return Number(totalQuantity * unitPrice);
        }
        return totalQuantity ? Number(totalQuantity) : 0;
    };

    const BOMTableHeader = [
        {
            title: "Product",
            widthClass: "w-[18%]  !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Description",
            widthClass: "w-[18%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Current Stock Level",
            widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Raw materials needed",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Scrap",
            widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "UOM",
            widthClass: "w-[7%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Unit price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Dependencies",
            widthClass: "w-[20%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Action",
            widthClass: "w-[15%] !border !bg-slate-50 !border-slate-200",
        },
    ];

    const BOMTableBody = BOMList?.map((plan, idx) => {
        const calcQuantity = plan?.quantity * plannedQuantity;

        const totalNeeded = plan?.scrap
            ? calcTotalQuantity(calcQuantity, plan?.scrap)
            : plan?.quantity
            ? calcQuantity
            : "";

        const totalStockAvailable =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return sum + (stock?.quantity || 0);
                  }, 0)
                : 0;
        const totalUnitPrice =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(
                                    stock?.stock?.cost?.amount || plan?.inventory?.unitCost?.amount
                                ) || 0
                              : 0)
                      );
                  }, 0)
                : 0;

        const totalPrice =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(
                                    stock?.stock?.cost?.amount || plan?.inventory?.unitCost?.amount
                                ) || 0
                              : 0) *
                              (stock?.quantity || 0)
                      );
                  }, 0)
                : 0;

        const deficit = Number(totalNeeded) - Number(totalStockAvailable);

        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {plan?.inventory?.productImageDetails?.productAvatar ||
                            plan?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        plan?.inventory?.productImageDetails?.productAvatar ||
                                        plan?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={plan?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(plan?.inventory?.productName, { length: 20 })}
                                </p>
                                {/* <p className="text-[#64748B]">{plan?.inventory?.sku}</p> */}
                                <p className={`text-[#64748B] text-[13px]`}>
                                    {`${plan?.batches?.length} ${
                                        plan?.batches?.length > 1 ? "batches" : "batch"
                                    }`}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                expandedRowAction: () => {
                    idx === expandedRowIdx ? setExpandedRowIdx(-1) : setExpandedRowIdx(idx);
                    idx !== expandedRowIdx &&
                        setStockList(plan?.batches?.length > 0 ? plan?.batches : []);
                },
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.instructions
                                ? truncate(plan?.instructions, { length: 30 })
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p
                            className={` text-sm ${
                                totalStockAvailable < totalNeeded
                                    ? "text-[#E4281D]"
                                    : "text-slate-950"
                            }`}
                        >
                            {plan?.inventory?.productStockDetails?.stockLevel !== undefined
                                ? formatMoney()?.format(
                                      plan?.inventory?.productStockDetails?.stockLevel
                                  )
                                : ""}
                            {` `}

                            {plan?.inventory?.productStockDetails?.stockLevel !== undefined &&
                                plan?.inventory?.productStockDetails?.unitOfMeasurement}
                            {` `}
                            {totalStockAvailable < totalNeeded && "Insufficient material"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalNeeded !== undefined
                                ? `${formatMoney()?.format(totalNeeded)} ${
                                      plan?.inventory?.productStockDetails?.unitOfMeasurement
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.scrap}
                            {plan?.scrap !== undefined ? "%" : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.inventory?.productStockDetails?.unitOfMeasurement || "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalUnitPrice !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      plan?.inventory?.unitCost?.currency
                                  )} ${formatMoney()?.format(totalUnitPrice)}`
                                : "0"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalPrice !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      plan?.inventory?.unitCost?.currency
                                  )} ${formatMoney()?.format(totalPrice)}`
                                : "0"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full px-2.5 text-sm py-2 flex flex-wrap gap-2 ${
                            plan?.linkedPurchaseOrders?.length === 0 &&
                            totalStockAvailable < totalNeeded &&
                            "text-[#CE9606]"
                        }`}
                    >
                        {plan?.linkedPurchaseOrders?.length >= 1
                            ? plan?.linkedPurchaseOrders?.map((po) => {
                                  return (
                                      <Button
                                          btnText={`PO-${po?.taId}`}
                                          btnClassname="!px-4 !bg-transparent !py-2 !text-gm-50 !w-fit text-[13px] !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                          onClick={() =>
                                              navigate(`/dashboard/purchase/shipment/${po?._id}`, {
                                                  state: { internalNavigation: true },
                                              })
                                          }
                                          icon=<i className="ri-file-line"></i>
                                          rightIcon=<i className="ri-arrow-right-line"></i>
                                      />
                                  );
                              })
                            : totalStockAvailable < totalNeeded
                            ? "Create PO for material shortage"
                            : "None"}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.linkedPurchaseOrders?.length >= 1 ? (
                                "PO Created"
                            ) : totalStockAvailable < totalNeeded ? (
                                <Button
                                    btnText="Create PO"
                                    btnClassname="!px-4 !bg-transparent !py-2 !text-g-60 !w-fit text-[13px] !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                    // disabled={}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/purchase/create-purchase-request?planId=${productionId}&inventoryId=${
                                                plan?.inventoryId
                                            }&productName=${
                                                plan?.inventory?.productName
                                            }&qty=${Math.ceil(deficit)}`,
                                            { state: { internalNavigation: true } }
                                        )
                                    }
                                    icon=<i className="ri-file-list-3-line"></i>
                                />
                            ) : (
                                ""
                            )}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });

    const tableHeader = [
        { title: "", widthClass: "w-[36%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[10%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[8%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[7%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[10%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[10%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[15%]  !font-rocGroteskMedium" },
    ];
    const WorkOrdertableHeader = [
        { title: "", widthClass: "w-[36%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[18%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[7%]  !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[10%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[10%] !font-rocGroteskMedium" },
    ];
    const expandedTableBody = stockList?.map((stock, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-2 px-3 flex font-rocGroteskMedium items-center h-full text-sm`}
                    >
                        <div className="flex items-center space-x-3">
                            {stock?.stock?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        stock?.stock?.productImageDetails?.productAvatar ||
                                        stock?.stock?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={stock?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                </div>
                            )}
                            <span className="text-[#B18000]">
                                {truncate(BOMList[expandedRowIdx]?.inventory?.productName, {
                                    length: 40,
                                })}
                                - {stock?.stock?.batchId}
                            </span>
                        </div>
                    </div>
                ),

                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        {/* <p className=""> {stock?.stock?.productStockDetails?.stockLevel !== undefined ? `${formatMoney()?.format(stock?.stock?.productStockDetails?.stockLevel)} ${BOMList[expandedRowIdx]?.inventory?.productStockDetails?.unitOfMeasurement}` : ""}</p> */}
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        {stock?.quantity !== undefined
                            ? `${formatMoney()?.format(stock?.quantity)}  ${
                                  BOMList[expandedRowIdx]?.inventory?.productStockDetails
                                      ?.unitOfMeasurement
                              }`
                            : ""}
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b",
            },

            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className=""></p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className=""></p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            //  {
            //     content: (
            //         <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
            //           <p className=""></p>
            //         </div>
            //     ),
            //     widthClass: "!border-slate-200 !border-b ",
            // },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className="">
                            {stock?.stock?.cost?.amount !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      stock?.stock?.cost?.currency
                                  )} ${formatMoney()?.format(
                                      stock?.stock?.cost?.amount ||
                                          BOMList[idx]?.inventory?.unitCost?.amount
                                  )}`
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },

            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className="">
                            {stock?.stock?.cost?.amount !== undefined &&
                            stock?.quantity !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      String(stock?.stock?.cost?.currency)
                                  )} ${formatMoney()?.format(
                                      Number(
                                          stock?.stock?.cost?.amount ||
                                              BOMList[idx]?.inventory?.cost?.amount
                                      ) * Number(stock?.quantity)
                                  )}`
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}
                    ></div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
        ];
    });

    const WorkOrderBOMTableHeader = [
        {
            title: "Category",
            widthClass: "w-[18%]  !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Material",
            widthClass: "w-[18%]  !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Description",
            widthClass: "w-[18%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Quantity",
            widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Scrap",
            widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total Quantity",
            widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "UOM",
            widthClass: "w-[7%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Unit price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
    ];

    const WorkOrderBOMTableBody = BOMList?.map((plan, idx) => {
        // const calcQuantity = plan?.quantity * plannedQuantity;
        // const totalQuantity =
        //     calcQuantity !== undefined && plan?.scrap !== undefined
        //         ? (calcQuantity * plan?.scrap) / 100 + calcQuantity
        //         : calcQuantity ?? 0;

        // const totalAmount =
        //     totalQuantity && plan?.inventory?.unitCost?.amount
        //         ? totalQuantity * plan?.inventory?.unitCost?.amount
        //         : 0;
        const calcQuantity = plan?.quantity * plannedQuantity;

        const totalNeeded = plan?.scrap
            ? calcTotalQuantity(calcQuantity, plan?.scrap)
            : plan?.quantity
            ? calcQuantity
            : "";

        const totalStockAvailable =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return sum + (stock?.quantity || 0);
                  }, 0)
                : 0;
        const totalUnitPrice =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(
                                    stock?.stock?.cost?.amount || plan?.inventory?.unitCost?.amount
                                ) || 0
                              : 0)
                      );
                  }, 0)
                : 0;

        const totalPrice =
            plan?.batches?.length > 0
                ? plan?.batches?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(
                                    stock?.stock?.cost?.amount || plan?.inventory?.unitCost?.amount
                                ) || 0
                              : 0) *
                              (stock?.quantity || 0)
                      );
                  }, 0)
                : 0;

        return [
            {
                content: (
                    <div key={idx} className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.category || plan?.inventory?.inventoryCategory || "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                expandedRowAction: () => {
                    idx === expandedRowIdx ? setExpandedRowIdx(-1) : setExpandedRowIdx(idx);
                    idx !== expandedRowIdx &&
                        setStockList(plan?.batches?.length > 0 ? plan?.batches : []);
                },
            },
            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {plan?.inventory?.productImageDetails?.productAvatar ||
                            plan?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        plan?.inventory?.productImageDetails?.productAvatar ||
                                        plan?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={plan?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(plan?.inventory?.productName, { length: 20 })}
                                </p>
                                {/* <p className="text-[#64748B]">{plan?.inventory?.sku}</p> */}
                                <p className={`text-[#64748B] text-[13px]`}>
                                    {`${plan?.batches?.length} ${
                                        plan?.batches?.length > 1 ? "batches" : "batch"
                                    }`}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.instructions
                                ? truncate(plan?.instructions, { length: 30 })
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {calcQuantity !== undefined
                                ? `${formatMoney()?.format(calcQuantity)} ${
                                      plan?.inventory?.productStockDetails?.unitOfMeasurement
                                          ? plan?.inventory?.productStockDetails?.unitOfMeasurement
                                          : ""
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.scrap} {plan?.scrap !== undefined ? "%" : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {/* {totalQuantity ? formatMoney()?.format(totalQuantity) : ""}{" "}
                            {totalQuantity
                                ? plan?.inventory?.productStockDetails?.unitOfMeasurement
                                : ""} */}
                            {totalNeeded !== undefined
                                ? `${formatMoney()?.format(totalNeeded)} ${
                                      plan?.inventory?.productStockDetails?.unitOfMeasurement !==
                                      undefined
                                          ? plan?.inventory?.productStockDetails?.unitOfMeasurement
                                          : ""
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.inventory?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalUnitPrice !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      plan?.inventory?.unitCost?.currency
                                  )} ${formatMoney()?.format(totalUnitPrice)}`
                                : "0"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalPrice !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      plan?.inventory?.unitCost?.currency
                                  )} ${formatMoney()?.format(totalPrice)}`
                                : "0"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });
    return (
        <div className="mt-6 font-rocGroteskMedium">
            <div
                className={`flex items-center justify-between p-4 border-x border-t  border-slate-200 rounded-t-md ${
                    BOMList?.length === 0 ? "border-b" : ""
                }`}
            >
                <div className="flex items-center gap-1">
                    <p className="text-gm-75 text-base ">
                        Bill of materials (BOM) {productName && `for ${productName}`}
                    </p>
                    <div>
                        <a id="bom">
                            <i className="ri-information-fill text-slate-500 text-base"></i>
                        </a>

                        <Tooltip
                            anchorSelect="#bom"
                            place={"top"}
                            content="A comprehensive list of raw materials, components, and quantities required for production."
                            style={{ width: "250px", zIndex: 9999 }}
                        />
                    </div>
                </div>

                <div className="flex gap-2 ">
                    <TextInput
                        name="search"
                        type="text"
                        value={search}
                        onChange={(e) => handleSearch(e)}
                        onInput={handleDebouncedSearch}
                        inputPlaceholder="Search BOM"
                        inputClassName={
                            "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                        }
                        leftIcon={
                            <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                        }
                        inputContainerClassname={
                            "!rounded-[4px]  !h-[40px] !pl-[20px] !bg-white !border !border-slate-200 !shadow-boxShadow !w-[300px]"
                        }
                    />

                    {showShortageButton && (
                        <Button
                            btnText={"Create POs for all shortages"}
                            type={"button"}
                            btnClassname="!py-2 !px-4 !text-black !w-fit !whitespace-nowrap max-sm:w-full"
                            disabled={shortages?.length < 1}
                            onClick={() =>
                                navigate(
                                    `/dashboard/purchase/create-purchase-request?planId=${productionId}`,
                                    {
                                        state: { shortages, internalNavigation: true },
                                    }
                                )
                            }
                        />
                    )}
                </div>
            </div>

            {isLoading && BOMList?.length === 0 ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={BOMTableHeader} />
                </div>
            ) : BOMList?.length >= 1 ? (
                <>
                    {/* tableBody={BOMTableBody}
                            tableHeader={BOMTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200"
                            headerItemClass="font-rocGroteskMedium !text-[#334155]"
                            tableClass="!border-slate-200"
                            isCollapseable={true}
                            allCellClick={false}
                            expandedRowIdx={expandedRowIdx}
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            rowExpanded={ */}

                    <CustomTable
                        tableBody={type === "workOrder" ? WorkOrderBOMTableBody : BOMTableBody}
                        tableHeader={
                            type === "workOrder" ? WorkOrderBOMTableHeader : BOMTableHeader
                        }
                        isScrollable={false}
                        isCellBordered={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        scrollWithin={false}
                        headerContainerClass="!border-slate-200"
                        headerItemClass="font-rocGroteskMedium !text-[#334155] "
                        // tbodyClass="!border !border-slate-200"
                        tableClass="!border-slate-200"
                        bodyItemClass="hover:!bg-[transparent] !cursor-default !border-slate-200"
                        isCollapseable={true}
                        allCellClick={false}
                        expandedRowIdx={expandedRowIdx}
                        rowExpanded={
                            <div className=" bg-[#FFFBF0]">
                                {stockList?.length === 0 ? (
                                    <div className="overflow-x-scroll">
                                        <p className="py-2 px-4 text-sm text-slate-900">
                                            No batch available
                                        </p>
                                    </div>
                                ) : (
                                    <div className="overflow-x-scroll">
                                        <CustomTable
                                            tableHeader={
                                                type === "workOrder"
                                                    ? WorkOrdertableHeader
                                                    : tableHeader
                                            }
                                            tableBody={expandedTableBody}
                                            scrollWithin={false}
                                            isCellBordered={false}
                                            isInifiniteScroll={true}
                                            tableClass="!bg-[#FFFBF0] !border-t-slate-200 !border-b-0 !border-x-0 !rounded-none mt-[-30px]"
                                            isScrollable={true}
                                            tableContainerClass=""
                                            bodyItemClass="hover:!bg-[#FFFBF0]  !bg-[#FFFBF0]  !cursor-default !border-slate-200"
                                            expandedRowTClass="!z-[0] !relative"
                                        />
                                    </div>
                                )}
                            </div>
                        }
                    />

                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.current === pagination?.number_of_pages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (
                <>
                    {debouncedSearch && BOMList?.length === 0 ? (
                        <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                            <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                Sorry, no results match your search. Refine your criteria and try
                                again.
                            </p>
                        </div>
                    ) : (
                        ""
                    )}
                </>
            )}
        </div>
    );
};

export default BOM;
