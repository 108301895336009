import Button from "components/common/Button";
import LandingFooter from "components/views/Landing/LandingFooter";
import LandingNav from "components/views/Landing/LandingNav";
import React from "react";

const Integrations = () => {
    return (
        <div className={`relative bg-[#FFFBEB]`}>
            <LandingNav />

            <section
                id="integrations"
                className="flex justify-center pb-[100px] mt-[50px] lg:mt-[132px]"
            >
                <div className="w-[63.8%] max-sm:w-full max-sm:px-6">
                    <div className="mb-12 text-center ">
                        <h4 className="text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Integrations
                        </h4>
                        <h5 className="text-[48px] max-lg:text-[32px] max-lg:leading-[38.4px] font-flame leading-[57.6px] text-green-2 ">
                            Seamlessly connect your inventory and sales data to Synth.
                        </h5>
                    </div>

                    <div className="mb-12 flex justify-center">
                        <div className="w-full">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Integration%20(1).svg"
                                alt=""
                                className=" w-full"
                            />
                            <img
                                src="https://storage.googleapis.com/heysynth_files/website/image_5428_qwblq0.svg"
                                alt=""
                                className=" w-full"
                            />
                            <img
                                src="https://storage.googleapis.com/heysynth_files/website/image_5431_d1f1gf.svg"
                                alt=""
                                className=" w-full"
                            />
                            <img
                                src="https://storage.googleapis.com/heysynth_files/website/image_5430_kfaw6f.svg"
                                alt=""
                                className=" w-full"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <LandingFooter />
        </div>
    );
};

export default Integrations;
