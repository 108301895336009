import PageFrame from "components/layout/PageFrame";
import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { authService } from "services/Ldb";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import useGetNavLinks from "hooks/navLinks";

const GoogleAuth = () => {
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const token = param.get("token");
    const { fetchUserProfileRequest, fetchUserProfileSuccess } = useAppSelector(
        (state) => state.auth
    );
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const { allowedLinks } = useGetNavLinks();
    const firstLink = allowedLinks[0]?.link;

    const navigateUser = () => {
        if (profile) {
            if (
                profile?.profile?.country &&
                profile?.profile?.businessType &&
                profile?.profile?.businessDoc &&
                profile?.profile?.address
            ) {
                return <Navigate to={firstLink} replace />;
            } else {
                return <Navigate to={"/new-onboard"} replace />;
            }
        }
    };

    useEffect(() => {
        if (token) {
            dispatch(authActions.getLdbProfile(token));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
            const user = {
                token: token,
                user: fetchUserProfileSuccess,
            };
            authService.saveAccount(user);
        }
    }, [fetchUserProfileSuccess, token]);

    return <PageFrame isLoading={fetchUserProfileRequest}>{navigateUser()}</PageFrame>;
};

export default GoogleAuth;
