export enum AccountPermissions {
    //all access
    all_access = "all_access",
    // Dashboard
    view_dashboard = "view_dashboard",

    // Inventory
    view_inventory = "view_inventory",
    create_inventory = "create_inventory",
    update_inventory = "update_inventory",
    add_stock = "add_stock",
    transfer_stock = "transfer_stock",

    // Purchase order
    view_purchase_order = "view_purchase_order",
    create_purchase_order = "create_purchase_order",

    // Suppliers
    view_suppliers = "view_suppliers",
    create_suppliers = "create_suppliers",

    // Production
    create_production_plan = "create_production_plan",
    view_production = "view_production",
    view_production_report = "view_production_report",

    // Workforce IQ
    create_ai_teammate = "create_ai_teammate",
    view_ai_task = "view_ai_task",

    // Sales
    view_sales_order = "view_sales_order",
    create_sales_order = "create_sales_order",
    update_sales_order = "update_sales_order",

    // Administration
    manage_settings = "manage_settings",
    manage_team = "manage_team",
    manage_integrations = "manage_integrations",

    //multi brand
    brand_admin = "brand_admin",
}
