import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/common/Button";
import PageFrame from "../../../components/layout/PageFrame";
import moment from "moment";
import Dropdown from "../../../components/common/Dropdown";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import { yearDropdown } from "helpers/yearDropdown";
import ListView from "../../../components/views/Synth2.0/MarketingCampaign/CampaignViews/ListView";
import PlanACampaignModal from "../../../components/views/Synth2.0/MarketingCampaign/PlanACampaignModal";
import WorkforceAnalyticsCard from "components/common/WorkforceAnalyticsCard";
import { formatMoney } from "helpers";
import Skeleton from "react-loading-skeleton";
import useGetAiNotifications from "hooks/workforceHooks/useGetAiNotifications";
import { truncate } from "lodash";
import { getChannelLoginUrl } from "helpers/getChannelLoginUrl";
const CampaignOverview = ({
    data,
    stats,
    setDates,
    dates,
    isLoading,
}: {
    data: { [key: string]: any };
    stats: { [key: string]: any };
    setDates: (dates: { startDate: string; endDate: string }) => void;
    dates: { startDate: string; endDate: string };
    isLoading: boolean;
}) => {
    const navigate = useNavigate();
    const customRef = useRef(null);
    const [durationFilter, setDurationFilter] = useState<string>("This month");
    const [campaignModal, setCampaignModal] = useState<boolean>(false);
    const [customValue, customOnChange] = useState("");
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [years, setYears] = useState<number[]>([]);
    const [campaignAlert, setCampaignAlert] = useState<boolean>(true);
    const [activeNotificationTab, setActiveNotificationTab] = useState<number>(0);

    const {
        data: aiNotifications,
        isFetching: isFetchingAiNotifications,
        handleSearch,
        handleDebouncedSearch,
        search,
    } = useGetAiNotifications(false);

    const notificationTabs = [
        { id: 0, label: "All" },
        { id: 1, label: "Forecast" },
        { id: 2, label: "Inventory" },
        { id: 3, label: "PO/Shipment" },
    ];

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    useEffect(() => {
        if (customValue?.length === 2) {
            setDurationFilter("CustomDate");
            setDates({
                startDate: moment(customValue[0])?.format("YYYY-MM-DD"),
                endDate: moment(customValue[1])?.format("YYYY-MM-DD"),
            });
        }
    }, [customValue]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    return (
        <PageFrame>
            <div className="bg-white rounded-lg border border-n-20 relative">
                <div className="flex items-center justify-between px-6 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <div className="flex items-center justify-between my-6">
                            <div className="flex items-center">
                                <div className="w-12 h-12 bg-p-50 rounded-lg flex items-center justify-center shadow-[0px_4px_8px_-4px_#10182808]">
                                    <i className="ri-robot-fill text-white text-2xl"></i>
                                </div>

                                <div className="ml-2">
                                    <div className="flex items-center gap-2">
                                        <p className="text-lg mb-[2px] font-rocGroteskMedium text-gm-50">
                                            {`${data?.teamMateName}`}
                                        </p>
                                        <div className="flex items-center w-fit h-6 space-x-2 bg-[#D0F4EDB2] rounded-md px-2.5">
                                            <span className="w-2 h-2 rounded-full bg-[#22C55E] " />
                                            <span className="text-xs text-slate-900 font-rocGroteskMedium">
                                                Active
                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-[#808080] font-rocGroteskMedium text-xs">
                                        Campaign Intelligence Assistant
                                    </p>
                                </div>

                                {/* <div className="ml-6 px-4 py-2.5 bg-gradient-to-b from-[#FFB902] to-[#36D61F] rounded-[200px] flex items-center gap-1.5 border border-[#F4F5F780] shadow-[0px_1px_2px_0px_rgba(91,104,113,0.15),0px_0px_1px_0px_rgba(26,32,36,0.1)]">
                                    <div className="bg-g-70 rounded-[3px] py-[1px] px-1.5">
                                        <p className="text-white font-rocGroteskMedium text-sm">
                                            Task Credits
                                        </p>
                                    </div>
                                    <div className="text-base text-white font-rocGroteskMedium">
                                        <i className="ri-database-2-line "></i>
                                        <span className=""> 40 points available</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-3">
                        {aiNotifications?.length > 0 && (
                            <Dropdown
                                handleChange={(name, value) => {
                                    // setDurationFilter(value);
                                }}
                                dropdown={
                                    <div className="flex items-center space-x-2 rounded-[200px] shadow-buttonShadow2 h-[36px] px-3 !border !border-slate-100">
                                        <i className="ri-notification-3-fill text-r-55 text-base"></i>
                                        <span className="text-sm text-slate-600 font-rocGroteskMedium">
                                            {aiNotifications?.length >= 10
                                                ? `${aiNotifications?.length}+`
                                                : aiNotifications?.length}{" "}
                                            new product notification(s)
                                        </span>
                                    </div>
                                }
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                // leaveOpen={true}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div
                                                onClick={(e) => e.stopPropagation()}
                                                className="rounded-lg w-[450px] "
                                            >
                                                <div className="flex justify-between items-center py-4 px-[18px] border-b border-slate-100">
                                                    <span className="text-sm font-rocGroteskBold ">
                                                        Notifications
                                                    </span>
                                                    <div className="flex items-center space-x-1">
                                                        <i className="ri-check-double-fill text-b-60 text-xl"></i>
                                                        <span className="text-xs font-rocGroteskMedium text-b-60">
                                                            Mark as read
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* <div className="flex items-center border-b border-slate-100 space-x-4 px-4">
                                                    {notificationTabs.map((tab) => (
                                                        <div
                                                            key={tab.id}
                                                            className={`py-2 text-xs font-rocGroteskMedium cursor-pointer ${
                                                                activeNotificationTab === tab.id
                                                                    ? "text-b-60 border-b-2 border-y-10"
                                                                    : "text-slate-500"
                                                            }`}
                                                            onClick={() =>
                                                                setActiveNotificationTab(tab.id)
                                                            }
                                                        >
                                                            {tab.label}
                                                        </div>
                                                    ))}
                                                </div> */}

                                                <div className="max-h-[320px] overflow-y-auto">
                                                    {aiNotifications?.map((item) => {
                                                        return (
                                                            <div
                                                                key={item?._id}
                                                                className="flex items-start space-x-2 px-[18px] py-4"
                                                            >
                                                                <div className="w-8 h-8 rounded-full shrink-0 flex items-center justify-center border border-[#928B78] bg-[#F7F7F7] text-xs text-[#928B78]">
                                                                    {item?.anomalyDescription?.charAt(
                                                                        0
                                                                    )}
                                                                </div>
                                                                <div className="w-fill-available">
                                                                    <div className="mb-2">
                                                                        <div className="flex items-center justify-between space-x-2 mb-[2px]">
                                                                            <h5 className="text-sm text-b-60 font-rocGroteskBold ">
                                                                                {truncate(
                                                                                    item?.anomalyDescription,
                                                                                    { length: 45 }
                                                                                )}
                                                                            </h5>
                                                                            <span className="text-xs text-gm-35 font-rocGroteskMedium">
                                                                                {moment(
                                                                                    item?.createdAt
                                                                                ).fromNow()}
                                                                            </span>
                                                                        </div>
                                                                        <p className="text-xs text-gm-35 font-rocGroteskMedium">
                                                                            {truncate(
                                                                                item?.recommendation,
                                                                                { length: 60 }
                                                                            )}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex items-center justify-between space-x-2">
                                                                        <div className="flex items-center gap-1 flex-wrap">
                                                                            {item?.campaign &&
                                                                                item?.campaign
                                                                                    ?.length > 0 &&
                                                                                item?.channel?.map(
                                                                                    (channel) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={
                                                                                                    channel
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    window.open(
                                                                                                        getChannelLoginUrl(
                                                                                                            channel
                                                                                                        ),
                                                                                                        "_blank"
                                                                                                    )
                                                                                                }
                                                                                                className="flex cursor-pointer items-center px-3 py-2 bg-slate-50 font-rocGroteskMedium text-sm text-slate-500 space-x-2.5 w-fit rounded-lg"
                                                                                            >
                                                                                                <span>
                                                                                                    Open{" "}
                                                                                                    {
                                                                                                        channel
                                                                                                    }
                                                                                                </span>
                                                                                                <i className="ri-external-link-line text-xl"></i>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </div>

                                                                        {item?.campaign &&
                                                                            item?.campaign?.length >
                                                                                0 && (
                                                                                <div className="flex items-center px-2 h-6 font-rocGroteskMedium  text-p-55 space-x-1 w-fit rounded-lg border border-slate-100">
                                                                                    {/* <i className="ri-shopping-basket-2-line text-2.5"></i> */}
                                                                                    <span className="text-[10px]">
                                                                                        Campaign
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                <div className="flex items-center justify-center py-2">
                                                    <span
                                                        onClick={() => {
                                                            navigate(
                                                                "/dashboard/workforce/notifications",
                                                                {
                                                                    state: {
                                                                        internalNavigation: true,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        className="text-sm font-rocGroteskBold text-b-60 cursor-pointer w-fit"
                                                    >
                                                        See all
                                                    </span>
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        )}
                        <div className="relative">
                            <Dropdown
                                handleChange={(name, value) => {
                                    setDurationFilter(value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-1.5 px-3 !border !border-slate-100 gap-2">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {durationFilter !== "CustomDate"
                                                ? durationFilter
                                                : dates?.startDate && dates?.endDate
                                                ? `${moment(dates.startDate).format(
                                                      "MMM DD, YY"
                                                  )} - ${moment(dates.endDate).format(
                                                      "MMM DD, YY"
                                                  )}`
                                                : durationFilter}
                                        </div>
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                    </div>
                                }
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={[
                                    {
                                        label: "All data",
                                        value: "All data",
                                        action: () => {
                                            setDates({
                                                startDate: "",
                                                endDate: "",
                                            });
                                            customOnChange("");
                                        },
                                    },
                                    {
                                        label: "This month",
                                        value: "This month",
                                        action: () => {
                                            setDates({
                                                startDate: moment()
                                                    .startOf("month")
                                                    ?.format("YYYY-MM-DD"),
                                                endDate: moment()
                                                    .endOf("month")
                                                    ?.format("YYYY-MM-DD"),
                                            });
                                            customOnChange("");
                                        },
                                    },
                                    ...(years && Array.isArray(years)
                                        ? years.map((item) => ({
                                              label:
                                                  item === new Date().getFullYear()
                                                      ? `This
                                                          year (${item})`
                                                      : item?.toString(),
                                              value: item,
                                              action: () => {
                                                  setDates({
                                                      startDate: moment(item, "YYYY")
                                                          .startOf("year")
                                                          ?.format("YYYY-MM-DD"),
                                                      endDate: moment(item, "YYYY")
                                                          .endOf("year")
                                                          ?.format("YYYY-MM-DD"),
                                                  });
                                                  customOnChange("");
                                              },
                                          }))
                                        : []),
                                    {
                                        label: "Custom",
                                        value: "Custom",
                                        action: () => {
                                            setShowCustomDate(true);
                                        },
                                    },
                                ]}
                            />

                            <div className="relative">
                                {showCustomDate && (
                                    <div
                                        ref={customRef}
                                        className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                    >
                                        <CustomCalendar
                                            showDoubleView={true}
                                            selectRange={true}
                                            value={customValue}
                                            onChange={customOnChange}
                                        />
                                        <div className="flex justify-end">
                                            <Button
                                                btnText={"Close"}
                                                btnClassname={"!w-fit !py-2"}
                                                onClick={() => setShowCustomDate(false)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            btnText="Plan a campaign"
                            onClick={() => {
                                setCampaignModal(true);
                            }}
                            type="submit"
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="p-8">
                    {isLoading ? (
                        <div className="grid grid-cols-3 lg:grid-cols-5 space-x-3 mb-[43px]">
                            {[1, 1, 1, 1, 1]?.map((item, idx) => (
                                <Skeleton
                                    key={idx}
                                    width={"100%"}
                                    height={127}
                                    highlightColor="#E2E8F0"
                                    borderRadius={8}
                                    baseColor="#F1F5F9"
                                    className="custom-skeleton"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="grid grid-cols-3 lg:grid-cols-5 space-x-3 mb-[43px]">
                            <WorkforceAnalyticsCard
                                title="Total campaigns"
                                value={formatMoney().format(stats?.totalCampaigns)}
                                containerClassname="!shadow-[0px_1px_4px_0px_rgba(16,24,40,0.07)] !border-[#FDFDFD] !border-[1px]"
                            />
                            <WorkforceAnalyticsCard
                                title="Total campaigns completed"
                                value={formatMoney().format(stats?.completedCampaigns)}
                                containerClassname="!shadow-[0px_1px_4px_0px_rgba(16,24,40,0.07)] !border-[#FDFDFD] !border-[1px]"
                            />
                            <WorkforceAnalyticsCard
                                title="Total ongoing campaigns"
                                value={formatMoney().format(stats?.ongoingCampaigns)}
                                containerClassname="!shadow-[0px_1px_4px_0px_rgba(16,24,40,0.07)] !border-[#FDFDFD] !border-[1px]"
                            />

                            <WorkforceAnalyticsCard
                                title="Efficiency ratio"
                                value={`${formatMoney().format(stats?.efficiency)}%`}
                                containerClassname="!shadow-[0px_1px_4px_0px_rgba(16,24,40,0.07)] !border-[#FDFDFD] !border-[1px]"
                                bottomComp={
                                    Boolean(stats?.efficiencyGrowth) && (
                                        <div className="flex items-center space-x-2">
                                            <div
                                                className={`${
                                                    String(stats?.efficiencyGrowth)?.startsWith("-")
                                                        ? "text-r-55 bg-r-25"
                                                        : "bg-[#F0FDF4] text-[#22C55E]"
                                                } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                            >
                                                <i className="ri-arrow-up-line mt-[-2px] "></i>
                                                <span>
                                                    {formatMoney().format(stats?.efficiencyGrowth)}%
                                                </span>
                                            </div>
                                            <span className="text-sm text-slate-500">
                                                since last month
                                            </span>
                                        </div>
                                    )
                                }
                            />
                            <WorkforceAnalyticsCard
                                title="Tasks needing Approval"
                                value={formatMoney().format(stats?.approvalTasks)}
                                containerClassname="!shadow-[0px_1px_4px_0px_rgba(16,24,40,0.07)] !border-[#FDFDFD] !border-[1px]"
                            />
                        </div>
                    )}
                    <ListView
                        campaignAlert={campaignAlert}
                        setCampaignAlert={setCampaignAlert}
                        setCampaignModal={setCampaignModal}
                    />
                </div>
            </div>

            <PlanACampaignModal campaignModal={campaignModal} setCampaignModal={setCampaignModal} />
        </PageFrame>
    );
};

export default CampaignOverview;
