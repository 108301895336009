import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import PageFrame from "components/layout/PageFrame";
import { getChannelLoginUrl } from "helpers/getChannelLoginUrl";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import useGetAiNotifications from "hooks/workforceHooks/useGetAiNotifications";
import { truncate } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AiNotifications = () => {
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/workforce" });

    const navigate = useNavigate();
    const [activeNotificationTab, setActiveNotificationTab] = useState<number>(0);

    const {
        data: aiNotifications,
        isFetching: isFetchingAiNotifications,
        handleSearch,
        handleDebouncedSearch,
        search,
        pagination,
    } = useGetAiNotifications();

    const notificationTabs = [
        { id: 0, label: "All alerts" },
        { id: 1, label: "Forecast" },
        { id: 2, label: "Inventory" },
        { id: 3, label: "PO/Shipment" },
    ];

    return (
        <PageFrame isLoading={isFetchingAiNotifications && Number(pagination.currentPage) === 1}>
            <div className="bg-white rounded-lg border border-n-20 relative">
                <div className="flex items-center justify-between px-6 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <div className="flex items-center space-x-1.5 py-[15px]">
                        <i
                            onClick={() => goBack()}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>

                        <div className="">
                            <p className="text-lg mb-[2px] font-rocGroteskMedium text-gm-50">
                                All alerts
                            </p>
                        </div>
                    </div>
                </div>

                <div className="p-6">
                    {/* <div className="flex items-center justify-between">
                        <div className="flex items-center border-b border-slate-100 space-x-4 px-4">
                            {notificationTabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    className={`py-2 text-xs font-rocGroteskMedium cursor-pointer ${
                                        activeNotificationTab === tab.id
                                            ? "text-b-60 border-b-2 border-y-10"
                                            : "text-slate-500"
                                    }`}
                                    onClick={() => setActiveNotificationTab(tab.id)}
                                >
                                    {tab.label}
                                </div>
                            ))}
                        </div>

                        <div className="flex items-center space-x-2">
                            <TextInput
                                value={search}
                                name={"search"}
                                type={"text"}
                                required={false}
                                onChange={(e) => handleSearch((e.target as HTMLInputElement).value)}
                                onInput={(e) =>
                                    handleDebouncedSearch((e.target as HTMLInputElement).value)
                                }
                                inputPlaceholder="Search"
                                // containerClassname=""
                                inputContainerClassname={"!h-[40px]"}
                                inputClassName="!w-[400px] !h-[40px]"
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <div className="flex items-center space-x-1">
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                
                                                Status
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                // dropdownClassName=""
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 left-0 rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        label: "All alerts",
                                        value: "all-alerts",
                                    },
                                ]}
                            />
                        </div>
                    </div> */}

                    {aiNotifications?.length > 0 ? (
                        <div className="py-6">
                            {aiNotifications?.map((item) => {
                                return (
                                    <div
                                        key={item?._id}
                                        className="flex items-start space-x-2 px-[18px] py-4"
                                    >
                                        <div className="w-8 h-8 rounded-full shrink-0 flex items-center justify-center border border-[#928B78] bg-[#F7F7F7] text-xs text-[#928B78]">
                                            {item?.anomalyDescription?.charAt(0)}
                                        </div>
                                        <div className="w-fill-available">
                                            <div className="mb-2">
                                                <div className="flex items-center justify-between space-x-5 mb-[2px]">
                                                    <h5 className="text-sm text-b-60 font-rocGroteskBold ">
                                                        {/* {truncate(item?.anomalyDescription, {
                                                            length: 80,
                                                        })} */}
                                                        {item?.anomalyDescription}
                                                    </h5>
                                                    <span className="text-xs text-gm-35 font-rocGroteskMedium">
                                                        {moment(item?.createdAt).fromNow()}
                                                    </span>
                                                </div>
                                                <p className="text-xs text-gm-35 font-rocGroteskMedium w-[90%]">
                                                    {/* {truncate(item?.recommendation, {
                                                        length: 170,
                                                    })} */}
                                                    {item?.recommendation}
                                                </p>
                                            </div>

                                            <div className="flex items-center justify-between space-x-2">
                                                <div className="flex items-center gap-1 flex-wrap">
                                                    {item?.campaign &&
                                                        item?.campaign?.length > 0 &&
                                                        item?.channel?.map((channel) => {
                                                            return (
                                                                <div
                                                                    key={channel}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            getChannelLoginUrl(
                                                                                channel
                                                                            ),
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                    className="flex cursor-pointer items-center px-3 py-2 bg-slate-50 font-rocGroteskMedium text-sm text-slate-500 space-x-2.5 w-fit rounded-lg"
                                                                >
                                                                    <span>Open {channel}</span>
                                                                    <i className="ri-external-link-line text-xl"></i>
                                                                </div>
                                                            );
                                                        })}
                                                </div>

                                                {item?.campaign && item?.campaign?.length > 0 && (
                                                    <div className="flex items-center px-2 h-6 font-rocGroteskMedium  text-p-55 space-x-1 w-fit rounded-lg border border-slate-100">
                                                        {/* <i className="ri-shopping-basket-2-line text-2.5"></i> */}
                                                        <span className="text-[10px]">
                                                            Campaign
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="py-12">
                            <p className="text-center text-xl font-rocGroteskMedium">
                                No notifications found
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </PageFrame>
    );
};

export default AiNotifications;
