import React from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import AppRoutes from "./routes";
import config from "config/config";
import * as Ably from "ably";
import { AblyProvider, ChannelProvider } from "ably/react";
import { NavProvider } from "components/common/Navbar/NavContext";

const client = new Ably.Realtime({ key: config.ABLY_API_KEY });

function App() {
    const allRoutes = useRoutes(AppRoutes());

    return (
        <>
            <AblyProvider client={client}>
                <Toaster position="top-right" />
                <NavProvider>
                    <ChannelProvider channelName="campaign">
                        <div className="App">{allRoutes}</div>
                    </ChannelProvider>
                </NavProvider>
            </AblyProvider>
        </>
    );
}

export default App;
