import { truncate, uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import moment from "moment";
import Button from "components/common/Button";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";

const PurchaseOrderShipmentDetails = ({ setAddShipment }) => {
    const limit = 10;
    const { purchaseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [poShipment, setPoShipment] = useState([]);
    const [showShipment, setShowShipment] = useState(poShipment?.[0]?._id);
    const [poShipmentPage, setPoShipmentPage] = useState<number>(1);
    const [loadMorePoShipment, setLoadMorePoShipment] = useState(false);
    const [clickedIndex, setClickedIndex] = useState(0);
    const [poShipmentPagination, setPoShipmentPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const shipmentCardContent = document.getElementById("dashboardContent");
    const { fetchPoShipmentSuccess, fetchingPoShipment } =
        useAppSelector((state) => state.shipment);

    // useEffect(() => {
    //     dispatch(shipmentActions.getPoShipment(purchaseId, limit, poShipmentPage));
    // }, [dispatch, purchaseId, limit, poShipmentPage]);



    useEffect(() => {
        setShowShipment(poShipment?.[0]?._id);
    }, [poShipment]);

    const onCardScroll = useCallback(
        () =>
            onScroll(
                shipmentCardContent,
                poShipmentPagination?.current as number,
                poShipmentPagination?.number_of_pages as number,
                () => {
                    setPoShipmentPage(() => poShipmentPage + 1);
                    setLoadMorePoShipment(true);
                }
            ),
        [shipmentCardContent, poShipmentPagination]
    );

    useEffect(() => {
        shipmentCardContent?.addEventListener("scroll", onCardScroll);
        return () => {
            shipmentCardContent?.removeEventListener("scroll", onCardScroll);
        };
    }, [shipmentCardContent, onCardScroll]);

    useEffect(() => {
        if (Boolean(fetchPoShipmentSuccess)) {
            setPoShipment((prev) =>
                poShipmentPage > 1
                    ? uniqBy([...prev, ...fetchPoShipmentSuccess?.data], "_id")
                    : [...fetchPoShipmentSuccess?.data]
            );
            setPoShipmentPagination({
                current: fetchPoShipmentSuccess?.pagination.current,
                number_of_pages: fetchPoShipmentSuccess?.pagination?.number_of_pages,
            });
            // setTotalShipment(fetchPoShipmentSuccess?.total);
        }
    }, [fetchPoShipmentSuccess]);
    return (
        <>
            {fetchingPoShipment && poShipment.length === 0 ? (
                <Loader />
            ) : (
                <div className="w-full pb-20 md:hidden block">
                    {poShipment?.length > 0 ? (
                        <>
                            {poShipment?.map((shipment, index) => (
                                <div className="h-full overflow-y-scroll" id="shipmentCardContent">
                                    <div>
                                        <div className=" mt-2 space-y-1.5  p-4 rounded-[10px] border">
                                            <div className="flex items-center pb-2 justify-between pt-2">
                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                    {shipment?.taId}
                                                </span>

                                                <div className=" items-center md:hidden flex space-x-2">
                                                    <div
                                                        className={`space-x-2 ${
                                                            shipment?.status?.toLowerCase() ===
                                                                "pending" && "bg-light-purple-2"
                                                        } ${
                                                            shipment?.status?.toLowerCase() ===
                                                                "completed" && "bg-light-green-1"
                                                        }
                                            ${
                                                shipment?.status?.toLowerCase() === "delivered" &&
                                                "bg-light-green-1"
                                            } 
                                            ${
                                                shipment?.status?.toLowerCase() === "in-transit" &&
                                                "bg-[#F9DFB399]"
                                            } font-rocGroteskMedium flex items-center h-full text-sm py-[5px] px-[15px]  rounded-[25px]`}
                                                    >
                                                        <div
                                                            className={`h-2 w-2 ${
                                                                shipment?.status?.toLowerCase() ===
                                                                    "pending" && "bg-[#6562E5]"
                                                            } ${
                                                                shipment?.status?.toLowerCase() ===
                                                                    "completed" && "bg-[#16C6A4]"
                                                            } 
                                                ${
                                                    shipment?.status?.toLowerCase() ===
                                                        "delivered" && "bg-[#16C6A4]"
                                                }
                                                ${
                                                    shipment?.status?.toLowerCase() ===
                                                        "in-transit" && "bg-orange"
                                                } rounded-full`}
                                                        ></div>
                                                        <span>
                                                            {shipment?.status
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                shipment?.status.slice(1)}
                                                        </span>
                                                    </div>
                                                    <i
                                                        className={` ${
                                                            clickedIndex === index
                                                                ? "ri-arrow-down-s-line"
                                                                : "ri-arrow-right-s-line"
                                                        } text-xl cursor-pointer`}
                                                        onClick={() => {
                                                            setShowShipment(shipment?._id);
                                                            setClickedIndex(index);
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            {clickedIndex === index && (
                                                <>
                                                    {shipment?.billOfLading && (
                                                        <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                Bill of laden
                                                            </span>
                                                            <span className="text-sm font-rocGroteskMedium text-right">
                                                                {shipment?.billOfLading}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {shipment?.airWayBillNumber && (
                                                        <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                Airway Bill number
                                                            </span>
                                                            <span className="text-sm font-rocGroteskMedium text-right">
                                                                {shipment?.airWayBillNumber}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {shipment?.trackingLink && (
                                                        <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                Tracking link
                                                            </span>
                                                            <span className="text-sm font-rocGroteskMedium text-right">
                                                                {truncate(shipment?.trackingLink, {
                                                                    length: 20,
                                                                })}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                            Shipment origin
                                                        </span>
                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                            {truncate(shipment?.origin?.address, {
                                                                length: 20,
                                                            })}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                            Shipment destination
                                                        </span>
                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                            {truncate(
                                                                shipment?.destination?.address
                                                                    ?.address ??
                                                                    shipment?.destination?.address
                                                                        ?.country,
                                                                {
                                                                    length: 20,
                                                                }
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                            Est.Time of delivery
                                                        </span>
                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                            {moment(shipment?.createdAt).format(
                                                                "DD MMM, YYYY"
                                                            )}
                                                        </span>
                                                    </div>

                                                    {/* <div className="flex items-center justify-between py-2">
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    Est.Time of
                                    arrival
                                </span>
                                <span className="text-sm font-rocGroteskMedium text-right">
                                    Apr 17, 2023
                                    2:01 AM
                                </span>
                            </div> */}
                                                </>
                                            )}
                                            <div
                                                className={` ${
                                                    clickedIndex === index && "pt-5"
                                                } md:hidden block`}
                                            >
                                                <Button
                                                    btnText="Add stock"
                                                    btnClassname={`!py-2 ${
                                                        shipment?.status?.toLowerCase() ===
                                                            "delivered" && "!opacity-50"
                                                    }`}
                                                    disabled={
                                                        shipment?.status?.toLowerCase() ===
                                                        "delivered"
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/inventory/stock-control/stock-entry?channel=shipment&shipmentId=${shipment?._id}`,
                                                            { state: { internalNavigation: true } }
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="pt-5 md:block hidden">
                                            <Button
                                                btnText="Add stock"
                                                btnClassname={`!py-2 ${
                                                    shipment?.status?.toLowerCase() ===
                                                        "delivered" && "!opacity-50"
                                                }`}
                                                disabled={
                                                    shipment?.status?.toLowerCase() === "delivered"
                                                }
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/inventory/stock-control/stock-entry?channel=shipment&shipmentId=${shipment?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {loadMorePoShipment && fetchingPoShipment && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {poShipmentPagination?.current ===
                                poShipmentPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                    <p className="text-center"> End of list</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-[458px]  px-10">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/empty_state_cargo_k8qlnf.svg"
                                alt="empty state"
                                className="w-10 h-10 mb-6"
                            />
                            <p className="text-base font-rocGroteskMedium text-gm-40 text-center">
                                We apologize, but there are no shipment details available at the
                                moment.
                            </p>
                        </div>
                    )}
                    <div className="pt-4 bg-white px-4 pb-4 fixed bottom-0 left-0 right-0">
                        {poShipment?.length > 0 && (
                            <Button
                                icon={<i className="ri-add-circle-line text-xl text-[#142837]"></i>}
                                btnClassname={`!py-2 !bg-[#E2E8F0] ${
                                    poShipment?.[0]?.status?.toLowerCase() === "rejected" &&
                                    "!opacity-[0.3]"
                                }  !border-[#E2E8F0]  w-full  !border`}
                                btnText="Add shipment"
                                onClick={() => setAddShipment(true)}
                                disabled={poShipment?.[0]?.status?.toLowerCase() === "rejected"}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PurchaseOrderShipmentDetails;
