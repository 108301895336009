import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import EditOfflinePurchase from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/EditOfflinePurchase";
import SelectInput from "components/common/InputField/SelectInput";
import PageFrame from "components/layout/PageFrame";
import EditDamages from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/EditDamages";
import BreadCrumb from "components/common/BreadCrumb";
import { truncate } from "lodash";
import CustomFieldsImplement from "components/common/CustomFieldsImplement";
// import { getEditPermissionList } from "helpers/getEditPermissionList";
import ModalContainer from "components/common/ModalContainer";
import CustomFields from "components/common/CustomFields";

const EditStock = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const showCrumbs = params.get("showCrumbs");
    const currTab = params.get("currTab");
    const { id } = useParams<any>();
    const [reason, setReason] = useState<string>("");
    const [profile] = useGetUserProfile();
    const [attachment, setAttachment] = useState<{ [key: string]: any }[]>([]);
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [productDates, setProductDates] = useState<{ [key: string]: any }>();
    const [stockDetails, setStockDetails] = useState<{ [key: string]: any }>();
    const [stockAllDetails, setStockAllDetails] = useState<{ [key: string]: any }>();
    const [deleteAttachments, setDeleteAttachments] = useState<string[]>([]);
    const [editStockDetails, setEditStockDetails] = useState<{ [key: string]: any }>();
    const [customModal, setCustomModal] = useState<boolean>(false);
    const [fieldType, setFieldType] = useState<string>("text");
    const [fieldName, setFieldName] = useState<string>("");
    const [customFieldArray, setCustomFieldArray] = useState<any>([]);
    const [editCustomFieldArray, setEditCustomFieldArray] = useState<any>([]);
    const [customAttachments, setCustomAttachments] = useState<any>();
    const [editCustomFieldIdx, setEditCustomFieldIdx] = useState<number>();
    const [deleteCustomFieldModal, setDeleteCustomFieldModal] = useState<boolean>(false);
    const [deleteIdx, setDeleteIdx] = useState<number>();
    const [productName, setProductName] = useState<string>("");
    const [recurringSetting, setRecurringSetting] = useState<boolean>(false);
    const [singleValueField, setSingleValueField] = useState<any>();
    const [multipleValuesField, setMultipleValuesField] = useState<any>([
        {
            selected: true,
            value: "",
        },
    ]);

    const { fetchingSingleStock, fetchedSingleStockSuccess, updatingStock, updatedStockSuccess } =
        useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (stockAllDetails?.customFields?.length > 0) {
            const formatCustomFields = () => {
                return stockAllDetails?.customFields?.map((field) => {
                    let formattedField = {
                        name: undefined,
                        value: null,
                    };

                    for (const key in field) {
                        if (key !== "fieldType") {
                            if (field.fieldType !== "phone" && field.fieldType !== "currency") {
                                formattedField.name = key;
                                formattedField.value = field[key];
                                formattedField[field?.fieldType] = field[key];
                            } else if (typeof field[key] === "object") {
                                formattedField = { ...formattedField, ...field[key], name: key };
                            }
                        }
                    }
                    if (formattedField.value === null) {
                        delete formattedField.value;
                    }

                    return { ...field, ...formattedField };
                });
            };
            setCustomFieldArray(formatCustomFields);
        }
    }, [stockAllDetails]);

    const handleDeleteFromCustomFieldsArray = (idx) => {
        const newArr = [...customFieldArray];

        newArr.splice(idx, 1);
        setCustomModal(false);

        setCustomFieldArray(newArr);
        setDeleteCustomFieldModal(false);
        setDeleteIdx(-1);
    };

    const handleEditCustomField = (idx) => {
        const customArr = [...customFieldArray];
        setEditCustomFieldIdx(idx);
        setFieldType(customArr[idx]?.fieldType);
        setFieldName(customArr[idx]?.name);
        setRecurringSetting(customArr[idx]?.recurringSetting);
        if (
            customArr[idx]?.fieldType === "checkbox" ||
            customArr[idx]?.fieldType === "radio" ||
            customArr[idx]?.fieldType === "dropDown"
        ) {
            setMultipleValuesField(customArr[idx]);
        } else if (customArr[idx]?.fieldType === "file") {
            setCustomAttachments(customArr[idx]);
        } else {
            setSingleValueField(customArr[idx]);
        }
        setCustomModal(true);
    };

    useEffect(() => {
        const copy = [...customFieldArray];
        const formatCustomFieldArray = copy.map(
            ({ name, value, fieldType, mobileExtension, mobile, currency, amount, ...rest }) => ({
                fieldType,
                [name]: value,
                ...(mobileExtension !== undefined && { mobileExtension }),
                ...(mobile !== undefined && { mobile }),
                ...(currency !== undefined && { currency }),
                ...(amount !== undefined && { amount }),
            })
        );
        setEditCustomFieldArray(formatCustomFieldArray);
    }, [customFieldArray]);

    const handleDateChange = (name, newDate) => {
        setProductDates({
            ...productDates,
            [name]: newDate,
        });
    };

    useEffect(() => {
        setStockDetails({
            inventoryId: stockAllDetails?.inventory?._id ?? "",
            vendorId: stockAllDetails?.vendorId ?? "",
            cost: {
                amount: stockAllDetails?.inventory?.unitCost?.amount ?? "",
                currency: stockAllDetails?.inventory?.unitCost?.currency ?? "",
            },
            quantity: stockAllDetails?.productStockDetails?.stockLevel ?? "",
            warehouseId: stockAllDetails?.warehouse?._id ?? "",
            conditionNote: stockAllDetails?.conditionNote,
            inspectionNote: stockAllDetails?.inspectionNote,
            quarantine: stockAllDetails?.quarantine,
        });
        setProductName(stockAllDetails?.inventory?.productName ?? "");
        if (stockAllDetails?.notes) {
            stockDetails.notes = stockAllDetails.notes;
        }

        if (stockAllDetails?.vendorId) {
            stockDetails.vendorId = stockAllDetails.vendorId;
        }
        setProductDates({
            expiryDate: stockAllDetails?.productViabilityDetails?.expiryDate ?? "",
            manufacturingDate: stockAllDetails?.productViabilityDetails?.manufacturingDate ?? "",
            bestBefore: stockAllDetails?.productViabilityDetails?.bestBefore ?? "",
            removalTime: stockAllDetails?.productViabilityDetails?.removalTime ?? "",
        });
    }, [stockAllDetails]);

    const handleStockDetailsChange = (e) => {
        const { name, value } = e.target;
        setStockDetails((prev: any) => {
            if (name === "amount" || name === "currency") {
                return {
                    ...prev,
                    cost: {
                        ...prev?.cost,
                        [name]: value,
                    },
                };
            } else return { ...prev, [name]: value };
        });
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachment((prev) => [
                        ...prev,
                        { path: url, filename: fileName, documentType: "order stocks" },
                    ]);
                    setUploadingAttachment(false);
                }
            );
        }
    };

    const handleDeleteAttachment = (idx, id) => {
        const copyData = [...attachment];

        const imagePath = decodeURIComponent(
            copyData[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        firebaseService.removeFile(imagePath, () => {
            copyData?.splice(idx, 1);
            setAttachment(copyData);
        });

        if (id) {
            setDeleteAttachments((prev) => {
                const updatedAttachments = [...prev];
                updatedAttachments.push(id);
                return updatedAttachments;
            });
        }
    };

    const handleSaveStock = () => {
        if (attachment?.length > 0) {
            const copyAttach = [...attachment];
            const newAttachments = copyAttach?.filter((item) => !item?._id);
            if (newAttachments?.length > 0) {
                setEditStockDetails({
                    ...stockDetails,
                    quantity: Number(stockDetails?.quantity),
                    customFields: editCustomFieldArray,
                    reason,
                    attachments: newAttachments,
                });
            } else {
                setEditStockDetails({
                    ...stockDetails,
                    quantity: Number(stockDetails?.quantity),
                    reason,
                    customFields: editCustomFieldArray,
                });
            }
        } else {
            setEditStockDetails({
                ...stockDetails,
                quantity: Number(stockDetails?.quantity),
                reason,
                customFields: editCustomFieldArray,
            });
        }
    };

    useEffect(() => {
        if (Boolean(fetchedSingleStockSuccess)) {
            setStockAllDetails(fetchedSingleStockSuccess?.stock);

            setAttachment(fetchedSingleStockSuccess?.stock?.attachments);
        }
    }, [fetchedSingleStockSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(inventoryActions.fetchSingleStock(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (deleteAttachments?.length > 0) {
            setStockDetails((prev) => {
                return {
                    ...prev,
                    deleteAttachments,
                };
            });
        }
    }, [deleteAttachments]);

    useEffect(() => {
        setStockDetails({
            ...stockDetails,
            reason,
        });
    }, [reason]);

    useEffect(() => {
        if (editStockDetails) {
            const { inventoryId, ...newEditStockDetails } = editStockDetails;
            dispatch(inventoryActions?.updateStock(id, newEditStockDetails));
        }
    }, [editStockDetails]);

    useEffect(() => {
        if (updatedStockSuccess) {
            navigate(`/dashboard/inventory?currTab=${currTab}`, {
                state: { internalNavigation: true },
            });
            dispatch(inventoryActions.resetUpdateStock());
        }
    }, [dispatch, updatedStockSuccess]);

    const isValidDate = (date) => date && moment(date).isValid();
    useEffect(() => {
        setStockDetails((prev) => {
            if (
                isValidDate(productDates?.bestBefore) ||
                isValidDate(productDates?.expiryDate) ||
                isValidDate(productDates?.removalTime) ||
                isValidDate(productDates?.manufacturingDate)
            ) {
                const updatedProductViabilityDetails = {
                    ...(isValidDate(productDates?.bestBefore)
                        ? { bestBefore: moment(productDates?.bestBefore).format("YYYY-MM-DD") }
                        : {}),
                    ...(isValidDate(productDates?.expiryDate)
                        ? { expiryDate: moment(productDates?.expiryDate).format("YYYY-MM-DD") }
                        : {}),
                    ...(isValidDate(productDates?.removalTime)
                        ? { removalTime: moment(productDates?.removalTime).format("YYYY-MM-DD") }
                        : {}),
                    ...(isValidDate(productDates?.manufacturingDate)
                        ? {
                              manufacturingDate: moment(productDates?.manufacturingDate).format(
                                  "YYYY-MM-DD"
                              ),
                          }
                        : {}),
                };

                return {
                    ...prev,
                    expiryDate: updatedProductViabilityDetails?.expiryDate,
                    removalTime: updatedProductViabilityDetails?.removalTime,
                    manufacturingDate: updatedProductViabilityDetails?.manufacturingDate,
                    bestBefore: updatedProductViabilityDetails?.bestBefore,
                };
            } else {
                return {
                    ...prev,
                };
            }
        });
    }, [productDates]);
    return (
        <PageFrame isLoading={fetchingSingleStock} containerClassName={"!h-[calc(100vh-108px)]"}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveStock();
                }}
            >
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100 top-[-20px] md:sticky  z-[50] bg-white">
                        <p className="text-base font-rocGroteskMedium">Edit stock count</p>
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() =>
                                    navigate(`/dashboard/inventory?currTab=${currTab || 1}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                                disabled={updatingStock}
                            />
                            <Button
                                btnText="Save"
                                btnPermission={[
                                    "all_access",
                                    "add_stock",
                                    "transfer_stock",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                type="submit"
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                disabled={updatingStock}
                                isLoading={updatingStock}
                                // permissions={getEditPermissionList("inventory")}
                            />
                        </div>
                    </div>

                    {showCrumbs === "true" && (
                        <div className="px-8 mt-6">
                            <BreadCrumb
                                parentCrumb={"Product "}
                                parentUrl={"#"}
                                parentClassName="!text-slate-400"
                                childrenCrumb={[
                                    { title: "Warehouse", path: "" },
                                    {
                                        title: "Stock Count History",
                                        path: `/dashboard/inventory/stock-count/${stockAllDetails?.inventoryId}/warehouse/${stockAllDetails?.warehouseId}`,
                                    },
                                    { title: "Batch ID", path: "/#" },
                                ]}
                                size="small"
                            />
                        </div>
                    )}
                    <div className="mt-4 mb-6 px-8 pb-6 border-b border-slate-200">
                        <div className="max-w-[393px] flex gap-4 items-center">
                            {stockAllDetails?.inventory?.productImageDetails?.productAvatar ? (
                                <div className="w-[64px] h-[64px] rounded py-3 px-[10px] bg-[#D0F4ED]">
                                    <img
                                        src={
                                            stockAllDetails?.inventory?.productImageDetails
                                                ?.productAvatar
                                        }
                                        alt=""
                                        className=""
                                    />
                                </div>
                            ) : (
                                <div className="w-16 h-16 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}{" "}
                            <div className="font-rocGroteskMedium">
                                <p className="text-base  text-slate-700">
                                    {stockAllDetails?.inventory?.productName}
                                </p>
                                <p className="text-sm text-slate-700">
                                    {truncate(stockAllDetails?.inventory?.productDescription, {
                                        length: 80,
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 py-8">
                        <div className="w-[65%]">
                            <p className="text-gun-metal font-base font-rocGroteskMedium mb-4">
                                Why do you want to edit this stock?
                            </p>

                            <SelectInput
                                value={reason}
                                name="reason"
                                placeholder={"Select reason"}
                                handleChange={(name, value) => {
                                    setReason(value);
                                }}
                                isRequired
                                dropdownOptions={[
                                    {
                                        label: "Adjustment",
                                        value: "adjustment",
                                    },
                                    {
                                        label: "Damages",
                                        value: "damage",
                                    },
                                    {
                                        label: "Consumption",
                                        value: "consumption",
                                    },
                                    {
                                        label: "Returned",
                                        value: "return",
                                    },
                                ]}
                            />
                        </div>

                        {reason !== "damage" ? (
                            <div className="mt-8">
                                <div className="py-8">
                                    <EditOfflinePurchase
                                        stockDetails={stockDetails}
                                        setStockDetails={setStockDetails}
                                        handleStockDetailsChange={handleStockDetailsChange}
                                        attachment={attachment}
                                        uploadingAttachment={uploadingAttachment}
                                        handleUpload={handleUpload}
                                        handleDeleteAttachment={handleDeleteAttachment}
                                        productDates={productDates}
                                        productName={productName}
                                        handleDateChange={handleDateChange}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="py-8">
                                <EditDamages
                                    stockDetails={stockDetails}
                                    setStockDetails={setStockDetails}
                                    handleStockDetailsChange={handleStockDetailsChange}
                                    attachment={attachment}
                                    uploadingAttachment={uploadingAttachment}
                                    handleUpload={handleUpload}
                                    handleDeleteAttachment={handleDeleteAttachment}
                                />
                            </div>
                        )}

                        <div className="">
                            {customFieldArray?.length > 0 && (
                                <p className="text-base font-rocGroteskMedium mb-4">
                                    Custom Fields
                                </p>
                            )}
                            <div className="mb-10">
                                <div className="w-[65%]">
                                    {customFieldArray?.map((item, idx) => {
                                        return (
                                            <div className="relative" key={idx}>
                                                <CustomFields
                                                    displayCustomField={item}
                                                    fieldType={item?.fieldType}
                                                    required={false}
                                                    className="!mb-4"
                                                />
                                                {
                                                    <div className="flex items-center gap-4 absolute -right-[70px] top-0">
                                                        <i
                                                            className="ri-edit-line text-lg text-slate-400 cursor-pointer"
                                                            onClick={() =>
                                                                handleEditCustomField(idx)
                                                            }
                                                        ></i>
                                                        <i
                                                            className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                            onClick={() => {
                                                                setDeleteCustomFieldModal(true);
                                                                setDeleteIdx(idx);
                                                            }}
                                                        ></i>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <CustomFieldsImplement
                openModal={customModal}
                closeModal={() => setCustomModal(!customModal)}
                fieldType={fieldType}
                fieldName={fieldName}
                recurringSetting={recurringSetting}
                editCustomFieldIdx={editCustomFieldIdx}
                setFieldType={setFieldType}
                setFieldName={setFieldName}
                setRecurringSetting={setRecurringSetting}
                setEditCustomFieldIdx={setEditCustomFieldIdx}
                multipleValuesField={multipleValuesField}
                singleValueField={singleValueField}
                customAttachments={customAttachments}
                setMultipleValuesField={setMultipleValuesField}
                setSingleValueField={setSingleValueField}
                setCustomAttachments={setCustomAttachments}
                openDeleteModal={() => setDeleteCustomFieldModal(true)}
                setDeleteIdx={setDeleteIdx}
                customFieldArray={customFieldArray}
                setCustomFieldArray={setCustomFieldArray}
                showDeleteButton={false}
            />
            <ModalContainer
                open={deleteCustomFieldModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteCustomFieldModal(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">
                            Delete Custom Field?
                        </h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to permanently remove this custom field? This
                            action will also delete associated content, and it cannot be undone.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                onClick={() => setDeleteCustomFieldModal(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={() => {
                                    handleDeleteFromCustomFieldsArray(deleteIdx);
                                }}
                                btnText={"Delete"}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default EditStock;
