import moment from "moment";
import React, { useEffect, useState } from "react";
import { adjustHeight } from "variables";
import ChildComment from "./ChildComment";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetCampaignCommentReplies from "hooks/workforceHooks/useGetCampaignCommentReplies";
import Loader from "components/common/Loader";
import { workforceActions } from "redux/Ldb/actions";
import { useNavigate, useParams } from "react-router-dom";
// import EmojiPicker from "emoji-picker-react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { MentionsInput, Mention } from "react-mentions";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import { sanitizeTaggedUserStringForDisplay } from "helpers/sanitizeTaggedUserString";
import useGetUserProfile from "hooks/useGetUserProfile";
import { cloneDeep } from "lodash";

type ParentCommentProps = {
    comment: { [key: string]: any };
    handleReplyComment: (section: number, content: string, parentId?: string) => void;
    teamList: { id: string | number; display: string }[];
    handleDebouncedChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    userProfile: { [key: string]: any };
    commentId: string;
    setCommentId: React.Dispatch<React.SetStateAction<string>>;
    parentReplies: { [key: string]: any };
    setParentRepliesCopy: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >;
};

const ParentComment = ({
    comment,
    handleReplyComment,
    teamList,
    handleDebouncedChange,
    userProfile,
    commentId,
    setCommentId,
    parentReplies,
    setParentRepliesCopy,
}: ParentCommentProps) => {
    const [openReplies, setOpenReplies] = useState(false);
    const [message, setMessage] = useState("");
    // const [commentId, setCommentId] = useState("");
    const [resolved, setResolved] = useState(false);
    const [expandReactions, setExpandReactions] = useState(true);
    const [reactions, setReactions] = useState<
        {
            reaction: string;
            senderId: string;
            sender?: any;
            _id?: string;
        }[]
    >(() => comment?.reactions || []);
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();
    const navigate = useNavigate();
    // const { data: parentReplies } = useGetCampaignCommentReplies(commentId);
    const nameArr = (
        comment?.sender?.fullName ||
        comment?.sender?.firstName ||
        comment?.sender?.lastName ||
        comment?.sender?.email ||
        ""
    ).split(" ");

    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    const {
        creatingCampaignComment,
        createCampaignCommentSuccess,
        fetchingCampaignCommentReplies,
        fetchCampaignCommentRepliesSuccess,
        updatingCampaignComment,
        updateCampaignCommentSuccess,
    } = useAppSelector((state) => state.workforce);

    const handleResolveComment = (commentId: string) => {
        const body = { isResolved: true };

        dispatch(workforceActions.updateCampaignComment(campaignId, commentId, body));
    };

    const handleSaveReaction = (
        reactionsArray: { reaction: string; senderId: string }[],
        commentId: string
    ) => {
        const body = {
            reactions: reactionsArray?.map((reaction) => {
                return {
                    reaction: reaction?.reaction,
                    senderId: reaction?.senderId,
                };
            }),
        };

        dispatch(workforceActions.updateCampaignComment(campaignId, commentId, body));
    };

    const handleAddReaction = (e: EmojiClickData) => {
        const reactionsCopy = cloneDeep([...reactions]);
        const existingReactionIndex = reactionsCopy.findIndex(
            (reaction) => reaction.senderId === userProfile?._id
        );

        if (existingReactionIndex !== -1) {
            reactionsCopy[existingReactionIndex].reaction = e.emoji;
        } else {
            reactionsCopy.push({
                reaction: e.emoji,
                senderId: userProfile?._id,
            });
        }

        setReactions(reactionsCopy);
        // setExpandReactions(true);
        handleSaveReaction(reactionsCopy, comment?._id);
    };

    useEffect(() => {
        if (Boolean(createCampaignCommentSuccess)) {
            setMessage("");
        }
    }, [createCampaignCommentSuccess]);

    // useEffect(() => {
    //     if (Boolean(updateCampaignCommentSuccess && commentId === comment?._id)) {
    //         setResolved(true);
    //         dispatch(workforceActions.resetUpdateCompaignCommentSuccess());
    //     }
    // }, [dispatch, updateCampaignCommentSuccess, commentId]);

    // useEffect(() => {
    //     if (!openReplies) {
    //         setCommentId("");
    //     }
    // }, [dispatch, openReplies]);

    useEffect(() => {
        if (Boolean(fetchCampaignCommentRepliesSuccess && comment?._id === commentId)) {
            setOpenReplies(true);
            dispatch(workforceActions.resetGetCampaignCommentRepliesSuccess());
        }
    }, [dispatch, fetchCampaignCommentRepliesSuccess, parentReplies?._id, commentId]);

    return (
        <div className="p-4 bg-white rounded-[10px] border border-slate-100 font-rocGroteskMedium">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                    {comment?.senderType === "agent" ? (
                        <img
                            src={comment?.agent?.avatar}
                            className="w-8 h-8 rounded-full border border-[928B78] object-cover"
                        />
                    ) : (
                        <div className="w-8 h-8 flex items-center justify-center bg-[#F7F7F7] border border-[928B78] rounded-full">
                            <span className="text-xs text-[#928B78]">{initials}</span>
                        </div>
                    )}

                    <h6 className="text-sm font-rocGroteskBold">
                        {comment?.senderType === "agent"
                            ? comment?.agent?.teamMateName
                            : comment?.sender?.fullName ||
                              comment?.sender?.firstName ||
                              comment?.sender?.lastName ||
                              comment?.sender?.email}
                    </h6>

                    <span className="text-xs text-gm-35">
                        {moment(comment?.createdAt).fromNow()}
                    </span>
                </div>

                <div className="flex items-center space-x-4">
                    {updatingCampaignComment && commentId === comment?._id ? (
                        <Loader size={5} />
                    ) : comment?.isResolved || resolved ? (
                        <i className="ri-checkbox-circle-fill text-xl text-g-55"></i>
                    ) : (
                        <i
                            onClick={() => {
                                setCommentId(comment?._id);
                                handleResolveComment(comment?._id);
                            }}
                            className="ri-checkbox-circle-line text-slate-500 text-xl cursor-pointer"
                        ></i>
                    )}
                    <i className="ri-more-2-fill text-slate-500 text-xl"></i>
                </div>
            </div>

            <div className="relative">
                <div className={`pl-5 ml-4 border-l-[2px] border-dashed border-[#CBD5E1] `}>
                    <p
                        className={`text-sm text-slate-600 mb-5 ${
                            (comment?.isResolved || resolved) && "line-through"
                        }`}
                    >
                        {sanitizeTaggedUserStringForDisplay(comment?.content)}
                    </p>

                    {comment?.senderType === "agent" && comment?.data?.length > 0 && (
                        <div className="flex items-center gap-2 flex-wrap mb-5">
                            {comment?.data?.map((data) => {
                                return (
                                    <div
                                        key={data?._id}
                                        className="text-xs text-p-50 flex items-center space-x-1 px-2 py-1 bg-slate-50 rounded border border-p-50 cursor-pointer"
                                        onClick={() => {
                                            switch (data?.dataType) {
                                                case "purchase_order":
                                                    navigate(`/dashboard/purchase/${data?.dataId}`);
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        <i className="ri-file-list-3-line text-base text-p-50"></i>
                                        <span>
                                            {data?.dataType === "purchase_order" ? "PO" : ""}{" "}
                                            {data?.dataName}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {reactions?.length > 0 && (
                        <div className="mb-5 w-fit bg-slate-100 border border-slate-300 rounded-[100px] px-2 py-1 flex items-center space-x-2">
                            {reactions?.slice(0, 3)?.map((reaction) => {
                                return (
                                    <div
                                        key={reaction?.senderId}
                                        className="flex items-center space-x-1"
                                    >
                                        <span>{reaction?.reaction}</span>
                                        <span className="text-xs text-slate-500">
                                            {
                                                reactions.filter(
                                                    (r) => r.reaction === reaction.reaction
                                                ).length
                                            }
                                        </span>
                                    </div>
                                );
                            })}
                            {(() => {
                                const uniqueReactions = [
                                    ...new Set(reactions.map((r) => r.reaction)),
                                ];

                                return (
                                    uniqueReactions.length > 3 && (
                                        <span className="text-xs text-slate-500">
                                            +{uniqueReactions.length - 3}
                                        </span>
                                    )
                                );
                            })()}
                        </div>
                    )}

                    <div className="mb-5 flex items-center border border-slate-200 rounded-[100px]  w-fit">
                        <EmojiPicker
                            reactionsDefaultOpen={true}
                            lazyLoadEmojis={false}
                            allowExpandReactions={false}
                            // open={expandReactions}
                            skinTonesDisabled={true}
                            onReactionClick={(e) => {
                                console.log("e", e);
                                handleAddReaction(e);
                            }}
                            emojiStyle={EmojiStyle.NATIVE}
                            reactions={["1f44d", "1f44e", "2764-fe0f", "1f602", "2705"]}
                        />

                        {/* <span
                            onClick={() => setExpandReactions(false)}
                            className="text-xs text-slate-500 border border-slate-200 rounded-full w-[20px] h-[20px] flex items-center justify-center cursor-pointer"
                        >
                            +
                        </span> */}
                    </div>

                    {comment?.replyCount > 0 && (
                        <div
                            onClick={() => {
                                // setOpenReplies((prev) => !prev);
                                // if()
                                // setCommentId(comment?._id);
                                if (openReplies) {
                                    setOpenReplies(false);
                                    // setCommentId(comment?._id);
                                    setCommentId("");
                                } else {
                                    setCommentId(comment?._id);
                                }
                                setParentRepliesCopy({});
                            }}
                            className="flex items-center space-x-1 text-p-55 mb-5 w-fit cursor-pointer"
                        >
                            <span className="text-sm">
                                {comment?.replyCount}{" "}
                                {comment?.replyCount === 1 ? "reply" : "replies"}
                            </span>
                            {fetchingCampaignCommentReplies && commentId === comment?._id ? (
                                <Loader size={3} />
                            ) : (
                                <i className="ri-arrow-down-s-line "></i>
                            )}
                        </div>
                    )}
                    {
                        // comment?.replyCount > 0 &&
                        openReplies &&
                            comment?._id === commentId &&
                            // parentReplies?._id === comment?._id &&
                            parentReplies?.replies.map((reply) => {
                                return <ChildComment key={reply?._id} comment={reply} />;
                            })
                    }
                </div>

                <div className="w-8 h-8 absolute left-0 bottom-0  bg-gradient-to-b from-transparent to-white"></div>
            </div>

            <div className="flex items-center justify-between w-full bg-white rounded-[8px] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] pr-5">
                <div className="flex items-center space-x-3 w-full">
                    <MentionsInput
                        onKeyDown={(e) => {
                            if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                e.preventDefault();

                                if (message) {
                                    setCommentId(comment?._id);
                                    handleReplyComment(comment?.section, message, comment?._id);
                                }
                            }
                        }}
                        value={message}
                        autoFocus={false}
                        onChange={(evt, newValue, newPlainTextValue, mentions) => {
                            setMessage(evt.target.value);

                            const matches = evt.target.value.match(
                                /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                            );

                            if (matches) {
                                const lastAt = matches[matches.length - 1];

                                handleDebouncedChange({
                                    target: { value: lastAt?.slice(1) },
                                } as React.ChangeEvent<HTMLInputElement>);
                            }
                        }}
                        id="chatInput"
                        placeholder={"Reply comment, @ to mention"}
                        className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                        customSuggestionsContainer={(children) => {
                            return (
                                <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                    {children}
                                </div>
                            );
                        }}
                        style={{
                            "&multiLine": {
                                input: {
                                    padding: "16px",
                                    outline: "none",
                                    border: "none",
                                    fontSize: "12px",
                                    backgroundColor: "white",
                                    // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                    overflowY: "auto",
                                    resize: "none",
                                    minHeight: "45px",
                                    maxHeight: "150px",
                                },
                            },
                        }}
                    >
                        <Mention
                            trigger="@"
                            data={teamList}
                            style={{ color: "#5452BF", fontWeight: 500, textDecoration: "none" }}
                            renderSuggestion={(
                                entry,
                                search,
                                highlightedDisplay,
                                index,
                                focused
                            ) => {
                                return (
                                    <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                        {entry?.display}
                                    </div>
                                );
                            }}
                        />
                    </MentionsInput>
                </div>

                {creatingCampaignComment && commentId === comment?._id ? (
                    <div>
                        <Loader size={3} />
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            if (message) {
                                handleReplyComment(comment?.section, message, comment?._id);
                            }
                        }}
                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                    >
                        <i className="ri-arrow-right-line text-[12px]"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParentComment;
