import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, set, sortBy, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetListBundle = () => {
    const dispatch = useAppDispatch();
    const { fetchingListBundle, fetchedListBundleSuccess, fetchedListBundleFailure } =
        useAppSelector((state) => state.inventory);

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [productSearchList, setProductSearchList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [searchTwo, setSearchTwo] = useState<string>("");
    const [selectedProduct, setSelectedProduct] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTwo(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearchTwo(e.target.value);
    }, 1000);

    const handleFilterChange = (value: string) => {
        setUpdatedPage(1);
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
    };

    const handleFilterChangeTwo = (name: string, value: string) => {
        setSelectedProduct({ name, value });
    };

    const handleClearFilterTwo = () => {
        setSelectedProduct({ name: "", value: "" });
    };

    useEffect(() => {
        dispatch(inventoryActions.fetchListBundle(10, updatedPage, debouncedSearch));

        return () => {
            dispatch(inventoryActions.resetFetchListBundle());
        };
    }, [dispatch, debouncedSearch, updatedPage]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventorySearchList(10, debouncedSearchTwo, ));
    }, [dispatch, debouncedSearchTwo]);

    useEffect(() => {
        if (Boolean(fetchedListBundleSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedListBundleSuccess.bundles], "_id")
                    : uniqBy([...prev, ...fetchedListBundleSuccess.bundles], "_id")
            );
            setPagination({
                currentPage: fetchedListBundleSuccess.pagination?.current,
                noOfPages: fetchedListBundleSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchListBundle());
        }
    }, [dispatch, fetchedListBundleSuccess, updatedPage, debouncedSearch]);

    return {
        isFetching: fetchingListBundle,
        error: fetchedListBundleFailure,
        data: productList,
        productSearchList,
        setProductList,
        pagination,
        setPagination,
        debouncedSearch,
        search,
        setSearch,
        setDebouncedSearch,
        searchTwo,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleSearch,
        handleSearchTwo,
        handleFilterChange,
        handleFilterChangeTwo,
        handleClearFilter,
        handleClearFilterTwo,
        selectedProduct,
        isLoadingMore,
    };
};

export default useGetListBundle;
