import { useLocation, useNavigate } from "react-router-dom";

interface NavigationHandlerOptions {
    fallbackPath?: string;
    preserveQueryParams?: boolean;
}

export const useNavigationHandler = (
    options: NavigationHandlerOptions = { fallbackPath: "/dashboard" }
) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { fallbackPath, preserveQueryParams = false } = options;

    const isInternalNavigation = Boolean(location.state?.internalNavigation);
    const goBack = async () => {
        if (isInternalNavigation) {
            navigate(-1);
        } else {
            // const queryParams = preserveQueryParams ? location.search : "";
            // console.log(location.search);
            navigate(`${fallbackPath}`);
        }
    };

    return {
        goBack,
    };
};
