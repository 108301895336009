import React from "react";
import { Document, Page, Font, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { formatMoney, getCurrencyFromCurrencyCode, hasHTMLTag, sanitizeHtml } from "helpers";
import moment from "moment";

type PurchaseOrdersPDFProps = {
    vendorName: string;
    vendorPhone: string;
    vendorAddress: string;
    vendorEmail: string;
    ldbName: string;
    ldbPhone: string;
    ldbEmail: string;
    ldbAddress: string;
    imageDetails: string;
    title?: string;
    totalQuantity: number;
    totalAmount: number;
    taxAmount: number;
    filesAttached?: { [key: string]: any };
    containers: { [key: string]: any }[];
    customerDetails?: { [key: string]: any };
    approvers?: { [key: string]: any };
};

const PurchaseOrdersPDF = ({
    imageDetails,
    title,
    containers,
    customerDetails,
    ldbName,
    ldbEmail,
    ldbAddress,
    vendorName,
    vendorEmail,
    vendorAddress,
    totalQuantity,
    totalAmount,
    taxAmount,
    filesAttached,
    ldbPhone,
    vendorPhone,
}: PurchaseOrdersPDFProps) => {
    Font.register({
        family: "roc-grotesk",
        fonts: [
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf",
                fontWeight: "light",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf",
                fontWeight: "normal",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf",
                fontWeight: "medium",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf",
                fontWeight: "bold",
            },
        ],
    });

    Font.register({
        family: "Roboto",
        fonts: [
            {
                src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
                fontWeight: 500,
            },
        ],
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: "column",
            backgroundColor: "#FFF",
            padding: 20,
            fontFamily: "Roboto",
        },
        bgWhite: {
            backgroundColor: "#FFF",
        },
        rounded: {
            borderRadius: 4,
        },

        wFull: {
            width: "100%",
        },
        borderDashed: {
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "#ccc",
        },
        flex: {
            display: "flex",
            flexDirection: "row",
        },
        justifyBetween: {
            justifyContent: "space-between",
        },
        itemsCenter: {
            alignItems: "center",
        },
        textGray300: {
            color: "#ccc",
        },
        textXs: {
            fontSize: 10,
        },

        textSm: {
            fontSize: "12px",
        },
        textGray500: {
            color: "#888",
        },
        textGm50: {
            color: "#142837",
        },
        textSlate900: {
            color: "#0F172A",
        },
        textCenter: {
            textAlign: "center",
        },
        borderBGm: {
            borderBottomWidth: 1,
            borderBottomColor: "#142837",
        },
        borderBSlate: {
            borderBottomWidth: 1,
            borderBottomColor: "#F1F5F9",
        },
        spaceY2: {
            marginVertical: 8,
        },
        logo: {
            width: 80,
            height: 80,
        },
        text16: {
            fontSize: "16px",
        },
        section: {
            marginBottom: 10,
        },
        label: {
            fontSize: 12,
            fontWeight: "bold",
        },
        fontMedium: {
            fontWeight: "medium",
        },
        content: {
            fontSize: 12,
        },
        w1_3: {
            width: "33.33%",
        },
        w1_2: {
            width: "50%",
        },
        w2_5: {
            width: "40%",
        },
        w2_3: {
            width: "66.67%",
        },
        mb3: {
            marginBottom: 3,
        },
        mb1: {
            marginBottom: 1,
        },
        mb4: {
            marginBottom: 4,
        },
        mb6: {
            marginBottom: 6,
        },
        mb10: {
            marginBottom: 10,
        },
        mt4: {
            marginTop: 4,
        },
        pb3: {
            paddingBottom: 3,
        },
        pb2: {
            paddingBottom: 2,
        },
        pb4: {
            paddingBottom: 4,
        },
        pt2: {
            paddingTop: 2,
        },
        pt4: {
            paddingTop: 4,
        },
        pb6: {
            paddingBottom: 6,
        },
        pb8: {
            paddingBottom: 8,
        },
        my4: {
            marginBottom: 4,
            marginTop: 4,
        },
        py8: {
            paddingBottom: 8,
            paddingTop: 8,
        },
        py20: {
            paddingBottom: 20,
            paddingTop: 20,
        },
        pb20: {
            paddingBottom: 20,
        },
        pb5: {
            paddingBottom: 5,
        },
        mt20: {
            marginTop: 20,
        },
        my10: {
            marginBottom: 10,
            marginTop: 10,
        },
        mt1: {
            marginTop: 1,
        },
        mt10: {
            marginTop: 10,
        },
        pdfImage: {
            width: 20,
            height: 20,
            marginLeft: 3,
        },
    });

    const headerStyles = StyleSheet.create({
        container: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 30,
            marginTop: 10,
        },
        imageContainer: {
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "#ccc",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 80,
            height: 80,
        },
        labelContainer: {
            backgroundColor: "#FFF",
            padding: 6,
            borderRadius: 4,
            cursor: "pointer",
        },
        label: {
            fontSize: 16,
            color: "#ccc",
        },
    });
    const tableStyles = StyleSheet.create({
        section: {
            marginTop: 20,
            marginBottom: 20,
        },
        headingSection: {
            marginBottom: 2,
            color: "#334155",
            fontWeight: "medium",
        },
        heading: {
            fontSize: 16,
            marginBottom: "16px",
            color: "#1E293B",
            borderBottom: "1px solid #F1F5F9",
        },
        container: {
            width: "100%",
            backgroundColor: "white",
        },
        tableBody: {
            borderLeft: "1 solid #E2E8F0",
            borderRight: "1 solid #E2E8F0",
            borderBottom: "1 solid #E2E8F0",
        },
        header: {
            backgroundColor: "#ffffff",
            flexDirection: "row",
            justifyContent: "space-between",
            borderLeft: "1 solid #E2E8F0",
            borderTop: "1 solid #E2E8F0",
            borderRight: "1 solid #E2E8F0",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            textAlign: "left",
            fontSize: 11,
            color: "#334155",
            fontWeight: "bold",
        },
        row: {
            flexDirection: "row",
            borderTop: "1 solid #E2E8F0",
            alignItems: "flex-start",
            justifyContent: "space-between",
            fontSize: 10,
            paddingTop: 7,
            paddingBottom: 6,
            paddingLeft: 10,
            color: "#3B4C58",
            fontWeight: "medium",
            breakInside: "avoid",
            pageBreakInside: "avoid",
        },
        cellWdith: {
            width: "23%",
            maxWidth: "23%",
            fontSize: 11,
        },
        cellWdith2: {
            width: "10%",
            fontSize: 11,
        },
        cellWdith3: {
            width: "12%",
            fontSize: 11,
        },
        cellWdith5: {
            width: "10%",
            fontSize: 11,
        },
        cellWdith4: {
            width: "4%",
            fontSize: 11,
        },
        cellWdith6: {
            width: "14%",
            fontSize: 11,
        },
        cellFlex: {
            flexDirection: "row",
            gap: 4,
            alignItems: "center",
        },
        profileImg: {
            width: 16,
        },
    });

    const subTotalAmount = Number(totalAmount) || 0;
    const taxTotalAmount = Number(taxAmount) || 0;
    const shippingAndHandlingNumber = Number(customerDetails?.shippingAndHandling) || 0;

    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.bgWhite}>
                    <View style={[styles.wFull]}>
                        <View style={[headerStyles.container]}>
                            <View style={headerStyles.imageContainer}>
                                {imageDetails && <Image src={imageDetails} style={styles.logo} />}
                            </View>
                            <View style={headerStyles.labelContainer}>
                                <Text style={headerStyles.label}>{title} Created</Text>
                            </View>
                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "medium",
                                        marginBottom: 8,
                                    }}
                                >
                                    From:
                                </Text>
                                <View
                                    style={{
                                        fontSize: 12,
                                        fontWeight: "medium",
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            fontWeight: "medium",
                                        }}
                                    >
                                        {ldbName}
                                    </Text>
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {ldbEmail}
                                    </Text>
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {ldbAddress}
                                    </Text>
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {ldbPhone}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ alignItems: "flex-end" }}>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "medium",
                                        marginBottom: 8,
                                    }}
                                >
                                    To:
                                </Text>
                                <View
                                    style={{
                                        fontSize: 12,
                                        fontWeight: "medium",

                                        alignItems: "flex-end",
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            fontWeight: "medium",
                                        }}
                                    >
                                        {vendorName}
                                    </Text>

                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {vendorEmail}
                                    </Text>
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {vendorAddress}
                                    </Text>
                                    <Text
                                        style={{
                                            marginBottom: "3px",
                                            color: "#4B5563",
                                        }}
                                    >
                                        {vendorPhone}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View
                            style={{
                                marginTop: 24,
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                width: "100%",
                                borderBottomWidth: 1,
                                borderTopWidth: 1,
                                paddingTop: 15,
                                paddingBottom: 15,
                                borderColor: "#E2E8F0",
                            }}
                        >
                            {/* <View>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        marginBottom: 8,
                                        color: "#142837",
                                        fontWeight: "medium",
                                    }}
                                >
                                    PO source
                                </Text>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        color: "#64748B",
                                        fontWeight: "medium",
                                    }}
                                >
                                    {customerDetails?.source
                                        ? customerDetails?.source?.charAt(0)?.toUpperCase() +
                                          customerDetails?.source?.slice(1)
                                        : "N/A"}
                                </Text>
                            </View> */}
                            <View>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        marginBottom: 8,
                                        color: "#142837",
                                        fontWeight: "medium",
                                    }}
                                >
                                    Issued Date
                                </Text>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        color: "#64748B",
                                        fontWeight: "medium",
                                    }}
                                >
                                    {customerDetails?.issuedDate
                                        ? moment(customerDetails?.issuedDate).format("MMM DD, YYYY")
                                        : "N/A"}
                                </Text>
                            </View>
                            <View>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        marginBottom: 8,
                                        color: "#142837",
                                        fontWeight: "medium",
                                    }}
                                >
                                    Due Date
                                </Text>
                                <Text
                                    style={{
                                        fontSize: "12px",
                                        color: "#64748B",
                                        fontWeight: "medium",
                                    }}
                                >
                                    {customerDetails?.dueDate
                                        ? moment(customerDetails?.dueDate).format("MMM DD, YYYY")
                                        : "N/A"}
                                </Text>
                            </View>
                        </View>

                        {/* <View
                            style={{
                                marginTop: 10,
                                flexWrap: "wrap",
                                width: "100%",
                                borderBottomWidth: 1,
                                paddingBottom: 15,
                                borderColor: "#E2E8F0",
                            }}
                        >
                            <Text
                                style={[
                                    styles.textSm,
                                    styles.my4,
                                    styles.textGm50,
                                    styles.fontMedium,
                                    styles.pb6,
                                ]}
                            >
                                Shipping information
                            </Text>
                            <View
                                style={{
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <View>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            marginBottom: 8,
                                            fontWeight: "medium",
                                            color: "#64748B",
                                        }}
                                    >
                                        FOB
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            color: "#142837",

                                            fontWeight: "medium",
                                        }}
                                    >
                                        {customerDetails?.fobPoint
                                            ? customerDetails?.fobPoint?.charAt(0)?.toUpperCase() +
                                              customerDetails?.fobPoint?.slice(1)
                                            : "N/A"}
                                    </Text>
                                </View>

                                <View>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            marginBottom: 8,
                                            fontWeight: "medium",
                                            color: "#64748B",
                                        }}
                                    >
                                        Shipping via
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "medium",
                                            color: "#142837",
                                        }}
                                    >
                                        {customerDetails?.shippingVia
                                            ? customerDetails?.shippingVia
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                              customerDetails?.shippingVia?.slice(1)
                                            : "N/A"}
                                    </Text>
                                </View>
                                <View>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            marginBottom: 8,
                                            fontWeight: "medium",
                                            color: "#64748B",
                                        }}
                                    >
                                        Shipping terms
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "medium",
                                            color: "#142837",
                                        }}
                                    >
                                        {customerDetails?.shippingTerms
                                            ? customerDetails?.shippingTerms
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                              customerDetails?.shippingTerms?.slice(1)
                                            : "N/A"}
                                    </Text>
                                </View>
                            </View>
                        </View> */}

                        <View style={tableStyles.section}>
                            <View style={tableStyles.container}>
                                <View style={tableStyles.header}>
                                    <Text style={tableStyles.cellWdith4}>No</Text>
                                    <Text style={tableStyles.cellWdith}>Product name</Text>
                                    <Text style={[tableStyles.cellWdith]}>Description</Text>
                                    <Text style={tableStyles.cellWdith3}>Qty</Text>
                                    {/* <Text style={tableStyles.cellWdith2}>Currency</Text> */}
                                    <Text style={tableStyles.cellWdith3}>Unit cost</Text>
                                    <Text style={tableStyles.cellWdith3}>Sub total</Text>
                                    <Text style={tableStyles.cellWdith6}>Delivery Date</Text>
                                    <Text style={tableStyles.cellWdith5}>Lead time</Text>
                                </View>
                                <View style={tableStyles.tableBody}>
                                    {containers?.map((item, idx) => (
                                        <View style={tableStyles.row} key={idx}>
                                            <Text style={tableStyles.cellWdith4}>{idx + 1}</Text>
                                            <View
                                                style={[
                                                    tableStyles.cellFlex,
                                                    tableStyles.cellWdith,
                                                ]}
                                            >
                                                {item?.productImage &&
                                                !item?.productImage?.includes("svg") ? (
                                                    <Image
                                                        src={item?.productImage}
                                                        style={tableStyles?.profileImg}
                                                    />
                                                ) : (
                                                    <Image
                                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/defaultProductImage.png"
                                                        style={tableStyles?.profileImg}
                                                    />
                                                )}

                                                <View>
                                                    <Text
                                                        style={{
                                                            maxWidth: 82,
                                                        }}
                                                    >
                                                        {item.productName}
                                                    </Text>
                                                    <Text>{item.sku}</Text>
                                                </View>
                                            </View>
                                            <View
                                                style={[tableStyles.cellWdith, { maxWidth: 100 }]}
                                            >
                                                <Text>{item?.description}</Text>
                                            </View>
                                            <Text style={[tableStyles.cellWdith3]}>
                                                {formatMoney().format(item?.quantity)}
                                            </Text>
                                            {/* <Text style={tableStyles.cellWdith2}>
                                                {item?.unitCostCurrency}
                                            </Text> */}
                                            <Text style={[tableStyles.cellWdith3]}>
                                                {getCurrencyFromCurrencyCode(
                                                    item?.unitCostCurrency ||
                                                        item?.unitCost?.currency
                                                )}
                                                {formatMoney().format(
                                                    item?.unitCostAmount || item?.unitCost?.amount
                                                )}
                                            </Text>
                                            <Text style={[tableStyles.cellWdith3]}>
                                                {getCurrencyFromCurrencyCode(
                                                    item?.unitCostCurrency ||
                                                        item?.unitCost?.currency
                                                )}
                                                {formatMoney().format(item?.subtotal?.amount)}
                                            </Text>
                                            <Text style={[tableStyles.cellWdith6]}>
                                                {moment(item.deliveryDate).format("MMM DD, YYYY")}
                                            </Text>
                                            <Text style={[tableStyles.cellWdith5]}>
                                                {item.leadTime}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View style={{ width: "50%", flexDirection: "column" }}>
                                <View style={{ marginBottom: 8 }}>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            color: "#142837",
                                            fontWeight: "medium",
                                        }}
                                    >
                                        Total Quantity: {formatMoney().format(totalQuantity)}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: "50%" }}>
                                <View style={{ flexDirection: "column" }}>
                                    <View style={{ marginBottom: 8 }}>
                                        <View style={{ marginBottom: 8 }}>
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    paddingVertical: 8,
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                    }}
                                                >
                                                    Total
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {getCurrencyFromCurrencyCode(
                                                        customerDetails?.currency
                                                    )}
                                                    {formatMoney().format(totalAmount)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                marginBottom: 4,
                                                borderTopWidth: 1,
                                                borderTopColor: "#E2E8F0",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    paddingVertical: 8,
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                    }}
                                                >
                                                    Tax ({customerDetails?.tax}%)
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {getCurrencyFromCurrencyCode(
                                                        customerDetails?.currency
                                                    )}
                                                    {formatMoney().format(taxAmount || 0)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical: 8,
                                                borderTopWidth: 1,
                                                borderTopColor: "#E2E8F0",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                    }}
                                                >
                                                    Shipping and Handling
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#142837",
                                                        fontWeight: "medium",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {getCurrencyFromCurrencyCode(
                                                        customerDetails?.currency
                                                    )}
                                                    {formatMoney().format(
                                                        Number(
                                                            customerDetails?.shippingAndHandling
                                                        ) || 0
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingVertical: 8,
                                                borderTopWidth: 1,
                                                borderTopColor: "#E2E8F0",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 13,
                                                    fontWeight: "medium",
                                                }}
                                            >
                                                Grand Total Amount
                                            </Text>
                                            <Text
                                                style={{
                                                    fontSize: 13,
                                                    fontWeight: "medium",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {getCurrencyFromCurrencyCode(
                                                    customerDetails?.currency
                                                )}
                                                {formatMoney().format(
                                                    subTotalAmount +
                                                        taxTotalAmount +
                                                        shippingAndHandlingNumber
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={{ marginTop: 5 }}>
                            <View
                                style={{
                                    paddingBottom: 13,
                                    borderBottomWidth: 1,
                                    borderBottomColor: "#CBD5E0",
                                }}
                            >
                                <Text
                                    style={{ fontSize: 12, fontWeight: "medium", color: "#142837" }}
                                >
                                    Incoterm
                                </Text>
                                <Text style={{ fontSize: 12, color: "#6B7280", marginTop: 6 }}>
                                    {customerDetails?.incoterm ? customerDetails?.incoterm : "N/A"}
                                </Text>
                            </View>
                            <View style={{ marginTop: 10, marginBottom: 10 }}>
                                <View style={{ marginBottom: 16 }}>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            fontWeight: "medium",
                                            color: "#142837",
                                        }}
                                    >
                                        Payment terms
                                    </Text>
                                    <Text style={{ fontSize: 12, color: "#6B7280", marginTop: 6 }}>
                                        {customerDetails?.paymentTerms
                                            ? customerDetails?.paymentTerms
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                              customerDetails?.paymentTerms?.slice(1)
                                            : "N/A"}
                                    </Text>
                                </View>
                            </View>
                            {filesAttached?.length > 0 && (
                                <View style={{ marginTop: 10 }}>
                                    <Text
                                        style={{
                                            fontSize: 12,
                                            color: "#142837",
                                            fontWeight: "medium",
                                            marginBottom: 8,
                                        }}
                                    >
                                        File Attachment
                                    </Text>
                                    {filesAttached?.map((doc: any, idx) => (
                                        <View
                                            key={idx}
                                            style={{
                                                flexDirection: "row",
                                                alignItems: "center",
                                                padding: 12,
                                                borderRadius: 6,
                                                borderWidth: 1,
                                                borderColor: "#F1F5F9",
                                            }}
                                        >
                                            <Image
                                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/pdf-document_ahrdqt.jpg"
                                                style={tableStyles?.profileImg}
                                            />
                                            <View style={{ marginLeft: 8 }}>
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#1E293B",
                                                        fontWeight: "medium",
                                                    }}
                                                >
                                                    {doc?.fileName || doc?.filename}
                                                </Text>
                                                {/* <Text
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#4B5563",
                                                        fontWeight: "medium",
                                                    }}
                                                >
                                                    2MB
                                                </Text> */}
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            )}
                        </View>

                        {customerDetails?.termsAndCondition && (
                            <View style={styles.my4}>
                                <Text
                                    style={[
                                        styles.textSm,
                                        styles.flex,
                                        styles.pb6,
                                        styles.my10,
                                        styles.justifyBetween,
                                        styles.fontMedium,
                                        styles.textGm50,
                                    ]}
                                >
                                    Terms and Conditions
                                </Text>
                                <View
                                    style={[styles.textSm, styles.fontMedium, styles.textGray500]}
                                >
                                    {hasHTMLTag(customerDetails?.termsAndCondition)
                                        ? sanitizeHtml(customerDetails?.termsAndCondition, true)
                                              .sanitizedHTML
                                        : customerDetails?.termsAndCondition}
                                </View>
                            </View>
                        )}

                        {customerDetails?.additionalInfo && (
                            <View style={styles.my4}>
                                <Text
                                    style={[
                                        styles.textSm,
                                        styles.flex,
                                        styles.pb6,
                                        styles.my10,
                                        styles.justifyBetween,
                                        styles.fontMedium,
                                        styles.textGm50,
                                    ]}
                                >
                                    Additional Information
                                </Text>
                                <View
                                    style={[styles.textSm, styles.fontMedium, styles.textGray500]}
                                >
                                    <Text style={{ width: "80%" }}>
                                        {customerDetails?.additionalInfo}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PurchaseOrdersPDF;
