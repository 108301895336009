import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { useNav } from "components/common/Navbar/NavContext";
import IntegrationCard from "components/views/Ldb/Dashboard/Integration/Card";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import useGetAssetAccounts from "hooks/integrationHooks/useGetAssetAccounts";
import useGetIncomeAccounts from "hooks/integrationHooks/useGetIncomeAccounts";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { integrationActions, vendorActions } from "redux/Ldb/actions";
import TrackstarIntegration from "../../../components/views/Synth2.0/Integration/TrackstarIntegration";
import { trackstarList } from "variables/trackstarList";
import { debounce } from "lodash";
import { AccountPermissions } from "types/permissions";

const Integration = () => {
    const [tabId, setTabId] = useState(0);
    const [showIntegrationConnect, setShowIntegrationConnect] = useState<boolean>(false);
    const [shopifyConnect, setShopifyConnect] = useState<boolean>(false);
    const [googleConnect, setGoogleConnect] = useState<boolean>(false);
    const [mircosoftConnect, setMicrosoftConnect] = useState<boolean>(false);
    const [amazonConnect, setAmazonConnect] = useState<boolean>(false);
    const [qbDesktopConnect, setQbDesktopConnect] = useState<boolean>(false);
    const [spsCommerceConnect, setSpsCommerceConnect] = useState<boolean>(false);
    const [klaviyoConnect, setKlaviyoConnect] = useState<boolean>(false);
    const [googleAdsConnect, setGoogleAdsConnect] = useState<boolean>(false);
    const [tiktokConnect, setTiktokConnect] = useState<boolean>(false);
    const [showAccounts, setShowAccounts] = useState<boolean>(false);
    const [quickbooksConnect, setQuickbooksConnect] = useState<boolean>(false);
    const [showAddStoreName, setShowAddStoreName] = useState<boolean>(false);
    const [connect, setConnect] = useState<boolean>(false);
    const [syncedAccount, setSyncedAccount] = useState<boolean>(false);
    const [storeName, setStoreName] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [expenseAccounts, setExpenseAccounts] = useState([]);
    const [payableAccounts, setPayableAccounts] = useState([]);
    const [formattedArray, setFormattedArray] = useState([]);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [selectedLiability, setSelectedLiability] = useState(null);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    // const [trackstars, setTrackstars] = useState([]);
    const [incomeAccounts, setIncomeAccounts] = useState([]);
    const [assetAccounts, setAssetAccounts] = useState([]);
    const [fixedAssetAccount, setFixedAssetAccounts] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const { setNavName, setOnClick, setShowNavIcon } = useNav();
    const tabNames = [
        { id: 0, name: "All integrations" },
        { id: 1, name: "Sales and Marketing" },
        { id: 2, name: "3PL and WMS" },

        // { id: 3, name: "Finance" },
        // { id: 4, name: "Procurement" },
        // { id: 5, name: "Payments" },
        // { id: 6, name: "Sales and CRM" },
        // { id: 7, name: "Social media" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const code = param.get("code");
    const state = param.get("state");
    const realmId = param.get("realmId");
    const hmac = param.get("hmac");
    const shop = param.get("shop");
    const timestamp = param.get("timestamp");
    const sellingPartnerId = param.get("selling_partner_id");
    const spapiOauthCode = param.get("spapi_oauth_code");
    const qbDesktopConnected = param.get("qbDesktopConnected");
    const socialKey = param.get("social-key");
    const auth_code = param.get("auth_code");

    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/integrations" });
    const {
        fetchedAllChannelsSuccess,
        fetchingAllChannels,
        authenticateGmailSettingsSuccess,
        authenticatingGmailSettings,
        authenticateMicrosoftSettingsSuccess,
        authenticatingMicrosoftSettings,
        manageConnectionSuccess,
        manageConnection,
        connectGoogleWorkspaceSettingsSuccess,
        connectMicrosoftWorkspaceSettingsSuccess,
        connectingGoogleWorkspaceSettings,
        connectingMicrosoftWorkspaceSettings,
    } = useAppSelector((state) => state.vendor);

    const {
        fetchingQuickbooksOauthUrl,
        fetchedQuickbooksOauthUrlSuccess,
        fetchedQuickbooksExchangeCodeSuccess,
        fetchingQuickbooksExchangeCode,
        setQuickbooksAccount,
        setQuickbooksAccountSuccess,
        fetchingQuickbooksExpenseAccounts,
        fetchingQuickbooksApAccounts,
        fetchedQuickbooksApAccountsSuccess,
        fetchedQuickbooksExpenseAccountsSuccess,
        fetchingShopifyOauthUrl,
        fetchedShopifyOauthUrlSuccess,
        verifyShopifyAccount,
        verifyShopifyAccountSuccess,
        fetchedQuickbooksIncomeAccountsSuccess,
        fetchedQuickbooksAssetAccountsSuccess,
        fetchingAmazonOauthUrl,
        fetchedAmazonOauthUrlSuccess,
        verifyAmazonAccount,
        verifyAmazonAccountSuccess,
        fetchingSpsCommerceOauthUrl,
        fetchedSpsCommerceOauthUrlSuccess,
        completeSpsCommerceConnectionSuccess,
        fetchingQbDesktopOauthUrl,
        fetchedQbDesktopOauthUrlSuccess,
        fetchingQbDesktopApAccounts,
        fetchedQbDesktopApAccountsSuccess,
        fetchingQbDesktopExpenseAccounts,
        fetchedQbDesktopExpenseAccountsSuccess,
        fetchingQbDesktopCurrentAssetAccounts,
        fetchedQbDesktopCurrentAssetAccountsSuccess,
        fetchingQbDesktopFixedAssetAccounts,
        fetchedQbDesktopFixedAssetAccountsSuccess,
        fetchingQbDesktopIncomeAccounts,
        fetchedQbDesktopIncomeAccountsSuccess,
        completingQbDesktopConnection,
        completeQbDesktopConnectionSuccess,
        settingUpQbDesktop,
        setupQbDesktopSuccess,
        settingUpKlaviyoConnection,
        setupKlaviyoConnectionSuccess,
        completingKlaviyoConnection,
        completeKlaviyoConnectionSuccess,
        settingUpGoogleAdsConnection,
        setupGoogleAdsConnectionSuccess,
        completingGoogleAdsConnection,
        completeGoogleAdsConnectionSuccess,
        settingUpTiktokConnection,
        setupTiktokConnectionSuccess,
        completingTiktokConnection,
        completeTiktokConnectionSuccess,
    } = useAppSelector((state) => state.integration);

    // const { data: incomeAccounts } = useGetIncomeAccounts();
    // const { data: assetAccounts } = useGetAssetAccounts();

    const quickBooksConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "quickbooks"
    );
    const shopifyConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "shopify"
    );
    const googleConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "gmail"
    );
    const microsoftConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "microsoft"
    );
    const amazonConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "amazon"
    );
    const quickbooksDesktop = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "quickbooks-desktop"
    );
    const spsCommerce = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "sps-commerce"
    );
    const activeTrackstars = fetchedAllChannelsSuccess?.channel?.filter(
        (item) => item?.channel?.toLowerCase() === "trackstar" && item?.active
    );

    const connectedButInactiveTrackstars = fetchedAllChannelsSuccess?.channel?.filter(
        (item) => item?.channel?.toLowerCase() === "trackstar" && !item?.active
    );
    const klaviyo = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "klaviyo"
    );
    const googleAds = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "google_ads"
    );
    const tiktok = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "tiktok_ads"
    );

    useEffect(() => {
        setNavName("Integrations");
        setOnClick(() => () => {
            goBack();
        });
        setShowNavIcon(true);
    }, [setNavName, setOnClick, setShowNavIcon]);

    useEffect(() => {
        const requiredKeys = [
            "Payable Account(s)",
            "Expense Account(s)",
            "Income Account(s)",
            "Asset Account(s)",
        ];
        const requiredQbDesktopKeys = [
            "Payable Account(s)",
            "Expense Account(s)",
            "Income Account(s)",
            "Current Asset Account(s)",
            // "Fixed Asset Account(s)",
        ];

        const hasAll = (qbDesktopConnected ? requiredQbDesktopKeys : requiredKeys).every((key) =>
            selectedAccounts.some((item) => Object.keys(item)[0] === key)
        );
        setShowButton(hasAll);
    }, [selectedAccounts, qbDesktopConnected]);

    const synAccount = () => {
        const qbAPAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Payable Account(s)"
        );
        const qbExpenseAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Expense Account(s)"
        );
        const qbIncomeAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Income Account(s)"
        );
        const qbAssetAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Asset Account(s)"
        );
        const qbCurrentAssetAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Current Asset Account(s)"
        );
        // const qbFixedAssetAccountRef = selectedAccounts?.find(
        //     (item) => Object.keys(item)[0] === "Fixed Asset Account(s)"
        // );

        const body = {
            qbAPAccountRef: {
                id: qbAPAccountRef?.["Payable Account(s)"]?.Id,
                name: qbAPAccountRef?.["Payable Account(s)"]?.Name,
            },
            qbExpenseAccountRef: {
                id: qbExpenseAccountRef?.["Expense Account(s)"]?.Id,
                name: qbExpenseAccountRef?.["Expense Account(s)"]?.Name,
            },
            qbIncomeAccountRef: {
                id: qbIncomeAccountRef?.["Income Account(s)"]?.Id,
                name: qbIncomeAccountRef?.["Income Account(s)"]?.Name,
            },
            qbAssetAccountRef: {
                id: qbAssetAccountRef?.["Asset Account(s)"]?.Id,
                name: qbAssetAccountRef?.["Asset Account(s)"]?.Name,
            },
        };

        if (completeQbDesktopConnectionSuccess) {
            const qbDesktopBody = {
                qbDesktopIncomeAccountRef: {
                    id: qbIncomeAccountRef?.["Income Account(s)"]?.Id,
                    name: qbIncomeAccountRef?.["Income Account(s)"]?.Name,
                },
                qbDesktopCOGAccountRef: {
                    id: qbExpenseAccountRef?.["Expense Account(s)"]?.Id,
                    name: qbExpenseAccountRef?.["Expense Account(s)"]?.Name,
                },
                qbDesktopCurrentAssetAccountRef: {
                    id: qbCurrentAssetAccountRef?.["Current Asset Account(s)"]?.Id,
                    name: qbCurrentAssetAccountRef?.["Current Asset Account(s)"]?.Name,
                },
                // qbDesktopFixedAssetAccountRef: {
                //     id: qbFixedAssetAccountRef?.["Fixed Asset Account(s)"]?.Id,
                //     name: qbFixedAssetAccountRef?.["Fixed Asset Account(s)"]?.Name,
                // },
                qbDesktopAPAccountRef: {
                    id: qbAPAccountRef?.["Payable Account(s)"]?.Id,
                    name: qbAPAccountRef?.["Payable Account(s)"]?.Name,
                },
            };

            return dispatch(integrationActions.setUpQbDesktop(qbDesktopBody));
        }

        dispatch(integrationActions.setUpQuickbooks(body));
    };

    useEffect(() => {
        if (code && state && realmId) {
            dispatch(integrationActions.getQuickbooksExchangeCode({ code, state, realmId }));
        }
    }, [code, state, realmId, dispatch]);

    useEffect(() => {
        if (qbDesktopConnected && qbDesktopConnected === "true") {
            setShowAccounts(true);
            dispatch(integrationActions.completeQbDesktopConnection());
        }
    }, [qbDesktopConnected, dispatch]);

    useEffect(() => {
        if (code && state && hmac && shop && timestamp) {
            dispatch(
                integrationActions.verifyShopifyAccount({
                    code,
                    state,
                    hmac,
                    shop: shop.replace(".myshopify.com", ""),
                    timestamp,
                })
            );
        }
    }, [code, state, hmac, shop, timestamp, dispatch]);

    useEffect(() => {
        if (state && spapiOauthCode && sellingPartnerId) {
            dispatch(
                integrationActions.getAmazonData({
                    selling_partner_id: sellingPartnerId,
                    state,
                    spapi_oauth_code: spapiOauthCode,
                })
            );
        }
    }, [state, spapiOauthCode, sellingPartnerId, dispatch]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (code && state && source && source === "klaviyo") {
            dispatch(
                integrationActions.completeKlaviyoConnection({
                    code,
                    state,
                    redirectUrl: `${
                        process.env.REACT_APP_STAGE === "production"
                            ? "https://heysynth.com"
                            : "https://staging.heysynth.com"
                    }/dashboard/integrations`,
                })
            );
        }
    }, [code, state, dispatch, klaviyoConnect]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (code && source && source === "googleAds") {
            dispatch(
                integrationActions.completeGoogleAdsConnection({
                    code,
                })
            );
        }
    }, [code, state, dispatch, googleAdsConnect]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (auth_code && source && source === "tiktok") {
            dispatch(
                integrationActions.completeTiktokConnection({
                    auth_code,
                })
            );
        }
    }, [auth_code, dispatch, tiktokConnect]);

    useEffect(() => {
        if (Boolean(setupGoogleAdsConnectionSuccess)) {
            saveToStorage("connectionSource", "googleAds", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setGoogleAdsConnect(false);

            dispatch(integrationActions.resetSetupGoogleAdsConnectionSuccess());
            window.open(setupGoogleAdsConnectionSuccess?.result, "_target");
        }
    }, [dispatch, setupGoogleAdsConnectionSuccess]);

    useEffect(() => {
        if (Boolean(setupKlaviyoConnectionSuccess)) {
            saveToStorage("connectionSource", "klaviyo", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setKlaviyoConnect(false);
            dispatch(integrationActions.resetSetupKlaviyoConnectionSuccess());
            window.open(setupKlaviyoConnectionSuccess?.loginUrl, "_self");
        }
    }, [dispatch, setupKlaviyoConnectionSuccess]);

    useEffect(() => {
        if (Boolean(setupTiktokConnectionSuccess)) {
            saveToStorage("connectionSource", "tiktok", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setTiktokConnect(false);
            dispatch(integrationActions.resetSetupTiktokConnectionSuccess());
            window.open(setupTiktokConnectionSuccess?.redirectUrl, "_self");
        }
    }, [dispatch, setupTiktokConnectionSuccess]);

    useEffect(() => {
        if (Boolean(connectGoogleWorkspaceSettingsSuccess)) {
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            setSyncedAccount(true);
            dispatch(vendorActions.resetConnectGoogleWorkspaceSettingsSuccess());
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [connectGoogleWorkspaceSettingsSuccess]);

    useEffect(() => {
        if (
            connectingGoogleWorkspaceSettings ||
            connectingMicrosoftWorkspaceSettings ||
            setQuickbooksAccount ||
            verifyShopifyAccount ||
            verifyAmazonAccount
        ) {
            setShowIntegrationConnect(false);
        }
    }, [
        connectingGoogleWorkspaceSettings,
        connectingMicrosoftWorkspaceSettings,
        setQuickbooksAccount,
        verifyShopifyAccount,
        verifyAmazonAccount,
    ]);

    useEffect(() => {
        if (Boolean(connectMicrosoftWorkspaceSettingsSuccess)) {
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            setSyncedAccount(true);
            dispatch(vendorActions.resetConnectMicrosoftWorkspaceSettingsSuccess());
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [connectMicrosoftWorkspaceSettingsSuccess]);

    useEffect(() => {
        if (Boolean(completeSpsCommerceConnectionSuccess)) {
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            setSyncedAccount(true);
            dispatch(integrationActions.resetCompleteSpsCommerceConnectionSuccess());
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [completeSpsCommerceConnectionSuccess]);

    useEffect(() => {
        if (connect && quickbooksConnect) {
            handleQuickbooksActivate();
        }
    }, [dispatch, connect, quickbooksConnect]);
    useEffect(() => {
        if (connect && shopifyConnect) {
            dispatch(integrationActions.getShopifyOauthUrl(storeName));
        }
    }, [dispatch, connect, shopifyConnect, storeName]);

    useEffect(() => {
        if (connect && amazonConnect) {
            dispatch(integrationActions.getAmazonOauthUrl());
        }
    }, [dispatch, connect, amazonConnect]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");
        if (code && source && source === "gmail" && !shopifyConnect && !timestamp && !state) {
            dispatch(vendorActions.connectGoogleWorkspaceSettings({ code }));
        }
        if (code && source && source === "microsoft" && !shopifyConnect && state) {
            dispatch(vendorActions.connectMicrosoftWorkspaceSettings({ code, state }));
        }
        if (code && source && source === "sps-commerce" && !shopifyConnect && state) {
            dispatch(integrationActions.completeSpsCommerceConnection({ code, state }));
        }
    }, [code, shopifyConnect, timestamp, state, dispatch]);

    const handleGmailActivate = () => {
        if (!googleConnected?.active) {
            dispatch(vendorActions.authenticateGmailSettings());
        } else if (googleConnected && !googleConnected?.active) {
            const body = {
                activate: true,
                socialId: googleConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };
    const handleMicrosoftActivate = () => {
        if (!microsoftConnected?.active) {
            dispatch(vendorActions.authenticateMicrosoftAcctSettings());
        } else if (microsoftConnected && !microsoftConnected?.active) {
            const body = {
                activate: true,
                socialId: microsoftConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleQuickbooksActivate = () => {
        if (!quickBooksConnected?.active) {
            dispatch(integrationActions.getQuickbooksOauthUrl());
        } else if (quickBooksConnected && !quickBooksConnected?.active) {
            const body = {
                activate: true,
                socialId: quickBooksConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleSpsCommerceActivate = () => {
        if (!spsCommerce?.active) {
            dispatch(integrationActions.getSpsCommerceOauthUrl());
        } else if (spsCommerce && !spsCommerce?.active) {
            const body = {
                activate: true,
                socialId: spsCommerce?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleQbDesktopActivate = () => {
        if (!quickbooksDesktop?.active) {
            dispatch(integrationActions.getQbDesktopOauthUrl());
        } else if (quickbooksDesktop && !quickbooksDesktop?.active) {
            const body = {
                activate: true,
                socialId: quickbooksDesktop?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleKlaviyoActivate = () => {
        dispatch(
            integrationActions.setupKlaviyoConnection(
                `${
                    process.env.REACT_APP_STAGE === "production"
                        ? "https://heysynth.com"
                        : "https://staging.heysynth.com"
                }/dashboard/integrations`
            )
        );
    };

    const handleGoogleAdsActivate = () => {
        dispatch(integrationActions.setupGoogleAdsConnection());
    };

    const handleTiktokActivate = () => {
        dispatch(integrationActions.setupTiktokConnection());
    };

    useEffect(() => {
        if (connect && tiktokConnect) {
            handleTiktokActivate();
        }
    }, [connect, tiktokConnect]);

    useEffect(() => {
        if (connect && googleAdsConnect) {
            handleGoogleAdsActivate();
        }
    }, [connect, googleAdsConnect]);

    useEffect(() => {
        if (connect && klaviyoConnect) {
            handleKlaviyoActivate();
        }
    }, [connect, klaviyoConnect]);

    useEffect(() => {
        if (socialKey && socialKey === "kvo") {
            handleKlaviyoActivate();
        }

        if (socialKey && socialKey === "goAds") {
            handleGoogleAdsActivate();
        }

        if (socialKey && socialKey === "tko") {
            handleTiktokActivate();
        }
    }, [socialKey]);

    useEffect(() => {
        if (connect && googleConnect) {
            handleGmailActivate();
        }
    }, [connect, googleConnect]);

    useEffect(() => {
        if (connect && mircosoftConnect) {
            handleMicrosoftActivate();
        }
    }, [dispatch, connect, mircosoftConnect]);

    useEffect(() => {
        if (connect && spsCommerceConnect) {
            handleSpsCommerceActivate();
        }
    }, [dispatch, connect, spsCommerceConnect]);

    useEffect(() => {
        if (connect && qbDesktopConnect) {
            handleQbDesktopActivate();
        }
    }, [dispatch, connect, qbDesktopConnect]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess) && mircosoftConnect) {
            const connectionRedirect = getFromStorage("connectedRedirect");

            dispatch(vendorActions.resetManageConnectionSuccess());
            setShowIntegrationConnect(false);
            if (connectionRedirect) {
                navigate(connectionRedirect);
            }
            dispatch(vendorActions.getAllChannels(debouncedSearch));
        }
    }, [dispatch, manageConnectionSuccess, mircosoftConnect]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess) && googleConnect) {
            const connectionRedirect = getFromStorage("connectedRedirect");

            dispatch(vendorActions.resetManageConnectionSuccess());
            setShowIntegrationConnect(false);
            if (connectionRedirect) {
                navigate(connectionRedirect);
            }
            dispatch(vendorActions.getAllChannels(debouncedSearch));
        }
    }, [dispatch, manageConnectionSuccess, googleConnect]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (Boolean(completeKlaviyoConnectionSuccess) && source && source === "klaviyo") {
            const connectionRedirect = getFromStorage("connectedRedirect");

            dispatch(integrationActions.resetCompleteKlaviyoConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
            setShowIntegrationConnect(false);
            setKlaviyoConnect(false);
            setSyncedAccount(true);
            // navigate("/dashboard/integrations", { replace: true });
            removeFromStorage("connectionSource");
            if (connectionRedirect) {
                navigate(connectionRedirect);
            }
        }
    }, [dispatch, completeKlaviyoConnectionSuccess]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (Boolean(completeGoogleAdsConnectionSuccess) && source && source === "googleAds") {
            const connectionRedirect = getFromStorage("connectedRedirect");

            dispatch(integrationActions.resetCompleteGoogleAdsConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
            setShowIntegrationConnect(false);
            setGoogleAdsConnect(false);
            setSyncedAccount(true);
            navigate("/dashboard/integrations", { replace: true });
            removeFromStorage("connectionSource");
            if (connectionRedirect) {
                navigate(connectionRedirect);
            }
        }
    }, [dispatch, completeGoogleAdsConnectionSuccess]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");

        if (Boolean(completeTiktokConnectionSuccess) && source && source === "tiktok") {
            const connectionRedirect = getFromStorage("connectedRedirect");

            dispatch(integrationActions.resetCompleteTiktokConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
            setShowIntegrationConnect(false);
            setTiktokConnect(false);
            setSyncedAccount(true);
            navigate("/dashboard/integrations", { replace: true });
            removeFromStorage("connectionSource");
            if (connectionRedirect) {
                navigate(connectionRedirect);
            }
        }
    }, [dispatch, completeTiktokConnectionSuccess]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess) && spsCommerceConnect) {
            dispatch(vendorActions.resetManageConnectionSuccess());
            setShowIntegrationConnect(false);
            dispatch(vendorActions.getAllChannels(debouncedSearch));
        }
    }, [dispatch, manageConnectionSuccess, spsCommerceConnect]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess) && quickbooksConnect) {
            window.open(manageConnectionSuccess?.socialAccount?.url, "_blank");
            setShowIntegrationConnect(false);
            dispatch(vendorActions.resetManageConnectionSuccess());
            dispatch(vendorActions.getAllChannels(debouncedSearch));
        }
    }, [dispatch, manageConnectionSuccess, quickbooksConnect]);

    useEffect(() => {
        if (
            (quickBooksConnected?.qbAPAccountRef?.name === undefined ||
                quickBooksConnected?.qbExpenseAccountRef?.name === undefined) &&
            quickBooksConnected?.active
        ) {
            setShowAccounts(true);
        }
    }, [quickBooksConnected]);

    useEffect(() => {
        if (
            (quickbooksDesktop?.qbDesktopAPAccountRef?.name === undefined ||
                quickbooksDesktop?.qbDesktopIncomeAccountRef?.name === undefined) &&
            quickbooksDesktop?.active
        ) {
            setShowAccounts(true);
        }
    }, [quickbooksDesktop]);

    useEffect(() => {
        const fetchData = async () => {
            if (Boolean(fetchedQuickbooksExchangeCodeSuccess)) {
                await dispatch(vendorActions.getAllChannels(debouncedSearch));
                navigate("/dashboard/integrations", {
                    replace: true,
                    state: { internalNavigation: true },
                });

                dispatch(integrationActions.resetGetQuickbooksOauthUrlSuccess());
            }
        };

        fetchData();
    }, [dispatch, fetchedQuickbooksExchangeCodeSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels(debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (quickBooksConnected?.active) {
            dispatch(integrationActions.getQuickbooksExpenseList());
            dispatch(integrationActions.getQuickbooksApList());
            dispatch(integrationActions.getQuickbooksAssetAccounts());
            dispatch(integrationActions.getQuickbooksIncomeAccounts());
        }
    }, [dispatch, quickBooksConnected?.active]);

    useEffect(() => {
        if (
            (completeQbDesktopConnectionSuccess &&
                completeQbDesktopConnectionSuccess?.result?.active) ||
            ((quickbooksDesktop?.qbDesktopAPAccountRef?.name === undefined ||
                quickbooksDesktop?.qbDesktopExpenseAccountRef?.name === undefined) &&
                quickbooksDesktop?.active)
        ) {
            // quickBooksConnected?.active

            dispatch(integrationActions.getQbDesktopApAccounts());
            dispatch(integrationActions.getQbDesktopCurrentAssetAccounts());
            dispatch(integrationActions.getQbDesktopFixedAssetAccounts());
            dispatch(integrationActions.getQbDesktopExpenseAccounts());
            dispatch(integrationActions.getQbDesktopIncomeAccounts());
        }
    }, [dispatch, completeQbDesktopConnectionSuccess, quickbooksDesktop]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksExpenseAccountsSuccess)) {
            setExpenseAccounts(fetchedQuickbooksExpenseAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksExpenseAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksIncomeAccountsSuccess)) {
            setIncomeAccounts(fetchedQuickbooksIncomeAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksIncomeAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksAssetAccountsSuccess)) {
            setAssetAccounts(fetchedQuickbooksAssetAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksAssetAccountsSuccess]);

    // =====

    useEffect(() => {
        if (Boolean(fetchedQbDesktopExpenseAccountsSuccess)) {
            setExpenseAccounts(fetchedQbDesktopExpenseAccountsSuccess?.accounts);
        }
    }, [fetchedQbDesktopExpenseAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQbDesktopIncomeAccountsSuccess)) {
            setIncomeAccounts(fetchedQbDesktopIncomeAccountsSuccess?.accounts);
        }
    }, [fetchedQbDesktopIncomeAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQbDesktopCurrentAssetAccountsSuccess)) {
            setAssetAccounts(fetchedQbDesktopCurrentAssetAccountsSuccess?.accounts);
        }
    }, [fetchedQbDesktopCurrentAssetAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQbDesktopFixedAssetAccountsSuccess)) {
            setFixedAssetAccounts(fetchedQbDesktopFixedAssetAccountsSuccess?.accounts);
        }
    }, [fetchedQbDesktopFixedAssetAccountsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQbDesktopApAccountsSuccess)) {
            setPayableAccounts(fetchedQbDesktopApAccountsSuccess?.accounts);
        }
    }, [fetchedQbDesktopApAccountsSuccess]);

    useEffect(() => {
        if (syncedAccount) {
            const timeoutId = setTimeout(() => {
                setSyncedAccount(false);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [syncedAccount]);

    useEffect(() => {
        if (Boolean(verifyShopifyAccountSuccess)) {
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            dispatch(integrationActions.resetVerifyShopifySuccess());
            setSyncedAccount(true);
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [verifyShopifyAccountSuccess, navigate]);

    useEffect(() => {
        if (Boolean(verifyAmazonAccountSuccess)) {
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            dispatch(integrationActions.resetAmazonDataSuccess());
            setSyncedAccount(true);
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [verifyAmazonAccountSuccess, navigate]);
    useEffect(() => {
        if (Boolean(setQuickbooksAccountSuccess)) {
            setSyncedAccount(true);
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            dispatch(integrationActions.resetSetUpQuickbooksSuccess());
            setShowAccounts(false);
        }
    }, [dispatch, setQuickbooksAccountSuccess]);

    useEffect(() => {
        if (Boolean(setupQbDesktopSuccess)) {
            dispatch(integrationActions.resetCompleteQbDesktopConnectionSuccess());
            setSyncedAccount(true);
            dispatch(vendorActions.getAllChannels(debouncedSearch));
            setShowAccounts(false);
            setSelectedAccounts([]);
            dispatch(integrationActions.resetSetupQbDesktopSuccess());
            navigate("/dashboard/integrations", {
                replace: true,
                state: { internalNavigation: true },
            });
        }
    }, [dispatch, setupQbDesktopSuccess]);

    useEffect(() => {
        const mergedArray = [
            { "Expense Account(s)": expenseAccounts || [] },
            { "Payable Account(s)": payableAccounts || [] },
            { "Income Account(s)": incomeAccounts || [] },
            { "Asset Account(s)": assetAccounts || [] },
        ];

        const mergedQbDesktopArray = [
            { "Expense Account(s)": expenseAccounts || [] },
            { "Payable Account(s)": payableAccounts || [] },
            { "Income Account(s)": incomeAccounts || [] },
            { "Current Asset Account(s)": assetAccounts || [] },
            // { "Fixed Asset Account(s)": fixedAssetAccount || [] },
        ];

        setFormattedArray(qbDesktopConnected ? mergedQbDesktopArray : mergedArray);
    }, [
        expenseAccounts,
        payableAccounts,
        incomeAccounts,
        assetAccounts,
        fixedAssetAccount,
        qbDesktopConnected,
    ]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksApAccountsSuccess)) {
            setPayableAccounts(fetchedQuickbooksApAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksApAccountsSuccess]);

    useEffect(() => {
        if (Boolean(authenticateGmailSettingsSuccess)) {
            saveToStorage("connectionSource", "gmail", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setGoogleConnect(false);
            window.open(authenticateGmailSettingsSuccess?.url, "_target");
            dispatch(vendorActions.resetAuthenticateGmailSettingsSuccess());
        }
    }, [dispatch, authenticateGmailSettingsSuccess]);

    useEffect(() => {
        if (Boolean(authenticateMicrosoftSettingsSuccess)) {
            saveToStorage("connectionSource", "microsoft", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setMicrosoftConnect(false);
            window.open(authenticateMicrosoftSettingsSuccess?.url, "_target");
            dispatch(vendorActions.resetAuthenticateMicrosoftSettingsSuccess());
        }
    }, [dispatch, authenticateMicrosoftSettingsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksOauthUrlSuccess)) {
            setShowIntegrationConnect(false);
            setConnect(false);
            setQuickbooksConnect(false);
            window.open(fetchedQuickbooksOauthUrlSuccess?.url, "_target");
            dispatch(integrationActions.resetGetQuickbooksOauthUrlSuccess());
        }
    }, [dispatch, fetchedQuickbooksOauthUrlSuccess]);

    useEffect(() => {
        if (Boolean(fetchedShopifyOauthUrlSuccess)) {
            setShowIntegrationConnect(false);
            setConnect(false);
            setShopifyConnect(false);
            setShowAddStoreName(false);
            setStoreName("");
            // window.open(fetchedShopifyOauthUrlSuccess?.loginUrl, "_target");
            window.open("https://apps.shopify.com/synth", "_target");
            dispatch(integrationActions.resetGetShopifyOauthUrlSuccess());
        }
    }, [dispatch, fetchedShopifyOauthUrlSuccess]);

    useEffect(() => {
        if (Boolean(fetchedAmazonOauthUrlSuccess)) {
            setShowIntegrationConnect(false);
            setConnect(false);
            setAmazonConnect(false);
            window.open(fetchedAmazonOauthUrlSuccess?.url, "_target");
            dispatch(integrationActions.resetAmazonAuthSuccess());
        }
    }, [dispatch, fetchedAmazonOauthUrlSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSpsCommerceOauthUrlSuccess)) {
            saveToStorage("connectionSource", "sps-commerce", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setSpsCommerceConnect(false);
            window.open(fetchedSpsCommerceOauthUrlSuccess?.authLink?.authUrl, "_target");
            dispatch(integrationActions.resetSpsCommerceOauthUrlSuccess());
        }
    }, [dispatch, fetchedSpsCommerceOauthUrlSuccess]);

    useEffect(() => {
        if (Boolean(fetchedQbDesktopOauthUrlSuccess)) {
            saveToStorage("connectionSource", "qb-desktop", true);
            setShowIntegrationConnect(false);
            setConnect(false);
            setQbDesktopConnect(false);
            window.open(fetchedQbDesktopOauthUrlSuccess?.result?.authUrl, "_target");
            dispatch(integrationActions.resetQbDesktopOauthUrlSuccess());
        }
    }, [dispatch, fetchedQbDesktopOauthUrlSuccess]);

    const handleDebouncedSearch = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 800);

    const displayActiveTab = () => {
        switch (tabId) {
            case 0:
                return fetchingAllChannels ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Active integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                {quickBooksConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="Quickooks"
                                        title="Quickbooks"
                                        content="Connecting your Quickbooks account allows Synth gain and analyze information about your sales, inventory, and purchase order which would be synced every hour."
                                        connected={true}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/quickbooks/${quickBooksConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {shopifyConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                        imageName="Shopify"
                                        title="Shopify"
                                        content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/shopify/${shopifyConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {googleConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                        imageName="Google "
                                        title="Google Suite"
                                        content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        width={35}
                                        height={26}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/google/${googleConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {microsoftConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                        imageName="Microsoft "
                                        title="Microsoft"
                                        content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/microsoft/${microsoftConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {amazonConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                        imageName="Amazon "
                                        title="Amazon"
                                        content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/amazon/${amazonConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {quickbooksDesktop?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="quickbooks desktop"
                                        title="QB Desktop"
                                        content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/quickbooks-desktop/${quickbooksDesktop?._id}`
                                            )
                                        }
                                    />
                                )}
                                {spsCommerce?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                        imageName="sps commerce"
                                        title="SPS Commerce"
                                        content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/sps-commerce/${spsCommerce?._id}`
                                            )
                                        }
                                    />
                                )}
                                {klaviyo?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                        imageName="klaviyo"
                                        title="Klaviyo"
                                        content="Connecting your Klaviyo account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/integrations/klaviyo/${klaviyo?._id}`
                                            );
                                        }}
                                    />
                                )}
                                {googleAds?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg"
                                        imageName="goole-ads"
                                        title="Google Ads"
                                        content="Connecting your Google ads account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/integrations/google-ads/${googleAds?._id}`
                                            );
                                        }}
                                    />
                                )}
                                <TrackstarIntegration
                                    activeTrackstars={activeTrackstars}
                                    active={true}
                                    search={debouncedSearch}
                                />
                            </div>
                        </div>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Inactive integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                {("quickbooks"?.includes(debouncedSearch.toLowerCase()) &&
                                    !quickBooksConnected?.active) ||
                                (!debouncedSearch && !quickBooksConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="Quickooks"
                                        title="Quickbooks"
                                        content="Connecting your Quickbooks account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        onClick={() => {
                                            setQuickbooksConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("shopify"?.includes(debouncedSearch.toLowerCase()) &&
                                    !shopifyConnected?.active) ||
                                (!debouncedSearch && !shopifyConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                        imageName="Shopify"
                                        title="Shopify"
                                        content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        onClick={() => {
                                            // setShopifyConnect(true);
                                            // setShowIntegrationConnect(true);
                                            // ===
                                            setShowIntegrationConnect(false);
                                            setConnect(false);
                                            setShopifyConnect(false);
                                            setShowAddStoreName(false);
                                            setStoreName("");
                                            // window.open(fetchedShopifyOauthUrlSuccess?.loginUrl, "_target");
                                            window.open(
                                                "https://apps.shopify.com/synth",
                                                "_target"
                                            );
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("google suite"?.includes(debouncedSearch.toLowerCase()) &&
                                    !googleConnected?.active) ||
                                (!debouncedSearch && !googleConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                        imageName="Google "
                                        title="Google Suite"
                                        content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        width={35}
                                        height={26}
                                        onClick={() => {
                                            setGoogleConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("microsoft"?.includes(debouncedSearch.toLowerCase()) &&
                                    !microsoftConnected?.active) ||
                                (!debouncedSearch && !microsoftConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                        imageName="Microsoft "
                                        title="Microsoft"
                                        content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setMicrosoftConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("amazon"?.includes(debouncedSearch.toLowerCase()) &&
                                    !amazonConnected?.active) ||
                                (!debouncedSearch && !amazonConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                        imageName="Amazon "
                                        title="Amazon"
                                        content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setAmazonConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("quickbooks desktop"?.includes(debouncedSearch.toLowerCase()) &&
                                    !quickbooksDesktop?.active) ||
                                (!debouncedSearch && !quickbooksDesktop?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="quickbooks desktop"
                                        title="Quickbooks Desktop"
                                        content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setQbDesktopConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("sps commerce"?.includes(debouncedSearch.toLowerCase()) &&
                                    !spsCommerce?.active) ||
                                (!debouncedSearch && !spsCommerce?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                        imageName="sps commerce"
                                        title="SPS Commerce"
                                        content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setSpsCommerceConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}

                                <TrackstarIntegration
                                    activeTrackstars={activeTrackstars}
                                    active={false}
                                    connectedButInactiveTrackstars={connectedButInactiveTrackstars}
                                    search={debouncedSearch}
                                />
                            </div>
                        </div>
                    </>
                );
            case 1:
                return fetchingAllChannels ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Active integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                {quickBooksConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="Quickooks"
                                        title="Quickbooks"
                                        content="Connecting your Quickbooks account allows Synth gain and analyze information about your sales, inventory, and purchase order which would be synced every hour."
                                        connected={true}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/quickbooks/${quickBooksConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {shopifyConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                        imageName="Shopify"
                                        title="Shopify"
                                        content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/shopify/${shopifyConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {googleConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                        imageName="Google "
                                        title="Google Suite"
                                        content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        width={35}
                                        height={26}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/google/${googleConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {microsoftConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                        imageName="Microsoft "
                                        title="Microsoft"
                                        content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/microsoft/${microsoftConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {amazonConnected?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                        imageName="Amazon "
                                        title="Amazon"
                                        content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/amazon/${amazonConnected?._id}`
                                            )
                                        }
                                    />
                                )}
                                {quickbooksDesktop?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="quickbooks desktop"
                                        title="QB Desktop"
                                        content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/quickbooks-desktop/${quickbooksDesktop?._id}`
                                            )
                                        }
                                    />
                                )}
                                {spsCommerce?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                        imageName="sps commerce"
                                        title="SPS Commerce"
                                        content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/integrations/sps-commerce/${spsCommerce?._id}`
                                            )
                                        }
                                    />
                                )}
                                {klaviyo?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                        imageName="klaviyo"
                                        title="Klaviyo"
                                        content="Connecting your Klaviyo account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/integrations/klaviyo/${klaviyo?._id}`
                                            );
                                        }}
                                    />
                                )}
                                {googleAds?.active && (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg"
                                        imageName="goole-ads"
                                        title="Google Ads"
                                        content="Connecting your Google ads account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={true}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/integrations/google-ads/${googleAds?._id}`
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Inactive integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                {("quickbooks"?.includes(debouncedSearch.toLowerCase()) &&
                                    !quickBooksConnected?.active) ||
                                (!debouncedSearch && !quickBooksConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="Quickooks"
                                        title="Quickbooks"
                                        content="Connecting your Quickbooks account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        onClick={() => {
                                            setQuickbooksConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("shopify"?.includes(debouncedSearch.toLowerCase()) &&
                                    !shopifyConnected?.active) ||
                                (!debouncedSearch && !shopifyConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                        imageName="Shopify"
                                        title="Shopify"
                                        content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        onClick={() => {
                                            // setShopifyConnect(true);
                                            // setShowIntegrationConnect(true);
                                            // ===
                                            setShowIntegrationConnect(false);
                                            setConnect(false);
                                            setShopifyConnect(false);
                                            setShowAddStoreName(false);
                                            setStoreName("");
                                            // window.open(fetchedShopifyOauthUrlSuccess?.loginUrl, "_target");
                                            window.open(
                                                "https://apps.shopify.com/synth",
                                                "_target"
                                            );
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("google suite"?.includes(debouncedSearch.toLowerCase()) &&
                                    !googleConnected?.active) ||
                                (!debouncedSearch && !googleConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                        imageName="Google "
                                        title="Google Suite"
                                        content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        width={35}
                                        height={26}
                                        onClick={() => {
                                            setGoogleConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("microsoft"?.includes(debouncedSearch.toLowerCase()) &&
                                    !microsoftConnected?.active) ||
                                (!debouncedSearch && !microsoftConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                        imageName="Microsoft "
                                        title="Microsoft"
                                        content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setMicrosoftConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("amazon"?.includes(debouncedSearch.toLowerCase()) &&
                                    !amazonConnected?.active) ||
                                (!debouncedSearch && !amazonConnected?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                        imageName="Amazon "
                                        title="Amazon"
                                        content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setAmazonConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("quickbooks desktop"?.includes(debouncedSearch.toLowerCase()) &&
                                    !quickbooksDesktop?.active) ||
                                (!debouncedSearch && !quickbooksDesktop?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                        imageName="quickbooks desktop"
                                        title="Quickbooks Desktop"
                                        content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setQbDesktopConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("sps commerce"?.includes(debouncedSearch.toLowerCase()) &&
                                    !spsCommerce?.active) ||
                                (!debouncedSearch && !spsCommerce?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                        imageName="sps commerce"
                                        title="SPS Commerce"
                                        content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setSpsCommerceConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}

                                {("klaviyo"?.includes(debouncedSearch.toLowerCase()) &&
                                    !klaviyo?.active) ||
                                (!debouncedSearch && !klaviyo?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                        imageName="klaviyo"
                                        title="Klaviyo"
                                        content="Connecting your Klaviyo account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setKlaviyoConnect(true);
                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                                {("google ads"?.includes(debouncedSearch.toLowerCase()) &&
                                    !googleAds?.active) ||
                                (!debouncedSearch && !googleAds?.active) ? (
                                    <IntegrationCard
                                        imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg"
                                        imageName="google-ads"
                                        title="Google Ads"
                                        content="Connecting your Google Ads account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                        connected={false}
                                        width={34}
                                        height={34}
                                        onClick={() => {
                                            setGoogleAdsConnect(true);

                                            setShowIntegrationConnect(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </>
                );
            case 2:
                return fetchingAllChannels ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Active integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                <TrackstarIntegration
                                    activeTrackstars={activeTrackstars}
                                    active={true}
                                    search={debouncedSearch}
                                />
                            </div>
                        </div>
                        <div className="mt-8 ">
                            <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                    Inactive integrations
                                </p>
                                <div className=" w-full border-t border-slate-200"></div>
                            </div>
                            <div className="grid mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                <TrackstarIntegration
                                    activeTrackstars={activeTrackstars}
                                    active={false}
                                    connectedButInactiveTrackstars={connectedButInactiveTrackstars}
                                    search={debouncedSearch}
                                />
                            </div>
                        </div>
                        {/* {tabId === 2 && (
                            <>
                                <div className="mt-8 ">
                                    <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                        <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                            Active integrations
                                        </p>
                                        <div className=" w-full border-t border-slate-200"></div>
                                    </div>
                                    <div className="grid mt-5 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                        {quickBooksConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                                imageName="Quickooks"
                                                title="Quickbooks"
                                                content="Connecting your Quickbooks account allows Synth gain and analyze information about your sales, inventory, and purchase order which would be synced every hour."
                                                connected={true}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/quickbooks/${quickBooksConnected?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {shopifyConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                                imageName="Shopify"
                                                title="Shopify"
                                                content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={true}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/shopify/${shopifyConnected?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {googleConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                                imageName="Google "
                                                title="Google Suite"
                                                content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={true}
                                                width={35}
                                                height={26}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/google/${googleConnected?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {microsoftConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                                imageName="Microsoft "
                                                title="Microsoft"
                                                content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/microsoft/${microsoftConnected?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {amazonConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                                imageName="Amazon "
                                                title="Amazon"
                                                content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/amazon/${amazonConnected?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {quickbooksDesktop?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                                imageName="quickbooks desktop"
                                                title="QB Desktop"
                                                content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/quickbooks-desktop/${quickbooksDesktop?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {spsCommerce?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                                imageName="sps commerce"
                                                title="SPS Commerce"
                                                content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/integrations/sps-commerce/${spsCommerce?._id}`,
                                                        { state: { internalNavigation: true } }
                                                    )
                                                }
                                            />
                                        )}
                                        {klaviyo?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                                imageName="klaviyo"
                                                title="Klaviyo"
                                                content="Connecting your Klaviyo account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/integrations/klaviyo/${klaviyo?._id}`
                                                    );
                                                }}
                                            />
                                        )}
                                        {googleAds?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg"
                                                imageName="goole-ads"
                                                title="Google Ads"
                                                content="Connecting your Google ads account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/integrations/google-ads/${googleAds?._id}`
                                                    );
                                                }}
                                            />
                                        )}
                                        {tiktok?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/tiktok_logo.webp"
                                                imageName="Tiktok"
                                                title="Tiktok"
                                                content="Connecting your Tiktok account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={true}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/integrations/tiktok/${tiktok?._id}`
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="mt-8 ">
                                    <div className="flex whitespace-nowrap space-x-7 mb-4 items-center">
                                        <p className="text-[#142837] md:text-slate-500 text-sm md:text-base font-rocGroteskMedium">
                                            Inactive integrations
                                        </p>
                                        <div className=" w-full border-t border-slate-200"></div>
                                    </div>
                                    <div className="grid mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                        {!quickBooksConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                                imageName="Quickooks"
                                                title="Quickbooks"
                                                content="Connecting your Quickbooks account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={false}
                                                onClick={() => {
                                                    setQuickbooksConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!shopifyConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                                imageName="Shopify"
                                                title="Shopify"
                                                content="Connecting your Shopify account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={false}
                                                onClick={() => {
                                                    // setShopifyConnect(true);
                                                    // setShowIntegrationConnect(true);
                                                    // ===
                                                    setShowIntegrationConnect(false);
                                                    setConnect(false);
                                                    setShopifyConnect(false);
                                                    setShowAddStoreName(false);
                                                    setStoreName("");
                                                    // window.open(fetchedShopifyOauthUrlSuccess?.loginUrl, "_target");
                                                    window.open(
                                                        "https://apps.shopify.com/synth",
                                                        "_target"
                                                    );
                                                }}
                                            />
                                        )}
                                        {!googleConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                                                imageName="Google "
                                                title="Google Suite"
                                                content="Connecting your Gmail account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={false}
                                                width={35}
                                                height={26}
                                                onClick={() => {
                                                    setGoogleConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!microsoftConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/ms_outlook_iqonc9.svg"
                                                imageName="Microsoft "
                                                title="Microsoft"
                                                content="Connecting your Microsoft account allows Synth get and analyze any supply chain related information on your social media accounts so as to streamline them."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setMicrosoftConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!amazonConnected?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Gmail_icon__2020_2_1_b0o8gy.svg"
                                                imageName="Amazon "
                                                title="Amazon"
                                                content="Connecting your Amazon Business account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setAmazonConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!quickbooksDesktop?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2611386_ievdjs.svg"
                                                imageName="quickbooks desktop"
                                                title="Quickbooks Desktop"
                                                content="Connecting your QB Desktop account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setQbDesktopConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!spsCommerce?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                                                imageName="sps commerce"
                                                title="SPS Commerce"
                                                content="Connecting your SPS Commerce account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setSpsCommerceConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!klaviyo?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/Klaviyo_symbol_zynlc7.webp"
                                                imageName="klaviyo"
                                                title="Klaviyo"
                                                content="Connecting your Klaviyo account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setKlaviyoConnect(true);
                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!googleAds?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/google-ads_syiiti.svg"
                                                imageName="google-ads"
                                                title="Google Ads"
                                                content="Connecting your Google Ads account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setGoogleAdsConnect(true);

                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                        {!tiktok?.active && (
                                            <IntegrationCard
                                                imageSrc="https://storage.googleapis.com/heysynth_files/synth_webapp/tiktok_logo.webp"
                                                imageName="Tiktok"
                                                title="Tiktok"
                                                content="Connecting your Tiktok account helps Synth gather data about your products, orders, sales, and inventory. This will help streamline your supply chain."
                                                connected={false}
                                                width={34}
                                                height={34}
                                                onClick={() => {
                                                    setTiktokConnect(true);

                                                    setShowIntegrationConnect(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )} */}
                    </>
                );
        }
    };
    return (
        <div className="relative bg-white md:border rounded-[8px] px-4 md:p-4 border-[#F1F5F9]">
            <div
                className={`${showAddStoreName && "md:block hidden"} ${
                    showAccounts && "sm:block hidden"
                } `}
            >
                <div className="sticky -top-[18px] bg-white">
                    <div className="bg-slate-50 md:block hidden px-8 py-4 border border-[#F1F5F9] rounded-[10px] ">
                        <p className="text-[#0F172A] text-xl font-rocGroteskMedium">Integrations</p>
                    </div>
                    {syncedAccount && (
                        <div className="absolute top-0 left-0 right-0 flex justify-center min-h-screen mt-4">
                            <div className="bg-[#16C6A4] flex justify-center items-center rounded-[8px] w-11/12 max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl h-12 sm:h-16 md:h-[78px] px-4 sm:px-6 md:px-10">
                                <p className="text-center font-rocGroteskBold text-white text-sm sm:text-base md:text-[20px] leading-5 sm:leading-6 md:leading-[28px]">
                                    Account linked successfully!
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-between items-center">
                        <div className="md:px-4 py-1 mt-5 flex justify-between items-center rounded md:bg-slate-50 w-fit">
                            {tabNames?.map((data, idx) => (
                                <div
                                    className={`cursor-pointer ${
                                        tabId === data?.id && "md:bg-white md:shadow-faintShadow"
                                    } md:px-[16px] py-[7px] rounded-[6px]`}
                                    key={idx}
                                    onClick={() => {
                                        setTabId(data?.id);
                                        setSearch("");
                                        setDebouncedSearch("");
                                    }}
                                >
                                    <p className="text-[#0F172A] font-rocGroteskMedium whitespace-nowrap text-sm">
                                        {data?.name}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className="">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e?.target?.value)}
                                onInput={handleDebouncedSearch}
                                inputPlaceholder={"Search products"}
                                inputClassName={
                                    "!h-[38px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px]  !h-[40px] !w-[300px] !pl-[20px] !bg-white !border !border-slate-200"
                                }
                            />
                        </div>
                    </div>
                </div>
                {displayActiveTab()}
            </div>
            <ModalContainer
                open={showIntegrationConnect}
                showCloseIcon={false}
                modalClassName={"py-10 px-5 md:px-10 max-sm:p-5"}
                tailwindClassName="max-w-[600px]"
                closeModal={() => {
                    setShowIntegrationConnect(false);
                    setConnect(false);
                    setShopifyConnect(false);
                    setGoogleConnect(false);
                    setMicrosoftConnect(false);
                    setQuickbooksConnect(false);
                }}
            >
                <div className="bg-white md:px-4 p-3 max-h-[650px] overflow-y-auto  rounded-[12px]">
                    <div className="w-full flex justify-end">
                        <div
                            className=" w-fit cursor-pointer"
                            onClick={() => {
                                setShowIntegrationConnect(false);
                                setConnect(false);
                                setShopifyConnect(false);
                                setGoogleConnect(false);
                                setMicrosoftConnect(false);
                                setQuickbooksConnect(false);
                                setKlaviyoConnect(false);
                            }}
                        >
                            <i className="ri-close-fill text-[29px] "></i>
                        </div>
                    </div>
                    <div className="px-2 md:px-6">
                        <div className="flex flex-col space-y-5">
                            <div className="flex flex-col items-center justify-center">
                                <div className="h-14 w-14 bg-light-yellow-2 rounded flex justify-center items-center">
                                    <img
                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/Vector_6_sxeori.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="mt-4">
                                    <h3 className="md:text-[22px] text-base text-center leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                        What type of data am I sharing with Synth?
                                    </h3>
                                    <p className="text-slate-500 text-center mt-1 text-sm md:text-base font-rocGroteskMedium">
                                        To help you accurately predict your demand forecasts, here
                                        are the type of data that Synth would be granted access to.
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3 mb-3 pb-3 border-b border-[#EBECF0]">
                                <div>
                                    <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                        <i className="ri-money-dollar-circle-fill text-slate-500 text-center"></i>
                                    </div>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <h6 className=" text-[#0F172A]">Sales information </h6>
                                    <p className="text-[#64748B]">
                                        E.g., sales volume, sales value, sales agents, sales
                                        channels, and any other relevant sales information
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3 mb-3 pb-3 border-b border-[#EBECF0]">
                                <div>
                                    <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                        <i className="ri-shopping-basket-2-fill text-slate-500 text-center"></i>
                                    </div>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <h6 className=" text-[#0F172A]">Inventory information </h6>
                                    <p className="text-[#64748B]">
                                        E.g., inventory volume, inventory value, retail stores,
                                        inventory managers, stock-outs, over-stocked products, and
                                        any other relevant inventory information.
                                    </p>
                                </div>
                            </div>

                            {(googleConnect ||
                                quickbooksConnect ||
                                mircosoftConnect ||
                                qbDesktopConnect ||
                                spsCommerceConnect) && (
                                <div className="flex space-x-3 mb-3 pb-3 border-b border-[#EBECF0]">
                                    <div>
                                        <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                            <i className="ri-shopping-basket-2-fill text-slate-500 text-center"></i>
                                        </div>
                                    </div>
                                    <div className="text-sm font-rocGroteskMedium">
                                        <h6 className=" text-[#0F172A]">
                                            Purchase orders (PO) information{" "}
                                        </h6>
                                        <p className="text-[#64748B]">
                                            E.g., suppliers, PO count, PO value, PO status, shipment
                                            tracking information, and any other relevant PO
                                            information.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mt-10 flex flex-col-reverse md:flex-row md:space-y-0 space-y-reverse space-y-3  md:space-x-3">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                disabled={
                                    fetchingQuickbooksOauthUrl ||
                                    fetchingShopifyOauthUrl ||
                                    authenticatingGmailSettings ||
                                    authenticatingMicrosoftSettings ||
                                    manageConnection ||
                                    fetchingAmazonOauthUrl ||
                                    fetchingSpsCommerceOauthUrl ||
                                    fetchingQbDesktopOauthUrl ||
                                    settingUpKlaviyoConnection
                                }
                                onClick={() => {
                                    setShowIntegrationConnect(false);
                                    setConnect(false);
                                    setShopifyConnect(false);
                                    setGoogleConnect(false);
                                    setMicrosoftConnect(false);
                                    setQuickbooksConnect(false);
                                    setQbDesktopConnect(false);
                                    setSpsCommerceConnect(false);
                                    setKlaviyoConnect(false);
                                }}
                            />
                            <Button
                                onClick={() => {
                                    if (!shopifyConnect) {
                                        setConnect(true);
                                    } else {
                                        setShowAddStoreName(true);
                                        setShowIntegrationConnect(false);
                                    }
                                }}
                                btnText={"Connect account"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.manage_integrations,
                                ]}
                                type={"button"}
                                isLoading={
                                    fetchingQuickbooksOauthUrl ||
                                    fetchingShopifyOauthUrl ||
                                    authenticatingGmailSettings ||
                                    authenticatingMicrosoftSettings ||
                                    manageConnection ||
                                    fetchingAmazonOauthUrl ||
                                    fetchingSpsCommerceOauthUrl ||
                                    fetchingQbDesktopOauthUrl ||
                                    settingUpKlaviyoConnection
                                }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <div className="md:block hidden">
                <ModalContainer
                    open={showAddStoreName}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10 max-sm:p-5"}
                    tailwindClassName="max-w-[600px]"
                    closeModal={() => {
                        setConnect(false);
                        setShopifyConnect(false);
                        setShowAddStoreName(false);
                    }}
                >
                    <div className="bg-white !px-4 p-3  rounded-[12px]">
                        <div className="w-full flex justify-end">
                            <div
                                className=" w-fit cursor-pointer"
                                onClick={() => {
                                    setConnect(false);
                                    setShopifyConnect(false);
                                    setShowAddStoreName(false);
                                }}
                            >
                                <i className="ri-close-fill text-[29px] "></i>
                            </div>
                        </div>
                        <div className=" md:px-6">
                            <div className="flex flex-col space-y-5">
                                <div className="flex flex-col items-center justify-center">
                                    <div className="h-14 w-14 bg-white shadow-faintShadow rounded flex justify-center items-center">
                                        <img
                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <h3 className="md:text-[22px] text-base text-center leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                            Connect Shopify
                                        </h3>
                                        <p className="text-slate-500 md:text-base text-center mt-1 text-sm font-rocGroteskMedium">
                                            To help you accurately predict your demand forecasts,
                                            connect your Shopify account.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex space-x-3 mb-3 pb-3 border-b border-[#EBECF0]">
                                    <div className="w-full">
                                        <TextInput
                                            type={"text"}
                                            value={storeName}
                                            onChange={(e) => setStoreName(e.target.value)}
                                            placeholder={"Enter store name"}
                                            required={true}
                                            containerClassname=""
                                        />
                                        <p
                                            className="text-[#0054C2] font-rocGroteskMedium text-sm mt-1 cursor-pointer"
                                            onClick={() =>
                                                window.open(
                                                    "https://docs.google.com/document/d/1K-KOPCSgHIhG0ZSByyhgvvefYfb5-xyL3ThsxDJMWcU/edit",
                                                    "_target"
                                                )
                                            }
                                        >
                                            How do I know my Shopify store name?{" "}
                                            <i className="ri-arrow-right-up-line"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 flex md:flex-row flex-col-reverse md:space-y-0 space-y-reverse space-y-3 md:space-x-3">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={fetchingShopifyOauthUrl || manageConnection}
                                    onClick={() => {
                                        setConnect(false);
                                        setShopifyConnect(false);
                                        setShowAddStoreName(false);
                                    }}
                                />
                                <Button
                                    onClick={() => setConnect(true)}
                                    btnText={"Connect account"}
                                    type={"button"}
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.manage_integrations,
                                    ]}
                                    btnClassname={`${storeName === "" && " !opacity-50"}`}
                                    disabled={storeName === ""}
                                    isLoading={fetchingShopifyOauthUrl || manageConnection}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
            {showAddStoreName && (
                <div className="md:hidden block ">
                    <div className="bg-white p-2 ">
                        <div className=" md:px-6">
                            <div className="flex flex-col space-y-5">
                                <div className="flex flex-col items-center justify-center">
                                    <div className="h-14 w-14 bg-white shadow-faintShadow rounded flex justify-center items-center">
                                        <img
                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/shopify_w87tn3.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <h3 className="md:text-[22px] text-base text-center leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                            Connect Shopify
                                        </h3>
                                        <p className="text-slate-500 md:text-base text-center mt-1 text-sm font-rocGroteskMedium">
                                            To help you accurately predict your demand forecasts,
                                            connect your Shopify account.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex space-x-3 mb-3 pb-3 border-b border-[#EBECF0]">
                                    <div className="w-full">
                                        <TextInput
                                            type={"text"}
                                            value={storeName}
                                            onChange={(e) => setStoreName(e.target.value)}
                                            placeholder={"Enter store name"}
                                            required={true}
                                            containerClassname=""
                                        />
                                        <p
                                            className="text-[#0054C2] font-rocGroteskMedium text-xs md:text-sm mt-1 cursor-pointer"
                                            onClick={() =>
                                                window.open(
                                                    "https://docs.google.com/document/d/1K-KOPCSgHIhG0ZSByyhgvvefYfb5-xyL3ThsxDJMWcU/edit",
                                                    "_target"
                                                )
                                            }
                                        >
                                            How do I know my Shopify store name?{" "}
                                            <i className="ri-arrow-right-up-line"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 flex md:flex-row flex-col-reverse md:space-y-0 space-y-reverse space-y-3 md:space-x-3">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={fetchingShopifyOauthUrl || manageConnection}
                                    onClick={() => {
                                        setConnect(false);
                                        setShopifyConnect(false);
                                        setShowAddStoreName(false);
                                    }}
                                />
                                <Button
                                    onClick={() => setConnect(true)}
                                    btnText={"Connect account"}
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.manage_integrations,
                                    ]}
                                    type={"button"}
                                    btnClassname={`${storeName === "" && " !opacity-50"}`}
                                    disabled={storeName === ""}
                                    isLoading={fetchingShopifyOauthUrl || manageConnection}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="sm:block hidden">
                <ModalContainer
                    open={showAccounts}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10 max-sm:p-5"}
                    tailwindClassName="max-w-[600px] max-h-[80%]"
                    closeModal={() => {
                        // setShowAccounts(false);
                    }}
                >
                    <div className="bg-white !px-4 p-3  rounded-[12px]">
                        <div className="w-full flex justify-end">
                            {/* <div
                                className=" w-fit cursor-pointer"
                                onClick={() => setShowAccounts(false)}
                            >
                                <i className="ri-close-fill text-[29px] "></i>
                            </div> */}
                        </div>
                        <div className=" px-6">
                            <div className="flex flex-col space-y-5">
                                <div className="text-center justify-center">
                                    <h3 className="text-[22px] leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                        Sync Accounts
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Select one account from each section that you will like us
                                        to get data from
                                    </p>
                                </div>
                                <div>
                                    <p className="text-[12px] text-center uppercase tracking-[2px] leading-[17.86px] text-slate-500 font-rocGroteskMedium">
                                        select accounts to sync
                                    </p>
                                </div>
                                {fetchingQuickbooksApAccounts ||
                                fetchingQuickbooksExpenseAccounts ||
                                fetchingQbDesktopApAccounts ||
                                fetchingQbDesktopExpenseAccounts ||
                                fetchingQbDesktopIncomeAccounts ||
                                fetchingQbDesktopCurrentAssetAccounts ||
                                fetchingQbDesktopFixedAssetAccounts ||
                                completingQbDesktopConnection ? (
                                    <div className="my-[10%]">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div className="bg-white px-2 rounded-[8px] min-h-fit max-h-[45vh]  overflow-y-scroll">
                                        {formattedArray?.map((acct, idx) => {
                                            const accountTitle = Object.keys(acct);
                                            const accountsArray = acct[accountTitle[0]];

                                            return (
                                                <div key={idx} className="">
                                                    <span className="text-xs text-slate-500 block font-rocGroteskMedium text-center mb-4 uppercase">
                                                        {accountTitle[0]}
                                                    </span>

                                                    <div className="border border-slate-200  shadow-[0px_4px_32px_0px_#0000000F] mb-5 rounded-lg">
                                                        {accountsArray?.map((account, idx) => {
                                                            const selectedAccount =
                                                                selectedAccounts?.find(
                                                                    (acctItem) => {
                                                                        return (
                                                                            acctItem?.[
                                                                                accountTitle[0]
                                                                            ]?.Id === account.Id
                                                                        );
                                                                    }
                                                                );

                                                            return (
                                                                <div
                                                                    key={account?.Id}
                                                                    className={`p-3 ${
                                                                        idx <
                                                                            accountsArray.length -
                                                                                1 && "border-b"
                                                                    } border-n-30 flex justify-between`}
                                                                >
                                                                    <div className="flex space-x-3">
                                                                        <div>
                                                                            <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                                                <i className="ri-money-dollar-circle-fill text-slate-500 text-center"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-sm font-rocGroteskMedium">
                                                                            <h6 className=" text-[#0F172A]">
                                                                                {account?.Name}
                                                                            </h6>
                                                                            <p className="text-[#64748B] text-[12px]">
                                                                                Account ID:{" "}
                                                                                {account?.Id}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className={`border rounded px-[16px] w-fit cursor-pointer py-[8px] flex space-x-3 items-center border-slate-200 group hover:bg-light-green hover:border-g-55 hover:text-g-55 ${
                                                                            selectedAccount &&
                                                                            "bg-light-green border-g-55 text-g-55"
                                                                        }`}
                                                                        onClick={() => {
                                                                            const selectedAccountIndex =
                                                                                [
                                                                                    ...selectedAccounts,
                                                                                ].findIndex(
                                                                                    (item) => {
                                                                                        return (
                                                                                            item?.[
                                                                                                accountTitle[0]
                                                                                            ]
                                                                                                ?.Id ===
                                                                                            account.Id
                                                                                        );
                                                                                    }
                                                                                );

                                                                            if (
                                                                                selectedAccountIndex !==
                                                                                -1
                                                                            ) {
                                                                                const selectedAccountsCopy =
                                                                                    [
                                                                                        ...selectedAccounts,
                                                                                    ];
                                                                                selectedAccountsCopy.splice(
                                                                                    selectedAccountIndex,
                                                                                    1
                                                                                );
                                                                                setSelectedAccounts(
                                                                                    selectedAccountsCopy
                                                                                );
                                                                            } else {
                                                                                const selectedAccountsCopy =
                                                                                    [
                                                                                        ...selectedAccounts,
                                                                                    ];
                                                                                const itemCategoryIndex =
                                                                                    selectedAccountsCopy.findIndex(
                                                                                        (item) => {
                                                                                            return (
                                                                                                Object.keys(
                                                                                                    item
                                                                                                )[0] ===
                                                                                                accountTitle[0]
                                                                                            );
                                                                                        }
                                                                                    );

                                                                                if (
                                                                                    itemCategoryIndex ===
                                                                                    -1
                                                                                ) {
                                                                                    // selectedAccountsCopy[itemCategoryIndex] = {
                                                                                    //     [accountTitle[0]]: account
                                                                                    // }
                                                                                    setSelectedAccounts(
                                                                                        (prev) => {
                                                                                            return [
                                                                                                ...prev,
                                                                                                {
                                                                                                    [accountTitle[0]]:
                                                                                                        account,
                                                                                                },
                                                                                            ];
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="h-fit">
                                                                            <div
                                                                                className={`h-4 w-4 rounded-full border-[#A2ABAB] border group-hover:hidden ${
                                                                                    selectedAccount &&
                                                                                    "hidden"
                                                                                }`}
                                                                            ></div>
                                                                            <i
                                                                                className={`ri-check-fill text-g-55 hidden group-hover:block ${
                                                                                    selectedAccount &&
                                                                                    "!block"
                                                                                }`}
                                                                            ></i>
                                                                        </div>
                                                                        <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                            Select
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            <div className="mt-10 flex md:flex-row flex-col-reverse space-y-reverse md:space-y-0 space-y-3 md:space-x-3">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={
                                        setQuickbooksAccount ||
                                        settingUpQbDesktop ||
                                        fetchingQuickbooksApAccounts ||
                                        fetchingQuickbooksExpenseAccounts ||
                                        fetchingQbDesktopApAccounts ||
                                        fetchingQbDesktopExpenseAccounts ||
                                        fetchingQbDesktopIncomeAccounts ||
                                        fetchingQbDesktopCurrentAssetAccounts ||
                                        fetchingQbDesktopFixedAssetAccounts ||
                                        completingQbDesktopConnection
                                    }
                                    onClick={() => {
                                        setSelectedExpense(null);
                                        setSelectedLiability(null);
                                        setShowAccounts(false);
                                        navigate("/dashboard/integrations", {
                                            replace: true,
                                            state: { internalNavigation: true },
                                        });
                                    }}
                                />
                                <Button
                                    onClick={synAccount}
                                    disabled={
                                        !showButton ||
                                        fetchingQuickbooksApAccounts ||
                                        fetchingQuickbooksExpenseAccounts ||
                                        fetchingQbDesktopApAccounts ||
                                        fetchingQbDesktopExpenseAccounts ||
                                        fetchingQbDesktopIncomeAccounts ||
                                        fetchingQbDesktopCurrentAssetAccounts ||
                                        fetchingQbDesktopFixedAssetAccounts ||
                                        completingQbDesktopConnection
                                    }
                                    btnText={`Sync selected accounts (${selectedAccounts.length})`}
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.manage_integrations,
                                    ]}
                                    type={"button"}
                                    isLoading={setQuickbooksAccount || settingUpQbDesktop}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
            {showAccounts && (
                <div className="bg-white md:px-4 sm:hidden block  md:p-3 pb-24 rounded-[12px]">
                    <div className=" md:px-6">
                        <div className="flex flex-col space-y-5">
                            <div className="text-center justify-center">
                                <h3 className="text-[22px] leading-[32px] mb-2 text-black font-rocGroteskMedium">
                                    Sync Accounts
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    Select one account from each section that you will like us to
                                    get data from
                                </p>
                            </div>
                            <div>
                                <p className="text-[12px] text-center uppercase tracking-[2px] leading-[17.86px] text-slate-500 font-rocGroteskMedium">
                                    select accounts to sync
                                </p>
                            </div>
                            {fetchingQuickbooksApAccounts ||
                            fetchingQuickbooksExpenseAccounts ||
                            fetchingQbDesktopApAccounts ||
                            fetchingQbDesktopExpenseAccounts ||
                            fetchingQbDesktopIncomeAccounts ||
                            fetchingQbDesktopCurrentAssetAccounts ||
                            fetchingQbDesktopFixedAssetAccounts ||
                            completingQbDesktopConnection ? (
                                <div className="my-[10%]">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="bg-white  rounded-[8px]">
                                    {formattedArray?.map((acct, idx) => {
                                        const accountTitle = Object.keys(acct);
                                        const accountsArray = acct[accountTitle[0]];

                                        return (
                                            <div key={idx} className="">
                                                <span className="text-xs text-slate-500 block font-rocGroteskMedium text-center mb-4 uppercase">
                                                    {accountTitle[0]}
                                                </span>

                                                <div className=" mb-5">
                                                    {accountsArray?.map((account, idx) => {
                                                        const selectedAccount =
                                                            selectedAccounts?.find((acctItem) => {
                                                                return (
                                                                    acctItem?.[accountTitle[0]]
                                                                        ?.Id === account.Id
                                                                );
                                                            });

                                                        return (
                                                            <div
                                                                key={account?.Id}
                                                                className={`mt-2 ${
                                                                    idx <
                                                                        accountsArray.length - 1 &&
                                                                    "pb-2 border-b "
                                                                } border-n-30 flex justify-between`}
                                                            >
                                                                <div className="flex space-x-3">
                                                                    <div>
                                                                        <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                                            <i className="ri-money-dollar-circle-fill text-slate-500 text-center"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-sm font-rocGroteskMedium">
                                                                        <h6 className=" text-[#0F172A]">
                                                                            {account?.Name}
                                                                        </h6>
                                                                        <p className="text-[#64748B] text-[12px]">
                                                                            Account ID:{" "}
                                                                            {account?.Id}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={`border rounded px-[16px] w-fit cursor-pointer py-[8px] flex space-x-3 items-center border-slate-200 group hover:bg-light-green hover:border-g-55 hover:text-g-55 ${
                                                                        selectedAccount &&
                                                                        "bg-light-green border-g-55 text-g-55"
                                                                    }`}
                                                                    onClick={() => {
                                                                        const selectedAccountIndex =
                                                                            [
                                                                                ...selectedAccounts,
                                                                            ].findIndex((item) => {
                                                                                return (
                                                                                    item?.[
                                                                                        accountTitle[0]
                                                                                    ]?.Id ===
                                                                                    account.Id
                                                                                );
                                                                            });

                                                                        if (
                                                                            selectedAccountIndex !==
                                                                            -1
                                                                        ) {
                                                                            const selectedAccountsCopy =
                                                                                [
                                                                                    ...selectedAccounts,
                                                                                ];
                                                                            selectedAccountsCopy.splice(
                                                                                selectedAccountIndex,
                                                                                1
                                                                            );
                                                                            setSelectedAccounts(
                                                                                selectedAccountsCopy
                                                                            );
                                                                        } else {
                                                                            const selectedAccountsCopy =
                                                                                [
                                                                                    ...selectedAccounts,
                                                                                ];
                                                                            const itemCategoryIndex =
                                                                                selectedAccountsCopy.findIndex(
                                                                                    (item) => {
                                                                                        return (
                                                                                            Object.keys(
                                                                                                item
                                                                                            )[0] ===
                                                                                            accountTitle[0]
                                                                                        );
                                                                                    }
                                                                                );

                                                                            if (
                                                                                itemCategoryIndex ===
                                                                                -1
                                                                            ) {
                                                                                // selectedAccountsCopy[itemCategoryIndex] = {
                                                                                //     [accountTitle[0]]: account
                                                                                // }
                                                                                setSelectedAccounts(
                                                                                    (prev) => {
                                                                                        return [
                                                                                            ...prev,
                                                                                            {
                                                                                                [accountTitle[0]]:
                                                                                                    account,
                                                                                            },
                                                                                        ];
                                                                                    }
                                                                                );
                                                                            }
                                                                        }

                                                                        // if (!selectedLiability) {
                                                                        //     setSelectedLiability(
                                                                        //         account
                                                                        //     );
                                                                        //     setTotalSelectedTwo(1);
                                                                        // } else if (
                                                                        //     selectedLiability?.Id ===
                                                                        //     account.Id
                                                                        // ) {
                                                                        //     setTotalSelectedTwo(0);
                                                                        //     setSelectedLiability(
                                                                        //         null
                                                                        //     );
                                                                        // }
                                                                    }}
                                                                >
                                                                    <div className="h-fit">
                                                                        <div
                                                                            className={`h-4 w-4 rounded-full border-[#A2ABAB] border group-hover:hidden ${
                                                                                selectedAccount &&
                                                                                "hidden"
                                                                            }`}
                                                                        ></div>
                                                                        <i
                                                                            className={`ri-check-fill text-g-55 hidden group-hover:block ${
                                                                                selectedAccount &&
                                                                                "!block"
                                                                            }`}
                                                                        ></i>
                                                                    </div>
                                                                    <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                        Select
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <div className="py-2 bg-white px-4 space-y-3 bottom-0 left-0 right-0 z-[50] fixed w-full flex flex-col">
                            <Button
                                onClick={synAccount}
                                disabled={!showButton}
                                btnText={`Sync selected accounts (${selectedAccounts.length})`}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.manage_integrations,
                                ]}
                                type={"button"}
                                isLoading={setQuickbooksAccount || settingUpQbDesktop}
                            />
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                disabled={setQuickbooksAccount || settingUpQbDesktop}
                                onClick={() => {
                                    setSelectedExpense(null);
                                    setSelectedLiability(null);
                                    setShowAccounts(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            <ModalContainer
                open={
                    connectingGoogleWorkspaceSettings ||
                    connectingMicrosoftWorkspaceSettings ||
                    setQuickbooksAccount ||
                    verifyShopifyAccount ||
                    verifyAmazonAccount
                }
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Connecting account...
                    </p>
                </div>
            </ModalContainer>

            {/* <ModalContainer
                open={settingUpKlaviyoConnection}
                showCloseIcon={false}
                modalClassName={"py-10 px-5 md:px-10 max-sm:p-5"}
                tailwindClassName="max-w-[600px]"
                closeModal={() => {}}
            >
                <div className="bg-white md:px-4 p-3 max-h-[650px]  rounded-[12px]">
                    <div className="flex flex-col items-center font-rocGroteskMedium">
                        <Loader size={8} />
                        <p className="text-center my-10">Connecting...</p>
                    </div>
                </div>
            </ModalContainer> */}
        </div>
    );
};

export default Integration;
