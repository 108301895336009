import config from "config/config";
import { authService } from "./authService";

export const integrationService = {
    fetchQuickbooksOauthUrl,
    fetchQuickbooksExchangeCode,
    fetchQuickbooksApAccounts,
    fetchQuickbooksExpenseAccounts,
    setUpQuickbooks,
    fetchShopifyOauthUrl,
    verifyShopifyAccount,
    fetchSocialAccount,
    updateSocialAccount,
    fetchIncomeAccounts,
    fetchAssetAccounts,
    fetchShopifyData,
    fetchAmazonOauthUrl,
    fetchAmazonData,
    fetchSpsCommerceOauthUrl,
    completeSpsCommerceConnection,
    fetchQbDesktopOauthUrl,
    completeQbDesktopConneection,
    fetchQbDesktopCurrentAssetAccounts,
    fetchQbDesktopFixedAssetAccounts,
    fetchQbDesktopApAccounts,
    fetchQbDesktopExpenseAccounts,
    fetchQbDesktopIncomeAccounts,
    setUpQbDesktop,
    setupKlaviyoConnection,
    completeKlaviyoConnection,
    setupGoogleAdsConnection,
    completeGoogleAdsConnection,
    setupTiktokConnection,
    completeTiktokConnection,
};

async function fetchQuickbooksOauthUrl() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/authenticate/settings`,
        requestOptions
    );
    return res;
}

async function fetchSpsCommerceOauthUrl() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/sps-commerce/connect`,
        requestOptions
    );
    return res;
}

async function completeSpsCommerceConnection(
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/sps-commerce/complete`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopOauthUrl() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/connect`,
        requestOptions
    );
    return res;
}

async function completeQbDesktopConneection() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/complete-connection`,
        requestOptions
    );
    return res;
}

async function fetchAmazonOauthUrl() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/amazon/start`,
        requestOptions
    );
    return res;
}

async function fetchQuickbooksExchangeCode(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/connect`,
        requestOptions
    );
    return res;
}
async function fetchShopifyData(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/shopify/auth`,
        requestOptions
    );
    return res;
}

async function fetchAmazonData(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/amazon/complete`,
        requestOptions
    );
    return res;
}

async function fetchQuickbooksApAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/list/ap-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQuickbooksExpenseAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/list/expense-accounts`,
        requestOptions
    );
    return res;
}

async function setUpQuickbooks(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/account/setup`,
        requestOptions
    );
    return res;
}

async function fetchShopifyOauthUrl(storeName: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/shopify/login/${storeName}`,
        requestOptions
    );
    return res;
}

async function verifyShopifyAccount(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/verify/shopify/token`,
        requestOptions
    );
    return res;
}

async function fetchSocialAccount(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/connection/${id}`,
        requestOptions
    );
    return res;
}
async function updateSocialAccount(
    data: Record<string, any> | Array<Record<string, any>>,
    socialId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/update/connection/${socialId}`,
        requestOptions
    );
    return res;
}

async function fetchIncomeAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/list/income-accounts`,
        requestOptions
    );
    return res;
}

async function fetchAssetAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks/list/asset-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopCurrentAssetAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/list/current-asset-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopFixedAssetAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/list/fixed-asset-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopApAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/list/ap-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopExpenseAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/list/cog-accounts`,
        requestOptions
    );
    return res;
}

async function fetchQbDesktopIncomeAccounts() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/list/income-accounts`,
        requestOptions
    );
    return res;
}

async function setUpQbDesktop(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/quickbooks-desktop/account/setup`,
        requestOptions
    );
    return res;
}

async function setupKlaviyoConnection(redirectUrl: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/klaviyo/connect?redirectUrl=${redirectUrl}`,
        requestOptions
    );
    return res;
}

async function completeKlaviyoConnection(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/klaviyo/authorization-callback`,
        requestOptions
    );
    return res;
}

async function setupGoogleAdsConnection() {
    const requestOptions = {
        method: "GET",
        // body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/google/auth`,
        requestOptions
    );
    return res;
}

async function completeGoogleAdsConnection(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/google/verify`,
        requestOptions
    );
    return res;
}

async function setupTiktokConnection() {
    const requestOptions = {
        method: "GET",
        // body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/tiktok/auth`,
        requestOptions
    );
    return res;
}

async function completeTiktokConnection(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/tiktok/verify`,
        requestOptions
    );
    return res;
}
