import React from "react";

const GeneratingLoader = () => {
    return (
        <svg
            className="animate-spin"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3590_21507)">
                <rect width="24" height="24" rx="12" fill="url(#pattern0_3590_21507)" />
                <path
                    opacity="0.5"
                    d="M21.75 12C21.75 10.7196 21.4978 9.45176 21.0078 8.26884C20.5178 7.08591 19.7997 6.01108 18.8943 5.10571C17.9889 4.20034 16.9141 3.48216 15.7312 2.99217C14.5482 2.50219 13.2804 2.25 12 2.25C10.7196 2.25 9.45176 2.50219 8.26884 2.99217C7.08591 3.48216 6.01108 4.20034 5.10571 5.10571C4.20034 6.01108 3.48216 7.08591 2.99217 8.26884C2.50219 9.45176 2.25 10.7196 2.25 12C2.25 13.2804 2.50219 14.5482 2.99217 15.7312C3.48216 16.9141 4.20034 17.9889 5.10571 18.8943C6.01108 19.7997 7.08591 20.5178 8.26884 21.0078C9.45176 21.4978 10.7196 21.75 12 21.75C13.2804 21.75 14.5482 21.4978 15.7312 21.0078C16.9141 20.5178 17.9889 19.7997 18.8943 18.8943C19.7997 17.9889 20.5178 16.9141 21.0078 15.7312C21.4978 14.5482 21.75 13.2804 21.75 12L21.75 12Z"
                    stroke="#2B71CC"
                    stroke-linecap="round"
                    stroke-dasharray="1 4"
                />
                <circle
                    cx="1.40625"
                    cy="1.40625"
                    r="1.40625"
                    transform="matrix(1 0 0 -1 10.5938 13.4062)"
                    fill="#0054C2"
                />
                <path
                    d="M21.8437 12C21.8437 12.0206 21.8437 12.0412 21.8435 12.0618"
                    stroke="#0054C2"
                    stroke-width="4"
                    stroke-linecap="round"
                />
                <path
                    d="M21.8437 12C21.8437 11.9794 21.8437 11.9588 21.8435 11.9382"
                    stroke="#0054C2"
                    stroke-width="4"
                    stroke-linecap="round"
                />
                <circle
                    cx="1.40625"
                    cy="1.40625"
                    r="1.40625"
                    transform="matrix(-1 0 0 1 13.4062 10.5938)"
                    fill="#0054C2"
                />
                <path
                    d="M2.15627 12C2.15627 11.9794 2.15633 11.9588 2.15646 11.9382"
                    stroke="#0054C2"
                    stroke-width="4"
                    stroke-linecap="round"
                />
                <path
                    d="M2.15627 12C2.15627 12.0206 2.15633 12.0412 2.15646 12.0618"
                    stroke="#0054C2"
                    stroke-width="4"
                    stroke-linecap="round"
                />
            </g>
            <defs>
                <pattern
                    id="pattern0_3590_21507"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use />
                </pattern>
                <clipPath id="clip0_3590_21507">
                    <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
                <image id="image0_3590_21507" width="1" height="1" preserveAspectRatio="none" />
            </defs>
        </svg>
    );
};

export default GeneratingLoader;
