import Checkbox from "components/common/InputField/Checkbox";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import React from "react";
import { Tooltip } from "react-tooltip";

type AiAgentActivitiesProps = {
    structure: { [key: string]: any }[];
    handleAiStepsChange?: (idx: number, name: string, value: any) => void;
};

const AiAgentActivities = ({ structure, handleAiStepsChange }: AiAgentActivitiesProps) => {
    const {
        data: teamList,
        handleSearch,
        handleDebouncedChange,
        search,
        isFetching: isFetchingTeamList,
    } = useGetTeam();

    return (
        <div>
            {structure?.map((step, idx) => {
                return (
                    <div
                        key={step?.title + String(idx)}
                        className={`font-rocGroteskMedium p-5 border border-slate-200 rounded-[8px] mb-3`}
                    >
                        <div className="flex items-center justify-between mb-1 text-sm space-x-2">
                            <div className="flex items-center space-x-1">
                                <span className="text-sm">{step?.title}</span>
                                <div>
                                    <a id={`step-info-${idx}`}>
                                        <i className="ri-information-fill text-slate-300 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect={`#step-info-${idx}`}
                                        place={"top"}
                                        content={step?.description}
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>
                            {step?.approvalPossible && (
                                <div className="text-[13px] flex items-center space-x-1">
                                    <span>
                                        {/* Turn {step?.shouldApprove ? "off" : "on"} need for approval */}
                                        Require approval before action
                                    </span>
                                    <ToggleInput
                                        checked={step?.shouldApprove}
                                        name={"shouldApprove"}
                                        onChange={(e) => {
                                            handleAiStepsChange(
                                                idx,
                                                e.target.name,
                                                e.target.checked
                                            );

                                            if (!e.target.checked) {
                                                handleAiStepsChange(idx, "approver", "");
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {/* <p className="text-sm text-slate-500 w-[75%] mb-3">{step?.description}</p> */}

                        {step?.key === "inventory_check" && (
                            <div>
                                <div className="flex items-center justify-between py-3">
                                    <hr className="w-[25%]" />
                                    <span className="text-[13px] text-slate-500 whitespace-nowrap">
                                        Choose action if an item is out of stock
                                    </span>
                                    <hr className="w-[25%]" />
                                </div>

                                <SelectInput
                                    value={step?.controlAction}
                                    name="controlAction"
                                    placeholder={"Select action"}
                                    handleChange={(name, value) => {
                                        handleAiStepsChange(idx, name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={step?.actions?.map((act) => ({
                                        label: camelCaseToRegularCase(act?.name),
                                        value: act?._id || act?.name,
                                    }))}
                                />
                            </div>
                        )}

                        {step?.shouldApprove && (
                            <div>
                                <div className="flex items-center justify-between py-3">
                                    <hr className="w-[36%]" />
                                    <span className="text-sm text-slate-500 whitespace-nowrap">
                                        Select approver
                                    </span>
                                    <hr className="w-[36%]" />
                                </div>

                                <SelectInput
                                    value={step?.approverName || step?.approver}
                                    name="approver"
                                    placeholder={"Select approver"}
                                    handleChange={(name, value) =>
                                        handleAiStepsChange(idx, name, value)
                                    }
                                    searchLoading={isFetchingTeamList}
                                    handleInputChange={handleDebouncedChange}
                                    isRequired={true}
                                    dropdownOptions={teamList?.map((team) => {
                                        return {
                                            label: team?.fullName || team?.email,
                                            value: team?._id,
                                        };
                                    })}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default AiAgentActivities;
