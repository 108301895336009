/* eslint-disable */
import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExcelFile from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/AddStockCount/ExcelFile";
import OfflinePurchase from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/AddStockCount/offlinePurchase";
import { customFieldActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import CustomFieldsImplement from "components/common/CustomFieldsImplement";
// import { getEditPermissionList } from "helpers/getEditPermissionList";
import CustomFields from "components/common/CustomFields";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import Shipment from "./Shipment";

const AddStock = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params, param] = useSearchParams();
    const currTab = params.get("currTab");
    const productId = params.get("productId");
    const warehouseId = params.get("warehouseId");
    const channel = params.get("channel");
    const shipmentId = params.get("shipmentId");
    const customFieldType = "stockCount";
    const [profile] = useGetUserProfile();
    const [receivingChannel, setReceivingChannel] = useState<string>("offline_purchase");
    const [stockData, setStockData] = useState<any>({
        cost: {
            currency: "USD",
        },
    });
    const [attachment, setAttachment] = useState([]);
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [productViabilityDetails, setProductViabilityDetails] = useState<any>(null);
    const [customModal, setCustomModal] = useState<boolean>(false);
    const [fieldType, setFieldType] = useState<string>("text");
    const [fieldName, setFieldName] = useState<string>("");
    const [customFieldArray, setCustomFieldArray] = useState<any>([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [productViabilityDetailsTwo, setProductViabilityDetailsTwo] = useState([]);
    const [customAttachments, setCustomAttachments] = useState<any>({
        value: [],
    });
    const [stockShipmentData, setStockShipmentData] = useState<any>();
    const [editCustomFieldIdx, setEditCustomFieldIdx] = useState<number>();
    const [deleteCustomFieldModal, setDeleteCustomFieldModal] = useState<boolean>(false);
    const [deleteIdx, setDeleteIdx] = useState<number>();
    const [recurringSetting, setRecurringSetting] = useState<boolean>(false);
    const [singleValueField, setSingleValueField] = useState<any>();
    const [recurringFields, setRecurringFields] = useState<any>();
    const [savedRecurringFieldId, setSavedRecurringFieldId] = useState<string>("");
    const [customFields, setCustomFields] = useState<any>();
    const [shipment, setShipment] = useState<any>();
    const [debounceShipmentSearch, setDebounceShipmentSearch] = useState<string>("");
    const [allWarehouse, setAllWarehouse] = useState<{ [key: string]: any }[]>(
        new Array(1).fill({})
    );

    const [multipleValuesField, setMultipleValuesField] = useState<any>({
        value: [
            {
                selected: true,
                value: "",
            },
        ],
    });

    const {
        addingProductToStockCount,
        addedProductToStockCountSuccess,
        addedMultipleProductToStockCountSuccess,
        addingMultipleProductToStockCount,
        validateBatchId,
        validateBatchIdSuccess,
    } = useAppSelector((state) => state.inventory);
    const { fetchingShipments, fetchShipmentsSuccess } = useAppSelector((state) => state?.shipment);

    const {
        fetchingCustomField,
        fetchCustomFieldSuccess,
        deletingCustomField,
        deleteCustomFieldSuccess,
    } = useAppSelector((state) => state.customField);

    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess)) {
            setShipment(fetchShipmentsSuccess?.data);
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        dispatch(shipmentActions?.getShipments(100, debounceShipmentSearch));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchCustomFieldSuccess)) {
            setRecurringFields(fetchCustomFieldSuccess?.customFields);
        }
    }, [fetchCustomFieldSuccess]);

    useEffect(() => {
        if (customFieldType) {
            dispatch(customFieldActions.getCustomFields(customFieldType));
        }
    }, [dispatch, customFieldType]);

    useEffect(() => {
        if (Boolean(deleteCustomFieldSuccess)) {
            setCustomModal(false);
        }
    }, [deleteCustomFieldSuccess]);

    useEffect(() => {
        if (recurringFields?.length > 0) {
            const formatCustomFields = () =>
                recurringFields?.map((field) => {
                    let formattedField: {
                        fieldType: string;
                        name: string;
                        recurringSetting: boolean;
                        _id: string;
                        noValue: boolean;
                        placeholder?: string;
                        placeholderValue?: string | number;
                        currency?: string;
                        value?: any;
                    } = {
                        fieldType: field?.fieldType,
                        name: field?.fieldName,
                        recurringSetting: true,
                        _id: field?._id,
                        noValue: true,
                    };

                    if (field.fieldType === "text" || field.fieldType === "multiline") {
                        formattedField = {
                            ...formattedField,
                            placeholder: field?.placeholder,
                            placeholderValue: field?.fieldName,
                        };
                    } else if (field.fieldType === "currency") {
                        formattedField = {
                            ...formattedField,
                            currency: field?.currency,
                        };
                    } else if (
                        field.fieldType === "checkbox" ||
                        field.fieldType === "radio" ||
                        field.fieldType === "select"
                    ) {
                        formattedField = {
                            ...formattedField,
                            value: field?.options?.map((item) => ({
                                value: item?.name,
                                selected: false,
                            })),
                        };
                    } else if (field.fieldType === "file") {
                        formattedField = {
                            ...formattedField,
                            value: [],
                        };
                    }

                    return formattedField;
                });

            setCustomFieldArray(formatCustomFields);
        }
    }, [recurringFields]);

    const handleDeleteFromCustomFieldsArray = (idx) => {
        const newArr = [...customFieldArray];

        if (newArr[idx]?.recurringSetting) {
            dispatch(customFieldActions?.deleteCustomField(newArr[idx]?._id));
            newArr.splice(idx, 1);
        } else {
            newArr.splice(idx, 1);
            setCustomModal(false);
        }
        setCustomFieldArray(newArr);
        setDeleteCustomFieldModal(false);
        setDeleteIdx(-1);
    };

    useEffect(() => {
        if (!customModal) {
            setMultipleValuesField({
                value: [
                    {
                        selected: true,
                        value: "",
                    },
                ],
            });
            setSingleValueField("");
            setRecurringSetting(false);
            setCustomAttachments({
                value: [],
            });
        }
    }, [customModal]);

    const handleEditCustomField = (idx) => {
        const customArr = [...customFieldArray];
        setEditCustomFieldIdx(idx);
        setFieldType(customArr[idx]?.fieldType);
        setFieldName(customArr[idx]?.name);
        setRecurringSetting(customArr[idx]?.recurringSetting);
        if (
            customArr[idx]?.fieldType === "checkbox" ||
            customArr[idx]?.fieldType === "radio" ||
            customArr[idx]?.fieldType === "dropDown"
        ) {
            setMultipleValuesField(customArr[idx]);
        } else if (customArr[idx]?.fieldType === "file") {
            setCustomAttachments(customArr[idx]);
        } else {
            setSingleValueField(customArr[idx]);
        }
        setCustomModal(true);

        if (customArr[idx]?._id) {
            setSavedRecurringFieldId(customArr[idx]?._id);
        }
    };

    const handleDateChange = (name, newDate) => {
        setProductViabilityDetails({
            ...productViabilityDetails,
            [name]: moment(newDate).format("YYYY-MM-DD"),
        });
    };

    const handleStockData = (e) => {
        const { name, value } = e.target;
        setStockData((prev: any) => {
            if (name === "amount" || name === "currency") {
                return {
                    ...prev,
                    cost: {
                        ...prev?.cost,
                        [name]: value,
                    },
                };
            }
            // else if (name === "quantityAdded" || name === "unitOfMeasurement") {
            //     return {
            //         ...prev,
            //         productStockDetails: {
            //             ...prev?.productStockDetails,
            //             [name]: value,
            //         },
            //     };
            // }
            else {
                return { ...prev, [name]: value };
            }
        });
    };

    const addMoreWarehouse = () => {
        setAllWarehouse((prev) => [...prev, {}]);
    };

    const handleWarehouses = (e, idx) => {
        const { name, value } = e.target;

        setAllWarehouse((prev) => {
            const updatedWarehouses = [...prev];
            updatedWarehouses[idx][name] = value;
            return updatedWarehouses;
        });
    };
    const handleUpload = (fileName: string, file: File, name?: string) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachment((prev) => [
                        ...prev,
                        {
                            path: url,
                            filename: fileName,
                            ...(name === "shipment" && {
                                documentType: "stockCount",
                            }),
                        },
                    ]);

                    setUploadingAttachment(false);
                }
            );
        }
    };

    const deleteAttachment = (idx) => {
        const newArr = [...attachment];
        const imagePath = decodeURIComponent(
            newArr[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachment(newArr);
        });
    };

    useEffect(() => {
        setStockData((prev) => ({
            ...prev,
            warehouses: allWarehouse,
        }));
    }, [allWarehouse]);

    const handleSaveStock = () => {
        if (receivingChannel === "shipment") {
            const body = {
                shipmentId: stockShipmentData?.shipmentId,
                stockDetails: checkedItems?.map((item) => ({
                    inventoryId: item?.inventoryId,
                    vendorId: item?.vendorId,
                    cost: item?.price,
                    warehouseId: item?.warehouseId,
                    productStockDetails: {
                        quantityAdded: item?.quantity,
                        unitOfMeasurement: item?.inventory?.productStockDetails?.unitOfMeasurement,
                    },
                    ...(item?.batchId && { batchId: item?.batchId }),
                })),
                productViabilityDetails: productViabilityDetailsTwo,
                receivingChannel: "shipment",
                ...(customFields && customFields?.length >= 1 && { customFields: customFields }),
                ...(attachment?.length >= 1 && { attachments: attachment }),
                ...(stockShipmentData?.notes && { notes: stockShipmentData?.notes }),
                ...(stockShipmentData?.inspectionNote && {
                    inspectionNote: stockShipmentData?.inspectionNote,
                }),
                ...(stockShipmentData?.conditionNote && {
                    conditionNote: stockShipmentData?.conditionNote,
                }),
                quarantine: stockShipmentData?.quarantine || false,
            };
            dispatch(inventoryActions?.addMultipleProductToStockCount(body));
        } else {
            const { receivingChannel, canExpire, ...updateStockData } = stockData;
            const totalQuantityInWarehouses = updateStockData?.warehouses.reduce((acc, curr) => {
                return acc + Number(curr?.quantity);
            }, 0);
            if (totalQuantityInWarehouses !== Number(updateStockData?.totalQuantity)) {
                toast.custom((t) => (
                    <CustomToast
                        t={t}
                        message={`Total quantity in warehouses should be equal to total quantity specified - ${updateStockData?.totalQuantity}`}
                        type="error"
                    />
                ));
                return;
            }
            dispatch(inventoryActions?.addProductToStockCount(updateStockData));
        }
    };

    const validateBatchNo = async () => {
        try {
            const res = await dispatch(inventoryActions.validateBatchId(stockData?.batchId));
            if (res?.success) {
                handleSaveStock();
            } else {
                toast.custom((t) => <CustomToast t={t} message={res.message} type="error" />);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (attachment?.length > 0) {
            setStockData((prev) => {
                return {
                    ...prev,
                    attachments: attachment,
                };
            });
        }
    }, [attachment]);

    useEffect(() => {
        if (receivingChannel) {
            setStockShipmentData((prev) => {
                if (receivingChannel === "shipment" && shipmentId) {
                    return {
                        receivingChannel,
                        shipmentId,
                    };
                } else {
                    return {
                        receivingChannel,
                    };
                }
            });
            setProductViabilityDetails(null);
        }
    }, [receivingChannel, shipmentId]);

    useEffect(() => {
        const newArr = [...customFieldArray];
        const formatCustomFieldArray = newArr.map(
            ({ name, value, fieldType, mobileExtension, mobile, currency, amount, withValue }) => {
                return withValue
                    ? {
                          fieldType,
                          [name]:
                              fieldType === "currency"
                                  ? { currency, amount }
                                  : fieldType === "phone"
                                  ? { mobileExtension, mobile }
                                  : value,
                      }
                    : null;
            }
        );
        const getCustomFieldDataWithValues = formatCustomFieldArray?.filter(
            (item) => item !== null
        );

        getCustomFieldDataWithValues?.length > 0 && setCustomFields(getCustomFieldDataWithValues);
    }, [customFieldArray]);
    useEffect(() => {
        if (Boolean(channel)) {
            setReceivingChannel(channel);
        }
    }, [channel]);

    useEffect(() => {
        if (customFields?.length > 0) {
            setStockData((prev) => ({
                ...prev,
                customFields,
            }));
        }
    }, [receivingChannel]);

    useEffect(() => {
        if (customFields?.length > 0) {
            setStockData((prev) => ({
                ...prev,
                customFields,
            }));
        }
    }, [customFields]);

    useEffect(() => {
        if (productViabilityDetails) {
            setStockData((prev) => {
                return {
                    ...prev,
                    productViabilityDetails,
                };
            });
        }
    }, [productViabilityDetails]);

    useEffect(() => {
        if (addedProductToStockCountSuccess) {
            navigate(`/dashboard/inventory?currTab=${currTab}&stockTab=0`, {
                state: { internalNavigation: true },
            });
            toast.custom((t) => (
                <CustomToast t={t} message="Stock Count Added Successfully" type="success" />
            ));
            dispatch(inventoryActions.resetAddProductToStockCount());
        }
    }, [dispatch, addedProductToStockCountSuccess]);

    useEffect(() => {
        if (addedMultipleProductToStockCountSuccess) {
            navigate(`/dashboard/inventory?currTab=1&stockTab=0`, {
                state: { internalNavigation: true },
            });
            toast.custom((t) => (
                <CustomToast t={t} message="Stock Count Added Successfully" type="success" />
            ));
            dispatch(inventoryActions.resetAddMultipleProductToStockCount());
        }
    }, [dispatch, addedMultipleProductToStockCountSuccess]);
    useEffect(() => {
        if (Boolean(productId)) {
            setStockData((prev) => {
                return {
                    ...prev,
                    inventoryId: productId,
                };
            });
        }
    }, [productId]);
    useEffect(() => {
        if (Boolean(warehouseId)) {
            setStockData((prev) => {
                return {
                    ...prev,
                    warehouseId,
                };
            });
            setAllWarehouse((prev) => {
                return prev.map((item) => {
                    return { ...item, warehouseId };
                });
            });
        }
    }, [warehouseId]);
    
    return (
        <PageFrame containerClassName={""} isLoading={fetchingCustomField}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (stockData?.batchId && receivingChannel === "offline_purchase") {
                        validateBatchNo();
                    } else {
                        handleSaveStock();
                    }
                }}
            >
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100 top-[-20px] md:sticky z-[50]  bg-white">
                        <div className="flex items-center gap-2">
                            <button
                                className=""
                                onClick={() =>
                                    navigate(`/dashboard/inventory?currTab=${currTab}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                            >
                                <i className="ri-arrow-left-line text-gm-50"></i>
                            </button>
                            <p className="text-base font-rocGroteskMedium">
                                Add stock entry to a product
                            </p>
                        </div>

                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() =>
                                    navigate(`/dashboard/inventory?currTab=${currTab}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                                disabled={
                                    addingProductToStockCount ||
                                    addingMultipleProductToStockCount ||
                                    (receivingChannel === "offline_purchase" && validateBatchId)
                                }
                            />
                            {}
                            <Button
                                btnText="Save stock entry"
                                type="submit"
                                btnPermission={[
                                    "all_access",
                                    "add_stock",
                                    "transfer_stock",
                                    "create_inventory",
                                    "update_inventory",
                                ]}
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                disabled={
                                    addingProductToStockCount ||
                                    addingMultipleProductToStockCount ||
                                    (receivingChannel === "offline_purchase" && validateBatchId)
                                }
                                isLoading={
                                    addingProductToStockCount ||
                                    addingMultipleProductToStockCount ||
                                    (receivingChannel === "offline_purchase" && validateBatchId)
                                }
                            />
                        </div>
                    </div>
                    <div className="px-8 pb-8">
                        {receivingChannel === "offline_purchase" && (
                            <div className="pt-8">
                                <OfflinePurchase
                                    stockData={stockData}
                                    handleStockData={handleStockData}
                                    attachment={attachment}
                                    uploadingAttachment={uploadingAttachment}
                                    handleUpload={handleUpload}
                                    deleteAttachment={deleteAttachment}
                                    productViabilityDetails={productViabilityDetails}
                                    handleDateChange={handleDateChange}
                                    receivingChannel={receivingChannel}
                                    allWarehouse={allWarehouse}
                                    addMoreWarehouse={addMoreWarehouse}
                                    handleWarehouses={handleWarehouses}
                                    setStockData={setStockData}
                                />
                            </div>
                        )}
                    </div>
                    <div className="px-8 pb-8">
                        {receivingChannel === "shipment" && (
                            <Shipment
                                stockData={stockData}
                                setStockShipmentData={setStockShipmentData}
                                attachment={attachment}
                                uploadingAttachment={uploadingAttachment}
                                handleUpload={handleUpload}
                                deleteAttachment={deleteAttachment}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                setProductViabilityDetailsTwo={setProductViabilityDetailsTwo}
                                productViabilityDetailsTwo={productViabilityDetailsTwo}
                                stockShipmentData={stockShipmentData}
                            />
                        )}
                    </div>
                    <div className="px-8">{receivingChannel === "excel" && <ExcelFile />}</div>
                    {customFieldArray?.length > 0 && (
                        <p className="text-base font-rocGroteskMedium mb-4 px-8">Custom Fields</p>
                    )}
                    <div className=" px-8 mb-10">
                        <div className="w-[65%]">
                            {customFieldArray?.map((item, idx) => {
                                return (
                                    <div className="relative" key={idx}>
                                        <CustomFields
                                            displayCustomField={item}
                                            fieldType={item?.fieldType}
                                            required={false}
                                            className="!mb-4"
                                        />

                                        {
                                            <div className="">
                                                {item?.fieldType === "file" && item?.noValue && (
                                                    <div
                                                        className="flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200 cursor-pointer mb-4"
                                                        onClick={() => handleEditCustomField(idx)}
                                                    >
                                                        <i className="ri-file-add-line text-2xl text-carribean-green"></i>

                                                        <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                                            Click to add attachment
                                                        </p>
                                                    </div>
                                                )}
                                                <div className="flex items-center gap-4 absolute -right-[70px] top-0">
                                                    <i
                                                        className="ri-edit-line text-lg text-slate-400 cursor-pointer"
                                                        onClick={() => handleEditCustomField(idx)}
                                                    ></i>
                                                    <i
                                                        className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                        onClick={() => {
                                                            setDeleteCustomFieldModal(true);
                                                            setDeleteIdx(idx);
                                                            setSavedRecurringFieldId(item?._id);
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                );
                            })}

                            <div className="pb-8 !font-rocGroteskMedium mt-12">
                                <hr className="bg-slate-300" />
                                <div className="flex justify-center !-mt-5">
                                    <Button
                                        btnText="Add Custom Fields"
                                        btnClassname="!w-auto !bg-slate-50 !px-4 !py-[6px] !border-slate-300 !border !rounded-full"
                                        onClick={() => {
                                            setCustomModal(true);
                                            setFieldType("text");
                                            setFieldName("");
                                            setRecurringSetting(false);
                                            setEditCustomFieldIdx(-1);
                                        }}
                                        icon=<i className="ri-add-line text-base "></i>
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <CustomFieldsImplement
                openModal={customModal}
                closeModal={() => setCustomModal(!customModal)}
                fieldType={fieldType}
                fieldName={fieldName}
                recurringSetting={recurringSetting}
                editCustomFieldIdx={editCustomFieldIdx}
                setFieldType={setFieldType}
                setFieldName={setFieldName}
                setRecurringSetting={setRecurringSetting}
                setEditCustomFieldIdx={setEditCustomFieldIdx}
                multipleValuesField={multipleValuesField}
                singleValueField={singleValueField}
                customAttachments={customAttachments}
                setMultipleValuesField={setMultipleValuesField}
                setSingleValueField={setSingleValueField}
                setCustomAttachments={setCustomAttachments}
                openDeleteModal={() => setDeleteCustomFieldModal(true)}
                setDeleteIdx={setDeleteIdx}
                customFieldArray={customFieldArray}
                setCustomFieldArray={setCustomFieldArray}
                savedRecurringFieldId={savedRecurringFieldId}
                showDeleteButton={true}
            />

            <ModalContainer
                open={deleteCustomFieldModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteCustomFieldModal(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">
                            Delete Custom Field?
                        </h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to permanently remove this custom field? This
                            action will also delete associated content, and it cannot be undone.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={deletingCustomField}
                                onClick={() => setDeleteCustomFieldModal(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={() => {
                                    handleDeleteFromCustomFieldsArray(deleteIdx);
                                }}
                                btnText={"Delete"}
                                type={"button"}
                                isLoading={deletingCustomField}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default AddStock;
