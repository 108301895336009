import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import Loader from "components/common/Loader";
import moment from "moment";
import EmptyStateComp from "components/common/EmptyStateComp";
import { convertDaysToWeeks } from "helpers/convertDaysToWeeks";

interface Props {
    data: any;
    isFetching: boolean;
    pagination: any;
    isLoadingMore: boolean;
    isModalShow: boolean;
}
const PreviousAlerts = ({ data, isFetching, isModalShow, pagination, isLoadingMore }: Props) => {
    const navigate = useNavigate();

    return (
        <PageFrame isLoading={isFetching && Number(pagination.currentPage) === 1}>
            {data?.length > 0 ? (
                <div className="border border-slate-200 rounded">
                    {data?.map((item, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`p-4 ${
                                    idx < 5 && "border-b border-slate-200"
                                } flex items-start space-x-3 max-sm:flex-col max-sm:space-x-0 max-sm:space-y-2`}
                            >
                                <div
                                    className={`w-8 h-8 rounded-full ${
                                        item.incidentType?.toLowerCase() === "stockout"
                                            ? "bg-[#FEF2F2] border-[#FF0000] "
                                            : "bg-[#FFFBEB] border-[#FA9600] "
                                    } border  flex items-center justify-center`}
                                >
                                    <i
                                        className={`ri-alarm-warning-fill ${
                                            item.incidentType?.toLowerCase() === "stockout"
                                                ? "text-[#FF0000]"
                                                : "text-[#FA9600]"
                                        } text-[17px]`}
                                    ></i>
                                </div>
                                <div className="w-full">
                                    <div className="flex space-x-2 max-sm:mb-1">
                                        <span className="flex items-center text-g-75 text-sm font-rocGroteskMedium mb-1">
                                            <p
                                                className={`h-2 w-2 rounded-full max-sm:hidden ${
                                                    item.incidentType.toLowerCase() === "stockout"
                                                        ? "bg-[#FF3347]"
                                                        : "bg-[#FA9600]"
                                                } mr-1.5`}
                                            ></p>
                                            {idx + 1} Alert - Potential{" "}
                                            {item.incidentType.toLowerCase() === "stockout"
                                                ? "stockout"
                                                : "overstocking"}{" "}
                                            for{" "}
                                            {item?.inventory?.productName?.charAt(0).toUpperCase() +
                                                item?.inventory?.productName.slice(1)}
                                        </span>
                                        <div
                                            className={` ${
                                                item.incidentType?.toLowerCase() === "stockout"
                                                    ? "bg-[#FEF2F2] border-[#FF0000]"
                                                    : "bg-[#FFFBEB] border-[#FA9600]"
                                            } flex items-center border  py-[1px] rounded-[3px] max-sm:h-[30px] px-[6px]`}
                                        >
                                            <p
                                                className={`text-xs font-rocGroteskMedium ${
                                                    item?.incidentType.toLowerCase() === "stockout"
                                                        ? "text-[#DC2626] "
                                                        : "text-[#EA580C]"
                                                }`}
                                            >
                                                {item?.incidentType?.charAt(0).toUpperCase() +
                                                    item?.incidentType.slice(1) || "----"}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="block text-slate-500 text-sm font-rocGroteskMedium mb-2">
                                        {/* Stockout qty: {formatMoney().format(item?.qty) || 0}{" "}
                                        {item?.inventory?.productStockDetails?.unitOfMeasurement ||
                                            "N/A"}{" "} */}
                                        Potential revenue loss:{" "}
                                        {getCurrencyFromCurrencyCode(item?.inventory?.currency)}
                                        {formatMoney().format(
                                            item?.inventory?.unitCost?.amount * item?.qty
                                        ) || 0}{" "}
                                        | Forecasted stock needed:{" "}
                                        {formatMoney().format(
                                            Number(item?.forecastedQty?.toFixed(0))
                                        ) || 0}{" "}
                                        {item?.inventory?.productStockDetails?.unitOfMeasurement ||
                                            "N/A"}
                                    </span>
                                    {isModalShow ? (
                                        <div className="flex w-full items-center justify-between">
                                            <div className="flex space-x-3 items-center max-lg:grid max-lg:grid-cols-2  max-lg:gap-4">
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                                                    {item?.risk === "high" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_2_h0x1km.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "medium" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_mlbycq.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "low" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_1_kxmng6.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.risk} risk
                                                    </span>
                                                </div>
                                                <div className="flex items-center max-lg:shrink-0 space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Reported {moment(item?.createdAt).fromNow()}
                                                    </span>
                                                </div>
                                                <div className="flex items-center max-lg:shrink-0 space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Stockout date"
                                                            : "Overstocking date"}
                                                        :{" "}
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`
                                                            : `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`}
                                                    </span>
                                                </div>
                                                <div className="flex items-center max-lg:shrink-0 space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Forecasted stock duration"
                                                            : "Overstocking duration"}
                                                        : {convertDaysToWeeks(item?.forecastLength)}
                                                    </span>
                                                </div>
                                                <div className="flex items-center max-lg:shrink-0 space-x-1.5 w-fit">
                                                    <i className="ri-check-fill text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.status}
                                                    </span>
                                                </div>
                                                <div className="flex items-center max-lg:shrink-0 space-x-1.5 w-fit">
                                                    <i className="ri-map-pin-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Target - {item?.warehouse?.warehouseName}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* <div className="flex items-center space-x-1.5 w-fit">
                                  <VendorAvatar
                                      name="Augustine Asiuwhu"
                                      imageSrc=""
                                      size={20}
                                      textClassname="!text-[8px]"
                                  />
                                  <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                      Augustine Asiuwhu
                                  </span>
                              </div> */}
                                            <div>
                                                {/* <i className="ri-arrow-right-s-line text-slate-500 text-lg"></i> */}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col  w-full items-center justify-between">
                                            <div className="grid grid-cols-2 gap-2  items-center max-lg:grid max-lg:grid-cols-2 max-lg:ml-[-29px] max-lg:w-[calc(100%+29px)] max-lg:gap-4">
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                                                    {item?.risk === "high" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_2_h0x1km.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "medium" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_mlbycq.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "low" && (
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/bar-chart-2-fill_1_kxmng6.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.risk} risk
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-full">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Reported {moment(item?.createdAt).fromNow()}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-full">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Stockout date:{" "}
                                                        {moment(item?.stockoutDate).format(
                                                            "DD MMM YYYY"
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 space-x-1.5 w-full">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Forecasted stock duration:{" "}
                                                        {convertDaysToWeeks(item?.forecastLength)}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-full">
                                                    <i className="ri-check-fill text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.status}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-full">
                                                    <i className="ri-map-pin-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Target - {item?.warehouse?.warehouseName}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="flex mt-1  w-full"> */}

                                            {/* <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                        <VendorAvatar
                                                            name="Augustine Asiuwhu"
                                                            imageSrc=""
                                                            size={20}
                                                            textClassname="!text-[8px]"
                                                        />
                                                        <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                            Augustine Asiuwhu
                                                        </span>
                                                    </div> */}
                                            {/* </div> */}
                                            <div>
                                                {/* <i className="ri-arrow-right-s-line text-slate-500 text-lg"></i> */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {isFetching && isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (
                <EmptyStateComp
                    title="No previous incidents"
                    description="There are no previous incidents to review. Your system has been running without issues."
                    icon={<i className="ri-alarm-warning-line text-slate-500 text-[40px]"></i>}
                />
            )}
        </PageFrame>
    );
};

export default PreviousAlerts;
