import React, { useEffect, useRef, useState } from "react";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import millify from "millify";
import { Tooltip } from "react-tooltip";
import CampaignComments from "../../../../pages/Synth2.0/MarketingCampaign/CampaignDetail/CampaignComments/index";
import { useAppDispatch } from "../../../../hooks/storeHooks";
import Loader from "../../../common/Loader";
import GeneratingLoader from "../../../common/GeneratingLoader";
import { Mention, MentionsInput } from "react-mentions";
import { ChannelProvider } from "ably/react";
import { formatMoney } from "../../../../helpers";
import moment from "moment";
import { cloneDeep, uniqBy } from "lodash";

type CampaignProps = {
    values: any;
    isSaved: boolean;
    campaignDetail: any;
    creatingCampaignComment?: boolean;
    parentComments?: any;
    handleDebouncedChange?: any;
    isFetchingTeamList?: boolean;
    teamList?: any;
    fetchingCampaignComment?: boolean;
    createCampaignCommentSuccess?: boolean;
    sectionComments?: any;
    sentimentBubbleChartData?: any;
    selectedSentimentTab?: number;
    setSelectedSentimentTab?: React.Dispatch<React.SetStateAction<number>>;
    handleAddComment?: any;
    getSectionCommentsCount?: any;
    scenerio?: string;
    setIsResolvedTab?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMentionTab?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CampaignSimulationDetails = ({
    values,
    creatingCampaignComment,
    parentComments,
    handleDebouncedChange,
    campaignDetail,
    teamList,
    fetchingCampaignComment,
    createCampaignCommentSuccess,
    sectionComments,
    sentimentBubbleChartData,
    selectedSentimentTab,
    setSelectedSentimentTab,
    handleAddComment,
    getSectionCommentsCount,
    isSaved,
    scenerio,
    setIsResolvedTab,
    setIsMentionTab,
}: CampaignProps) => {
    const navigate = useNavigate();
    const newMsgContainerRef = useRef(null);

    const [activeTab, setActiveTab] = useState<number>(0);
    const [msg, setMsg] = useState<string>("");
    const [activeCommentSection, setActiveCommentSection] = useState<number | null>(null);
    const [selectedSection, setSelectedSection] = useState(0);
    const [activeCommentTab, setActiveCommentTab] = useState(0);
    const [skuId, setSkuId] = useState<number>(0);
    const [inventory, setInventory] = useState<string>("");
    const [estimatedRevenueData, setEstimatedRevenueData] = useState<number>(0);
    const [lastDayStockLevelData, setLastDayStockLevelData] = useState<number>(0);
    const [campaignForecastedDemandData, setCampaignForecastedDemandData] = useState<number>(0);
    const [stockNeedsData, setStockNeedsData] = useState<number>(0);
    const [stockoutDate, setStockOutDate] = useState<string>("");
    const [forecastedSalesGraphData, setForecastedSalesGraphData] = useState<{
        [key: string]: any;
    }>({});
    console.log("campaignDetail", campaignDetail, campaignDetail?.predictions?.length);
    const totalforcastedProductDemand = values?.suggestedProducts?.reduce((acc, curr) => {
        const matchedInventory = campaignDetail?.predictions?.find(
            (pred) =>
                pred.inventoryId === curr?.inventoryId &&
                pred.scenario === (scenerio || "most likely")
        );

        const totalY =
            matchedInventory?.predictions?.reduce((sum, pred) => {
                return sum + (pred.y || 0);
            }, 0) || 0;

        return acc + totalY;
    }, 0);

    const totalInventoryForecast = values?.suggestedProducts?.reduce((acc, curr) => {
        const matchedInventory = campaignDetail?.predictions?.find(
            (pred) =>
                pred.inventoryId === curr?.inventoryId &&
                pred.scenario === (scenerio || "most likely")
        );

        const totalStockLevel =
            matchedInventory?.predictions?.reduce((sum, pred) => {
                return sum + (pred.stockLevel || 0);
            }, 0) || 0;

        return acc + totalStockLevel;
    }, 0);

    const potentialRevenue = values?.campaignInventoryForeastInsight?.reduce((acc, curr, idx) => {
        const totalPredictedDemand =
            curr?.predictions?.reduce((sum, pred) => sum + (Math.abs(pred?.y) || 0), 0) || 0;
        const matchingProduct = values?.suggestedProducts?.find(
            (product) => product?.inventory?._id === curr?.inventoryId
        );
        const productPrice = matchingProduct?.inventory?.salesPrice?.amount || 0;

        return acc + totalPredictedDemand * productPrice;
    }, 0);

    const potentialLossFromStockout = values?.suggestedProducts?.reduce((acc, product) => {
        // Find matching prediction data for this product
        const matchingPrediction = campaignDetail?.predictions?.find(
            (pred) =>
                pred.inventoryId === product?.inventory?._id &&
                pred.scenario === (scenerio || "most likely")
        );

        if (!matchingPrediction?.predictions) return acc;

        // Get current date
        const today = new Date();

        // Filter predictions from today onwards and find first stockout
        const futureStockouts = matchingPrediction.predictions.filter(
            (pred) => new Date(pred.date) >= today
        );

        const firstStockoutIndex = futureStockouts.findIndex((pred) => pred.stockLevel === 0);

        if (firstStockoutIndex === -1) return acc;

        // Sum all stockouts from first stockout onwards
        const totalStockouts = futureStockouts
            .slice(firstStockoutIndex)
            .reduce((sum, pred) => sum + pred.y, 0);

        // Calculate revenue loss for this product
        const productPrice = product?.inventory?.salesPrice?.amount || 0;
        const revenueLoss = totalStockouts * productPrice;

        return acc + revenueLoss;
    }, 0);

    const forecastedDemand = campaignDetail?.predictions?.reduce(
        (acc, curr) =>
            acc +
            (curr?.predictions?.reduce((sum, pred) => sum + (Math.abs(pred?.y) || 0), 0) || 0),
        0
    );

    const startDate = moment(values?.campaignStartDate).format("YYYY-MM-DD");
    const endDate = moment(values?.campaignEndDate).format("YYYY-MM-DD");

    useEffect(() => {
        setInventory(values?.suggestedProducts?.[0]?.inventoryId);
        setStockOutDate("");
    }, [values]);

    useEffect(() => {
        if (inventory) {
            // getInventoryDetails(inventory);
            setForecastedSalesGraphData({});
            setEstimatedRevenueData(0);
            setLastDayStockLevelData(0);
            setCampaignForecastedDemandData(0);
            setStockNeedsData(0);
            setStockOutDate("");
            getInventoryDetails(inventory);
        }
    }, [inventory, scenerio, values]);

    const getInventoryDetails = (inventoryId: string) => {
        if (Array?.isArray(campaignDetail?.predictions)) {
            const copyArr = [...campaignDetail?.predictions];
            const data = copyArr?.find(
                (obj: any) =>
                    obj.inventoryId === inventoryId &&
                    (scenerio ? obj.scenario === scenerio : obj.scenario === "most likely")
            );
            estimatedRevenue(data);
            getLastDayStockLevel(data);
            campaignForecastedDemand(data);
            potentialRevenueLoss(data);
            getForecastedSalesGraph(data);
        }
    };

    const getForecastedSalesGraph = (inventoryData: any) => {
        const forecastedSalesGraph = {};
        if (!inventoryData?.predictions) {
            return forecastedSalesGraph;
        }

        const startDates = new Date(startDate);
        const endDates = new Date(endDate);
        const dateArray = [];

        for (let date = new Date(startDates); date <= endDates; date.setDate(date.getDate() + 1)) {
            dateArray.push(new Date(date));
        }

        const formattedDates = dateArray.map((date) => moment(date)?.format("MM/DD/YYYY"));

        const inventoryCost = values?.suggestedProducts?.find((product) => {
            return product?.inventoryId === inventoryData?.inventoryId;
        })?.inventory?.salesPrice?.amount;

        const salesArray = formattedDates.map((date) => {
            const match = inventoryData?.salesData?.find(
                (item) => moment(item?.date)?.format("MM/DD/YYYY") === date
            );

            return (match?.totalQtySold || 0) * inventoryCost;
        });

        const forecastArray = formattedDates.map((date) => {
            const match = inventoryData?.predictions?.find(
                (item) => moment(item?.date)?.format("MM/DD/YYYY") === date
            );

            return (match?.y || 0) * inventoryCost;
        });

        setForecastedSalesGraphData({
            forecast: forecastArray,
            date: formattedDates,
            sales: salesArray,
        });
    };

    const estimatedRevenue = (inventoryData: any) => {
        let sum = 0;
        let startLoop = false;
        if (!inventoryData?.predictions) {
            return sum;
        }
        for (let obj of inventoryData?.predictions) {
            if (obj?.date === startDate || obj?.date) {
                startLoop = true;
            }
            if (startLoop) {
                if (obj.stockLevel === 0) {
                    setStockOutDate(obj?.date);
                    break;
                }
                sum += obj.stockLevel;
            }
        }
        setEstimatedRevenueData(sum);
        return sum;
    };

    const getLastDayStockLevel = (inventoryData: any) => {
        if (!inventoryData?.predictions) {
            return 0;
        }
        const stockLevel = Array?.isArray(inventoryData?.predictions)
            ? inventoryData?.predictions?.find(
                  (obj: any, idx: number) =>
                      obj.date === endDate || inventoryData?.predictions?.length - 1
              )?.stockLevel
            : 0;
        setLastDayStockLevelData(stockLevel);
        return stockLevel;
    };

    const campaignForecastedDemand = (inventoryData: any) => {
        // sales during campaign

        const sales = Array?.isArray(inventoryData?.predictions)
            ? inventoryData?.predictions?.reduce((sum, item) => {
                  return sum + item?.y;
              }, 0)
            : 0;
        setCampaignForecastedDemandData(sales);
        return sales;
    };

    const potentialRevenueLoss = (inventoryData: any) => {
        if (!inventoryData?.predictions) {
            return;
        }
        if (Array?.isArray(inventoryData?.predictions)) {
            const filterNoStockIndex = inventoryData?.predictions?.findIndex(
                (item) => item?.stockLevel === 0
            );
            const filterNoStock = inventoryData?.predictions?.slice(filterNoStockIndex);
            const loss = filterNoStock?.reduce((sum, item) => {
                return sum + item?.y;
            }, 0);
            setStockNeedsData(loss);
        }
    };

    const inventoryForecast = campaignDetail?.predictions?.reduce((acc, curr) => {
        const totalPredictedDemand =
            curr?.predictions?.reduce((sum, pred) => sum + (Math.abs(pred?.y) || 0), 0) || 0;
        const matchingProduct = values?.suggestedProducts?.find(
            (product) => product?.inventory?._id === curr?.inventoryId
        );
        const stockLevel = matchingProduct?.inventory?.productStockDetails?.stockLevel || 0;
        return acc + (totalPredictedDemand - stockLevel);
    }, 0);

    const campaignRecommendation = uniqBy(
        [
            ...(values?.campaignForecastRecommendation || [])?.map((recommendation) => {
                return {
                    ...recommendation,
                    uniqueId: recommendation?.action + recommendation?.inventoryId,
                };
            }),
        ],
        "uniqueId"
    );

    const latestCampaignTasks = cloneDeep(campaignDetail?.aiAgentTasks)?.sort(
        (a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
    )[0];

    const mostLikelyPredictions = campaignDetail?.predictions?.filter(
        (pred) => pred.scenario === (scenerio || "most likely")
    );

    const totalSales = mostLikelyPredictions?.reduce((acc, curr) => {
        return acc + curr?.predictions?.reduce((sum, pred) => sum + (pred?.sales || 0), 0);
    }, 0);

    const sellThroughRate =
        (totalSales / mostLikelyPredictions?.[0]?.predictions?.[0]?.sales) * 100;

    const optionOne = {
        grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
        xAxis: {
            type: "category",

            data: campaignDetail?.predictions
                ?.find((pred) => pred.scenario === (scenerio || "most likely"))
                ?.predictions?.map((item) => moment(item?.date).format("MM/DD")),
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                color: "#89939b",
                // align: "center",
                rotate: 80,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#E2E8F0",
                },
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },
        },
        axisLine: {
            lineStyle: {
                color: "#E2E8F0",
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                show: false,
                color: "#89939b",
                // formatter: "${value}",
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#E2E8F0",
                },
            },
        },

        series: [
            {
                name: "Inventory forecast",

                data: (() => {
                    if (!mostLikelyPredictions?.length) return [];

                    // Get all unique dates
                    const dates = mostLikelyPredictions[0]?.predictions?.map((p) => p.date);

                    // For each date, sum up stockLevel - y across all predictions
                    return (
                        dates?.map((date) => {
                            return mostLikelyPredictions.reduce((sum, pred) => {
                                const matchingPrediction = pred.predictions?.find(
                                    (p) => p.date === date
                                );
                                return sum + (matchingPrediction?.stockLevel || 0);
                            }, 0);
                        }) || []
                    );
                })(),
                type: "line",
                // symbolSize: 5,
                // symbol: "circle",
                showSymbol: false,
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#22D3EE",
                    type: "line",
                },
                areaStyle: {
                    opacity: 0,
                    // color: "#00C6FA0D",
                },
            },
            {
                name: "Forecasted demand",
                data: (() => {
                    if (!mostLikelyPredictions?.length) return [];

                    // Get all unique dates
                    const dates = mostLikelyPredictions[0]?.predictions?.map((p) => p.date);

                    // For each date, sum up stockLevel - y across all predictions
                    return (
                        dates?.map((date) => {
                            return mostLikelyPredictions.reduce((sum, pred) => {
                                const matchingPrediction = pred.predictions?.find(
                                    (p) => p.date === date
                                );
                                return sum + (matchingPrediction?.y || 0);
                            }, 0);
                        }) || []
                    );
                })(),
                type: "line",
                // symbolSize: 5,
                // symbol: "circle",
                showSymbol: false,
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#FC1D8B",
                    type: "line",
                },
                areaStyle: {
                    opacity: 0,
                    // color: "#FF3D1D0D",
                },
            },
        ],
        color: ["#22D3EE", "#FC1D8B"],
        tooltip: {
            trigger: "axis",
            backgroundColor: "#fff",
            textStyle: {
                color: "#000",
            },

            axisPointer: {
                snap: true,
                type: "line",
                lineStyle: {
                    color: "#CBD5E1",
                    width: 1,
                    type: "line",
                },
                show: true,
                z: 10,
            },
        },
    };

    const optionTwo = {
        grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
        xAxis: {
            type: "category",
            data: forecastedSalesGraphData?.date,
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                color: "#89939b",
                // align: "center",
                // rotate: 70,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#E2E8F0",
                },
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },
        },
        axisLine: {
            lineStyle: {
                color: "#E2E8F0",
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                show: false,
                color: "#89939b",
                // formatter: "${value}",
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#E2E8F0",
                },
            },
        },
        series: [
            {
                name: "Actual sales",
                data: forecastedSalesGraphData?.sales,
                type: "line",
                // symbolSize: 5,
                // symbol: "circle",
                showSymbol: false,
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#22D3EE",
                    type: "line",
                },
                areaStyle: {
                    // opacity: 0.8,
                    color: "#00C6FA0D",
                },
            },
            {
                name: "Forecasted demand",
                data: forecastedSalesGraphData?.forecast,
                type: "line",
                // symbolSize: 5,
                // symbol: "circle",
                showSymbol: false,
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#FC1D8B",
                    type: "line",
                },
                areaStyle: {
                    // opacity: 0.8,
                    color: "#FF3D1D0D",
                },
            },
        ],
        color: ["#22D3EE", "#FC1D8B"],
        tooltip: {
            trigger: "axis",
            backgroundColor: "#fff",
            textStyle: {
                color: "#000",
            },
            formatter: function (params) {
                return `
                <div>
                    <p style="margin-bottom: 10px">${params?.[0]?.name}</p>
                    <div style="display:flex ; align-items: center; gap: 10px; margin-bottom: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.[0]?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.[0]?.seriesName}</span>
                        </div> 

                        <span style="font-weight: 600;">$${formatMoney().format(
                            params?.[0]?.value
                        )}</span>
                    </div>
                    <div style="display:flex ; align-items: center; gap: 10px; margin-bottom: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.[1]?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.[1]?.seriesName}</span>
                        </div> 

                        <span style="font-weight: 600;">$${formatMoney().format(
                            params?.[1]?.value
                        )}</span>
                    </div>
                </div>
                `;
            },
            axisPointer: {
                snap: true,
                type: "line",
                lineStyle: {
                    color: "#CBD5E1",
                    width: 1,
                    type: "line",
                },
                show: true,
                z: 10,
            },
        },
    };

    const bubbleChartOption = {
        tooltip: {
            show: true,
            formatter: function (params) {
                return `<div class="chart"><span style="font-size: 14px;">${params?.value[3]}</span></div>`;
            },
        },
        xAxis: {
            show: false, // Hide the x-axis
            type: "value",
            min: 0,
            max: 300, // Adjust the range to ensure space for the bubbles
        },
        yAxis: {
            show: false, // Hide the y-axis
            type: "value",
            min: 0,
            max: 150, // Adjust the range to center the bubbles vertically
        },
        series: [
            {
                type: "scatter",
                symbolSize: (data) => {
                    // return (data[2] / totalRegionalRevenue) * 100 * 2.5;
                    // return Math.sqrt(data[2]) / 500;
                    return data[2] * 1.2;
                },
                data: sentimentBubbleChartData?.map((keyword, idx) => {
                    return {
                        value: keyword,
                        // itemStyle: { color: COLORS[idx] },
                        itemStyle: { color: "#7F7CE9" },
                        // label: { color: idx === 0 || idx === 3 ? "white" : "black" },
                        label: { color: "white" },
                    };
                }),
                label: {
                    show: true,
                    formatter: (params) => `${params.value[3]}`,
                    // color: "white",
                    fontWeight: "bold",
                    fontSize: 12,
                },
                itemStyle: {
                    color: "#6562E5", // Adjust bubble color (or use gradient if needed)
                    opacity: 0.8,
                    shadowBlur: 10,
                    shadowColor: "rgba(0, 0, 0, 0.2)",
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1,
                        shadowBlur: 15,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };

    const campaignSection = {
        "section 1": (
            <div className="group relative cursor-pointer">
                {isSaved && (
                    <div className="bg-white rounded-[10px] border border-slate-100 cursor-pointer">
                        <div className="p-4 flex justify-between items-center border-b border-slate-100">
                            <div className="flex items-center space-x-4 w-1/2">
                                <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                    <i className="ri-calendar-todo-line text-xl text-y-10"></i>
                                </div>

                                <div>
                                    <p className="text-xs text-gm-30">Campaign schedule</p>
                                    <p className="text-base ">
                                        {values?.campaignStartDate && values?.campaignEndDate
                                            ? moment(values?.campaignStartDate).format(
                                                  "DD MMM, YYYY"
                                              ) +
                                              " - " +
                                              moment(values?.campaignEndDate).format("DD MMM, YYYY")
                                            : ""}
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-center space-x-4 w-1/2">
                                <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                    <i className="ri-route-line text-xl text-y-10"></i>
                                </div>

                                <div>
                                    <p className="text-xs text-gm-30">Campaign source</p>
                                    <p className="text-base flex items-center space-x-1.5">
                                        <span className="w-[26px] h-[26px] flex items-center justify-center rounded-full bg-white shadow-[0px_1px_2px_0px_#00000012]">
                                            <img
                                                src={
                                                    campaignDetail?.channel === "google"
                                                        ? "https://storage.googleapis.com/heysynth_files/app/google_ads.svg"
                                                        : campaignDetail?.channel === "tiktok"
                                                        ? "https://storage.googleapis.com/heysynth_files/app/tiktokIcon.svg"
                                                        : campaignDetail?.channel === "meta"
                                                        ? "https://storage.googleapis.com/heysynth_files/synth_webapp/meta.svg"
                                                        : campaignDetail?.channel === "klaviyo" &&
                                                          "https://storage.googleapis.com/heysynth_files/app/klaviyo_logo.svg.svg"
                                                }
                                                alt="channel icon"
                                                width={13}
                                                height={17}
                                            />
                                        </span>
                                        <span>{campaignDetail?.channel}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 flex justify-between items-center">
                            <div className="flex items-center space-x-4 w-1/2">
                                <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                    <i className="ri-money-dollar-circle-line text-xl text-y-10"></i>
                                </div>

                                <div>
                                    <p className="text-xs text-gm-30">Campaign spend</p>
                                    <p className="text-base ">N/A {/* this is a dollar value */}</p>
                                </div>
                            </div>

                            <div className="flex items-center space-x-4 w-1/2">
                                <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                    <i className="ri-arrow-turn-back-line text-xl text-y-10"></i>
                                </div>

                                <div>
                                    <p className="text-xs text-gm-30">Campaign conversation rate</p>
                                    <p className="text-base flex items-center space-x-2">
                                        {/* <span>12-d%</span>
                                        <span className="text-[#DC2626] text-xs px-1.5 border border-[#DC2626] rounded-[3px]">
                                            Lower than campaign spend
                                        </span> */}
                                        N/A {/* this is a dollar value */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => {
                                        // setActiveTab(1);
                                        setActiveCommentSection(1);
                                    }}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 1 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-38%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    // onKeyUp={(e) => {
                                    //     adjustHeight(e.target);
                                    // }}
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(1, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(1, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(1) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(1);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[20%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(1)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 2": (
            <div className="group relative cursor-pointer">
                <div className="relative">
                    <div className="gap-[10px] flex items-center flex-wrap mb-8">
                        {values?.suggestedProducts?.map((product) => {
                            const matchedInventory = campaignDetail?.predictions?.find(
                                (pred) =>
                                    pred.inventoryId === product?.inventoryId &&
                                    pred.scenario === (scenerio || "most likely")
                            );

                            const totalY =
                                matchedInventory?.predictions?.reduce((sum, pred) => {
                                    return sum + (pred.y || 0);
                                }, 0) || 0;

                            return (
                                <p
                                    key={product?.inventoryId}
                                    className="flex items-center text-sm border border-[#B8B8B8] rounded px-2 py-[2px] "
                                >
                                    <span className="text-slate-500">
                                        {product?.inventory?.sku}: {formatMoney().format(totalY)}{" "}
                                        units
                                    </span>{" "}
                                    {/* <span className="text-r-50">, Potential Stockout Detected:</span>{" "}
                            <span className="text-r-50 font-rocGroteskBold">20 Jan,2025</span> */}
                                </p>
                            );
                        })}
                    </div>

                    <div className="flex items-center overflow-x-auto space-x-2 mb-8">
                        <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                            <span className="text-slate-400 block mb-1">Potential revenue</span>
                            <span className="text-slate-500 block font-rocGroteskBold">
                                ${formatMoney().format(potentialRevenue)}
                            </span>
                        </div>

                        <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                            <span className="text-slate-400 block mb-1">Sell Through Rate</span>
                            <span className="text-slate-500 block font-rocGroteskBold">
                                {formatMoney().format(sellThroughRate)}%
                            </span>
                        </div>

                        <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                            <span className="text-slate-400 block mb-1">
                                Potential revenue loss due to likely stockout
                            </span>
                            <span className="text-slate-500 block font-rocGroteskBold">
                                ${formatMoney().format(potentialLossFromStockout)}
                            </span>
                        </div>

                        <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                            <span className="text-slate-400 block mb-1">
                                Discount-Adjusted Gross Margin
                            </span>
                            <span className="text-slate-500 block font-rocGroteskBold">N/A</span>
                        </div>
                    </div>

                    {campaignDetail?.predictions?.length === 0 && (
                        <div className="absolute bottom-[8px] left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                            <div className="text-sm w-10">
                                <GeneratingLoader />
                            </div>

                            <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                Generating results...
                            </p>
                        </div>
                    )}
                </div>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(2)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 2 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    // onKeyUp={(e) => {
                                    //     adjustHeight(e.target);
                                    // }}
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(2, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(2, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(2) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(2);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[20%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(2)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 3": (
            <div className="group relative cursor-pointer min-h-[350px] bg-white rounded-[10px] shadow-[1px_-6px_15px_0px_#00000005]">
                <div className="px-[14px] py-5 border-b border-slate-300 mb-3">
                    <span className="text-[15px]">Inventory forecast vs Forecasted demand</span>
                </div>

                <div className="flex items-center space-x-8 px-[13px] mb-3">
                    <div>
                        <div className="flex items-center space-x-1.5 mb-1.5">
                            <span className="rounded-full w-2 h-2 bg-[#22D3EE]"></span>
                            <span className="text-xs text-slate-500">Inventory forecast</span>
                        </div>
                        <span className="block text-g-75 text-base ">
                            {millify(totalInventoryForecast || 0, { precision: 2 })}
                        </span>
                    </div>

                    <div>
                        <div className="flex items-center space-x-1.5 mb-1.5">
                            <span className="rounded-full w-2 h-2 bg-[#FC1D8B]"></span>
                            <span className="text-xs text-slate-500">Forecasted demand</span>
                        </div>
                        <span className="block text-g-75 text-base ">
                            {millify(totalforcastedProductDemand || 0, {
                                precision: 2,
                            })}
                        </span>
                    </div>
                </div>

                <div className="mb-8">
                    {mostLikelyPredictions?.length > 0 ? (
                        <ReactEcharts option={optionOne} />
                    ) : (
                        <div className="relative">
                            <div className="flex items-center justify-center h-full my-10">
                                <p className="text-slate-500 text-xl text-center">
                                    No data available for at this time
                                </p>
                            </div>

                            <div className="absolute left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                                <div className="text-sm w-10">
                                    <GeneratingLoader />
                                </div>

                                <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                    Generating results...
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(3)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 3 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(3, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(3, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(3) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(3);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[20%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(3)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 4": (
            <div className="group relative cursor-pointer">
                <ul className="list-disc text-xs text-slate-500 space-y-1.5 ml-3">
                    {[...campaignRecommendation]?.map((recommendation, idx) => {
                        let text = recommendation?.recommendation;

                        // Split snake_case action into individual words
                        const actionWords =
                            recommendation?.action?.split("_").map((word) => word.toLowerCase()) ||
                            [];

                        // Replace stock level occurrences first
                        text = text.replace(/stock\s+level/gi, (match) => `<u>${match}</u>`);

                        // Then replace individual action words
                        actionWords.forEach((word) => {
                            if (word && word.length > 0) {
                                // Create regex that matches whole word case-insensitively
                                const regex = new RegExp(`\\b${word}\\b`, "gi");
                                text = text.replace(regex, (match) => `<u>${match}</u>`);
                            }
                        });

                        // Format numbers with commas if not already formatted
                        text = text.replace(/\$?(\d+(?:,\d+)*|\d+)(?!\d)/g, (match) => {
                            // Keep any leading $ symbol
                            const hasPrefix = match.startsWith("$");
                            const num = match.replace(/[$,]/g, "");

                            // Skip if number already has proper formatting
                            if (
                                match ===
                                (hasPrefix ? "$" : "") +
                                    new Intl.NumberFormat("en-US").format(Number(num))
                            ) {
                                return match;
                            }

                            return (
                                (hasPrefix ? "$" : "") +
                                new Intl.NumberFormat("en-US").format(Number(num))
                            );
                        });

                        return <li key={idx} dangerouslySetInnerHTML={{ __html: text }} />;
                    })}
                </ul>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(4)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 4 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(4, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(4, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(4) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(4);
                            setActiveTab(1);
                        }}
                        className="absolute right-[0] top-[-70%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(4)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 5": (
            <div className="group relative cursor-pointer">
                <div className="min-h-[350px] bg-white rounded-[10px] shadow-[1px_-6px_15px_0px_#00000005]">
                    <div className="px-[14px] py-5 border-b border-slate-300 mb-3 flex items-center justify-between">
                        <span className="text-[15px]">Sales trend</span>

                        {/* <div className="border border-[#4EFF92] flex gap-2 items-center py-1 px-2 rounded-lg">
                            <span className="w-6 h-6 flex items-center justify-center rounded bg-g-70">
                                <i className="ri-line-chart-line text-xs text-white"></i>
                            </span>

                            <p className="text-[#009A3B] font-rocGroteskRegular text-xs">
                                sales lift:
                                <span className="font-rocGroteskMedium">150%</span>
                            </p>
                        </div> */}
                    </div>

                    <div className="flex items-center space-x-8 px-[13px] mb-3">
                        <div>
                            <div className="flex items-center space-x-1.5 mb-1.5">
                                <span className="rounded-full w-2 h-2 bg-[#22D3EE]"></span>
                                <span className="text-xs text-slate-500">Actual sales</span>
                            </div>
                            <span className="block text-g-75 text-base ">
                                $
                                {formatMoney()?.format(
                                    forecastedSalesGraphData?.sales?.reduce(
                                        (acc, curr) => acc + curr,
                                        0
                                    )
                                )}
                            </span>
                        </div>

                        <div>
                            <div className="flex items-center space-x-1.5 mb-1.5">
                                <span className="rounded-full w-2 h-2 bg-[#FC1D8B]"></span>
                                <span className="text-xs text-slate-500">Forecasted demand</span>
                            </div>
                            <span className="block text-g-75 text-base ">
                                $
                                {formatMoney()?.format(
                                    forecastedSalesGraphData?.forecast?.reduce(
                                        (acc, curr) => acc + curr,
                                        0
                                    )
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="mb-8 px-2">
                        {forecastedSalesGraphData?.sales?.length > 0 ||
                        forecastedSalesGraphData?.forecast?.length > 0 ? (
                            <ReactEcharts option={optionTwo} />
                        ) : (
                            <div className="relative">
                                <div className="flex items-center justify-center h-full my-10">
                                    <p className="text-slate-500 text-xl text-center">
                                        No data available for at this time
                                    </p>
                                </div>

                                <div className="absolute left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                                    <div className="text-sm w-10">
                                        <GeneratingLoader />
                                    </div>

                                    <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                        Generating results...
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(5)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 5 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(5, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(5, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(5) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(5);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[20%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(5)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 6": (
            <div className="group/item relative cursor-pointer bg-white rounded-[10px] border border-slate-100 p-3">
                <div className="mb-1.5 flex items-center justify-between">
                    <span className="text-base">SKUs in this campaign</span>
                </div>

                <div className="space-x-1.5 flex items-center overflow-x-auto">
                    {values?.suggestedProducts?.map((sku, idx) => (
                        <p
                            key={idx}
                            className={` ${
                                skuId === idx ? "bg-slate-800" : "bg-white"
                            } flex items-center text-sm rounded px-2 py-[2px] shrink-0 group cursor-pointer`}
                            onClick={() => {
                                setSkuId(idx);
                                getInventoryDetails(sku?.inventoryId);
                            }}
                        >
                            <span className={` ${skuId === idx ? "text-white" : "text-slate-500"}`}>
                                {sku?.name} - SKU {sku?.inventory?.sku}
                            </span>
                        </p>
                    ))}
                </div>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[-15%] opacity-0 group-hover/item:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(6)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 6 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(6, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(6, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(6) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(6);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[18%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(6)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 7": (
            <div className="group relative cursor-pointer mt-6">
                <p className="text-lg mb-4">
                    Demand Summary <span className="text-sm">(for campaign duration)</span>
                </p>

                <div>
                    <div className="bg-white rounded-t-[10px] shadow-[1px_-6px_15px_0px_#00000005]">
                        <div className="px-[14px] py-3 border-b border-slate-100 mb-3 flex items-center justify-between">
                            <span className="text-xs text-slate-500">
                                SKU {values?.suggestedProducts?.[skuId]?.inventory?.sku} -{" "}
                                {values?.suggestedProducts?.[skuId]?.name}
                            </span>

                            {/* <div className="flex items-center space-x-2 bg-n-20 rounded-lg py-1.5 px-2">
                                <i className="ri-eye-fill text-base"></i>
                                <span className="text-[10px]">View reasoning source</span>
                            </div> */}
                        </div>

                        <div className="relative">
                            <div className=" flex">
                                <div className="w-1/2 border-r border-slate-100 p-4">
                                    <div className="space-x-3 mb-4 flex items-center">
                                        <div className="w-10 h-10 rounded-lg flex items-center justify-center bg-p-50">
                                            <i className="ri-store-2-line text-[22px] text-white"></i>
                                        </div>

                                        <div className="text-sm">
                                            <span className="block">Current Stock Level</span>
                                            <span className="text-slate-500 block">
                                                {formatMoney()?.format(
                                                    values?.suggestedProducts?.[skuId]?.inventory
                                                        ?.productStockDetails?.stockLevel
                                                )}{" "}
                                                units
                                            </span>
                                        </div>
                                    </div>

                                    <div className="text-xs mb-4">
                                        <span className="text-slate-500 block">
                                            Estimated revenue before potential stockout
                                        </span>
                                        <span className="text-slate-500 font-rocGroteskBold block">
                                            $
                                            {formatMoney()?.format(
                                                estimatedRevenueData *
                                                    values?.suggestedProducts?.[skuId]?.inventory
                                                        ?.salesPrice?.amount
                                            )}
                                        </span>
                                    </div>

                                    <div className="text-xs">
                                        <span className="text-slate-500 block">
                                            Inventory stock level post-campaign
                                        </span>
                                        <span className="text-slate-500 font-rocGroteskBold block">
                                            {formatMoney()?.format(lastDayStockLevelData)} units
                                        </span>
                                    </div>
                                </div>

                                <div className="w-1/2 p-4">
                                    <div className="space-x-3 mb-2.5 flex items-center">
                                        <div className="w-10 h-10 rounded-lg flex items-center justify-center bg-[#4FCB01]">
                                            <i className="ri-line-chart-line text-[22px] text-white"></i>
                                        </div>

                                        <div className="text-sm">
                                            <span className="block">
                                                Forecasted Demand during campaign
                                            </span>
                                            <span className="text-slate-500 block">
                                                {formatMoney()?.format(
                                                    campaignForecastedDemandData
                                                )}{" "}
                                                units
                                            </span>
                                        </div>
                                    </div>

                                    {stockoutDate && (
                                        <p className="flex items-center text-sm text-r-50 border border-[#DC2626] bg-#FFF2F2[] rounded px-1.5 py-[1px] mb-4 w-fit">
                                            <span className="">Potential Stockout Detected:</span>{" "}
                                            <span className="font-rocGroteskBold">
                                                {stockoutDate
                                                    ? moment(stockoutDate).format("DD MMM, YYYY")
                                                    : ""}
                                            </span>
                                        </p>
                                    )}

                                    <div className="text-xs mb-4">
                                        <span className="text-slate-500 block">
                                            Potential revenue loss due to likely stockout
                                        </span>
                                        <span className="text-slate-500 font-rocGroteskBold block">
                                            {stockoutDate
                                                ? `$
                                            ${formatMoney()?.format(
                                                stockNeedsData *
                                                    values?.suggestedProducts?.[skuId]?.inventory
                                                        ?.salesPrice?.amount
                                            )}`
                                                : "N/A"}
                                        </span>
                                    </div>

                                    <div className="flex space-x-8">
                                        <div className="text-xs">
                                            <span className="text-slate-500 block">
                                                Stock needs
                                            </span>
                                            <span className="text-r-50 font-rocGroteskBold block">
                                                {stockoutDate
                                                    ? formatMoney()?.format(stockNeedsData)
                                                    : "N/A"}
                                            </span>
                                        </div>

                                        <div className="text-xs">
                                            <span className="text-slate-500 block">
                                                Suggested Re-order date
                                            </span>
                                            <span className="text-slate-500 font-rocGroteskBold block">
                                                {values?.suggestedProducts?.[skuId]?.inventory
                                                    ?.leadTime?.value &&
                                                values?.suggestedProducts?.[skuId]?.inventory
                                                    ?.leadTime?.duration &&
                                                stockoutDate
                                                    ? moment(stockoutDate)
                                                          ?.subtract(
                                                              values?.suggestedProducts?.[skuId]
                                                                  ?.inventory?.leadTime?.value,
                                                              values?.suggestedProducts?.[skuId]
                                                                  ?.inventory?.leadTime?.duration
                                                          )
                                                          ?.format("DD MMM, YYYY")
                                                    : "N/A"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {campaignDetail?.predictions?.length === 0 && (
                                <div className="absolute bottom-[8px] left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                                    <div className="text-sm w-10">
                                        <GeneratingLoader />
                                    </div>

                                    <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                        Generating results...
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    {values?.campaignInventoryForeastInsight?.[skuId]?.demandInsight && (
                        <div className="rounded-b-[10px] bg-[#FFEDD54D] px-[14px] py-4">
                            <div className="space-x-3 flex items-center">
                                <div className="w-[30px] h-[30px] rounded-full flex items-center justify-center bg-[#FFB75733] shrink-0">
                                    <i className="ri-lightbulb-flash-fill text-base text-[#E88500]"></i>
                                </div>

                                <p className="text-sm">
                                    {values?.campaignInventoryForeastInsight?.[skuId]?.demandInsight
                                        ?.split(".")
                                        .map((sentence, index, array) =>
                                            index < array.length - 1 ? (
                                                <span key={index}>
                                                    {sentence.trim()}.
                                                    {(index + 1) % 2 === 0 && <br />}
                                                    {(index + 1) % 2 === 0 && <br />}
                                                </span>
                                            ) : (
                                                sentence
                                            )
                                        )}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(7)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 7 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(7, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(7, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(7) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(7);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[20%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(7)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
        "section 8": (
            <div className="group/item relative cursor-pointer">
                <div className="bg-white rounded-t-[10px] shadow-[1px_-6px_15px_0px_#00000005]">
                    <div className="px-[14px] py-3 border-b border-slate-100 mb-3 flex items-center justify-between">
                        <div className="flex items-center space-x-2.5">
                            <div
                                onClick={() => setSelectedSentimentTab(0)}
                                className={`flex items-center text-sm border border-[#B8B8B8] rounded-[30px] px-[14px] py-1.5 shrink-0 group hover:bg-slate-800 cursor-pointer ${
                                    selectedSentimentTab === 0 && "bg-slate-800"
                                }`}
                            >
                                <span
                                    className={`group-hover:text-white ${
                                        selectedSentimentTab === 0 ? "text-white" : "text-slate-500"
                                    }`}
                                >
                                    Positive analysis
                                </span>
                            </div>

                            <div
                                onClick={() => setSelectedSentimentTab(1)}
                                className={`flex items-center text-sm border border-[#B8B8B8] rounded-[30px] px-[14px] py-1.5 shrink-0 group hover:bg-slate-800 cursor-pointer ${
                                    selectedSentimentTab === 1 && "bg-slate-800"
                                }`}
                            >
                                <span
                                    className={`group-hover:text-white ${
                                        selectedSentimentTab === 1 ? "text-white" : "text-slate-500"
                                    }`}
                                >
                                    Negative analysis
                                </span>
                            </div>
                        </div>

                        {/* <div className="flex items-center space-x-2 bg-n-20 rounded-lg py-1.5 px-2">
                            <i className="ri-eye-fill text-base"></i>
                            <span className="text-[10px]">View reasoning source</span>
                        </div> */}
                    </div>

                    <div>
                        {sentimentBubbleChartData?.length > 0 ? (
                            <div className="flex w-full justify-center pb-4">
                                <div className="w-full">
                                    <ReactEcharts option={bubbleChartOption} />
                                </div>
                            </div>
                        ) : (
                            <div className="relative">
                                <div className="flex items-center justify-center h-full py-10">
                                    <p className="text-slate-500 text-xl text-center">
                                        No data available for at this time
                                    </p>
                                </div>

                                {sentimentBubbleChartData?.length === 0 && (
                                    <div className="absolute bottom-[8px] left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                                        <div className="text-sm w-10">
                                            <GeneratingLoader />
                                        </div>

                                        <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                            Generating results...
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {values?.sentimentAnalysisSummary && (
                    <div className="rounded-b-[10px] bg-[#FFEDD54D] px-[14px] py-4">
                        <div className="space-x-3 flex items-center">
                            <div className="w-[30px] h-[30px] rounded-full flex items-center justify-center bg-[#FFB75733] shrink-0">
                                <i className="ri-lightbulb-flash-fill text-base text-[#E88500]"></i>
                            </div>

                            <p className="text-sm">
                                {values?.sentimentAnalysisSummary
                                    ?.split(".")
                                    .map((sentence, index, array) =>
                                        index < array.length - 1 ? (
                                            <span key={index}>
                                                {sentence.trim()}.{(index + 1) % 2 === 0 && <br />}
                                                {(index + 1) % 2 === 0 && <br />}
                                            </span>
                                        ) : (
                                            sentence
                                        )
                                    )}
                            </p>
                        </div>
                    </div>
                )}

                {isSaved && (
                    <div className="absolute right-[-18px] top-[20%] opacity-0 group-hover/item:opacity-100 transition-opacity duration-200 cursor-pointer p-2.5 rounded-[10px] bg-white flex flex-col space-y-2 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                        {/* <div>
                            <a id={`feedback`}>
                                <i className="ri-feedback-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#feedback`}
                                place={"right"}
                                content={"Give feedback"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}

                        <div>
                            <a id={`comment`}>
                                <i
                                    onClick={() => setActiveCommentSection(8)}
                                    className="ri-chat-3-line text-slate-500 text-lg"
                                ></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#comment`}
                                place={"right"}
                                content={"Comment"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>

                        {/* <div>
                            <a id={`rerun`}>
                                <i className="ri-refresh-line text-slate-500 text-lg"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#rerun`}
                                place={"right"}
                                content={"Re-run"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    fontSize: "12px",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div> */}
                    </div>
                )}

                {activeCommentSection === 8 && (
                    <div
                        ref={newMsgContainerRef}
                        className="absolute right-[-40%] top-[20%] z-10 cursor-pointer "
                    >
                        <div className="w-[250px] rounded-lg bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className="flex items-start space-x-3 w-full h-[70px]">
                                <MentionsInput
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(8, msg);
                                            }
                                        }
                                    }}
                                    value={msg}
                                    onChange={(evt, newValue, newPlainTextValue, mentions) => {
                                        setMsg(evt.target.value);

                                        const matches = evt.target.value.match(
                                            /(?<=\s|^)(@[a-zA-Z0-9._%+-]+(?:@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?)(?=\s|$)/g
                                        );

                                        if (matches) {
                                            const lastAt = matches[matches.length - 1]; // Displays the last @ occurrence

                                            handleDebouncedChange({
                                                target: { value: lastAt?.slice(1) },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                    id="chatInput"
                                    placeholder={"Reply comment, @ to mention"}
                                    className="mention-input-container border-0 w-full overflow-y-auto resize-none min-h-[45px] max-h-[150px] text-sm  placeholder:text-[10px] placeholder:text-gm-25 bg-transparent p-4 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                    customSuggestionsContainer={(children) => {
                                        return (
                                            <div className="border border-slate-100 shadow-cardShadow rounded max-h-[350px] overflow-y-auto">
                                                {children}
                                            </div>
                                        );
                                    }}
                                    style={{
                                        "&multiLine": {
                                            input: {
                                                padding: "16px",
                                                outline: "none",
                                                border: "none",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                                // boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.04)",
                                                overflowY: "auto",
                                                resize: "none",
                                                minHeight: "60px",
                                                maxHeight: "150px",
                                            },
                                        },
                                    }}
                                >
                                    <Mention
                                        trigger="@"
                                        data={teamList}
                                        onAdd={() => {
                                            handleDebouncedChange({
                                                target: { value: "" },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        renderSuggestion={(
                                            entry,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => {
                                            return (
                                                <div className="text-sm py-2 px-4 hover:bg-slate-100 font-rocGroteskMedium">
                                                    {entry?.display}
                                                </div>
                                            );
                                        }}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="border-t border-slate-100 px-2 flex items-center justify-between p-2">
                                <div className="flex items-center space-x-1">
                                    <i className="ri-at-line text-slate-400"></i>
                                </div>
                                {creatingCampaignComment ? (
                                    <div>
                                        <Loader size={3} />
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            if (msg && !creatingCampaignComment) {
                                                handleAddComment(8, msg);
                                            }
                                        }}
                                        className="h-5 w-5 rounded-full flex items-center justify-center bg-y-10 "
                                    >
                                        <i className="ri-arrow-right-line text-[12px]"></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isSaved && getSectionCommentsCount(8) > 0 && (
                    <div
                        onClick={() => {
                            setSelectedSection(8);
                            setActiveTab(1);
                        }}
                        className="absolute right-[5%] top-[13%] w-fit cursor-pointer "
                    >
                        <div className="relative flex items-center justify-center p-2 rounded bg-[#E4281D] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
                            <div className=" text-white text-[10px] font-bold flex items-center justify-center rounded-md relative">
                                {getSectionCommentsCount(8)}
                                <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 translate-y-[77%] rotate-45 w-2 h-2 bg-[#E4281D] border-l-6 border-r-6 border-t-6 border-l-transparent border-r-transparent border-t-red-600"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ),
    };

    // useEffect(() => {
    //     if (Boolean(createCampaignCommentSuccess)) {
    //         setActiveCommentSection(null);
    //         setMsg("");
    //     }
    // }, [createCampaignCommentSuccess]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (newMsgContainerRef.current && !newMsgContainerRef.current?.contains(event.target)) {
                setActiveCommentSection(null);
                setMsg("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [newMsgContainerRef]);
    console.log("values -->>", values);
    return (
        <div
            className={` ${
                isSaved ? "bg-white border border-n-20" : ""
            } rounded-lg  relative font-rocGroteskMedium pb-14`}
        >
            <div className={` ${isSaved ? "mt-[58px] flex justify-end" : ""}  `}>
                <div className={` ${isSaved ? "flex justify-end space-x-6 w-full relative" : ""} `}>
                    <div
                        className={` ${
                            isSaved
                                ? "w-[57%] border border-slate-100 rounded-lg bg-[#FBFBFB] p-4"
                                : ""
                        }  `}
                    >
                        {isSaved && (
                            <div className="flex items-center justify-between pb-3 mb-6 border-b border-slate-200">
                                <span className="text-2xl">
                                    “{campaignDetail?.campaignName}” Campaign
                                </span>
                                <div className="flex items-center space-x-2 bg-[#D0F4EDB2] rounded-md py-1.5 px-2.5">
                                    {/* <span className="w-2 h-2 rounded-full bg-[#22C55E] " /> */}
                                    <span className="text-sm">{latestCampaignTasks?.status}</span>
                                </div>
                            </div>
                        )}

                        <div className="space-y-6 ">
                            {Object.keys(values).length === 0 ? (
                                <div className="border-[1px] rounded-[10px] border-transparent bg-gradient-to-tl from-[#62CC00] from-0% to-[#FFB902] to-100% ">
                                    <div className="bg-white rounded-[10px] p-4 relative">
                                        <div className=" pb-3 border-b border-slate-100 mb-4">
                                            <div className="space-x-2 flex items-center">
                                                <i className="ri-sparkling-fill text-2xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#62CC00] from-0% to-[#FFB902] to-100%"></i>
                                                <span className="text-lg font-rocGroteskBold">
                                                    Campaign Inteliigence
                                                </span>
                                            </div>
                                        </div>

                                        <div className="flex items-center space-x-2 mb-2.5">
                                            <span className="w-[18px] h-[18px] flex items-center justify-center rounded bg-[#4FCB01]">
                                                <i className="ri-line-chart-line text-[10px] text-white"></i>
                                            </span>
                                            <p className="text-sm">
                                                <span>Forecasted Demand during campaign:</span>{" "}
                                                <span className="text-slate-500 block">
                                                    {formatMoney()?.format(
                                                        campaignForecastedDemandData
                                                    )}{" "}
                                                    units
                                                </span>
                                            </p>
                                        </div>
                                        <div className="absolute bottom-[80px] left-0 flex w-full justify-start bg-white border-[#F9F9F9] border rounded-lg shadow-[-6px_4px_8px_-4px_rgba(255,185,2,0.03),6px_8px_24px_-4px_rgba(255,185,2,0.08)] px-2 py-4 ">
                                            <div className="text-sm w-10">
                                                <GeneratingLoader />
                                            </div>

                                            <p className="border-l border-slate-200 pl-2 text-700 text-sm">
                                                Generating results...
                                            </p>
                                        </div>
                                        <div className="gap-[10px] flex items-center flex-wrap mb-8">
                                            {values?.suggestedProducts?.map((product) => {
                                                return (
                                                    <p
                                                        key={product?.inventoryId}
                                                        className="flex items-center text-sm border border-[#B8B8B8] rounded px-2 py-[2px] "
                                                    >
                                                        <span className="text-slate-500">
                                                            {product?.inventory?.sku}:{" "}
                                                            {formatMoney().format(
                                                                product?.inventory
                                                                    ?.productStockDetails
                                                                    ?.stockLevel
                                                            )}{" "}
                                                            units
                                                        </span>{" "}
                                                        {/* <span className="text-r-50">, Potential Stockout Detected:</span>{" "}
                            <span className="text-r-50 font-rocGroteskBold">20 Jan,2025</span> */}
                                                    </p>
                                                );
                                            })}
                                        </div>

                                        <div className="flex items-center overflow-x-hidden space-x-2 mb-8">
                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    Ad cost
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    $430,000
                                                </span>
                                            </div>

                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    ROAS
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    5
                                                </span>
                                            </div>

                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    Potential revenue
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    $430,000
                                                </span>
                                            </div>

                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    CPC
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    $34
                                                </span>
                                            </div>

                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    CTR
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    24%
                                                </span>
                                            </div>

                                            <div className="text-xs px-2 py-[5px] border border-slate-100 rounded-lg min-w-[100px] shrink-0">
                                                <span className="text-slate-400 block mb-1">
                                                    Potential revenue loss due to likely stockout
                                                </span>
                                                <span className="text-slate-500 block font-rocGroteskBold">
                                                    $180,950.56
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="space-y-6 ">
                                    {campaignSection["section 1"]}

                                    <div className="border-[1px] rounded-[10px] border-transparent bg-gradient-to-tl from-[#62CC00] from-0% to-[#FFB902] to-100%">
                                        <div className="bg-white p-4 rounded-[10px]">
                                            <div className="flex items-center justify-between pb-3 border-b border-slate-100 mb-4">
                                                <div className="space-x-2 flex items-center">
                                                    <i className="ri-sparkling-fill text-2xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#62CC00] from-0% to-[#FFB902] to-100%"></i>
                                                    <span className="text-lg font-rocGroteskBold">
                                                        Campaign Inteliigence
                                                    </span>
                                                </div>

                                                {/* <div className="flex items-center space-x-2 bg-n-20 rounded-lg py-1.5 px-2">
                                            <i className="ri-eye-fill text-base"></i>
                                            <span className="text-[10px]">
                                                View reasoning source
                                            </span>
                                        </div> */}
                                            </div>

                                            <div>
                                                <div>
                                                    <div className="flex items-center space-x-2 mb-2.5">
                                                        <span className="w-[18px] h-[18px] flex items-center justify-center rounded bg-[#4FCB01]">
                                                            <i className="ri-line-chart-line text-[10px] text-white"></i>
                                                        </span>
                                                        <p className="text-sm">
                                                            <span>
                                                                Forecasted Demand during campaign:
                                                            </span>{" "}
                                                            <span className="text-slate-500">
                                                                {formatMoney().format(
                                                                    totalforcastedProductDemand
                                                                )}{" "}
                                                                units
                                                            </span>
                                                        </p>
                                                    </div>

                                                    {campaignSection["section 2"]}

                                                    {campaignSection["section 3"]}

                                                    {campaignSection["section 4"]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {!isSaved && (
                                        <div className="bg-white rounded-[10px] border border-slate-100">
                                            <div className="p-4 flex justify-between items-center">
                                                <div className="flex items-center space-x-4 w-1/2">
                                                    <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                                        <i className="ri-calendar-todo-line text-xl text-y-10"></i>
                                                    </div>

                                                    <div>
                                                        <p className="text-xs text-gm-30">
                                                            Campaign schedule
                                                        </p>
                                                        <p className="text-base ">
                                                            {values?.campaignStartDate &&
                                                            values?.campaignEndDate
                                                                ? moment(
                                                                      values?.campaignStartDate
                                                                  ).format("DD MMM, YYYY") +
                                                                  " - " +
                                                                  moment(
                                                                      values?.campaignEndDate
                                                                  ).format("DD MMM, YYYY")
                                                                : ""}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="flex items-center space-x-4 w-1/2">
                                                    <div className="w-10 h-10 bg-[#FFFBE9] border border-[#FCDE91] rounded-full flex items-center justify-center">
                                                        <i className="ri-route-line text-xl text-y-10"></i>
                                                    </div>

                                                    <div>
                                                        <p className="text-xs text-gm-30">
                                                            Campaign source
                                                        </p>
                                                        <p className="text-base flex items-center space-x-1.5">
                                                            <span className="w-[26px] h-[26px] flex items-center justify-center rounded-full bg-white shadow-[0px_1px_2px_0px_#00000012]">
                                                                <img
                                                                    src={
                                                                        campaignDetail?.channel ===
                                                                        "google"
                                                                            ? "https://storage.googleapis.com/heysynth_files/app/google_ads.svg"
                                                                            : campaignDetail?.channel ===
                                                                              "tiktok"
                                                                            ? "https://storage.googleapis.com/heysynth_files/app/tiktokIcon.svg"
                                                                            : campaignDetail?.channel ===
                                                                              "meta"
                                                                            ? "https://storage.googleapis.com/heysynth_files/synth_webapp/meta.svg"
                                                                            : campaignDetail?.channel ===
                                                                                  "klaviyo" &&
                                                                              "https://storage.googleapis.com/heysynth_files/app/klaviyo_logo.svg.svg"
                                                                    }
                                                                    alt="channel icon"
                                                                    width={13}
                                                                    height={17}
                                                                />
                                                            </span>
                                                            <span>{campaignDetail?.channel}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex items-center w-full text-slate-500 justify-between my-5">
                                        <hr className="w-[30%]" />
                                        <span className="text-sm">Sales Vs Forecasted Demand</span>
                                        <hr className="w-[30%]" />
                                    </div>

                                    {campaignSection["section 5"]}

                                    {campaignSection["section 6"]}

                                    {campaignSection["section 7"]}

                                    {isSaved ? (
                                        <div>
                                            <p className="text-lg mb-4">
                                                Sentiment Analysis{" "}
                                                <span className="text-sm">
                                                    (based on customer interaction during campaign)
                                                </span>
                                            </p>

                                            {campaignSection["section 8"]}
                                        </div>
                                    ) : (
                                        <div>
                                            {/* <div className="">
                                        <p className="text-slate-600 text-sm">
                                            Here is a purchase order that has been created to
                                            replenish
                                            <span className="text-slate-600 font-rocGroteskBold">
                                                {" "}
                                                SKU 2456{" "}
                                            </span>{" "}
                                            . Kindly review to be sure that they are accurate before
                                            sending them out to your suppliers.
                                        </p>

                                        <div className="flex justify-between items-center my-4">
                                            <div className="flex gap-2 items-center">
                                                <div className="bg-slate-100 rounded-full h-[52px] w-[52px] flex items-center justify-center">
                                                    <i className="ri-file-text-line text-[#6562E5] text-2xl"></i>
                                                </div>
                                                <div className="">
                                                    <p className="text-slate-700 text-sm">
                                                        PO for Natural Tomato Sauce (SKU 2456)
                                                    </p>

                                                    <Button
                                                        btnText={`Click to view`}
                                                        type="button"
                                                        // isLoading={creatingAiTeammate || updatingAiTeammate}
                                                        onClick={() => console.log("clickkk")}
                                                        btnClassname="!text-xs !w-fit !text-slate-500 !bg-transparent !p-0"
                                                    />
                                                </div>
                                            </div>
                                            <Button
                                                btnText={`Send to supplier`}
                                                type="button"
                                                // isLoading={creatingAiTeammate || updatingAiTeammate}
                                                onClick={() => console.log("clickkk")}
                                                btnClassname="!px-4 !py-2 !w-fit !text-[#334155] !bg-[#E0E0FA66] !border !border-[#5452BF] !rounded-[8px]"
                                            />
                                        </div>
                                    </div> */}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {isSaved && (
                        <div className="w-[35%]">
                            <div className="border border-slate-100 rounded-[10px] sticky top-[70px]">
                                <div className="px-4 py-2.5 border-b border-slate-100 flex items-center space-x-2.5">
                                    <div
                                        onClick={() => setActiveTab(0)}
                                        className={`flex items-center space-x-2.5 px-3 py-2 ${
                                            activeTab === 0 ? "bg-slate-100 " : "text-slate-500"
                                        } rounded-lg cursor-pointer hover:bg-slate-100 hover:text-slate-800`}
                                    >
                                        <i className="ri-space-ship-line"></i>
                                        <span className="text-sm font-rocGrotesMedium">
                                            Recommendations
                                        </span>
                                    </div>

                                    <div
                                        onClick={() => setActiveTab(1)}
                                        className={`flex items-center space-x-2.5 px-3 py-2 ${
                                            activeTab === 1 ? "bg-slate-100 " : "text-slate-500"
                                        } rounded-lg cursor-pointer hover:bg-slate-100 hover:text-slate-800`}
                                    >
                                        <i className="ri-chat-2-line"></i>
                                        <span className="text-sm font-rocGrotesMedium">
                                            Comments
                                        </span>
                                    </div>
                                </div>

                                {activeTab === 0 && (
                                    <div>
                                        {values?.campaignForecastRecommendation?.length > 0 ? (
                                            <div>
                                                <div className="py-4 space-y-8 overflow-y-auto max-h-[300px]">
                                                    {campaignRecommendation?.map(
                                                        (recommendation, idx) => {
                                                            return (
                                                                <div className="flex items-center justify-between px-4">
                                                                    <div className="flex items-center space-x-2">
                                                                        <span className="w-8 h-8 rounded-full border border-[#FCDE91] flex items-center justify-center bg-[#FFFBE9] text-[#FFB902]">
                                                                            {idx + 1}
                                                                        </span>
                                                                        <span className="text-sm capitalize">
                                                                            {recommendation?.action
                                                                                ?.split("_")
                                                                                .join(" ")}
                                                                        </span>
                                                                    </div>

                                                                    <Button
                                                                        btnText="Perform action"
                                                                        btnClassname="!bg-n-20 px-4 !py-2.5 !rounded-lg !w-fit"
                                                                        onClick={() => {
                                                                            const action =
                                                                                recommendation?.action;
                                                                            const inventoryId =
                                                                                recommendation?.inventoryId;

                                                                            switch (action) {
                                                                                case "create_purchase_order":
                                                                                    navigate(
                                                                                        `/dashboard/purchase/create-purchase-request?inventory=${inventoryId}`
                                                                                    );
                                                                                    break;
                                                                                case "create_production_plan":
                                                                                    navigate(
                                                                                        `/dashboard/dashboard/production/create-plan?planTab=1&inventoryId=${inventoryId}`
                                                                                    );
                                                                                    break;
                                                                                case "transfer_sku":
                                                                                    navigate(
                                                                                        `/dashboard/inventory/stock-control/stock-transfer?currTab=17inventoryId=${inventoryId}`
                                                                                    );
                                                                                    break;
                                                                                case "increase_stock":
                                                                                case "decrease_stock":
                                                                                    navigate(
                                                                                        `/dashboard/inventory/stock-control/stock-entry?channel=offline_purchase&currTab=1&inventoryId=${inventoryId}`
                                                                                    );
                                                                                    break;
                                                                                default:
                                                                                    console.log(
                                                                                        "Unknown action:",
                                                                                        action
                                                                                    );
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>

                                                {values?.agentTasks?.length > 0 && (
                                                    <div>
                                                        <div className="space-x-2 flex items-center  border-y border-slate-100 px-4 py-3">
                                                            <i className="ri-sparkling-fill text-xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#62CC00] from-0% to-[#FFB902] to-100%"></i>
                                                            <span className="text-sm font-rocGroteskBold">
                                                                Confirm AI Tasks
                                                            </span>
                                                        </div>

                                                        <div className="p-4 space-y-8 overflow-y-auto max-h-[300px]">
                                                            {values?.agentTasks?.map(
                                                                (task, idx) => (
                                                                    <div
                                                                        key={task?.taskId}
                                                                        className="flex items-center justify-between space-x-2"
                                                                    >
                                                                        <div className="flex items-center space-x-2">
                                                                            <span className="w-8 h-8 rounded-full border border-[#8BF39E] flex items-center justify-center bg-[#F0FFE9] text-[#04A91A]">
                                                                                {idx + 1}
                                                                            </span>
                                                                            <span className="text-sm">
                                                                                {task?.taskName}
                                                                            </span>
                                                                        </div>

                                                                        <Button
                                                                            btnText="Preview"
                                                                            btnClassname="!bg-n-20 px-4 !py-2.5 !rounded-lg !w-fit"
                                                                            onClick={() => {
                                                                                const action =
                                                                                    task?.taskType;

                                                                                switch (action) {
                                                                                    case "create_purchase_order":
                                                                                        navigate(
                                                                                            `/dashboard/purchase/${task?.taskId}`
                                                                                        );
                                                                                        break;
                                                                                    case "create_production_plan":
                                                                                        navigate(
                                                                                            `/dashboard/dashboard/production/${task?.taskId}`
                                                                                        );
                                                                                        break;

                                                                                    default:
                                                                                        console.log(
                                                                                            "Unknown action:",
                                                                                            action
                                                                                        );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}

                                                            {/* <div className="flex items-center justify-between">
                                                        <div className="flex items-center space-x-2">
                                                            <span className="w-8 h-8 rounded-full border border-[#8BF39E] flex items-center justify-center bg-[#F0FFE9] text-[#04A91A]">
                                                                2
                                                            </span>
                                                            <div>
                                                                <span className="text-sm block">
                                                                    Create a production plan
                                                                </span>
                                                                <span className="text-xs block underline">
                                                                    Preview plan
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <Button
                                                            btnText="Approve Plan"
                                                            btnClassname="!bg-n-20 px-4 !py-2.5 !rounded-lg !w-fit"
                                                        />
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="flex flex-col items-center space-y-2 my-5">
                                                <img
                                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/emptyRecommendations_u8kzo3.svg"
                                                    className="w-[201px] h-[201px]"
                                                />
                                                <p className="text-sm font-rocGroteskMedium text-slate-500 text-center w-[80%]">
                                                    No recommendations to show yet. You will see any
                                                    AI recommendations here when you have some.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {activeTab === 1 && (
                                    <div>
                                        <div className="flex items-center justify-between px-4 pt-4">
                                            <div className="flex items-center space-x-4">
                                                <span
                                                    role="tab"
                                                    tabIndex={0}
                                                    className={`text-sm font-rocGroteskMedium cursor-pointer ${
                                                        activeCommentTab === 0
                                                            ? "text-p-100 border-b-2 border-y-10"
                                                            : "text-slate-500"
                                                    } pb-2`}
                                                    onClick={() => {
                                                        setActiveCommentTab(0);
                                                        setIsResolvedTab(false);
                                                        setIsMentionTab(false);

                                                        // if (activeCommentTab !== 0) {
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCampaignSectionCommentsSuccess()
                                                        //     );
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCompaignCommentSuccess()
                                                        //     );
                                                        // }
                                                    }}
                                                >
                                                    All
                                                </span>
                                                <span
                                                    role="tab"
                                                    tabIndex={0}
                                                    className={`text-sm font-rocGroteskMedium cursor-pointer ${
                                                        activeCommentTab === 1
                                                            ? "text-p-100 border-b-2 border-y-10"
                                                            : "text-slate-500"
                                                    } pb-2`}
                                                    onClick={() => {
                                                        setActiveCommentTab(1);
                                                        setIsMentionTab(true);
                                                        setIsResolvedTab(false);

                                                        // if (activeCommentTab !== 1) {
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCampaignSectionCommentsSuccess()
                                                        //     );
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCompaignCommentSuccess()
                                                        //     );
                                                        // }
                                                    }}
                                                >
                                                    Mentions
                                                </span>
                                                <span
                                                    role="tab"
                                                    tabIndex={0}
                                                    className={`text-sm font-rocGroteskMedium cursor-pointer ${
                                                        activeCommentTab === 2
                                                            ? "text-p-100 border-b-2 border-y-10"
                                                            : "text-slate-500"
                                                    } pb-2`}
                                                    onClick={() => {
                                                        setActiveCommentTab(2);
                                                        setIsResolvedTab(true);
                                                        setIsMentionTab(false);

                                                        // if (activeCommentTab !== 2) {
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCampaignSectionCommentsSuccess()
                                                        //     );
                                                        //     dispatch(
                                                        //         workforceActions.resetGetCompaignCommentSuccess()
                                                        //     );
                                                        // }
                                                    }}
                                                >
                                                    Resolved
                                                </span>
                                            </div>
                                        </div>

                                        {fetchingCampaignComment ? (
                                            <div className="flex justify-center mt-5">
                                                {" "}
                                                <Loader />{" "}
                                            </div>
                                        ) : sectionComments?.length > 0 ? (
                                            <ChannelProvider channelName={`campaign_comments`}>
                                                <CampaignComments
                                                    parentComments={sectionComments}
                                                    setIsResolvedTab={setIsResolvedTab}
                                                    setIsMentionTab={setIsMentionTab}
                                                    setActiveTab={setActiveTab}
                                                    setActiveCommentSection={
                                                        setActiveCommentSection
                                                    }
                                                    setMsg={setMsg}
                                                />
                                            </ChannelProvider>
                                        ) : (
                                            <div className="flex flex-col items-center space-y-2 my-5">
                                                <img
                                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/emptyComments_z6zfmf.svg"
                                                    className="w-[201px] h-[201px]"
                                                />
                                                <p className="text-sm font-rocGroteskMedium text-slate-500 text-center w-[80%]">
                                                    No comments to show yet. Hover on a section on
                                                    the report to drop your first comment
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CampaignSimulationDetails;
