import InventoryAnalyticsCard from "components/common/InventoryAnalyticsCard";
import React, { useState } from "react";

import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TabsContainer from "components/common/TabsContainer";
import TextInput from "components/common/InputField/TextInput";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetMappedSkusList from "hooks/inventoryHooks/useGetMappedSkusList";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import CustomTableLoader from "components/common/CustomTableLoader";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import EmptyStateComp from "components/common/EmptyStateComp";
import Loader from "components/common/Loader";

const SkuMapping = () => {
    const tabs = ["Mapped SKUs"];
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const {
        data: mappedSkusData,
        isFetching,
        handleSearchChange,
        handleDebounceChange,
        search,
        pagination,
        isLoadingMore,
    } = useGetMappedSkusList();

    const tableProductHeader = [
        {
            title: "Master SKU",
            widthClass: "w-[21%] !border-r-none",
        },
        { title: "Associated SKUs", widthClass: "w-[16.4%] !border-r-none" },
        { title: "Channels", widthClass: "w-[11%] !border-r-none" },
        { title: "Current stock", widthClass: "w-[11%] !border-r-none" },
        { title: "Ongoing sales", widthClass: "w-[11%] !border-r-none" },
        { title: "", widthClass: "w-[4.3%] !border-r-none" },
    ];

    const tableData = mappedSkusData?.map((product) => {
        return {
            groupContent: (
                <div
                    className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className="font-rocGroteskMedium   items-center flex space-x-3">
                        {product?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={
                                    product?.productImageDetails?.productAvatar ||
                                    product?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}

                        <div className="text-sm font-rocGroteskMedium">
                            <p className="text-g-75 ">
                                SKU - {truncate(product?.sku, { length: 35 })}
                            </p>
                            <p className="text-slate-500">
                                {truncate(product?.productName, { length: 35 })}
                            </p>
                        </div>
                    </div>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/inventory/mapped-sku/${product?._id}`, {
                    state: { internalNavigation: true },
                });
            },
            associatedSKUs: product?.mappedSkus?.map((associatedSku) => {
                return [
                    {
                        content: (
                            <div
                                key={1}
                                className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                            >
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-g-75 ">
                                        SKU - {truncate(associatedSku?.sku, { length: 25 })}
                                    </p>
                                    <p className="text-slate-500 ">
                                        {truncate(associatedSku?.productName, { length: 25 })}
                                    </p>
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <div className="flex items-center space-x-3">
                                    {displayProductChannelIcon(
                                        associatedSku?.channels?.[0] || "Synth"
                                    )}
                                    <span className="text-sm font-rocGroteskMedium">
                                        {associatedSku?.channels?.[0] || "Synth"}
                                    </span>
                                </div>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>{associatedSku?.stockLevel}</p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                        // shouldRowSpan: true,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                            >
                                <p>{associatedSku?.onGoingSales}</p>
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown
                                    dropdown={
                                        <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                    dropdownItemsClasses={``}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        {
                                            label: "View Mapped SKU",
                                            value: "view mapped sku",
                                            action: () => {
                                                navigate(
                                                    `/dashboard/inventory/mapped-sku/${product?._id}`,
                                                    { state: { internalNavigation: true } }
                                                );
                                            },
                                        },
                                        {
                                            label: "Edit Mapped SKU",
                                            value: "Edit mapped sku",
                                            action: () => {
                                                navigate(
                                                    `/dashboard/inventory/mapped-sku/edit/${product?._id}`,
                                                    { state: { internalNavigation: true } }
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        ),
                        // widthClass: `!border-none`,
                    },
                ];
            }),
        };
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return isFetching && mappedSkusData?.length === 0 ? (
                    <div>
                        <CustomTableLoader tableHeader={tableProductHeader} />
                    </div>
                ) : mappedSkusData?.length > 0 ? (
                    <div className="mb-[-90px] pb-[90px]">
                        <CustomTable
                            groupedData={tableData}
                            tableHeader={tableProductHeader}
                            isAllSelectable={false}
                            isCellSelectable={false}
                            isCellBordered={true}
                            isGrouped={true}
                            headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                            headerContainerClass="!bg-slate-50 !border !border-[#E2E8F0] "
                            tableContainerClass="mb-[-90px] pb-[90px]"
                            isScrollable={false}
                            bodyItemClass="!border-t-none !border-r-none"
                            tableClass="!border-t-none !border-r-none !border-l-none"
                        />

                        {isFetching && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No Mapped SKUs yet"
                        description="You haven't created any Mapped SKUs yet, you can start by clicking the button below"
                        hasButton
                        btnAction={() =>
                            navigate("/dashboard/inventory/sku-mapping/create", {
                                state: { internalNavigation: true },
                            })
                        }
                        btnText="Map new SKUs"
                    />
                );

            default:
                break;
        }
    };

    return (
        <div>
            <div>
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-fit max-lg:w-full mb-6"}
                    showButton={
                        <div className="">
                            <div className="flex max-lg:mb-4 max-sm:space-x-0 max-sm:flex-col max-sm:space-y-3 items-center space-x-3">
                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search  products"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    onInput={handleDebounceChange}
                                    containerClassname={"!w-[400px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />

                                <Button
                                    btnText="Map new SKUs"
                                    btnClassname="!py-3 !w-fit !whitespace-nowrap"
                                    isLoading={false}
                                    btnPermission={[
                                        "all_access",
                                        "create_inventory",
                                        "update_inventory",
                                    ]}
                                    onClick={() =>
                                        navigate("/dashboard/inventory/sku-mapping/create", {
                                            state: { internalNavigation: true },
                                        })
                                    }
                                />
                                {/* <div className="border rounded border-slate-200 max-sm:w-full"> */}
                                {/* <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    // handleChange={(name, value) => handleFilterChange(value)}
                                    dropdown={
                                        <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                            <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Map new SKUs
                                                </span>
                                            </div>
                                            <div className=" py-2.5">
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[-58px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div className="w-[200px]">
                                                    <div
                                                        className="py-2.5 px-3 border-b border-slate-200 cursor-pointer"
                                                        onClick={() => console.log("first")}
                                                    >
                                                        <span className="block text-sm whitespace-nowrap font-rocGroteskMedium">
                                                            Single products
                                                        </span>
                                                        <span className="block text-[12px] text-slate-500 whitespace-nowrap font-rocGroteskMedium">
                                                            One item sold individually
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="py-2.5 px-3 border-b border-slate-200 cursor-pointer"
                                                        onClick={() => console.log("second")}
                                                    >
                                                        <span className="block text-sm whitespace-nowrap font-rocGroteskMedium">
                                                            Bundle products
                                                        </span>
                                                        <span className="block text-[12px] text-slate-500 whitespace-nowrap font-rocGroteskMedium">
                                                            Multiple items sold together
                                                        </span>
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default SkuMapping;
