import React, { useMemo } from "react";
import PrivateRoutes from "components/common/Routes/PrivateRoute";
import PublicRoutes from "components/common/Routes/PublicRoute";
import NoMatchPage from "pages/Ldb/404Page";

// Private Pages
import Copilot from "pages/Ldb/Copilot";
import Dashboard from "pages/Ldb/Dashboard";
// import DashboardHome from "pages/Ldb/Home";
import PurchaseOrder from "pages/Ldb/Orders/PurchaseOrder";
import PurchaseOrderDetail from "pages/Ldb/Orders/PurchaseOrder/PurchaseOrderDetails";
import Quotes from "pages/Ldb/Orders/Quotes";
// import QuoteDetails from "pages/Ldb/Orders/Quotes/QuoteDetails";
import QuoteItemDetails from "pages/Ldb/Orders/Quotes/QuoteItemDetails";
import QuoteVendorDetails from "pages/Ldb/Orders/Quotes/QuoteVendorDetails";
import Rfqs from "pages/Ldb/Orders/Rfqs";
import CreateRfqs from "pages/Ldb/Orders/Rfqs/CreateRfqs";
// import OrderDetails from "pages/Ldb/Orders/Rfqs/OrderDetails";
import RfqDetails from "pages/Ldb/Orders/Rfqs/RfqDetails";
import Shipment from "pages/Ldb/Shipment";
import ShipmentDetails from "pages/Ldb/Shipment/ShipmentDetails";
import Inventory from "pages/Ldb/Inventory/ProductList";
import AddProduct from "components/views/Synth2.0/Inventory/Tabs/ProductList/AddProduct";
import AddBundle from "components/views/Synth2.0/Inventory/Tabs/ProductList/AddBundleProduct";
import BundleDetails from "components/views/Synth2.0/Inventory/Tabs/ProductList/BundleDetails";
import TeamManagement from "pages/Synth2.0/TeamManagement";
import AddVendor from "pages/Ldb/VendorManagement/AddVendor";
import VendorAnalytics from "pages/Ldb/VendorManagement/VendorAnalytics";
import VendorDetails from "pages/Ldb/VendorManagement/VendorDetails";

import Tasks from "pages/Ldb/Task";
import VendorList from "pages/Ldb/VendorManagement/VendorList";
import EditPurchaseOrder from "pages/Ldb/Orders/PurchaseOrder/EditOrders";
import CreatePurchaseRequest from "pages/Synth2.0/Purchase/CreatePurchaseRequest";
import EditPurchaseRequest from "pages/Synth2.0/Purchase/EditPurchaseRequest";
import PurchaseOrderAnalytics from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import Settings from "pages/Ldb/Settings";
import ProcurementCycle from "pages/Ldb/BusinessAnalytics/ProcurementCycle";
import AnalyticsPurchaseOrder from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import SpendAnalysis from "pages/Ldb/BusinessAnalytics/SpendAnalysis";
import ProductDetailsPage from "pages/Ldb/Inventory/ProductList/ProductDetails";
import WareHouse from "pages/Ldb/Inventory/Warehouse";
import AddWareHouse from "pages/Ldb/Inventory/Warehouse/AddWarehouse";
import EditWareHouse from "pages/Ldb/Inventory/Warehouse/EditWarehouse";
import WarehouseDetails from "components/views/Synth2.0/Inventory/Tabs/WarehouseDetails";
import Purchase from "pages/Synth2.0/Purchase";
import PurchaseSettings from "pages/Synth2.0/Purchase/Settings";
import Suppliers from "pages/Synth2.0/Suppliers";
import CreateSuppliers from "pages/Synth2.0/Suppliers/createSuppliers";
import SupplierDetails from "pages/Synth2.0/Suppliers/supplierDetails";
import SupplierContract from "pages/Synth2.0/Suppliers/supplierContract";
import SalesDetails from "pages/Synth2.0/Sales/SalesDetails";
import DashboardHome from "pages/Synth2.0/Dashboard";

// end private pages

// Public Pages
import Home from "pages/Landing/Home";
import Integrations from "pages/Landing/Integrations";
// import BookADemo from "pages/Landing/BookADemo";
import WaitList from "pages/Landing/WaitList";
// import Pricing from "pages/Landing/Pricing";
import Terms from "pages/Landing/Terms";
import Policy from "pages/Landing/Policy";
import AuthSteps from "pages/Ldb/Auth/AuthSteps";
import Login from "pages/Ldb/Auth/Login";
import Signup from "pages/Ldb/Auth/Signup";
import ForgotPassword from "pages/Ldb/Auth/ForgotPassword";
import ResetPassword from "pages/Ldb/Auth/ResetPassword";
import SignupSuccess from "pages/Ldb/Auth/SignupSuccess";
import NewOnboard from "pages/Ldb/Onboarding/newOnboard";
import AcctVerification from "pages/Ldb/Auth/AcctVerification";
import ChangeVerificationEmail from "pages/Ldb/Auth/ChangeVerificationEmail";
import GoogleAuth from "pages/Ldb/Auth/GoogleAuth";
import QuoteDetails from "pages/Synth2.0/Purchase/QuoteDetails";
import Playground from "pages/Synth2.0/Playground";
import Integration from "pages/Synth2.0/Integration";
import ShipmentPurchaseDetails from "pages/Synth2.0/Purchase/ShipmentPurchaseDetails";
import PurchaseDetails from "pages/Synth2.0/Purchase/PurchaseOrderDetails";
import IntegrationDetails from "pages/Synth2.0/Integration/integrationDetails";
import Products from "pages/Synth2.0/Inventory";
import ImportProducts from "pages/Synth2.0/Inventory/ImportProducts";
import Workforce from "pages/Synth2.0/Workforce";
import CreateAiTeammate from "pages/Synth2.0/Workforce/CreateAiTeammate";
import AiTeammateDetails from "pages/Synth2.0/Workforce/AiTeammateDetails";
import CampaignOverview from "pages/Synth2.0/MarketingCampaign/CampaignOverview";
import PlanCampaign from "pages/Synth2.0/MarketingCampaign/PlanCampaign";
import TaskManager from "pages/Synth2.0/TaskManager";
import TaskDetail from "pages/Synth2.0/TaskManager/TaskDetail";
import TaskDetailWorkOrder from "pages/Synth2.0/TaskManager/TaskDetailWorkOrder";
import EditOrderStep from "pages/Synth2.0/TaskManager/EditOrderStep";
import AlertDetails from "components/views/Synth2.0/Inventory/Tabs/Alerts/AlertDetails";
import ProductDetail from "components/views/Synth2.0/Inventory/Tabs/ProductList/ProductDetail";
import GlobalProductDetail from "components/views/Synth2.0/Inventory/Tabs/ProductList/GlobalProductDetail";
import AddStock from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/AddStockCount";
import StockAdjustment from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockAdjustment/StockAdjustment";
import StockAdjustmentDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockAdjustment/StockAdjustmentDetails";
import StockTransfer from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockTransfer/StockTransfer";
import StockTransferDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockTransfer/StockTransferDetails";
import StockDetails from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/StockDetails";
import EditStock from "components/views/Synth2.0/Inventory/Tabs/StockControl/StockEntry/EditStock";
import Order from "pages/Synth2.0/Order";
import OrderDetails from "pages/Synth2.0/Order/OrderDetails";
import CreateOrder from "pages/Synth2.0/Order/CreateOrder";
import OrderSettings from "pages/Synth2.0/Order/Settings";
import ShopifyAuth from "pages/Synth2.0/Integration/ShopifyAuth";
// import CreateOrder from "pages/Synth2.0/Purchase/CreatePurchaseRequest";
import EditProduct from "components/views/Synth2.0/Inventory/Tabs/ProductList/EditProduct";
import TrackAndTrace from "pages/Synth2.0/TrackAndTrace";
import ProductTrackDetails from "components/views/Synth2.0/TrackTrace/ProductTrackDetails";
import CreateQuote from "pages/Synth2.0/Order/CreateQuote";
import QuoteSingleDetails from "pages/Synth2.0/Order/Quotedetails";
import CreateSku from "pages/Synth2.0/Inventory/SkuCreation/index";
import ExpandedDetails from "components/views/Synth2.0/Order/ExpandedDetails";
import MappedSkuDetail from "components/views/Synth2.0/Inventory/Tabs/SkuMapping/MappedSkuDetail";
import EditSku from "components/views/Synth2.0/Inventory/Tabs/SkuMapping/EditSku";
import Production from "pages/Synth2.0/Production/index";
import CreateProductionPlan from "pages/Synth2.0/Production/CreateProductionPlan";
import CreateBillOfMaterial from "pages/Synth2.0/Production/CreateBillOfMaterial";
import BillOfMaterialDetails from "pages/Synth2.0/Production/BillOfMaterialDetails";
import WorkOrderDetails from "pages/Synth2.0/Production/WorkOrderDetails";
import ReportDetails from "pages/Synth2.0/Production/ReportDetails";
import CampaignDetail from "pages/Synth2.0/MarketingCampaign/CampaignDetail/index";
import ProductionSettings from "pages/Synth2.0/Production/ProductionSettings";

import ProductionPlanDetails from "pages/Synth2.0/Production/ProductionPlanDetails";

import MultiBrandDashboard from "pages/Synth2.0/Multibrand/Dashboard";
import MultiBrandAlerts from "pages/Synth2.0/Multibrand/Dashboard/Alerts";
import MultiBrandAlertDetails from "pages/Synth2.0/Multibrand/Dashboard/Alerts/AlertDetails";
import MultiBrandInventory from "pages/Synth2.0/Multibrand/Inventory";
import MultiBrandTeam from "pages/Synth2.0/Multibrand/Team";
import MultiBrandPortfolio from "pages/Synth2.0/Multibrand/Portfolio";
import AddBrand from "pages/Synth2.0/Multibrand/Portfolio/AddBrand";
import AiNotifications from "pages/Synth2.0/Workforce/AiNotification";

import { ChannelProvider } from "ably/react";
import { getFromStorage } from "helpers";
import useGetUserProfile from "hooks/useGetUserProfile";
import Forecast from "pages/Synth2.0/Forecast";
import { AccountPermissions } from "types/permissions";

const AppRoutes = () => {
    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    // Forecast
    const singleBrandDashboardContent = {
        path: "/dashboard",
        element: <Dashboard />,
        permissions: [],
        children: [
            // {
            //     path: "home",
            //     element: (
            //         <ChannelProvider
            //             channelName={`synth-ai-chat-${
            //                 process.env.REACT_APP_STAGE === "production" ? "production" : "staging"
            //             }`}
            //         >
            //             <DashboardHome />
            //         </ChannelProvider>
            //     ),
            //     permissions: [AccountPermissions.all_access, AccountPermissions.view_dashboard],
            // },

            // start of synthally v2.0 routes

            {
                path: "forecast",
                element: <Forecast />,
                permissions: [AccountPermissions.all_access, AccountPermissions.view_dashboard],
            },

            {
                path: "inventory",
                element: <Products />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "forecast",
                element: <Forecast />,
            },
            {
                path: "inventory/mapped-sku/:productId",
                element: <MappedSkuDetail />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/import-products",
                element: <ImportProducts />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/alert/:alertId/:inventoryIdx",
                element: <AlertDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/stock-entry",
                element: <AddStock />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/stock-entry/:id",
                element: <StockDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/edit-stock/:id",
                element: <EditStock />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/stock-adjustment",
                element: <StockAdjustment />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/stock-adjustment/:id",
                element: <StockAdjustmentDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/stock-transfer",
                element: <StockTransfer />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/stock-control/transfer-stock/:id",

                element: <StockTransferDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },

            {
                path: "inventory/sku-mapping/create",
                element: <CreateSku />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/mapped-sku/edit/:productId",
                element: <EditSku />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "order",
                element: <Order />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_sales_order,
                    AccountPermissions.create_sales_order,
                    AccountPermissions.update_sales_order,
                ],
            },
            // {
            //     path: "order/dashboard-details",
            //     element: <ExpandedDetails />,
            //     permissions: [
            //         AccountPermissions.all_access,
            //         AccountPermissions.view_sales_order,
            //         AccountPermissions.create_sales_order,
            //         AccountPermissions.update_sales_order,
            //     ],
            // },
            {
                path: "order/:orderId",
                element: <OrderDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_sales_order,
                    AccountPermissions.create_sales_order,
                    AccountPermissions.update_sales_order,
                ],
            },
            {
                path: "order/:orderId/edit",
                element: <EditOrderStep />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_sales_order,
                    AccountPermissions.create_sales_order,
                    AccountPermissions.update_sales_order,
                ],
            },
            {
                path: "order/create",
                element: <CreateOrder />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_sales_order,
                    AccountPermissions.create_sales_order,
                    AccountPermissions.update_sales_order,
                ],
            },
            {
                path: "quote/:quoteId",
                element: <QuoteSingleDetails />,
                permissions: [],
            },
            {
                path: "quote/create",
                element: <CreateQuote />,
                permissions: [],
            },
            {
                path: "order/settings",
                element: <OrderSettings />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_sales_order,
                    AccountPermissions.create_sales_order,
                    AccountPermissions.update_sales_order,
                ],
            },
            {
                path: "purchase",
                element: <Purchase />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase/:purchaseId",
                element: <PurchaseDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase/shipment/:purchaseId",
                element: <ShipmentPurchaseDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase/:purchaseId/quotes/:quoteId",
                element: <QuoteDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "playground",
                element: <Playground />,
                permissions: [],
            },
            {
                path: "purchase/create-purchase-request",
                element: <CreatePurchaseRequest />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase/edit-purchase-request/:editId",
                element: <EditPurchaseRequest />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },

            {
                path: "purchase/settings",
                element: <PurchaseSettings />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "integrations",
                element: <Integration />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.manage_integrations,
                ],
            },
            {
                path: "integrations/:integrationName/:integrationId",
                element: <IntegrationDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.manage_integrations,
                ],
            },
            {
                path: "inventory/products/:productId",
                element: <ProductDetail />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/global-products/:productId",
                element: <GlobalProductDetail />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "teams",
                element: <TeamManagement />,
                permissions: [AccountPermissions.all_access, AccountPermissions.manage_team],
            },
            {
                path: "suppliers",
                element: <Suppliers />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "suppliers/create-supplier",
                element: <CreateSuppliers />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "suppliers/edit-supplier/:supplierId",
                element: <CreateSuppliers />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "suppliers/:id",
                element: <SupplierDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "suppliers/:id/contract",
                element: <SupplierContract />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "track-trace",
                element: <TrackAndTrace />,
                permissions: [],
            },
            {
                path: "track-trace/:id",
                element: <ProductTrackDetails />,
                permissions: [],
            },
            {
                path: "workforce",
                element: <Workforce />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "workforce/ai-teammate/:aiTeammateId",
                element: <AiTeammateDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "workforce/notifications",
                element: <AiNotifications />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "workforce/edit-ai-teammate/:aiTeammateId",
                element: (
                    <ChannelProvider
                        channelName={`agent_tasks_${
                            userProfile?.user?.parentId || userProfile?.user?._id
                        }`}
                    >
                        {" "}
                        <CreateAiTeammate />
                    </ChannelProvider>
                ),
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "workforce/create-ai-teammate",
                element: (
                    <ChannelProvider
                        channelName={`agent_tasks_${
                            userProfile?.user?.parentId || userProfile?.user?._id
                        }`}
                    >
                        {" "}
                        <CreateAiTeammate />
                    </ChannelProvider>
                ),
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "task",
                element: (
                    <ChannelProvider
                        channelName={`agent_tasks_${
                            userProfile?.user?.parentId || userProfile?.user?._id
                        }`}
                    >
                        <TaskManager />
                    </ChannelProvider>
                ),
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "task/:taskId",
                element: (
                    <ChannelProvider
                        channelName={`agent_tasks_${
                            userProfile?.user?.parentId || userProfile?.user?._id
                        }`}
                    >
                        <TaskDetail />
                    </ChannelProvider>
                ),
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "campaign/:campaignId",
                element: <CampaignDetail />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "task/workorder/:taskId",
                element: <TaskDetailWorkOrder />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "task/:taskId/edit-order",
                element: <EditOrderStep />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            {
                path: "production/settings",
                element: <ProductionSettings />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production",
                element: <Production />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/create-plan",
                element: <CreateProductionPlan />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    // AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/edit-plan/:productionId",
                element: <CreateProductionPlan />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    // AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/:productionId",
                element: <ProductionPlanDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/create-bom",
                element: <CreateBillOfMaterial />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/edit-bom/:id",
                element: <CreateBillOfMaterial />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/bom/:id",
                element: <BillOfMaterialDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/work-order/:workOrderId",
                element: <WorkOrderDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            {
                path: "production/report/:workOrderId",
                element: <ReportDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_production,
                    AccountPermissions.create_production_plan,
                    AccountPermissions.view_production_report,
                ],
            },
            // {
            //     path: "campaign/marketing-assistant/:aiTeammateId",
            //     element: <CampaignOverview />,
            // },
            {
                path: "campaign/plan-campaign/:campaignId",
                element: <PlanCampaign />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_ai_task,
                    AccountPermissions.create_ai_teammate,
                ],
            },
            // end of synthally v2.0 routes

            // start of synthally v1.0 routes
            {
                path: "vendors",
                element: <VendorList />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "vendors/add-vendor",
                element: <AddVendor />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "vendor/:vendorId",
                element: <VendorDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "vendors/analytics",
                element: <VendorAnalytics />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_suppliers,
                    AccountPermissions.create_suppliers,
                ],
            },
            {
                path: "shipment",
                element: <Shipment />,
                permissions: [AccountPermissions.all_access],
            },
            {
                path: "shipment/:shipmentId",
                element: <ShipmentDetails />,
                permissions: [AccountPermissions.all_access],
            },
            {
                path: "inventory/add-product",
                element: <AddProduct />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/edit-product/:productId",
                element: <AddProduct />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    // AccountPermissions.add_stock,
                    // AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/add-bundle",
                element: <AddBundle />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    // AccountPermissions.add_stock,
                    // AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/edit-bundle/:bundleId",
                element: <AddBundle />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    // AccountPermissions.add_stock,
                    // AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/bundle/:bundleId",
                element: <BundleDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },

            {
                path: "inventory/add-warehouse",
                element: <AddWareHouse />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/edit-warehouse/:warehouseId",
                element: <AddWareHouse />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "inventory/warehouse/:id",
                element: <WarehouseDetails />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_inventory,
                    AccountPermissions.create_inventory,
                    AccountPermissions.update_inventory,
                    AccountPermissions.add_stock,
                    AccountPermissions.transfer_stock,
                ],
            },
            {
                path: "purchase-orders",
                element: <PurchaseOrder />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase-orders/:poId",
                element: <PurchaseOrderDetail />,
                permissions: [
                    AccountPermissions.all_access,
                    AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "purchase-orders/edit",
                element: <EditPurchaseOrder />,
                permissions: [
                    AccountPermissions.all_access,
                    // AccountPermissions.view_purchase_order,
                    AccountPermissions.create_purchase_order,
                ],
            },
            {
                path: "team-management",
                element: <TeamManagement />,
                permissions: [AccountPermissions.all_access, AccountPermissions.manage_team],
            },
            {
                path: "settings/:id",
                element: <Settings />,
                permissions: [AccountPermissions.all_access, AccountPermissions.manage_settings],
            },
            // end of synthally v1.0 routes
        ]?.filter((item) => {
            if (
                profile?.role === "owner" ||
                userProfile?.user?.role === "owner" ||
                userProfile?.user?.parent?.role === "owner"
            ) {
                return true;
            } else {
                return !item?.path?.toLowerCase()?.includes("workforce");
            }
        }),
    };

    const multiBrandDashboardContent = {
        path: "/dashboard/portfolio",
        element: <Dashboard />,
        permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
        children: [
            {
                path: "home",
                element: <MultiBrandDashboard />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "incidents",
                element: <MultiBrandAlerts />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "incident/:alertId/:inventoryIdx",
                element: <MultiBrandAlertDetails />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "inventory",
                element: <MultiBrandInventory />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "management",
                element: <MultiBrandPortfolio />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "management/add-brand",
                element: <AddBrand />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "management/add-brand/:brandId",
                element: <AddBrand />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
            {
                path: "team",
                element: <MultiBrandTeam />,
                permissions: [AccountPermissions.brand_admin, AccountPermissions.all_access],
            },
        ],
    };

    const routes = [
        {
            path: "",
            element: <Home />,
            permissions: [],
        },
        {
            path: "/integrations",
            element: <Integrations />,
            permissions: [],
        },
        // {
        //     path: "/book-a-demo",
        //     element: <Home />,
        // },
        {
            path: "/wait-list",
            element: <Home />,
            permissions: [],
        },
        {
            path: "/terms",
            element: <Terms />,
            permissions: [],
        },
        {
            path: "/policy",
            element: <Policy />,
            permissions: [],
        },

        // {
        //     path: "/auth-steps",
        //     element: (
        //         <PrivateRoutes>
        //             <AuthSteps />
        //         </PrivateRoutes>
        //     ),
        // },
        {
            path: "/login",
            element: (
                <PublicRoutes>
                    <Login />
                </PublicRoutes>
            ),
            permissions: [],
        },
        // {
        //     path: "/register",
        //     element: (
        //         <PublicRoutes>
        //             <Signup />
        //         </PublicRoutes>
        //     ),
        // },

        {
            path: "/forgot-password",
            element: (
                <PublicRoutes>
                    <ForgotPassword />
                </PublicRoutes>
            ),
            permissions: [],
        },
        {
            path: "/reset-password",
            element: (
                <PublicRoutes>
                    <ResetPassword />
                </PublicRoutes>
            ),
            permissions: [],
        },
        {
            path: "/registration-success/:token",
            element: (
                <PublicRoutes>
                    <SignupSuccess />
                </PublicRoutes>
            ),
            permissions: [],
        },
        {
            path: "/new-onboard",
            element: (
                <PrivateRoutes>
                    <NewOnboard />
                </PrivateRoutes>
            ),
            permissions: [],
        },
        {
            path: "/verify",
            element: (
                <PublicRoutes>
                    <AcctVerification />
                </PublicRoutes>
            ),
            permissions: [],
        },
        {
            path: "/change-email/:token",
            element: (
                <PublicRoutes>
                    <ChangeVerificationEmail />
                </PublicRoutes>
            ),
            permissions: [],
        },

        {
            path: "/google-auth",
            element: (
                <PublicRoutes>
                    <GoogleAuth />
                </PublicRoutes>
            ),
            permissions: [],
        },
        {
            path: "/integrations/shopify",
            element: (
                // <PublicRoutes>
                <ShopifyAuth />
                // </PublicRoutes>
            ),
            permissions: [],
        },

        {
            path: "*",
            element: <NoMatchPage />,
            permissions: [],
        },
    ];

    const allRoutes: {
        path: string;
        element: React.JSX.Element;
        permissions?: string[];
        children?: {
            path: string;
            element: React.JSX.Element;
            permissions?: string[];
        }[];
    }[] =
        profile?.isMultiBrandAccount ||
        profile?.parent?.isMultiBrandAccount ||
        userProfile?.user?.isMultiBrandAccount ||
        userProfile?.user?.parent?.isMultiBrandAccount
            ? [...routes, singleBrandDashboardContent, multiBrandDashboardContent]
            : [...routes, singleBrandDashboardContent];

    const allowedUserRoutes = allRoutes?.map((route) => {
        if (route?.children) {
            route.children = route.children?.filter((childrenRoute) => {
                const allowedRoutes =
                    childrenRoute?.permissions?.length === 0 ||
                    childrenRoute?.permissions?.some((permission) =>
                        userProfile?.user?.permission?.includes(permission)
                    );

                return allowedRoutes;
            });
        }

        return route;
    });

    return allowedUserRoutes;

    // return profile?.isMultiBrandAccount ||
    //     profile?.parent?.isMultiBrandAccount ||
    //     userProfile?.user?.isMultiBrandAccount ||
    //     userProfile?.user?.parent?.isMultiBrandAccount
    //     ? [...routes, singleBrandDashboardContent, multiBrandDashboardContent]
    //     : [...routes, singleBrandDashboardContent];
};

export default AppRoutes;
