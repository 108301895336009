import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../common/Button";
import PageFrame from "../../../../layout/PageFrame";
import Dropdown from "../../../../common/Dropdown";
import TabsContainer from "../../../../common/TabsContainer";
import TextInput from "../../../../common/InputField/TextInput";
import CustomTable from "../../../../common/CustomTable";
import EmptyStateComp from "../../../../common/EmptyStateComp";
import CalendarView from "./CalendarView";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { marketingCampaignActions } from "redux/Ldb/actions";
import useGetCampaignList from "hooks/ing/useGetCampaignList";

type overviewProps = {
    campaignAlert: boolean;
    setCampaignAlert: (data: boolean) => void;
    setCampaignModal: (data: boolean) => void;
};

const ListView = ({ campaignAlert, setCampaignAlert, setCampaignModal }: overviewProps) => {
    const navigate = useNavigate();
    const customRef = useRef(null);
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [viewType, setViewType] = useState<string>("grid");
    const [gridDate, setGridDate] = useState<any>(new Date());
    const [campaignCalendarList, setCampaignCalendarList] = useState<{ [key: string]: any }[]>([]);
    const [search, setSearch] = useState<string>("");
    const [view, setView] = useState<string>("month");

    const [mismatch, setMismatch] = useState<boolean>(false);
    const tabs = ["All campaigns", "Upcoming campaigns", "Completed campaigns"];

    const { fetchAllCampaignCalendarViewSuccess, fetchingAllCampaignCalendarView } = useAppSelector(
        (state) => state.marketingCampaign
    );


    const {
        data: campaignList,
        isLoading,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        status,
        setStatus,
        setType,
    } = useGetCampaignList();

    useEffect(() => {
        if (activeTab === 0) {
            setStatus("");
        } else if (activeTab === 1) {
            setStatus("upcoming");
        } else {
            setStatus("completed");
        }
    }, [activeTab]);

    useEffect(() => {
        setType(viewType);
    }, [viewType]);

    useEffect(() => {
        if (viewType === "grid") {
            dispatch(marketingCampaignActions?.getAllCampaignsCalendar(debouncedSearch, status));
        }
    }, [dispatch, debouncedSearch, status, viewType]);

    useEffect(() => {
        if (Boolean(fetchAllCampaignCalendarViewSuccess)) {
            setCampaignCalendarList(fetchAllCampaignCalendarViewSuccess?.campaigns);
        }
    }, [dispatch, fetchAllCampaignCalendarViewSuccess]);

    const TableHeader = [
        { title: "Campaign", widthClass: "w-[30%]" },
        { title: "Start Date", widthClass: "w-[15%]" },
        { title: "End Date", widthClass: "w-[15%]" },
        { title: "Campaign channel", widthClass: "w-[20%]" },
        { title: "Status", widthClass: "w-[16%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const TableBody = campaignList?.map((campaign, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                    >
                        <div className=" w-full">
                            <span className="text-sm bg-slate-100 w-fit rounded-lg h-6 px-1 block mb-[2px]">
                                #{campaign?.campaignId}
                            </span>
                        </div>
                        <p className="text-slate-500">"{campaign?.campaignName}"</p>
                    </div>
                ),
                cellClickAction: () => navigate(`/dashboard/campaign/${campaign?._id}`),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>{moment(campaign?.startDate)?.format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>{moment(campaign?.endDate)?.format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm  gap-2 `}
                    >
                        <div className="flex items-center space-x-3">
                            <img
                                src={
                                    campaign?.channel === "google"
                                        ? "https://storage.googleapis.com/heysynth_files/app/google_ads.svg"
                                        : campaign?.channel === "tiktok"
                                        ? "https://storage.googleapis.com/heysynth_files/app/tiktokIcon.svg"
                                        : campaign?.channel === "klaviyo" &&
                                          "https://storage.googleapis.com/heysynth_files/app/klaviyo_logo.svg.svg"
                                }
                                alt=""
                                className=""
                            />
                            <p className="text-slate-900">{campaign?.channel}</p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <div className="flex items-center gap-2 text-sm">
                            {campaign?.status && (
                                <div
                                    className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       campaign?.status === "completed"
                                           ? "bg-[#0054C2]"
                                           : campaign?.status === "inprogress"
                                           ? "bg-[#56B648]"
                                           : campaign?.status === "upcoming"
                                           ? "bg-[#FFB902]"
                                           : "bg-[#303437]"
                                   }
                                    `}
                                ></div>
                            )}
                            <span className="capitalize">
                                {campaign?.status === "inprogress"
                                    ? "In progress"
                                    : campaign?.status}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View details",
                                    value: "view",
                                    action: () => navigate(`/dashboard/campaign/${campaign?._id}`),
                                },
                            ]}
                        />
                    </div>
                ),
            },
        ];
    });

    const emptyAndSearchState = () => {
        return (search || (status && activeTab === 0)) &&
            ((campaignList?.length === 0 && viewType === "list") ||
                (campaignCalendarList?.length === 0 && viewType === "grid")) ? (
            <EmptyStateComp
                title=""
                description=" Sorry, no results match your search. Refine your criteria and
                                    try again."
                icon={<i className="ri-building-3-line text text-[40px]"></i>}
                contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                descriptionClass="flex flex-col items-center justify-center"
                className={`${viewType !== "list" && "absolute top-[40%] z-[1000]"}`}
            />
        ) : (
            ((campaignList?.length === 0 && viewType === "list") ||
                (campaignCalendarList?.length === 0 && viewType === "grid")) && (
                <div className={`${viewType !== "list" && "border-x border-b border-slate-200"}`}>
                    <EmptyStateComp
                        title="No campaign found"
                        description="It looks like you don't have any campaign at the moment. To get started, plan a new campaign now!"
                        icon={<i className="ri-calendar-todo-line text text-[40px]"></i>}
                        hasButton={true}
                        btnText="Plan a campaign"
                        btnAction={() =>
                            setCampaignModal(true)
                        }
                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white !px-6 !text-center"
                        descriptionClass="flex flex-col items-center"
                        className={`${viewType !== "list" && "absolute top-[30%] z-[1000]"}`}
                    />
                </div>
            )
        );
    };
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
            case 1:
            case 2:
                return viewType === "list" ? (
                    isLoading && campaignList?.length === 0 ? (
                        <div className="overflow-x-hidden ">
                            <CustomTableLoader tableHeader={TableHeader} />
                        </div>
                    ) : campaignList?.length > 0 ? (
                        <div>
                            <CustomTable
                                tableBody={TableBody}
                                tableHeader={TableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />

                            {isLoadingMore && (
                                <div className="w-full flex justify-center my-3">
                                    <Loader size={4} />
                                </div>
                            )}

                            {pagination?.currentPage === pagination?.noOfPages && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <>{emptyAndSearchState()}</>
                    )
                ) : (
                    <div className="">
                        <CalendarView
                            setGridDate={setGridDate}
                            gridDate={gridDate}
                            view={view}
                            setView={setView}
                            data={campaignCalendarList}
                        />
                        {isLoading && campaignList?.length === 0 ? (
                            <div
                                className={`flex items-center justify-center border border-slate-200 rounded-lg shadow-[0px_8px_8px_-4px_#10182808] max-sm:w-full max-sm:p-5`}
                            >
                                <Loader size={4} />
                            </div>
                        ) : (
                            emptyAndSearchState()
                        )}
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <PageFrame>
            <div className="relative">
                <div className="font-rocGroteskMedium">
                    <div className="">
                        {/* {campaignAlert && (
                                <div
                                    className={`mb-8 px-[18px] py-3.5 border-l-4 ${
                                        mismatch
                                            ? "border-l-[#E4281D] bg-[#FEBA892E]"
                                            : "border-l-[#FFB902] bg-[#FFD66B2E]"
                                    }   rounded-[10px] w-full flex gap-2 items-start`}
                                >
                                    <i
                                        className={`ri-notification-3-fill ${
                                            mismatch
                                                ? "from-[#FF5500] to-[#FFC280]"
                                                : "from-[#FFAB00] to-[#FFE380]"
                                        } bg-gradient-to-b  bg-clip-text text-transparent fill-current text-xl`}
                                    ></i>

                                    <div className="w-full flex justify-between">
                                        <div className="text-slate-900 text-[13px] ">
                                            <p className="font-rocGroteskBold">
                                                {mismatch
                                                    ? "SKU/Product name mismatch detected!"
                                                    : "New campaign created!"}
                                            </p>
                                            <p className="">Campaign name: “Gooble Holidays”</p>
                                            <p className="">Targeted region: California, USA</p>

                                            <Button
                                                btnText={
                                                    mismatch
                                                        ? "Correct matching errors"
                                                        : "View details"
                                                }
                                                btnClassname="!py-0 !px-0 cursor-pointer !text-[14px] !text-slate-900 !bg-transparent !font-rocGroteskBold !w-fit  !mt-1"
                                                btnTextClassName="!underline"
                                                btnType="textFirst"
                                                icon=<i className="ri-arrow-right-up-line text-xl text-gm-50"></i>
                                            />
                                        </div>
                                        <i
                                            className="ri-close-line cursor-pointer text-gm-50"
                                            onClick={() => setCampaignAlert(false)}
                                        ></i>
                                    </div>
                                </div>
                            )} */}

                        
                        <div>
                            <TabsContainer
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={(idx) => {
                                    setActiveTab(idx);
                                }}
                                component={displayActiveTab()}
                                className="!px-0"
                                showButtonClassName
                                itemClassName="!pb-2 max-lg:whitespace-nowrap"
                                borderLineClase={
                                    "!text-slate-100 w-[40%] mb-6 border-b border-[#F1F5F9]"
                                }
                                showButton={
                                    <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                        <div className="flex">
                                            <div className="border-slate-200 border rounded">
                                                <button
                                                    className={`${
                                                        viewType === "grid" && "bg-slate-200"
                                                    } py-[6px] px-3 text-gm-50 text-[13px] rounded-l`}
                                                    onClick={() => setViewType("grid")}
                                                >
                                                    <i className="ri-layout-grid-line"></i>
                                                </button>
                                                <button
                                                    className={`${
                                                        viewType === "list" && "bg-slate-200"
                                                    } py-[6px] px-3 text-gm-50 text-[13px]`}
                                                    onClick={() => setViewType("list")}
                                                >
                                                    <i className="ri-list-check"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <TextInput
                                            name={"search"}
                                            value={search}
                                            type={"text"}
                                            inputPlaceholder={"Search campaigns"}
                                            inputClassName={
                                                "!h-[38px] pl-[0px] !bg-white text-sm !mb-0"
                                            }
                                            onChange={(e) => setSearch(e?.target?.value)}
                                            onInput={debounce((e) => {
                                                setDebouncedSearch(e?.target?.value);
                                            }, 800)}
                                            containerClassname={"!w-[250px] max-sm:!w-full"}
                                            leftIcon={
                                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                            }
                                            inputContainerClassname={
                                                "!rounded-[4px] !pl-[25px] !h-[40px] !bg-white !border !border-slate-200"
                                            }
                                        />

                                        {activeTab === 0 && (
                                            <Dropdown
                                                name="filter"
                                                value={""}
                                                dropdownTriggerClassName={" "}
                                                handleChange={(name, value) => {
                                                    setStatus(value);
                                                }}
                                                dropdown={
                                                    <div className="bg-white flex items-center justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                                            {status === "inprogress"
                                                                ? "In progress"
                                                                : status
                                                                ? status
                                                                : "Status"}
                                                        </span>

                                                        <div className="flex items-center space-x-2">
                                                            {status && (
                                                                <i
                                                                    onClick={(e) => {
                                                                        e?.stopPropagation();

                                                                        setStatus("");
                                                                    }}
                                                                    className="ri-close-circle-fill text-sm text-slate-500"
                                                                ></i>
                                                            )}
                                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                        </div>
                                                    </div>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                        !p-0 !top-[105%]`}
                                                wholeContainerClass={`max-sm:!w-full`}
                                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                                dropdownOptions={[
                                                    {
                                                        label: "Completed",
                                                        value: "completed",
                                                    },
                                                    {
                                                        label: "In Progress",
                                                        value: "inprogress",
                                                    },
                                                    {
                                                        label: "Pending",
                                                        value: "pending",
                                                    },
                                                    {
                                                        label: "Upcoming",
                                                        value: "upcoming",
                                                    },
                                                ]}
                                            />
                                        )}

                                        <Button
                                            btnText={"Settings"}
                                            type={"button"}
                                            btnClassname="!text-[13px] !bg-white !py-2 !px-4 !w-fit !whitespace-nowrap border border-slate-200"
                                            onClick={() => navigate("/dashboard/settings/3")}
                                            icon={<i className="ri-settings-2-line"></i>}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default ListView;
