import React, { useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown";

const MarketingAgentCalendar = ({
    setGridDate,
    gridDate,
    setView,
    view,
    campaigns,
    setCalendarViewDetailsModal,
    setCampaignId,
}) => {
    const navigate = useNavigate();
    const localizer = momentLocalizer(moment);
    const period = ["Day", "Week", "Month"];
    const calendarRef = useRef(null);
    const [durationFilter, setDurationFilter] = useState("Month");

    const CustomToolbar = ({ label, onNavigate }) => {
        return (
            <div className="flex items-center justify-between p-4 border-t border-x border-slate-200 rounded-t-lg w-full">
                <div className="flex gap-2">
                    <button
                        onClick={() => onNavigate("PREV")}
                        className="bg-slate-100 rounded-md w-8 h-8 flex items-center justify-center"
                    >
                        <i className="ri-arrow-left-line text-gm-50 text-sm"></i>
                    </button>

                    <button
                        onClick={() => onNavigate("NEXT")}
                        className="bg-slate-100 rounded-md w-8 h-8 flex items-center justify-center"
                    >
                        <i className="ri-arrow-right-line text-gm-50 text-sm"></i>
                    </button>
                </div>
                <p className="text-base text-g-75 ">{moment(gridDate).format("MMM Do, YYYY")}</p>
                <Dropdown
                    handleChange={(name, value) => {
                        setDurationFilter(value);
                    }}
                    dropdown={
                        <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-1.5 px-3 !border !border-slate-100 gap-2">
                            <div
                                className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                            >
                                {durationFilter}
                            </div>
                            <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                        </div>
                    }
                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                    dropdownClassName="!w-full !mb-10 !-mt-7"
                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                    name="valueRange"
                    dropdownOptions={period?.map((item, idx) => ({
                        label: item,
                        value: item,
                        action: () => {
                            setView(item.toLowerCase());
                        },
                    }))}
                />
            </div>
        );
    };

    const CustomEvent = ({ event, idx }) => (
        <div
            className=""
            style={{
                padding: "2px 5px",
                fontSize: "12px",
                borderWidth: "0.5px",
                borderRadius: "8px",
                ...(event?.status === "completed"
                    ? {
                          backgroundColor: "#F2FFFF",
                          borderColor: "#4EAFFF",
                          color: "#003AB9",
                      }
                    : event?.status === "inprogress"
                    ? {
                          backgroundColor: "#F2FFF4",
                          borderColor: "#4EFF92",
                          color: "#009A3B",
                      }
                    : event?.status === "upcoming"
                    ? {
                          backgroundColor: "#FFFBF2",
                          borderColor: "#FFD64E",
                          color: "#F7A700",
                      }
                    : {
                          backgroundColor: "#FFFBF2",
                          borderColor: "#FFD64E",
                          color: "#F7A700",
                      }),
            }}
        >
            <div className="flex items-center gap-2">
                <i
                    className={`${
                        event?.status === "completed"
                            ? "ri-price-tag-3-line from-[#0037B6] to-[#00A1F8]"
                            : event?.status === "inprogress"
                            ? "ri-price-tag-3-line from-[#00B664] to-[#00EE4B]"
                            : event?.status === "upcoming"
                            ? "ri-shopping-bag-line from-[#FFAB00] to-[#FFE380]"
                            : "ri-shopping-bag-line from-[#FFAB00] to-[#FFE380]"
                    } bg-gradient-to-b  bg-clip-text text-transparent fill-current text-lg`}
                ></i>
                <div className="">
                    <p className="mb-[-10px]">{`"${event.title}" campaign`}</p>
                    <Button
                        btnText="View details"
                        btnClassname="!py-0 !px-0 cursor-pointer !text-[12px] !text-slate-600 !bg-transparent !font-rocGroteskMedium !w-fit  !mt-1"
                        btnTextClassName="!underline"
                        btnType="textFirst"
                        icon=<i className="ri-arrow-right-line text-lg text-slate-600"></i>
                        onClick={() => {
                            setCampaignId(event?.id);
                            setCalendarViewDetailsModal(true);
                        }}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="marketing">
            <Calendar
                localizer={localizer}
                events={campaigns}
                ref={calendarRef}
                startAccessor="start"
                endAccessor="end"
                initialView="dayGridDAY"
                style={{ height: 1200 }}
                date={gridDate}
                onNavigate={(newDate) => setGridDate(newDate)}
                view={view}
                onView={setView}
                selectable={false}
                components={{
                    event: CustomEvent,
                    toolbar: CustomToolbar,
                }}
                formats={{
                    timeGutterFormat: "h a",
                    dayFormat: "ddd D",

                    agendaTimeFormat: {
                        hour: "numeric",
                        minute: "numeric",
                        meridiem: "short",
                    },
                    eventTimeRangeFormat: ({ start, end }) => {
                        return `${moment(start).format("ha")} - ${moment(end).format("ha")}`;
                    },
                }}
            />
        </div>
    );
};

export default MarketingAgentCalendar;
