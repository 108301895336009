import React from "react";

export const displayProductChannelIcon = (channel: string, className?: string) => {
    switch (channel?.toLowerCase()) {
        case "quickbooks":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg"
                    alt="quickbooks"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "quickbooks desktop":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/quickbooksIcon_poqahg.svg"
                    alt="quickbooks desktop"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "sps-commerce":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/sps_commerce_v0uhiz.png"
                    alt="sps commerce"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "shopify":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/shopifyIcon_qelbea.svg"
                    alt="shopify"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "amazon":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/amazon_ciygng.svg"
                    alt="amazon"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "gmail":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_1_zya4ai.svg"
                    alt="gmail"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "gmail-full":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/gmail_logo_dtfhgg.jpg"
                    alt="gmail"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "microsoft":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/microsoft-outlook_owky6v.png"
                    alt="microsoft"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "outlook":
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/microsoft-outlook_owky6v.png"
                    alt="outlook"
                    className={`w-6 h-6 ${className}`}
                />
            );
        default:
            return (
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/synthIconOnly_rcj7zd.svg"
                    alt="synth"
                    className={`w-6 h-6 ${className}`}
                />
            );
    }
};
