import Dropdown from "./../../../components/common/Dropdown";
import React, { Dispatch, SetStateAction } from "react";
import TaskColumnContainer from "./../../../components/common/TaskColumnContainer";
import TaskCard from "./../../../components/common/TaskCard";
import { camelCaseToRegularCase } from "./../../../helpers/camelCaseToRegularCase";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { onScroll } from "./../../../helpers";
import Loader from "./../../../components/common/Loader";
import { useAppSelector } from "./../../../hooks/storeHooks";

type GridViewProps = {
    pendingTasks: { [key: string]: any }[];
    completedTasks: { [key: string]: any }[];
    failedTasks: { [key: string]: any }[];
    pagination?: { currentPage: string; noOfPages: string }[];
    paginationCompleted?: { currentPage: string; noOfPages: string };
    paginationFailed?: { currentPage: string; noOfPages: string };
    setUpdatedPage?: Dispatch<SetStateAction<number>>[];
    setLoadMore?: Dispatch<SetStateAction<boolean>>[];
    humanStatus?: string;
};

const GridViewHumanTask = ({
    pendingTasks,
    completedTasks,
    failedTasks,
    pagination,
    setUpdatedPage,
    setLoadMore,
    humanStatus,
}: GridViewProps) => {
    const navigate = useNavigate();
    const { fetchingTaskList } = useAppSelector((state) => state.workforce);

    return (
        <div className="grid grid-cols-3 space-x-1.5">
            <TaskColumnContainer
                title={"Pending tasks"}
                icon={<i className={`ri-hourglass-line text-[#FF8A0099] text-base`}></i>}
                total={pendingTasks?.length}
                borderColor={"#FF8A00"}
                pagination={pagination[0]}
                setUpdatedPage={setUpdatedPage[0]}
                setLoadMore={setLoadMore[0]}
            >
                <>
                    {pendingTasks?.map((task, idx) => {
                        const startDate = moment(task?.createdAt)?.format("MMM DD, YYYY hh:mm A");
                        return (
                            <TaskCard
                                key={task?._id}
                                title={"QC tasks for"}
                                workOrderId={`WO-${task?.workOrderTaId}`}
                                description={camelCaseToRegularCase(task?.task)}
                                time={""}
                                startDate={startDate}
                                createdBy={`Created by: ${
                                    task?.createdBy?.fullName || task?.createdBy?.email
                                }`}
                                profileImage={task?.createdBy?.profile?.avatar || ""}
                                userAttentionRequired={false}
                                userProfileImg={task?.assignee?.profile?.avatar || true}
                                onClick={() =>
                                    navigate(`/dashboard/task/workOrder/${task?._id}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {(humanStatus === "pending" || !humanStatus) &&
                        pagination[0]?.currentPage === pagination[0]?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                </>
            </TaskColumnContainer>

            <TaskColumnContainer
                title={"Completed/Approved tasks"}
                icon={<i className={`ri-checkbox-multiple-line text-[#117FFF] text-base`}></i>}
                total={completedTasks?.length}
                borderColor={"#117FFF"}
                pagination={pagination[1]}
                setUpdatedPage={setUpdatedPage[1]}
                setLoadMore={setLoadMore[1]}
            >
                <>
                    {completedTasks?.map((task, idx) => {
                        const startDate = moment(task?.createdAt)?.format("MMM DD, YYYY hh:mm A");
                        return (
                            // <TaskCard
                            //     key={task?._id}
                            //     title={"QC tasks for WO-2024-08-15-001"}
                            //     description={camelCaseToRegularCase(task?.taskType)}
                            //     time={""}
                            //     createdBy={"Created by: Sarah Drasña"}
                            //     userAttentionRequired={false}
                            //     avatarUrl={task?.agent?.avatar}
                            //     onClick={() => navigate(`/dashboard/task/${task?._id}`)}
                            // />
                            <TaskCard
                                key={task?._id}
                                title={"QC tasks for"}
                                workOrderId={`WO-${task?.workOrderTaId}`}
                                description={camelCaseToRegularCase(task?.task)}
                                time={""}
                                startDate={startDate}
                                createdBy={`Created by: ${
                                    task?.createdBy?.fullName || task?.createdBy?.email
                                }`}
                                profileImage={task?.createdBy?.profile?.avatar || ""}
                                userAttentionRequired={false}
                                userProfileImg={task?.assignee?.profile?.avatar || true}
                                onClick={() =>
                                    navigate(`/dashboard/task/workOrder/${task?._id}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {(humanStatus === "completed" || !humanStatus) &&
                        pagination[1]?.currentPage === pagination[1]?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                </>
            </TaskColumnContainer>

            <TaskColumnContainer
                title={"Rejected/Failed task"}
                icon={<i className={`ri-close-line text-[#E4281D] text-base`}></i>}
                total={failedTasks?.length}
                borderColor={"#E4281D"}
                pagination={pagination[2]}
                setUpdatedPage={setUpdatedPage[2]}
                setLoadMore={setLoadMore[2]}
            >
                <>
                    {failedTasks?.map((task, idx) => {
                        const startDate = moment(task?.createdAt)?.format("MMM DD, YYYY hh:mm A");
                        return (
                            <TaskCard
                                key={task?._id}
                                title={"QC tasks for"}
                                workOrderId={`WO-${task?.workOrderTaId}`}
                                description={camelCaseToRegularCase(task?.task)}
                                time={""}
                                startDate={startDate}
                                createdBy={`Created by: ${
                                    task?.createdBy?.fullName || task?.createdBy?.email
                                }`}
                                profileImage={task?.createdBy?.profile?.avatar || ""}
                                userAttentionRequired={false}
                                userProfileImg={task?.assignee?.profile?.avatar || true}
                                onClick={() =>
                                    navigate(`/dashboard/task/workOrder/${task?._id}`, {
                                        state: { internalNavigation: true },
                                    })
                                }
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {(humanStatus === "failed" || !humanStatus) &&
                        pagination[2]?.currentPage === pagination[2]?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                </>
            </TaskColumnContainer>
        </div>
    );
};

export default GridViewHumanTask;
