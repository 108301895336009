export const getChannelLoginUrl = (channel: string) => {
    switch (channel?.toLowerCase()) {
        case "klaviyo":
            return "https://www.klaviyo.com/login";
        case "tiktok":
            return "https://www.tiktok.com/login";
        case "google ads":
            return "https://business.google.com/ca-en/google-ads/";
        default:
            return "#";
    }
};
