import React from "react";
import CustomToast from "components/common/CustomToast";
import { vendorTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { vendorService, websiteService } from "services/Ldb";
import { Dispatch } from "redux";

export const vendorActions = {
    joinWaitlist,
    resetJoinWaitListSuccess,
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    resetAuthenticateGmailSuccess,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
    getOnboardedVendors,
    getSuggestedVendors,
    resetGetSuggestedVendorsSuccess,
    resetConnectGoogleWorkspaceSuccess,
    authenticateMicrosoftAcct,
    connectMicrosoftWorkspace,
    resetAuthenticateMicrosoftSuccess,
    resetConnectMicrosoftWorkspaceSuccess,
    getVendorPo,
    manageConnection,
    resetManageConnectionSuccess,
    getVendorActivities,
    resetGetVendorActivitiesSuccess,
    authenticateGmailSettings,
    connectGoogleWorkspaceSettings,
    authenticateMicrosoftAcctSettings,
    connectMicrosoftWorkspaceSettings,
    resetAuthenticateGmailSettingsSuccess,
    resetAuthenticateMicrosoftSettingsSuccess,
    resetConnectMicrosoftWorkspaceSettingsSuccess,
    resetConnectGoogleWorkspaceSettingsSuccess,
    getVendorByCategory,
    resetAddVendorSuccess,
    addVendor,
    updateVendor,
    fetchVendors,
    fetchSupplierAnalytics,
    fetchSupplierProduct,
    fetchSupplierOngoingPo,
    fetchSupplierQuotes,
    fetchSupplierDetails,
    deleteSupplier,
    fetchSupplierReturnedGoods,
    fetchSupplierProductHistory,
    resetUpdateVendorSuccess,
    resetDeleteVendor,
};

function joinWaitlist(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.JOIN_WAITLIST_REQUEST));
        websiteService.joinWaitList(data).then(
            (res) => {
                dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.JOIN_WAITLIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getLdbVendors(
    vendorLimit?: string | number,
    search?: string,
    status?: string,
    source?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_LDB_VENDORS_REQUEST));

        vendorService.getLdbVendors(vendorLimit, search, status, source).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_LDB_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_LDB_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getOnboardedVendors() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST));

        vendorService.getOnboardedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSuggestedVendors() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST));

        vendorService.getSuggestedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleVendor(vendorId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SINGLE_VENDOR_REQUEST));

        vendorService.getSingleVendor(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SINGLE_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateGmail() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_GMAIL_REQUEST));

        vendorService.authenticateGmail().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_GMAIL_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateGmailSettings() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_REQUEST));

        vendorService.authenticateGmailSettings().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectGoogleWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST));

        vendorService.connectGoogleWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE, error?.message));
                }
            }
        );
    };
}
function connectGoogleWorkspaceSettings(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST));

        vendorService.connectGoogleWorkspaceSettings(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function manageConnection(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.MANAGE_CONNECTION_REQUEST));

        vendorService.manageConnection(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.MANAGE_CONNECTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.MANAGE_CONNECTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateMicrosoftAcct() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST));

        vendorService.authenticateMicrosoftAcct().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateMicrosoftAcctSettings() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST));

        vendorService.authenticateMicrosoftAcctSettings().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectMicrosoftWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST));

        vendorService.connectMicrosoftWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectMicrosoftWorkspaceSettings(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST));

        vendorService.connectMicrosoftWorkspaceSettings(reqObj).then(
            (res) => {
                dispatch(
                    success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getAllChannels(search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_ALL_CHANNELS_REQUEST));

        vendorService.getAllChannels(search).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ALL_CHANNELS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ALL_CHANNELS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorsByOrderType(orderType: string = "supply", search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST));

        vendorService.getVendorsByOrderType(orderType, search).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getVendorPo(vendorId: string, limit?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_PO_REQUEST));

        vendorService.getVendorPo(vendorId, limit).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_PO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_PO_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorActivities(vendorId) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_ACTIVITIES_REQUEST));

        vendorService.getVendorActivities(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_ACTIVITIES_FAILURE, error?.message));
                }
            }
        );
    };
}
function getVendorByCategory(category, startDate, endDate) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_BY_CATEGORY_REQUEST));

        vendorService.getVendorByCategory(category, startDate, endDate).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_BY_CATEGORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_BY_CATEGORY_FAILURE, error?.message));
                }
            }
        );
    };
}
function addVendor(data) {
    return (dispatch) => {
        dispatch(request(vendorTypes.ADD_VENDOR_REQUEST));

        vendorService.addVendor(data).then(
            (res) => {
                dispatch(success(vendorTypes.ADD_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.ADD_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateVendor(data, id) {
    return (dispatch) => {
        dispatch(request(vendorTypes.UPDATE_VENDOR_REQUEST));

        vendorService.updateVendor(data, id).then(
            (res) => {
                dispatch(success(vendorTypes.UPDATE_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.UPDATE_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchVendors(search?: string, risk?: string, limit?: number, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDORS_REQUEST));

        vendorService.listVendors(search, risk, limit, page).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSupplierAnalytics(changeValue?: string, startDate?: string, endDate?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_ANALYTICS_REQUEST));

        vendorService.supplierAnalytics(changeValue, startDate, endDate).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUPPLIER_ANALYTICS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSupplierProduct(supplierId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_PRODUCTS_REQUEST));

        vendorService.supplierProduct(supplierId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_PRODUCTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUPPLIER_PRODUCTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSupplierOngoingPo(supplierId: string, limit) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_ONGOING_PO_REQUEST));

        vendorService.supplierOngoingPo(supplierId, limit).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_ONGOING_PO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_SUPPLIER_ONGOING_PO_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSupplierQuotes(supplierId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_QUOTES_REQUEST));

        vendorService.supplierQuotes(supplierId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUPPLIER_QUOTES_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchSupplierDetails(supplierId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_DETAILS_REQUEST));

        vendorService.supplierDetails(supplierId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_DETAILS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUPPLIER_DETAILS_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteSupplier(supplierId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.DELETE_SUPPLIER_REQUEST));

        vendorService.deleteSupplier(supplierId).then(
            (res) => {
                dispatch(success(vendorTypes.DELETE_SUPPLIER_SUCCESS, res));
            },
            (error) => {
                if (error.message) {
                    dispatch(failure(vendorTypes.DELETE_SUPPLIER_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchSupplierProductHistory(supplierId: string, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_REQUEST));

        vendorService.supplierProductHistory(supplierId, inventoryId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchSupplierReturnedGoods(supplierId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_REQUEST));

        vendorService.supplierReturnedGoods(supplierId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetGetVendorActivitiesSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS, null));
    };
}

function resetConnectMicrosoftWorkspaceSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, null));
    };
}

function resetConnectMicrosoftWorkspaceSettingsSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS, null));
    };
}

function resetAuthenticateMicrosoftSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, null));
    };
}
function resetAuthenticateMicrosoftSettingsSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS, null));
    };
}

function resetConnectGoogleWorkspaceSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, null));
    };
}
function resetConnectGoogleWorkspaceSettingsSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS, null));
    };
}
function resetAuthenticateGmailSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, null));
    };
}

function resetAuthenticateGmailSettingsSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS, null));
    };
}

function resetGetSuggestedVendorsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, null));
    };
}
function resetManageConnectionSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.MANAGE_CONNECTION_SUCCESS, null));
    };
}
function resetJoinWaitListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, null));
    };
}

function resetAddVendorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.ADD_VENDOR_SUCCESS, null));
    };
}
function resetUpdateVendorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.UPDATE_VENDOR_SUCCESS, null));
    };
}

function resetDeleteVendor() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.DELETE_SUPPLIER_SUCCESS, null));
        dispatch(success(vendorTypes.DELETE_SUPPLIER_FAILURE, false));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data: any) {
    return { type: type, payload: data };
}
function failure(type: string, error: any) {
    return { type: type, payload: error ?? "" };
}
