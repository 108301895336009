import Button from "components/common/Button";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { authActions } from "redux/Ldb/actions";

type approvalTypes = {
    approvalWorkflow: any;
    productName: string;
    // approversId?: string[];
    type?: string;
};

const ApprovalWorkflow = ({ approvalWorkflow, productName, type }: approvalTypes) => {
    const dispatch = useAppDispatch();
    const [approved, setApproved] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [approvers, setApprovers] = useState<any>([]);
    const [rejectors, setRejectors] = useState<any>([]);
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [allApprovers, setAllApprovers] = useState<{ [key: string]: any }[]>([]);
    const [approvedIds, setApprovedIds] = useState<string[]>([]);
    const [rejectorsIds, setRejectorsIds] = useState<string[]>([]);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        setApprovers(approvalWorkflow?.approvers);
        setRejectors(approvalWorkflow?.rejectors);
        setApprovedIds(approvalWorkflow?.approverIds);
        setRejectorsIds(approvalWorkflow?.rejectorIds);
    }, [approvalWorkflow]);

    const filterApprove = (id) => {
        return approvers?.length >= 1
            ? approvers?.filter((approve) => approve?.approver?._id === id)
            : rejectors?.length >= 1
            ? rejectors?.filter((reject) => reject?.rejector?._id === id)
            : "";
    };

    console.log(approvalWorkflow, "allowedApprovers");
    return (
        <>
            <div className="font-rocGroteskMedium">
                <p className="text-gm-50 text-base pb-6">
                    Approval workflow {productName && `for ${productName}`}
                </p>

                <div
                    className={` ${
                        approvalWorkflow?.allowedApprovers?.length >= 1
                            ? "border border-slate-200 rounded-md"
                            : ""
                    } `}
                >
                    <>
                        {approvalWorkflow?.allowedApprovers?.map((approver, idx) => (
                            <div
                                key={idx}
                                className={`${
                                    approvers?.length - 1 !== idx
                                        ? "border-b border-b-slate-200"
                                        : ""
                                } p-4  space-y-3 shadow-boxShadow-12`}
                            >
                                <div className="flex gap-4 items-center">
                                    {approver?.avatar ? (
                                        <VendorAvatar
                                            imageSrc={approver?.avatar}
                                            size={32}
                                            containerClassname="!rounded-md"
                                        />
                                    ) : (
                                        <div className="w-9 h-9 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                            <i className="ri-user-follow-line text-md text-gm-50"></i>
                                        </div>
                                    )}

                                    <div className="">
                                        <p className="text-g-75">
                                            {approver?.fullName || approver?.email}{" "}
                                            <span className="text-slate-500 text-sm capitalize">
                                                {" "}
                                                - {approver.role}
                                            </span>
                                        </p>
                                        <p className="text-slate-500 text-sm ">
                                            {" "}
                                            {approvedIds?.includes(approver?._id)
                                                ? " Date Approved:"
                                                : rejectorsIds?.includes(approver?._id)
                                                ? " Date Rejected:"
                                                : ""}
                                            {` `}
                                            {filterApprove(approver?._id)[0] &&
                                                ` ${moment(
                                                    filterApprove(approver?._id)[0]?.date
                                                )?.format("MMM DD, YYYY, H:MM a")}`}{" "}
                                        </p>
                                    </div>
                                </div>

                                {filterApprove(approver?._id)[0] ? (
                                    <p className="text-g-75 text-sm">
                                        {filterApprove(approver?._id)[0]?.reason ||
                                            filterApprove(approver?._id)[0]?.comment}
                                    </p>
                                ) : (
                                    ""
                                )}

                                <div className="flex gap-2">
                                    <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                        Level {idx + 1}
                                    </div>

                                    {approvedIds?.includes(approver?._id) ? (
                                        <div className="py-1 px-3 bg-[#D0F4ED] rounded text-xs text-g-75 ">
                                            Approved
                                        </div>
                                    ) : rejectorsIds?.includes(approver?._id) ? (
                                        <div className="py-1 px-3 bg-[#e96868] rounded text-xs text-g-75 ">
                                            Rejected
                                        </div>
                                    ) : (
                                        <div className="py-1 px-3 bg-[#F9DFB3] rounded text-xs text-g-75 ">
                                            Pending
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        {/* {rejectors?.map((rejector, idx) => (
                            <div
                                key={idx}
                                className={`${
                                    rejectors?.length - 1 !== idx
                                        ? "border-b border-b-slate-200"
                                        : ""
                                } p-4  space-y-3 shadow-boxShadow-12`}
                            >
                                <div className="flex gap-4 items-center">
                                    {rejector?.profile?.avatar ? (
                                        <VendorAvatar
                                            imageSrc={rejector?.profile?.avatar}
                                            size={32}
                                            containerClassname="!rounded-md"
                                        />
                                    ) : (
                                        <div className="w-9 h-9 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                            <i className="ri-user-follow-line text-md text-gm-50"></i>
                                        </div>
                                    )}

                                    <div className="">
                                        <p className="text-g-75 text-sm">
                                            {rejector?.rejector?.fullName ||
                                                rejector?.rejector?.email}
                                            <span className="text-slate-500 text-sm capitalize">
                                                {" "}
                                                - {rejector.rejector?.role}
                                            </span>
                                        </p>
                                        <p className="text-slate-500 text-sm ">
                                            Date Rejected:
                                            {rejector?.date &&
                                                ` ${moment(rejector?.date)?.format(
                                                    "MMM DD, YYYY, H:MM a"
                                                )}`}{" "}
                                        </p>
                                    </div>
                                </div>

                                {rejector?.reason ? (
                                    <p className="text-g-75 text-sm">rejector?.reason</p>
                                ) : (
                                    ""
                                )}

                                <div className="flex gap-2">
                                    <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                        Level{" "}
                                        {approvers?.length > 1 ? approvers?.length + 1 : idx + 1}
                                    </div>

                                    <div className="py-1 px-3 bg-[#e96868] rounded text-xs text-g-75 ">
                                        Rejected
                                    </div>
                                </div>
                            </div>
                        ))} */}
                    </>
                </div>

                <div className="">
                    <p className="mt-1 text-base text-slate-500 ">No approval required</p>
                </div>
            </div>

            <ModalContainer
                open={approved}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setApproved(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setApproved(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production Plan Approved! (1/3)
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    You've successfully reviewed and approved the production plan.
                                    Your part is done, and we've notified the next approver in line.
                                </p>
                            </div>
                        </div>

                        <div className="px-6 pb-4">
                            <p className="text-sm text-gm-50 my-4">Approvals</p>

                            <div className="space-y-2">
                                <div className="flex items-center justify-between gap-2 pb-2 border-b border-b-[#F1F5F9]">
                                    <div className="">
                                        <p className="text-g-75">
                                            Kaimana Kalani{" "}
                                            <span className="text-slate-500 text-sm ">
                                                {" "}
                                                - Production Manager{" "}
                                            </span>
                                        </p>
                                        <p className="text-slate-500 text-sm ">
                                            Sept 7, 2024, 10:00 AM
                                        </p>
                                    </div>
                                    <div className="flex gap-2">
                                        <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                            Level 1
                                        </div>
                                        <div className="py-1 px-3 bg-[#D0F4ED] rounded text-xs text-g-75 ">
                                            Approved
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between gap-2 pb-2 border-b border-b-[#F1F5F9]">
                                    <div className="">
                                        <p className="text-g-75">
                                            Kaimana Kalani{" "}
                                            <span className="text-slate-500 text-sm ">
                                                {" "}
                                                - Production Manager{" "}
                                            </span>
                                        </p>
                                        <p className="text-slate-500 text-sm ">
                                            Sept 7, 2024, 10:00 AM
                                        </p>
                                    </div>
                                    <div className="flex gap-2">
                                        <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                            Level 2
                                        </div>
                                        <div className="py-1 px-3 bg-[#F9DFB3] rounded text-xs text-g-75 ">
                                            Pending
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between gap-2 pb-2">
                                    <div className="">
                                        <p className="text-g-75">
                                            Kaimana Kalani{" "}
                                            <span className="text-slate-500 text-sm ">
                                                {" "}
                                                - Production Manager{" "}
                                            </span>
                                        </p>
                                        <p className="text-slate-500 text-sm ">
                                            Sept 7, 2024, 10:00 AM
                                        </p>
                                    </div>
                                    <div className="flex gap-2">
                                        <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                            Level 3
                                        </div>
                                        <div className="py-1 px-3 bg-[#F9DFB3] rounded text-xs text-g-75 ">
                                            Pending
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setApproved(false);
                                }}
                            />
                            <Button
                                btnText={"View Status"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={rejected}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setRejected(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setRejected(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#FF3D1D] flex items-center justify-center">
                                <i className="ri-close-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production Plan Rejected! (1/3)
                                </span>
                                <p className="mt-1 text-sm text-slate-500 text-center ">
                                    The production plam has been rejected, and your feedback has
                                    been received. The requester will be notified for necessary
                                    revisions.
                                </p>
                            </div>
                        </div>

                        <div className="px-6 pb-4">
                            <p className="text-sm text-gm-50 my-4">Approvals</p>

                            <div className="space-y-2">
                                <div className="pb-2 border-b border-b-[#F1F5F9]">
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="">
                                            <p className="text-g-75 text-[13px]">
                                                Kaimana Kalani - Production Manager{" "}
                                            </p>
                                            <p className="text-slate-500 text-xs ">
                                                Sept 7, 2024, 10:00 AM
                                            </p>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                                Level 1
                                            </div>
                                            <div className="py-1 px-3 bg-[#FEE2E2] rounded text-xs text-g-75 ">
                                                Rejected
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-[13px] text-slate-500 mt-2">
                                        I don’t like the way you structured the Work Order. Please
                                        recreated the Work Order again. Thank you
                                    </p>
                                </div>
                                <div className="pb-2 border-b border-b-[#F1F5F9]">
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="">
                                            <p className="text-g-75 text-[13px]">
                                                Kaimana Kalani - Production Manager{" "}
                                            </p>
                                            <p className="text-slate-500 text-xs ">
                                                Sept 7, 2024, 10:00 AM
                                            </p>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                                Level 2
                                            </div>
                                            <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                                Cancelled
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-2">
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="">
                                            <p className="text-g-75 text-[13px]">
                                                Kaimana Kalani - Production Manager{" "}
                                            </p>
                                            <p className="text-slate-500 text-xs ">
                                                Sept 7, 2024, 10:00 AM
                                            </p>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                                Level 3
                                            </div>
                                            <div className="py-1 px-3 bg-slate-100 rounded text-xs text-g-75 ">
                                                Cancelled
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setRejected(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};
export default ApprovalWorkflow;

//
// <div className="py-1 px-3 bg-[#D0F4ED] rounded text-xs text-g-75 ">Approved</div>
{
    /* <div className="py-1 px-3 bg-[#e96868] rounded text-xs text-g-75 ">
                                             Rejected
                                         </div> */
}
