import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetSingleMappedSku from "hooks/inventoryHooks/useGetSingleMappedSku";
import { formatMoney } from "helpers";
import moment from "moment";
import { truncate } from "lodash";
import PageFrame from "components/layout/PageFrame";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const MappedSkuDetail = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/inventory" });

    const { data: singleMappedData, isFetching } = useGetSingleMappedSku();

    const unitPriceSum = singleMappedData?.mappedSkus?.reduce(
        (acc, curr) => acc + (curr.unitPrice || 0),
        0
    );

    const stockLevelSum = singleMappedData?.mappedSkus?.reduce(
        (acc, curr) => acc + (curr.stockLevel || 0),
        0
    );

    const tableHeader = [
        { title: "Product name", widthClass: "w-[40%]" },
        { title: "Current stock level", widthClass: "w-[20%]" },
        { title: "Unit price", widthClass: "w-[20%]" },
        { title: "Channel", widthClass: "w-[20%]" },
    ];

    const tableBody = singleMappedData?.mappedSkus?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            {product?.productImageDetails?.productAvatar ||
                            product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">
                                    {truncate(product?.productName, { length: 30 })}
                                </p>
                                <p className="text-slate-500 ">
                                    SKU-{truncate(product?.sku, { length: 30 })}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },

            {
                content: (
                    <div
                        className={`w-full py-2 border-slate-100 font-rocGroteskMedium text-sm text-g-75 `}
                    >
                        <p>{formatMoney().format(product?.stockLevel || 0)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full py-2 border-slate-100 font-rocGroteskMedium text-sm text-g-75 `}
                    >
                        <p>
                            $
                            {formatMoney().format(
                                product?.unitPrice ? product?.unitPrice?.toFixed(2) : 0
                            )}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full py-2 border-slate-100 text-sm text-g-75 `}>
                        <div className="flex items-center space-x-2">
                            {displayProductChannelIcon(product?.channel)}
                            <span className="text-sm font-rocGroteskMedium capitalize">
                                {product?.channel === "quickbooks" ? "QB" : product?.channel}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    return (
        <PageFrame isLoading={isFetching}>
            <div className="bg-white rounded-lg border border-n-20">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => goBack()}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            SKU-{singleMappedData?.sku}
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => goBack()}
                        />
                        <Button
                            btnText="Edit mapped SKU"
                            btnPermission={["all_access", "create_inventory", "update_inventory"]}
                            onClick={() => {
                                navigate(`/dashboard/inventory/mapped-sku/edit/${productId}`, {
                                    state: { internalNavigation: true },
                                });
                            }}
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="px-8 mt-8 flex justify-center">
                    <div className="w-[60%]">
                        <div className="mb-6">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">Master SKU</p>
                            </div>
                            <div className="border-b-[1px] border-b-[#E2E8F0] pb-4">
                                <p className="!text-2xl font-rocGroteskMedium  !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6">
                                    SKU-{singleMappedData?.sku} (
                                    {truncate(singleMappedData?.productName, {
                                        length: 50,
                                    })}
                                    )
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-between space-x-3 border-b border-slate-200 pb-6 items-center">
                            <div>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    Mapped SKU value
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                    ${formatMoney().format(unitPriceSum?.toFixed(2))}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    Created on
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                    {moment(singleMappedData?.createdAt).format("DD MMM YYYY")}
                                </p>
                            </div>
                        </div>

                        <div className="mt-8">
                            <p className="text-base font-rocGroteskMedium mb-3 text-gm-50">
                                Products/SKUs
                            </p>

                            <div className="pb-3">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    isCellSelectable={false}
                                    headerContainerClass="!bg-[#F8FAFC] !font-rocGroteskMedium !text-[#334155] !rounded !border-slate-200"
                                    isScrollable={false}
                                    isCellBordered={false}
                                    bodyItemClass={"hover:!bg-transparent"}
                                />
                                <div className="flex w-[100%]  py-3  border border-t-0 border-slate-100 rounded-bl bg-[#F8FAFC] rounded-br">
                                    <div className="w-[40%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            Totals: {singleMappedData?.mappedSkus?.length}{" "}
                                            product(s)
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {formatMoney().format(stockLevelSum)}
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            ${formatMoney().format(unitPriceSum?.toFixed(2))}
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default MappedSkuDetail;
