import React, { useEffect } from 'react'
import PurchaseOrderDataDetails from "components/views/Synth2.0/Order/PurchaseOrderDetails"
import { useNav } from 'components/common/Navbar/NavContext';
import { useNavigate } from 'react-router-dom';
import { useNavigationHandler } from 'hooks/useNavigationHandler';

const PurchaseOrderDetails =()=> {
    const { setNavName, setOnClick, setShowNavIcon } = useNav();
    const navigate = useNavigate();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/purchase" });

    useEffect(() => {
        setNavName("Purchase Details");
        setOnClick(() => () => {
            goBack();
        });
        setShowNavIcon(true)

    }, [setNavName, setOnClick, setShowNavIcon]);

    return (
        <PurchaseOrderDataDetails showTabs={false}/>
    )
}

export default PurchaseOrderDetails