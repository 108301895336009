import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Checkbox from "components/common/InputField/Checkbox";
import Loader from "components/common/Loader";
import PageTitleSection from "components/common/PageTitleSection";
import { ItemDetailsCard } from "components/views/Ldb/Dashboard/Quotes/QuoteItemResponse";
import { displayFileIcon, formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { calculateDaysUntilToday } from "helpers/calculateInDays";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";

interface Props {}

const QuoteDetails = () => {
    const [quotesResponses, setQuotesResponses] = useState<any>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { quoteId } = useParams();
    const { fetchingSingleResponse, fetchedSingleResponseSuccess } = useAppSelector(
        (state) => state.order
    );

    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/purchase" });

    useEffect(() => {
        dispatch(orderActions.getSingleQuoteResponse(quoteId));
    }, [dispatch, quoteId]);

    useEffect(() => {
        if (Boolean(fetchedSingleResponseSuccess)) {
            setQuotesResponses(fetchedSingleResponseSuccess?.quoteResponse);
        }
    }, [fetchedSingleResponseSuccess]);

    const tableHeader = [
        { title: "Item/Service", widthClass: "w-[55%] " },
        { title: "Quantity", widthClass: "w-[19%]" },
        {
            title: `Unit Price ($)`,
            widthClass: "w-[19%]",
        },
        {
            title: `Total Price ($)`,
            widthClass: "w-[19%]",
        },
    ];

    const tableBody = quotesResponses?.charges?.map((charge, idx) => {
        const total = charge?.amount?.amount * charge?.quantity;
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-[12px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 leading-5">
                            {truncate(charge?.inventory?.productName, {
                                length: 50,
                            })}{" "}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[12px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {formatMoney().format(charge?.quantity)}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[12px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 ">
                            {formatMoney().format(charge?.amount?.amount)}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[12px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 ">{formatMoney().format(total)}</p>
                    </div>
                ),
            },
        ];
    });

    const analysisTableHeader = [
        { title: "Criteria", widthClass: "w-[61%] " },
        {
            title: "Successful",
            widthClass: "w-[12%] bg-[#F0FDF4]",
            itemClass: "flex justify-center",
        },
        { title: "At risk", widthClass: "w-[11%] bg-[#FFFBEB]", itemClass: "flex justify-center" },
        { title: "Failed", widthClass: "w-[11%] bg-[#FEF2F2]", itemClass: "flex justify-center" },
    ];

    const analysisTableBody = [1, 2, 3]?.map((item, idx) => [
        {
            content: (
                <div
                    className={`py-[14px] pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                >
                    <p className="text-slate-700 mb-1">Pencil</p>
                    <p className="text-slate-500">
                        {
                            "Payment terms of this quote might not meet the payment terms your customers gave as it is a 15 days payment terms while your customer will pay you in 30 days"
                        }
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[14px] pr-3 w-full flex font-rocGroteskMedium justify-center items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <p className="text-slate-700 ">----- </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                >
                    <p className="text-slate-700 ">----- </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                >
                    <p className="text-slate-700 ">----- </p>
                </div>
            ),
        },
    ]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="pb-2 border-b border-dashed">
                <div
                    onClick={() => goBack()}
                    className="flex items-center space-x-3 mt-3 mb-5 pl-3 w-fit cursor-pointer"
                >
                    <i className="ri-arrow-left-line text-lg"></i>
                    <span className="underline text-sm font-rocGroteskMedium mt-[2px]">Back</span>
                </div>

                <div className="mb-4">
                    <PageTitleSection
                        leftComp={
                            <BreadCrumb
                                parentCrumb={"Purchase"}
                                parentUrl={"/dashboard/purchase"}
                                childrenCrumb={[
                                    { title: "Quote details", path: `/dashboard/quote/1` },
                                ]}
                                size="large"
                            />
                        }
                    />
                </div>
            </div>
            {fetchingSingleResponse ? (
                <div className="my-[20% flex justify-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="h-full w-full  pt-5">
                        <div className="w-full  ">
                            {/* <div className="lg:px-[34px] px-2  border-b border-slate-100 pb-4">
                    <BreadCrumb
                        parentCrumb={"Orders"}
                        parentClassName={"text-slate-400"}
                        parentUrl={"/dashboard/quotes"}
                        childrenCrumb={[
                            { title: "Quotes", path: `/dashboard/quotes` },
                            { title: "Quote details", path: `#` },
                        ]}
                        size="small"
                    />
                    <h6 className="lg:text-xl lg:px-0 px-2 text-base text-slate-900 font-rocGroteskMedium mt-2">
                        
                        Quote responses for RFQ-
                    </h6>
                </div> */}
                            <div className="w-full">
                                <div className="">
                                    {quotesResponses?.suggestedQuotes?.map(
                                        (suggestedQuote, idx) => (
                                            <ItemDetailsCard
                                                key={idx}
                                                // rating={responseDetails?.vendor?.rating}
                                                showSuggestionQuote={true}
                                                title={
                                                    suggestedQuote?.vendor?.profile?.businessName
                                                }
                                                email={suggestedQuote?.vendor?.email}
                                                logo={suggestedQuote?.vendor?.profile?.avatar}
                                                description={suggestedQuote?.additionalComment}
                                                rfqTaId={suggestedQuote?.rfq?.taId}
                                                sentAt={`${
                                                    calculateDaysUntilToday(
                                                        suggestedQuote?.createdAt
                                                    ) === 1
                                                        ? `${calculateDaysUntilToday(
                                                              suggestedQuote?.createdAt
                                                          )} day ago`
                                                        : `${calculateDaysUntilToday(
                                                              suggestedQuote?.createdAt
                                                          )} days ago`
                                                }`}
                                            />
                                        )
                                    )}

                                    <div className="border-b pb-5 border-slate-100">
                                        <div className="lg:px-[32px] px-4">
                                            <h4 className="text-slate-900  text-base font-rocGroteskMedium lg:text-xl">
                                                Quote details
                                            </h4>
                                            <div className="w-full mt-5">
                                                <div className="lg:block hidden">
                                                    <CustomTable
                                                        tableBody={tableBody}
                                                        tableHeader={tableHeader}
                                                        isAllSelectable={false}
                                                        isScrollable={false}
                                                        isCellBordered={true}
                                                        tableClass="!rounded-[8px]"
                                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !text-[12px] "
                                                    />
                                                    <div className="flex justify-end mt-0 w-full">
                                                        <div className="w-[34%]">
                                                            <div className="flex justify-between border-x border-b border-slate-100 px-3">
                                                                <div className=" border-slate-100 py-[12px] w-1/2 border-r">
                                                                    <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                                        Total
                                                                    </p>
                                                                </div>
                                                                <div className="border-slate-100 pl-4 py-[12px] w-1/2 bg-slate-50">
                                                                    <p className="text-sm font-rocGroteskBold text-left text-gm-50">
                                                                        {getCurrencyFromCurrencyCode(
                                                                            quotesResponses?.amount
                                                                                ?.currency
                                                                        )}
                                                                        {formatMoney().format(
                                                                            quotesResponses?.amount
                                                                                ?.amount
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {[1, 2]?.map((charge) => (
                                                    <div className="space-y-1 mb-1 lg:hidden block">
                                                        <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                            <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                                Item/service
                                                            </p>
                                                            <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                                {truncate("charge?.description", {
                                                                    length: 40,
                                                                })}{" "}
                                                            </p>
                                                        </div>
                                                        <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                            <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                                Quantity
                                                            </p>
                                                            <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                                2
                                                            </p>
                                                        </div>
                                                        <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                            <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                                Unit Price ($)
                                                            </p>
                                                            <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                                $28,000
                                                            </p>
                                                        </div>
                                                        <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                            <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                                Total Price ($)
                                                            </p>
                                                            <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                                $56,000
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                                {quotesResponses?.documents?.map((doc, idx) => {
                                                    return (
                                                        <a
                                                            key={idx}
                                                            href={doc?.path}
                                                            download={doc?.filename}
                                                            className="bg-white mt-10 cursor-pointer border flex items-center py-3 lg:py-4 px-3 justify-between  rounded-[6px]  border-slate-200"
                                                        >
                                                            <div className="space-x-2 flex items-center ">
                                                                {displayFileIcon(
                                                                    "pdf",
                                                                    true,
                                                                    "!w-4 !h-4"
                                                                )}
                                                                <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                                    {truncate(doc?.filename, {
                                                                        length: 30,
                                                                    })}
                                                                </p>
                                                            </div>
                                                            <div className="cursor-pointer">
                                                                <i className="ri-download-line text-[20px] text-gm-50"></i>
                                                            </div>
                                                        </a>
                                                    );
                                                })}

                                                <div className="mt-12 mb-6">
                                                    <h4 className="text-slate-900 flex mb-8 font-rocGroteskMedium text-base lg:text-xl">
                                                        Quote Analysis{" "}
                                                        <span className="lg:hidden block pl-1">
                                                            {" "}
                                                            - criteria
                                                        </span>
                                                    </h4>
                                                    <div className="space-y-4 md:block hidden">
                                                        <CustomTable
                                                            tableBody={analysisTableBody}
                                                            tableHeader={analysisTableHeader}
                                                            isAllSelectable={false}
                                                            isScrollable={false}
                                                            isCellBordered={true}
                                                            tableClass="!rounded-[8px] mb-3"
                                                            headerItemClass="font-rocGroteskMedium !text-slate-700 !text-[12px] "
                                                        />

                                                        <div className="py-5 px-6 flex items-center justify-between bg-[#f9f4f6]">
                                                            <div>
                                                                <p className="mb-3 text-base font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                                                    Our AI has carefully reviewed
                                                                    all of the quotes you received
                                                                    for RFQs with similar items.
                                                                </p>
                                                                <Button
                                                                    icon={
                                                                        <i className="ri-bard-fill text-white text-base"></i>
                                                                    }
                                                                    btnText={
                                                                        "Click to see how this quote ranks"
                                                                    }
                                                                    btnTextClassName={"text-[13px]"}
                                                                    btnClassname={
                                                                        "!w-fit !py-2 !px-3 !text-white bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"
                                                                    }
                                                                />
                                                            </div>
                                                            <i className="ri-bard-fill text-[48px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm text-slate-900 mb-1">
                                                                    Payment terms
                                                                </p>
                                                                <p className="text-sm text-slate-500">
                                                                    Payment terms of this quote
                                                                    might not meet the payment terms
                                                                    your customers gave as it is a
                                                                    15 days payment terms while your
                                                                    customer will pay you in 30 days
                                                                </p>
                                                                <div className="flex xs:flex-row gap-3 flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                                    <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Successful
                                                                        </p>
                                                                        <Checkbox
                                                                            name="paymentSuccess"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            At risk
                                                                        </p>
                                                                        <Checkbox
                                                                            name="paymentAtRisk"
                                                                            isChecked={false}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Failed
                                                                        </p>
                                                                        <Checkbox
                                                                            name="paymentFailed"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm text-slate-900 mb-1">
                                                                    Pricing
                                                                </p>
                                                                <p className="text-sm text-slate-500">
                                                                    Although the total price of this
                                                                    quote contributes to 30% of your
                                                                    customer budget, it is the
                                                                    cheapest quote received so far
                                                                </p>
                                                                <div className="flex  gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                                    <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Successful
                                                                        </p>
                                                                        <Checkbox
                                                                            name="pricingSuccess"
                                                                            isChecked={false}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            At risk
                                                                        </p>
                                                                        <Checkbox
                                                                            name="pricingAtRisk"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Failed
                                                                        </p>
                                                                        <Checkbox
                                                                            name="pricingFailed"
                                                                            isChecked={false}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm text-slate-900 mb-1">
                                                                    Quality
                                                                </p>
                                                                <p className="text-sm text-slate-500">
                                                                    The vendor provided you with an
                                                                    horrible service the last time
                                                                    and has a rating of 1
                                                                </p>
                                                                <div className="flex gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                                    <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Successful
                                                                        </p>
                                                                        <Checkbox
                                                                            name="qualitySuccess"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            At risk
                                                                        </p>
                                                                        <Checkbox
                                                                            name="qualityAtRisk"
                                                                            isChecked={false}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Failed
                                                                        </p>
                                                                        <Checkbox
                                                                            name="qualityFailed"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm text-slate-900 mb-1">
                                                                    Delivery timeline
                                                                </p>
                                                                <p className="text-sm text-slate-500">
                                                                    The delivery timeline from this
                                                                    vendor is too close to the
                                                                    delivery date requested by your
                                                                    customer
                                                                </p>
                                                                <div className="flex gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                                    <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Successful
                                                                        </p>
                                                                        <Checkbox
                                                                            name="deliverySuccess"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            At risk
                                                                        </p>
                                                                        <Checkbox
                                                                            name="deliveryAtRisk"
                                                                            isChecked={false}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                        <p className="text-xs text-slate-700">
                                                                            Failed
                                                                        </p>
                                                                        <Checkbox
                                                                            name="deliveryFailed"
                                                                            isChecked={true}
                                                                            className={
                                                                                "h-6 w-6 !border-tradeally-neutral-200"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pt-6 border-t border-slate-100">
                                                    <h4 className="text-xl font-rocGroteskMedium mb-4">
                                                        Comment
                                                    </h4>
                                                    <p className="text-sm font-rocGroteskMedium">
                                                        I am accepting this quote because it aligns
                                                        perfectly with our budget and provides the
                                                        specific features and functionality we need
                                                        for our project.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuoteDetails;
