import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetSingleCampaign = (camId?: string) => {
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();

    const { fetchingSingleCampaign, fetchSingleCampaignSuccess, fetchSingleCampaignFailure } =
        useAppSelector((state) => state.workforce);

    const [campaignDetail, setCampaignDetail] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (campaignId || camId) {
            dispatch(workforceActions.getSingleCampaign(campaignId || camId));

            return () => {
                dispatch(workforceActions.resetGetSingleCampaignSuccess());
            };
        }
    }, [dispatch, campaignId, camId]);

    useEffect(() => {
        if (Boolean(fetchSingleCampaignSuccess)) {
            setCampaignDetail(fetchSingleCampaignSuccess?.campaign);
        }
    }, [fetchSingleCampaignSuccess]);

    return {
        isFetching: fetchingSingleCampaign,
        error: fetchSingleCampaignFailure,
        data: campaignDetail,
        setCampaignDetail,
    };
};

export default useGetSingleCampaign;
