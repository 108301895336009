import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button";
import useGetSingleAiTeammate from "hooks/workforceHooks/useGetSingleAiTeammate";
import PageFrame from "components/layout/PageFrame";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import moment from "moment";
import { formatMoney, getFromStorage } from "helpers";
import VendorAvatar from "components/common/VendorAvatar";
import WorkforceAnalyticsCard from "components/common/WorkforceAnalyticsCard";
import Dropdown from "components/common/Dropdown";
import TabsContainer from "components/common/TabsContainer";
import TextInput from "components/common/InputField/TextInput";
import { truncate } from "lodash";
import CustomTable from "components/common/CustomTable";
import { Tooltip } from "react-tooltip";
import Loader from "components/common/Loader";
import CampaignOverview from "../MarketingCampaign/CampaignOverview";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const AiTeammateDetails = () => {
    const navigate = useNavigate();
    const {
        data,
        isFetching,
        isFetchingTasks,
        aiTeammateStats,
        isFetchingStats,
        isFetchingAiTeammate,
        aiTeammateAllTasks,
        aiTeammatePendingTasks,
        isLoadingMore,
        pagination,
        search,
        handleSearch,
        handleDebouncedChange,
        handleTaskStatusChange,
        taskStatus,
        handleClearTaskStatusFilter,
        dates,
        setDates,
    } = useGetSingleAiTeammate();

    const { aiTeammateId } = useParams();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [selectedView, setSelectedView] = useState("list");
    const profile = getFromStorage("ally-user");
    const currentDay = moment();
    const tabs = ["All tasks", "Needing approval"];
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/workforce" });

    const taskTableHeader = [
        { title: "Task no", widthClass: "w-[22.3%]" },
        { title: "Task name", widthClass: "w-[34%]" },
        { title: "Start Date", widthClass: "w-[10.8%]" },
        { title: "Time on workflow", widthClass: "w-[11%]" },
        { title: "Status", widthClass: "w-[16%]" },
        // { title: "", widthClass: "w-[5.1%]" },
    ];

    const taskTableBody = aiTeammateAllTasks?.map((task, idx) => {
        const runningDurationinHrs = moment(task?.endDate || currentDay)?.diff(
            task?.createdAt,
            "hours"
        );
        const runningDurationinMins = moment(task?.endDate || currentDay)?.diff(
            task?.createdAt,
            "minutes"
        );

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                    >
                        <div className=" w-full">
                            <span className="text-sm bg-slate-100 w-fit rounded-lg h-6 px-1 block mb-[2px]">
                                #{task?.taId}
                            </span>

                            <div>
                                <a id={`order-no-column-${idx}`}>
                                    <p className="text-sm text-slate-500">
                                        {/* {camelCaseToRegularCase(task?.taskType)} - {task?.taId} */}
                                        {/* Order for Grant Wines and Spirits */}
                                        {truncate(task?.state?.order_info?.order_summary, {
                                            length: 30,
                                        })}
                                    </p>
                                </a>
                                <Tooltip
                                    anchorSelect={`#order-no-column-${idx}`}
                                    place={"top"}
                                    content={task?.state?.order_info?.order_summary}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () =>
                    navigate(`/dashboard/task/${task?._id}`, {
                        state: { internalNavigation: true },
                    }),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                    >
                        <div className="flex items-center space-x-3 w-full">
                            <span>{camelCaseToRegularCase(task?.taskType)}</span>

                            {task?.status?.toLowerCase()?.includes("attention") && (
                                <i className="ri-information-line text-r-50 text-2xl"></i>
                            )}

                            {task?.status?.toLowerCase()?.includes("attention") && (
                                <span className="flex items-center justify-center whitespace-nowrap text-xs w-fit bg-[#56B648] rounded-md h-6 px-2 mb-[2px] text-white font-rocGroteskBold">
                                    Approval needed
                                </span>
                            )}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>{moment(task?.createdAt)?.format("MMM DD, YYYY")}</span>
                        {/* <span>Mar 12, 2024</span> */}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>
                            {runningDurationinHrs
                                ? `${runningDurationinHrs} hour(s)`
                                : `${runningDurationinMins} min(s)`}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm  `}>
                        <div className="flex items-center space-x-2">
                            {/* <span className="w-2 h-2 rounded-full bg-[#EAB308]"></span> */}

                            <span>{task?.status}</span>
                        </div>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
            //             onClick={(e) => e.stopPropagation()}
            //         >
            //             <Dropdown
            //                 dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
            //                 dropdownClassName={"!w-full !mb-10 !-mt-7"}
            //                 dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
            //                 dropdownItemsClasses={``}
            //                 name={"faq-dropdown"}
            //                 dropdownOptions={[
            //                     {
            //                         label: "View details",
            //                         value: "view",
            //                         action: () => navigate(`/dashboard/task/1`),
            //                     },
            //                 ]}
            //             />
            //         </div>
            //     ),
            // },
        ];
    });

    const taskApprovalTableHeader = [
        { title: "Task no", widthClass: "w-[13.7%]" },
        { title: "Task name", widthClass: "w-[34%]" },
        { title: "Start Date", widthClass: "w-[10.8%]" },
        { title: "Time on workflow", widthClass: "w-[11%]" },
        { title: "Status", widthClass: "w-[16%]" },
        { title: "Approver", widthClass: "w-[10.5%]" },
        // { title: "", widthClass: "w-[5.1%]" },
    ];

    const taskApprovalTableBody = aiTeammatePendingTasks?.map((task, idx) => {
        const runningDurationinHrs = moment(task?.endDate || currentDay)?.diff(
            task?.createdAt,
            "hours"
        );
        const runningDurationinMins = moment(task?.endDate || currentDay)?.diff(
            task?.createdAt,
            "minutes"
        );

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                    >
                        <div className=" w-full">
                            <span className="text-sm bg-slate-100 w-fit rounded-lg h-6 px-1 block mb-[2px]">
                                #{task?.taId}
                            </span>
                        </div>
                    </div>
                ),
                cellClickAction: () =>
                    navigate(`/dashboard/task/${task?._id}`, {
                        state: { internalNavigation: true },
                    }),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm  `}
                    >
                        <div className="flex items-center space-x-3 w-full">
                            <span>{camelCaseToRegularCase(task?.taskType)}</span>

                            {task?.status?.toLowerCase()?.includes("attention") && (
                                <i className="ri-information-line text-r-50 text-2xl"></i>
                            )}

                            {task?.status?.toLowerCase()?.includes("attention") && (
                                <span className="flex items-center justify-center whitespace-nowrap text-xs w-fit bg-[#56B648] rounded-md h-6 px-2 mb-[2px] text-white font-rocGroteskBold">
                                    Approval needed
                                </span>
                            )}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>{moment(task?.createdAt)?.format("MMM DD, YYYY")}</span>
                        {/* <span>Mar 12, 2024</span> */}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>
                            {runningDurationinHrs
                                ? `${runningDurationinHrs} hour(s)`
                                : `${runningDurationinMins} min(s)`}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm  `}>
                        <div className="flex items-center space-x-2">
                            {/* <span className="w-2 h-2 rounded-full bg-[#EAB308]"></span> */}

                            <span>{task?.status}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm `}>
                        <span>
                            {task?.taskSteps?.slice(-1)?.[0]?.approval?.fullName ||
                                task?.taskSteps?.slice(-1)?.[0]?.approval?.firstName ||
                                task?.taskSteps?.slice(-1)?.[0]?.approval?.lastName}
                        </span>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
            //             onClick={(e) => e.stopPropagation()}
            //         >
            //             <Dropdown
            //                 dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
            //                 dropdownClassName={"!w-full !mb-10 !-mt-7"}
            //                 dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
            //                 dropdownItemsClasses={``}
            //                 name={"faq-dropdown"}
            //                 dropdownOptions={[
            //                     {
            //                         label: "View details",
            //                         value: "view",
            //                         action: () => navigate(`/dashboard/task/1`),
            //                     },
            //                 ]}
            //             />
            //         </div>
            //     ),
            // },
        ];
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return taskTableBody?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={taskTableBody}
                            tableHeader={taskTableHeader}
                            isScrollable={false}
                            // isScrolled={isScrolled}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-12"
                        />

                        {isLoadingMore && (
                            <div className="w-full flex justify-center my-3">
                                <Loader size={4} />
                            </div>
                        )}

                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p className="my-12 text-xl font-rocGroteskMedium text-center">
                            No tasks available at this time
                        </p>
                    </div>
                );
            case 1:
                return taskApprovalTableBody?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={taskApprovalTableBody}
                            tableHeader={taskApprovalTableHeader}
                            isScrollable={false}
                            // isScrolled={isScrolled}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-12"
                        />

                        {isLoadingMore && (
                            <div className="w-full flex justify-center my-3">
                                <Loader size={4} />
                            </div>
                        )}

                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p className="my-12 text-xl font-rocGroteskMedium text-center">
                            No tasks available at this time
                        </p>
                    </div>
                );

            default:
                break;
        }
    };

    return (
        <>
            {data?.agentType !== "marketingCampaign" ? (
                <PageFrame isLoading={isFetching}>
                    <div className="bg-white rounded-lg border border-n-20 relative">
                        <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                            <div className="flex items-center space-x-1.5">
                                <i
                                    onClick={() => navigate(-1)}
                                    className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                                ></i>
                                <span className="text-lg font-rocGroteskMedium">
                                    {`${data?.teamMateName} - ${camelCaseToRegularCase(
                                        data?.agentType
                                    )}`}
                                </span>
                            </div>
                            <div className="flex items-center space-x-3">
                                <Button
                                    btnText="Cancel"
                                    btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                                    disabled={false}
                                    onClick={() => navigate(-1)}
                                />
                                <Button
                                    btnText="Edit AI teammate"
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_ai_teammate,
                                    ]}
                                    onClick={() => {
                                        navigate(
                                            `/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`
                                        );
                                    }}
                                    type="submit"
                                    btnClassname="!px-4 !py-2 !w-fit"
                                />
                            </div>
                        </div>

                        <div className="p-8 font-rocGroteskMedium">
                            <div className="">
                                <div className="flex items-center justify-between border-b border-slate-200 pb-8 mb-8">
                                    <div className="flex items-center space-x-4 ">
                                        <div className="relative w-fit shadow-[0px_20px_24px_-4px_#10182814]">
                                            <img
                                                src={data?.avatar}
                                                alt="ai ageent avatar"
                                                className="w-14 h-14 rounded-lg"
                                            />
                                        </div>

                                        <div className="">
                                            <p className="text-xs mb-[2px] font-rocGroteskMedium">
                                                AI teammate
                                            </p>
                                            <p className="text-lg font-rocGroteskMedium">
                                                {`${data?.teamMateName} - ${camelCaseToRegularCase(
                                                    data?.agentType
                                                )}`}
                                            </p>
                                            {data?.communicationChannel?.emailChannels?.[0]?.social
                                                ?.synthEmail?.email && (
                                                <div className="text-xs text-slate-500 font-rocGroteskMedium bg-slate-100 rounded px-1.5 py-1">
                                                    <span>Email:</span>{" "}
                                                    <span>
                                                        {
                                                            data?.communicationChannel
                                                                ?.emailChannels?.[0]?.social
                                                                ?.synthEmail?.email
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex items-center w-fit h-6 space-x-2 bg-[#D0F4EDB2] rounded-md px-2.5">
                                        <span className="w-2 h-2 rounded-full bg-[#22C55E] " />
                                        <span className="text-sm">Active</span>
                                    </div>
                                </div>

                                <div className="grid grid-cols-5 space-x-3 mb-[43px]">
                                    <WorkforceAnalyticsCard
                                        title="Total tasks started"
                                        value={formatMoney().format(aiTeammateStats?.startedTasks)}
                                    />
                                    <WorkforceAnalyticsCard
                                        title="Total tasks completed"
                                        value={formatMoney().format(aiTeammateStats?.totalTasks)}
                                    />
                                    <WorkforceAnalyticsCard
                                        title="Total pending tasks"
                                        value={formatMoney().format(aiTeammateStats?.pendingTasks)}
                                    />
                                    <WorkforceAnalyticsCard
                                        title="Total needing approval"
                                        value={formatMoney().format(aiTeammateStats?.approvalTasks)}
                                    />
                                    <WorkforceAnalyticsCard
                                        title="Efficiency ratio"
                                        value={`${formatMoney().format(
                                            aiTeammateStats?.efficiency
                                        )}%`}
                                        bottomComp={
                                            Boolean(aiTeammateStats?.efficiencyGrowth) && (
                                                <div className="flex items-center space-x-2">
                                                    <div
                                                        className={`${
                                                            String(
                                                                aiTeammateStats?.efficiencyGrowth
                                                            )?.startsWith("-")
                                                                ? "text-r-55 bg-r-25"
                                                                : "bg-[#F0FDF4] text-[#22C55E]"
                                                        } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                                    >
                                                        <i className="ri-arrow-up-line mt-[-2px] "></i>
                                                        <span>
                                                            {formatMoney().format(
                                                                aiTeammateStats?.efficiencyGrowth
                                                            )}
                                                            %
                                                        </span>
                                                    </div>
                                                    <span className="text-sm text-slate-500">
                                                        since last month
                                                    </span>
                                                </div>
                                            )
                                        }
                                    />
                                </div>

                                <div>
                                    <TabsContainer
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        setActiveTab={(idx) => {
                                            setActiveTab(idx);
                                        }}
                                        component={displayActiveTab()}
                                        className="!px-0"
                                        showButtonClassName
                                        itemClassName="!pb-2 max-lg:whitespace-nowrap"
                                        borderLineClase={"!text-slate-100 w-fit max-lg:w-full mb-6"}
                                        showButton={
                                            <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                                {/* <div className="rounded flex h-[40px] w-[80px] border border-slate-200">
                                            <span
                                                onClick={() => {
                                                    setSelectedView("grid");

                                                    // if (activeTab === 1) {

                                                    //     handleClearSearch();

                                                    // }
                                                }}
                                                className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer border-r border-slate-200 ${
                                                    selectedView === "grid" && "bg-slate-200"
                                                }`}
                                            >
                                                <i className="ri-layout-grid-line text-base"></i>
                                            </span>
                                            <span
                                                onClick={() => {
                                                    setSelectedView("list");
                                                    // handleClearSearch();
                                                }}
                                                className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer ${
                                                    selectedView === "list" && "bg-slate-200"
                                                }`}
                                            >
                                                <i className="ri-list-check text-base"></i>
                                            </span>
                                        </div> */}

                                                <TextInput
                                                    name={"search"}
                                                    value={search}
                                                    type={"text"}
                                                    inputPlaceholder={"Search tasks"}
                                                    inputClassName={
                                                        "!h-[38px] pl-[0px] !bg-white text-sm !mb-0"
                                                    }
                                                    onChange={(e) => handleSearch(e)}
                                                    onInput={handleDebouncedChange}
                                                    containerClassname={"!w-[250px] max-sm:!w-full"}
                                                    leftIcon={
                                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                                    }
                                                    inputContainerClassname={
                                                        "!rounded-[4px] max-sm:!w-full !pl-[25px] !h-[40px] !bg-white !border !border-slate-200"
                                                    }
                                                />

                                                <Dropdown
                                                    name="filter"
                                                    value={""}
                                                    dropdownTriggerClassName={" "}
                                                    handleChange={(name, value) => {
                                                        handleTaskStatusChange(value);
                                                    }}
                                                    dropdown={
                                                        <div className="bg-white flex items-center justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                                                {taskStatus ? taskStatus : "Status"}
                                                            </span>

                                                            <div className="flex items-center space-x-2">
                                                                {taskStatus && (
                                                                    <i
                                                                        onClick={(e) => {
                                                                            e?.stopPropagation();

                                                                            handleClearTaskStatusFilter();
                                                                        }}
                                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                                    ></i>
                                                                )}
                                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                        !p-0 !top-[105%]`}
                                                    wholeContainerClass={`max-sm:!w-full`}
                                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                                    dropdownOptions={[
                                                        {
                                                            label: "Pending",
                                                            value: "pending",
                                                        },
                                                        {
                                                            label: "In Progress",
                                                            value: "in progress",
                                                        },
                                                        {
                                                            label: "Completed",
                                                            value: "completed",
                                                        },
                                                        {
                                                            label: "Failed",
                                                            value: "failed",
                                                        },
                                                        {
                                                            label: "Attention Required",
                                                            value: "attention required",
                                                        },
                                                    ]}
                                                />

                                                {/* {activeTab === 1 && (
                                            <Button
                                                btnText={"Create new task"}
                                                btnClassname="!px-4 !py-2.5 !w-fit"
                                                // onClick={() => setTaskModal(true)}
                                            />
                                        )} */}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </PageFrame>
            ) : (
                <PageFrame isLoading={isFetchingAiTeammate}>
                    <CampaignOverview
                        isLoading={isFetchingStats}
                        data={data}
                        stats={aiTeammateStats}
                        setDates={setDates}
                        dates={dates}
                    />
                </PageFrame>
            )}
        </>
    );
};

export default AiTeammateDetails;
