import Button from "components/common/Button";
import { useNav } from "components/common/Navbar/NavContext";
import PageTitleSection from "components/common/PageTitleSection";
import PurchaseOrder from "components/views/Ldb/Dashboard/Orders/PurchaseOrder";
import PurchaseOrderRequestList from "components/views/Ldb/Dashboard/Orders/PurchaseOrderRequest";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccountPermissions } from "types/permissions";

const Purchase = () => {
    const [param] = useSearchParams();
    const curTab = param.get("curTab");
    const [titleId, setTitleId] = useState<number>(0);
    const navigate = useNavigate();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/purchase" });
    const title = [
        {
            id: 0,
            name: "Purchase orders",
        },
        {
            id: 1,
            name: "Purchase order list",
        },
    ];

    const { setNavName, setOnClick, setShowNavIcon } = useNav();

    useEffect(() => {
        setNavName("Purchase");
        setOnClick(() => () => {
            goBack();
        });
        setShowNavIcon(true);
    }, [setNavName, setOnClick, setShowNavIcon]);

    useEffect(() => {
        setTitleId(Number(curTab));
    }, [curTab]);

    return (
        <div className="py-4 md:border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-8 px-4 max-lg:hidden block ">
                <PageTitleSection
                    leftComp={<h3 className="text-xl font-rocGroteskMedium">Purchase</h3>}
                    // rightComp={
                    //     <div className="flex items-center space-x-[14px]">
                    //         <Dropdown
                    //             handleChange={(name, value) => {}}
                    //             dropdown={
                    //                 <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center rounded-[5px] h-[32px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                    //                     <i className="ri-filter-3-line text-sm mt-[-2px]"></i>

                    //                     <p className="font-rocGroteskMedium text-[13px]">1 year</p>
                    //                 </div>
                    //             }
                    //             dropdownItemsClasses={`!px-0 !py-0 !w-full`}
                    //             dropdownClassName="!w-full !mb-10  !-mt-7"
                    //             dropdownContainerClasses={`shadow-cardShadow-4 !border-b !mb-0 border-slate-100 rounded !max-h-fit`}
                    //             dropdownOptions={[
                    //                 {
                    //                     value: "1 year",
                    //                     label: "1 year",
                    //                 },
                    //                 {
                    //                     value: "2 years",
                    //                     label: "2 years",
                    //                 },
                    //                 {
                    //                     value: "3 years",
                    //                     label: "3 years",
                    //                 },
                    //             ]}
                    //         />
                    //         <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center h-[32px] rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                    //             <i className="ri-share-forward-line text-sm mt-[-2px]"></i>

                    //             <p className="font-rocGroteskMedium text-sm">Share</p>
                    //         </div>
                    //     </div>
                    // }
                />
            </div>
            <div className="w-fit md:flex hidden mx-4   bg-[#F8FAFC] p-[4px] rounded  items-center space-x-5 mb-6">
                {title?.map((content, idx) => (
                    <div
                        key={idx}
                        onClick={() => {
                            setTitleId(content?.id);
                            navigate(`/dashboard/purchase?curTab=${idx}`, {
                                replace: true,
                                state: { internalNavigation: true },
                            });
                        }}
                        className={`${
                            idx === titleId ? "bg-white shadow-sm" : ""
                        } cursor-pointer text-sm px-3 md:px-[16px] py-[7px] rounded-[6px] text-[#0F172A] font-rocGroteskMedium`}
                    >
                        {content?.name}
                    </div>
                ))}
            </div>
            <div className="w-full py-3 md:hidden sm:px-0 px-4 flex  bg-[#F8FAFC] p-[4px] rounded  items-center space-x-5 mb-6">
                {title?.map((content, idx) => (
                    <div
                        key={idx}
                        onClick={() => {
                            setTitleId(content?.id);
                            navigate(`/dashboard/purchase?curTab=${idx}`, {
                                replace: true,
                                state: { internalNavigation: true },
                            });
                        }}
                        className={`${
                            idx === titleId ? "bg-white shadow-sm" : ""
                        } cursor-pointer text-sm px-3 md:px-[16px] py-[7px] rounded-[6px] text-[#0F172A] font-rocGroteskMedium`}
                    >
                        {content?.name === "Purchase order list" ? "PO list" : content?.name}
                    </div>
                ))}
            </div>
            <div className="max-lg:block px-4 hidden">
                <Button
                    btnText={"Create PO"}
                    type={"button"}
                    icon={<i className="ri-add-line text-gm-50 text-base mb-6"></i>}
                    btnPermission={[
                        AccountPermissions.create_purchase_order,
                        AccountPermissions.all_access,
                    ]}
                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                    onClick={() =>
                        navigate("/dashboard/purchase/create-purchase-request", {
                            state: { internalNavigation: true },
                        })
                    }
                />
            </div>
            <div className="px-4">
                {titleId === 1 && <PurchaseOrderRequestList />}
                {titleId === 0 && <PurchaseOrder />}
            </div>
        </div>
    );
};

export default Purchase;
