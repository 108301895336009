export function createUrlSearchParams(params: Record<string, string | number | boolean | Date>) {
    const searchParams = new URLSearchParams();

    for (const key in params) {
        const value = params[key];
        if (Boolean(value)) {
            searchParams.append(key, String(value));
        }
    }

    return decodeURIComponent(searchParams.toString()).replace(/\+/g, " ");
}
