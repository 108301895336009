import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import CustomTable from "components/common/CustomTable";
import PageFrame from "components/layout/PageFrame";
import moment from "moment";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import ModalContainer from "components/common/ModalContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetSingleReport from "hooks/ProductionHooks/useGetSingleReport";
import { debounce, truncate } from "lodash";
import TextareaInput from "components/common/InputField/TextareaInput";
import { productionActions } from "redux/Ldb/actions";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import SelectInput from "components/common/InputField/SelectInput";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportPDF from "./ReportPDF";
import VendorAvatar from "components/common/VendorAvatar";
import { Tooltip } from "react-tooltip";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const ReportDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { workOrderId } = useParams();
    const [params] = useSearchParams();
    const {goBack} = useNavigationHandler({fallbackPath: "/dashboard/production"})
    const [fullURL, setFullURL] = useState<string>("");
    const [showDefect, setShowDefects] = useState<boolean>(false);
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const [reportSuccess, setReportSuccess] = useState<boolean>(false);
    const [review, setReview] = useState<boolean>(false);
    // const [warehouse, setWarehouse] = useState<boolean>(false);
    const [finishedGoodsAdded, setFinishedGoodsAdded] = useState<boolean>(false);
    const [productionReport, setProductionReport] = useState<boolean>(false);
    const [noReload, setNoReload] = useState<boolean>(false);
    const [directCost, setDirectCost] = useState<{ [key: string]: any }[]>([{ currency: "USD" }]);
    const [indirectCost, setIndirectCost] = useState<{ [key: string]: any }[]>([
        { currency: "USD" },
    ]);
    // const [warehouseDetails, setWarehouseDetails] = useState<{ [key: string]: any }>({});
    const [directCostSum, setDirectCostSum] = useState<number>();
    const [indirectCostSum, setIndirectCostSum] = useState<number>();
    const [notes, setNotes] = useState<string>("");
    const [expandedRowIdx, setExpandedRowIdx]= useState<number>(-1);
    const [stockList, setStockList] = useState<{ [key: string]: any }[]>([]);
    // const [warehouseId, setWarehouseId] = useState<string>("");

    const [showCheckList, setShowCheckList] = useState<boolean>(false);

    const { savingReport, saveReportSuccess, addingToInventory, addToInventorySuccess } =
        useAppSelector((state) => state.production);

    const { data: reportDetails, isLoading } = useGetSingleReport();

    useEffect(() => {
        setDirectCost(
            reportDetails?.report?.directCosts?.length >= 1
                ? reportDetails?.report?.directCosts?.map((cost) => {
                      const { _id, ...rest } = cost;
                      return rest;
                  })
                : reportDetails?.report?.status !== "draft"
                ? []
                : [{ currency: "USD" }]
        );
        setIndirectCost(
            reportDetails?.report?.indirectCosts?.length >= 1
                ? reportDetails?.report?.indirectCosts?.map((cost) => {
                      const { _id, ...rest } = cost;
                      return rest;
                  })
                : reportDetails?.report?.status !== "draft"
                ? []
                : [{ currency: "USD" }]
        );
    }, [reportDetails]);

    const handleDirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...directCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setDirectCost(copyArr);
    };
    const handleIndirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...indirectCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setIndirectCost(copyArr);
    };

    const handleSaveReport = () => {
        const saveIndirect =
            indirectCost?.length >= 1
                ? indirectCost
                      ?.filter((item) => {
                          return item?.category && item?.amount;
                      })
                      ?.map((item) => {
                          return {
                              category: item?.category,
                              currency: "USD",
                              amount: item?.amount,
                          };
                      })
                : [];

        const saveDirect =
            directCost?.length >= 1
                ? directCost
                      ?.filter((item) => {
                          return item?.category && item?.amount;
                      })
                      ?.map((item) => {
                          return {
                              category: item?.category,
                              currency: "USD",
                              amount: item?.amount,
                          };
                      })
                : [];

        const body = {
            ...(saveDirect?.length > 0 && { directCosts: saveDirect }),
            ...(saveIndirect?.length > 0 && { indirectCosts: saveIndirect }),
            ...(notes && { notes }),
        };
        setNoReload(true);
        dispatch(productionActions?.saveReport(body, workOrderId));
    };

    useEffect(() => {
        if (Boolean(saveReportSuccess)) {
            dispatch(productionActions?.fetchSingleReport(workOrderId));
            dispatch(productionActions?.resetSaveReport());
            setReportSuccess(true);
        }
    }, [saveReportSuccess]);

    // const handleAddToInventory = () => {
    //     const body = {
    //         warehouseId,
    //     };
    //     setNoReload(true);
    //     dispatch(productionActions?.addToInventory(body, reportDetails?.report?._id));
    // };

    // useEffect(() => {
    //     if (Boolean(addToInventorySuccess)) {
    //         dispatch(productionActions?.fetchSingleReport(workOrderId));
    //         dispatch(productionActions?.resetAddToInventory());
    //         setWarehouse(false);
    //         setFinishedGoodsAdded(true);
    //     }
    // }, [addToInventorySuccess]);

    // useEffect(() => {
    //     if (!warehouse) {
    //         setWarehouseId("");
    //     }
    // }, [warehouse]);

    useEffect(() => {
        const sum = Array.isArray(directCost)
            ? directCost.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0)
            : 0;
        setDirectCostSum(sum);
    }, [directCost]);

    useEffect(() => {
        const sum = indirectCost?.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0);
        setIndirectCostSum(sum);
    }, [indirectCost]);

    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(fullURL)
            .then(() => {
                // setIsCopied(true);
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    useEffect(() => {
        const { href } = window.location;
        setFullURL(href);
    }, []);

    const summary = [
        {
            header: "Label Discrepancy",
            body: [
                {
                    title: "Issue:",
                    text: "Misprinted labels identified during QC checks.",
                },
                {
                    title: "Quantity Affected:",
                    text: "200 labels",
                },
                {
                    title: "Corrective Action:",
                    text: "Misprinted labels were scrapped, and new labels were applied.",
                },
            ],
        },
        {
            header: "Bottle Damage",
            body: [
                {
                    title: "Issue:",
                    text: "Misprinted labels identified during QC checks.",
                },
                {
                    title: "Quantity Affected:",
                    text: "200 labels",
                },
                {
                    title: "Corrective Action:",
                    text: "Misprinted labels were scrapped, and new labels were applied.",
                },
            ],
        },
    ];

    const FinishedGoodsTableHeader = [
        {
            title: "Product",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[25%]" : "w-[50%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "Previous Stock ",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "Added",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "New stock ",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "LOT Number",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[15%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
    ];

    const FinishedGoodsTableBody = reportDetails?.report?.finishedGoods?.map((product, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {/* {product?.inventory?.productImageDetails?.productAvatar ||
                            product?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.inventory?.productImageDetails?.productAvatar ||
                                        product?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                             )} */}

                            <div className="text-sm">
                                <p className="text-g-75">{product?.inventory?.productName}</p>
                                <p className="text-[#64748B]">{product?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
                    >
                        <p className="flex items-center justify-start">
                            {formatMoney()?.format(product?.previousStockLevel?.amount)}
                            {` `}
                            {product?.previousStockLevel?.unitOfMeasurement ? product?.previousStockLevel?.unitOfMeasurement : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200  text-sm text-[#00A643] flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            {product?.quantity?.amount &&
                                `+${formatMoney()?.format(product?.quantity?.amount)}`}
                            {` `}
                            {product?.quantity?.amount && product?.quantity?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: `!border-slate-200 ${finishedGoodsAdded ? "" : "bg-[#E6FFF0]"} `,
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
                    >
                        <p className="flex items-center justify-start">
                            {formatMoney()?.format(
                                product?.previousStockLevel?.amount + product?.quantity?.amount
                            )}
                            {` `}
                            {product?.previousStockLevel?.unitOfMeasurement ? product?.previousStockLevel?.unitOfMeasurement :""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
                    >
                        <p className="flex items-center justify-start">{product?.batchId}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    // const FinishedGoodsModalTableHeader = [
    //     {
    //         title: "Product",
    //         widthClass: ` ${
    //             finishedGoodsAdded ? "w-[25%]" : "w-[50%]"
    //         } !font-rocGroteskMedium !border-slate-200`,
    //     },
    //     {
    //         title: "Added",
    //         widthClass: ` ${
    //             finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
    //         } !font-rocGroteskMedium !border-slate-200`,
    //     },
    //     {
    //         title: "New stock ",
    //         widthClass: ` ${
    //             finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
    //         } !font-rocGroteskMedium !border-slate-200`,
    //     },
    //     {
    //         title: "LOT Number",
    //         widthClass: ` ${
    //             finishedGoodsAdded ? "w-[15%]" : "w-[13%]"
    //         } !font-rocGroteskMedium !border-slate-200`,
    //     },
    // ];

    // const FinishedGoodsModalTableBody = reportDetails?.report?.finishedGoods?.map(
    //     (product, idx) => {
    //         return [
    //             {
    //                 content: (
    //                     <div key={idx} className={`w-full  `}>
    //                         <div className="flex items-center gap-2 py-2 px-2.5">
    //                             <div className="text-sm">
    //                                 <p className="text-g-75">
    //                                     {truncate(product?.inventory?.productName, { length: 20 })}
    //                                 </p>
    //                                 <p className="text-[#64748B]">{product?.inventory?.sku}</p>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 ),
    //                 tableDataDivClass: "!pl-0",
    //                 widthClass: "!border-slate-200",
    //             },
    //             {
    //                 content: (
    //                     <div
    //                         className={`w-full border-slate-200  text-sm text-[#00A643] flex items-center  font-rocGroteskMedium `}
    //                     >
    //                         <p className="px-4">
    //                             +{formatMoney()?.format(product?.quantity?.amount)}
    //                             {` `}
    //                             {product?.quantity?.unitOfMeasurement}
    //                         </p>
    //                     </div>
    //                 ),
    //                 tableDataDivClass: "!pl-0",
    //                 widthClass: `!border-slate-200 ${finishedGoodsAdded ? "" : "bg-[#E6FFF0]"} `,
    //             },
    //             {
    //                 content: (
    //                     <div
    //                         className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
    //                     >
    //                         <p className="flex items-center justify-start">
    //                             {formatMoney()?.format(
    //                                 product?.previousStockLevel?.amount + product?.quantity?.amount
    //                             )}
    //                             {` `}
    //                             {product?.previousStockLevel?.unitOfMeasurement}
    //                         </p>
    //                     </div>
    //                 ),
    //                 tableDataDivClass: "!pl-0",
    //                 widthClass: "!border-slate-200",
    //             },
    //             {
    //                 content: (
    //                     <div
    //                         className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
    //                     >
    //                         <p className="flex items-center justify-start">{product?.batchId}</p>
    //                     </div>
    //                 ),
    //                 tableDataDivClass: "!pl-0",
    //                 widthClass: "!border-slate-200",
    //             },
    //         ];
    //     }
    // );

        const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = quantity;
        const scrapNum = scrap;
        const percentage = (quantityNum * scrapNum) / 100;
        
        const totalQuantity = quantity ? Number(percentage )+ Number(quantity) : 0;
        if (unitPrice !== undefined) {
            return Number(totalQuantity * unitPrice);
        }
        return totalQuantity ? Number(totalQuantity) : 0;
    };


    const ProductionSummaryTableHeader = [
        { title: "Materials", widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200" },
        {
            title: "BOM-specified Amount",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Actual Amount used ",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Discrepancy ",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Discrepancy Cost",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const ProductionSummaryTableBody = reportDetails?.report?.billOfMaterialIds?.map((bom, idx) => {
        const calcQuantity = bom?.quantity * reportDetails?.report?.plannedQuantity?.amount;

    const totalNeeded = bom?.scrap
                                ? calcTotalQuantity(calcQuantity, bom?.scrap)
                                : bom?.quantity
                                ? calcQuantity
                                : ""
                            

        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {bom?.inventory?.productImageDetails?.productAvatar ||
                            bom?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        bom?.inventory?.productImageDetails?.productAvatar ||
                                        bom?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={bom?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(bom?.inventory?.productName, { length: 20 })}
                                </p>
                                {/* <p className="text-[#64748B]"> {bom?.inventory?.sku} </p> */}
                                 <p className={`text-[#64748B] text-[13px]`}>
                                {`${bom?.batches?.length} ${bom?.batches?.length > 1 ? "batches" : "batch"}`}

                                </p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                 expandedRowAction: () => {
                    idx === expandedRowIdx ? setExpandedRowIdx(-1) : setExpandedRowIdx(idx);
                    idx !== expandedRowIdx && setStockList(bom?.batches?.length > 0 ? bom?.batches : [])
                },
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4 text-slate-950">
                            {/* {bom?.quantity !== undefined && formatMoney()?.format(calcQuantity)}{" "}
                            {` `}
                            {bom?.quantity !== undefined &&
                                bom?.inventory?.productStockDetails?.unitOfMeasurement} */}
                                 {totalNeeded !== undefined ? `${formatMoney()?.format(totalNeeded )} ${bom?.inventory?.productStockDetails?.unitOfMeasurement ? bom?.inventory?.productStockDetails?.unitOfMeasurement : ""}` : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4 text-slate-950">
                            {bom?.finalQuantity !== undefined &&
                                formatMoney()?.format(bom?.finalQuantity)}
                            {` `}
                            {bom?.finalQuantity !== undefined &&
                                bom?.inventory?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={` w-full border-slate-200 text-sm  flex items-center  font-rocGroteskMedium `}
                    >
                        <p
                            className={`${
                                bom?.finalQuantity > calcQuantity ? "text-r-50" : "text-[#56B648]"
                            } px-4`}
                        >
                            {bom?.finalQuantity > calcQuantity
                                ? formatMoney()?.format(bom?.materialLoss)
                                : "No discrepancy"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={` w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            <p
                                className={`${
                                    bom?.finalQuantity > calcQuantity
                                        ? "text-r-50"
                                        : "text-[#56B648]"
                                } px-4`}
                            >
                                {bom?.finalQuantity > calcQuantity
                                    ? getCurrencyFromCurrencyCode(bom?.costMaterialLoss?.currency)
                                    : ""}
                                {bom?.finalQuantity > calcQuantity
                                    ? formatMoney()?.format(bom?.costMaterialLoss?.amount)
                                    : "No discrepancy cost"}
                            </p>
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });

      const tableHeader = [
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass:  "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%]  !font-rocGroteskMedium" },
        { title: "",  widthClass: "w-[20%] !font-rocGroteskMedium" },
    ];

    const expandedTableBody = stockList?.map((stock, idx) => {
        
        return [
            {
                content: (
                    <div
                        className={`py-2 px-3 flex font-rocGroteskMedium items-center h-full text-sm `}
                    >
                        <div className="flex items-center space-x-3">
                            {stock?.stock?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        stock?.stock?.productImageDetails?.productAvatar ||
                                        stock?.stock?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={stock?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                </div>
                            )}
                            <span className="text-[#B18000]">
                                {truncate(reportDetails?.report?.billOfMaterialIds[expandedRowIdx]?.inventory?.productName, {
                                    length: 40,
                                })}
                                - {stock?.stock?.batchId}
                            </span>
                        </div>
                    </div>
                ),
              
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
            // {
            //     content: (
            //         <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
            //             <p className=""> {stock?.stock?.productStockDetails?.stockLevel !== undefined ? `${formatMoney()?.format(stock?.stock?.productStockDetails?.stockLevel)} ${reportDetails?.report?.billOfMaterialIds[expandedRowIdx]?.inventory?.productStockDetails?.unitOfMeasurement}` : ""}</p>
            //         </div>
            //     ),
            //     widthClass: "!border-slate-200 !border-b",
            // },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                      {stock?.quantity !== undefined ? `${formatMoney()?.format(stock?.quantity)}` : "" }
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b",
            },
            
            
              {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                      
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
              {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                      
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
             {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                      
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
        ];
    });

    const DirectCostTableHeader = [
        { title: "Category", widthClass: "w-[34%]!font-rocGroteskMedium !border-slate-200" },
        {
            title: "Currency",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Total Estimated Cost",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const DirectCostTableBody = directCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center !font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={item?.amount && true}
                            onChange={(e) => handleDirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            containerClassname=""
                            inputClassName="w-full !font-rocGroteskMedium"
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            inputContainerClassname={`!border-0 !rounded-none ${
                                reportDetails?.report?.status !== "draft" && "!bg-transparent"
                            }`}
                            disabled={reportDetails?.report?.status !== "draft"}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium`}
                    >
                        <p className="text-g-75">{item?.currency}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-g-75 flex items-center !font-rocGroteskMedium `}
                    >
                        {reportDetails?.report?.status === "draft" ? (
                            <TextInput
                                value={item?.amount ?? ""}
                                name={"amount"}
                                type="number"
                                required={item?.category && true}
                                onChange={(e) => handleDirectCostData(e, idx)}
                                inputPlaceholder="0.00"
                                containerClassname=""
                                inputClassName="w-full !font-rocGroteskMedium"
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                inputContainerClassname={`!border-0 !rounded-none ${
                                    reportDetails?.report?.status !== "draft" && "!bg-transparent"
                                }`}
                            />
                        ) : (
                            <p className="px-2.5">{formatMoney()?.format(item?.amount)}</p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });
    const DirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className="border-r border-slate-200 w-[34%]"></div>
                <div className="w-[33%]"></div>
                <div className="w-[33%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        {" "}
                        ${formatMoney()?.format(directCostSum)}
                    </p>
                </div>
            </div>
        );
    };

    const IndirectCostTableHeader = [
        { title: "Category", widthClass: "w-[34%]!font-rocGroteskMedium !border-slate-200" },
        {
            title: "Currency",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Total Estimated Cost",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const IndirectCostTableBody = indirectCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center  font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={item?.amount && true}
                            onChange={(e) => handleIndirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            containerClassname=""
                            inputClassName="w-full !font-rocGroteskMedium"
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            inputContainerClassname={`!border-0 !rounded-none ${
                                reportDetails?.report?.status !== "draft" && "!bg-transparent"
                            }`}
                            disabled={reportDetails?.report?.status !== "draft"}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium`}
                    >
                        <p className="text-g-75">{item?.currency}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        {reportDetails?.report?.status === "draft" ? (
                            <TextInput
                                value={item?.amount ?? ""}
                                name={"amount"}
                                type="number"
                                required={item?.category && true}
                                onChange={(e) => handleIndirectCostData(e, idx)}
                                inputPlaceholder="0.00"
                                containerClassname=""
                                inputClassName="w-full !font-rocGroteskMedium"
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                inputContainerClassname={`!border-0 !rounded-none ${
                                    reportDetails?.report?.status !== "draft" && "!bg-transparent"
                                }`}
                                disabled={reportDetails?.report?.status !== "draft"}
                            />
                        ) : (
                            <p className="px-2.5">{formatMoney()?.format(item?.amount)}</p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });
    const IndirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className="border-r border-slate-200 w-[34%]"></div>
                <div className="w-[33%]"></div>
                <div className="w-[33%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        ${formatMoney()?.format(indirectCostSum)}
                    </p>
                </div>
            </div>
        );
    };
    return (
        <div>
            <PageFrame isLoading={!noReload && isLoading}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSaveReport();
                    }}
                    className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12"
                >
                    <div className="flex items-center justify-between px-8 py-6 border-b border-n-20 sticky top-[-20px] bg-white z-[1200]">
                        <div
                            className="flex items-center space-x-1.5 w-fit cursor-pointer"
                            onClick={() => goBack()}
                        >
                            <i className="ri-arrow-left-line text-[20px]"></i>
                            <p
                                className={`${
                                    reportDetails?.report?.status !== "draft"
                                        ? "underline text-sm"
                                        : "text-lg"
                                }  text-g-75 font-rocGroteskMedium `}
                            >
                                {reportDetails?.report?.status !== "draft"
                                    ? "Back"
                                    : "Final review: Edited production report"}
                            </p>
                        </div>

                        {reportDetails?.report?.status === "draft" ? (
                            <div className="flex items-center  gap-3">
                                <div className="flex items-center gap-2">
                                    <div
                                        className={`w-2 h-2 text-sm rounded-full shrink-0 flex items-center justify-center captialize
                                    bg-slate-300
                                    `}
                                    ></div>

                                    <span className="capitalize text-sm text-slate-500">Draft</span>
                                </div>

                                <Button
                                    btnText="Cancel"
                                    type="button"
                                    onClick={() => {
                                        goBack();
                                    }}
                                    disabled={savingReport}
                                    btnClassname={`!bg-slate-100 !px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                />
                                <Button
                                    btnText="Save Report"
                                    type="submit"
                                    isLoading={savingReport}
                                    disabled={savingReport}
                                    btnClassname={`!px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                />
                            </div>
                        ) : (
                            <div className="flex items-center  gap-3">
                                {/* <Button
                                    btnText="Export to Excel"
                                    type="button"
                                    // onClick={() => {
                                    // }}
                                    btnClassname={`!bg-transparent !border !border-slate-200 !px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                /> */}

                                <PDFDownloadLink
                                    document={
                                        <ReportPDF
                                            taId={reportDetails?.report?.taId}
                                            product={reportDetails?.report?.inventory?.productName}
                                            reportDate={
                                                reportDetails?.report?.createdAt
                                                    ? moment(
                                                          reportDetails?.report?.createdAt
                                                      )?.format("MMM DD, YYYY")
                                                    : "N/A"
                                            }
                                            startDate={
                                                reportDetails?.report?.productionStart
                                                    ? moment(
                                                          reportDetails?.report?.productionStart
                                                      )?.format("MMM DD, YYYY")
                                                    : "N/A"
                                            }
                                            completionDate={
                                                reportDetails?.report?.productionEnd
                                                    ? moment(
                                                          reportDetails?.report?.productionEnd
                                                      )?.format("MMM DD, YYYY")
                                                    : "N/A"
                                            }
                                            shift={
                                                reportDetails?.report?.defaultShift?.startTime &&
                                                reportDetails?.report?.defaultShift?.endTime
                                                    ? `${moment(
                                                          reportDetails?.report?.defaultShift
                                                              ?.startTime,
                                                          "HH:mm"
                                                      )?.format("hh:mma")} - ${moment(
                                                          reportDetails?.report?.defaultShift
                                                              ?.endTime,
                                                          "HH:mm"
                                                      )?.format("hh:mma")}`
                                                    : "N/A"
                                            }
                                            quantityProduced={`${formatMoney().format(
                                                reportDetails?.report?.finalQuantity?.amount
                                            )} ${
                                                reportDetails?.report?.finalQuantity
                                                    ?.unitOfMeasurement
                                            }`}
                                            // sellableUnits={` ${formatMoney().format(
                                            //     reportDetails?.report?.sellableQuantity?.amount
                                            // )} ${
                                            //     reportDetails?.report?.sellableQuantity
                                            //         ?.unitOfMeasurement
                                            // }`}
                                            batches={reportDetails?.report?.totalBatches}
                                            targetQuantity={` ${formatMoney().format(
                                                reportDetails?.report?.plannedQuantity?.amount
                                            )} ${
                                                reportDetails?.report?.plannedQuantity
                                                    ?.unitOfMeasurement
                                            }`}
                                            completionRate={` ${formatMoney().format(
                                                reportDetails?.report?.completionRate
                                            )}${
                                                reportDetails?.report?.completionRate !==
                                                    undefined && "%"
                                            }`}
                                            finishedGoods={reportDetails?.report?.finishedGoods}
                                            yieldPercentage={formatMoney()?.format(
                                                reportDetails?.report?.percentYield
                                            )}
                                            discrepancyCost={`${
                                                reportDetails?.report?.costBOMLoss?.amount !==
                                                    undefined &&
                                                getCurrencyFromCurrencyCode(
                                                    reportDetails?.report?.costBOMLoss?.currency
                                                )
                                            }${formatMoney().format(
                                                reportDetails?.report?.costBOMLoss?.amount
                                            )}`}
                                            // unsellableGoodsCost={` ${
                                            //     reportDetails?.report?.costProductLoss?.amount !==
                                            //         undefined &&
                                            //     getCurrencyFromCurrencyCode(
                                            //         reportDetails?.report?.costProductLoss?.currency
                                            //     )
                                            // }${formatMoney().format(
                                            //     reportDetails?.report?.costProductLoss?.amount
                                            // )}`}
                                            // productionLoss={`${
                                            //     reportDetails?.report?.costBOMLoss?.amount !==
                                            //         undefined &&
                                            //     reportDetails?.report?.costProductLoss?.amount !==
                                            //         undefined &&
                                            //     getCurrencyFromCurrencyCode(
                                            //         reportDetails?.report?.costBOMLoss?.currency
                                            //     )
                                            // }${formatMoney().format(
                                            //     reportDetails?.report?.costBOMLoss?.amount +
                                            //         reportDetails?.report?.costProductLoss?.amount
                                            // )}`}
                                            materialsUsed={reportDetails?.report?.billOfMaterialIds?.map(
                                                (bom) => ({
                                                    ...bom,
                                                    quantity:
                                                        bom?.quantity *
                                                        reportDetails?.report?.plannedQuantity
                                                            ?.amount,
                                                })
                                            )}
                                            directCosts={directCost?.filter(
                                                (item) => item?.category && item?.amount
                                            )}
                                            indirectCosts={indirectCost?.filter(
                                                (item) => item?.category && item?.amount
                                            )}
                                            directCostSum={`$${formatMoney()?.format(
                                                directCostSum
                                            )}`}
                                            indirectCostSum={`$${formatMoney()?.format(
                                                indirectCostSum
                                            )}`}
                                            checkList={reportDetails?.qualityChecklist}
                                            notes={reportDetails?.report?.notes}
                                        />
                                    }
                                    fileName={`production-report-${reportDetails?.report?.taId}.pdf`}
                                >
                                    {({ loading }) => {
                                        return loading ? (
                                            <Button
                                                btnText={"Download"}
                                                type={"button"}
                                                isLoading={loading}
                                                disabled={loading}
                                                btnClassname="!py-2  !bg-slate-100 !text-g-75 !text-[13px] !px-4"
                                            />
                                        ) : (
                                            <Button
                                                btnText={"Download as PDF"}
                                                type={"button"}
                                                isLoading={false}
                                                btnClassname="!py-2 !bg-slate-100 !text-g-75 !border-[#94A3B8] !text-[13px] !px-4"
                                                icon={
                                                    <img
                                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/pdf-document_kqjkit.svg"
                                                        alt=""
                                                        className=""
                                                    />
                                                }
                                            />
                                        );
                                    }}
                                </PDFDownloadLink>

                                <Button
                                    btnText="Copy link"
                                    type="button"
                                    onClick={() => {
                                        handleCopyClick();
                                    }}
                                    btnClassname={`!px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                />
                            </div>
                        )}
                    </div>

                    <div className="px-6">
                        <div className="py-6 border-b border-slate-200">
                            <p className="text-gm-50 text-sm mb-1">Production report ID</p>
                            <h6 className="text-g-75 text-2xl">
                                {reportDetails?.report?.taId && `PR-${reportDetails?.report?.taId}`}
                            </h6>
                            {/* {reportDetails?.report?.status !== "draft" &&
                                !reportDetails?.report?.stockLevelUpdated && (
                                    <div className="flex gap-2 mt-3">
                                        <Button
                                            btnText="Add finished goods to Inventory"
                                            type="button"
                                            onClick={() => setWarehouse(true)}
                                            btnClassname={`!bg-[#09C655] !px-4 !py-2  !w-fit text-[13px] !text-[#fff]`}
                                        />
                                    </div>
                                )} */}
                        </div>

                        <div className="">
                            <div className="grid grid-cols-5 py-6 border-b border-slate-200">
                                <div className="">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Product</p>

                                        <div>
                                            <a id="product-name">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#product-name"
                                                place={"top"}
                                                content="The item being manufactured in this report or production cycle."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <p className="text-gm-50 text-sm">
                                        {reportDetails?.report?.inventory?.productName}
                                    </p>
                                </div>
                                <div className="">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Report Date</p>

                                        <div>
                                            <a id="report-start-date">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#report-start-date"
                                                place={"top"}
                                                content="The date the production data was recorded or reported."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-gm-50 text-sm">
                                        {reportDetails?.report?.createdAt
                                            ? moment(reportDetails?.report?.createdAt)?.format(
                                                  "MMM DD, YYYY"
                                              )
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Production Start Date
                                        </p>

                                        <div>
                                            <a id="start-date">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#start-date"
                                                place={"top"}
                                                content="The actual date when production began."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-gm-50 text-sm">
                                        {reportDetails?.report?.productionStart
                                            ? moment(
                                                  reportDetails?.report?.productionStart
                                              )?.format("MMM DD, YYYY")
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Production Completion Date
                                        </p>

                                        <div>
                                            <a id="completion-date">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#completion-date"
                                                place={"top"}
                                                content="The actual date when production was finished."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-gm-50 text-sm">
                                        {reportDetails?.report?.productionEnd
                                            ? moment(reportDetails?.report?.productionEnd)?.format(
                                                  "MMM DD, YYYY"
                                              )
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Shift</p>

                                        <div>
                                            <a id="shift">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#shift"
                                                place={"top"}
                                                content="The work shift during which the production occurred."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-gm-50 text-sm">
                                        {reportDetails?.report?.defaultShift?.startTime &&
                                        reportDetails?.report?.defaultShift?.endTime
                                            ? `${moment(
                                                  reportDetails?.report?.defaultShift?.startTime,
                                                  "HH:mm"
                                              )?.format("hh:mma")} -
                                              ${moment(
                                                  reportDetails?.report?.defaultShift?.endTime,
                                                  "HH:mm"
                                              )?.format("hh:mma")}`
                                            : "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-6 pb-8 space-y-10 pt-6">
                        <div className="pt-10">
                            <p className="text-base text-gm-50 mb-4">Production summary</p>

                            <div className="grid grid-cols-4 gap-2">
                                <div className="bg-[#E6FFF0] border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-g-75 text-sm">Total quantity produced</p>

                                        <div>
                                            <a id="quantity-produced">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#quantity-produced"
                                                place={"top"}
                                                content="The total number of units manufactured during the production run."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={` text-lg pt-2 flex items-center`}>
                                        <p className="text-[#00A643]">
                                            {formatMoney().format(
                                                reportDetails?.report?.finalQuantity?.amount
                                            )}
                                            {` `}
                                            {
                                                reportDetails?.report?.finalQuantity
                                                    ?.unitOfMeasurement
                                            }
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Sellable Units</p>

                                        <div>
                                            <a id="sellable-units">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#sellable-units"
                                                place={"top"}
                                                content=""
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {` ${formatMoney().format(
                                                reportDetails?.report?.sellableQuantity?.amount
                                            )}
                                            ${
                                                reportDetails?.report?.sellableQuantity
                                                    ?.unitOfMeasurement
                                            }`}
                                        </p>
                                    </div>
                                </div> */}
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Number of batches</p>

                                        <div>
                                            <a id="batches">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#batches"
                                                place={"top"}
                                                content="The total count of batches produced during the run."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">{reportDetails?.report?.totalBatches}</p>
                                    </div>
                                </div>
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Production target quantity
                                        </p>

                                        <div>
                                            <a id="target-quanity">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#target-quanity"
                                                place={"top"}
                                                content="The total number of units targeted for this production run."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {formatMoney().format(
                                                reportDetails?.report?.plannedQuantity?.amount
                                            )}
                                            {` `}
                                            {
                                                reportDetails?.report?.plannedQuantity
                                                    ?.unitOfMeasurement
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Completion rate</p>

                                        <div>
                                            <a id="completion-rate">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#completion-rate"
                                                place={"top"}
                                                content="The percentage of the production target achieved."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {formatMoney().format(
                                                reportDetails?.report?.completionRate
                                            )}
                                            {reportDetails?.report?.completionRate !== undefined &&
                                                "%"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" ">
                            <p className="text-base text-[#000] mb-4">
                                Finished good to be added to inventory
                            </p>

                            <CustomTable
                                tableBody={FinishedGoodsTableBody}
                                tableHeader={FinishedGoodsTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                        </div>

                        <div className="pb-10 ">
                            <p className="text-base text-[#000] mb-4">Production yield</p>

                            <div className="grid grid-cols-3 gap-2">
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Total Units produced
                                        </p>

                                        <div>
                                            <a id="units-produced">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#units-produced"
                                                place={"top"}
                                                content="The total number of units manufactured during the production run."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="text-g-75">
                                            {" "}
                                            {reportDetails?.report?.finalQuantity?.amount !==
                                                undefined &&
                                                formatMoney().format(
                                                    reportDetails?.report?.finalQuantity?.amount
                                                )}
                                            {` `}
                                            {reportDetails?.report?.finalQuantity?.amount !==
                                                undefined &&
                                                reportDetails?.report?.finalQuantity
                                                    ?.unitOfMeasurement}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Sellable Units</p>

                                        <div>
                                            <a id="completion-rate">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#completion-rate"
                                                place={"top"}
                                                content=""
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {reportDetails?.report?.sellableQuantity?.amount !==
                                                undefined &&
                                                formatMoney().format(
                                                    reportDetails?.report?.sellableQuantity?.amount
                                                )}
                                            {` `}
                                            {reportDetails?.report?.sellableQuantity?.amount !==
                                                undefined &&
                                                reportDetails?.report?.sellableQuantity
                                                    ?.unitOfMeasurement}
                                        </p>
                                    </div>
                                </div> */}
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">Yield percentage</p>

                                        <div>
                                            <a id="yield-percentage">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#yield-percentage"
                                                place={"top"}
                                                content="The ratio of total number of units produced to total units targeted for production, expressed as a percentage."
                                                style={{ width: "300px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {reportDetails?.report?.percentYield !== null &&
                                                formatMoney()?.format(
                                                    reportDetails?.report?.percentYield
                                                )}
                                            {reportDetails?.report?.percentYield !== null
                                                ? "%"
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="!mt-6 pb-10 ">
                            <p className="text-base text-[#000] mb-4">Production Loss result</p>
                            <div className="grid grid-cols-3 gap-2 mb-4">
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Material Discrepancy Cost
                                        </p>

                                        <div>
                                            <a id="discrepancy-cost">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#discrepancy-cost"
                                                place={"top"}
                                                content="The cost associated with any material differences or errors during production."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="text-g-75">
                                            {reportDetails?.report?.costBOMLoss?.amount !==
                                                undefined &&
                                                getCurrencyFromCurrencyCode(
                                                    reportDetails?.report?.costBOMLoss?.currency
                                                )}

                                            {reportDetails?.report?.costBOMLoss?.amount !==
                                                undefined &&
                                                formatMoney().format(
                                                    reportDetails?.report?.costBOMLoss?.amount
                                                )}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Unsellable Goods Cost
                                        </p>

                                        <div>
                                            <a id="completion-rate">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#completion-rate"
                                                place={"top"}
                                                content=""
                                                style={{ width: "180px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {reportDetails?.report?.costProductLoss?.amount !==
                                                undefined &&
                                                getCurrencyFromCurrencyCode(
                                                    reportDetails?.report?.costProductLoss?.currency
                                                )}

                                            {reportDetails?.report?.costProductLoss?.amount !==
                                                undefined &&
                                                formatMoney().format(
                                                    reportDetails?.report?.costProductLoss?.amount
                                                )}
                                        </p>
                                    </div>
                                </div>
                                <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                    <div className="flex items-center gap-1">
                                        <p className="text-slate-500 text-sm">
                                            Overall Production Loss
                                        </p>

                                        <div>
                                            <a id="completion-rate">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#completion-rate"
                                                place={"top"}
                                                content=""
                                                style={{ width: "180px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={` text-lg text-g-75 pt-2 flex items-center`}>
                                        <p className="">
                                            {reportDetails?.report?.costBOMLoss?.amount !==
                                                undefined &&
                                                reportDetails?.report?.costProductLoss?.amount !==
                                                    undefined &&
                                                getCurrencyFromCurrencyCode(
                                                    reportDetails?.report?.costBOMLoss?.currency
                                                )}
                                            {formatMoney().format(
                                                reportDetails?.report?.costBOMLoss?.amount +
                                                    reportDetails?.report?.costProductLoss?.amount
                                            )}
                                        </p>
                                    </div>
                                </div> */}
                            </div>

                            <div className="flex items-center gap-1 pt-10 mb-4">
                                <p className="text-base text-[#000] ">
                                    Materials used for production
                                </p>
                                <div>
                                    <a id="raw-materials">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#raw-materials"
                                        place={"top"}
                                        content="A list of raw materials used for this production."
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>
                          
                        <CustomTable
                        tableBody={ProductionSummaryTableBody}
                        tableHeader={ProductionSummaryTableHeader}
                        isScrollable={false}
                        isCellBordered={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        scrollWithin={false}
                        headerContainerClass="!bg-slate-50 !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !text-[#334155] "
                        // tbodyClass="!border !border-slate-200"
                        tableClass="!border-slate-200"
                        bodyItemClass="hover:!bg-[transparent] !cursor-default !border-slate-200"
                        isCollapseable={true}
                        allCellClick={false}
                        expandedRowIdx={expandedRowIdx}
                        rowExpanded={
                                        
                                            <div className=" bg-[#FFFBF0]">
                                            
                                               { stockList?.length === 0 ?
                                                <div className="overflow-x-scroll">
                                                   <p className="py-2 px-4 text-sm text-slate-900">No batch available</p>
                                                </div>
                                                :  <div className="overflow-x-scroll">
                                                    <CustomTable
                                                        tableBody={expandedTableBody}
                                                        tableHeader={tableHeader}
                                                        scrollWithin={false}
                                                        isCellBordered={false}
                                                        isInifiniteScroll={true}
                                                        tableClass="!bg-[#FFFBF0] !border-t-slate-200 !border-b-0 !border-x-0 !rounded-none mt-[-30px]"
                                                        isScrollable={true}
                                                        tableContainerClass=""
                                                        bodyItemClass="hover:!bg-[#FFFBF0]  !bg-[#FFFBF0]  !cursor-default !border-slate-200"
                                                        expandedRowTClass="!z-[0] !relative"
                                                    />
                                                   </div>
                                                }
                                            </div>
                                        
                            }
                        />
                        </div>

                        <div className="">
                            <div className="flex items-center gap-1 p-4 border-x border-t  border-slate-200 rounded-t-md">
                                <p className="text-gm-75 text-base ">Direct costs</p>

                                <div>
                                    <a id="direct-cost">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#direct-cost"
                                        place={"top"}
                                        content=" Costs directly attributable to production, such as raw materials and labor."
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>
                            <CustomTable
                                tableBody={DirectCostTableBody}
                                tableHeader={DirectCostTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                            <DirectCostTableFooter />
                            {reportDetails?.report?.status === "draft" && (
                                <Button
                                    btnText="Add more direct cost"
                                    btnClassname=" mt-4 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() =>
                                        setDirectCost((prev) => [
                                            ...prev,
                                            {
                                                currency: "USD",
                                            },
                                        ])
                                    }
                                />
                            )}
                        </div>

                        <div className="">
                            <div className="flex items-center gap-1 p-4 border-x border-t  border-slate-200 rounded-t-md">
                                <p className="text-gm-75 text-base ">Indirect costs</p>

                                <div>
                                    <a id="indirect-cost">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#indirect-cost"
                                        place={"top"}
                                        content="Overhead expenses not directly tied to production, like utilities and rent."
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>

                            <CustomTable
                                tableBody={IndirectCostTableBody}
                                tableHeader={IndirectCostTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                            <IndirectCostTableFooter />
                            {reportDetails?.report?.status === "draft" && (
                                <Button
                                    btnText="Add more indirect cost"
                                    btnClassname=" mt-4 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() =>
                                        setIndirectCost((prev) => [
                                            ...prev,
                                            {
                                                currency: "USD",
                                            },
                                        ])
                                    }
                                />
                            )}
                        </div>

                        <div className="pt-5 ">
                            <p className="text-base text-gm-50 mb-4">
                                Discrepancies and corrective actions
                            </p>

                            {
                                <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                    <div
                                        className={`flex justify-between items-center py-3 px-4 cursor-pointer ${
                                            showCheckList ? "border-b border-b-slate-200" : ""
                                        }`}
                                        onClick={() => setShowCheckList(!showCheckList)}
                                    >
                                        <div className="flex items-center gap-2">
                                            <i className="ri-list-check text-[#801F0F]"></i>
                                            <p className="text-[#801F0F] text-sm">
                                                Quality Control checklists
                                            </p>
                                        </div>
                                        <i
                                            className={`${
                                                showCheckList
                                                    ? "ri-arrow-up-s-line"
                                                    : "ri-arrow-down-s-line"
                                            } text-gm-50 cursor-pointer`}
                                            onClick={() => setShowCheckList(!showCheckList)}
                                        ></i>
                                    </div>
                                    {showCheckList && (
                                        <div className="py-3 px-4">
                                            <ul className="list-disc py-3 px-4">
                                                {reportDetails?.qualityChecklist?.length >= 1
                                                    ? reportDetails?.qualityChecklist?.map(
                                                          (qc, idx) => (
                                                              <li
                                                                  key={idx}
                                                                  className="text-[#142837] text-sm"
                                                              >
                                                                  {qc?.task}
                                                              </li>
                                                          )
                                                      )
                                                    : "N/A"}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>

                        {/* <div className="pt-5 ">
                            <p className="text-base text-gm-50 mb-4">
                                AI Recommendations and next steps
                            </p>
                            <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                <div
                                    className={`flex justify-between items-center py-3 px-4  cursor-pointer ${
                                        showDefect ? "border-b border-b-slate-200" : ""
                                    }`}
                                    onClick={() => setShowDefects(!showDefect)}
                                >
                                    <div className="flex items-center gap-2">
                                        <i className="ri-list-check text-gm-75"></i>
                                        <p className="text-gm-75 text-sm">
                                            Our Recommendations {`for ${reportDetails?.report?.inventory?.productName}`}
                                        </p>
                                    </div>
                                    <i
                                        className={`${
                                            showDefect
                                                ? "ri-arrow-up-s-line"
                                                : "ri-arrow-down-s-line"
                                        } text-gm-50 cursor-pointer`}
                                    ></i>
                                </div>

                                {showDefect && (
                                    <div className="py-3 px-4 space-y-4">
                                        {summary?.map((item, idx) => (
                                            <div
                                                key={idx}
                                                className={`${
                                                    summary?.length - 1 === idx
                                                        ? ""
                                                        : "border-b border-b-slate-200"
                                                }`}
                                            >
                                                <div className="flex items-center gap-2">
                                                    <div className="flex items-center justify-center w-6 h-6 bg-slate-100 border border-slate-200 rounded-full">
                                                        <i className="ri-list-check text-slate-500"></i>
                                                    </div>
                                                    <p className="text-g-75 text-sm">
                                                        {item?.header}
                                                    </p>
                                                </div>
                                                <ul className="list-disc py-3 pl-8 pr-4 space-y-1.5">
                                                    {item?.body?.map((bodyText, bodyIdx) => (
                                                        <li
                                                            key={bodyIdx}
                                                            className="text-slate-500 text-sm"
                                                        >
                                                            <span className="text-g-75">
                                                                {bodyText?.title}{" "}
                                                            </span>
                                                            <span className="text-slate-500">
                                                                {bodyText?.text}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div> */}

                        {reportDetails?.report?.status === "draft" ? (
                            <div className="pt-5 ">
                                <p className="text-base text-gm-50 mb-4">
                                    Enter notes and recommendations
                                </p>
                                <TextareaInput
                                    name={"notes"}
                                    value={notes}
                                    onChange={(e) => setNotes(e?.target?.value)}
                                    rows={5}
                                    placeholder={"Additional info"}
                                />
                            </div>
                        ) : (
                            reportDetails?.report?.status === "saved" && (
                                <div className="pt-5 ">
                                    <p className="text-base text-gm-50 mb-4">
                                        Notes and recommendations
                                    </p>
                                    <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                        <div
                                            className={`flex justify-between items-center py-3 px-4  cursor-pointer ${
                                                showNotes ? "border-b border-b-slate-200" : ""
                                            }`}
                                            onClick={() => setShowNotes(!showNotes)}
                                        >
                                            <div className="flex items-center gap-2">
                                                <i className="ri-list-check text-gm-75"></i>
                                                <p className="text-gm-75 text-sm">
                                                    Your Recommendations{" "}
                                                    {`for ${reportDetails?.report?.inventory?.productName}`}
                                                </p>
                                            </div>
                                            <i
                                                className={`${
                                                    showNotes
                                                        ? "ri-arrow-up-s-line"
                                                        : "ri-arrow-down-s-line"
                                                } text-gm-50 cursor-pointer`}
                                            ></i>
                                        </div>

                                        {showNotes && (
                                            <div className="py-3 px-4 space-y-4">
                                                <p className="text-slate-500">
                                                    {reportDetails?.report?.notes || "N/A"}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </form>
            </PageFrame>

            <ModalContainer
                open={reportSuccess}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setReportSuccess(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setReportSuccess(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production report successfully saved
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    The production report{" "}
                                    {`for ${reportDetails?.report?.inventory?.productName}`} has
                                    been successfully edited and saved. All changes have been
                                    applied, and the report is now final.
                                </p>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setReportSuccess(false);
                                }}
                            />
                            <Button
                                btnText={"View report"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setReportSuccess(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={review}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setReview(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setReview(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                <i className="ri-file-edit-line text-[40px] text-[#142837]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production report ready for review
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    Your production report{" "}
                                    {`for ${reportDetails?.report?.inventory?.productName}`} has
                                    been generated and is awaiting your review. Please edit any
                                    necessary details before saving to ensure accuracy.
                                </p>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Save report"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setReview(false);
                                }}
                            />
                            <Button
                                btnText={"Review and edit report"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {/* <ModalContainer
                open={warehouse}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setWarehouse(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddToInventory();
                    }}
                    className="bg-white rounded-[8px] relative"
                >
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                <i className="ri-building-line text-[40px] text-[#142837]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Select warehouse for finished goods
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center mb-4`}>
                                    You're about to add the finished goods{" "}
                                    {`for ${reportDetails?.report?.inventory?.productName}`}
                                    {` `}
                                    to inventory. Please select the warehouse where these goods will
                                    be stored.
                                </p>

                                <div className="mb-4">
                                    <SelectInput
                                        value={warehouseId || ""}
                                        name="warehouseId"
                                        placeholder={"Warehouse Location"}
                                        handleChange={(name, value) => {
                                            // handleProductData({ target: { name, value } });
                                            setWarehouseId(value);
                                            if (value) {
                                                const selectedWarehouse = warehouseList?.find(
                                                    (item) => {
                                                        return item?._id === value;
                                                    }
                                                );
                                                setWarehouseDetails(selectedWarehouse);
                                            }
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            handleDebouncedChange(evt);
                                        }, 800)}
                                        searchLoading={isFetching}
                                        clearValue={true}
                                        isRequired={true}
                                        dropdownOptions={warehouseList?.map((warehouse) => ({
                                            label: warehouse.warehouseName,
                                            value: warehouse._id,
                                        }))}
                                        dropdownClassName="!max-h-24"
                                    />
                                </div>

                                {warehouseId && (
                                    <div className="mt-6">
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Warehouse Name</p>
                                            <p className="text-g-75">
                                                {warehouseDetails?.warehouseName}
                                            </p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Location</p>
                                            <p className="text-g-75">
                                                {warehouseDetails?.address?.address ||
                                                warehouseDetails?.address?.country
                                                    ? `${
                                                          warehouseDetails?.address?.address || ""
                                                      } ${warehouseDetails?.address?.country || ""}`
                                                    : "N/A"}
                                            </p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Current Capacity</p>
                                            <p className="text-g-75"></p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Total Capacity</p>
                                            <p className="text-g-75"></p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Last Inventory Date</p>
                                            <p className="text-g-75"></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setWarehouse(false);
                                }}
                            />
                            <Button
                                btnText={"Select warehouse"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                type="submit"
                                isLoading={addingToInventory}
                                disabled={addingToInventory}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={finishedGoodsAdded}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[800px]"
                showCloseIcon={false}
                closeModal={() => {
                    setFinishedGoodsAdded(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setFinishedGoodsAdded(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Finished goods successfully added
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    Your production{" "}
                                    {`of ${reportDetails?.report?.inventory?.productName}`} has been
                                    successfully added to your inventory.
                                </p>
                            </div>
                        </div>

                        <div className="pb-5 px-8 grid grid-cols-2 gap-3">
                            <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                <p className="text-sm text-g-75">Production Summary</p>
                                <p className="text-sm text-slate-500">
                                    Total quantity produced:{" "}
                                    {formatMoney().format(
                                        reportDetails?.report?.finalQuantity?.amount
                                    )}
                                    {` `}
                                    {reportDetails?.report?.finalQuantity?.unitOfMeasurement}
                                </p>
                                <p className="text-sm text-slate-500">
                                    Production date:{" "}
                                    {reportDetails?.report?.productionEnd
                                        ? moment(reportDetails?.report?.productionEnd)?.format(
                                              "MMM DD, YYYY"
                                          )
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                <p className="text-sm text-g-75">Warehouse name</p>
                                <p className="text-sm text-slate-500">
                                    {warehouseDetails?.warehouseName}
                                </p>
                            </div>
                        </div>

                        <div className="px-8 mb-8">
                            <p className="text-sm text-[#000] mb-1">Inventory update</p>

                            <CustomTable
                                tableBody={FinishedGoodsModalTableBody}
                                tableHeader={FinishedGoodsModalTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center  justify-end space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setFinishedGoodsAdded(false);
                                }}
                            />
                            <Button
                                btnText={"View updated inventory"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setFinishedGoodsAdded(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}

            <ModalContainer
                open={productionReport}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[800px]"
                showCloseIcon={false}
                closeModal={() => {
                    setProductionReport(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production report uploaded successfully
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    Your co-manufacturer production report{" "}
                                    {`for ${reportDetails?.report?.inventory?.productName}`}
                                    has been successfully uploaded and inventory has been updated.
                                </p>
                            </div>
                        </div>

                        <div className="pb-5 px-8">
                            <div className="grid grid-cols-2 gap-3">
                                <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                    <p className="text-sm text-g-75">Co-manufacturer</p>
                                    <p className="text-sm text-slate-500">
                                        Island Spirits Distilling Co.
                                    </p>
                                    <p className="text-sm text-slate-500">
                                        Uploaded on: Aug 28, 2024, 15:30 HST
                                    </p>
                                </div>{" "}
                                <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                    <p className="text-sm text-g-75">Production Summary</p>
                                    <p className="text-sm text-slate-500">
                                        Total units produced: 5,000
                                    </p>
                                    <p className="text-sm text-slate-500">
                                        Production date: Aug 25, 2024{" "}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 mb-8">
                            <p className="text-sm text-[#000] mb-1">Inventory update</p>

                            <CustomTable
                                tableBody={FinishedGoodsTableBody}
                                tableHeader={FinishedGoodsTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center  justify-end space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setProductionReport(false);
                                }}
                            />
                            <Button
                                btnText={"View updated inventory"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ReportDetails;
