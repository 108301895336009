import React from "react";
import Dropdown from "../Dropdown";
import { useDispatch } from "react-redux";
import { authActions } from "redux/Ldb/actions";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/storeHooks";

type NavbarProps = {
    showNav?: boolean;
    showNavDropdown?: boolean;
    profile?: { [key: string]: any };
};

const Navbar = ({ showNav, showNavDropdown, profile }: NavbarProps) => {
    const dispatch = useAppDispatch();

    const nameArr = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];
    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    return (
        <nav className="w-full h-[84px] flex justify-between px-[47px] max-sm:px-4 items-center bg-[white] border-b border-n-40">
            <Link to={"/"}>
                <img
                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/synthAlly_logo_skcywb.svg"
                    alt="synthAlly logo"
                    className="w-[110px] h-6 mt-[-5px] max-lg:w-fit max-lg:h-[28px]"
                />
            </Link>

            {!showNav && (
                <div className="flex items-center gap-4 max-lg:hidden">
                    <a
                        href={"https://calendar.app.google/zLPU8pHyV6asLu7t9"}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm hover:underline font-rocGroteskMedium"
                    >
                        Contact sales
                    </a>
                    <p className="text-sm underline font-rocGroteskMedium">|</p>
                    <a href="mailto:hello@synthally.com" className="flex items-center gap-1">
                        <i className="ri-customer-service-2-fill"></i>
                        <span className="text-sm hover:underline font-rocGroteskBold">
                            Contact support
                        </span>
                    </a>
                </div>
            )}
            {showNavDropdown && (
                <div className="flex items-center gap-4">
                    <Dropdown
                        value={""}
                        dropdown={
                            <div className="">
                                <div className="max-sm:hidden grid grid-cols-[0.4fr_2fr_0.2fr] items-center gap-2 py-3 px-2.5 w-[224px] border rounded">
                                    <div className=" rounded-full w-8 h-8 border-[1.5px] border-g-75 flex items-center justify-center text-xs">
                                        {initials}
                                    </div>
                                    <p className="text-sm capitalize">
                                        {truncate(profile?.profile?.businessName, { length: 22 })}
                                    </p>
                                    <div className="flex flex-col justify-center items-center gap-[1px] mt-[-21px]">
                                        <i className="ri-arrow-drop-up-fill h-[4px] before:content-['\ea55']"></i>
                                        <i className="ri-arrow-drop-down-fill h-[4px] before:content-['\ea4f']"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="hidden rounded-full w-8 h-8 border-[1.5px] border-g-75 max-sm:flex items-center justify-center text-xs">
                                        {initials}
                                    </div>
                                </div>
                            </div>
                        }
                        dropdownContainerClasses={
                            " max-sm:left-[-70px] top-[51px] shadow-cardShadow-2 rounded !border-0"
                        }
                        dropdownClassName={"!max-h-fit"}
                        dropdownItemsClasses={"last:text-r-50 last:hover:bg-r-50/20"}
                        name={"quickAction"}
                        dropdownOptions={[
                            {
                                label: "Logout",
                                value: "logout",
                                icon: <i className="ri-logout-box-line"></i>,
                                action: () => dispatch(authActions.logOut()),
                            },
                        ]}
                    />
                </div>
            )}
        </nav>
    );
};

export default Navbar;
