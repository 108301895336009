import React, { useEffect, useState } from "react";
import moment from "moment";
import AlertCard from "components/common/AlertCard";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import useGetSingleProductStockLevels from "hooks/useGetSingleProductStockLevels";
import { truncate } from "lodash";
import { formatMoney } from "helpers";
import { useAppSelector } from "hooks/storeHooks";
import { calculateDateDifference } from "helpers/getTimeDiffInDaysWeeksMths";

const Lots = () => {
    const now = moment();
    const [inventoryDetails, setInventoryDetails] = useState<any>({});
    const {
        data: allWarehouses,
        warehouseValue,
        search,
        handleDebouncedChange,
        handleSearch,
        handleFilterChange,
        handleClearFilter,
    } = useGetAllWarehouses();

    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        updatingSingleProduct,
        updatedSingleProductSuccess,
    } = useAppSelector((state) => state.inventory);
    const { data, isFetching, isLoadingMore, pagination, setUpdatedPage } =
        useGetSingleProductStockLevels(warehouseValue?.value);

    function daysUntil(targetDateStr: string): string {
        const targetDate = new Date(targetDateStr);
        const today = new Date();
        const timeDifference = targetDate.getTime() - today.getTime();
        const totalDaysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        const years = Math.floor(totalDaysLeft / 365);
        const days = totalDaysLeft % 365;
        if (years > 0) {
            return `${years} year(s) and ${days} day(s) left`;
        } else {
            return totalDaysLeft ? `${totalDaysLeft} day(s) left` : "Expired";
        }
    }
    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setInventoryDetails(fetchedSingleInventorySuccess);
        }
    }, [fetchedSingleInventorySuccess]);

    const inventoryLevelTableHeader = [
        { title: "Batch ID", widthClass: "w-[14%]" },
        { title: "Connected source", widthClass: "w-[13%]" },
        { title: "Stock levels", widthClass: "w-[10%]" },
        // { title: "Forecasted stock needs", widthClass: "w-[21.8%]" },
        { title: "Date Created", widthClass: "w-[14%]" },
        { title: "Age of Stock", widthClass: "w-[14%]" },

        { title: "Expiry Date", widthClass: "w-[14%]" },
        { title: "Warehouse", widthClass: "w-[14%]" },
        // { title: "Action", widthClass: "w-[12.5%]" },
    ];

    const inventoryLevelTableBody = data?.map((stock, idx) => {
        const age = calculateDateDifference(stock?.createdAt, now);

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        {stock?.batchId}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        {stock?.receivingChannel.toLowerCase() === "shipment" ||
                        stock?.receivingChannel.toLowerCase() === "excel_file" ||
                        stock?.receivingChannel.toLowerCase() === "offline_purchase" ? (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon("synth")}
                                <span>{truncate("Synth", { length: 10 })} </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(stock?.inventory?.channel)}
                                <span>{truncate(stock?.inventory?.channel, { length: 10 })} </span>
                            </div>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{stock?.productStockDetails?.stockLevel?.toLocaleString()}</span>
                    </div>
                ),
            },

            // {
            //     content: (
            //         <div
            //             className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
            //         >
            //             {stock?.forcastedStockNeeds ? (
            //                 <div className="text-sm font-rocGroteskMedium flex items-center space-x-2">
            //                     <i className="ri-information-line text-sm text-r-50"></i>
            //                     <span>{stock?.forcastedStockNeeds || "N/A"}</span>
            //                 </div>
            //             ) : (
            //                 <div className="text-sm font-rocGroteskMedium flex items-center space-x-2">
            //                     <p> N/A</p>
            //                 </div>
            //             )}
            //         </div>
            //     ),
            // },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="text-sm font-rocGroteskMedium  items-center">
                            <p className="text-[#0F172A]">
                                {moment(
                                    stock?.productViabilityDetails?.manufacturingDate ||
                                        stock?.createdAt
                                ).format("MMM D, YYYY")}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="text-sm font-rocGroteskMedium  items-center">
                            <p className="text-[#0F172A]">{`${age?.time} ${age.duration}`}</p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="text-sm font-rocGroteskMedium  items-center">
                            <p className="text-[#0F172A]">
                                {moment(stock?.productViabilityDetails?.expiryDate).format(
                                    "MMM D, YYYY • h:mm A"
                                )}
                            </p>
                            <p className="text-[#64748B]">
                                {daysUntil(stock?.productViabilityDetails?.expiryDate)}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{stock?.warehouse?.warehouseName}</span>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
            //         >
            //             <Button
            //                 btnText="Restock product"
            //                 btnClassname="!py-1.5 !px-3 !h-8 !text-[13px] !whitespace-nowrap"
            //             />
            //         </div>
            //     ),
            // },
        ];
    });

    return (
        <PageFrame isLoading={isFetching && !warehouseValue}>
            <div className="grid grid-cols-3 space-x-3 mb-6">
                <div className="rounded-[6px] border border-slate-200 p-4">
                    <span className="block font-rocGroteskMedium text-base">
                        Total expected demand
                    </span>
                    <span className="block font-rocGroteskMedium text-2xl leading-[34.56px]">
                        {formatMoney().format(inventoryDetails?.totalForcastedSalesAmount)}
                    </span>
                    <div className="flex items-center space-x-2">
                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                            {formatMoney().format(inventoryDetails?.forecastRecordCount)} week(s)
                        </span>
                    </div>
                </div>

                <div className="rounded-[6px] border border-slate-200 p-4">
                    <span className="block font-rocGroteskMedium text-base">
                        Total inventory across all channels
                    </span>
                    <span className="block font-rocGroteskMedium text-2xl leading-[34.56px]">
                        {inventoryDetails?.inventory?.productStockDetails?.stockLevel
                            ? formatMoney().format(
                                  inventoryDetails?.inventory?.productStockDetails?.stockLevel
                              )
                            : 0}
                    </span>
                    {/* <div className="flex items-center space-x-2">
                        <div className="flex items-center space-x-1 px-1.5 h-6 rounded-[3px] bg-[#FEF2F2]">
                            <i className="ri-arrow-down-line text-sm text-[#DC2626]"></i>
                            <span className="text-sm text-[#DC2626] font-rocGroteskMedium">
                                -20%
                            </span>
                        </div>

                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                            vs previous forcast
                        </span>
                    </div> */}
                </div>
            </div>

            <div className="pt-2 mb-[80px]">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-xl font-rocGroteskMedium">Inventory levels</h4>
                    <Dropdown
                        handleChange={(name, value) => {
                            console.log("first", name, value);
                        }}
                        dropdown={
                            <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                    {warehouseValue?.name || "Warehouse"}
                                </span>
                                {warehouseValue?.name && (
                                    <i
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setUpdatedPage(1);
                                            handleClearFilter();
                                        }}
                                        className="ri-close-circle-line text-slate-500"
                                    ></i>
                                )}
                                <i className="ri-arrow-down-s-line text-g-75"></i>
                            </div>
                        }
                        // dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-[0] rounded`}
                        dropdownOptions={[
                            // <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            {
                                customChild: (
                                    <div>
                                        <div>
                                            <TextInput
                                                value={search}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={handleSearch}
                                                onInput={handleDebouncedChange}
                                                inputPlaceholder="Search warehouse"
                                                containerClassname=""
                                                // inputContainerClassname={"!border-none !rounded-none "}
                                                inputClassName="w-full"
                                            />
                                        </div>
                                        <div>
                                            {allWarehouses?.map((warehouse) => {
                                                return (
                                                    <div
                                                        key={warehouse?._id}
                                                        onClick={() => {
                                                            setUpdatedPage(1);
                                                            handleFilterChange(
                                                                warehouse?.warehouseName,
                                                                warehouse?._id
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                warehouseValue?.value ===
                                                                    warehouse?._id && "bg-slate-100"
                                                            }`}
                                                        >
                                                            <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                            <div>
                                                                <span
                                                                    className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                        warehouse?.address
                                                                            ?.address && "mb-[2px]"
                                                                    }`}
                                                                >
                                                                    {warehouse?.warehouseName}
                                                                </span>
                                                                <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                    {warehouse?.address?.address}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>

                {data?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={inventoryLevelTableBody}
                            tableHeader={inventoryLevelTableHeader}
                            isScrollable={false}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-6"
                        />

                        {isFetching && isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex justify-center mt-10">
                        <p className="text-lg font-rocGroteskMedium text-center">
                            No data available
                        </p>
                    </div>
                )}
            </div>
        </PageFrame>
    );
};

export default Lots;
