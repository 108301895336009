import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const marketingCampaignService = {
    planCampaign,
    addCampaign,
    getAllCampaigns,
    getAllCampaignsCalendar,
};

async function planCampaign(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/plan`,
        requestOptions
    );
    return res;
}

async function addCampaign(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/add-to-calendar`,
        requestOptions
    );
    return res;
}

async function getAllCampaigns(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    channel?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
        status,
        channel,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getAllCampaignsCalendar(search?: string, status?: string, channel?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        search: search as string,
        status,
        channel,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/campaign/calendar?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
