export const marketingCampaignTypes = {
    PLAN_CAMPAIGN_REQUEST: "PLAN_CAMPAIGN_REQUEST",
    PLAN_CAMPAIGN_SUCCESS: "PLAN_CAMPAIGN_SUCCESS",
    PLAN_CAMPAIGN_FAILURE: "PLAN_CAMPAIGN_FAILURE",
    ADD_CAMPAIGN_TO_CALENDAR_REQUEST: "ADD_CAMPAIGN_TO_CALENDAR_REQUEST",
    ADD_CAMPAIGN_TO_CALENDAR_SUCCESS: "ADD_CAMPAIGN_TO_CALENDAR_SUCCESS",
    ADD_CAMPAIGN_TO_CALENDAR_FAILURE: "ADD_CAMPAIGN_TO_CALENDAR_FAILURE",
    FETCH_ALL_CAMPAIGN_REQUEST: "FETCH_ALL_CAMPAIGN_REQUEST",
    FETCH_ALL_CAMPAIGN_SUCCESS: "FETCH_ALL_CAMPAIGN_SUCCESS",
    FETCH_ALL_CAMPAIGN_FAILURE: "FETCH_ALL_CAMPAIGN_FAILURE",
    FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_REQUEST: "FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_REQUEST",
    FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_SUCCESS: "FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_SUCCESS",
    FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_FAILURE: "FETCH_ALL_CAMPAIGN_CALENDAR_VIEW_FAILURE",
};
