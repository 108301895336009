import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, set, sortBy, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useParams, useSearchParams } from "react-router-dom";

const useGetWarehouseGlobalProduct = (scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [categoryFilter, setCategoryFilter] = useState<string>("");
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [stockLevelFilter, setStockLevelFilter] = useState<string>("");
    const [applyFilter, setApplyFilter] = useState<boolean>(false);

    const [stockLevelRange, setStockLevelRange] = useState<{
        stockLevelMin?: string;
        stockLevelMax?: string;
    }>({});
    const [forecastedNeedsRange, setForecastedNeedsRange] = useState<{
        forecastedDemandMin?: string;
        forecastedDemandMax?: string;
    }>({});
    const [allProductsNoVariantValues, setAllProductsNoVariantValues] = useState<{
        sortBy?: string;
        sortDirection?: string;
    }>({});
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const {
        fetchingWarehouseGlobalProduct,
        fetchedWarehouseGlobalProductSuccess,
        fetchedWarehouseGlobalProductFailure,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setProductList([]);
        setDebouncedSearch(e.target.value);
    }, 1000);

    useEffect(() => {
        setUpdatedPage(1);
        setProductList([]);
    }, [applyFilter]);

    useEffect(() => {
        if (id) {
            dispatch(
                inventoryActions.fetchWarehouseGlobalProduct(
                    id,
                    10,
                    updatedPage,
                    debouncedSearch,
                    channelFilter,
                    allProductsNoVariantValues?.sortBy,
                    allProductsNoVariantValues?.sortDirection,
                    stockLevelRange?.stockLevelMin,
                    stockLevelRange?.stockLevelMax,
                    forecastedNeedsRange?.forecastedDemandMin,
                    forecastedNeedsRange?.forecastedDemandMax
                )
            );
        }
        return () => {
            dispatch(inventoryActions.resetFetchWarehouseGlobalProduct());
        };
    }, [dispatch, debouncedSearch, updatedPage, allProductsNoVariantValues, applyFilter]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseGlobalProductSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy(
                          [...fetchedWarehouseGlobalProductSuccess?.warehouse?.products?.data],
                          "_id"
                      )
                    : uniqBy(
                          [
                              ...prev,
                              ...fetchedWarehouseGlobalProductSuccess?.warehouse?.products?.data,
                          ],
                          "_id"
                      )
            );
            setPagination({
                currentPage: fetchedWarehouseGlobalProductSuccess.pagination?.current,
                noOfPages: fetchedWarehouseGlobalProductSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchWarehouseGlobalProduct());
        }
    }, [
        dispatch,
        fetchedWarehouseGlobalProductSuccess,
        debouncedSearch,
        updatedPage,
        allProductsNoVariantValues,
        applyFilter,
    ]);

    return {
        isFetching: fetchingWarehouseGlobalProduct,
        error: fetchedWarehouseGlobalProductFailure,
        data: productList,
        setProductList,
        setUpdatedPage,
        pagination,
        setPagination,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedChange,
        isLoadingMore,
        updatedPage,
        setAllProductsNoVariantValues,
        setStockLevelRange,
        setForecastedNeedsRange,
        setCategoryFilter,
        stockLevelRange,
        forecastedNeedsRange,
        categoryFilter,
        channelFilter,
        setChannelFilter,
        stockLevelFilter,
        setStockLevelFilter,
        applyFilter,
        setApplyFilter,
    };
};

export default useGetWarehouseGlobalProduct;
