import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetCampaignComments = (isResolvedTab?: boolean, isMentionTab?: boolean) => {
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();

    const {
        fetchingCampaignComment,
        fetchCampaignCommentSuccess,
        fetchCampaignCommentFailure,
        createCampaignCommentSuccess,
    } = useAppSelector((state) => state.workforce);

    const [comments, setComments] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        if (campaignId) {
            dispatch(workforceActions.getCampaignComments(campaignId, isResolvedTab, isMentionTab));

            return () => {
                dispatch(workforceActions.resetGetCompaignCommentSuccess());
            };
        }
    }, [dispatch, campaignId, isResolvedTab, isMentionTab, createCampaignCommentSuccess]);

    useEffect(() => {
        if (Boolean(fetchCampaignCommentSuccess)) {
            console.log("fetchCampaignCommentSuccess", fetchCampaignCommentSuccess);
            setComments(fetchCampaignCommentSuccess?.comments);
        }
    }, [fetchCampaignCommentSuccess]);

    return {
        isFetching: fetchingCampaignComment,
        error: fetchCampaignCommentFailure,
        data: comments,
    };
};

export default useGetCampaignComments;
