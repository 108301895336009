import { authTypes, integrationTypes } from "redux/Ldb/types";

const initialState = {
    fetchingQuickbooksOauthUrl: false,
    fetchedQuickbooksOauthUrlSuccess: null,
    fetchedQuickbooksOauthUrlFailure: null,
    fetchingQuickbooksExchangeCode: false,
    fetchedQuickbooksExchangeCodeSuccess: null,
    fetchedQuickbooksExchangeCodeFailure: null,
    fetchingQuickbooksApAccounts: false,
    fetchedQuickbooksApAccountsSuccess: null,
    fetchedQuickbooksApAccountsFailure: null,
    fetchingQuickbooksExpenseAccounts: false,
    fetchedQuickbooksExpenseAccountsSuccess: null,
    fetchedQuickbooksExpenseAccountsFailure: null,
    setQuickbooksAccount: false,
    setQuickbooksAccountSuccess: null,
    setQuickbooksAccountFailure: null,
    fetchingShopifyOauthUrl: false,
    fetchedShopifyOauthUrlSuccess: null,
    fetchedShopifyOauthUrlFailure: null,
    verifyShopifyAccount: false,
    verifyShopifyAccountSuccess: null,
    verifyShopifyAccountFailure: null,
    fetchSocialAccount: false,
    fetchSocialAccountSuccess: null,
    fetchSocialAccountFailure: null,
    fetchShopifyData: false,
    fetchShopifyDataSuccess: null,
    fetchShopifyDataFailure: null,
    updateSocialAccount: false,
    updateSocialAccountSuccess: null,
    updateSocialAccountFailure: null,
    fetchingQuickbooksIncomeAccounts: false,
    fetchedQuickbooksIncomeAccountsSuccess: null,
    fetchedQuickbooksIncomeAccountsFailure: null,
    fetchingQuickbooksAssetAccounts: false,
    fetchedQuickbooksAssetAccountsSuccess: null,
    fetchedQuickbooksAssetAccountsFailure: null,
    fetchingAmazonOauthUrl: false,
    fetchedAmazonOauthUrlSuccess: null,
    fetchedAmazonOauthUrlFailure: null,
    verifyAmazonAccount: false,
    verifyAmazonAccountSuccess: null,
    verifyAmazonAccountFailure: null,
    fetchingSpsCommerceOauthUrl: false,
    fetchedSpsCommerceOauthUrlSuccess: null,
    fetchedSpsCommerceOauthUrlFailure: null,
    completingSpsCommerceConnection: false,
    completeSpsCommerceConnectionSuccess: null,
    completeSpsCommerceConnectionFailure: null,
    fetchingQbDesktopOauthUrl: false,
    fetchedQbDesktopOauthUrlSuccess: null,
    fetchedQbDesktopOauthUrlFailure: null,
    completingQbDesktopConnection: false,
    completeQbDesktopConnectionSuccess: null,
    completeQbDesktopConnectionFailure: null,
    fetchingQbDesktopIncomeAccounts: false,
    fetchedQbDesktopIncomeAccountsSuccess: null,
    fetchedQbDesktopIncomeAccountsFailure: null,
    fetchingQbDesktopCurrentAssetAccounts: false,
    fetchedQbDesktopCurrentAssetAccountsSuccess: null,
    fetchedQbDesktopCurrentAssetAccountsFailure: null,
    fetchingQbDesktopFixedAssetAccounts: false,
    fetchedQbDesktopFixedAssetAccountsSuccess: null,
    fetchedQbDesktopFixedAssetAccountsFailure: null,
    fetchingQbDesktopApAccounts: false,
    fetchedQbDesktopApAccountsSuccess: null,
    fetchedQbDesktopApAccountsFailure: null,
    fetchingQbDesktopExpenseAccounts: false,
    fetchedQbDesktopExpenseAccountsSuccess: null,
    fetchedQbDesktopExpenseAccountsFailure: null,
    settingUpQbDesktop: false,
    setupQbDesktopSuccess: null,
    setupQbDesktopFailure: null,
    settingUpKlaviyoConnection: false,
    setupKlaviyoConnectionSuccess: null,
    setupKlaviyoConnectionFailure: null,
    completingKlaviyoConnection: false,
    completeKlaviyoConnectionSuccess: null,
    completeKlaviyoConnectionFailure: null,
    settingUpGoogleAdsConnection: false,
    setupGoogleAdsConnectionSuccess: null,
    setupGoogleAdsConnectionFailure: null,
    completingGoogleAdsConnection: false,
    completeGoogleAdsConnectionSuccess: null,
    completeGoogleAdsConnectionFailure: null,
    settingUpTiktokConnection: false,
    setupTiktokConnectionSuccess: null,
    setupTiktokConnectionFailure: null,
    completingTiktokConnection: false,
    completeTiktokConnectionSuccess: null,
    completeTiktokConnectionFailure: null,
};

export const integration = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case integrationTypes.COMPLETE_TIKTOK_CONNECTION_REQUEST:
            return {
                ...state,
                completingTiktokConnection: true,
                completeTiktokConnectionSuccess: null,
                completeTiktokConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_TIKTOK_CONNECTION_SUCCESS:
            return {
                ...state,
                completingTiktokConnection: false,
                completeTiktokConnectionSuccess: action.payload,
                completeTiktokConnectionFailure: null,
            };
        case integrationTypes.SETUP_TIKTOK_CONNECTION_FAILURE:
            return {
                ...state,
                completingTiktokConnection: false,
                completeTiktokConnectionSuccess: null,
                completeTiktokConnectionFailure: action.payload,
            };

        case integrationTypes.SETUP_TIKTOK_CONNECTION_REQUEST:
            return {
                ...state,
                settingUpTiktokConnection: true,
                setupTiktokConnectionSuccess: null,
                setupTiktokConnectionFailure: null,
            };
        case integrationTypes.SETUP_TIKTOK_CONNECTION_SUCCESS:
            return {
                ...state,
                settingUpTiktokConnection: false,
                setupTiktokConnectionSuccess: action.payload,
                setupTiktokConnectionFailure: null,
            };
        case integrationTypes.SETUP_TIKTOK_CONNECTION_FAILURE:
            return {
                ...state,
                settingUpTiktokConnection: false,
                setupTiktokConnectionSuccess: null,
                setupTiktokConnectionFailure: action.payload,
            };

        case integrationTypes.COMPLETE_GOOGLE_ADS_CONNECTION_REQUEST:
            return {
                ...state,
                completingGoogleAdsConnection: true,
                completeGoogleAdsConnectionSuccess: null,
                completeGoogleAdsConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_GOOGLE_ADS_CONNECTION_SUCCESS:
            return {
                ...state,
                completingGoogleAdsConnection: false,
                completeGoogleAdsConnectionSuccess: action.payload,
                completeGoogleAdsConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_GOOGLE_ADS_CONNECTION_FAILURE:
            return {
                ...state,
                completingGoogleAdsConnection: false,
                completeGoogleAdsConnectionSuccess: null,
                completeGoogleAdsConnectionFailure: action.payload,
            };

        case integrationTypes.SETUP_GOOGLE_ADS_CONNECTION_REQUEST:
            return {
                ...state,
                settingUpGoogleAdsConnection: true,
                setupGoogleAdsConnectionSuccess: null,
                setupGoogleAdsConnectionFailure: null,
            };
        case integrationTypes.SETUP_GOOGLE_ADS_CONNECTION_SUCCESS:
            return {
                ...state,
                settingUpGoogleAdsConnection: false,
                setupGoogleAdsConnectionSuccess: action.payload,
                setupGoogleAdsConnectionFailure: null,
            };
        case integrationTypes.SETUP_GOOGLE_ADS_CONNECTION_FAILURE:
            return {
                ...state,
                settingUpGoogleAdsConnection: false,
                setupGoogleAdsConnectionSuccess: null,
                setupGoogleAdsConnectionFailure: action.payload,
            };

        case integrationTypes.COMPLETE_KLAVIYO_CONNECTION_REQUEST:
            return {
                ...state,
                completingKlaviyoConnection: true,
                completeKlaviyoConnectionSuccess: null,
                completeKlaviyoConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_KLAVIYO_CONNECTION_SUCCESS:
            return {
                ...state,
                completingKlaviyoConnection: false,
                completeKlaviyoConnectionSuccess: action.payload,
                completeKlaviyoConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_KLAVIYO_CONNECTION_FAILURE:
            return {
                ...state,
                completingKlaviyoConnection: false,
                completeKlaviyoConnectionSuccess: null,
                completeKlaviyoConnectionFailure: action.payload,
            };

        case integrationTypes.SETUP_KLAVIYO_CONNECTION_REQUEST:
            return {
                ...state,
                settingUpKlaviyoConnection: true,
                setupKlaviyoConnectionSuccess: null,
                setupKlaviyoConnectionFailure: null,
            };
        case integrationTypes.SETUP_KLAVIYO_CONNECTION_SUCCESS:
            return {
                ...state,
                settingUpKlaviyoConnection: false,
                setupKlaviyoConnectionSuccess: action.payload,
                setupKlaviyoConnectionFailure: null,
            };
        case integrationTypes.SETUP_KLAVIYO_CONNECTION_FAILURE:
            return {
                ...state,
                settingUpKlaviyoConnection: false,
                setupKlaviyoConnectionSuccess: null,
                setupKlaviyoConnectionFailure: action.payload,
            };

        case integrationTypes.SETUP_QB_DESKTOP_REQUEST:
            return {
                ...state,
                settingUpQbDesktop: true,
                setupQbDesktopSuccess: null,
                setupQbDesktopFailure: null,
            };
        case integrationTypes.SETUP_QB_DESKTOP_SUCCESS:
            return {
                ...state,
                settingUpQbDesktop: false,
                setupQbDesktopSuccess: action.payload,
                setupQbDesktopFailure: null,
            };
        case integrationTypes.SETUP_QB_DESKTOP_FAILURE:
            return {
                ...state,
                settingUpQbDesktop: false,
                setupQbDesktopSuccess: null,
                setupQbDesktopFailure: action.payload,
            };

        case integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQbDesktopExpenseAccounts: true,
                fetchedQbDesktopExpenseAccountsSuccess: null,
                fetchedQbDesktopExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopExpenseAccounts: false,
                fetchedQbDesktopExpenseAccountsSuccess: action.payload,
                fetchedQbDesktopExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQbDesktopExpenseAccounts: false,
                fetchedQbDesktopExpenseAccountsSuccess: null,
                fetchedQbDesktopExpenseAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQbDesktopApAccounts: true,
                fetchedQbDesktopApAccountsSuccess: null,
                fetchedQbDesktopApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopApAccounts: false,
                fetchedQbDesktopApAccountsSuccess: action.payload,
                fetchedQbDesktopApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQbDesktopApAccounts: false,
                fetchedQbDesktopApAccountsSuccess: null,
                fetchedQbDesktopApAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQbDesktopCurrentAssetAccounts: true,
                fetchedQbDesktopCurrentAssetAccountsSuccess: null,
                fetchedQbDesktopCurrentAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopCurrentAssetAccounts: false,
                fetchedQbDesktopCurrentAssetAccountsSuccess: action.payload,
                fetchedQbDesktopCurrentAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQbDesktopCurrentAssetAccounts: false,
                fetchedQbDesktopCurrentAssetAccountsSuccess: null,
                fetchedQbDesktopCurrentAssetAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQbDesktopFixedAssetAccounts: true,
                fetchedQbDesktopFixedAssetAccountsSuccess: null,
                fetchedQbDesktopFixedAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopFixedAssetAccounts: false,
                fetchedQbDesktopFixedAssetAccountsSuccess: action.payload,
                fetchedQbDesktopFixedAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQbDesktopFixedAssetAccounts: false,
                fetchedQbDesktopFixedAssetAccountsSuccess: null,
                fetchedQbDesktopFixedAssetAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQbDesktopIncomeAccounts: true,
                fetchedQbDesktopIncomeAccountsSuccess: null,
                fetchedQbDesktopIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopIncomeAccounts: false,
                fetchedQbDesktopIncomeAccountsSuccess: action.payload,
                fetchedQbDesktopIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQbDesktopIncomeAccounts: false,
                fetchedQbDesktopIncomeAccountsSuccess: null,
                fetchedQbDesktopIncomeAccountsFailure: action.payload,
            };

        case integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_REQUEST:
            return {
                ...state,
                completingQbDesktopConnection: true,
                completeQbDesktopConnectionSuccess: null,
                completeQbDesktopConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_SUCCESS:
            return {
                ...state,
                completingQbDesktopConnection: false,
                completeQbDesktopConnectionSuccess: action.payload,
                completeQbDesktopConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_FAILURE:
            return {
                ...state,
                completingQbDesktopConnection: false,
                completeQbDesktopConnectionSuccess: null,
                completeQbDesktopConnectionFailure: action.payload,
            };

        case integrationTypes.GET_QB_DESKTOP_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingQbDesktopOauthUrl: true,
                fetchedQbDesktopOauthUrlSuccess: null,
                fetchedQbDesktopOauthUrlFailure: null,
            };
        case integrationTypes.GET_QB_DESKTOP_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingQbDesktopOauthUrl: false,
                fetchedQbDesktopOauthUrlSuccess: action.payload,
                fetchedQbDesktopOauthUrlFailure: null,
            };
        case integrationTypes.GET_QB_DESKTOP_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingQbDesktopOauthUrl: false,
                fetchedQbDesktopOauthUrlSuccess: null,
                fetchedQbDesktopOauthUrlFailure: action.payload,
            };

        case integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_REQUEST:
            return {
                ...state,
                completingSpsCommerceConnection: true,
                completeSpsCommerceConnectionSuccess: null,
                completeSpsCommerceConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_SUCCESS:
            return {
                ...state,
                completingSpsCommerceConnection: false,
                completeSpsCommerceConnectionSuccess: action.payload,
                completeSpsCommerceConnectionFailure: null,
            };
        case integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_FAILURE:
            return {
                ...state,
                completingSpsCommerceConnection: false,
                completeSpsCommerceConnectionSuccess: null,
                completeSpsCommerceConnectionFailure: action.payload,
            };

        case integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingSpsCommerceOauthUrl: true,
                fetchedSpsCommerceOauthUrlSuccess: null,
                fetchedSpsCommerceOauthUrlFailure: null,
            };
        case integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingSpsCommerceOauthUrl: false,
                fetchedSpsCommerceOauthUrlSuccess: action.payload,
                fetchedSpsCommerceOauthUrlFailure: null,
            };
        case integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingSpsCommerceOauthUrl: false,
                fetchedSpsCommerceOauthUrlSuccess: null,
                fetchedSpsCommerceOauthUrlFailure: action.payload,
            };

        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: true,
                fetchedQuickbooksIncomeAccountsSuccess: null,
                fetchedQuickbooksIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: false,
                fetchedQuickbooksIncomeAccountsSuccess: action.payload,
                fetchedQuickbooksIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: false,
                fetchedQuickbooksIncomeAccountsSuccess: null,
                fetchedQuickbooksIncomeAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: true,
                fetchedQuickbooksAssetAccountsSuccess: null,
                fetchedQuickbooksAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: false,
                fetchedQuickbooksAssetAccountsSuccess: action.payload,
                fetchedQuickbooksAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: false,
                fetchedQuickbooksAssetAccountsSuccess: null,
                fetchedQuickbooksAssetAccountsFailure: action.payload,
            };

        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: true,
                fetchedQuickbooksOauthUrlSuccess: null,
                fetchedQuickbooksOauthUrlFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: false,
                fetchedQuickbooksOauthUrlSuccess: action.payload,
                fetchedQuickbooksOauthUrlFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: false,
                fetchedQuickbooksOauthUrlSuccess: null,
                fetchedQuickbooksOauthUrlFailure: action.payload,
            };

        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_REQUEST:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: true,
                fetchedQuickbooksExchangeCodeSuccess: null,
                fetchedQuickbooksExchangeCodeFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: false,
                fetchedQuickbooksExchangeCodeSuccess: action.payload,
                fetchedQuickbooksExchangeCodeFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_FAILURE:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: false,
                fetchedQuickbooksExchangeCodeSuccess: null,
                fetchedQuickbooksExchangeCodeFailure: action.payload,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksApAccounts: true,
                fetchedQuickbooksApAccountsSuccess: null,
                fetchedQuickbooksApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksApAccounts: false,
                fetchedQuickbooksApAccountsSuccess: action.payload,
                fetchedQuickbooksApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksApAccounts: false,
                fetchedQuickbooksApAccountsSuccess: null,
                fetchedQuickbooksApAccountsFailure: action.payload,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: true,
                fetchedQuickbooksExpenseAccountsSuccess: null,
                fetchedQuickbooksExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: false,
                fetchedQuickbooksExpenseAccountsSuccess: action.payload,
                fetchedQuickbooksExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: false,
                fetchedQuickbooksExpenseAccountsSuccess: null,
                fetchedQuickbooksExpenseAccountsFailure: action.payload,
            };

        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_REQUEST:
            return {
                ...state,
                setQuickbooksAccount: true,
                setQuickbooksAccountSuccess: null,
                setQuickbooksAccountFailure: null,
            };
        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_SUCCESS:
            return {
                ...state,
                setQuickbooksAccount: false,
                setQuickbooksAccountSuccess: action.payload,
                setQuickbooksAccountFailure: null,
            };
        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_FAILURE:
            return {
                ...state,
                setQuickbooksAccount: false,
                setQuickbooksAccountSuccess: null,
                setQuickbooksAccountFailure: action.payload,
            };

        case integrationTypes.GET_SHOPIFY_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingShopifyOauthUrl: true,
                fetchedShopifyOauthUrlSuccess: null,
                fetchedShopifyOauthUrlFailure: null,
            };
        case integrationTypes.GET_SHOPIFY_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingShopifyOauthUrl: false,
                fetchedShopifyOauthUrlSuccess: action.payload,
                fetchedShopifyOauthUrlFailure: null,
            };
        case integrationTypes.GET_SHOPIFY_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingShopifyOauthUrl: false,
                fetchedShopifyOauthUrlSuccess: null,
                fetchedShopifyOauthUrlFailure: action.payload,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_REQUEST:
            return {
                ...state,
                verifyShopifyAccount: true,
                verifyShopifyAccountSuccess: null,
                verifyShopifyAccountFailure: null,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                verifyShopifyAccount: false,
                verifyShopifyAccountSuccess: action.payload,
                verifyShopifyAccountFailure: null,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_FAILURE:
            return {
                ...state,
                verifyShopifyAccount: false,
                verifyShopifyAccountSuccess: null,
                verifyShopifyAccountFailure: action.payload,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_REQUEST:
            return {
                ...state,
                fetchSocialAccount: true,
                fetchSocialAccountSuccess: null,
                fetchSocialAccountFailure: null,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetchSocialAccount: false,
                fetchSocialAccountSuccess: action.payload,
                fetchSocialAccountFailure: null,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_FAILURE:
            return {
                ...state,
                fetchSocialAccount: false,
                fetchSocialAccountSuccess: null,
                fetchSocialAccountFailure: action.payload,
            };

        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_REQUEST:
            return {
                ...state,
                updateSocialAccount: true,
                updateSocialAccountSuccess: null,
                updateSocialAccountFailure: null,
            };
        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_SUCCESS:
            return {
                ...state,
                updateSocialAccount: false,
                updateSocialAccountSuccess: action.payload,
                updateSocialAccountFailure: null,
            };
        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_FAILURE:
            return {
                ...state,
                updateSocialAccount: false,
                updateSocialAccountSuccess: null,
                updateSocialAccountFailure: action.payload,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_REQUEST:
            return {
                ...state,
                fetchShopifyData: true,
                fetchShopifyDataSuccess: null,
                fetchShopifyDataFailure: null,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_SUCCESS:
            return {
                ...state,
                fetchShopifyData: false,
                fetchShopifyDataSuccess: action.payload,
                fetchShopifyDataFailure: null,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_FAILURE:
            return {
                ...state,
                fetchShopifyData: false,
                fetchShopifyDataSuccess: null,
                fetchShopifyDataFailure: action.payload,
            };

        case integrationTypes.GET_AMAZON_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingAmazonOauthUrl: true,
                fetchedAmazonOauthUrlSuccess: null,
                fetchedAmazonOauthUrlFailure: null,
            };
        case integrationTypes.GET_AMAZON_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingAmazonOauthUrl: false,
                fetchedAmazonOauthUrlSuccess: action.payload,
                fetchedAmazonOauthUrlFailure: null,
            };
        case integrationTypes.GET_AMAZON_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingAmazonOauthUrl: false,
                fetchedAmazonOauthUrlSuccess: null,
                fetchedAmazonOauthUrlFailure: action.payload,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_REQUEST:
            return {
                ...state,
                verifyAmazonAccount: true,
                verifyAmazonAccountSuccess: null,
                verifyAmazonAccountFailure: null,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_SUCCESS:
            return {
                ...state,
                verifyAmazonAccount: false,
                verifyAmazonAccountSuccess: action.payload,
                verifyAmazonAccountFailure: null,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_FAILURE:
            return {
                ...state,
                verifyAmazonAccount: false,
                verifyAmazonAccountSuccess: null,
                verifyAmazonAccountFailure: action.payload,
            };
        default:
            return state;
    }
};
