import CustomTable from "../../../../../../../common/CustomTable";
import Dropdown from "../../../../../../../common/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import VendorAvatar from "../../../../../../../common/VendorAvatar";
import { debounce, truncate } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { formatMoney, getSalesMultiBarChartData } from "../../../../../../../../helpers";
import useGetAllWarehouses from "../../../../../../../../hooks/useGetAllWarehouses";
import TextInput from "../../../../../../../common/InputField/TextInput";
import Loader from "../../../../../../../common/Loader";
import { displayProductChannelIcon } from "../../../../../../../../helpers/displayProductChannelIcon";
import CustomCalendar from "../../../../../../../common/CalendarComp.js/Calendar";
import Button from "../../../../../../../common/Button";
import { Tooltip } from "react-tooltip";
import useGetGlobalProductSales from "hooks/inventoryHooks/useGetGlobalProductSales";
import ReactECharts from "echarts-for-react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";

type ProductProps = {
    productList: { [key: string]: any }[];
    pagination: { [key: string]: any };
    setUpdatedPage: (page: number) => void;
    isLoadingMore: boolean;
    isFetching: boolean;
};
const ProductsVariant = ({
    productList,
    pagination,
    setUpdatedPage,
    isLoadingMore,
    isFetching,
}: ProductProps) => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const customRef = useRef(null);
    const dispatch = useAppDispatch();
    const [durationFilter, setDurationFilter] = useState<string>("This year");
    const [customFilter, setCustomFilter] = useState<string>("");
    const [startAndEndDate, setStartAndEndDate] = useState<string[]>([]);
    const [chartFilter, setChartFilter] = useState<string>("yearly");
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [customValue, customOnChange] = useState("");
    const [gmwValuesFilter, setGmwValuesFilter] = useState({
        location: "",
        period: "yearly",
        inventoryId: "",
    });
    const [inventoryList, setInventoryList] = useState<{ [key: string]: any }[]>([]);
    const [debounceInventorySearch, setDebounceInventorySearch] = useState<string>("");
    const [inventorySearch, setInventorySearch] = useState<string>("");
    const [inventory, setInventory] = useState<{ [key: string]: any }>({});
    const {
        fetchingGlobalProductListDropdown,
        fetchedGlobalProductListDropdownSuccess,
        fetchedGlobalProductListDropdownFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (fetchedGlobalProductListDropdownSuccess) {
            setInventoryList(fetchedGlobalProductListDropdownSuccess?.product?.variants);
        }
    }, [fetchedGlobalProductListDropdownSuccess]);

    useEffect(() => {
        if (productId) {
            dispatch(
                inventoryActions.fetchGlobalProductListDropdown(
                    productId,
                    50,
                    1,
                    debounceInventorySearch
                )
            );
        }
    }, [productId, debounceInventorySearch]);

    const {
        data,
        isFetching: fetchingSales,
        handleDateRangeChange,
        handleStartAndEndDateChange,
        handleWarehouseChange,
        handleInventoryIdChange,
    } = useGetGlobalProductSales();

    const {
        warehouseValue: warehouseDataValue,
        searchTwo,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchResult,
        handleFilterChange: filterChange,
        handleClearFilter: clearFilter,
    } = useGetAllWarehouses();

    // const { data } = useGetSingleProductGMWValues(
    //     gmwValuesFilter?.period,
    //     warehouseDataValue?.value
    // );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    const dateValues = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },

        {
            period: "custom",
            value: "custom",
        },
    ];

    const multiBarChartOption = {
        legend: { show: false },
        tooltip: {
            formatter: function (params) {
                return `
                <div>
                    <p style="margin-bottom: 10px">${params?.seriesName}</p>
                    <div style="display:flex ; align-items: center; gap: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.value?.[0]}</span>
                        </div> 

                        <span style="font-weight: 600;">${
                            params?.value?.[1] || params?.value?.[2]
                        }</span>
                    </div>
                </div>
                `;
            },
        },
        color: ["#6562E5", "#56B648"],
        dataset: {
            source: [
                ["product", "Sales demand", "Sales forecasted"],
                ...getSalesMultiBarChartData(data, gmwValuesFilter?.period, customValue),
            ],
        },
        xAxis: { type: "category" },
        yAxis: {
            axisLabel: {
                formatter: "{value}",
            },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            { type: "bar", barWidth: "13%" },
            { type: "bar", barWidth: "13%" },
        ],
    };

    const tableHeader = [
        {
            title: "Product name",
            widthClass: "w-[300px]",
        },
        { title: "SKU No.", widthClass: "w-[200px]" },
        { title: "Sales channels", widthClass: "w-[200px]" },
        { title: "Categories", widthClass: "w-[180px]" },
        { title: "Current stock level", widthClass: "w-[180px]" },
        { title: "Forecasted needs", widthClass: "w-[180px]" },
        { title: "On order", widthClass: "w-[150px]" },
        { title: "Ongoing sales", widthClass: "w-[180px]" },
        { title: "Reorder date", widthClass: "w-[180px]" },
        { title: "Action", widthClass: "w-[150px]" },
    ];

    const tableBody = productList?.map((product, index) => {
        const givenDate = moment(product?.reorderDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }
        console.log(product?.forecastedNeeds);
        return [
            {
                content: (
                    <div
                        className={`py-3 px-2 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`product-column-${index}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#product-column-${index}`}
                                        place={"right"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`, {
                        state: { internalNavigation: true },
                    });
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        <div>
                            <a id={`sku-column-${index}`}>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(product?.sku, { length: 14 })}
                                </span>
                            </a>
                            <Tooltip
                                anchorSelect={`#sku-column-${index}`}
                                place={"top"}
                                content={product?.sku}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            {displayProductChannelIcon(product?.salesChannels)}
                            <span>
                                {truncate(
                                    product?.salesChannels === "quickbooks"
                                        ? "QB"
                                        : product?.salesChannels,
                                    { length: 10 }
                                )}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.category || "N/A"}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {!product?.stockLevel
                                ? `${formatMoney()?.format(product?.stockLevel)} ${
                                      product?.unitOfMeasurement || ""
                                  }`
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="">
                            {product?.forecastedNeeds ? (
                                <p className="flex items-center gap-2">
                                    <span className="material-symbols-outlined text-[14px] text-r-50">
                                        warning
                                    </span>
                                    {product?.forecastedNeeds
                                        ? formatMoney()?.format(product?.forecastedNeeds)
                                        : "0"}
                                    {product?.unitOfMeasurement || ""}
                                </p>
                            ) : (
                                "N/A"
                            )}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.onOrder !== null
                                ? `${formatMoney()?.format(product?.onOrder)} ${
                                      product?.unitOfMeasurement || ""
                                  }`
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.ongoingSales !== null
                                ? `${formatMoney()?.format(product?.ongoingSales)} ${
                                      product?.unitOfMeasurement || ""
                                  }`
                                : "N/A"}
                        </span>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        {product?.reorderDate && differenceInDays >= 0 && (
                            <span
                                className={`block ${
                                    differenceInDays > 10
                                        ? "text-carribean-green"
                                        : "text-[#E4281D]"
                                }`}
                            >
                                {differenceInDays === 0
                                    ? "Today"
                                    : formatMoney().format(differenceInDays) + " days"}
                            </span>
                        )}
                        <span>
                            {product?.reorderDate
                                ? moment(product?.reorderDate)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {product?.inventoryIncident?.incidentType === "stockout" ||
                        !product?.inventoryIncident?.incidentType ? (
                            <Button
                                btnText="Restock"
                                btnPermission={["all_access", "create_purchase_order"]}
                                btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                                onClick={() => {
                                    navigate(
                                        `/dashboard/purchase/create-purchase-request?inventory=${product?._id}`,
                                        { state: { internalNavigation: true } }
                                    );
                                }}
                            />
                        ) : product?.inventoryIncident &&
                          product?.inventoryIncident?.incidentType?.toLowerCase() ===
                              "overstocking" ? (
                            <Button
                                btnText="Overstocked"
                                btnClassname="!px-[8px] !py-2 !w-fit !h-[35px] !text-[13px] !text-white !whitespace-nowrap !bg-[#FF0000]"
                                icon={
                                    <i className="ri-alarm-warning-line text-[16px] text-white"></i>
                                }
                            />
                        ) : (
                            ""
                        )}
                    </div>
                ),
                widthClass: "!border-b",
            },
        ];
    });

    const handleDateChange = (e) => {
        const { value } = e.target;
        const [val, period] = value.split("-");

        setDurationFilter(period);
        if (val !== "custom") {
            handleDateRangeChange(val);
            setStartAndEndDate([]);
            setChartFilter(val);
            customOnChange("");
            setShowCustomDate(false);
        } else {
            setShowCustomDate(true);
        }
    };

    useEffect(() => {
        if (
            gmwValuesFilter?.period === "custom" &&
            customValue[0] &&
            customValue[1] &&
            customValue[0] !== customValue[1]
        ) {
            const dates = [
                moment(customValue?.[0]).format("MMM DD YYYY"),
                moment(customValue?.[1]).format("MMM DD YYYY"),
            ];

            setStartAndEndDate([dates?.[0], dates?.[1]]);
            if (customValue?.[0] !== customValue?.[1]) {
                handleStartAndEndDateChange(customValue);
                setShowCustomDate(false);
            }
        }
    }, [gmwValuesFilter?.period, customValue]);

    return (
        <div id="salesContainer" className="relative">
            {/* <Source /> */}

            <div className="py-5  bg-white rounded-[6px] border border-[#E2E8F080] mt-2 mb-8">
                <div className="flex items-center justify-between pb-5 px-6 border-b border-slate-200 mb-6">
                    <div className="flex items-center space-x-3">
                        <div>
                            <span className="block font-rocGroteskMedium text-sm text-g-75">
                                Total sales
                            </span>
                            <span className="block font-rocGroteskMedium text-xl text-p-50">
                                {formatMoney()?.format((data?.totalQty || 0)?.toFixed(2)) || 0}
                            </span>
                        </div>
                        <div>
                            <span className="block font-rocGroteskMedium text-sm text-g-75">
                                Total forecasted sales
                            </span>
                            <span className="block font-rocGroteskMedium text-xl text-[#56B648]">
                                {formatMoney()?.format((data?.totalForecastQty || 0)?.toFixed(2)) ||
                                    0}
                            </span>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-2">
                            <span className="w-[14px] h-[14px] rounded-[2px] bg-[#56B648]"></span>
                            <span className={`text-g-75 font-rocGroteskMedium text-sm`}>
                                Sales forecasted
                            </span>
                        </div>
                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-2">
                            <span className="w-[14px] h-[14px] rounded-[2px] bg-p-50"></span>
                            <span className={`text-g-75 font-rocGroteskMedium text-sm`}>
                                Sales demand
                            </span>
                        </div>
                        <div className="flex items-center rounded-[5px] border border-slate-200 ">
                            <div className="border-r border-slate-200">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("first --->>>", name, value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center  whitespace-nowrap h-[40px] py-2 px-4 space-x-1 ">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {warehouseDataValue?.name || "Warehouses"}
                                            </span>
                                            {warehouseDataValue?.name && (
                                                <i
                                                    onClick={() => {
                                                        clearFilter();
                                                        handleWarehouseChange("");
                                                    }}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    search={
                                        <div>
                                            <TextInput
                                                value={searchTwo}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={handleSearchTwo}
                                                onInput={handleDebouncedChangeTwo}
                                                inputPlaceholder="Search warehouse"
                                                containerClassname=""
                                                // inputContainerClassname={"!border-none !rounded-none "}
                                                // inputClassName="w-[50%]"
                                            />
                                        </div>
                                    }
                                    dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100  rounded `}
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div>
                                                    <div className="max-h-[280px]">
                                                        {searchResult?.map((warehouse) => {
                                                            return (
                                                                <div
                                                                    key={warehouse?._id}
                                                                    onClick={() => {
                                                                        filterChange(
                                                                            warehouse?.warehouseName,
                                                                            warehouse?._id
                                                                        );
                                                                        handleWarehouseChange(
                                                                            warehouse?._id
                                                                        );
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                            warehouseDataValue?.value ===
                                                                                warehouse?._id &&
                                                                            "bg-slate-100"
                                                                        }`}
                                                                    >
                                                                        <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                        <div>
                                                                            <span
                                                                                className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                    warehouse
                                                                                        ?.address
                                                                                        ?.address &&
                                                                                    "mb-[2px]"
                                                                                }`}
                                                                            >
                                                                                {truncate(
                                                                                    warehouse?.warehouseName,
                                                                                    { length: 40 }
                                                                                )}
                                                                            </span>
                                                                            <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                                {truncate(
                                                                                    warehouse
                                                                                        ?.address
                                                                                        ?.address,
                                                                                    { length: 40 }
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>

                            <div className="border-r border-slate-200">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("first --->>>", name, value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center  whitespace-nowrap h-[40px] py-2 px-4 space-x-1 ">
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {inventory?.name || "All Products"}
                                            </span>
                                            {inventory?.name && (
                                                <i
                                                    onClick={() => {
                                                        setInventory({});
                                                        handleInventoryIdChange("");
                                                    }}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    search={
                                        <div className="top-0">
                                            <TextInput
                                                value={inventorySearch}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={(e) =>
                                                    setInventorySearch(e?.target?.value)
                                                }
                                                onInput={debounce((e) => {
                                                    setDebounceInventorySearch(e?.target?.value);
                                                }, 1000)}
                                                inputPlaceholder="Search product"
                                                containerClassname=""
                                            />
                                        </div>
                                    }
                                    dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100  rounded `}
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div>
                                                    <div className="max-h-[280px]">
                                                        {inventoryList?.map((inventory) => {
                                                            return (
                                                                <div
                                                                    key={inventory?._id}
                                                                    onClick={() => {
                                                                        setInventory({
                                                                            value: inventory?._id,
                                                                            name: inventory?.productName,
                                                                        });
                                                                        handleInventoryIdChange(
                                                                            inventory?._id
                                                                        );
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                            inventory?.value ===
                                                                                inventory?._id &&
                                                                            "bg-slate-100"
                                                                        }`}
                                                                    >
                                                                        {inventory
                                                                            ?.productImageDetails
                                                                            ?.productImages?.[0] ? (
                                                                            <VendorAvatar
                                                                                imageSrc={
                                                                                    inventory
                                                                                        ?.productImageDetails
                                                                                        ?.productAvatar ||
                                                                                    inventory
                                                                                        ?.productImageDetails
                                                                                        ?.productImages?.[0]
                                                                                }
                                                                                size={32}
                                                                                name={
                                                                                    inventory?.productName
                                                                                }
                                                                                containerClassname="!rounded-md"
                                                                            />
                                                                        ) : (
                                                                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                                            </div>
                                                                        )}
                                                                        <div>
                                                                            <span
                                                                                className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                    inventory?.productName &&
                                                                                    "mb-[2px]"
                                                                                }`}
                                                                            >
                                                                                {truncate(
                                                                                    inventory?.productName,
                                                                                    { length: 40 }
                                                                                )}
                                                                            </span>
                                                                            <span className="block text-slate-500 text-sm font-rocGroteskMedium ">
                                                                                {inventory?.sku}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    handleChange={(name, value) => {
                                        handleDateChange({ target: { name, value } });
                                        setGmwValuesFilter((prev) => {
                                            return {
                                                ...prev,
                                                period: value?.split("-")[0],
                                            };
                                        });
                                    }}
                                    dropdown={
                                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-1 bg-slate-50">
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                            >
                                                {startAndEndDate?.length !== 0
                                                    ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                                    : durationFilter || "This year"}
                                            </span>
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    dropdownOptions={[
                                        ...dateValues?.map((item, index) => ({
                                            label: (
                                                <div
                                                    key={index}
                                                    className={`${
                                                        item.period === "custom" && "capitalize"
                                                    } text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                     !px-0`}
                                                >
                                                    {item?.period}
                                                </div>
                                            ),
                                            value: item?.value + "-" + item?.period,
                                        })),
                                    ]}
                                />
                            </div>
                            <div className="relative">
                                {showCustomDate && (
                                    <div
                                        ref={customRef}
                                        className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                    >
                                        <CustomCalendar
                                            showDoubleView={true}
                                            selectRange={true}
                                            value={customValue}
                                            onChange={customOnChange}
                                        />
                                        <div className="flex justify-end">
                                            <Button
                                                btnText={"Close"}
                                                btnClassname={"!w-fit !py-2"}
                                                onClick={() => setShowCustomDate(false)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {!fetchingSales &&
                !Boolean(data?.totalSale) &&
                !Boolean(data?.forecastTotalSale) ? (
                    <div className="w-[100%] h-[400px] flex items-center justify-center">
                        <div className="flex items-center  gap-[3px] flex-col w-[353px] justify-center">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/icon_w8c4mn.png"
                                alt=""
                                className=""
                            />
                            <p className="text-g-75 font-rocGroteskBold text-base ">
                                {" "}
                                No sales data available{" "}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="px-6">
                        {fetchingSales ? (
                            <div className="h-[300px] flex items-center justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        ) : (
                            <ReactECharts option={multiBarChartOption} />
                        )}
                    </div>
                )}
            </div>

            <div className="pt-2">
                <div>
                    {productList?.length > 0 ? (
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isCellBordered={true}
                                stickyHeader={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                                isScrollable={true}
                                rightFixedColumnCount={1}
                                leftFixedColumnCount={0}
                            />

                            {isFetching && isLoadingMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {pagination?.currentPage === pagination?.noOfPages && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className="flex justify-center items-center h-[300px]">
                                <span className="text-g-75 font-rocGroteskMedium text-lg">
                                    No product variant
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductsVariant;
