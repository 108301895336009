import React, { useEffect, useState } from "react";
import { truncate } from "lodash";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import UsMap from "components/common/UsMap";
import { getFullState, getLatAndLng } from "helpers/getLatLng";
import useGetTrackTraceBatchDistributors from "hooks/trackTraceHook/useGetTrackTraceBatchDistributors";
import useGetTrackTraceBatchTracking from "hooks/trackTraceHook/useGetTrackTraceBatchTracking";
import useGetTrackTraceBatchWarehouses from "hooks/trackTraceHook/useGetTrackTraceBatchWarehouses";
import useGetTrackTraceBatchCustomers from "hooks/trackTraceHook/useGetTrackTraceCustomers";
import { Map } from "components/common/Map/Map";
import Loader from "components/common/Loader";
import CustomMobileTable from "components/common/CustomMobileTable";

const SupplyChain = () => {
    const {
        data: batchWarehouses,
        isFetching: isFetchingWarehouses,
        handleStateChange: handleStateChangeWarehouse,
        isLoadingMore: warehouseLoadMore,
    } = useGetTrackTraceBatchWarehouses();

    const {
        data: batchCustomers,
        isFetching: isFetchingBatchCustomers,
        handleStateChange: handleStateChangeCustomers,
        isLoadingMore: customerLoadMore,
    } = useGetTrackTraceBatchCustomers();
    const {
        data: batchDistributors,
        isFetching: isFetchingBatchDistributors,
        handleStateChange: handleStateChangeDistributors,
        isLoadingMore: distributorLoadMore,
    } = useGetTrackTraceBatchDistributors();
    const { data: batchTracking, handleDateChange: handleBatchTrackingDateChange } =
        useGetTrackTraceBatchTracking();

    const [location, setLocation] = useState<any>("");
    const [fullState, setFullState] = useState<string>("");
    const [value, setValue] = useState<number>();

    const [date, setDate] = useState<string>("");
    const [searchModal, setSearchModal] = useState<boolean>(false);
    const [loadingMap, setLoadingMap] = useState<boolean>(true);
    const [modalPosition, setModalPosition] = useState<{ [key: string]: any }>({ x: 0, y: 0 });
    const [warehouseUSMap, setWarehouseUSMap] = useState<boolean>(false);
    const [distributorUSMap, setDistributorUSMap] = useState<boolean>(false);
    const [customerUSMap, setCustomerUSMap] = useState<boolean>(false);
    const [warehouseMapData, setWarehouseMapData] = useState<{ [key: string]: any }>([]);
    const [customerMapData, setCustomerMapData] = useState<{ [key: string]: any }>();
    const [distributorMapData, setDistributorMapData] = useState<{ [key: string]: any }>([]);
    const [markerPosition, setMarkerPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const [tabId, setTabId] = useState<number>(0);
    const tabNames = [
        {
            id: 0,
            name: `Warehouses  ${
                batchTracking?.warehouseData?.length > 0
                    ? batchTracking?.warehouseData?.length?.toLocaleString()
                    : ""
            }`,
        },
        {
            id: 1,
            name: `Distributors   ${
                batchTracking?.distrubutor?.length > 0
                    ? batchTracking?.distrubutor?.length?.toLocaleString()
                    : ""
            }`,
        },
        {
            id: 2,
            name: `Customers  ${
                batchTracking?.customer?.length > 0
                    ? batchTracking?.customer?.length?.toLocaleString()
                    : ""
            }`,
        },
    ];

    const values = [
        {
            label: "This year",
            value: "yearly",
        },
        {
            label: "This month",
            value: "monthly",
        },
        {
            label: "This week",
            value: "weekly",
        },

        {
            label: "Today",
            value: "daily",
        },
        {
            label: "All products",
            value: "all",
        },
    ];

    useEffect(() => {
        if (location) {
            switch (tabId) {
                case 0:
                    handleStateChangeWarehouse(location);
                    break;
                case 1:
                    handleStateChangeDistributors(location);
                    break;
                case 2:
                    handleStateChangeCustomers(location);
                    break;
                default:
                    break;
            }
        }
    }, [tabId, location]);

    useEffect(() => {
        setSearchModal(false);
        setLocation("");
        setFullState("");
    }, [tabId]);

    const getTotal = (dataArray) => {
        let totalWarehouses = 0;
        dataArray?.forEach((data) => {
            const stock = parseFloat(data?.totalStock);
            if (!isNaN(stock)) {
                totalWarehouses += stock;
            }
        });
        return totalWarehouses;
    };

    const getUSMapData = async (dataArray, name?: string) => {
        let res = [];
        dataArray?.map((data) => {
            name === "warehouse"
                ? res?.push({
                      country: data?.warehouseLocation?.country,
                      value: +data?.totalStock,
                      stateCode: data?.warehouseLocation?.state,
                  })
                : res?.push({
                      stateCode: data?._id?.state,
                      country: data?._id?.country,
                      value: +data?.totalStock,
                  });
        });

        let formattedState = await getFullState(res);
        formattedState && setWarehouseUSMap(true);
        return formattedState;
    };

    const getNonUSMapData = async (dataArray, name?: string) => {
        let res = [];
        dataArray?.map((data) => {
            name === "warehouse"
                ? res?.push({
                      country: data?.warehouseLocation?.country,
                      state: data?.warehouseLocation?.state,
                      value: +data?.totalStock,
                  })
                : res?.push({
                      country: data?._id?.country,
                      state: data?._id?.state,
                      value: +data?.totalStock,
                  });
        });
        let latAndLng = await getLatAndLng(res);
        // latAndLng && setLoadingMap(false);
        return latAndLng;
    };

    const checkWarehouseCountry = async (dataArray, name) => {
        if (dataArray?.length === 0) {
            return [];
        } else {
            const isWarehouse = name === "warehouse";
            const check = dataArray?.every(
                (data) =>
                    (isWarehouse ? data.warehouseLocation?.country : data._id?.country) ===
                    "United States"
            );

            if (isWarehouse) {
                // setWarehouseUSMap(check);
                return check
                    ? getUSMapData(dataArray, "warehouse")
                    : await getNonUSMapData(dataArray, "warehouse");
            } else {
                const setter = name === "distributor" ? setDistributorUSMap : setCustomerUSMap;
                setter(check);
                return check ? getUSMapData(dataArray) : await getNonUSMapData(dataArray);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const warehouseData = await checkWarehouseCountry(
                batchTracking?.warehouseData,
                "warehouse"
            );
            const customerData = await checkWarehouseCountry(batchTracking?.customer, "customer");
            const distributorData = await checkWarehouseCountry(
                batchTracking?.distrubutor,
                "distributor"
            );
            setWarehouseMapData(warehouseData);
            setCustomerMapData(customerData);
            setDistributorMapData(distributorData);
            warehouseData && customerData && distributorData && setLoadingMap(false);
        };

        if (batchTracking?.customer && batchTracking?.warehouseData && batchTracking?.distrubutor) {
            fetchData();
        }
    }, [batchTracking]);

    const onMapMarkerMovement = (e, markerData) => {
        setMarkerPosition({ x: e.clientX, y: e.clientY });
        if (markerData?.value) {
            setLocation(markerData?.name);
            setValue(markerData?.value);
            setSearchModal(true);
        }
    };

    const warehouseTableHeader = [
        { title: "Distributor Name", widthClass: "w-[10%]" },
        { title: "Address", widthClass: "w-[12%]" },
        { title: "Inventory Level", widthClass: "w-[6%]" },
        { title: "% of units/total product inventory", widthClass: "w-[6%]" },
        { title: "Average days to expiry", widthClass: "w-[6%]" },
    ];

    const warehouseTableBody = batchWarehouses?.map((warehouse, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{warehouse?._id}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(warehouse?.warehouse?.address?.address, { length: 40 })}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{warehouse?.totalStock?.toLocaleString()}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">
                        {" "}
                        {warehouse?.iventoryPercentage
                            ? `${warehouse?.iventoryPercentage}%`
                            : "N/A"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">
                        {warehouse?.totalDaysToExpiry
                            ? Math.round(warehouse?.totalDaysToExpiry)
                            : "N/A"}
                    </p>
                </div>
            ),
        },
    ]);

    const mobileWarehouseTableBody = batchWarehouses?.map((warehouse, idx) => {
        return {
            topString: warehouse?._id,
            topContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <span>{truncate(warehouse?._id, { length: 25 })}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">
                        {warehouse?.totalStock?.toLocaleString()}
                    </span>
                </div>
            ),
            bottomContent: (
                <div>
                    <div className="flex items-center text-slate-400 text-xs font-rocGroteskMedium space-x-3 mb-1">
                        <span>
                            {truncate(warehouse?.warehouse?.address?.address, { length: 25 })}
                        </span>
                        <span className="text-slate-500">|</span>
                        <span className="text-slate-500">
                            {warehouse?.totalStock?.toLocaleString()}
                        </span>
                        <span className="text-slate-500">|</span>
                        <span className="text-slate-500">
                            {warehouse?.iventoryPercentage
                                ? `${warehouse?.iventoryPercentage}%`
                                : "N/A"}
                        </span>
                    </div>
                    <p className="text-xs text-slate-500 font-rocGroteskMedium">
                        Avg days to expiry -{" "}
                        {warehouse?.totalDaysToExpiry
                            ? Math.round(warehouse?.totalDaysToExpiry)
                            : "N/A"}
                    </p>
                </div>
            ),
            // rightIcon: (
            //     <div
            //         className={` flex justify-end space-x-2.5  items-center h-full border-slate-100 text-sm text-slate-800 `}
            //         onClick={(e) => e.stopPropagation()}
            //     >
            //         <p className="text-xs font-rocGroteskMedium">
            //             Expiry -{" "}
            //             {warehouse?.totalDaysToExpiry
            //                 ? Math.round(warehouse?.totalDaysToExpiry)
            //                 : "N/A"}
            //         </p>
            //     </div>
            // ),
        };
    });

    const distributorTableHeader = [
        { title: "Distributor Name", widthClass: "w-[10%]" },
        { title: "Address", widthClass: "w-[12%]" },
        { title: "Inventory Level", widthClass: "w-[6%]" },
        { title: "% of units/total product inventory", widthClass: "w-[6%]" },
        { title: "Average days to expiry", widthClass: "w-[6%]" },
    ];

    const distributorTableBody = batchDistributors?.map((sales, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{`${sales?.company}`}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(sales?.address, { length: 40 })}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{sales?.totalStock?.toLocaleString()}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">
                        {sales?.iventoryPercentage ? `${sales?.iventoryPercentage}%` : "N/A"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">
                        {sales?.totalDaysToExpiry ? Math.round(sales?.totalDaysToExpiry) : "N/A"}
                    </p>
                </div>
            ),
        },
    ]);

    const mobileDistributorTableBody = batchDistributors?.map((sale, idx) => {
        return {
            topString: sale?._id,
            topContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <span>{truncate(sale?.company, { length: 25 })}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">{sale?.totalStock?.toLocaleString()}</span>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center text-slate-400 text-xs font-rocGroteskMedium space-x-3">
                    <span>{truncate(sale?.address, { length: 25 })}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">{sale?.totalStock?.toLocaleString()}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">
                        {sale?.iventoryPercentage ? `${sale?.iventoryPercentage}%` : "N/A"}
                    </span>
                </div>
            ),
            rightIcon: (
                <div
                    className={` flex justify-end space-x-2.5  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <p className="text-sm font-rocGroteskMedium">
                        Expiry -{" "}
                        {sale?.totalDaysToExpiry ? Math.round(sale?.totalDaysToExpiry) : "N/A"}
                    </p>
                </div>
            ),
        };
    });

    const customerTableHeader = [
        { title: "Sales Channel ", widthClass: "w-[200px]" },
        { title: "No. of Customers", widthClass: "w-[200px]" },
    ];

    const customerTableBody = batchCustomers?.map((customer, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{customer?._id}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <p className="">{customer?.totalUniqueCustomers}</p>
                </div>
            ),
        },
    ]);

    const mobileCustomerTableBody = batchCustomers?.map((customer, idx) => {
        return {
            topString: customer?._id,
            topContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <span>{truncate(customer?.company, { length: 25 })}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">{customer?.totalUniqueCustomers}</span>
                </div>
            ),
        };
    });

    return (
        <div className="w-full">
            <div className="">
                <div className=" font-rocGroteskMedium px-3 py-4 !my-0 !w-full border border-slate-200 rounded-t-lg">
                    <div className="flex max-sm:flex-col max-sm:space-y-2 max-sm:px-0 max-sm:pt-0 max-sm:pb-2 max-sm:items-start justify-between items-center border-b border-slate-300 pb-1 px-1">
                        <div className="bg-slate-50 !w-fit max-sm:!w-full max-sm:gap-1 p-1 flex gap-2 font-rocGroteskMedium font-sm text-slate-900 mt-1">
                            {tabNames?.map((tab, idx) => {
                                return (
                                    <button
                                        className={`w-auto  py-[7px] px-4 max-sm:px-2 text-sm ${
                                            tabId === idx &&
                                            "!bg-white rounded-[6px] shadow-buttonShadow"
                                        }`}
                                        key={idx}
                                        onClick={() => setTabId(idx)}
                                    >
                                        {tab?.name}
                                    </button>
                                );
                            })}
                        </div>
                        <div className="flex gap-1">
                            <div className="relative">
                                <Dropdown
                                    handleChange={(name, values) => {
                                        setDate(values?.split("-")[1]);
                                        const value = values?.split("-")[0];
                                        handleBatchTrackingDateChange({ target: { name, value } });
                                    }}
                                    dropdown={
                                        <div className="flex items-center rounded-[6px] shadow-buttonShadow2 p-[5px] gap-2 !border !border-slate-200">
                                            <div
                                                className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                            >
                                                {date || "All products"}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                        </div>
                                    }
                                    // disabled={}
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded-[6px] !max-h-fit !w-auto
                            `}
                                    name="valueRange"
                                    dropdownOptions={[
                                        ...values?.map((item, idx) => ({
                                            label: (
                                                <div
                                                    key={idx}
                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                >
                                                    {item?.label}
                                                </div>
                                            ),
                                            value: item?.value + "-" + item?.label,
                                        })),
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="relative">
                        {tabId === 0 && (
                            <div
                                className={`${
                                    batchTracking?.warehouseData?.length >= 1 && "bg-lb-25"
                                } rounded-md mt-[22px] relative`}
                            >
                                {batchTracking?.warehouseData?.length >= 1 && (
                                    <div className="left-16 top-[12px] bg-white rounded-md text-slate-700 font-rocGroteskMedium py-4 px-10 absolute w-[257px] z-[9999]">
                                        <p className="text-2xl text-center mb-2">
                                            {getTotal(
                                                batchTracking?.warehouseData
                                            )?.toLocaleString()}
                                        </p>

                                        <p className="text-sm text-center">Total stored items</p>
                                    </div>
                                )}
                                {loadingMap ? (
                                    <div className="h-[300px] flex items-center">
                                        <Loader size={8} />
                                    </div>
                                ) : warehouseUSMap ? (
                                    <div className="pt-[50px]">
                                        <UsMap
                                            setLocation={setLocation}
                                            setModalPosition={setModalPosition}
                                            setSearchModal={setSearchModal}
                                            locationSearch={location}
                                            setFullState={setFullState}
                                            mapData={warehouseMapData}
                                            setValue={setValue}
                                        />
                                    </div>
                                ) : !warehouseUSMap ? (
                                    <div className="h-[450px] w-full border mt-2 rounded-[8px]">
                                        <Map
                                            mapLoading={loadingMap}
                                            lat={warehouseMapData?.[0]?.lat}
                                            long={warehouseMapData?.[0]?.lng}
                                            locationData={warehouseMapData?.map((data) => ({
                                                lat: +data?.lat,
                                                lng: +data?.lng,
                                                name: data?.name,
                                                value: data?.value,
                                            }))}
                                            onMapMarkerMovement={onMapMarkerMovement}
                                            showPin={true}
                                            setModalPosition={setModalPosition}
                                            markerPosition={markerPosition}
                                            setSearchModal={setSearchModal}
                                        />
                                    </div>
                                ) : (
                                    !loadingMap &&
                                    warehouseMapData?.length === 0 && (
                                        <div className="flex items-center justify-center flex-col h-[350px]">
                                            <img
                                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Group_axtkpb.svg"
                                                alt=""
                                                className=" mb-4"
                                            />
                                            <p className="">No data display</p>
                                            <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center">
                                                It looks like there's no map available to display at
                                                the moment
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {tabId === 1 && (
                            <div
                                className={`${
                                    distributorMapData?.length >= 1 && "bg-lb-25"
                                } rounded-md mt-[22px] relative`}
                            >
                                {distributorMapData?.length >= 1 && (
                                    <div className="left-16 top-[12px] bg-white rounded-md text-slate-700 font-rocGroteskMedium py-4 px-10 absolute w-[257px] z-[9999]">
                                        <p className="text-2xl text-center mb-2">
                                            {getTotal(batchTracking?.distrubutor)?.toLocaleString()}
                                        </p>
                                        <p className="text-sm text-center">
                                            Total distributed items
                                        </p>
                                    </div>
                                )}
                                {!loadingMap && distributorMapData?.length === 0 ? (
                                    <div className="flex items-center justify-center flex-col h-[300px]">
                                        <img
                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/Group_axtkpb.svg"
                                            alt=""
                                            className=" mb-4"
                                        />
                                        <p className="">No data display</p>
                                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center">
                                            It looks like there's no map available to display at the
                                            moment
                                        </p>
                                    </div>
                                ) : distributorUSMap ? (
                                    <div className="pt-[50px]">
                                        <UsMap
                                            setLocation={setLocation}
                                            setModalPosition={setModalPosition}
                                            setSearchModal={setSearchModal}
                                            locationSearch={location}
                                            setFullState={setFullState}
                                            mapData={distributorMapData}
                                            setValue={setValue}
                                        />
                                    </div>
                                ) : (
                                    !distributorUSMap && (
                                        <>
                                            {loadingMap ? (
                                                <div className="">
                                                    <Loader size={8} />
                                                </div>
                                            ) : (
                                                <div className="h-[450px] w-full border mt-2 rounded-[8px]">
                                                    <Map
                                                        lat={distributorMapData?.[0]?.lat}
                                                        long={distributorMapData?.[0]?.lng}
                                                        locationData={distributorMapData?.map(
                                                            (data) => ({
                                                                lat: +data?.lat,
                                                                lng: +data?.lng,
                                                                name: data?.name,
                                                                value: data?.value,
                                                            })
                                                        )}
                                                        onMapMarkerMovement={onMapMarkerMovement}
                                                        showPin={true}
                                                        setModalPosition={setModalPosition}
                                                        markerPosition={markerPosition}
                                                        setSearchModal={setSearchModal}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )
                                )}
                            </div>
                        )}

                        {tabId === 2 && (
                            <div
                                className={`${
                                    customerMapData?.length >= 1 && "bg-lb-25"
                                } rounded-md mt-[22px] relative`}
                            >
                                {customerMapData?.length >= 1 && (
                                    <div className="left-16 top-[12px] bg-white rounded-md text-slate-700 font-rocGroteskMedium py-4 px-10 absolute w-[257px] z-[9999]">
                                        <p className="text-2xl text-center mb-2">
                                            {batchTracking?.consumerCount}
                                        </p>
                                        <p className="text-sm text-center">Total customers</p>
                                    </div>
                                )}
                                {customerMapData && customerMapData?.length === 0 ? (
                                    <div className="flex items-center justify-center flex-col h-[300px]">
                                        <img
                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/Group_axtkpb.svg"
                                            alt=""
                                            className=" mb-4"
                                        />
                                        <p className="">No data display</p>
                                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center">
                                            It looks like there's no map available to display at the
                                            moment
                                        </p>
                                    </div>
                                ) : customerUSMap ? (
                                    <div className="pt-[50px] ">
                                        <UsMap
                                            setLocation={setLocation}
                                            setModalPosition={setModalPosition}
                                            setSearchModal={setSearchModal}
                                            locationSearch={location}
                                            setFullState={setFullState}
                                            mapData={customerMapData}
                                            setValue={setValue}
                                        />
                                    </div>
                                ) : (
                                    !customerUSMap && (
                                        <>
                                            {loadingMap ? (
                                                <div className="">
                                                    <Loader size={8} />
                                                </div>
                                            ) : (
                                                <div className="h-[450px] w-full border mt-2 rounded-[8px]">
                                                    <Map
                                                        lat={customerMapData?.[0]?.lat}
                                                        long={customerMapData?.[0]?.lng}
                                                        locationData={customerMapData?.map(
                                                            (data) => ({
                                                                lat: +data?.lat,
                                                                lng: +data?.lng,
                                                                name: data?.name,
                                                                value: data?.value,
                                                            })
                                                        )}
                                                        onMapMarkerMovement={onMapMarkerMovement}
                                                        showPin={true}
                                                        setModalPosition={setModalPosition}
                                                        markerPosition={markerPosition}
                                                        setSearchModal={setSearchModal}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )
                                )}
                            </div>
                        )}

                        {searchModal ? (
                            // <div className={` overflow-y-scroll absolute top-0 left-0 !z-[9999] `}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: `${modalPosition?.y - 400}px`,
                                    left: `${modalPosition?.x - 80}px`,
                                }}
                                className={` ${
                                    tabId === 2 ? "w-[400px] " : "w-[700px]"
                                } !z-[9999] mb-8 max-lg:w-[300px]`}
                                onClick={(evt) => evt.stopPropagation()}
                            >
                                <div
                                    id="modalContent"
                                    className={` bg-white rounded-[20px] shadow-boxShadow-3  max-h-[300px] overflow-y-scroll mb-[50px]`}
                                >
                                    <div className="mx-5 mb-5 relative">
                                        <div className="sticky top-0 flex justify-between bg-white py-5 z-[9999]">
                                            <div className="flex  gap-[6px] items-center ">
                                                {tabId !== 1 ? (
                                                    <div className="p-1 bg-[rgba(252,195,78,0.15)] rounded-full w-[30px] h-[30px] text-center">
                                                        <i className="ri-store-2-fill text-[#FCC34E] "></i>
                                                    </div>
                                                ) : (
                                                    <div className="p-1 bg-lb-25 rounded-full w-[30px] h-[30px] text-center">
                                                        <img
                                                            src="https://storage.googleapis.com/heysynth_files/synth_webapp/Vector_3_bf28ec.svg"
                                                            alt=""
                                                            className="inline-block"
                                                        />
                                                    </div>
                                                )}
                                                <p className="text-slate-800 font-rocGroteskBold text-base mr-1">
                                                    {`${
                                                        tabId === 0
                                                            ? "Warehouses"
                                                            : tabId === 1
                                                            ? "Distributors"
                                                            : "Customers"
                                                    } in ${fullState || location}`}
                                                </p>
                                            </div>
                                            <i
                                                onClick={() => setSearchModal(false)}
                                                className="ri-close-fill cursor-pointer text-slate-800 text-2xl "
                                            ></i>
                                        </div>
                                        {tabId !== 2 && (
                                            <div className="">
                                                <p className="text-xs font-rocGroteskMedium text-slate-500 mb-1.5">
                                                    Total units in this location{" "}
                                                </p>

                                                <p className="text-base font-rocGroteskMedium text-slate-900 mb-2">
                                                    {value?.toLocaleString()}
                                                </p>
                                            </div>
                                        )}

                                        {(isFetchingBatchCustomers ||
                                            isFetchingBatchDistributors ||
                                            isFetchingWarehouses) &&
                                        (!customerLoadMore ||
                                            !warehouseLoadMore ||
                                            !distributorLoadMore) ? (
                                            <Loader size={8} />
                                        ) : (
                                            <>
                                                <div className="max-lg:hidden">
                                                    <CustomTable
                                                        tableHeader={
                                                            tabId === 2
                                                                ? customerTableHeader
                                                                : tabId === 1
                                                                ? distributorTableHeader
                                                                : warehouseTableHeader
                                                        }
                                                        tableBody={
                                                            tabId === 2
                                                                ? customerTableBody
                                                                : tabId === 1
                                                                ? distributorTableBody
                                                                : warehouseTableBody
                                                        }
                                                        isScrollable={false}
                                                        isCellBordered={true}
                                                        headerContainerClass="!bg-slate-50"
                                                        headerItemClass="font-rocGroteskMedium "
                                                        tableClass={`mb-6 ${
                                                            tabId === 2
                                                                ? "!w-[400px]"
                                                                : "!w-[650px]"
                                                        }`}
                                                    />
                                                </div>

                                                <div className="hidden max-lg:block">
                                                    <CustomMobileTable
                                                        data={
                                                            tabId === 2
                                                                ? mobileCustomerTableBody
                                                                : tabId === 1
                                                                ? mobileDistributorTableBody
                                                                : mobileWarehouseTableBody
                                                        }
                                                    />
                                                </div>

                                                {(customerLoadMore ||
                                                    warehouseLoadMore ||
                                                    distributorLoadMore) && (
                                                    <div className="flex justify-center my-4">
                                                        <Loader color="gm-25" size={4} />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : // </div>
                        null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplyChain;
