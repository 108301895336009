import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Checkbox from "components/common/InputField/Checkbox";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigationHandler } from "hooks/useNavigationHandler";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authActions, orderActions } from "redux/Ldb/actions";
import { AccountPermissions } from "types/permissions";

const OrderSettings = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {goBack} = useNavigationHandler({fallbackPath: "/dashboard/order"})
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [teamList, setTeamList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [settingsDetails, setSettingsDetails] = useState<any>([]);
    const [data, setData] = useState({
        poAdjustment: false,
        prConversionToPo: false,
        adjustmentAmount: "0",
        role: "",
        approvers: [],
    });
    const {
        fetchedPOSettingsSuccess,
        fetchingPOSettings,
        updatedPOSettingsSuccess,
        updatingPOSettings,
    } = useAppSelector((state) => state.order);

    const { fetchingRoles, fetchedRolesSuccess, fetchingTeamMember, fetchedTeamMemberSuccess } =
        useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(orderActions.getPOSettings());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getRoles(20, "", debouncedSearch.search));
    }, [dispatch, debouncedSearch.search]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers());
    }, [dispatch]);

    useEffect(() => {
        setData({
            poAdjustment: settingsDetails?.purchaseOrderAdjustment?.allowPoAdjustment,
            prConversionToPo: settingsDetails?.convertPurchaserequestToPo,
            adjustmentAmount: settingsDetails?.purchaseOrderAdjustment?.adjustmentLimit,
            role: settingsDetails?.userRoleAndPermission?.[0],
            approvers: settingsDetails?.approvers,
        });
    }, [settingsDetails]);

    useEffect(() => {
        if (Boolean(updatedPOSettingsSuccess)) {
            dispatch(orderActions.getPOSettings());
        }
    }, [updatedPOSettingsSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedPOSettingsSuccess)) {
            setSettingsDetails(fetchedPOSettingsSuccess?.setings);
        }
    }, [fetchedPOSettingsSuccess]);

    const handleAddLevel = () => {
        const newApprover = {
            fullName: "",
            role: "",
            avatar: "",
            _id: "",
        };

        setData((prevData) => ({
            ...prevData,
            approvers: [...prevData.approvers, newApprover],
        }));
    };

    const handleApproverSelection = (selectedApprover) => {
        const emptyApproverIndex = data.approvers.findIndex(
            (approver) =>
                approver.fullName === "" &&
                approver.role === "" &&
                approver.avatar === "" &&
                approver._id === ""
        );
        const existingApproverIndex = data.approvers.findIndex(
            (approver) => approver._id === selectedApprover._id
        );

        if (emptyApproverIndex !== -1 && existingApproverIndex === -1) {
            const updatedApprovers = [...data.approvers];
            updatedApprovers[emptyApproverIndex] = selectedApprover;

            setData((prevData) => ({
                ...prevData,
                approvers: updatedApprovers,
            }));
        }
    };

    const updateSettings = () => {
        const body = {
            convertPurchaserequestToPo: data?.prConversionToPo,
            purchaseOrderAdjustment: {
                allowPoAdjustment: data?.poAdjustment,
                adjustmentLimit: Number(data?.adjustmentAmount),
            },
            approversId: data?.approvers?.map((item) => item._id?.trim())?.filter(Boolean),
            userRoleAndPermission: [data?.role],
        };
        dispatch(orderActions.updatePOSettings(body));
    };

    return (
        <div className="bg-white border border-[#F1F5F9] rounded-[8px]  py-4">
            <div className="flex justify-between items-center border-b px-8 pb-4 ">
                <div
                    className="flex items-center w-fit cursor-pointer"
                    onClick={() => goBack()}
                >
                    <i className="ri-arrow-left-line text-gm-50"></i>
                    <p className="text-gm-50 underline text-sm font-rocGroteskMedium">Back</p>
                </div>
                <div className="flex space-x-3">
                    <Button
                        btnText="Cancel"
                        disabled={updatingPOSettings}
                        btnClassname="!bg-[#F4F5F7] !py-2 !w-fit "
                        onClick={() => goBack()}
                    />
                    <Button
                        btnText="Save changes"
                        btnPermission={[
                            AccountPermissions.all_access,
                            AccountPermissions.create_sales_order,
                            AccountPermissions.update_sales_order,
                        ]}
                        btnClassname="!w-fit !py-2 "
                        isLoading={updatingPOSettings}
                        onClick={updateSettings}
                    />
                </div>
            </div>
            <div className="mt-6 px-8 ">
                <div>
                    <h3 className="text-[18px] mb-3 leading-[28px] font-rocGroteskMedium text-gm-50">
                        Sales order & receipt management settings
                    </h3>
                    <h4 className="text-[18px] leading-[28px] font-rocGroteskMedium text-gm-50">
                        Notification
                    </h4>
                    <p className="text-slate-500 w-[47%] text-sm font-rocGroteskMedium">
                        Receive timely updates and important notifications via both email and in-app
                        alerts, ensuring you stay informed and connected with customizable
                        preferences for seamless communication
                    </p>
                    <div className="border-b pb-7 mt-7">
                        <div className="flex space-x-2  border-slate-200">
                            <div className="">
                                <Checkbox
                                    name=""
                                    isChecked={data?.prConversionToPo}
                                    onChange={() =>
                                        setData((prevData) => ({
                                            ...prevData,
                                            prConversionToPo: !data.prConversionToPo,
                                        }))
                                    }
                                />
                            </div>
                            <div className="-mt-[4px]">
                                <p className="text-sm w-[100%] font-rocGroteskMedium text-gm-50">
                                    Receive email notifications for order updates
                                </p>
                                <p className="text-slate-500 w-[100%] text-sm font-rocGroteskMedium">
                                    Get email and in-app notifications for order updates.
                                </p>
                            </div>
                        </div>
                        <div className="flex space-x-2 mt-7  border-slate-200">
                            <div className="">
                                <Checkbox
                                    name=""
                                    isChecked={data?.prConversionToPo}
                                    onChange={() =>
                                        setData((prevData) => ({
                                            ...prevData,
                                            prConversionToPo: !data.prConversionToPo,
                                        }))
                                    }
                                />
                            </div>
                            <div className="-mt-[4px]">
                                <p className="text-sm w-[100%] font-rocGroteskMedium text-gm-50">
                                    Receive email notifications for status changes
                                </p>
                                <p className="text-slate-500 w-[100%] text-sm font-rocGroteskMedium">
                                    Receive email notifications for status changes and updates,
                                    keeping you informed.
                                </p>
                            </div>
                        </div>
                        <div className="flex space-x-2 mt-7  border-slate-200">
                            <div className="">
                                <Checkbox
                                    name=""
                                    isChecked={data?.prConversionToPo}
                                    onChange={() =>
                                        setData((prevData) => ({
                                            ...prevData,
                                            prConversionToPo: !data.prConversionToPo,
                                        }))
                                    }
                                />
                            </div>
                            <div className="-mt-[4px]">
                                <p className="text-sm w-[100%] font-rocGroteskMedium text-gm-50">
                                    Receive In-app and email notifications for both order updates
                                    and status changes
                                </p>
                                <p className="text-slate-500 w-[100%] text-sm font-rocGroteskMedium">
                                    Receive both in-app and email notifications for order updates
                                    and status changes, ensuring you're always up-to-date
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <p className="text-[18px] leading-[28px] font-rocGroteskMedium text-gm-50">
                        Approvals - Sales order and receipt
                    </p>
                    <p className="text-[#64748B] w-[50%] mt-3  text-sm font-rocGroteskMedium">
                        Set up sales order approvals to ensure accuracy and compliance before
                        processing.
                    </p>
                    <div className="border-b pb-7 border-slate-200">
                        <div className="w-[70%] grid grid-cols-2 gap-4  mt-3">
                            {data?.approvers?.length > 0 && (
                                <>
                                    {data.approvers.map((approver, idx) => (
                                        <Dropdown
                                            key={idx}
                                            dropdown={
                                                <div className="font-rocGroteskMedium justify-between p-2.5 rounded border !w-full items-center -mt-2 flex space-x-1">
                                                    <div className="flex space-x-2 items-center">
                                                        <VendorAvatar
                                                            imageSrc={approver?.profile?.avatar}
                                                            size={24}
                                                            containerClassname=""
                                                            name={"Avatar"}
                                                        />

                                                        <p className="text-gm-50 text-sm  font-rocGroteskMedium">
                                                            {approver?.fullName} - {approver?.role}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center space-x-4">
                                                        <p className="text-[13px] leading-[20px] text-slate-500">
                                                            Lev.{idx + 1}
                                                        </p>
                                                        <i className="ri-arrow-down-s-line text-slate-500"></i>
                                                    </div>
                                                </div>
                                            }
                                            className="w-full bg-[green]"
                                            dropdownClassName={"!w-full !bg-[blue] !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-2 border rounded !max-h-fit  `}
                                            dropdownItemsClasses={``}
                                            name={"faq-dropdown"}
                                            dropdownOptions={teamList?.map((res, idx) => ({
                                                label: (
                                                    <div
                                                        className="font-rocGroteskMedium items-center -mt-2 flex space-x-1"
                                                        key={idx}
                                                    >
                                                        <VendorAvatar
                                                            imageSrc={res?.profile?.avatar}
                                                            size={24}
                                                            containerClassname="!rounded"
                                                            name={res?.fullName}
                                                        />

                                                        <div className="text-xs  font-rocGroteskMedium">
                                                            <p className="text-gm-50 ">
                                                                {res?.fullName}
                                                            </p>
                                                            <p className="text-slate-500 ">
                                                                {res?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ),
                                                value: res?._id,
                                                action: () => handleApproverSelection(res),
                                            }))}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        <div
                            className="mt-3 flex items-center cursor-pointer w-fit"
                            onClick={handleAddLevel}
                        >
                            <i className="ri-add-fill text-g-65"></i>
                            <p className="underline text-g-65 text-sm font-rocGroteskMedium">
                                Add more levels
                            </p>
                        </div>
                    </div>
                </div>

                <div className=" pb-7 mt-6">
                    <div>
                        <p className="text-[18px] leading-[28px] font-rocGroteskMedium text-gm-50">
                            Define user roles and permission
                        </p>
                        <div className="w-[50%] mt-3">
                            <SelectInput
                                name="role"
                                value={data?.role}
                                placeholder={"Select Role"}
                                handleChange={(name, value) =>
                                    setData((prevData) => ({
                                        ...prevData,
                                        role: value,
                                    }))
                                }
                                isRequired={true}
                                dropdownOptions={roles?.map((item) => ({
                                    label: item?.label,
                                    value: item?.role,
                                }))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSettings;
