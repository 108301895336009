export const determineRiskStatus = (risk: number) => {
    if (risk <= 4) {
        return {
            value: "Low",
            img: "https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2615019_1_oyxqr8.svg",
        };
    } else if (risk >= 5 && risk <= 7) {
        return {
            value: "Medium",
            img: "https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2615019_2_yz9dkz.svg",
        };
    } else {
        return {
            value: "High",
            img: "https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_2615019_3_rj8hoz.svg",
        };
    }
};
