import Button from "components/common/Button";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useGetSingleInventoryIncidents from "hooks/inventoryHooks/useGetSingleInventoryIncidents";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";
import AlertTabDetails from "components/views/Synth2.0/Inventory/Tabs/Alerts/AlertTabDetails";

import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import { oceanCarriers } from "variables/oceanCarriers";
import ModalContainer from "components/common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import { inventoryActions } from "redux/Ldb/actions";
import VendorAvatar from "components/common/VendorAvatar";
import { debounce } from "lodash";
import TextareaInput from "components/common/InputField/TextareaInput";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import useGetReplishmentStockList from "hooks/inventoryHooks/useGetReplishmentStock";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import useCloseInventoryIncident from "hooks/inventoryHooks/useCloseInventoryIncident";
import { firebaseService } from "services/firebaseService";

const AlertDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [productData, setProductData] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const [mode, setMode] = useState("");
    const [openDocument, setOpenDocument] = useState<boolean>(false);
    const [response, setResponse] = useState<any>([]);
    const [openApproval, setOpenApproval] = useState<boolean>(false);
    const [openShipment, setOpenShipment] = useState<boolean>(false);
    const [showAddShipmentReminder, setShowAddShipmentReminder] = useState<boolean>(false);
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [replacingAttachment, setReplacingAttachment] = useState<boolean>(false);
    const [poShipment, setPoShipment] = useState([]);
    const [country, setCountry] = useState<string>("");
    const [shipmentId, setShipmentId] = useState<string>("");
    const [showSuccessShipmentAdded, setshowSuccessShipmentAdded] = useState<boolean>(false);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [poShipmentPagination, setPoShipmentPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { alertId, inventoryIdx } = useParams();
    const { data, isFetching, pagination, isLoadingMore } = useGetSingleInventoryIncidents(alertId);
    const {
        createShipment,
        createdShipmentSuccess,
        fetchPoShipmentSuccess,
        updateShipment,
        updatedShipmentSuccess,
        fetchingPoShipment,
    } = useAppSelector((state) => state.shipment);

    const [shipmentDetails, setShipmentDetails] = useState<{ [key: string]: any }>({
        billOfLading: "",
        trackingLink: "",
        carrier: "",
        billNumber: "",
        destination: "",
        additionalInfo: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        shipmentSearch: "",
    });
    const [warehouseList, setWarehouseList] = useState([]);
    const { fetchedWarehouseListSuccess } = useAppSelector((state) => state.inventory);
    const {
        data: replishmentStock,
        isFetching: isFetchingReplishment,
        reFetchData,
    } = useGetReplishmentStockList();
    const {
        data: closeInident,
        isFetching: isFetchingCloseIncident,
        setClose,
    } = useCloseInventoryIncident();
    const productDetailsTableHeader = [
        { title: "Product name", widthClass: "w-[30.6%]" },
        { title: "SKU", widthClass: "w-[15.2%]" },
        { title: "Qty", widthClass: "w-[15.2%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    const handleDeleteRow = (index) => {
        const updatedData = productData.filter((_, idx) => idx !== index);
        setProductData(updatedData);
    };
    const handleAddRow = () => {
        const newRow = {
            name: "",
            inventoryCategory: "",
            qty: 0,
            unitOfMeasurement: "",
            amount: 0,
        };

        setProductData([...productData, newRow]);
    };

    useEffect(() => {
        const filteredData = replishmentStock?.purchaseOrder?.itemsOrServices?.filter(
            (item) => item?.inventoryId
        );
        setProductData(filteredData || []);
    }, [replishmentStock?.purchaseOrder?.itemsOrServices]);

    const updatedData = [...productData];

    const handleTableChange = (name, value, idx) => {
        const selectedProduct = productData?.find((product) => product.name === value);

        if (selectedProduct && !updatedData.some((product) => product.name === value)) {
            updatedData[idx] = {
                ...updatedData[idx],
                ...selectedProduct,
                qty: selectedProduct?.qty || 0,
            };

            setProductData(updatedData);
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Product selected already exists"} type="error" />
            ));
        }
    };
    const handleQuantityChange = (e, idx) => {
        const { value } = e.target;
        const updatedData = [...productData];

        updatedData[idx] = {
            ...updatedData[idx],
            qty: value,
        };

        setProductData(updatedData);
    };
    const productDetailsTableBody = productData?.map((data: any, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`-ml-3 pr-3  flex font-rocGroteskMedium items-center text-sm text-slate-500 `}
                >
                    <SelectInput
                        name="category"
                        value={data.name}
                        placeholder={"Enter Product"}
                        handleChange={(name, value) => handleTableChange(name, value, idx)}
                        className={"w-full !py-0 border-none"}
                        isRequired={true}
                        clearValue
                        dropdownOptions={replishmentStock?.purchaseOrder?.itemsOrServices?.map(
                            (res) => ({
                                label: (
                                    <div className={`font-rocGroteskMedium -mt-2 flex space-x-1`}>
                                        <VendorAvatar
                                            imageSrc={
                                                res?.inventory?.productImageDetails?.productAvatar
                                            }
                                            size={24}
                                            name={res?.name}
                                        />

                                        <p className="text-slate-500 ">{res?.name}</p>
                                    </div>
                                ),
                                value: res?.name,
                            })
                        )}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <p className="text-slate-700">{data?.inventory?.sku || "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full -ml-3  border-r pr-1  font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <TextInput
                        value={Number(data?.qty)?.toFixed(0)}
                        name={"qty"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", ".", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        inputContainerClassname="!border-none"
                        inputPlaceholder="Enter qty"
                        onChange={(e) => handleQuantityChange(e, idx)}
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <i
                    className={`ri-delete-bin-line text-sm text-[#64748B] ${
                        productData[0] && "opacity-50"
                    } `}
                    onClick={() => productData.length > 1 && handleDeleteRow(idx)}
                ></i>
            ),
        },
    ]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, "", debouncedFilter.search));
    }, [dispatch, debouncedFilter.search]);

    useEffect(() => {
        const countryObject = address?.origin?.details?.[0]?.address_components?.find((obj) =>
            obj.types.includes("country")
        );
        if (countryObject) {
            setCountry(countryObject.long_name);
        }
    }, [address?.origin]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        if (Boolean(createdShipmentSuccess)) {
            setResponse(createdShipmentSuccess);
            setShowAddShipmentReminder(false);
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id));
            setshowSuccessShipmentAdded(true);
            reFetchData();
        }
    }, [createdShipmentSuccess, dispatch]);

    const handleSubmit = () => {
        const body: { [key: string]: any } = {
            channel: "web",
            origin: {
                address: address?.origin?.address,
                lng: address?.origin?.lng,
                lat: address?.origin?.lat,
                details: [address?.origin?.address],
                country: country,
            },

            destinationId: shipmentDetails?.destination,
            purchaseOrderId: replishmentStock?.purchaseOrder?._id,
            shipmentMode: mode,
            productDetails: productData?.map((product) => ({
                category: product?.inventory?.category ?? product?.name,
                inventoryId: product?.inventoryId,
                quantity: Number(Number(product?.qty)?.toFixed(0)),
            })),
        };
        if (shipmentDetails?.additionalInfo) {
            body.additionalNotes = shipmentDetails?.additionalInfo;
        }
        if (mode.toLowerCase() === "land") {
            if (shipmentDetails?.trackingLink) {
                body.trackingLink = shipmentDetails?.trackingLink;
            }
        }
        if (mode.toLowerCase() === "air") {
            body.airWayBillNumber = shipmentDetails?.billNumber;
            body.carrier = shipmentDetails?.carrier;
        }
        if (mode.toLowerCase() === "ocean") {
            if (shipmentDetails?.trackingLink) {
                body.trackingLink = shipmentDetails?.trackingLink;
            }
            body.billOfLading = shipmentDetails?.billOfLading;
        }
        dispatch(shipmentActions.createShipment(body));
    };
    useEffect(() => {
        if (replishmentStock?.purchaseOrder?._id) {
            dispatch(
                shipmentActions.getPoShipment(
                    replishmentStock?.purchaseOrder?._id,
                    debouncedFilter.shipmentSearch
                )
            );
        }
    }, [dispatch, replishmentStock?.purchaseOrder?._id, debouncedFilter.shipmentSearch]);

    useEffect(() => {
        if (Boolean(fetchPoShipmentSuccess)) {
            setPoShipment(fetchPoShipmentSuccess?.data);
            setPoShipmentPagination({
                current: fetchPoShipmentSuccess?.pagination.current,
                number_of_pages: fetchPoShipmentSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchPoShipmentSuccess]);
    const handleReplaceUpload = (indexToReplace: number, newFileName: string, newFile: File) => {
        setReplacingAttachment(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${newFileName}`,
            newFile,
            (url) => {
                setAttachments((prevAttachments) => {
                    const updatedAttachments = [...prevAttachments];
                    updatedAttachments[indexToReplace] = {
                        filename: newFileName,
                        path: url,
                        documentType: "pdf",
                    };
                    return updatedAttachments;
                });

                setReplacingAttachment(false);
            }
        );
    };
    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${fileName}`,
                file,
                (url) => {
                    setAttachments((prev) => [
                        ...prev,
                        { path: url, filename: fileName, documentType: "pdf" },
                    ]);

                    setUploadingAttachment(false);
                }
            );
        }
    };
    const handleRemoveUpload = (idx) => {
        const newArr = [...attachments];

        const imagePath = decodeURIComponent(
            attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachments(newArr);
            setDeletingImage(false);
        });
    };

    const handleUpdateShipment = () => {
        const body = {
            attachments,
        };
        dispatch(shipmentActions.updateShipment(body, shipmentId));
    };
    useEffect(() => {
        if (Boolean(updatedShipmentSuccess)) {
            dispatch(shipmentActions.resetUpdateShipmentSuccess());
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id));
            reFetchData();
            setOpenDocument(false);
        }
    }, [updatedShipmentSuccess, dispatch]);

    return (
        <PageFrame isLoading={isFetching}>
            <div className="border border-slate-100 rounded-[8px] bg-white">
                <div className="px-6 py-[22px] border-b border-n-20 flex items-center justify-between">
                    <div
                        onClick={() => navigate(-1)}
                        className="cursor-pointer space-x-1 flex items-center justify-center"
                    >
                        <i className="ri-arrow-left-line text-sm text-g-75"></i>
                        <span className="text-sm underline text-gm-75 font-rocGroteskMedium">
                            Back
                        </span>
                    </div>

                    <div className="flex items-center space-x-3">
                        {/* <Button
                            btnText="Stop"
                            btnClassname="!px-4 !py-2 !h-[40px] !bg-transparent !border !border-n-20"
                        /> */}
                        {/* <Button btnText="Escalate" btnClassname="!px-4 !py-2 !h-[40px] !bg-n-20" /> */}
                        {data?.status?.toLowerCase() === "open" && (
                            <Button
                                btnText="Resolve"
                                isLoading={isFetchingCloseIncident}
                                btnClassname="!px-4 !py-2 !h-[40px] "
                                onClick={() => setClose(true)}
                            />
                        )}
                    </div>
                </div>

                <div className={`p-6 border-b border-slate-100 flex items-start space-x-4`}>
                    <div
                        className={`w-[60px] h-[60px] border ${
                            data?.incidentType?.toLowerCase() === "stockout"
                                ? " border-[#FF0000] bg-[#FEF2F2]"
                                : "border-[#FA9600] bg-[#FFFBEB]"
                        } rounded  flex items-center justify-center`}
                    >
                        {data?.incidentType?.toLowerCase() === "stockout" ? (
                            <i className="ri-alarm-warning-fill text-[#FF0000] text-[32px]"></i>
                        ) : (
                            <i className="ri-error-warning-fill text-[#FA9600] text-[32px]"></i>
                        )}
                    </div>
                    <div>
                        <div className="flex items-center space-x-3">
                            <span className=" text-g-75 text-xl font-rocGroteskMedium">
                                #INCIDENT {inventoryIdx} - Potential {data?.incidentType} for{" "}
                                {data?.inventory?.productName?.charAt(0).toUpperCase() +
                                    data?.inventory?.productName?.slice(1)}
                            </span>
                            <span
                                className={` ${
                                    data?.incidentType?.toLowerCase() === "stockout"
                                        ? "text-r-50 border-r-50"
                                        : "text-[#EA580C] border-[#EA580C] bg-[#FFEDD5]"
                                } px-2 py-[2px] text-sm rounded-[3px] border  font-rocGroteskMedium`}
                            >
                                {data?.incidentType?.charAt(0).toUpperCase() +
                                    data?.incidentType?.slice(1)}
                            </span>
                        </div>

                        <span className="block text-slate-500 text-base font-rocGroteskMedium mb-2">
                            Reported by Synth |{" "}
                            {moment(data?.createdAt).utc().format("D MMM YYYY [at] hh:mmA [UTC]")}
                        </span>
                        <div className="flex items-center space-x-4">
                            {/* <div className="flex items-center space-x-1.5 w-fit">
                            <VendorAvatar
                                name="Augustine Asiuwhu"
                                imageSrc=""
                                size={20}
                                textClassname="!text-[8px]"
                            />
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Augustine Asiuwhu
                            </span>
                        </div> */}

                            {/* <div className="flex items-center space-x-1.5 w-fit">
                            <VendorAvatar
                                name="Augustine Asiuwhu"
                                imageSrc=""
                                size={20}
                                textClassname="!text-[8px]"
                            />
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Augustine Asiuwhu
                            </span>
                        </div> */}
                        </div>
                    </div>
                </div>

                <div className="px-6 pt-8">
                    <div className="grid grid-cols-4 max-lg:space-x-0 max-lg:gap-4 max-lg:grid-cols-2 space-x-4 mb-8">
                        <div className="p-6 border border-slate-200 shadow-[0px_4px_8px_-4px_#10182808] rounded-[6px]">
                            <div className="rounded-[2px] bg-[#FF0000] w-[43px] h-[22px] flex items-center justify-center mb-1.5">
                                <span className="text-xs font-rocGroteskMedium uppercase text-white">
                                    Live
                                </span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-[22px] max-sm:text-base font-rocGroteskMedium text-g-75">
                                    {data?.risk?.charAt(0).toUpperCase() + data?.risk?.slice(1)}{" "}
                                    risk
                                </span>
                                <i className="ri-bar-chart-2-fill text-2xl max-sm:text-xl text-[#FF0000] mt-[-4ßpx]"></i>
                            </div>
                        </div>

                        <div className="p-6 border border-slate-200 shadow-[0px_4px_8px_-4px_#10182808] rounded-[6px]">
                            <span className="block text-base text-slate-500 font-rocGroteskMedium mb-1">
                                Started at
                            </span>

                            <span className="block text-[22px] max-sm:text-base font-rocGroteskMedium text-g-75">
                                {moment(data?.createdAt).fromNow()}
                            </span>
                        </div>

                        <div className="p-6 border border-slate-200 shadow-[0px_4px_8px_-4px_#10182808] rounded-[6px]">
                            <span className="block text-base text-slate-500 font-rocGroteskMedium mb-1">
                                Alert type
                            </span>

                            <span className="block text-[22px] max-sm:text-base font-rocGroteskMedium text-g-75">
                                {data?.incidentType?.charAt(0).toUpperCase() +
                                    data?.incidentType?.slice(1)}
                            </span>
                        </div>

                        <div className="p-6 border border-slate-200 shadow-[0px_4px_8px_-4px_#10182808] rounded-[6px]">
                            <span className="block text-base text-slate-500 font-rocGroteskMedium mb-1">
                                Potential Revenue Loss
                            </span>

                            <span className="block text-[22px] max-sm:text-base font-rocGroteskMedium text-g-75">
                                {getCurrencyFromCurrencyCode(data?.inventory?.currency)}
                                {formatMoney().format(
                                    data?.inventory?.unitCost?.amount * data?.qty
                                ) || 0}
                            </span>
                        </div>
                    </div>

                    <div className="mb-8">
                        <h5 className="text-base font-rocGroteskMedium mb-1">Summary</h5>
                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                            {data?.summary || "N/A"}
                        </p>
                    </div>
                </div>
                <AlertTabDetails
                    data={data}
                    isFetching={isFetching}
                    pagination={pagination}
                    isLoadingMore={isLoadingMore}
                    modalShow={true}
                    isFetchingReplishment={isFetchingReplishment}
                    replishmentStock={replishmentStock}
                    onClickDocument={() => {
                        setOpenDocument(true);
                    }}
                    incidentId={alertId}
                    onClickShipment={() => {
                        setOpenShipment(true);
                    }}
                />
            </div>
            <ModalContainer
                open={openDocument}
                showCloseIcon={false}
                tailwindClassName="w-[700px] max-lg:w-[80%] max-sm:w-full"
                closeModal={() => {
                    setOpenDocument(false);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <div className="">
                        <div className="flex py-4  px-8 max-lg:px-4 border-b border-slate-200 justify-between items-center">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Add documents related to shipment(s)
                            </p>
                            <i
                                className="ri-close-fill text-xl text-black cursor-pointer"
                                onClick={() => setOpenDocument(false)}
                            ></i>
                        </div>
                        <div className=" px-8 max-lg:px-4 mt-6 border-b border-slate-200 ">
                            <div>
                                <SelectInput
                                    value={shipmentId}
                                    name="shipment"
                                    placeholder={"Select Shipment"}
                                    handleChange={(name, value) => {
                                        setShipmentId(value);
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingPoShipment}
                                    handleInputChange={debounce((evt) => {
                                        setDebouncedFilter({
                                            search: "",
                                            shipmentSearch: evt.target.value,
                                        });
                                    }, 800)}
                                    dropdownOptions={poShipment?.map((data: any) => ({
                                        label: (
                                            <div className="flex space-x-1 items-center">
                                                <p>{data?.origin?.country}</p> <span> - </span>
                                                <p>{data?.destination?.address?.country}</p>
                                            </div>
                                        ),
                                        value: data?._id,
                                    }))}
                                />
                            </div>
                            {attachments?.length === 0 && (
                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                                        <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                        <p className="text-[#142837] mb-1 text-sm ">
                                            Drag your file here to upload
                                        </p>
                                        <p className="text-[#6B778C] text-sm mb-4">
                                            File size limit: 5MB
                                        </p>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {attachments?.length > 0 && (
                            <div className="px-8 max-lg:px-4 max-h-[400px] overflow-y-auto mt-6 border-b border-slate-200">
                                {attachments?.map((doc, idx) => (
                                    <div
                                        key={idx}
                                        className="h-[68px] justify-between  items-center   border-t border-b border-r rounded border-slate-200 flex"
                                    >
                                        <div className="flex items-center space-x-3">
                                            <div className="bg-slate-50 border w-[68px] rounded-tl rounded-bl h-[68px] flex justify-center items-center border-slate-200">
                                                <i className="ri-file-fill text-4xl text-slate-500"></i>
                                            </div>
                                            <div>
                                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                    {doc?.filename}
                                                </p>
                                                <p className="text-slate-500  font-rocGroteskMedium text-sm">
                                                    PDF
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center pr-6 space-x-2 ">
                                            <div className="relative w-fit cursor-pointer">
                                                <Button
                                                    isLoading={replacingAttachment}
                                                    disabled={
                                                        uploadingAttachment ||
                                                        deletingImage ||
                                                        replacingAttachment
                                                    }
                                                    onClick={() => {
                                                        !replacingAttachment &&
                                                            document
                                                                .getElementById("fileInput")
                                                                ?.click();
                                                    }}
                                                    btnText="Replace File"
                                                    btnClassname="!px-4 !py-2.5 !bg-transparent !whitespace-nowrap  !border !border-[#DFE1E6] "
                                                />

                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className={`absolute z-[-1]  opacity-0`}
                                                    accept="png"
                                                    onChange={(e) =>
                                                        handleReplaceUpload(
                                                            idx,
                                                            e.target?.files[0]?.name,
                                                            e.target.files[0] as File
                                                        )
                                                    }
                                                />
                                            </div>

                                            <Button
                                                btnText="Remove"
                                                isLoading={deletingImage}
                                                disabled={
                                                    uploadingAttachment ||
                                                    deletingImage ||
                                                    replacingAttachment
                                                }
                                                btnClassname="!px-4 !py-2.5 !bg-transparent !text-[#FF0000] !border !border-[#DFE1E6] "
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveUpload(idx);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-6 px-5 font-rocGroteskMedium flex  justify-between items-center">
                                        <div className="flex items-center space-x-2.5">
                                            <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                            <div>
                                                <p className="text-[#142837] mb-1 text-sm ">
                                                    Drag your file here to upload
                                                </p>
                                                <p className="text-[#6B778C] text-sm mb-4">
                                                    File size limit: 5MB
                                                </p>
                                            </div>
                                        </div>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="mt-3 px-8 max-lg:px-4 flex space-x-4 items-center ">
                            <Button
                                btnText="Close"
                                disabled={updateShipment}
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                onClick={() => setOpenDocument(false)}
                            />
                            <Button
                                isLoading={updateShipment}
                                btnText="Add uploaded file to shipment"
                                onClick={handleUpdateShipment}
                                btnClassname="!px-4 !py-2.5 "
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openShipment}
                showCloseIcon={false}
                tailwindClassName="w-[700px] max-lg:w-[80%] max-sm:w-full"
                closeModal={() => {
                    setOpenShipment(false);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShowAddShipmentReminder(true);
                            setOpenShipment(false);
                        }}
                    >
                        <div className="">
                            <div className="flex py-4 border-b border-slate-200 px-8 max-lg:px-4 justify-between items-center">
                                <p className="text-lg font-rocGroteskMedium text-g-75">
                                    Add shipment(s) from your supplier
                                </p>
                                <i
                                    className="ri-close-fill text-xl text-black cursor-pointer"
                                    onClick={() => setOpenShipment(false)}
                                ></i>
                            </div>
                            <div className=" mt-6 max-h-[400px] overflow-y-auto">
                                <div className="px-8 max-lg:px-4">
                                    <div className=" pb-4">
                                        <div className="flex space-x-5 w-full">
                                            <div className="w-full">
                                                <TextInput
                                                    value={""}
                                                    disabled={true}
                                                    name="shipmentId"
                                                    placeholder={"Shipment ID"}
                                                    onChange={(e) => {}}
                                                />
                                                <span className="mt-1 flex items-center">
                                                    <img
                                                        src="https://storage.googleapis.com/heysynth_files/synth_webapp/Frame_g6qjiy.svg"
                                                        className="h-2 w-2"
                                                        alt=""
                                                    />
                                                    <p className="text-[10px] leading-[14px] text-[#64748B]">
                                                        This is auto generated by the system
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="w-full">
                                                <SelectInput
                                                    value={mode}
                                                    name="mode"
                                                    placeholder={"Select mode"}
                                                    handleChange={(name, value) => setMode(value)}
                                                    isRequired={true}
                                                    optionItemContainerClassName={
                                                        "hover:bg-slate-100"
                                                    }
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-ship-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Ocean
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "ocean",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-plane-fill"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Air
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "air",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-truck-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Land
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "land",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className=" pb-4">
                                            {mode.toLowerCase() === "ocean" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                    <TextInput
                                                        value={shipmentDetails?.billOfLading}
                                                        type={"text"}
                                                        name="billOfLading"
                                                        placeholder={"Bill of lading"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "air" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.billNumber}
                                                        name="billNumber"
                                                        placeholder={"Airway bill number"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                    <SelectInput
                                                        value={shipmentDetails?.carrier}
                                                        name="carrier"
                                                        placeholder={"Carrier"}
                                                        handleChange={(name, value) =>
                                                            handleChange({
                                                                target: { name, value },
                                                            })
                                                        }
                                                        isRequired={true}
                                                        optionItemContainerClassName={
                                                            "hover:bg-slate-100"
                                                        }
                                                        dropdownOptions={oceanCarriers?.map(
                                                            (carrierData) => {
                                                                return {
                                                                    label: carrierData?.[
                                                                        "Full Name"
                                                                    ],
                                                                    value: carrierData?.SCAC,
                                                                };
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "land" && (
                                                <div className="mt-3 ">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex space-x-5 mt-1">
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.streetAddress
                                                        ? address?.origin?.address
                                                        : ""
                                                }
                                                placeholder="Origin"
                                                required={true}
                                                name={"origin"}
                                            />

                                            <SelectInput
                                                value={shipmentDetails?.destination}
                                                name="destination"
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedFilter({
                                                        search: evt.target.value,
                                                        shipmentSearch: "",
                                                    });
                                                }, 800)}
                                                placeholder={"Destination"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                optionItemContainerClassName={"hover:bg-slate-100"}
                                                dropdownOptions={warehouseList?.map((list) => {
                                                    return {
                                                        label: list?.warehouseName,
                                                        value: list?._id,
                                                    };
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-8 mt-3  max-lg:px-4">
                                    <div className="font-rocGroteskMedium">
                                        <p className="text-base  text-gm-50 mb-2">
                                            Product details
                                        </p>
                                        <p className="text-sm  text-[#64748B]  mb-2">
                                            Kindly include the products you wish to ship in your
                                            shipment
                                        </p>
                                        <div>
                                            <CustomTable
                                                tableBody={productDetailsTableBody}
                                                tableHeader={productDetailsTableHeader}
                                                isAllSelectable={false}
                                                isCellSelectable={false}
                                                headerContainerClass="!bg-[#F1F5F9] !border-[#E2E8F0] !border"
                                                isScrollable={false}
                                                isCellBordered={false}
                                                bodyItemClass={"hover:!bg-transparent"}
                                            />
                                        </div>
                                        {productData?.length > 0 &&
                                            productData?.length <
                                                replishmentStock?.purchaseOrder?.itemsOrServices
                                                    ?.length && (
                                                <Button
                                                    btnText="Add products"
                                                    onClick={handleAddRow}
                                                    btnClassname="!px-4 !mt-3 !py-2.5 !w-fit "
                                                />
                                            )}
                                    </div>
                                    <div className=" mt-4 pb-4">
                                        <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                            Additional notes
                                        </p>
                                        <TextareaInput
                                            name={"additionalInfo"}
                                            value={shipmentDetails?.additionalInfo}
                                            onChange={handleChange}
                                            rows={2}
                                            placeholder={"Additional notes"}
                                            containerClassname=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-3 border-t border-slate-200 px-8 max-lg:px-4 flex space-x-4 items-center ">
                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        setOpenShipment(false);
                                    }}
                                />
                                <Button
                                    btnText="Add shipment"
                                    btnClassname="!px-4 !py-2.5 "
                                    type={"submit"}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showAddShipmentReminder}
                showCloseIcon={false}
                tailwindClassName="md:w-[600px] max-lg:w-[80%] max-sm:w-full"
            >
                <div className="bg-white p-6 max-lg:p-3 flex flex-col rounded-[8px]  ">
                    <p className="text-[18px] mb-2 leading-[24px] font-rocGroteskMedium text-gm-50">
                        Review shipment for accuracy
                    </p>

                    <p className="text-slate-500 font-rocGroteskMedium text-base">
                        Please review and ensure that the items listed in the Purchase Order (PO)
                        match what has been delivered. Before adding these products to inventory,
                        carefully review the product details for accuracy in both quantity and
                        quality to ensure everything is correct.
                    </p>

                    <div className=" justify-end mt-6 max-lg:mt-3 flex space-x-4">
                        <Button
                            btnText={"No, go back"}
                            btnClassname={"!bg-[#F4F5F7] !w-fit !py-3"}
                            disabled={createShipment}
                            onClick={() => {
                                setShowAddShipmentReminder(false);
                                setOpenShipment(true);
                            }}
                        />
                        <Button
                            btnText={`Proceed to add shipment to inventory`}
                            onClick={handleSubmit}
                            btnClassname={"!w-fit !py-3"}
                            disabled={createShipment}
                            isLoading={createShipment}
                        />
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showSuccessShipmentAdded}
                showCloseIcon={false}
                closeModal={() => {
                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                    setshowSuccessShipmentAdded(false);
                    setShipmentDetails({});
                    setMode("");
                }}
                tailwindClassName="w-[500px] max-lg:w-[80%] max-sm:w-full"
            >
                {response?.failed?.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-8 max-lg:pt-4 max-h-[70vh] ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                    }}
                                ></i>
                            </div>
                            <div className="flex flex-col items-center">
                                <img
                                    src="https://storage.googleapis.com/heysynth_files/synth_webapp/Software_xcvcl6.svg"
                                    alt="warn-sign"
                                />

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment creation failed!
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    There was an error while creating your shipment as the quantity
                                    exceeded the purchase order
                                </p>
                            </div>
                            {response?.failed?.map((item, idx) => (
                                <div className="max-h-[50vh] overflow-y-scroll ">
                                    <div className="mt-2">
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            Package {idx + 1}
                                        </p>
                                    </div>
                                    <div className="bg-white space-y-3 mt-2 text-sm mb-2 shadow-cardDropdownShadow p-4">
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Product name</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.productName}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">SKU</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.sku}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Inventory category</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.inventoryCategory}
                                            </p>
                                        </div>

                                        <div className="flex justify-between text-[#FF3D1D] items-center font-rocGroteskMedium">
                                            <div className="flex items-center space-x-1 ">
                                                <i className="ri-information-line"></i>
                                                <p>Quantity</p>
                                            </div>
                                            <p className="text-end">
                                                {item?.qtyRemaining < 0 ? 0 : item?.qtyRemaining}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full px-8 py-4 max-lg:px-4 border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 max-lg:px-4 pt-5 max-h-[70vh] overflow-y-scroll ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                    }}
                                ></i>
                            </div>
                            <div className="flex mb-4 flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-2xl  text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment added successful
                                </p>
                                <p className="text-base text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    The shipment has been successfully created and associated with
                                    Purchase Order: PO-{replishmentStock?.purchaseOrder?.taId}
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-8 max-lg:px-4 max-lg:pb-3 pb-6 pt-2  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                }}
                            />
                        </div>
                    </div>
                )}
            </ModalContainer>

            {/* <ModalContainer
                open={openApproval}
                showCloseIcon={false}
                tailwindClassName="w-[600px] max-lg:w-[80%] max-sm:w-full"
                closeModal={() => {
                    setOpenApproval(false);
                }}
            >
                <div className="bg-white max-h-[600px]  h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <div className="">
                        <div className="px-6 pt-4">
                            <i className="ri-close-fill cursor-pointer text-2xl justify-end flex text-[#142837]" onClick={()=> setOpenApproval(false)}></i>
                        </div>
                        <div className="flex py-2 flex-col  px-8 justify-center items-center">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Software_1_xh62gs.svg"
                                alt=""
                            />
                            <p className="text-[22px] mt-2 leading-[34px] font-rocGroteskMedium text-[#142837]">
                                Pending purchase request approval! (1/3)
                            </p>
                            <span className="mt-1 text-center text-base text-[#6B778C] font-rocGroteskMedium">
                                You have a pending purchase request. Please approve it to ensure
                                timely procurement. If not approved before the countdown expires, it
                                will be canceled and not sent to the supplier.
                            </span>
                            <div className="flex mt-3 items-center space-x-2">
                                <p className="text-base font-rocGroteskMedium text-[#042821]">
                                    Expires in:
                                </p>
                                <div className="space-x-1 flex w-fit rounded-[3px] items-center border py-1 bg-[#FEE2E2] border-[#DC2626] px-2">
                                    <i className="ri-time-line text-[#DC2626] text-[13px]"></i>
                                    <span className="text-[#DC2626] text-base font-rocGroteskMedium">
                                        2d: 0h: 45m: 22s
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=" mt-6 max-h-[400px] overflow-y-auto">
                            <div className="px-8 mb-3">
                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                    Approvals
                                </p>
                                <div className="mt-2">
                                    <div className="flex items-center justify-between border-b py-3">
                                        <div className="flex space-x-2 items-center">
                                            <VendorAvatar name="Iroh Om" size={28} imageSrc="" />
                                            <div>
                                                <p className="text-[13px] font-rocGroteskMedium leading-[24px] text-gm-50">
                                                    Tom Smith - Finance
                                                </p>
                                                <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                                    April 17, 2023; 2:00pm
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center justify-center bg-slate-100  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Level 1</p>
                                            </div>
                                            <div className="flex items-center justify-center bg-[#D0F4ED]  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Approved</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between border-b py-3">
                                        <div className="flex space-x-2 items-center">
                                            <VendorAvatar name="Iroh Om" size={28} imageSrc="" />
                                            <div>
                                                <p className="text-[13px] font-rocGroteskMedium leading-[24px] text-gm-50">
                                                    Tom Smith - Finance
                                                </p>
                                                <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                                    April 17, 2023; 2:00pm
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center justify-center bg-slate-100  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Level 1</p>
                                            </div>
                                            <div className="flex items-center justify-center bg-[#D0F4ED]  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Approved</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex space-x-2 items-center">
                                            <VendorAvatar name="Iroh Om" size={28} imageSrc="" />
                                            <div>
                                                <p className="text-[13px] font-rocGroteskMedium leading-[24px] text-gm-50">
                                                    Tom Smith - Finance
                                                </p>
                                                <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                                    April 17, 2023; 2:00pm
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center justify-center bg-slate-100  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Level 1</p>
                                            </div>
                                            <div className="flex items-center justify-center bg-[#F9DFB3]  !w-fit px-3 py-1 rounded text-xs font-rocGroteskMedium">
                                                <p>Pending</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 border-t border-slate-200 px-8 flex space-x-4 items-center ">
                            <Button
                                btnText="View purchase request"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                onClick={() => {}}
                            />
                            <Button
                                btnText="Approve purchase request"
                                onClick={() => {}}
                                btnClassname="!px-4 !py-2.5 "
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}
        </PageFrame>
    );
};

export default AlertDetails;
