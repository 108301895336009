export const integrationTypes = {
    GET_QUICKBOOKS_OAUTH_URL_REQUEST: "GET_QUICKBOOKS_OAUTH_URL_REQUEST",
    GET_QUICKBOOKS_OAUTH_URL_SUCCESS: "GET_QUICKBOOKS_OAUTH_URL_SUCCESS",
    GET_QUICKBOOKS_OAUTH_URL_FAILURE: "GET_QUICKBOOKS_OAUTH_URL_FAILURE",
    GET_QUICKBOOKS_EXCHANGE_CODE_REQUEST: "GET_QUICKBOOKS_EXCHANGE_CODE_REQUEST",
    GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS: "GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS",
    GET_QUICKBOOKS_EXCHANGE_CODE_FAILURE: "GET_QUICKBOOKS_EXCHANGE_CODE_FAILURE",
    FETCH_QUICKBOOKS_AP_ACCOUNTS_REQUEST: "FETCH_QUICKBOOKS_AP_ACCOUNTS_REQUEST",
    FETCH_QUICKBOOKS_AP_ACCOUNTS_SUCCESS: "FETCH_QUICKBOOKS_AP_ACCOUNTS_SUCCESS",
    FETCH_QUICKBOOKS_AP_ACCOUNTS_FAILURE: "FETCH_QUICKBOOKS_AP_ACCOUNTS_FAILURE",
    FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_REQUEST: "FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_REQUEST",
    FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_SUCCESS: "FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_SUCCESS",
    FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_FAILURE: "FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_FAILURE",
    SET_UP_QUICKBOOKS_ACCOUNT_REQUEST: "SET_UP_QUICKBOOKS_ACCOUNT_REQUEST",
    SET_UP_QUICKBOOKS_ACCOUNT_SUCCESS: "SET_UP_QUICKBOOKS_ACCOUNT_SUCCESS",
    SET_UP_QUICKBOOKS_ACCOUNT_FAILURE: "SET_UP_QUICKBOOKS_ACCOUNT_FAILURE",
    GET_SHOPIFY_OAUTH_URL_REQUEST: "GET_SHOPIFY_OAUTH_URL_REQUEST",
    GET_SHOPIFY_OAUTH_URL_SUCCESS: "GET_SHOPIFY_OAUTH_URL_SUCCESS",
    GET_SHOPIFY_OAUTH_URL_FAILURE: "GET_SHOPIFY_OAUTH_URL_FAILURE",
    VERIFY_SHOPIFY_ACCOUNT_REQUEST: "VERIFY_SHOPIFY_ACCOUNT_REQUEST",
    VERIFY_SHOPIFY_ACCOUNT_SUCCESS: "VERIFY_SHOPIFY_ACCOUNT_SUCCESS",
    VERIFY_SHOPIFY_ACCOUNT_FAILURE: "VERIFY_SHOPIFY_ACCOUNT_FAILURE",
    FETCH_SOCIAL_ACCOUNT_REQUEST: "FETCH_SOCIAL_ACCOUNT_REQUEST",
    FETCH_SOCIAL_ACCOUNT_SUCCESS: "FETCH_SOCIAL_ACCOUNT_SUCCESS",
    FETCH_SOCIAL_ACCOUNT_FAILURE: "FETCH_SOCIAL_ACCOUNT_FAILURE",
    UPDATE_SOCIAL_ACCOUNT_REQUEST: "UPDATE_SOCIAL_ACCOUNT_REQUEST",
    UPDATE_SOCIAL_ACCOUNT_SUCCESS: "UPDATE_SOCIAL_ACCOUNT_SUCCESS",
    UPDATE_SOCIAL_ACCOUNT_FAILURE: "UPDATE_SOCIAL_ACCOUNT_FAILURE",
    FETCH_QUICKBOOKS_INCOME_ACCOUNTS_REQUEST: "FETCH_QUICKBOOKS_INCOME_ACCOUNTS_REQUEST",
    FETCH_QUICKBOOKS_INCOME_ACCOUNTS_SUCCESS: "FETCH_QUICKBOOKS_INCOME_ACCOUNTS_SUCCESS",
    FETCH_QUICKBOOKS_INCOME_ACCOUNTS_FAILURE: "FETCH_QUICKBOOKS_INCOME_ACCOUNTS_FAILURE",
    FETCH_QUICKBOOKS_ASSET_ACCOUNTS_REQUEST: "FETCH_QUICKBOOKS_ASSET_ACCOUNTS_REQUEST",
    FETCH_QUICKBOOKS_ASSET_ACCOUNTS_SUCCESS: "FETCH_QUICKBOOKS_ASSET_ACCOUNTS_SUCCESS",
    FETCH_QUICKBOOKS_ASSET_ACCOUNTS_FAILURE: "FETCH_QUICKBOOKS_ASSET_ACCOUNTS_FAILURE",
    FETCH_SHOPIFY_DATA_REQUEST: "FETCH_SHOPIFY_DATA_REQUEST",
    FETCH_SHOPIFY_DATA_SUCCESS: "FETCH_SHOPIFY_DATA_SUCCESS",
    FETCH_SHOPIFY_DATA_FAILURE: "FETCH_SHOPIFY_DATA_FAILURE",
    GET_AMAZON_OAUTH_URL_REQUEST: "GET_AMAZON_OAUTH_URL_REQUEST",
    GET_AMAZON_OAUTH_URL_SUCCESS: "GET_AMAZON_OAUTH_URL_SUCCESS",
    GET_AMAZON_OAUTH_URL_FAILURE: "GET_AMAZON_OAUTH_URL_FAILURE",
    VERIFY_AMAZON_ACCOUNT_REQUEST: "VERIFY_AMAZON_ACCOUNT_REQUEST",
    VERIFY_AMAZON_ACCOUNT_SUCCESS: "VERIFY_AMAZON_ACCOUNT_SUCCESS",
    VERIFY_AMAZON_ACCOUNT_FAILURE: "VERIFY_AMAZON_ACCOUNT_FAILURE",
    GET_SPS_COMMERCE_OAUTH_URL_REQUEST: "GET_SPS_COMMERCE_OAUTH_URL_REQUEST",
    GET_SPS_COMMERCE_OAUTH_URL_SUCCESS: "GET_SPS_COMMERCE_OAUTH_URL_SUCCESS",
    GET_SPS_COMMERCE_OAUTH_URL_FAILURE: "GET_SPS_COMMERCE_OAUTH_URL_FAILURE",
    COMPLETE_SPS_COMMERCE_CONNECTION_REQUEST: "COMPLETE_SPS_COMMERCE_CONNECTION_REQUEST",
    COMPLETE_SPS_COMMERCE_CONNECTION_SUCCESS: "COMPLETE_SPS_COMMERCE_CONNECTION_SUCCESS",
    COMPLETE_SPS_COMMERCE_CONNECTION_FAILURE: "COMPLETE_SPS_COMMERCE_CONNECTION_FAILURE",
    GET_QB_DESKTOP_OAUTH_URL_REQUEST: "GET_QB_DESKTOP_OAUTH_URL_REQUEST",
    GET_QB_DESKTOP_OAUTH_URL_SUCCESS: "GET_QB_DESKTOP_OAUTH_URL_SUCCESS",
    GET_QB_DESKTOP_OAUTH_URL_FAILURE: "GET_QB_DESKTOP_OAUTH_URL_FAILURE",
    COMPLETE_QB_DESKTOP_CONNECTION_REQUEST: "COMPLETE_QB_DESKTOP_CONNECTION_REQUEST",
    COMPLETE_QB_DESKTOP_CONNECTION_SUCCESS: "COMPLETE_QB_DESKTOP_CONNECTION_SUCCESS",
    COMPLETE_QB_DESKTOP_CONNECTION_FAILURE: "COMPLETE_QB_DESKTOP_CONNECTION_FAILURE",
    FETCH_QB_DESKTOP_AP_ACCOUNTS_REQUEST: "FETCH_QB_DESKTOP_AP_ACCOUNTS_REQUEST",
    FETCH_QB_DESKTOP_AP_ACCOUNTS_SUCCESS: "FETCH_QB_DESKTOP_AP_ACCOUNTS_SUCCESS",
    FETCH_QB_DESKTOP_AP_ACCOUNTS_FAILURE: "FETCH_QB_DESKTOP_AP_ACCOUNTS_FAILURE",
    FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_REQUEST: "FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_REQUEST",
    FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_SUCCESS: "FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_SUCCESS",
    FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_FAILURE: "FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_FAILURE",
    FETCH_QB_DESKTOP_INCOME_ACCOUNTS_REQUEST: "FETCH_QB_DESKTOP_INCOME_ACCOUNTS_REQUEST",
    FETCH_QB_DESKTOP_INCOME_ACCOUNTS_SUCCESS: "FETCH_QB_DESKTOP_INCOME_ACCOUNTS_SUCCESS",
    FETCH_QB_DESKTOP_INCOME_ACCOUNTS_FAILURE: "FETCH_QB_DESKTOP_INCOME_ACCOUNTS_FAILURE",
    FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_REQUEST:
        "FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_REQUEST",
    FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_SUCCESS:
        "FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_SUCCESS",
    FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_FAILURE:
        "FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_FAILURE",
    FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_REQUEST: "FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_REQUEST",
    FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_SUCCESS: "FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_SUCCESS",
    FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_FAILURE: "FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_FAILURE",
    SETUP_QB_DESKTOP_REQUEST: "SETUP_QB_DESKTOP_REQUEST",
    SETUP_QB_DESKTOP_SUCCESS: "SETUP_QB_DESKTOP_SUCCESS",
    SETUP_QB_DESKTOP_FAILURE: "SETUP_QB_DESKTOP_FAILURE",
    SETUP_KLAVIYO_CONNECTION_REQUEST: "SETUP_KLAVIYO_CONNECTION_REQUEST",
    SETUP_KLAVIYO_CONNECTION_SUCCESS: "SETUP_KLAVIYO_CONNECTION_SUCCESS",
    SETUP_KLAVIYO_CONNECTION_FAILURE: "SETUP_KLAVIYO_CONNECTION_FAILURE",
    COMPLETE_KLAVIYO_CONNECTION_REQUEST: "COMPLETE_KLAVIYO_CONNECTION_REQUEST",
    COMPLETE_KLAVIYO_CONNECTION_SUCCESS: "COMPLETE_KLAVIYO_CONNECTION_SUCCESS",
    COMPLETE_KLAVIYO_CONNECTION_FAILURE: "COMPLETE_KLAVIYO_CONNECTION_FAILURE",
    SETUP_GOOGLE_ADS_CONNECTION_REQUEST: "SETUP_GOOGLE_ADS_CONNECTION_REQUEST",
    SETUP_GOOGLE_ADS_CONNECTION_SUCCESS: "SETUP_GOOGLE_ADS_CONNECTION_SUCCESS",
    SETUP_GOOGLE_ADS_CONNECTION_FAILURE: "SETUP_GOOGLE_ADS_CONNECTION_FAILURE",
    COMPLETE_GOOGLE_ADS_CONNECTION_REQUEST: "COMPLETE_GOOGLE_ADS_CONNECTION_REQUEST",
    COMPLETE_GOOGLE_ADS_CONNECTION_SUCCESS: "COMPLETE_GOOGLE_ADS_CONNECTION_SUCCESS",
    COMPLETE_GOOGLE_ADS_CONNECTION_FAILURE: "COMPLETE_GOOGLE_ADS_CONNECTION_FAILURE",
    SETUP_TIKTOK_CONNECTION_REQUEST: "SETUP_TIKTOK_CONNECTION_REQUEST",
    SETUP_TIKTOK_CONNECTION_SUCCESS: "SETUP_TIKTOK_CONNECTION_SUCCESS",
    SETUP_TIKTOK_CONNECTION_FAILURE: "SETUP_TIKTOK_CONNECTION_FAILURE",
    COMPLETE_TIKTOK_CONNECTION_REQUEST: "COMPLETE_TIKTOK_CONNECTION_REQUEST",
    COMPLETE_TIKTOK_CONNECTION_SUCCESS: "COMPLETE_TIKTOK_CONNECTION_SUCCESS",
    COMPLETE_TIKTOK_CONNECTION_FAILURE: "COMPLETE_TIKTOK_CONNECTION_FAILURE",
};
