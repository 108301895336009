import AppRoutes from "routes";
import allUrls from "variables/allUrls";

export const isLoggedInUserPermitted = (loggedInUserPermissions: string[] | undefined | null) => {
    // If permissions are undefined/null, deny access
    if (!loggedInUserPermissions) {
        return false;
    }

    const allowedRoutes = allUrls;
    const currentPath = window.location.pathname;

    // Find matching route and check permissions
    const isPermitted = allowedRoutes.some((route) => {
        // Check main route
        if (route.path === currentPath) {
            return (
                !route.permissions?.length ||
                route.permissions.some((permission) => loggedInUserPermissions.includes(permission))
            );
        }

        // Check child routes
        if (route.children) {
            return route.children.some((child) => {
                const fullPath = route.path + "/" + child.path;

                if (fullPath === currentPath) {
                    return (
                        !child.permissions?.length ||
                        child.permissions.some((permission) =>
                            loggedInUserPermissions.includes(permission)
                        )
                    );
                }
                return false;
            });
        }

        return false;
    });

    return isPermitted;
};

export const isUserPermitted = (
    loggedInUserPermissions: string[] | undefined | null,
    requiredPermissions: string[] | undefined | null
) => {
    // // If permissions are undefined/null, deny access
    if (!loggedInUserPermissions || !requiredPermissions) {
        return true;
    }

    // If no required permissions, allow access
    if (requiredPermissions?.length === 0) {
        return true;
    }

    // Check if user has any of the required permissions
    return requiredPermissions?.some((permission) => loggedInUserPermissions?.includes(permission));
};
