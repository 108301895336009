import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { adjustHeight } from "variables";
import ParentComment from "./ParentComment";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { workforceActions } from "redux/Ldb/actions";
import { sanitizeTaggedUserStringForSubmission } from "helpers/sanitizeTaggedUserString";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import useGetUserProfile from "hooks/useGetUserProfile";
import { getFromStorage } from "helpers";
import useGetCampaignCommentReplies from "hooks/workforceHooks/useGetCampaignCommentReplies";
import { useChannel } from "ably/react";
import { cloneDeep } from "lodash";

type CampaignCommentsProps = {
    parentComments: { [key: string]: any }[];
    setIsResolvedTab?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMentionTab?: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
    setActiveCommentSection?: React.Dispatch<React.SetStateAction<number>>;
    setMsg?: React.Dispatch<React.SetStateAction<string>>;
};

const CampaignComments = ({
    parentComments,
    setIsResolvedTab,
    setIsMentionTab,
    setActiveTab,
    setActiveCommentSection,
    setMsg,
}: CampaignCommentsProps) => {
    const { campaignId } = useParams();
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState("");
    const [commentId, setCommentId] = useState("");
    // const [activeTab, setActiveTab] = useState(0);
    // const [newComment, setNewComment] = useState<{ [key: string]: any }[]>([]);
    const {
        data: team,
        handleSearch,
        handleDebouncedChange,
        search,
        isFetching: isFetchingTeamList,
    } = useGetTeam();
    const containerRef = useRef(null);
    const userProfile = getFromStorage("ally-user");
    const { data: parentReplies } = useGetCampaignCommentReplies(commentId);
    const { fetchUserProfileSuccess: profile } = useAppSelector((state) => state.auth);
    const [allComments, setAllComments] = useState<{ [key: string]: any }[]>(() => [
        ...parentComments,
    ]);
    const [parentRepliesCopy, setParentRepliesCopy] = useState<{ [key: string]: any }>(() => ({}));

    const teamList = team?.map((item) => ({
        id: item?._id,
        display: item?.fullName || item?.firstName || item?.lastName || item?.email,
    }));

    // const allComments = [...newComment, ...parentComments];
    // const parentRepliesCopy = { ...parentReplies };
    // console.log("parentRepliesCopy", parentRepliesCopy, parentReplies);

    const {} = useChannel(`campaign_comments`, (commentData) => {
        console.log("commentData", commentData);
        if (commentData?.name === `comment:new:${profile?.parentId || profile?._id}`) {
            console.log("new comment data -->", commentData);

            setAllComments((prev) => [commentData?.data, ...prev]);
            setCommentId("");
            setActiveTab(1);
            setActiveCommentSection(null);
            setMsg("");
        }

        if (commentData?.name === `comment:updated:${profile?.parentId || profile?._id}`) {
            console.log("update comment data -->", commentData);

            const allCommentsCopy = cloneDeep([...allComments]);
            const index = allCommentsCopy.findIndex(
                (comment) => comment?._id === commentData?.data?.comment?._id
            );

            if (index !== -1) {
                allCommentsCopy[index].replyCount =
                    commentData?.data?.comment?.replies?.length || 0;
                allCommentsCopy[index].isResolved = commentData?.data?.comment?.isResolved || false;
                allCommentsCopy[index].replies = commentData?.data?.comment?.replies || [];
            }
            if (commentData?.data?.comment?.replies?.length > 0) {
                setParentRepliesCopy(commentData?.data?.comment);
            }
            setAllComments(allCommentsCopy);
            dispatch(workforceActions.resetUpdateCompaignCommentSuccess());
        }
    });
    const handleAddComment = (
        section: number,
        content: string,

        parentId?: string
    ) => {
        const taggedIds = sanitizeTaggedUserStringForSubmission(content)?.taggedIds;
        const msgToSubmit = sanitizeTaggedUserStringForSubmission(content)?.submissionString;

        const body: { [key: string]: any } = {
            content: msgToSubmit,
            // "taggedUsersId": [
            // "677feae9cea7f4a0a6aed36d"
            // ],
            // parentId: "67ba7b2df473b300bfcd76f4",
            section: section,
        };

        if (taggedIds?.length > 0) {
            body.taggedUsersId = taggedIds;
        }

        if (parentId) {
            body.parentId = parentId;
        }

        dispatch(workforceActions.createCampaignComment(campaignId, body));
    };

    useEffect(() => {
        if (parentComments?.length > 0) {
            setAllComments(parentComments);
        }
    }, [parentComments]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight; // Scroll to bottom
        }
    }, []); // Run when picker opens

    return (
        <div className="space-y-2 p-4 ">
            <div ref={containerRef} className="h-[67vh] overflow-y-auto space-y-2">
                {allComments?.map((comment) => {
                    return (
                        <ParentComment
                            key={comment?._id}
                            comment={comment}
                            handleReplyComment={handleAddComment}
                            teamList={teamList}
                            handleDebouncedChange={handleDebouncedChange}
                            userProfile={profile}
                            commentId={commentId}
                            setCommentId={setCommentId}
                            setParentRepliesCopy={setParentRepliesCopy}
                            parentReplies={
                                parentRepliesCopy?._id ? parentRepliesCopy : parentReplies
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CampaignComments;
