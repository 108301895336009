import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { truncate } from "lodash";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetSingleTask from "hooks/workforceHooks/useGetSingleTask";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import moment from "moment";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import useGetCustomerList from "hooks/salesHooks/useGetCustomerList";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import AddressInput from "components/common/InputField/AddressInput";
import { salesActions, workforceActions } from "redux/Ldb/actions";
import PageFrame from "components/layout/PageFrame";
import ModalContainer from "components/common/ModalContainer";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import DateInput from "components/common/DateInput";
import { Tooltip } from "react-tooltip";
import useGetSingleSalesOrder from "hooks/salesHooks/useGetSingleSalesOrder";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const EditOrderStep = ({}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { updatingTaskSalesOrder, updateTaskSalesOrderSuccess } = useAppSelector(
        (state) => state.workforce
    );
    const { updatingSalesOrder, updateSalesOrderSuccess } = useAppSelector((state) => state.sales);
    const { taskId, orderId } = useParams();
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/task" });
    const [products, setProducts] = useState<{ [key: string]: any }[]>([
        {
            productName: "",
            sku: "",
            inventoryId: "",
            inventory: {},
            unitPrice: "",
            quantity: "",
            currency: "",
            stockLevel: "",
            total: "",
            exists: false,
        },
    ]);
    const [orderDetails, setOrderDetails] = useState({
        customerName: "",
        customerEmail: "",
        customerAddress: "",
        customerPhone: "",
        currency: "USD",
        deliveryDate: "",
        orderDate: "",
        ext: "",
        phone: "",
    });
    const [address, setAddress] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [confirmSave, setConfirmSave] = useState<boolean>(false);
    const [showDeliveryDate, setShowDeliveryDate] = useState<boolean>(false);
    const [salesDate, setSalesDate] = useState<string>("");

    const { data: singleTask, isFetching: isFetchingTask } = useGetSingleTask();
    const { data: orderDetail, isFetching: isFetchingOrder } = useGetSingleSalesOrder();

    const currentStep = singleTask?.stepsRan?.find((step) => step?.key === "order_assistant");

    const { productSearchList, isFetching, handleDebouncedChangeTwo } = useGetProductList();
    const {
        data: customerData,
        isFetching: fetchingCustomers,
        handleDebounceSearch,
    } = useGetCustomerList();

    const totalCost = products
        ?.filter((item) => item?.exists)
        ?.reduce((acc, curr) => acc + Number(curr?.total), 0);

    const handleOrderDetailsChange = (name: string, value: string | boolean) => {
        setOrderDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAddProduct = () => {
        setProducts((prev) => [
            ...prev,
            {
                productName: "",
                sku: "",
                inventoryId: "",
                inventory: {},
                unitPrice: "",
                quantity: "",
                currency: "",
                stockLevel: "",
                total: "",
                exists: true,
            },
        ]);
    };

    const handleRemoveProduct = (index: number) => {
        setProducts((prevProductItems) => {
            const updatedItems = prevProductItems.filter((_, idx) => idx !== index);
            if (updatedItems.length === 0) {
                return [
                    {
                        productName: "",
                        sku: "",
                        inventoryId: "",
                        inventory: {},
                        unitPrice: "",
                        quantity: "",
                        currency: "",
                        stockLevel: "",
                        total: "",
                        exists: false,
                    },
                ];
            }

            return updatedItems;
        });
    };

    const handleSubmit = () => {
        const body = {
            stepId: currentStep?._id,
            state: {
                order_creation_approved: true,
                order_info: {
                    delivery_address: address?.deliveryAddress?.address,
                    products: products?.map((prod) => {
                        return {
                            quantity: prod?.quantity,
                            name: prod?.productName,
                            uom: (prod?.inventory as any)?.productStockDetails?.unitOfMeasurement,
                            exists: prod?.exists,
                            inventoryId: prod?.inventoryId,
                            inventory: prod?.inventory,
                        };
                    }),
                    order_summary: `Order from ${orderDetails?.customerName} for ${products
                        ?.map((product) => product?.productName)
                        ?.join(", ")}`,
                    delivery_date: orderDetails?.deliveryDate,
                    email: orderDetails?.customerEmail,
                    mobile: null,
                    company: orderDetails?.customerName,
                },
            },
        };

        dispatch(workforceActions?.updateTaskSalesOrder(body));
    };

    const handleUpdateOrder = () => {
        const body: { [key: string]: any } = {
            customerDetails: {
                name: orderDetails?.customerName,
                first_name: orderDetails?.customerName,
                last_name: orderDetails?.customerName,
                address1: address?.deliveryAddress?.address || orderDetails?.customerAddress,
                // phone: orderDetails?.customerPhone,
                email: orderDetails?.customerEmail,
                // city: orderDetails?.customerAddress,
                // zip: "10001",
                // province: "NY",
                // country: "USA",
                // address2: "Apt 4B",
                // company: "Acme Corp",
                // latitude: 40.7128,
                // longitude: -74.006,
                // country_code: "US",
                // province_code: "NY",
            },

            products: products?.map((item) => {
                return {
                    inventoryId: item?.inventoryId,
                    quantity: item?.quantity,
                    subtotal: {
                        amount: item?.total,
                        currency: item?.currency || "USD",
                    },
                    price: {
                        amount: item?.unitPrice,
                        currency: item?.currency || "USD",
                    },
                };
            }),
            total: orderDetail?.total || totalCost,
            currency: orderDetail?.currency || "USD",
        };

        if (salesDate) {
            body.saleDate = salesDate;
        }

        if (orderDetails?.deliveryDate) {
            body.deliveryDate = orderDetails?.deliveryDate;
        }

        if (orderDetails?.customerPhone) {
            body.customerDetails.phone = orderDetails?.customerPhone;
        }

        if (address?.deliveryAddress?.latitude) {
            body.customerDetails.latitude = address?.deliveryAddress?.latitude;
        }

        if (address?.deliveryAddress?.longitude) {
            body.customerDetails.longitude = address?.deliveryAddress?.longitude;
        }

        if (address?.deliveryAddress?.country) {
            body.customerDetails.country = address?.deliveryAddress?.country;
        }

        if (address?.deliveryAddress?.city) {
            body.customerDetails.city = address?.deliveryAddress?.city;
        }

        if (address?.deliveryAddress?.zip) {
            body.customerDetails.zip = address?.deliveryAddress?.zip;
        }

        dispatch(salesActions?.updateSalesOrder(orderId, body));
    };

    const tableHeader = [
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Product SKU/Name</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[40%]",
        },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span className="text-xs">Qty</span>
                    <span className="text-[10px] text-p-50 px-1.5 flex items-center justify-center h-[20px] border border-p-50 rounded-xl">
                        Editable
                    </span>
                </div>
            ),
            widthClass: "w-[14.1%]",
        },
        { title: "Unit price", widthClass: "w-[10.7%]" },
        { title: "Total", widthClass: "w-[14.4%]" },
        { title: "Available stock level", widthClass: "w-[15%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const tableBody = products
        ?.filter((item) => item?.exists)
        ?.map((item, idx) => {
            return [
                {
                    content: (
                        <div
                            className={` pr-3 flex space-x-2 items-center h-full w-full border-slate-100 text-sm font-rocGroteskMedium`}
                        >
                            {item?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        item?.inventory?.productImageDetails?.productAvatar ||
                                        item?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={item?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div className="w-full">
                                <div className="pt-1.5">
                                    <a id={`sku-${idx}`}>
                                        <p className="text-slate-500 text-xs whitespace-nowrap font-rocGroteskMedium pl-4 ">
                                            {truncate(item?.sku, { length: 30 })}
                                        </p>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#sku-${idx}`}
                                        place={"top"}
                                        content={item?.sku}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#142837",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#FFFFFF",
                                            boxShadow: "0px 4px 16px 0px rgba(16, 24, 40, 0.03)",
                                        }}
                                    />
                                </div>

                                <SelectInput
                                    value={item?.productName || item?.inventoryId}
                                    name="inventoryId"
                                    inputPlaceholder={"Select"}
                                    handleChange={(name, value) => {
                                        if (value) {
                                            setProducts((prevSelectedItems) => {
                                                const updatedItems = [...prevSelectedItems];
                                                const selectedItem = productSearchList.find(
                                                    (item) => item._id === value
                                                );

                                                if (selectedItem) {
                                                    updatedItems[idx] = {
                                                        ...updatedItems[idx],
                                                        unitPrice: selectedItem?.salesPrice?.amount,
                                                        productName: selectedItem.productName,
                                                        sku: selectedItem.sku,
                                                        inventoryId: selectedItem._id,
                                                        inventory: selectedItem,
                                                        exists: true,
                                                        total: String(
                                                            Number(
                                                                selectedItem?.salesPrice?.amount
                                                            ) * Number(item?.quantity)
                                                        ),
                                                        stockLevel:
                                                            selectedItem?.productStockDetails
                                                                ?.stockLevel,
                                                    };
                                                }

                                                return updatedItems;
                                            });
                                        }
                                    }}
                                    handleInputChange={handleDebouncedChangeTwo}
                                    isRequired={true}
                                    idx={idx}
                                    className="!border-0 !rounded-none !py-0 !h-8"
                                    inputClassName={"!font-rocGroteskMedium"}
                                    inputContainerClassName="!mb-0"
                                    searchLoading={isFetching}
                                    clearValue={true}
                                    dropdownOptions={productSearchList?.map((item) => ({
                                        label: (
                                            <div className="flex gap-3 items-center">
                                                <div className="flex gap flex-col">
                                                    <p className="font-rocGroteskMedium">
                                                        {item?.productName}
                                                    </p>
                                                </div>
                                            </div>
                                        ),
                                        value: `${item?._id}`,
                                    }))}
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    content: (
                        <div
                            className={` pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                        >
                            <TextInput
                                value={item?.quantity}
                                name={"qty"}
                                type={"text"}
                                required={true}
                                onChange={(e) => {
                                    setProducts((prevSelectedItems) => {
                                        const updatedItems = [...prevSelectedItems];

                                        updatedItems[idx] = {
                                            ...updatedItems[idx],
                                            quantity: e.target.value,
                                            total: String(
                                                Number(updatedItems[idx]?.unitPrice) *
                                                    Number(e.target.value)
                                            ),
                                        };

                                        return updatedItems;
                                    });
                                }}
                                inputPlaceholder={"Enter Qty"}
                                inputContainerClassname="!border-none"
                            />
                        </div>
                    ),
                    tableDataDivClass: "!pl-0",
                },
                {
                    content: (
                        <div
                            className={` pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                        >
                            {getCurrencyFromCurrencyCode(item?.currency)}
                            {formatMoney().format(Number(item?.unitPrice)?.toFixed(2))}
                        </div>
                    ),
                },
                {
                    content: (
                        <div
                            className={` pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                        >
                            {getCurrencyFromCurrencyCode(item?.currency)}
                            {formatMoney().format(
                                (Number(item?.unitPrice) * Number(item?.quantity))?.toFixed(2)
                            )}
                        </div>
                    ),
                },
                {
                    content: (
                        <div
                            className={` pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                        >
                            {item?.stockLevel && item?.stockLevel !== "N/A"
                                ? formatMoney().format(Number(item?.stockLevel)?.toFixed(2))
                                : "N/A"}
                        </div>
                    ),
                },
                {
                    content: (
                        <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                            {idx > 0 && (
                                <i
                                    onClick={() => handleRemoveProduct(idx)}
                                    className="ri-delete-bin-5-line cursor-pointer"
                                ></i>
                            )}
                        </div>
                    ),
                    tableDataDivClass: "!pl-3",
                },
            ];
        });

    useEffect(() => {
        if (Boolean(singleTask) && taskId) {
            const editableStep = singleTask?.state;

            const parsedProducts = editableStep?.order_info?.products?.map((item) => {
                return {
                    productName: item?.name,
                    sku: item?.inventory?.sku,
                    inventoryId: item?.inventoryId,
                    inventory: item?.inventory,
                    unitPrice: item?.inventory?.unitCost?.amount,
                    quantity: item?.quantity,
                    currency: item?.inventory?.unitCost?.currency,
                    stockLevel: item?.inventory?.productStockDetails?.stockLevel || "N/A",
                    total: item?.inventory?.unitCost?.amount * item?.quantity,
                    exists: item?.exists,
                };
            });

            setProducts(parsedProducts);
            setAddress({
                deliveryAddress: { address: editableStep?.order_info?.delivery_address },
            });
            setOrderDetails({
                customerName: editableStep?.order_info?.company,
                customerEmail: editableStep?.order_info?.email,
                customerAddress: editableStep?.order_info?.delivery_address,
                customerPhone: editableStep?.order_info,
                currency: "USD",
                deliveryDate: moment(editableStep?.order_info?.delivery_date, "DD/MM/YYYY")?.format(
                    "DD MMM, YYYY"
                ),
                orderDate: "",
                ext: "",
                phone: "",
            });
        }

        if (Boolean(orderDetail) && orderId) {
            console.log("orderDetail", orderDetail);
            // setProducts(orderDetail?.products);
            const parsedProducts = orderDetail?.products?.map((item) => {
                return {
                    productName: item?.productName,
                    sku: item?.sku,
                    inventoryId: item?.inventoryId,
                    inventory: item?.inventory,
                    unitPrice: item?.unitPrice?.amount,
                    quantity: item?.qty,
                    currency: item?.unitPrice?.currency,
                    stockLevel: item?.stockLevel || "",
                    total: item?.total,
                    exists: true,
                };
            });

            setProducts(parsedProducts);
            setSalesDate(orderDetail?.saleDate);
            setAddress({
                deliveryAddress: { address: orderDetail?.customerDetails?.address1 },
            });
            setOrderDetails({
                customerName:
                    orderDetail?.customerDetails?.company ||
                    (orderDetail?.customerDetails?.first_name || "") +
                        " " +
                        (orderDetail?.customerDetails?.last_name || ""),
                customerEmail: orderDetail?.customerDetails?.email,
                customerAddress: orderDetail?.customerDetails?.address1,
                customerPhone: orderDetail?.customerDetails?.phone,
                currency: orderDetail?.currency,

                deliveryDate: orderDetail?.deliveryDate || "",
                orderDate: "",
                ext: "",
                phone: "",
            });
        }
    }, [singleTask, taskId, isFetchingTask, orderDetail, orderId]);

    useEffect(() => {
        if (Boolean(updateTaskSalesOrderSuccess)) {
            setConfirmSave(false);
            toast.custom((t) => (
                <CustomToast t={t} message={"Update successful"} type={"success"} />
            ));
            dispatch(workforceActions.resetUpdateTaskSalesOrderSuccess());
            navigate(`/dashboard/task/${taskId}`, {
                state: { internalNavigation: true },
            });
        }
    }, [dispatch, updateTaskSalesOrderSuccess]);

    useEffect(() => {
        if (Boolean(updateSalesOrderSuccess)) {
            setConfirmSave(false);
            toast.custom((t) => (
                <CustomToast t={t} message={"Update successful"} type={"success"} />
            ));
            dispatch(salesActions.resetUpdateSalesOrderSuccess());
            goBack();
        }
    }, [dispatch, updateSalesOrderSuccess]);

    return (
        <PageFrame>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setConfirmSave(true);
                }}
                className="p-4 border border-slate-100 rounded-[8px] bg-white"
            >
                <div
                    onClick={() => goBack()}
                    className="cursor-pointer text-[#3B4C58] space-x-3 w-fit flex items-center py-4"
                >
                    <i className="ri-arrow-left-line text-sm "></i>
                    <p className="text-sm underline  font-rocGroteskMedium">Back</p>
                </div>
                <div className="mb-6 flex justify-between items-center py-4 border-b border-slate-200">
                    <p className="text-[22px] leading-[33px] text-gm-50 font-rocGroteskMedium">
                        Sales order details
                    </p>

                    <div className="flex items-center space-x-3">
                        <Button
                            onClick={() => goBack()}
                            btnClassname="!py-2.5 !px-4 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !w-fit !h-10"
                            btnText="Cancel"
                        />
                        <Button
                            // onClick={onClickTwo}
                            btnPermission={[
                                AccountPermissions.all_access,
                                AccountPermissions.create_sales_order,
                                AccountPermissions.update_sales_order,
                            ]}
                            type="submit"
                            btnClassname="!py-2.5 !px-8 !w-fit !h-10"
                            btnText="Save order"
                        />
                    </div>
                </div>

                <div className=" flex space-x-3 ">
                    <div className="w-[74%]  pb-4">
                        <div className="flex items-center justify-between mb-3">
                            <div>
                                <span className="block text-base font-rocGroteskMedium mb-1">
                                    Details
                                </span>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 border px-4 rounded items-center justify-between py-6">
                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Channel
                                </span>
                                <div className="flex items-center space-x-1.5">
                                    {displayProductChannelIcon(
                                        (orderId ? orderDetail?.channel : singleTask?.source)
                                            ?.toLowerCase()
                                            ?.includes("microsoft")
                                            ? "microsoft"
                                            : (orderId ? orderDetail?.channel : singleTask?.source)
                                                  ?.toLowerCase()
                                                  ?.includes("gmail")
                                            ? "gmail"
                                            : (orderId ? orderDetail?.channel : singleTask?.source)
                                                  ?.toLowerCase()
                                                  ?.includes("shopify")
                                            ? "shopify"
                                            : "synth",
                                        "!w-5 !h-5"
                                    )}
                                    <span className="block text-base font-rocGroteskMedium capitalize">
                                        {(orderId ? orderDetail?.channel : singleTask?.source)
                                            ?.toLowerCase()
                                            ?.includes("microsoft")
                                            ? "microsoft"
                                            : (orderId ? orderDetail?.channel : singleTask?.source)
                                                  ?.toLowerCase()
                                                  ?.includes("gmail")
                                            ? "gmail"
                                            : (orderId ? orderDetail?.channel : singleTask?.source)
                                                  ?.toLowerCase()
                                                  ?.includes("shopify")
                                            ? "shopify"
                                            : "synth"}
                                    </span>
                                </div>
                            </div>

                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Order date
                                </span>
                                <span className="block text-base font-rocGroteskMedium">
                                    {moment(currentStep?.createdAt)?.format("MMM DD, YYYY")}
                                </span>
                            </div>

                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Delivery date
                                </span>
                                <span className="block text-base font-rocGroteskMedium">
                                    {singleTask?.state?.order_info?.delivery_date || "N/A"}
                                </span>
                            </div>
                        </div>

                        <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                        <div className="">
                            <div className="flex items-center justify-between mb-4">
                                <span className="block text-base font-rocGroteskMedium ">
                                    Product details
                                </span>
                            </div>

                            {tableBody?.length > 0 ? (
                                <div className="mb-4">
                                    <div>
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isAllSelectable={false}
                                            isCellBordered={true}
                                            scrollWithin={true}
                                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                                            tableClass="!w-fill-available"
                                            tableContainerClass="pb-[280px] mb-[-280px]"
                                        />
                                    </div>

                                    <div className="mt-4 pt-3 flex items-center justify-between">
                                        <div
                                            onClick={handleAddProduct}
                                            className="w-fit cursor-pointer flex items-center space-x-3"
                                        >
                                            <i className="ri-add-circle-line text-[#6562E5]"></i>
                                            <span className="font-rocGroteskMedium text-sm text-[#6562E5] underline">
                                                Add more
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex justify-center my-5">
                                    <p className="text-xl">
                                        No product(s) available in your your inventory
                                    </p>
                                </div>
                            )}
                            {tableBody?.length > 0 && (
                                <div>
                                    <p className="text-sm mb-4 font-rocGroteskMedium text-[#64748B]">
                                        Breakdown
                                    </p>
                                    <div className="space-y-6 bg-[#F8FAFC] p-4 rounded items-center justify-between">
                                        {/* <div className="flex items-center justify-between w-full">
                                    <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                        Sub total
                                    </span>
                                    <div>
                                        <img src="" className="max-h-[20px] max-w-[20px]" alt="" />
                                        <span className="block text-base font-rocGroteskMedium">
                                            {subTotal}
                                        </span>
                                    </div>
                                </div> */}

                                        <div className="flex items-center justify-between w-full">
                                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                                Total
                                            </span>
                                            <span className="block text-base font-rocGroteskMedium">
                                                {formatMoney().format(totalCost)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    </div>

                    <div className="w-[26%]  border border-[#E2E8F0] p-4">
                        <div className="mb-4">
                            <span className="text-base text-gm-50 block font-rocGroteskMedium mb-3">
                                Customer details
                            </span>
                            <div className="space-y-2">
                                <SelectInput
                                    value={orderDetails?.customerName}
                                    name="customer"
                                    placeholder={"Customer name"}
                                    handleChange={(name, value) => {
                                        if (value?.startsWith("{")) {
                                            const parsedValue = JSON.parse(value || "");

                                            setOrderDetails((prev) => {
                                                return {
                                                    ...prev,
                                                    customerName:
                                                        parsedValue?.company ||
                                                        parsedValue?.name ||
                                                        `${parsedValue?.firstName} ${parsedValue?.lastName}`,
                                                    customerEmail: parsedValue?.email,
                                                    customerAddress: parsedValue?.address1,
                                                    customerPhone: parsedValue?.phone,
                                                };
                                            });
                                        }
                                    }}
                                    handleInputChange={handleDebounceSearch}
                                    isRequired={false}
                                    searchLoading={fetchingCustomers}
                                    dropdownOptions={customerData
                                        ?.filter((item) => item?.email)
                                        ?.map((customer) => {
                                            return {
                                                label: `${
                                                    customer?.name ||
                                                    customer?.firstName + " " + customer?.lastName
                                                } - ${customer?.email}`,
                                                value: JSON.stringify(customer || {}),
                                            };
                                        })}
                                />

                                <TextInput
                                    value={orderDetails?.customerName}
                                    name={"customerName"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) =>
                                        handleOrderDetailsChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Customer name"}
                                    containerClassname="w-full "
                                    inputContainerClassname={" "}
                                    inputClassName={`w-full`}
                                />

                                <TextInput
                                    value={orderDetails?.customerEmail}
                                    name={"customerEmail"}
                                    type={"email"}
                                    required={true}
                                    onChange={(e) =>
                                        handleOrderDetailsChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Customer email"}
                                    containerClassname="w-full "
                                    inputContainerClassname={" "}
                                    inputClassName={`w-full`}
                                />

                                <DateInput
                                    label="Delivery date"
                                    value={
                                        typeof orderDetails?.deliveryDate === "string" &&
                                        orderDetails?.deliveryDate?.includes("/")
                                            ? moment(
                                                  orderDetails?.deliveryDate,
                                                  "DD/MM/YYYY"
                                              )?.format()
                                            : orderDetails?.deliveryDate
                                    }
                                    onChange={(newDate) =>
                                        handleOrderDetailsChange("deliveryDate", newDate as string)
                                    }
                                    showCalendar={showDeliveryDate}
                                    calendarClassname={"!right-0"}
                                    required={true}
                                    onCalendarToggle={setShowDeliveryDate}
                                />

                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.deliveryAddress
                                            ? address?.deliveryAddress?.address
                                            : ""
                                    }
                                    placeholder="Delivery address"
                                    required={true}
                                    name={"deliveryAddress"}
                                />
                            </div>
                        </div>

                        {/* <div className="">
                        <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1.5">
                            Shipping destination
                        </span>
                        <span className="text-[15px]  text-gm-50 font-rocGroteskMedium">
                            {billAddress}
                        </span>
                    </div> */}

                        <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                    </div>
                </div>
            </form>

            <ModalContainer
                open={confirmSave}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setConfirmSave(false)}
                tailwindClassName="h-[371px] w-[572px] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setConfirmSave(false)}
                            className="ri-close-fill text-gm-45 text-[30px] cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to save, saving will update{" "}
                                {taskId ? "and create" : ""} this sale order?
                            </h6>
                        </div>
                        <div className="mt-5 mb-3 space-x-2 flex items-center">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setConfirmSave(false)}
                                disabled={updatingTaskSalesOrder || updatingSalesOrder}
                            />
                            <Button
                                btnText={"Yes, save changes"}
                                type={"button"}
                                btnPermission={[
                                    AccountPermissions.all_access,
                                    AccountPermissions.create_sales_order,
                                    AccountPermissions.update_sales_order,
                                ]}
                                btnClassname="!py-3 !bg-[#DCFCE7] border border-[#16A34A] !text-dark-gray-2 !px-4"
                                onClick={taskId ? handleSubmit : handleUpdateOrder}
                                isLoading={updatingTaskSalesOrder || updatingSalesOrder}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default EditOrderStep;
