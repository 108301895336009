import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { debounce, uniqBy } from "lodash";

const useGetAiNotifications = (allowInfiniteScroll = true) => {
    const dispatch = useAppDispatch();

    const [allAiNotifications, setAllAiNotifications] = useState<{ [key: string]: any }[]>([]);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { fetchingAiNotifications, fetchAiNotificationsSuccess, fetchAiNotificationsFailure } =
        useAppSelector((state) => state.workforce);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            10
        );

    const handleDebouncedSearch = debounce((value: string) => {
        setDebouncedSearch(value);
        setUpdatedPage(1);
    }, 500);

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    useEffect(() => {
        dispatch(workforceActions.getAiNotifications(updatedPage, debouncedSearch));
    }, [dispatch, debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchAiNotificationsSuccess)) {
            console.log("fetchAiNotificationsSuccess", fetchAiNotificationsSuccess);

            setAllAiNotifications((prev) =>
                updatedPage === 1 && allowInfiniteScroll
                    ? uniqBy([...fetchAiNotificationsSuccess?.data], "_id")
                    : uniqBy([...prev, ...fetchAiNotificationsSuccess?.data], "_id")
            );

            setPagination({
                currentPage: fetchAiNotificationsSuccess.pagination?.current,
                noOfPages: fetchAiNotificationsSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchAiNotificationsSuccess, allowInfiniteScroll]);

    return {
        isFetching: fetchingAiNotifications,
        error: fetchAiNotificationsFailure,
        data: allAiNotifications,
        isLoadingMore,
        pagination,
        setUpdatedPage,
        setLoadMore,
        setDebouncedSearch,
        handleSearch,
        handleDebouncedSearch,
        search,
    };
};

export default useGetAiNotifications;
