import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";

const useGetCampaignErrors = () => {
    const dispatch = useAppDispatch();

    const { fetchingCampaignErrors, fetchCampaignErrorsSuccess, fetchCampaignErrorsFailure } =
        useAppSelector((state) => state.workforce);
    const { fetchBrandTokenSuccess } = useAppSelector((state) => state.multibrand);

    const [campaignErrors, setCampaignErrors] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        dispatch(workforceActions.getCampaignErrors());
    }, [dispatch, fetchBrandTokenSuccess]);

    useEffect(() => {
        if (Boolean(fetchCampaignErrorsSuccess)) {
            console.log("fetchCampaignErrorsSuccess", fetchCampaignErrorsSuccess);
            setCampaignErrors(fetchCampaignErrorsSuccess?.tasks);
        }
    }, [fetchCampaignErrorsSuccess]);

    return {
        isFetching: fetchingCampaignErrors,
        error: fetchCampaignErrorsFailure,
        data: campaignErrors,
        setCampaignErrors,
    };
};

export default useGetCampaignErrors;
