import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageFrame from "../../../components/layout/PageFrame";
import Button from "../../../components/common/Button";
import TextareaInput from "components/common/InputField/TextareaInput";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import { displayFileIcon } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "components/common/Loader";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import TextInput from "components/common/InputField/TextInput";
import CustomTable from "components/common/CustomTable";
import ModalContainer from "components/common/ModalContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { taskActions } from "redux/Ldb/actions";
import SelectInput from "components/common/InputField/SelectInput";
import Dropdown from "components/common/Dropdown";
import moment from "moment";
import { AccountPermissions } from "types/permissions";
import { useNavigationHandler } from "hooks/useNavigationHandler";

const TaskDetailWorkOrder = () => {
    const navigate = useNavigate();
    const { taskId } = useParams();
    const dispatch = useAppDispatch();
    const [profile] = useGetUserProfile();
    const [notes, setNotes] = useState<string>("");
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [uploadingCustomAttachment, setUploadingCustomAttachment] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<any>([]);
    const [deleteIdx, setDeleteIdx] = useState<number>(-1);
    const [qcData, setQcData] = useState<{ [key: string]: any }[]>([{}]);
    const [qcFailedModal, setQcFailedModal] = useState<boolean>(false);
    const [qcPassModal, setQcPassModal] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const [task, setTask] = useState<{ [key: string]: any }>({});
    const [qcIdx, setQcIdx] = useState<number>(-1);
    const { goBack } = useNavigationHandler({ fallbackPath: "/dashboard/task" });

    const {
        updatingHumanTask,
        updateHumanTaskSuccess,
        completingHumanTask,
        completeHumanTaskSuccess,
        fetchingSingleHumanTask,
        fetchSingleHumanTaskSuccess,
    } = useAppSelector((state) => state.task);

    useEffect(() => {
        dispatch(taskActions?.fetchSingleHumanTask(taskId));
    }, [dispatch, taskId]);

    useEffect(() => {
        if (Boolean(fetchSingleHumanTaskSuccess)) {
            setTask(fetchSingleHumanTaskSuccess?.task);
        }
    }, [fetchSingleHumanTaskSuccess]);

    useEffect(() => {
        setQcData((prev) => [
            {
                ...prev,
                task: task?.task,
                defect: task?.defect,
                quantity: task?.quantity,
                correction: task?.correction,
                status: task?.status,
            },
        ]);
        setAttachments(task?.attachments?.length > 0 ? task?.attachments : []);
    }, [task]);

    const handleQcData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...qcData];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setQcData(copyArr);
    };

    const deleteAttachment = (idx) => {
        const newArr = [...attachments];

        const imagePath = decodeURIComponent(
            attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachments(newArr);
        });
        setDeletingImage(false);
    };

    const handleAttachmentUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingCustomAttachment(true);

            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachments((prev) => [...prev, { path: url, filename: fileName }]);

                    setDeleteIdx(-1);
                    setUploadingCustomAttachment(false);
                }
            );
        }
    };

    const handleUpdateHumanTask = () => {
        const body = {
            attachments:
                attachments?.length >= 1
                    ? attachments?.map((item) => {
                          return {
                              path: item?.path,
                              filename: item?.filename,
                          };
                      })
                    : [],
            ...(notes && { notes }),
            ...(qcData[0]?.defect && { defect: qcData[0]?.defect }),
            ...(qcData[0]?.quantity && { quantity: qcData[0]?.quantity }),
            ...(qcData[0]?.correction && { correction: qcData[0]?.correction }),
            ...(qcData[0]?.status && { status: qcData[0]?.status }),
            ...(qcData[0]?.status === "failed" && { qcNotes: comment }),
        };
        dispatch(taskActions.updateHumanTask(body, taskId));
    };
    useEffect(() => {
        if (Boolean(updateHumanTaskSuccess)) {
            dispatch(taskActions.resetUpdateHumanTask());
            navigate(`/dashboard/task?taskType=human_task`, {
                state: { internalNavigation: true },
            });
        }
    }, [updateHumanTaskSuccess]);

    useEffect(() => {
        if (Boolean(completeHumanTaskSuccess)) {
            dispatch(taskActions.resetCompleteHumanTask());
        }
    }, [completeHumanTaskSuccess]);

    const QCTableHeader = [
        { title: "Task", widthClass: "w-[25%] !font-rocGroteskMedium border-slate-200" },
        {
            title: "Defect/Issue detected",
            widthClass: "w-[25%] !font-rocGroteskMedium border-slate-200",
        },
        { title: "Quantity", widthClass: "w-[10%] !font-rocGroteskMedium border-slate-200" },
        {
            title: "Corrective action/ Next step",
            widthClass: "w-[25%] !font-rocGroteskMedium border-slate-200",
        },
        { title: "Status", widthClass: "w-[15%] !font-rocGroteskMedium border-slate-200" },
    ];

    const QCTableBody = qcData?.map((item, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full text-sm text-slate-900 px-2.5`}>
                        <p className="">{item?.task}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0 !border-slate-200",

                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`w-full text-sm text-slate-900 `}>
                        {task?.isCompleted ? (
                            <p
                                className={`px-2.5 ${
                                    item?.defect ? "text-[#E4281D]" : "text-slate-500"
                                } `}
                            >
                                {" "}
                                {item?.defect || "No defect detected"}
                            </p>
                        ) : (
                            <TextInput
                                value={item?.defect || ""}
                                name="defect"
                                type="text"
                                onChange={(e) => handleQcData(e, idx)}
                                inputPlaceholder="Enter any defect"
                                containerClassname=""
                                inputClassName="w-full"
                                inputContainerClassname={"!border-0 !rounded-none "}
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            />
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },

            {
                content: (
                    <div className={`w-full  text-sm text-slate-900 `}>
                        {task?.isCompleted ? (
                            <p
                                className={`px-2.5 ${
                                    item?.quantity?.amount ? "text-[#E4281D]" : "text-slate-500"
                                } `}
                            >
                                {item?.quantity?.amount || 0}
                            </p>
                        ) : (
                            <TextInput
                                value={item?.quantity || ""}
                                name="quantity"
                                type="number"
                                onChange={(e) => handleQcData(e, idx)}
                                inputPlaceholder="e.g 30"
                                containerClassname=""
                                inputClassName="w-full"
                                inputContainerClassname={"!border-0 !rounded-none "}
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                            />
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },

            {
                content: (
                    <div className={`w-full  text-sm text-slate-900 `}>
                        {task?.isCompleted ? (
                            <p
                                className={`px-2.5 ${
                                    item?.correction ? "text-slate-900" : "text-slate-500"
                                } `}
                            >
                                {item?.correction || "No corrective action to take"}
                            </p>
                        ) : (
                            <TextInput
                                value={item?.correction || ""}
                                name="correction"
                                type="text"
                                onChange={(e) => handleQcData(e, idx)}
                                inputPlaceholder=""
                                containerClassname=""
                                inputClassName="w-full"
                                inputContainerClassname={"!border-0 !rounded-none "}
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            />
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`w-full text-sm text-slate-900 !font-rocGroteskMedium px-2.5`}>
                        {task?.isCompleted ? (
                            <div className="flex items-center gap-2 py-4">
                                <div
                                    className={` ${
                                        item?.status === "passed"
                                            ? "bg-[#00DB8F] text-slate-900"
                                            : item?.status === "failed" &&
                                              "bg-[#FFE9E9] text-[#FF0004]"
                                    }  w-[20px] h-[20px] flex items-center justify-center rounded-full`}
                                >
                                    <i
                                        className={` ${
                                            item?.status === "passed"
                                                ? "ri-checkbox-circle-fill text-[#fff]"
                                                : item?.status === "failed" &&
                                                  "ri-close-fill text-[#FF0004]"
                                        }   text-xs`}
                                    ></i>
                                </div>
                                <p
                                    className={` ${
                                        item?.status === "passed"
                                            ? "text-slate-900"
                                            : item?.status === "failed" && "text-[#FF0004]"
                                    } `}
                                >
                                    {item?.status === "passed"
                                        ? "QC PASS"
                                        : item?.status === "failed" && "QC FAILED"}
                                </p>
                            </div>
                        ) : (
                            <SelectInput
                                value={item?.status || ""}
                                name="status"
                                handleChange={(name, value) => {
                                    handleQcData({ target: { name, value } }, idx);
                                    setQcIdx(idx);
                                    if (value === "failed") {
                                        setQcFailedModal(true);
                                    }
                                }}
                                dropdownOptions={[
                                    {
                                        label: "QC PASS",
                                        value: "passed",
                                        icon: (
                                            <div className="bg-[#00DB8F] w-[20px] h-[20px] flex items-center justify-center rounded-full">
                                                <i className="ri-checkbox-circle-fill text-[#fff] text-xs"></i>
                                            </div>
                                        ),
                                    },
                                    {
                                        label: "QC PENDING",
                                        value: "pending",
                                        icon: (
                                            <div className="bg-[#FEF3C7] w-[20px] h-[20px] flex items-center justify-center rounded-full">
                                                <i className="ri-time-line text-[#D97706] text-xs"></i>
                                            </div>
                                        ),
                                    },
                                    {
                                        label: "QC FAILED",
                                        value: "failed",
                                        icon: (
                                            <div className="bg-[#FFE9E9] w-[20px] h-[20px] flex items-center justify-center rounded-full">
                                                <i className="ri-close-fill text-[#FF0004] text-xs"></i>
                                            </div>
                                        ),
                                    },
                                ]}
                                className="!border-none !pl-0"
                                inputClassName=" !text-xs !text-slate-900 !font-rocGroteskMedium"
                                optionItemClassName="!text-xs !text-slate-900 !font-rocGroteskMedium"
                                dropdownClassName=" !max-h-[150px]"
                                isRequired={true}
                                clearValue={true}
                                disabled={task?.isCompleted}
                            />
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });

    return (
        <PageFrame isLoading={fetchingSingleHumanTask}>
            <div className="bg-white rounded-lg border border-n-20 relative font-rocGroteskMedium">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[1000]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => goBack()}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">QC task overview</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => goBack()}
                        />
                        {!task?.isCompleted && (
                            <Button
                                btnText="Mark as done"
                                btnClassname="!px-4 !py-2 !w-fit"
                                disabled={qcData[0]?.status === "pending"}
                                onClick={() => setQcPassModal(true)}
                                isLoading={updatingHumanTask || completingHumanTask}
                            />
                        )}
                    </div>
                </div>

                <div className="px-8">
                    <div className="py-6 border-b border-slate-200">
                        <p className="text-gm-50 text-sm mb-1">Work Order ID</p>
                        <div className="flex items-center">
                            <h6 className="text-[#6562E5]  text-underline text-2xl">
                                {task?.workOrderTaId && `WO-${task?.workOrderTaId}`}
                            </h6>
                            <i className="ri- text-[#6562E5] text-[17px]"></i>
                        </div>
                    </div>

                    <div className=" ">
                        <div className="grid grid-cols-4 py-6 border-b border-slate-200">
                            <div className="">
                                <p className="text-gm-50 text-sm">Created by</p>
                                <p className="text-slate-500 text-sm">
                                    {task?.createdBy?.fullName || task?.createdBy?.email}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-gm-50 text-sm">Assignee</p>
                                <p className="text-slate-500 text-sm">
                                    {task?.assignee?.fullName || task?.assignee?.email}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-gm-50 text-sm">Start Date</p>
                                <p className="text-slate-500 text-sm">
                                    {task?.startDate
                                        ? moment(task?.startDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-gm-50 text-sm">End date</p>
                                <p className="text-slate-500 text-sm">
                                    {task?.endDate
                                        ? moment(task?.endDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="!mt-8 ">
                        <p className="text-base text-[#334155] !mb-4">Quality control checklists</p>
                        <CustomTable
                            tableBody={QCTableBody}
                            tableHeader={QCTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200 "
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tableClass="!border-slate-200"
                            bodyItemClass="hover:!bg-transparent !border-slate-200"
                        />
                    </div>

                    <div className="pb-5 pt-6">
                        {!task?.isCompleted ? (
                            <div className="">
                                <p className="text-gm-50 text-base  pb-3">Attachments</p>

                                {attachments?.length > 0 ? (
                                    <div className="mt-4 ">
                                        {attachments?.map((item, idx) => {
                                            return (
                                                <div
                                                    className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                                                    key={idx}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {displayFileIcon(
                                                            getFileTypeFromUrl(item?.path),
                                                            false,
                                                            "!w-[28px] !h-[28px]"
                                                        )}
                                                        <div className="font-rocGroteskMedium">
                                                            <p className="text-sm">
                                                                {item?.filename}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {deletingImage && idx === deleteIdx ? (
                                                        <Loader size={3} />
                                                    ) : (
                                                        <i
                                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                            onClick={() => {
                                                                deleteAttachment(idx);
                                                                setDeleteIdx(idx);
                                                            }}
                                                        ></i>
                                                    )}
                                                </div>
                                            );
                                        })}

                                        <label
                                            className={`flex items-center gap-4 py-3 px-6 rounded border border-slate-200  ${
                                                !uploadingCustomAttachment && "cursor-pointer"
                                            }`}
                                            htmlFor="customAttachment"
                                        >
                                            <div className="flex items-center justify-start gap-4">
                                                <i className="ri-upload-cloud-line text-[#142837] text-[22px]"></i>
                                                <div className="">
                                                    {uploadingCustomAttachment ? (
                                                        <div className="flex justify-start items-start w-fit">
                                                            <Loader size={4} />
                                                        </div>
                                                    ) : (
                                                        <p className="font-rocGroteskMedium">
                                                            <span className="underline text-sm text-gm-50">
                                                                Click to upload document
                                                            </span>
                                                        </p>
                                                    )}

                                                    <p className="lowercase text-[13px] text-tradeally-neutral-200  font-rocGroteskMedium">
                                                        .JPEG .PDF .CSV .DOCX | File size limit: 5MB
                                                    </p>
                                                </div>
                                            </div>
                                        </label>

                                        <input
                                            type="file"
                                            accept=".pdf,.doc,.docx,.txt, image/*"
                                            className={`top-0 w-full h-full outline-none opacity-0 ${
                                                !uploadingCustomAttachment && "cursor-pointer"
                                            }`}
                                            id="customAttachment"
                                            disabled={uploadingCustomAttachment}
                                            onChange={(evt) => {
                                                if (
                                                    !isFileSizeLessThanOrEqualTo(
                                                        evt.target.files?.[0],
                                                        5
                                                    )
                                                ) {
                                                    toast.custom((t) => (
                                                        <CustomToast
                                                            t={t}
                                                            message={"File too large"}
                                                            type={"error"}
                                                        />
                                                    ));
                                                    return;
                                                }

                                                if (evt.target.files?.[0]) {
                                                    handleAttachmentUpload(
                                                        evt.target.files?.[0].name,
                                                        evt.target.files?.[0]
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={`mb-4`}>
                                        <div className="relative">
                                            <div
                                                className="flex flex-col cursor-pointer justify-center mb-4 py-4 
                                    text-sm rounded border border-slate-200 px-6"
                                            >
                                                <div className="flex items-center justify-start gap-4">
                                                    <i className="ri-upload-cloud-line text-[#142837] text-[22px]"></i>
                                                    <div className="">
                                                        {uploadingCustomAttachment ? (
                                                            <div className="flex justify-start items-start w-fit">
                                                                <Loader size={4} />
                                                            </div>
                                                        ) : (
                                                            <p className="font-rocGroteskMedium">
                                                                <span className="underline text-sm text-gm-50">
                                                                    Click to upload document
                                                                </span>
                                                            </p>
                                                        )}
                                                        <p className="lowercase text-[13px] text-tradeally-neutral-200  font-rocGroteskMedium">
                                                            .JPEG .PDF .CSV .DOCX | File size limit:
                                                            5MB
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx,.txt, image/*"
                                                className={`${
                                                    !uploadingCustomAttachment &&
                                                    "absolute top-0 cursor-pointer"
                                                }  w-full h-full outline-none opacity-0 `}
                                                id="customAttachment"
                                                onChange={(evt) => {
                                                    if (
                                                        !isFileSizeLessThanOrEqualTo(
                                                            evt.target.files?.[0],
                                                            4
                                                        )
                                                    ) {
                                                        toast.custom((t) => (
                                                            <CustomToast
                                                                t={t}
                                                                message={"File too large"}
                                                                type={"error"}
                                                            />
                                                        ));
                                                        return;
                                                    }

                                                    if (evt.target.files?.[0]) {
                                                        handleAttachmentUpload(
                                                            evt.target.files?.[0].name,
                                                            evt.target.files?.[0]
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="border border-slate-200 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,)] p-4">
                                <p className="text-base text-gm-50 mb-2 border-b border-slate-200">
                                    Attachments
                                </p>
                                <div className="">
                                    {attachments?.length > 0 ? (
                                        <div className="mt-4 grid grid-cols-2 gap-2 space-y-2">
                                            {attachments?.map((item, idx) => {
                                                return (
                                                    <div
                                                        className=""
                                                        key={idx}
                                                        onClick={() => {
                                                            const a = document.createElement("a");
                                                            a.href = item?.path;
                                                            a.target = "_blank";
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        }}
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            {displayFileIcon(
                                                                getFileTypeFromUrl(item?.path),
                                                                false,
                                                                "!w-[28px] !h-[28px]"
                                                            )}
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm">
                                                                    {item?.filename}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <p className="text-sm text-gm-50">N/A</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {!task?.isCompleted ? (
                        <div className="">
                            <p className="text-base text-gm-50 mb-4">Additional Info</p>
                            <TextareaInput
                                name={"notes"}
                                value={notes}
                                onChange={(e) => setNotes(e?.target?.value)}
                                rows={5}
                                placeholder={"Additional info"}
                            />
                        </div>
                    ) : (
                        <div className="border border-slate-200 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,)] p-4 mb-20">
                            <p className="text-base text-gm-50 mb-2 border-b border-slate-200">
                                Additional Info
                            </p>
                            <p className="text-sm text-gm-50">
                                <ul className="">
                                    <li className="">{task?.notes || "N/A"}</li>
                                </ul>
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <ModalContainer
                open={qcFailedModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQcFailedModal(true);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setQcFailedModal(false);
                        const copyArr = [...qcData];
                        copyArr[qcIdx] = {
                            ...copyArr[qcIdx],
                            status: "failed",
                        };
                        setQcData(copyArr);
                    }}
                    className="bg-white rounded-[8px] relative"
                >
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setQcFailedModal(false);
                            const copyArr = [...qcData];
                            copyArr[qcIdx] = {
                                ...copyArr[qcIdx],
                                status: "pending",
                            };
                            setQcData(copyArr);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-1">
                                <i className="ri-feedback-line text-gm-50 text-[40px]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Quality Check Failed: Provide a Reason
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    The Quality Check for this production batch has failed. Please
                                    provide a reason for the failure to proceed with further
                                    actions.
                                </p>
                            </div>
                        </div>

                        <div className="px-6 pb-6">
                            <TextareaInput
                                name={"comment"}
                                value={comment}
                                onChange={(e) => setComment(e?.target.value)}
                                rows={5}
                                placeholder={"Comment"}
                                required={true}
                            />
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setQcFailedModal(false);
                                    const copyArr = [...qcData];
                                    copyArr[qcIdx] = {
                                        ...copyArr[qcIdx],
                                        status: "pending",
                                    };
                                    setQcData(copyArr);
                                }}
                            />
                            <Button
                                btnText={"Submit reason"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-white !border border-n-20 !font-rocGroteskMedium !px-4 !bg-[#FF3D1D]"
                                type="submit"
                                // isLoading={updatingApprovalStatus}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={qcPassModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQcPassModal(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdateHumanTask();
                    }}
                >
                    <div className="bg-white rounded-[8px] relative">
                        <i
                            className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                            onClick={() => {
                                setQcPassModal(false);
                            }}
                        ></i>
                        <div className="mt-4 ">
                            <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                                </div>
                                <div className="px-4 font-rocGroteskMedium">
                                    <span className="block mb-2 text-center text-[22px] text-gm-50">
                                        Mark QC Task(s) as Complete
                                    </span>
                                    <p className={`mt-1 text-sm text-slate-500 text-center `}>
                                        Marking these QC tasks as done will finalize them. Ensure
                                        all checks are complete before proceeding. Are you sure you
                                        want to continue?
                                    </p>
                                </div>
                            </div>

                            <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                <Button
                                    btnText={"No, cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setQcPassModal(false);
                                    }}
                                />
                                <Button
                                    btnText="Yes, mark as done"
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </PageFrame>
    );
};

export default TaskDetailWorkOrder;
