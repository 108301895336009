import React from "react";
import CustomToast from "components/common/CustomToast";
import { chatTypes, salesTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { chatService, salesService } from "services/Ldb";
import { Dispatch } from "redux";

export const salesActions = {
    getSalesOrderList,
    resetGetSalesOrderListSuccess,
    createSalesOrder,
    resetCreateSalesOrderSuccess,
    getSingleSalesOrder,
    resetGetSingleSalesOrderSuccess,
    getSalesDashboardStats,
    resetGetSalesDashboardStatsSuccess,
    getSalesTopAndLeastProducts,
    resetGetSalesTopAndLeastProductsSuccess,
    getSalesVelocityStat,
    resetGetSalesVelocityStatSuccess,
    getSalesChannelStat,
    resetGetSalesChannelStatSuccess,
    getCustomerList,
    resetGetCustomerListSuccess,
    getSalesTopAndLeastRegions,
    resetGetSalesTopAndLeastRegionsSuccess,
    createSalesShipment,
    resetCreateSalesShipmentSuccess,
    updateSalesOrder,
    resetUpdateSalesOrderSuccess,
};

function getSalesOrderList(
    page?: number | string,
    status?: string,
    search?: string,
    warehouseId?: string,
    startDate?: string,
    endDate?: string,
    channel?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_ORDER_LIST_REQUEST));

        salesService
            .fetchSalesOrderList(page, status, search, warehouseId, startDate, endDate, channel)
            .then(
                (res) => {
                    dispatch(success(salesTypes.FETCH_SALES_ORDER_LIST_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(salesTypes.FETCH_SALES_ORDER_LIST_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function createSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.CREATE_SALES_ORDER_REQUEST));

        salesService.createSalesOrder(data).then(
            (res) => {
                dispatch(success(salesTypes.CREATE_SALES_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.CREATE_SALES_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSalesOrder(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.UPDATE_SALES_ORDER_REQUEST));

        salesService.updateSalesOrder(id, data).then(
            (res) => {
                dispatch(success(salesTypes.UPDATE_SALES_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.UPDATE_SALES_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleSalesOrder(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SINGLE_SALES_ORDER_REQUEST));

        salesService.fetchSingleSalesOrder(id).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SINGLE_SALES_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.FETCH_SINGLE_SALES_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSalesDashboardStats(startDate?: string, endDate?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_DASHBOARD_STATS_REQUEST));

        salesService.fetchSalesDashboardStats(startDate, endDate).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SALES_DASHBOARD_STATS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(salesTypes.FETCH_SALES_DASHBOARD_STATS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getSalesTopAndLeastProducts(startDate?: string, endDate?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_REQUEST));

        salesService.fetchSalesTopAndLeast(startDate, endDate).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getSalesTopAndLeastRegions(startDate?: string, endDate?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_REQUEST));

        salesService.fetchSalesTopAndLeast(startDate, endDate).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getSalesVelocityStat(
    startDate?: string,
    endDate?: string,
    groupBy?: "warehouse" | "channel",
    inventoryId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_VELOCITY_STAT_REQUEST));

        salesService.fetchSalesVelocityStats(startDate, endDate, groupBy, inventoryId).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SALES_VELOCITY_STAT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.FETCH_SALES_VELOCITY_STAT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSalesChannelStat(startDate?: string, endDate?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_SALES_CHANNEL_STAT_REQUEST));

        salesService.fetchSalesChannelStats(startDate, endDate).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_SALES_CHANNEL_STAT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.FETCH_SALES_CHANNEL_STAT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getCustomerList(page?: string | number, limit?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.FETCH_CUSTOMER_LIST_REQUEST));

        salesService.fetchCustomerList(page, limit, search).then(
            (res) => {
                dispatch(success(salesTypes.FETCH_CUSTOMER_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.FETCH_CUSTOMER_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function createSalesShipment(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(salesTypes.CREATE_SALES_SHIPMENT_REQUEST));

        salesService.createSalesShipment(data).then(
            (res) => {
                dispatch(success(salesTypes.CREATE_SALES_SHIPMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(salesTypes.CREATE_SALES_SHIPMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetUpdateSalesOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.UPDATE_SALES_ORDER_SUCCESS, null));
    };
}

function resetGetCustomerListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_CUSTOMER_LIST_SUCCESS, null));
    };
}

function resetGetSalesChannelStatSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_CHANNEL_STAT_SUCCESS, null));
    };
}

function resetGetSalesVelocityStatSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_VELOCITY_STAT_SUCCESS, null));
    };
}

function resetGetSalesTopAndLeastProductsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_SUCCESS, null));
    };
}

function resetGetSalesTopAndLeastRegionsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_SUCCESS, null));
    };
}

function resetGetSalesDashboardStatsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_DASHBOARD_STATS_SUCCESS, null));
    };
}

function resetGetSalesOrderListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SALES_ORDER_LIST_SUCCESS, null));
    };
}

function resetCreateSalesOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.CREATE_SALES_ORDER_SUCCESS, null));
    };
}

function resetGetSingleSalesOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.FETCH_SINGLE_SALES_ORDER_SUCCESS, null));
    };
}

function resetCreateSalesShipmentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(salesTypes.CREATE_SALES_SHIPMENT_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
