import Button from "../../../components/common/Button";
import PageFrame from "../../../components/layout/PageFrame";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { marketingCampaignActions, workforceActions } from "redux/Ldb/actions";
import { useStream } from "@langchain/langgraph-sdk/react";
import { MarketingAgentState } from "types/marketingCampaignType";
import useGetSingleCampaign from "hooks/workforceHooks/useGetSingleCampaign";
import CampaignSimulationDetails from "components/views/Synth2.0/MarketingCampaign/CampaignSimulationDetails";
import { cloneDeep } from "lodash";
import config from "config/config";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

const PlanCampaign = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { campaignId } = useParams();
    const location = useLocation();
    const { addingCampaign, addCampaignSuccess } = useAppSelector(
        (state) => state.marketingCampaign
    );
    const { data: campaignDetail, isFetching: isFetchingCampaignDetail } = useGetSingleCampaign();
    const [scenerio, setScenerio] = useState<string>("optimistic");
    const [threadValues, setThreadValues] = useState({});

    const handleSaveCampaign = () => {
        dispatch(marketingCampaignActions.addCampaign({ campaignId: campaignId }));
    };

    useEffect(() => {
        if (Boolean(addCampaignSuccess)) {
            dispatch(marketingCampaignActions.resetAddCampaign());
            navigate(`/dashboard/workforce/ai-teammate/${location?.state?.aiTeammateId}`);
        }
    }, [addCampaignSuccess]);

    const copyArr = cloneDeep(campaignDetail?.aiAgentTasks);
    const defaultTask = copyArr?.reverse()?.[0];
    const getThreadId =
        copyArr?.reverse()?.find((task) => task?.simulationScenario === scenerio)?.threadId ||
        defaultTask?.threadId;

    const thread = useStream<typeof MarketingAgentState.State>({
        apiUrl: config.LANGCHAIN_API_URL,
        apiKey: config.LANGCHAIN_API_KEY,
        assistantId: "cia",
        messagesKey: "messages",
        threadId: getThreadId,
    });
    const { values } = thread;

    useEffect(() => {
        setThreadValues({});
    }, [scenerio]);

    useEffect(() => {
        if (defaultTask && copyArr?.length === 1) {
            setScenerio(defaultTask?.simulationScenario);
        }
    }, [defaultTask, copyArr?.length]);

    // useEffect(() => {
    //     if (campaignDetail?.aiAgentTasks?.length < 3) {
    //         dispatch(workforceActions.getSingleCampaign(campaignId));
    //     }
    // }, [campaignDetail?.aiAgentTasks?.length, campaignId]);

    useEffect(() => {
        if (values && Object.keys(values).length > 0) {
            setThreadValues((prevValues) => {
                if (JSON.stringify(prevValues) !== JSON.stringify(values)) {
                    return values;
                }
                return prevValues;
            });
        }
    }, [values, scenerio]);

    return (
        <PageFrame>
            <div className="bg-white rounded-lg border border-n-20 relative font-rocGroteskMedium pb-14">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <p className="text-lg">Plan a campaign</p>

                    <div className="flex items-center space-x-3">
                        <Button
                            btnText={`Close`}
                            type="button"
                            disabled={addingCampaign}
                            onClick={() =>
                                navigate(
                                    `/dashboard/workforce/ai-teammate/${location?.state?.aiTeammateId}`
                                )
                            }
                            btnClassname="!px-4 !py-2 !w-fit !bg-[#F4F5F7]"
                        />

                        <Button
                            btnText={`Save to calendar`}
                            type="button"
                            isLoading={addingCampaign}
                            disabled={addingCampaign}
                            onClick={handleSaveCampaign}
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="flex justify-center space-x-6 w-full relative mt-[58px]">
                    <div className="w-[90%] md:w-[80%] xl:w-[57%] border border-slate-100 rounded-lg bg-[#FBFBFB] p-4">
                        <div className="flex items-center gap-2.5 mb-6">
                            <Button
                                btnText={`Best-case scenario`}
                                type="button"
                                onClick={() => {
                                    const isBestCaseScenarioPresent = copyArr?.find(
                                        (task) => task?.simulationScenario === "optimistic"
                                    );

                                    if (!isBestCaseScenarioPresent) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={
                                                    "This scenario is still being generated. Please select another scenario or try again later."
                                                }
                                                type={"error"}
                                            />
                                        ));
                                        dispatch(workforceActions.getSingleCampaign(campaignId));
                                    } else {
                                        setScenerio("optimistic");
                                    }
                                }}
                                btnClassname={` ${
                                    scenerio === "optimistic"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                            <Button
                                btnText={`Worse-case scenario`}
                                type="button"
                                onClick={() => {
                                    const isWorseCaseScenarioPresent = copyArr?.find(
                                        (task) => task?.simulationScenario === "pessimistic"
                                    );

                                    if (!isWorseCaseScenarioPresent) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={
                                                    "This scenario is still being generated. Please select another scenario or try again later."
                                                }
                                                type={"error"}
                                            />
                                        ));
                                        dispatch(workforceActions.getSingleCampaign(campaignId));
                                    } else {
                                        setScenerio("pessimistic");
                                    }
                                }}
                                btnClassname={` ${
                                    scenerio === "pessimistic"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                            <Button
                                btnText={`Likely scenario`}
                                type="button"
                                onClick={() => {
                                    const isMostLikelyScenarioPresent = copyArr?.find(
                                        (task) => task?.simulationScenario === "most likely"
                                    );
                                    if (!isMostLikelyScenarioPresent) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={
                                                    "This scenario is still being generated. Please select another scenario or try again later."
                                                }
                                                type={"error"}
                                            />
                                        ));
                                        dispatch(workforceActions.getSingleCampaign(campaignId));
                                    } else {
                                        setScenerio("most likely");
                                    }
                                }}
                                btnClassname={` ${
                                    scenerio === "most likely"
                                        ? "!bg-[#303437] !text-white"
                                        : "!text-[#303437] !bg-white border border-[#B8B8B8]"
                                } !px-4 !py-2 !w-fit  !rounded-[30px]`}
                            />
                        </div>

                        <CampaignSimulationDetails
                            values={threadValues}
                            campaignDetail={campaignDetail}
                            isSaved={false}
                            scenerio={scenerio}
                        />
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default PlanCampaign;
