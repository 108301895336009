import React, { useEffect, useRef, useState } from "react";
import { authService } from "services/Ldb";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { authActions, workforceActions } from "redux/Ldb/actions";
import Button from "../Button";
import ModalContainer from "../ModalContainer";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { io } from "socket.io-client";
import config from "config/config";
import { useStream } from "@langchain/langgraph-sdk/react";
import { MarketingAgentState } from "types/marketingCampaignType";
import { useChannel } from "ably/react";
import MultiselectInput from "../InputField/MultiselectInput";
import useGetCampaignErrors from "hooks/workforceHooks/useGetCampaignErrors";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import SelectInput from "../InputField/SelectInput";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import CustomToast from "../CustomToast";
import toast from "react-hot-toast";

// const socket = io(`${config.API_URL}`);

interface PrivateRoutesProps {
    children: React.ReactNode;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
    const [featureLimitModal, setFeatureLimitModal] = useState(false);
    const [featureLimitMessage, setFeatureLimitMessage] = useState("");
    const [campaignError, setCampaignError] = useState(false);
    const [campaignErrorData, setCampaignErrorData] = useState([]);
    const [maximizedCampaignErrorModal, setMaximizedCampaignErrorModal] = useState(false);
    const [campaignErrorForm, setCampaignErrorForm] = useState<
        {
            campaignId: string;
            campaignName: string;
            products: string[];
        }[]
    >([]);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);
    const { correctCampaignErrorSuccess, correctingCampaignError } = useAppSelector(
        (state) => state.workforce
    );
    const navigate = useNavigate();
    const containerRef = useRef(null);
    // const {
    //     data: teamList,
    //     isFetching: isFetchingTeam,
    //     handleSearch,
    //     handleDebouncedChange,
    //     search,
    // } = useGetTeam();

    const {
        data: productData,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        setSearch,
        setDebouncedSearch,
        pagination,
        isLoadingMore,
    } = useGetProductList(null, "Finished Goods");
    // console.log("productData", productData);
    const data = [
        "Manage up to 100 active vendors",
        "Create unlimited AI text prompts",
        "Add unlimited team members",
        "Manage your team and create access levels",
        "Up to 10,000 AI-assisted quote evaluations",
        "500 AI-generated RFQs",
        "Dedicated onboarding & account manager",
        "Enable integration with standard and custom ERPs & 3rd-party systems",
        "And many more...",
    ];

    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));

    const redirectUrl = getFromStorage("redirect-url");
    const { data: campaignErrors } = useGetCampaignErrors();

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-user"));

        if (!authService.loggedIn()) {
            if (!authService.loggedIn() && Boolean(profile?.user)) {
                dispatch(authActions.logOut());
            }

            saveToStorage("redirect-url", location.pathname + location?.search);
            navigate("/login");
            // window.open("/login", "_self");
            return;
        }
    };

    const handleCorrectCampaignError = () => {
        const body = {
            campaigns: campaignErrorForm?.map((item) => {
                return {
                    campaignId: item?.campaignId,
                    products: item?.products,
                };
            }),
        };

        dispatch(workforceActions.correctCampaignError(body));
    };

    const handleCampaignErrorForm = (
        campaignId: string,
        campaignName: string,
        products: string[]
    ) => {
        const camppaignErrorFormCopy = [...campaignErrorForm];
        const existingIndex = camppaignErrorFormCopy.findIndex(
            (form) => form.campaignId === campaignId
        );
        if (existingIndex !== -1) {
            const updatedForm = [...camppaignErrorFormCopy];
            updatedForm[existingIndex].products = products;
            setCampaignErrorForm(updatedForm);
            return;
        }
        setCampaignErrorForm([...camppaignErrorFormCopy, { campaignId, campaignName, products }]);
    };

    const scrollDown = () => {
        if (containerRef.current) {
            setTimeout(() => {
                containerRef.current.scrollTo({
                    top: containerRef.current.scrollHeight + 150,
                    behavior: "smooth",
                });
            }, 200);
        }
    };

    useEffect(() => {
        if (correctCampaignErrorSuccess) {
            setMaximizedCampaignErrorModal(false);
            setCampaignError(false);
            setCampaignErrorForm([]);
            toast.custom((t) => (
                <CustomToast t={t} message={"Correction successful"} type="success" />
            ));
            dispatch(workforceActions.resetCorrectCampaignErrorSuccess());
        }
    }, [dispatch, correctCampaignErrorSuccess]);

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    useEffect(() => {
        const handleErrorOccurred = () => {
            setFeatureLimitModal(true);
        };
        document.addEventListener("errorOccurred", handleErrorOccurred);

        return () => {
            document.removeEventListener("errorOccurred", handleErrorOccurred);
        };
    }, []);

    useEffect(() => {
        if (campaignErrors?.length > 0) {
            const formattedData = campaignErrors?.map((item) => {
                return {
                    campaignId: item?.campaign?._id,
                    campaignName: item?.campaign?.campaignName,
                    products: [],
                };
            });
            setCampaignError(true);
            setCampaignErrorForm(formattedData);
        } else {
            setCampaignError(false);
            setCampaignErrorForm([]);
        }
    }, [campaignErrors?.length]);

    const {} = useChannel(`campaign`, (syncRes) => {
        // console.log("syncRes", syncRes);

        if (syncRes?.name === `campaign_error_${profile?.user?.parentId || profile?.user?._id}`) {
            // console.log("campaign error -->", syncRes);
            const formattedData = {
                campaignId: syncRes?.data?.campaignId,
                campaignName: syncRes?.data?.campaignName,
                products: [],
            };

            setCampaignErrorForm((prev) => [...prev, formattedData]);
            setCampaignError(true);
        }
    });

    if (profile?.user?.profile && !profile?.user?.profile?.subscriptionActive) {
        return (
            <ModalContainer
                open={true}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 !backdrop-blur-md "}
                tailwindClassName="w-[37%] max-sm:w-[95%]"
                closeModal={() => {}}
            >
                <div className="bg-white pt-8 rounded-[8px]">
                    <div className="mt-4 p-6">
                        <div className="text-center flex justify-center flex-col items-center">
                            <i className="ri-pantone-fill text-[48px] text-p-50 mb-4"></i>
                            <div>
                                <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                    Subscription expired
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    Oops! It seems like your subscription has expired. To continue
                                    using Synth to manage your inventory needs, kindly renew or
                                    purchase a new subscription plan.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-4 px-6 border-t border-slate-200 flex space-x-3">
                        <Button
                            btnText={"Log out"}
                            type={"button"}
                            btnClassname={"!bg-n-20 !text-gm-50"}
                            onClick={() => {
                                dispatch(authActions.logOut());
                                navigate("/login");
                            }}
                        />
                        <Button
                            onClick={() => {
                                window.open(`mailto:hello@synthally.com`, "_blank");
                            }}
                            btnText={"Renew subscription"}
                            type={"button"}
                        />
                    </div>
                </div>
            </ModalContainer>
        );
    }

    if (redirectUrl && location.pathname !== `/new-onboard`) {
        removeFromStorage("redirect-url");
    }

    if (!authService.loggedIn()) {
        if (!authService.loggedIn() && Boolean(profile?.user)) {
            dispatch(authActions.logOut());
        }

        saveToStorage("redirect-url", location.pathname + location?.search);
        // navigate("/login");
        window.open("/login", "_self");
        return;
    }

    if (
        authService.loggedIn() &&
        !profile?.user?.active &&
        profile?.token &&
        // !profile?.verifyComplete &&
        location.pathname !== `/registration-success/${profile?.token}`
    ) {
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }

    return (
        <div className="relative">
            {children}

            <ModalContainer
                open={featureLimitModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setFeatureLimitModal(false);
                    setFeatureLimitMessage("");
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[12px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://storage.googleapis.com/heysynth_files/synth_webapp/Tradeally_2.0/icons/feature_limit_icon_i3m7bb.svg"
                                alt="feature limit icon"
                                className="w-[76px] h-[76px]"
                            />
                            <div>
                                <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                    Feature Limit Exceeded, <br /> Upgrade Now for More Benefits
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    {featureLimitMessage
                                        ? featureLimitMessage
                                        : `It looks like you've reached the limit for this feature in your
                                    subscription. upgrade your account and unlock additional
                                    features and improve their experience.`}
                                </p>
                            </div>
                        </div>
                        <div className="my-5">
                            <div className="flex flex-col gap-3 ">
                                {data.map((description, index) => (
                                    <div
                                        key={description + String(index)}
                                        className="flex gap-2 items-center"
                                    >
                                        <i className="ri-check-fill text-g-50"></i>
                                        <span className="text-gm-50 text-[13px] font-rocGroteskMedium">
                                            {description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-5 flex space-x-3">
                            <Button
                                btnText={"Not now"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setFeatureLimitModal(false);
                                    setFeatureLimitMessage("");
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setFeatureLimitModal(false);
                                    setFeatureLimitMessage("");
                                    navigate("/dashboard/settings/2");
                                }}
                                btnText={"Upgrade"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={maximizedCampaignErrorModal}
                showCloseIcon={false}
                modalClassName={` pr-[33px] pb-[42px]`}
                tailwindClassName="w-[49%] !overflow-y-visible"

                // closeModal={() => {}}
            >
                <div className="bg-white  rounded-[10px]">
                    <div className="p-6 flex justify-between rounded-t-[10px] space-x-2 h-[129px] bg-gradient-to-br from-[#E7F1E1] from-[33%] to-[#F7EAC8] to-[110%]">
                        <div className="">
                            <h5 className="text-2xl text-slate-800 font-rocGroteskBold mb-1.5">
                                Correct matching errors
                            </h5>

                            <p className="text-slate-600 text-[13px] font-rocGroteskMedium">
                                Synth detected that a new campaign was created but the corresponding
                                product name was not found in your brand's inventory. Correct
                                mismatching SKUs/products.
                            </p>
                        </div>

                        <div
                            className="!mr-5 w-fit rotate-45 cursor-pointer"
                            onClick={() => {
                                setMaximizedCampaignErrorModal(false);
                            }}
                        >
                            <i className="ri-contract-up-down-line text-2xl"></i>
                        </div>
                    </div>

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleCorrectCampaignError();
                        }}
                        className={`py-5 rounded-b-[10px] `}
                    >
                        <div
                            ref={containerRef}
                            className="space-y-6 px-4 max-h-[59vh] overflow-y-auto"
                        >
                            {campaignErrorForm?.map((item, idx) => (
                                <details
                                    open
                                    key={`${item?.campaignId}-${idx}`}
                                    className=" border border-slate-100 rounded-[10px] mb-5"
                                >
                                    <summary className="flex justify-between cursor-pointer p-4">
                                        <h6 className="text-lg font-rocGroteskMedium">
                                            Campaign {idx + 1}
                                        </h6>
                                        <i className="ri-arrow-down-s-line text-2xl text-slate-400"></i>
                                    </summary>

                                    <div className="px-4 py-2 border-t border-slate-100">
                                        <div className="">
                                            <div className="flex items-center w-full justify-between mb-4">
                                                <p className="text-xs font-rocGroteskMedium text-gm-30">
                                                    Campaign name
                                                </p>
                                            </div>
                                            <div className="border-b-[1px] border-b-slate-200 pb-2.5">
                                                <span>{item?.campaignName}</span>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between space-x-3 my-6">
                                            <span className="text-sm text-slate-500 font-rocGroteskMedium whitespace-nowrap">
                                                Select the right product for this campaign
                                            </span>
                                            <hr className="w-fill-available border-slate-200 text-red-500" />
                                        </div>

                                        <div>
                                            <div className="mb-2.5">
                                                <MultiselectInput
                                                    value={item?.products}
                                                    name="products"
                                                    placeholder={"Select SKU/product"}
                                                    // inputContainerClassName="mt-[-10px]"
                                                    handleChange={(name, value, valueArr) => {
                                                        // campaignId should be here instead of name
                                                        handleCampaignErrorForm(
                                                            item?.campaignId,
                                                            item?.campaignName,
                                                            valueArr
                                                        );
                                                        // handleSearch(value);
                                                    }}
                                                    searchLoading={productListLoading}
                                                    handleInputChange={handleDebouncedChange}
                                                    isRequired={true}
                                                    onTrigger={scrollDown}
                                                    dropdownOptions={productData?.map((item) => {
                                                        return {
                                                            label: item?.productName,
                                                            value: item?._id,
                                                        };
                                                    })}
                                                />
                                            </div>
                                            {/* <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-2">
                                                <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                    Suggested:
                                                </span>
                                                <div className="flex items-center space-x-2 px-2.5 h-8 bg-slate-50 rounded-lg border border-slate-200 w-fit">
                                                    <span className="text-[10px]">
                                                        Pasta Pureé 10lbs (SKU 2470)
                                                    </span>
                                                    <i className="ri-add-fill text-xl"></i>
                                                </div>
                                            </div>

                                            <div className="flex items-center space-x-1 font-rocGroteskMedium pt-">
                                                <span className="text-sm text-tradeally-neutral-200 ">
                                                    Current name:
                                                </span>
                                                <span className="text-sm text-slate-600">
                                                    Tomato Sauce 10lbs
                                                </span>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </details>
                            ))}
                        </div>

                        <div
                            className={`flex justify-end space-x-2 pt-5 px-4 border-t border-slate-100 mt-6`}
                        >
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50 !w-fit"}
                                isLoading={false}
                                onClick={() => {
                                    const formattedData = campaignErrors?.map((item) => {
                                        return {
                                            campaignId: item?.campaign?.campaignId,
                                            campaignName: item?.campaign?.campaignName,
                                            products: [],
                                        };
                                    });
                                    setMaximizedCampaignErrorModal(false);
                                    setCampaignErrorForm(formattedData);
                                    setCampaignError(false);
                                }}
                            />
                            <Button
                                // onClick={() => {}}
                                btnText={"Correct matching errors"}
                                btnClassname={"!w-fit"}
                                isLoading={correctingCampaignError}
                                type={"submit"}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>

            {!maximizedCampaignErrorModal &&
                campaignError &&
                !fetchUserProfileSuccess?.isMultiBrandAccount && (
                    <div className="bg-white w-[49%] z-[99991] rounded-[10px] sticky bottom-[42px] mr-[33px] shadow-[-8px_4px_18px_0px_#0000000A] float-right">
                        <div className="p-6 flex justify-between rounded-t-[10px] space-x-2 h-[129px] bg-gradient-to-br from-[#E7F1E1] from-[33%] to-[#F7EAC8] to-[110%]">
                            <div className="">
                                <h5 className="text-2xl text-slate-800 font-rocGroteskBold mb-1.5">
                                    Correct matching errors
                                </h5>

                                <p className="text-slate-600 text-[13px] font-rocGroteskMedium">
                                    Synth detected that a new campaign was created but the
                                    corresponding product name was not found in your brand's
                                    inventory. Correct mismatching SKUs/products.
                                </p>
                            </div>

                            <div
                                className="!mr-5 w-fit rotate-45 cursor-pointer"
                                onClick={() => {
                                    setMaximizedCampaignErrorModal(true);
                                }}
                            >
                                <i className="ri-expand-up-down-line text-2xl"></i>
                            </div>
                        </div>

                        <div className={`pb-5 rounded-b-[10px] `}>
                            <div
                                className={`flex justify-end space-x-2 pt-5 px-4 border-t border-slate-100 `}
                            >
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50 !w-fit"}
                                    isLoading={false}
                                    onClick={() => {
                                        setMaximizedCampaignErrorModal(false);
                                        setCampaignError(false);
                                        setCampaignErrorForm([]);
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setMaximizedCampaignErrorModal(true);
                                    }}
                                    btnText={"Start matching"}
                                    btnClassname={"!w-fit"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default PrivateRoutes;
