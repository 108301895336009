import Dropdown from "components/common/Dropdown";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import React, { useEffect, useRef, useState } from "react";
import ProductionPlan from "components/views/Synth2.0/Production/ProductionPlan";
import BillOfMaterial from "components/views/Synth2.0/Production/BillOfMaterial";
import WorkOrder from "components/views/Synth2.0/Production/WorkOrder";
import Report from "components/views/Synth2.0/Production/Report";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "components/common/InputField/TextInput";
import { debounce } from "lodash";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import useGetProductionAnalytics from "hooks/ProductionHooks/useGetProductionAnalytics";
import moment from "moment";
import { formatMoney } from "helpers";
import useGetWorkOrderList from "hooks/ProductionHooks/useGetWorkOrderList";
import useGetProductionReportList from "hooks/ProductionHooks/useGetProductionReportList";
import { Tooltip } from "react-tooltip";
import { yearDropdown } from "helpers/yearDropdown";
import Skeleton from "react-loading-skeleton";
import { AccountPermissions } from "types/permissions";

const Production = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");

    const customRef = useRef(null);

    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);

    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["Production Plan", "Bill of Materials", "Work Order", "Reports"];

    const [customValue, customOnChange] = useState("");
    const [durationFilter, setDurationFilter] = useState<string>("This month");
    const [debouncedSearchBOM, setDebouncedSearchBOM] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [chartSearch, setChartSearch] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [viewType, setViewType] = useState<string>("chart");
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const { analytics, isLoading, dates, setDates } = useGetProductionAnalytics();

    const {
        setDebouncedSearch,
        handleAssigneeIdChange,
        assigneeId,
        setAssigneeId,
        setStatus: setPlanStatus,
        data,
        isLoadingMore,
        pagination,
    } = useGetProductionPlanList();

    const {
        setDebouncedSearch: setWorkOrderDebouncedSearch,
        handleAssigneeIdChange: workOrderHandleAssigneeIdChange,
        assigneeId: workOrderAssigneeId,
        setAssigneeId: setWorkOrderAssigneeId,
        setStatus: setWorkOrderStatus,
        data: workOrderData,
        pagination: workOrderPagination,
        isLoadingMore: workOrderLoadingMore,
        isLoading: workOrderLoading,
    } = useGetWorkOrderList();

    const {
        setDebouncedSearch: setReportDebouncedSearch,
        setStatus: setReportStatus,
        data: reportData,
        pagination: reportPagination,
        isLoadingMore: reportLoadingMore,
        isLoading: reportLoading,
    } = useGetProductionReportList();

    const handleDebouncedSearch = debounce((e) => {
        const { value } = e?.target;
        if (activeTab === 0 && viewType === "chart") {
            setChartSearch(value);
        } else if (activeTab === 0) {
            setDebouncedSearch(value);
        } else if (activeTab === 1) {
            setDebouncedSearchBOM(value);
        } else if (activeTab === 2) {
            setWorkOrderDebouncedSearch(value);
        } else {
            setReportDebouncedSearch(value);
        }
    }, 800);

    const handleClearStatus = () => {
        setStatus("");
        if (activeTab === 0) {
            setPlanStatus("");
        } else if (activeTab === 2) {
            setWorkOrderStatus("");
        } else if (activeTab === 3) {
            setReportStatus("");
        }
    };

    const handleStatusChange = (e) => {
        const { value } = e?.target;

        setStatus(value);
        if (activeTab === 0) {
            setPlanStatus(value);
        } else if (activeTab === 2) {
            setWorkOrderStatus(value);
        } else if (activeTab === 3) {
            setReportStatus(value);
        }
    };

    useEffect(() => {
        setSearch("");
        setStatus("");
        setAssigneeId("");
        setWorkOrderAssigneeId("");
        setDebouncedSearch("");
        setDebouncedSearchBOM("");
        setWorkOrderDebouncedSearch("");
        setReportDebouncedSearch("");
        setPlanStatus("");
        setWorkOrderStatus("");
        setReportStatus("");
        setChartSearch("");
    }, [activeTab]);

    useEffect(() => {
        setChartSearch("");
    }, [viewType]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ProductionPlan
                        search={search}
                        setSearch={setSearch}
                        setStatus={setStatus}
                        setAssigneeId={setAssigneeId}
                        data={data}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        assigneeId={assigneeId}
                        status={status}
                        viewType={viewType}
                        setViewType={setViewType}
                        chartSearch={chartSearch}
                    />
                );
            case 1:
                return (
                    <BillOfMaterial
                        debouncedSearch={debouncedSearchBOM}
                        setDebouncedSearch={setDebouncedSearchBOM}
                        search={search}
                        setSearch={setSearch}
                    />
                );
            case 2:
                return (
                    <WorkOrder
                        search={search}
                        data={workOrderData}
                        isLoadingMore={workOrderLoadingMore}
                        isLoading={workOrderLoading}
                        pagination={workOrderPagination}
                        statusFilter={status}
                        assigneeId={workOrderAssigneeId}
                    />
                );
            case 3:
                return (
                    <Report
                        search={search}
                        data={reportData}
                        isLoadingMore={reportLoadingMore}
                        isLoading={reportLoading}
                        pagination={reportPagination}
                        statusFilter={status}
                    />
                );
            default:
            // return <ProductionPlan/>
        }
    };

    useEffect(() => {
        setActiveTab(Number(currTab));
    }, [currTab]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    useEffect(() => {
        if (customValue[0] !== customValue[1])
            setDates({
                startDate: moment(customValue[0])?.format("YYYY-MM-DD"),
                endDate: moment(customValue[1])?.format("YYYY-MM-DD"),
            });
    }, [customValue]);

    return (
        <div className="p-4 border border-slate-200 rounded-[8px] bg-white">
            <div className="mb-8">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Production</h3>
                        </div>
                    }
                    rightComp={
                        <div className="flex gap-3">
                            <div className="shadow-buttonShadow2 flex items-center space-x-1.5 bg-white border border-slate-200 rounded py-[11px] px-3 ">
                                <i className="ri-signal-tower-fill text-base text-[#15803D] "></i>
                                <span className="text-[13px] text-[#15803D] font-rocGroteskMedium">
                                    Real-time tracking of inventory levels.
                                </span>
                            </div>
                            <div className="relative">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setDurationFilter(value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 !border !border-slate-100 gap-2">
                                            <i className="ri-calendar-line text-sm"></i>
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                            >
                                                {typeof durationFilter === "number"
                                                    ? durationFilter
                                                    : dates?.startDate && dates?.endDate
                                                    ? `${moment(dates.startDate).format(
                                                          "MMM DD, YY"
                                                      )} - ${moment(dates.endDate).format(
                                                          "MMM DD, YY"
                                                      )}`
                                                    : durationFilter}
                                            </div>
                                            <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[
                                        {
                                            label: "This month",
                                            value: "This month",
                                            action: () => {
                                                setDates({
                                                    startDate: "",
                                                    endDate: "",
                                                });
                                                customOnChange("");
                                            },
                                        },
                                        ...(years && Array.isArray(years)
                                            ? years.map((item) => ({
                                                  label:
                                                      item === new Date().getFullYear()
                                                          ? `This
                                                          year (${item})`
                                                          : item?.toString(),
                                                  value: item,
                                                  action: () => {
                                                      setDates({
                                                          startDate: moment(item, "YYYY")
                                                              .startOf("year")
                                                              ?.format("YYYY-MM-DD"),
                                                          endDate: moment(item, "YYYY")
                                                              .endOf("year")
                                                              ?.format("YYYY-MM-DD"),
                                                      });
                                                      customOnChange("");
                                                  },
                                              }))
                                            : []),
                                        {
                                            label: "Custom",
                                            value: "Custom",
                                            action: () => {
                                                setDates({
                                                    startDate: "",
                                                    endDate: "",
                                                });
                                                setShowCustomDate(true);
                                            },
                                        },
                                    ]}
                                />

                                <div className="relative">
                                    {showCustomDate && (
                                        <div
                                            ref={customRef}
                                            className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                        >
                                            <CustomCalendar
                                                showDoubleView={true}
                                                selectRange={true}
                                                value={customValue}
                                                onChange={customOnChange}
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    btnText={"Close"}
                                                    btnClassname={"!w-fit !py-2"}
                                                    onClick={() => setShowCustomDate(false)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            {isLoading ? (
                <div className="grid grid-cols-4 gap-2 mb-8">
                    {[1, 1, 1, 1]?.map((item, idx) => (
                        <Skeleton
                            key={idx}
                            width={"100%"}
                            height={127}
                            highlightColor="#E2E8F0"
                            borderRadius={8}
                            baseColor="#F1F5F9"
                            className="custom-skeleton"
                        />
                    ))}
                </div>
            ) : (
                <div className="grid grid-cols-4 gap-2 mb-8">
                    <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                        <div className="flex items-center gap-1 mb-2">
                            <p className="block text-base text-g-75 2xl font-rocGroteskMedium ">
                                Total production cost
                            </p>
                            <div>
                                <a id="prod-cost">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>

                                <Tooltip
                                    anchorSelect="#prod-cost"
                                    place={"top"}
                                    content="The total expense incurred to manufacture products, including materials, labor, and overhead"
                                    style={{ width: "250px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                        <p className="text-2xl">
                            {analytics?.totalCost?.value ? "$" : ""}
                            {`${
                                analytics?.totalCost?.value !== undefined
                                    ? formatMoney()?.format(analytics?.totalCost?.value)
                                    : "--"
                            }`}
                        </p>

                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div
                                className={`${
                                    analytics?.totalCost?.percentIncrease >= 0
                                        ? "bg-[#F0FDF4] text-[#22C55E]"
                                        : "bg-[#FEF2F2] text-[#DC2626]"
                                }  rounded-[3px] py-0.5 px-1.5 text-sm`}
                            >
                                <i
                                    className={`${
                                        analytics?.totalCost?.percentIncrease >= 0
                                            ? "ri-arrow-up-line"
                                            : "ri-arrow-down-line"
                                    }`}
                                ></i>
                                <span className="">
                                    {analytics?.totalCost?.percentIncrease !== undefined
                                        ? `${Math.round(
                                              Math.abs(analytics?.totalCost?.percentIncrease)
                                          )}%`
                                        : "-"}
                                </span>
                            </div>
                            {!dates?.startDate && !dates?.endState && (
                                <p className="text-sm text-slate-500">since last month</p>
                            )}
                        </div>
                    </div>
                    <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                        <div className="flex items-center gap-1 mb-2">
                            <p className="block text-base text-g-75 2xl font-rocGroteskMedium">
                                Number of defects
                            </p>
                            <div>
                                <a id="defects">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>

                                <Tooltip
                                    anchorSelect="#defects"
                                    place={"top"}
                                    content="Total count of defective units identified during production."
                                    style={{ width: "250px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                        <p className="text-2xl">{`${"--"}`}</p>
                        {/* {productionData?.defect && (
                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div className="bg-[#F0FDF4] text-[#22C55E] rounded-[3px] py-0.5 px-1.5 text-sm">
                                <i className="ri-arrow-up-line"> </i>
                                <span className="">14%</span>
                            </div>
                            <p className="text-sm text-slate-500">since last month</p>
                        </div>
                    )} */}
                    </div>
                    <div className="py-4 pl-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12 ">
                        <div className="flex items-center gap-1 mb-2">
                            <p className="block text-base text-g-75 2xl font-rocGroteskMedium ">
                                Products meeting quality standards
                                <a id="stands">
                                    <i className="ri-information-fill text-slate-500 text-base "></i>
                                </a>
                            </p>
                            <div>
                                <Tooltip
                                    anchorSelect="#stands"
                                    place={"top"}
                                    content="Percentage of items that pass all quality control checks."
                                    style={{ width: "250px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                        <p className="text-2xl">{`${"--"}`}</p>
                        {/* {productionData?.standards && (
                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div className="bg-[#F0FDF4] text-[#22C55E] rounded-[3px] py-0.5 px-1.5 text-sm">
                                <i className="ri-arrow-up-line"> </i>
                                <span className="">14%</span>
                            </div>
                            <p className="text-sm text-slate-500">since last month</p>
                        </div>
                    )} */}
                    </div>
                    <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                        <div className="flex items-center gap-1 mb-3">
                            <p className="block text-base text-g-75 2xl font-rocGroteskMedium ">
                                Scrap and waste rates
                            </p>
                            <div>
                                <a id="waste-rates">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>

                                <Tooltip
                                    anchorSelect="#waste-rates"
                                    place={"top"}
                                    content="The percentage of materials wasted or scrapped during production."
                                    style={{ width: "250px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                        <p className="text-2xl">{`${
                            analytics?.totalScrap !== undefined
                                ? analytics?.totalScrap?.value
                                : "--"
                        }`}</p>

                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div
                                className={`${
                                    analytics?.totalScrap?.percentIncrease >= 0
                                        ? "bg-[#F0FDF4] text-[#22C55E]"
                                        : "bg-[#FEF2F2] text-[#DC2626]"
                                }  rounded-[3px] py-0.5 px-1.5 text-sm`}
                            >
                                <i
                                    className={`${
                                        analytics?.totalScrap?.percentIncrease >= 0
                                            ? "ri-arrow-up-line"
                                            : "ri-arrow-down-line"
                                    }`}
                                ></i>
                                <span className="">
                                    {analytics?.totalScrap?.percentIncrease !== undefined
                                        ? `${Math.round(
                                              Math.abs(analytics?.totalScrap?.percentIncrease)
                                          )}%`
                                        : "--"}
                                </span>
                            </div>
                            {!dates?.startDate && !dates?.endState && (
                                <p className="text-sm text-slate-500">since last month</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="relative">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        navigate(`/dashboard/production?currTab=${idx}`, {
                            replace: true,
                            state: { internalNavigation: true },
                        });
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                    borderLineClase={"!text-slate-100 w-[37%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex gap-2 ">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e?.target?.value)}
                                onInput={handleDebouncedSearch}
                                inputPlaceholder={
                                    activeTab === 0 && viewType === "chart"
                                        ? "Search Production forecast"
                                        : activeTab === 0
                                        ? "Search production plan"
                                        : activeTab === 1
                                        ? "Search BOM"
                                        : "Search"
                                }
                                inputClassName={
                                    "!h-[38px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                                }
                                inputContainerClassname={`${
                                    activeTab === 0 && viewType === "chart" && "!w-[250px]"
                                } !rounded-[4px]  !h-[40px] !pl-[20px] !bg-white !border !border-slate-200`}
                            />
                            {activeTab !== 1 && viewType !== "chart" && (
                                <>
                                    <Dropdown
                                        name="filter"
                                        value={status}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            handleStatusChange({ target: { name, value } });
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {activeTab === 3 && status === "draft"
                                                        ? "Pending"
                                                        : activeTab === 3 && status === "saved"
                                                        ? "In inventory"
                                                        : status
                                                        ? status
                                                        : "Status"}
                                                </span>

                                                {status && (
                                                    <i
                                                        onClick={handleClearStatus}
                                                        className="ri-close-circle-line text-slate-500"
                                                    ></i>
                                                )}
                                                <div className="flex items-center space-x-2">
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-[200px] !p-0 !top-[105%]`}
                                        wholeContainerClass={`max-sm:!w-full`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 "
                                        dropdownOptions={
                                            activeTab !== 3
                                                ? [
                                                      {
                                                          label: "Pending",
                                                          value: "pending",
                                                      },
                                                      {
                                                          label: "Cancelled",
                                                          value: "cancelled",
                                                      },
                                                      {
                                                          label: "Planned",
                                                          value: "planned",
                                                      },
                                                      {
                                                          label: "In progress",
                                                          value: "in-progress",
                                                      },
                                                      {
                                                          label: "Completed",
                                                          value: "completed",
                                                      },
                                                      {
                                                          label: "Rescheduled",
                                                          value: "rescheduled",
                                                      },
                                                      {
                                                          label: "On hold",
                                                          value: "on-hold",
                                                      },
                                                      {
                                                          label: "Delayed",
                                                          value: "delayed",
                                                      },
                                                      {
                                                          label: "Partially completed",
                                                          value: "partial-completed",
                                                      },

                                                      { label: "All", value: "" },
                                                  ]
                                                : [
                                                      {
                                                          label: "Pending",
                                                          value: "draft",
                                                      },
                                                      {
                                                          label: "Added to inventory",
                                                          value: "saved",
                                                      },
                                                      { label: "All", value: "" },
                                                  ]
                                        }
                                    />

                                    {activeTab !== 3 && (
                                        <Dropdown
                                            name="filter"
                                            value={""}
                                            dropdownTriggerClassName={" "}
                                            handleChange={(name, value) => {
                                                activeTab === 0
                                                    ? handleAssigneeIdChange({
                                                          target: { name, value },
                                                      })
                                                    : workOrderHandleAssigneeIdChange({
                                                          target: { name, value },
                                                      });
                                            }}
                                            dropdown={
                                                <div className="">
                                                    {activeTab === 0 ? (
                                                        <div className="flex items-center max-sm:justify-between bg-white h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                                {assigneeId?.split("-")[1]
                                                                    ? assigneeId?.split("-")[1]
                                                                    : "Assigned to"}
                                                            </span>
                                                            {assigneeId?.split("-")[1] && (
                                                                <i
                                                                    onClick={() =>
                                                                        setAssigneeId("")
                                                                    }
                                                                    className="ri-close-circle-line text-slate-500"
                                                                ></i>
                                                            )}
                                                            <div className="flex items-center space-x-2">
                                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center max-sm:justify-between bg-white h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                                {workOrderAssigneeId?.split("-")[1]
                                                                    ? workOrderAssigneeId?.split(
                                                                          "-"
                                                                      )[1]
                                                                    : "Assigned to"}
                                                            </span>
                                                            {workOrderAssigneeId?.split("-")[1] && (
                                                                <i
                                                                    onClick={() =>
                                                                        setWorkOrderAssigneeId("")
                                                                    }
                                                                    className="ri-close-circle-line text-slate-500"
                                                                ></i>
                                                            )}
                                                            <div className="flex items-center space-x-2">
                                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-[200px] !p-0 !top-[105%]`}
                                            wholeContainerClass={`max-sm:!w-full`}
                                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                            dropdownOptions={teamMembers?.map((member) => ({
                                                label: member?.fullName || member?.email,
                                                value: member?._id + "-" + member?.email,
                                            }))}
                                        />
                                    )}
                                </>
                            )}
                            <Button
                                btnText={"Settings"}
                                type={"button"}
                                btnClassname="!text-[13px] !bg-white !py-2 !px-4 !w-fit !whitespace-nowrap border border-slate-200"
                                onClick={() =>
                                    navigate("/dashboard/production/settings", {
                                        state: { internalNavigation: true },
                                    })
                                }
                                icon={<i className="ri-settings-2-line"></i>}
                            />
                            {activeTab !== 3 && (
                                <Button
                                    btnText={`${
                                        activeTab === 0 || activeTab === 2
                                            ? "Create production plan"
                                            : activeTab === 1
                                            ? "Create bill of material"
                                            : ""
                                        // : "Upload production report"
                                    } `}
                                    type={"button"}
                                    btnPermission={[
                                        AccountPermissions.all_access,
                                        AccountPermissions.create_production_plan,
                                    ]}
                                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                    onClick={() =>
                                        activeTab === 0 || activeTab === 2
                                            ? navigate(
                                                  "/dashboard/production/create-plan?planTab=1",
                                                  { state: { internalNavigation: true } }
                                              )
                                            : activeTab === 1
                                            ? navigate(
                                                  `/dashboard/production/create-bom?currTab=${currTab}`,
                                                  { state: { internalNavigation: true } }
                                              )
                                            : ""
                                    }
                                />
                            )}
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Production;
