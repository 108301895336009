import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetSingleAiTeammate = () => {
    const dispatch = useAppDispatch();
    const { aiTeammateId } = useParams();

    const {
        fetchingSingleAiTeammate,
        fetchSingleAiTeammateSuccess,
        fetchSingleAiTeammateFailure,
        fetchingAiAgentTasks,
        fetchAiAgentTasksSuccess,
        fetchAiAgentTasksFailure,
        fetchingAiAgentTaskStats,
        fetchAiAgentTaskStatsSuccess,
        fetchAiAgentTaskStatsFailure,
    } = useAppSelector((state) => state.workforce);

    const [aiTeammateDetails, setAiTeammateDetails] = useState<{ [key: string]: any }>({});
    const [aiTeammateStats, setAiTeammateStats] = useState<{ [key: string]: any }>({});
    const [aiTeammatePendingTasks, setAiTeammatePendingTasks] = useState<{ [key: string]: any }[]>(
        []
    );
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [aiTeammateAllTasks, setAiTeammateAllTasks] = useState<{ [key: string]: any }[]>([]);
    const [taskStatus, setTaskStatus] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [dates, setDates] = useState<{ startDate: string; endDate: string }>({
        startDate: "",
        endDate: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            10
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    const handleTaskStatusChange = (value: string) => {
        setUpdatedPage(1);
        setTaskStatus(value);
    };

    const handleClearTaskStatusFilter = () => {
        setUpdatedPage(1);
        setTaskStatus("");
    };

    useEffect(() => {
        if (aiTeammateId) {
            dispatch(workforceActions.getSingleAiTeammate(aiTeammateId));

            return () => {
                dispatch(workforceActions.resetGetSingleAiTeammateSuccess());
            };
        }
    }, [dispatch, aiTeammateId]);

    useEffect(() => {
        if (aiTeammateId) {
            dispatch(
                workforceActions.getSingleAiAgentTaskStats(
                    aiTeammateId,
                    dates?.startDate,
                    dates?.endDate,
                    dates?.startDate && dates?.endDate ? "custom" : ""
                )
            );

            return () => {
                dispatch(workforceActions.resetGetSingleAiAgentTaskStatsSuccess());
            };
        }
    }, [dispatch, aiTeammateId, dates]);

    useEffect(() => {
        if (aiTeammateId) {
            dispatch(
                workforceActions.getSingleAiAgentTasks(
                    aiTeammateId,
                    updatedPage,
                    debouncedSearch,
                    taskStatus
                )
            );

            return () => {
                dispatch(workforceActions.resetGetSingleAiAgentTasksSuccess());
            };
        }
    }, [dispatch, aiTeammateId, updatedPage, debouncedSearch, taskStatus]);

    useEffect(() => {
        if (Boolean(fetchSingleAiTeammateSuccess)) {
            setAiTeammateDetails(fetchSingleAiTeammateSuccess);
        }
    }, [fetchSingleAiTeammateSuccess]);

    useEffect(() => {
        if (Boolean(fetchAiAgentTaskStatsSuccess)) {
            setAiTeammateStats(fetchAiAgentTaskStatsSuccess?.stats);
        }
    }, [fetchAiAgentTaskStatsSuccess]);

    useEffect(() => {
        if (Boolean(fetchAiAgentTasksSuccess)) {
            setAiTeammatePendingTasks((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchAiAgentTasksSuccess?.tasks?.pendingTasks], "_id")
                    : uniqBy([...prev, ...fetchAiAgentTasksSuccess?.tasks?.pendingTasks], "_id")
            );

            setAiTeammateAllTasks((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchAiAgentTasksSuccess?.tasks?.allTasks], "_id")
                    : uniqBy([...prev, ...fetchAiAgentTasksSuccess?.tasks?.allTasks], "_id")
            );
            setPagination({
                currentPage: fetchAiAgentTasksSuccess?.pagination?.current,
                noOfPages: fetchAiAgentTasksSuccess?.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchAiAgentTasksSuccess]);

    return {
        isFetching: fetchingSingleAiTeammate || fetchingAiAgentTaskStats,
        isFetchingTasks: fetchingAiAgentTasks,
        isFetchingStats: fetchingAiAgentTaskStats,
        isFetchingAiTeammate: fetchingSingleAiTeammate,
        error: fetchSingleAiTeammateFailure,
        data: aiTeammateDetails,
        setAiTeammateDetails,
        aiTeammateStats,
        aiTeammatePendingTasks,
        aiTeammateAllTasks,
        isLoadingMore,
        pagination,
        search,
        handleSearch,
        handleDebouncedChange,
        handleTaskStatusChange,
        taskStatus,
        handleClearTaskStatusFilter,
        dates,
        setDates,
    };
};

export default useGetSingleAiTeammate;
