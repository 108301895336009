export const roles = [
    { label: "Admin", value: "admin" },
    { label: "Manager", value: "manager" },
    { label: "Operator/Logistician", value: "operator" },
    { label: "Viewer/Analyst", value: "viewer" },
    { label: "Technician", value: "Technician" },
];

// export const accessTypes = [
//     { label: "All access", value: "all_access" },
//     { label: "View financials", value: "financials_view" },
//     { label: "Edit shipments", value: "shipment_edit" },
//     { label: "Edit workflows", value: "workflow_edit" },
//     { label: "View workflows only", value: "workflow_view" },
//     { label: "View shipment", value: "shipment_view" },
//     { label: "View analytics", value: "analytics_view" },
//     { label: "Edit analytics", value: "analytics_edit" },
//     { label: "Operations access", value: "operations_edit" },
//     { label: "View other members", value: "team_view" },
//     { label: "Manage other members", value: "team_edit" },
// ];

export const accessTypes = [
    { label: "All access", value: "all_access" },
    { label: "View dashboard stats and forecast", value: "view_dashboard" },
    { label: "View inventory", value: "view_inventory" },
    { label: "Create inventory products", value: "create_inventory" },
    { label: "Update inventory details", value: "update_inventory" },
    { label: "Add/increase stock", value: "add_stock" },
    { label: "Transfer stock between warehouses", value: "transfer_stock" },
    { label: "View purchase orders", value: "view_purchase_order" },
    { label: "Create purchase orders", value: "create_purchase_order" },
    { label: "View suppliers", value: "view_suppliers" },
    { label: "Create and manage suppliers", value: "create_suppliers" },
    { label: "Create production plans", value: "create_production_plan" },
    { label: "View production plans and work orders", value: "view_production" },
    { label: "View production reports", value: "view_production_report" },
    { label: "Create AI teammates", value: "create_ai_teammate" },
    { label: "View and manage AI tasks", value: "view_ai_task" },
    { label: "View sales orders and stats", value: "view_sales_order" },
    { label: "Create sales orders", value: "create_sales_order" },
    { label: "Update sales orders", value: "update_sales_order" },
    { label: "Manage business settings", value: "manage_settings" },
    { label: "Manage team members", value: "manage_team" },
    { label: "Manage integrations", value: "manage_integrations" },
    { label: "Manage brands", value: "brand_admin" },
];
