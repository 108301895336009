import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import useGetTrackTraceFinishedGoods from "hooks/trackTraceHook/useGetTrackTraceFinishedGoods";
import useGetTrackTraceRawMaterials from "hooks/trackTraceHook/useGetTrackTraceRawMaterials";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type finishedGoodsProps = {
    dateRange?: string;
    warehouseId?: string;
};

const RawMaterial = ({ dateRange, warehouseId }: finishedGoodsProps) => {
    const navigate = useNavigate();
    const {
        data: rawMaterial,
        error: finishedGoodsError,
        handleDebouncedChange,
        isFetching: RawMaterialsLoading,
        isLoadingMore,
        pagination,
        handleSearch,
        setSortValues,
        setDateRange,
        setWarehouseId,
    } = useGetTrackTraceRawMaterials();
    useEffect(() => {
        if (dateRange) {
            setDateRange(dateRange);
        }
        if (warehouseId) {
            setWarehouseId(warehouseId);
        }
    }, [dateRange, warehouseId]);

    const handleSortChange = (idx: number, sortValue: number) => {
        let sortKey;
        if (idx === 0) {
            sortKey = "batchId";
        } else if (idx === 1) {
            sortKey = "stockItem";
        } else if (idx === 2) {
            sortKey = "status";
        } else if (idx === 3) {
            sortKey = "expiryDate";
        }
        setSortValues({ [sortKey]: sortValue });
    };

    const tableHeader = [
        {
            title: `Original batch ID`,
            widthClass: "w-[20%] ",
            sortIcon: true,
        },
        { title: "Stock item", widthClass: "w-[26%]", sortIcon: true },
        { title: "Status", widthClass: "w-[20%]", sortIcon: true },
        { title: "Expiry Date", widthClass: "w-[16%]", sortIcon: true },
        { title: "Action", widthClass: "w-[10%] pl-4" },
    ];

    const tableBody = rawMaterial?.map((product, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  `}
                >
                    <p className="">{product?.batchId}</p>
                    <p className="text-slate-400 text-xs ">
                        {moment(product?.updatedAt).format("MM/DD/YYYY; h:mma")}
                    </p>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/track-trace/${product?.batchId}?productType=raw-material`, {
                    state: { internalNavigation: true },
                });
            },
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <p className="">{product?.inventory?.productName}</p>
                </div>
            ),
        },
        {
            content: (
                <div className="py-4">
                    <p
                        className={` ${
                            product?.status === "fulfilled"
                                ? "text-g-60 bg-g-25"
                                : product?.status === "rejected"
                                ? "text-r-55 bg-r-25 "
                                : product?.status === "defect-detected"
                                ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                : "text-slate-900 bg-slate-200"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        {product?.status === "defect-detected"
                            ? "Defect detected"
                            : product?.status === "in-progess"
                            ? "In progress"
                            : product?.status}
                    </p>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    {product?.inventorystock?.productViabilityDetails?.expiryDate && (
                        <p className="">
                            {moment(
                                product?.inventorystock?.productViabilityDetails?.expiryDate
                            ).format("MM/DD/YYYY")}
                        </p>
                    )}
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-2.5 px-6 !font-rocGroteskMedium text-sm text-slate-900 border-l  border-l-slate-200 w-full text-center`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 !left-[-50px]
                             border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: `View details`,
                                value: "1",
                                action: () => {
                                    navigate(
                                        `/dashboard/track-trace/${product?.batchId}?productType=raw-material`,
                                        { state: { internalNavigation: true } }
                                    );
                                },
                            },
                            {
                                label: "Initiate recall",
                                value: "2",
                                action: () => {
                                    console.log("2");
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const mobileTableBody = rawMaterial?.map((product, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/track-trace/${product?.batchId}?productType=finished-good`, {
                    state: { internalNavigation: true },
                });
            },
            topString: product?._id,
            topContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <span>{product?.batchId}</span>
                    <span className="text-slate-500">|</span>
                    <span className="text-slate-500">
                        {truncate(product?.inventory?.productName, { length: 12 })}
                    </span>
                </div>
            ),
            bottomContent: (
                <div className="mt-3 flex gap-3 text-slate-400 text-xs font-rocGroteskMedium">
                    <p>{moment(product?.updatedAt).format("MM/DD/YYYY; h:mma")}</p>
                </div>
            ),
            rightIcon: (
                <div
                    className={` flex justify-end space-x-2.5  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <p
                        className={` ${
                            product?.status === "fulfilled"
                                ? "text-g-60 bg-g-25"
                                : product?.status === "rejected"
                                ? "text-r-55 bg-r-25 "
                                : product?.status === "defectDetected"
                                ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                : "text-slate-900 bg-slate-200"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        {product?.status === "defectDetected"
                            ? "Defect detected"
                            : product?.status === "inProgress"
                            ? "In progress"
                            : product?.status}
                    </p>

                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 !left-[-80px]
                             border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: `View details`,
                                value: "1",
                                action: () => {
                                    navigate(
                                        `/dashboard/track-trace/${product?.batchId}?productType=finished-good`,
                                        { state: { internalNavigation: true } }
                                    );
                                },
                            },
                            // {
                            //     label: "Initiate recall",
                            //     value: "2",
                            //     action: () => {
                            //         console.log("2");
                            //     },
                            // },
                        ]}
                    />
                </div>
            ),
        };
    });

    return (
        <div className="overflow-x-scroll mb-6">
            {RawMaterialsLoading && !isLoadingMore && (
                <CustomTableLoader tableHeader={tableHeader} />
            )}
            {rawMaterial?.length > 0 && (
                <>
                    <div className="max-lg:hidden">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isScrollable={false}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                            tableClass="!border !border-slate-200"
                            bodyItemClass="!border-slate-200"
                            sort={handleSortChange}
                        />
                    </div>

                    <div className="hidden max-lg:block">
                        <CustomMobileTable data={mobileTableBody} />
                    </div>

                    {RawMaterialsLoading && isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default RawMaterial;
